import { useEffect, useRef, useState } from 'react';
import Geogebra from './Geogebra';
import styled from '@emotion/styled';
import { translateAppletTexts } from './utils';
import useResourceTimer from '../../hooks/useResourceTimer';
import LoaderContainer from '../../components/LoaderContainer';
import { css } from '@emotion/react';
import { useEventListener } from '@innovamat/hooks';
import { isTouchDevice } from '../../utils/isTouchDevice';

type GeogebraViewerProps = {
  file: any;
  height?: number | undefined;
  width?: number | undefined;
  script: string;
  translations: {};
  settings?: {};
  isInFullScreen: boolean;
};

declare global {
  interface Window {
    geogebra: any;
  }
}

const GeogebraContainer = styled.div`
  overflow: auto;
  width: 100%;
  height: 100%;

  ${({ isInFullScreen }: { isInFullScreen: boolean; isTouchable: boolean }) =>
    isInFullScreen &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `}

  ${({ isTouchable }: { isTouchable: boolean }) =>
    isTouchable &&
    css`
      overflow: scroll;
      height: 100% !important;
      background-color: transparent;

      ::-webkit-scrollbar {
        width: 20px;
        height: 20px;
      }

      ::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.5);
        border: 2px solid transparent;
        border-radius: 10px;
        margin: 30px;
      }

      ::-webkit-scrollbar-track {
        background-color: transparent;
      }

      ::-webkit-scrollbar-corner {
        background-color: transparent;
      }
    `}
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

function parseSettings(settings: any) {
  if (!settings) return {};
  return {
    enableRightClick: settings.enable_right_click,
    enableLabelDrags: settings.enable_label_drags,
    showResetIcon: settings.show_reset_icon,
    enableShiftDragZoom: settings.enable_shift_drag_zoom,
    showMenuBar: settings.show_menu_bar,
    showToolBar: settings.show_tool_bar,
    showAlgebraInput: settings.show_algebra_input,
    allowStyleBar: settings.allow_style_bar,
    allowUpscale: settings.allow_upscale,
    showToolBarHelp: false,
  };
}

function GeogebraViewer(props: GeogebraViewerProps): JSX.Element {
  useResourceTimer();
  const isTouchable = isTouchDevice();

  const geoRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);

  const { file, script, settings, translations, isInFullScreen } = props;

  const [applet, setApplet] = useState<any>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [textsArray, setTextsArray] = useState(null);

  const [, setSize] = useState({
    height: 0,
    innerHeight: 0,
  });

  useEventListener(
    'scroll',
    () => {
      if (geoRef.current !== null && scrollRef.current !== null) {
        geoRef.current.scrollTop = scrollRef.current.scrollTop;
      }
    },
    scrollRef
  );

  useEventListener(
    'scroll',
    () => {
      if (geoRef.current !== null && scrollRef.current !== null) {
        scrollRef.current.scrollTop = geoRef.current.scrollTop;
      }
    },
    geoRef
  );

  const handleOnLoad = (): void => {
    setApplet(window.geogebra);
    window.geogebra?.setErrorDialogsActive(false);
    setTextsArray(window.geogebra.getValueString('textsArray'));
    setIsLoaded(true);
  };

  useEffect(() => {
    if (applet && translations && textsArray) {
      translateAppletTexts(applet, translations, textsArray);
    }
  }, [translations, applet, textsArray]);

  useEffect(() => {
    if (isLoaded && isTouchable) {
      setTimeout(() => {
        const innerGeo = document.getElementsByClassName('ggbTransform')[0];
        const height = geoRef.current?.clientHeight;
        setSize({
          height: height!,
          innerHeight: innerGeo?.clientHeight,
        });
      }, 1000);
    }
  }, [isLoaded, isTouchable, isInFullScreen]);

  const parameters = JSON.parse(
    JSON.stringify({ ...props, ...parseSettings(settings) })
  );

  return (
    <Wrapper>
      {!isLoaded && <LoaderContainer />}
      <GeogebraContainer
        isInFullScreen={isInFullScreen}
        isTouchable={isTouchable}
        id="geo-container"
        ref={geoRef}
      >
        <Geogebra
          {...parameters}
          filename={file}
          id="geogebra"
          appletOnLoad={handleOnLoad}
          appName={script}
          key={script}
          width={1366}
          height={768}
        />
      </GeogebraContainer>
    </Wrapper>
  );
}

export default GeogebraViewer;
