import styled from '@emotion/styled';
import { Typography, getTypography } from '@innovamat/glimmer-components';
import { usePartColors } from '../../hooks/usePartColors';
import { IconEvaluable, IconEvaluablePrintable } from '../IconEvaluable';
import { Tip } from '../Tip';
import { PreprInnerHtml } from '../PreprInnerHtml';
import { Solution } from '../Solution';
import { WhatToObserve } from '../WhatToObserve';
import { ManagementComment } from '../ManagementComment';
import { Media } from '../Media';
import {
  Content,
  Evaluable,
  Management,
  PartType,
} from '../../types/SmartGuide';
import { Table } from '../Table';
import { PageBreak } from '../page-break';
import { PrintableBody1 } from '../../printable-styles/printable-typography';

const NumContainer = styled.div`
  width: 24px;
  min-width: 24px;
  display: grid;
  place-items: center;
  margin: 8px 4px 0 0;
  color: ${({ color }) => color};

  @media print {
    margin: 0 2px 0 0;
  }
`;

const StepContainer = styled.div`
  display: flex;
  align-items: flex-start;
  max-width: 100%;
`;

const StepContent = styled.div`
  padding: 8px 0 8px 16px;
  border-left: 1px solid;
  border-color: ${({ color }) => color};
  display: flex;
  flex: 1;
  width: 1%;
  flex-direction: column;
  gap: 16px;

  @media print {
    padding: 2px 0 2px 8px;
    gap: 8px;
  }
`;

const NumberText = styled(Typography.Body1)`
  ${({ theme }) => getTypography(theme, 'Subtitle 2')}
`;

const StepText = styled(PrintableBody1)`
  gap: 16px;
  display: flex;
  justify-content: space-between;

  @media print {
    color: #000000;
    gap: 8px;
  }
`;

const TextComponent = styled(PrintableBody1)``;

const StyledIconEvaluable = styled(IconEvaluable)`
  height: auto !important;
`;

const StyledIconEvaluablePrintable = styled(IconEvaluablePrintable)`
  height: auto;

  > div {
    height: auto;
  }
`;

const ManagementContainer = styled.div`
  display: grid;
  gap: 8px;
`;

type Props = {
  num?: number | null;
  part: PartType;
  content: Content[];
  evaluable?: Evaluable;
  isPrintable?: boolean;
};

export function NumberedStep({
  num,
  part,
  content,
  evaluable,
  isPrintable,
}: Props) {
  const { getElement } = usePartColors();
  const color = getElement(part);

  const getListManagement = (index: number) => {
    return content.slice(index + 1).reduce(
      (acc, item, _, arr) => {
        if (item.__typename === 'ConsejoDeGestin') {
          acc.push(item);
        } else {
          arr.splice(1);
          return acc;
        }
        return acc;
      },
      [content[index]]
    );
  };

  const renderFirstText = (firstChild: Content) => {
    if (firstChild.__typename !== 'Text') return null;
    if (typeof firstChild.text !== 'string')
      return (
        <StepText key={`first-child`}>
          <p>{firstChild.text}</p>
        </StepText>
      );
    return (
      <StepText key={`first-child`}>
        <PreprInnerHtml text={firstChild.text} />
        {evaluable?.isEvaluable && !isPrintable && (
          <StyledIconEvaluable dimension={evaluable.dimension} />
        )}
        {evaluable?.isEvaluable && isPrintable && (
          <StyledIconEvaluablePrintable dimension={evaluable.dimension} />
        )}
      </StepText>
    );
  };

  const renderChildren = () => {
    if (!content) return null;

    return content.map((child, index: number) => {
      if (!child) return null;

      switch (child.__typename) {
        case 'Text':
          if (index === 0) return renderFirstText(child);
          return typeof child.text === 'string' ? (
            <TextComponent>
              <PreprInnerHtml
                key={`${child.__typename}-${index}`}
                text={child.text}
              />
            </TextComponent>
          ) : (
            <TextComponent>{child.text}</TextComponent>
          );

        case 'ConsejoDeGestin':
          if (index > 1 && content[index - 1].__typename === 'ConsejoDeGestin')
            return null;
          return (
            <ManagementContainer key={`Consejo-${index}`}>
              {getListManagement(index).map((element, i) => (
                <ManagementComment
                  key={`${element.__typename}-${index + i}`}
                  text={(element as Management).text}
                  media={(element as Management).media}
                  tables={(element as Management).tables}
                />
              ))}
            </ManagementContainer>
          );

        case 'Formacin':
          return (
            <ManagementComment
              key={`${child.__typename}-${index}`}
              text={child.text}
              media={child.media}
              tables={child.tables}
            />
          );

        case 'Ampliacin':
          return (
            <Tip
              isPrintable={isPrintable}
              key={`${child.__typename}-${index}`}
              type="ampliation"
              media={child.media}
              tables={child.tables}
            >
              {child.text}
            </Tip>
          );

        case 'Apoyo':
          return (
            <Tip
              isPrintable={isPrintable}
              key={`${child.__typename}-${index}`}
              type="support"
              media={child.media}
              tables={child.tables}
            >
              {child.text}
            </Tip>
          );

        case 'Solucin':
          return (
            <Solution
              isPrintable={isPrintable}
              key={`${child.__typename}-${index}`}
              part={part}
              media={child.media}
              tables={child.tables}
            >
              {child.text}
            </Solution>
          );

        case 'QuePodemosObservar':
          return (
            <WhatToObserve
              isPrintable={isPrintable}
              key={`${child.__typename}-${index}`}
              whatToObserve={child.items}
            />
          );

        case 'ZzImagen':
          if (!child.media) return null;
          return (
            <Media key={`${child.__typename}-${index}`} media={child.media} />
          );

        case 'TableSmartguides':
          return <Table tables={child.tables} />;

        case 'PageBreak':
          return <PageBreak />;

        default:
          return null;
      }
    });
  };

  return (
    <StepContainer>
      <NumContainer color={color}>
        <NumberText>{num !== null ? num : ''}</NumberText>
      </NumContainer>
      <StepContent color={color}>{renderChildren()}</StepContent>
    </StepContainer>
  );
}
