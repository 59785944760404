export const borders = {
  size: { '01': '1px solid', '02': '2px solid', '03': '3px solid' },

  radius: {
    xs: '0px',
    ms: '2px',
    sm: '4px',
    md: '8px',
    lg: '20px',
    xl: '30px',
    xxl: '60px',
  },
};
