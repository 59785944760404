import styled from '@emotion/styled';
import { Typography } from '../Typography';
import { IconButton } from '../IconButton';
import { useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { useGlimmerTheme } from '../../theme';

const Widget = styled.div`
  position: fixed;
  bottom: 0;
  right: 32px;
  width: 360px;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.24));
  border-radius: 8px 8px 0px 0px;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 10;
`;

const Content = styled.div<{ isExpanded: boolean; contentHeight: number }>`
  opacity: 1;
  max-height: ${({ contentHeight }) => contentHeight}px;
  transition: all 0.15s ease;

  ${({ isExpanded }) =>
    !isExpanded &&
    css`
      max-height: 0;
      opacity: 0;
      box-sizing: border-box;
      transition: all 0.15s ease;
    `}
`;

const Header = styled.div`
  padding: 16px 16px 16px 20px;
  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.surface['surface-secondary'].value};
  border-radius: 8px 8px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Icons = styled.div`
  display: flex;
  gap: 8px;
`;

const ArrowIcon = styled(IconButton)<{ isExpanded: boolean }>`
  transform: ${({ isExpanded }) =>
    isExpanded ? 'rotate(0deg)' : 'rotate(180deg)'};
`;

type Props = {
  children: React.ReactNode;
  title: string;
  isVisible?: boolean;
  onClose?: () => void;
  isExpanded?: boolean;
  onExpand?: (isExpanded: boolean) => void;
};

export function FloatingWidget({
  isVisible,
  isExpanded,
  onClose,
  title,
  children,
  onExpand,
}: Props) {
  const [display, setDisplay] = useState(
    isVisible !== undefined ? isVisible : true
  );
  const theme = useGlimmerTheme();
  const [expanded, setExpanded] = useState(
    isExpanded !== undefined ? isExpanded : true
  );
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState(0);

  const handleToggleExpand = () => {
    const prevValue = !expanded;
    setExpanded(prevValue);
    onExpand?.(prevValue);
  };

  const handleClickClose = () => {
    setDisplay(false);
    onClose?.();
  };

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.offsetHeight);
    }
  }, [expanded]);

  if (isExpanded !== undefined && isExpanded !== expanded) {
    setExpanded(!!isExpanded);
  }

  if (isVisible !== undefined && isVisible !== display) {
    setDisplay(!!isVisible);
  }

  const iconColor = theme.tokens.color.alias.cm.text.text.value;

  if (!display) return null;

  return (
    <Widget data-testid="floating-widget">
      <Header>
        <Typography.Subtitle1>{title}</Typography.Subtitle1>
        <Icons>
          <ArrowIcon
            onClick={handleToggleExpand}
            isExpanded={expanded}
            icon="ExpandMoreIcon"
            size="S"
            iconColor={iconColor}
            dataTestId="floating-widget-toggle-button"
          />
          <IconButton
            dataTestId="floating-widget-close-button"
            icon="CloseIcon"
            size="S"
            iconColor={iconColor}
            onClick={handleClickClose}
          />
        </Icons>
      </Header>
      <Content
        contentHeight={contentHeight}
        isExpanded={expanded}
        data-testid="floating-widget-children"
      >
        <div ref={contentRef}>{children}</div>
      </Content>
    </Widget>
  );
}
