import styled from '@emotion/styled';
import { Modal, Typography, theme } from '@innovamat/glimmer-components';
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};
const StudentViewModal = ({ isOpen, onClose }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('weeklyPractice.modal.studentViewMode.title')}
      closeOnEsc
      closeOnClickOutside
      buttons={[
        {
          children: t('common.close'),
          variant: 'accent',
          onClick: onClose,
        },
      ]}
    >
      <ContentContainer>
        <span>{t('weeklyPractice.modal.studentViewMode.content.first')}</span>
        <span>
          <Trans
            i18nKey="weeklyPractice.modal.studentViewMode.content.second"
            components={{
              a: (
                <Typography.Link1
                  href={t('weeklyPractice.modal.studentViewMode.content.link')}
                  target="_blank"
                  rel="noopener noreferrer"
                  color={theme.tokens.color.specific.element.link.value}
                />
              ),
            }}
          />
        </span>
      </ContentContainer>
    </Modal>
  );
};

export default StudentViewModal;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 8px;
`;
