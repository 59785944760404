import styled from '@emotion/styled';
import { Cell, TableContentType } from '../../types/Table';
import { PreprInnerHtml } from '../PreprInnerHtml';
import { Media } from '../Media';
import { PartType } from '../../types/SmartGuide';
import { usePartColors } from '../../hooks/usePartColors';
import { getTypography, useGlimmerTheme } from '@innovamat/glimmer-components';

type Props = {
  tables: TableContentType[];
  part?: PartType;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  table {
    margin: 8px 0;
    width: 100%;
    border-spacing: 4px;
    color: ${({ theme }) => theme.tokens.color.alias.cm.text.text.value};

    @media print {
      gap: 12px;
      border-spacing: 2px;
      margin: 2px 0;
    }
  }

  @media print {
    gap: 12px;
    border-spacing: 2px;
    margin: 2px 0;
  }

  .mediaContainer {
    margin: 0;
  }
`;

const Td = styled.td<{ bgColor: string }>`
  padding: 8px;
  border-radius: 4px;
  background-color: ${({ bgColor }) => bgColor};

  @media print {
    padding: 8px;
    ${({ theme }) => getTypography(theme, 'Body 3')}
    line-height: 14px;
  }
`;

const Th = styled.th<{ bgColor: string }>`
  padding: 16px 8px;
  border-radius: 4px;
  background-color: ${({ bgColor }) => bgColor};

  @media print {
    padding: 8px 4px;
    ${({ theme }) => getTypography(theme, 'Body 3')}
    line-height: 14px;
    font-weight: 500;
  }
`;

export function Table({ tables, part }: Props): JSX.Element {
  const { getMedium } = usePartColors();
  const theme = useGlimmerTheme();

  const getHeaderColor = (cell: Cell, index: number): string => {
    const isFirstItemAndEmpty = index === 0 && !cell.text && !cell.media;

    if (isFirstItemAndEmpty) return 'transparent';

    return cell.isHeader
      ? getMedium(part)
      : theme.tokens.color.alias.cm.surface['surface-primary'].value;
  };

  return (
    <Container>
      {tables.map((table) => (
        <table key={table.title}>
          <tbody>
            {table.rows.map((row, index) => (
              <tr key={index}>
                {row.map((cell, index) => {
                  const CellComponent = cell.isHeader ? Th : Td;
                  return (
                    <CellComponent
                      key={index}
                      width={`${table.columnPercentage[index]}%`}
                      bgColor={getHeaderColor(cell, index)}
                    >
                      <PreprInnerHtml text={cell.text} />
                      {cell.media && <Media media={cell.media} />}
                    </CellComponent>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      ))}
    </Container>
  );
}
