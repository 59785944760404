import styled from '@emotion/styled';

const SessionContentWrapper = styled.div``;

const GuideWrapper = styled.div`
  margin: 0 auto;
  margin-top: 16px;
  min-height: calc(100vh - var(--heightHeader, 0vh));
`;
const RelatedItemsWrapper = styled.div`
  margin-top: 16px;
  min-height: calc(100vh - var(--heightHeader, 0vh));
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    > div {
      margin-left: 4px;
    }
  }
`;

const AssessableResourcesWrapper = styled.div`
  min-height: calc(100vh - var(--heightHeader, 0vh));
`;

const PlayerSectionWrapper = styled.div`
  width: 100%;
  height: calc(calc(var(--vh, 1vh) * 100) - 220px);
  margin-top: 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-height: calc(100vh - var(--heightHeader, 0vh));
  }
`;

export {
  SessionContentWrapper,
  GuideWrapper,
  RelatedItemsWrapper,
  PlayerSectionWrapper,
  AssessableResourcesWrapper,
};
