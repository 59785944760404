/* eslint-disable jsx-a11y/label-has-associated-control */
import { Dispatch, SetStateAction, useState } from 'react';

import { FloatWrapper, Label, Input } from './FloatingLabelInput.styled';

type Props = {
  label: string;
  isPasswordCorrect: boolean;
  parentCallback: Dispatch<SetStateAction<string>>;
};

function FloatingLabelInput(props: Props): JSX.Element {
  const { label, isPasswordCorrect, parentCallback } = props;
  const [active, setActive] = useState(false);
  const handleFocus = (): void => {
    setActive(true);
  };
  const handleBlur = (e: any): void => {
    if (!e.target.value) {
      setActive(false);
    }
  };

  const handleInputChange = (e: any): void => {
    parentCallback(e.target.value);
  };
  return (
    <FloatWrapper>
      <Label htmlFor="float-input" active={active}>
        {label}
      </Label>
      <Input
        type="password"
        id="float-input"
        onFocus={handleFocus}
        onBlur={handleBlur}
        isPasswordCorrect={isPasswordCorrect}
        onChange={handleInputChange}
      />
    </FloatWrapper>
  );
}

export default FloatingLabelInput;
