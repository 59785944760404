import { useMutation } from '@tanstack/react-query';
import { AuthError } from '../utils/AuthError';
import { LoginHookProps, LoginRequest, LoginResponse } from '../types/login';
import { usersLoginRequest } from '../utils/api';

export function useUsersLogin({ dispatch, credentials }: LoginHookProps) {
  const { mutate } = useMutation<
    LoginResponse,
    AuthError,
    LoginRequest | string
  >({
    mutationFn: (request) => {
      return usersLoginRequest(request as LoginRequest, credentials);
    },
  });

  const handleSetLoading = (loading: boolean) => {
    dispatch({
      type: 'SET_IS_LOADING',
      payload: {
        loading,
        key: { type: 'users' },
      },
    });
  };

  const handleLogin = (request: LoginRequest | string) => {
    handleSetLoading(true);
    mutate(request, {
      onSuccess(data) {
        dispatch({
          type: 'SET_LOGIN_INFO',
          payload: {
            loggedFrom: { type: 'users' },
            response: data,
          },
        });
        handleSetLoading(false);
      },
      onError(error) {
        dispatch({ type: 'SET_ERROR', payload: error });
        handleSetLoading(false);
      },
    });
  };

  return handleLogin;
}
