import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ChangeEvent, InputHTMLAttributes, useState } from 'react';
import { Icon } from '../Icon';
import { useGlimmerTheme } from '../../theme';
import { StateLayer } from '../../utils/common.styled';
import { ConfettiAnimation } from '../ConfettiAnimation/ConfettiAnimation';

interface LittleCheckProps extends InputHTMLAttributes<HTMLInputElement> {
  checked?: boolean;
  onCheck?: (value: boolean) => void;
  solutions?: boolean;
  customStyles?: string;
  isConfettiAnimationEnabled?: boolean;
}

const LittleCheckWrapper = styled.label<{
  customStyles?: string;
}>`
  display: flex;
  align-items: center;
  position: relative;
  height: 14px;
  width: 14px;

  &:hover .stateLayer {
    border-radius: 50%;
    background-color: ${({ theme }) =>
      theme.tokens.color.specific['state-layer']['state-hover-darker'].value};
  }

  ${({ customStyles }) =>
    customStyles &&
    css`
      ${customStyles}
    `}
`;

const LittleCheckInput = styled.input<{
  checked?: boolean;
  solutions?: boolean;
}>`
  cursor: pointer;
  display: flex;
  align-items: center;
  appearance: none;

  ${({ theme, solutions }) =>
    css`
      ::before {
        background-color: transparent;
        border: 1px solid
          ${theme.tokens.color.alias.cm.border['border-default'].value};
        border-radius: 50%;
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        height: 12px;
        width: 12px;
        transition: all 0.1s ease-in-out;
      }

      :disabled::before {
        pointer-events: none;
        border: 1px solid
          ${theme.tokens.color.alias.cm.border['border-disabled'].value};
      }

      :checked::before {
        background-color: ${solutions
          ? theme.tokens.color.alias.cm.bg['bg-accent-inverted'].value
          : theme.tokens.color.alias.cm.bg['bg-neutral-inverted'].value};
        border: 1px solid ${solutions ? 'transparent' : 'transparent'};
      }

      :disabled:checked::before {
        pointer-events: none;
        background-color: ${theme.tokens.color.alias.cm.bg['bg-disabled']
          .value};
      }
    `}
`;

const CheckIcon = styled(Icon)<{ disabled?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  color: ${({ disabled, theme }) =>
    disabled
      ? theme.tokens.color.alias.cm.icon['icon-disabled'].value
      : theme.tokens.color.alias.cm.icon['icon-inverted'].value};
`;

const ConfettiWrapper = styled.div`
  position: absolute;
  bottom: -67px;
  right: -75px;
  z-index: 11;
`;

export function LittleCheck({
  checked: controlledChecked,
  defaultChecked,
  onCheck,
  disabled,
  solutions,
  customStyles,
  isConfettiAnimationEnabled = false,
  ...rest
}: LittleCheckProps) {
  const theme = useGlimmerTheme();
  const [uncontrolledChecked, setUncontrolledChecked] = useState(
    defaultChecked || false
  );
  const [isConfettiAnimation, setConfettiAnimation] = useState(false);

  const checked =
    controlledChecked !== undefined ? controlledChecked : uncontrolledChecked;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newChecked = event.target.checked;

    if (isConfettiAnimationEnabled && newChecked) {
      setConfettiAnimation(true);
      setTimeout(() => {
        setConfettiAnimation(false);
      }, 1500);
    }

    if (onCheck) {
      onCheck(newChecked);
    } else {
      setUncontrolledChecked(newChecked);
    }
  };

  const getIconColor = () =>
    disabled
      ? theme.tokens.color.alias.cm.icon['icon-disabled'].value
      : theme.tokens.color.alias.cm.icon['icon-inverted'].value;

  return (
    <LittleCheckWrapper
      customStyles={customStyles}
      onClick={(e) => e.stopPropagation()}
    >
      {!disabled && <StateLayer className="stateLayer" />}
      {isConfettiAnimation && (
        <ConfettiWrapper>
          <ConfettiAnimation width={164} />
        </ConfettiWrapper>
      )}
      <LittleCheckInput
        data-testid="little-check"
        type="checkbox"
        className="checkbox"
        disabled={disabled}
        checked={checked}
        onChange={handleChange}
        solutions={solutions}
        {...rest}
      />
      {checked && (
        <CheckIcon
          size="S"
          icon="CheckIcon"
          disabled={disabled}
          iconColor={getIconColor()}
        />
      )}
    </LittleCheckWrapper>
  );
}

export default LittleCheck;
