import { css } from '@emotion/react';
import styled from '@emotion/styled';

const AccordionTab = styled.div<{
  isBordered?: boolean;
}>`
  padding: 16px 8px;
  border-bottom: 1px solid;
  border-color: transparent;
  ${({ isBordered, theme }) =>
    isBordered &&
    css`
      border-color: ${theme.colors.dark['05']};
    `}
`;

const AccordionHeader = styled.button`
  all: unset;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
`;
const AccordionContentWrapper = styled.div<{
  isVisible?: boolean;
  hasAnimation?: boolean;
}>`
  max-height: 0;
  overflow: hidden;
  ${(props: any) =>
    props.hasAnimation &&
    css`
      transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
    `}
  ${(props: any) =>
    props.isVisible &&
    css`
      max-height: 1000px;
      ${props.hasAnimation &&
      css`
        transition: max-height 1s ease-in-out;
      `}
    `}
`;

const AccordionContent = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  word-break: break-word;
`;

const ArrowWrapper = styled.div<{ isExpanded: boolean }>`
  border: none;
  background: none;
  padding: 0.6rem;
  outline: none;
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
  &:active {
    outline: none;
  }

  transform: ${({ isExpanded }) =>
    isExpanded ? 'rotate(0deg)' : 'rotate(180deg)'};
  transition: transform 0.2s ease-in-out;
`;

export {
  AccordionTab,
  AccordionHeader,
  AccordionContentWrapper,
  AccordionContent,
  ArrowWrapper,
};
