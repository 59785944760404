import * as React from 'react';
import type { SVGProps } from 'react';
const SvgExpand = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M5 14.998.625 19.373M19.375 5.623v-5h-5M.625 14.373v5h5M19.375.623 15 4.998M15 14.998l4.375 4.375M.625 5.623v-5h5M19.375 14.373v5h-5M.625.623 5 4.998M6.875 6.873h6.25v6.25h-6.25Z"
      />
    </g>
  </svg>
);
export default SvgExpand;
