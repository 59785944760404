import * as React from 'react';
import type { SVGProps } from 'react';
const SvgVideoDone = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M9.375 14.375a5 5 0 1 0 10 0 5 5 0 0 0-10 0"
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="m16.603 12.922-2.42 3.227a.628.628 0 0 1-.943.068l-1.25-1.25M6.875 16.875h-5a1.25 1.25 0 0 1-1.25-1.25V1.875a1.25 1.25 0 0 1 1.25-1.25h8.858c.33 0 .649.132.883.366l2.393 2.393c.234.235.366.552.366.884v2.607"
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M5.2 10.565a.57.57 0 0 1-.825-.51v-3.86a.57.57 0 0 1 .826-.51l3.859 1.93a.57.57 0 0 1 0 1.02z"
    />
  </svg>
);
export default SvgVideoDone;
