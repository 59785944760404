import * as React from 'react';
import type { SVGProps } from 'react';
const SvgView = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M10 4.376C6.64 4.319 3.167 6.666.983 9.07a1.386 1.386 0 0 0 0 1.855c2.136 2.353 5.6 4.755 9.017 4.697 3.417.058 6.882-2.344 9.02-4.697a1.386 1.386 0 0 0 0-1.855C16.833 6.667 13.36 4.319 10 4.376"
      />
      <path
        strokeWidth={1.249995}
        d="M13.125 10A3.125 3.125 0 1 1 10 6.874 3.124 3.124 0 0 1 13.125 10"
      />
    </g>
  </svg>
);
export default SvgView;
