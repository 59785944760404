import { DEFAULT_REGION } from '@innovamat/localization';
import { getUserRegion } from '../store/auth/selectors';
import useAppSelector from './useAppSelector';
import useQueryParams from './useQueryParams';

export default function useGetUserRegion(): string {
  const userRegion = useAppSelector(getUserRegion);
  const query = useQueryParams();

  const getRegion = (): string => {
    const queryRegion = query.get('r') || query.get('region');

    if (queryRegion) return queryRegion;
    return userRegion || DEFAULT_REGION;
  };

  const region = getRegion();

  return region;
}
