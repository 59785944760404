import * as React from 'react';
import type { SVGProps } from 'react';
const SvgArrowUp2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={15}
    fill="none"
    {...props}
  >
    <path
      fill="#CCC"
      d="M11.048 2.955c-.466-.606-1.63-.606-2.096 0l-6.288 8.182c-.466.605.116 1.363 1.048 1.363h12.576c.932 0 1.514-.758 1.048-1.364z"
    />
  </svg>
);
export default SvgArrowUp2;
