import styled from '@emotion/styled';
import {
  Bar,
  Typography,
  useGlimmerTheme,
} from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  gap: 24px;
`;

const Percentage = styled(Typography.Body1)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

const Footer = styled.footer`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

type Props = {
  percentage: number;
};

function ProgressBar({ percentage }: Props): JSX.Element {
  const { tokens } = useGlimmerTheme();
  const color = tokens.color.global.teal.teal500.value;
  const { t } = useTranslation();

  const description =
    percentage === 100
      ? t('students.import.progress.reviewed-incidents')
      : t('students.import.progress.reviewing-process');

  return (
    <>
      <Container>
        <Header>
          {percentage === 100 ? (
            <Typography.H4 role="heading">
              {t('students.import.progress.reviewing-completed')}
            </Typography.H4>
          ) : (
            <>
              <Typography.Body1 role="heading">
                {t('students.import.progress.reviewing-students')}
              </Typography.Body1>
              <Percentage>{percentage}%</Percentage>
            </>
          )}
        </Header>
        {percentage !== 100 && (
          <Bar width={'100%'} colors={[color]} values={[percentage]} />
        )}
        <Footer>
          {percentage === 100 ? (
            <Typography.Body1>{description}</Typography.Body1>
          ) : (
            <Typography.Body2>{description}</Typography.Body2>
          )}
        </Footer>
      </Container>
    </>
  );
}

export { ProgressBar };
