import styled from '@emotion/styled';
import { CardSelect, getTypography } from '@innovamat/glimmer-components';

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 8px;
`;

export const OnboardingCardSelect = styled(CardSelect)`
  ${({ theme }) => getTypography(theme, 'Subtitle 1')}
  padding: 20px 16px 20px 16px;
`;
