import { css } from '@emotion/react';
import type { SerializedStyles } from '@emotion/react';

const parseMotionString = (motionString: string) => {
  const match = motionString.match(/\(([^)]+)\)/);
  if (!match) throw new Error('Invalid motion string format');
  const [c1, c2, c3, c4, duration] = match[1].split(',').map(Number);
  return { c1, c2, c3, c4, duration };
};

export const getMotion = (motionString: string): SerializedStyles => {
  const { c1, c2, c3, c4, duration } = parseMotionString(motionString);
  return css`
    transition-timing-function: cubic-bezier(${c1}, ${c2}, ${c3}, ${c4});
    transition-duration: ${duration}ms;
  `;
};
