import {
  getAppletReadableName,
  getFixedLocalizedScore,
  isNullOrUndefined,
} from '../../utils/common';
import {
  Tooltip,
  Typography,
  Icon,
  theme,
} from '@innovamat/glimmer-components';
import { ContentApplet } from '@innovamat/apollo-codegen';
import { getReportsTypography } from '../../utils/typography';
import styled from '@emotion/styled';

type Props = {
  timeApplets: number;
  appletsImproved: number;
  appletsAttemptedCount: number;
  tooltipTrainingZone: ContentApplet[];
  dataTestId?: string;
};

const TrainingZoneWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  cursor: default;
`;

const TimeContainer = styled.div`
  ${getReportsTypography()}
  max-width: 50px;
  min-width: 50px;
`;

const Minutes = styled(Typography.Subtitle2)`
  display: inline;
  margin-left: 1px;
`;

const AppletsMetricsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  max-width: 60px;
  min-width: 60px;
  gap: 4px;

  ${getReportsTypography()}
`;

const AppletsMetrics = styled.div`
  margin-left: -4px;
  height: 24px;
`;

const IconWrapper = styled.div`
  height: 19px;
`;

const IconStyle = styled(Icon)`
  transform: rotate(90deg);

  :hover {
    animation: arrow 1s ease-in-out infinite;
  }
  @keyframes arrow {
    0%,
    100% {
      transform: rotate(90deg) translate(2px);
    }
    50% {
      transform: rotate(90deg) translate(0px);
    }
  }
`;

function TrainingZone({
  timeApplets,
  appletsImproved,
  appletsAttemptedCount,
  tooltipTrainingZone,
  dataTestId,
}: Props): JSX.Element {
  const showMin = (): string => {
    const minutes =
      isNullOrUndefined(timeApplets) || timeApplets === 0 ? '' : 'min';
    return minutes;
  };

  const tooltipValues = (): string => {
    let tooltipValues = '';
    tooltipTrainingZone.forEach((applet) => {
      tooltipValues += `${getAppletReadableName(
        applet.data?.name,
        applet.sceneName,
        applet.pack
      )}: ${getFixedLocalizedScore(applet!.score!)} \n`;
    });
    return tooltipValues;
  };

  return (
    <TrainingZoneWrapper data-testid={dataTestId}>
      {!!appletsAttemptedCount && (
        <Tooltip
          content={`${Math.ceil(timeApplets / 60)} min`}
          popperOptions={{ strategy: 'fixed' }}
        >
          <TimeContainer>
            {Math.ceil(timeApplets / 60)}
            <Minutes>{showMin()}</Minutes>
          </TimeContainer>
        </Tooltip>
      )}
      {!!appletsAttemptedCount && (
        <Tooltip
          content={
            <div style={{ whiteSpace: 'pre-line' }}>{tooltipValues()}</div>
          }
          popperOptions={{ strategy: 'fixed' }}
        >
          <AppletsMetricsContainer>
            <IconWrapper>
              <IconStyle
                icon="BackIcon"
                size="M"
                iconColor={theme.tokens.color.global.green.green700.value}
              />
            </IconWrapper>
            <AppletsMetrics>
              {`${appletsImproved}`}
              <Typography.Subtitle2
                style={{ display: 'inline' }}
              >{`/${appletsAttemptedCount}`}</Typography.Subtitle2>
            </AppletsMetrics>
          </AppletsMetricsContainer>
        </Tooltip>
      )}
    </TrainingZoneWrapper>
  );
}

export default TrainingZone;
