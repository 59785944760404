import { useEffect, useState } from 'react';
import { LottieAnimation, Typography } from '@innovamat/glimmer-components';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

const Text = styled(Typography.Body1)`
  margin-top: -96px;
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

const ReportsLoader = (): JSX.Element => {
  const { t } = useTranslation();
  const [animationData, setAnimationData] = useState<any>(null);

  useEffect(() => {
    // Dynamically import the animation JSON from the given path
    import('./reports-loader.json')
      .then((data) => {
        setAnimationData(data.default);
      })
      .catch((error) => console.error('Error loading animation data:', error));
  }, []);

  return (
    <Container>
      <LottieAnimation
        animationData={animationData}
        width={500}
        height={500}
        loop
        autoplay
      />
      <Text>{t('reports.admin.loading', 'hola')}</Text>
    </Container>
  );
};

export default ReportsLoader;
