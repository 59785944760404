import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAddCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M10 6.25v7.5M6.25 10h7.5M.625 10a9.375 9.375 0 1 0 18.75 0 9.375 9.375 0 1 0-18.75 0"
      />
    </g>
  </svg>
);
export default SvgAddCircle;
