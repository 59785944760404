import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../../store/root/root.interfaces';
import { actionHide403Modal } from '../../../store/unityData/unityData.actions';
import { useTranslation } from 'react-i18next';
import { ModalContent, Subtitle, Title } from './Unity403Modal.styled';

const Unity403Modal = () => {
  const dispatch = useDispatch();
  const {
    unityData: { show403Modal },
  } = useSelector(({ uData }: AppState) => ({
    unityData: uData,
  }));

  const handleClose = () => {
    dispatch(actionHide403Modal());
  };

  const handleSubmit = () => {
    const classroomManager = process.env.NX_CLASSROOM_MANAGER;
    dispatch(actionHide403Modal());
    window.location.href = classroomManager || 'https://app.innovamat.com';
  };

  const { t } = useTranslation();

  return (
    <>
      {show403Modal && (
        <ModalContent
          width="60%"
          size="auto"
          open={show403Modal}
          onClose={handleClose}
          onSubmit={handleSubmit}
          showCancelBtn={false}
          submitText={t('platform.403.error.button.text', 'OK')}
        >
          <Title>
            {t('platform.403.error.title', 'Your account is not yet activated')}
          </Title>
          <Subtitle>
            {t(
              'platform.403.error.subtitle',
              "Please check your email to complete the registration. If you've already done it, wait a few minutes and try logging in again."
            )}
          </Subtitle>
        </ModalContent>
      )}
    </>
  );
};
export default Unity403Modal;
