import { theme } from '@innovamat/glimmer-components';
import { isNullOrUndefined } from './common';
import { scoreLevels } from './common';

type GetScoreColorOptions = {
  useNoDataToken?: boolean;
  isNotRepresentativeScore?: boolean;
};
const baseTokenReport = theme.tokens.color.specific.reports;

export const getScoreColor = (
  score?: number | null,
  options: GetScoreColorOptions = {}
): string => {
  const { useNoDataToken = false, isNotRepresentativeScore = false } = options;
  if (isNullOrUndefined(score)) {
    return useNoDataToken
      ? baseTokenReport.semantic['no-data'].value
      : baseTokenReport.tables.cell['bg-empty'].value;
  }

  if (isNotRepresentativeScore) {
    return baseTokenReport.semantic['no-data'].value;
  }

  const ceilScore = parseFloat(score!.toFixed(1));

  if (ceilScore < scoreLevels.LOW_SCORE) {
    return baseTokenReport.semantic.low.value;
  }
  if (ceilScore < scoreLevels.MEDIUM_SCORE) {
    return baseTokenReport.semantic.medium.value;
  }
  if (ceilScore < scoreLevels.MEDIUM_HIGH_SCORE) {
    return baseTokenReport.semantic.high.value;
  }
  return baseTokenReport.semantic['super-high'].value;
};
