import styled from '@emotion/styled';

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Column = styled.div`
  display: flex;
`;

const StyledRadioButton = styled.div<{ isDisabled: boolean }>`
  * {
    cursor: pointer;
    user-select: none;
    opacity: ${({ isDisabled }) => (isDisabled ? 0.8 : 1)};
  }
  input[type='radio'] {
    accent-color: ${({ theme }) => theme.colors.semantic['01']};
  }
`;

export { Row, Column, StyledRadioButton };
