import { css } from '@emotion/react';
import { theme } from '@innovamat/glimmer-components';

export const withBreakpoint = (children: any) => {
  return css`
    @media (max-width: ${theme.breakpoints.lg}) {
      ${children}
    }
  `;
};
