import { useEffect } from 'react';
import { ButtonV2 as Button, InputField } from '@innovamat/innova-components';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-domv6';
import SignInButton from '../SignInButton';
import { useIsAuthenticated } from '@azure/msal-react';
import LoginWrapper from '../LoginWrapper';
import { initializeElectronData, isElectronApp } from '../../utils/electronApp';
import msbutton from '../../assets/svg/microsoft.svg';
import googleButton from '../../assets/svg/google.svg';
import useLogin from './useLogin';
import {
  Footer,
  SeparatorWrapper,
  Separator,
  StyledLink,
  SignInWrapper,
  Form,
  KnowMore,
} from './Login.styled';
import { AutoLoginGoogleButton } from '@innovamat/social-login';

function Login(): JSX.Element {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const isAuthenticated = useIsAuthenticated();
  useEffect(() => {
    // NOTE: isAuthenticated is only for check if is Microsoft account
    if (!isAuthenticated && isElectronApp()) {
      // TODO: Should be refactored some how
      // eslint-disable-next-line no-lonely-if
      initializeElectronData(dispatch);
    }
  }, [isAuthenticated]);

  const {
    handleSetCredentials,
    onLogin,
    credentials,
    notification,
    showPassword,
    isLoading,
    displayError,
    handleDisplayError,
    isValid,
  } = useLogin();

  const { username, password } = credentials;

  const handleLogin = (e: React.FormEvent): void => {
    e.preventDefault();
    onLogin({ type: 'users', payload: { username, password } });
  };

  const onMsalLogin = (): void => {
    onLogin({ type: 'msal' });
  };

  const onGoogleLogin = (): void => {
    onLogin({ type: 'google' });
  };

  const isSuccess = window.location.search.includes('success=true');

  const handleFocus = (): void => {
    handleDisplayError(false);
  };

  return (
    <>
      <LoginWrapper
        title={t('Login.Accedeix a la App')}
        isSuccess={isSuccess}
        notification={notification}
      >
        <>
          <Form onSubmit={handleLogin} displayError={displayError}>
            <InputField
              label={t('Login.Nom d’usuari o email')}
              required
              placeholder={t('Login.Nom d’usuari o email')}
              onChange={(e) => handleSetCredentials(e.target.value, 'username')}
              onFocus={handleFocus}
              value={username}
            />
            <InputField
              label={t('Login.Contrassenya')}
              required
              type={showPassword ? 'text' : 'password'}
              value={password}
              placeholder={t('Login.Contrassenya')}
              onChange={(e) => handleSetCredentials(e.target.value, 'password')}
              description={t(
                'Login.password.descriptionLength',
                'Mínimo 6 caracteres, combinando letras y números.'
              )}
              onFocus={handleFocus}
            />
            <Button
              type="submit"
              disabled={isValid()}
              onClick={handleLogin}
              loading={isLoading}
            >
              <strong>{t('Login.Accedir')}</strong>
            </Button>
          </Form>

          <Footer>
            <Link to={`/${i18n.language}/forgot-password`} />
            <StyledLink
              href={`http://app.innovamat.com/${i18n.language}/forgot-password`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('Login.Has oblidat la contrassenya?')}
            </StyledLink>
            <SeparatorWrapper>
              <Separator />
              <span>{t('Login.msal.or')}</span>
              <Separator />
            </SeparatorWrapper>
            <SignInWrapper>
              <AutoLoginGoogleButton />

              <SignInButton
                img={googleButton}
                text={t('Login.google.button', 'Inicia sesión con Google')}
                onClick={onGoogleLogin}
              />
              <SignInButton
                img={msbutton}
                text={t('Login.msal.button', 'Inicia sesión con Microsoft')}
                onClick={onMsalLogin}
              />
            </SignInWrapper>
            <KnowMore>
              <span>{t('Login.knowMoreAboutInnovamat')}</span>
              <StyledLink
                href="http://www.innovamat.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('Login.clickHere')}
              </StyledLink>
            </KnowMore>
          </Footer>
        </>
      </LoginWrapper>
    </>
  );
}

export default Login;
