import styled from '@emotion/styled';
import { MenuItem, Typography, useDevice } from '@innovamat/glimmer-components';
import { IconType } from '@innovamat/glimmer-icons';
import { useMySettings } from '../providers/my-settings-provider';
import { useTranslation } from 'react-i18next';
import { SettingsTabType } from '../types';

const LateralMenu = styled.div`
  padding-top: 24px;
  padding-right: 32px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding-right: 0;
    width: 100%;
  }
`;

const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const Title = styled(Typography.Body1)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

const getMenuItems = (isLeadOrParent?: boolean): MenuItem[] => [
  {
    icon: 'UserIcon',
    key: 'PersonalData',
  },
  {
    icon: 'PasswordIcon',
    key: 'Password',
  },
  {
    icon: 'MailIcon',
    key: 'Email',
    hidden: isLeadOrParent,
  },
  {
    icon: 'DataIcon',
    key: 'SchoolData',
    hidden: isLeadOrParent,
  },
  {
    icon: 'LanguageIcon',
    key: 'Language',
  },
];

type MenuItem = {
  icon: IconType;
  key: SettingsTabType;
  hidden?: boolean;
};

export function MySettingsMenu(): JSX.Element {
  const { t } = useTranslation();
  const {
    changeActiveTab,
    isTabActive,
    getTitle,
    showLanguageSelector,
    isLeadOrParent,
  } = useMySettings();
  const { isMobile } = useDevice();

  return (
    <LateralMenu>
      <Title>{t('settings')}</Title>
      <TabsWrapper>
        {getMenuItems(isLeadOrParent).map((item) => {
          if (item.key === 'Language' && !showLanguageSelector) {
            return null;
          }

          if (item.hidden) {
            return null;
          }

          return (
            <MenuItem
              icon={item.icon}
              text={getTitle(item.key)}
              fill
              state={!isMobile && isTabActive(item.key) ? 'selected' : 'active'}
              onClick={() => changeActiveTab(item.key)}
              rightIcon={isMobile ? 'NextIcon' : undefined}
              key={item.key}
              size="L"
            />
          );
        })}
      </TabsWrapper>
    </LateralMenu>
  );
}
