
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as CbNumbersOperationsIconComponent } from "./cb-numbers-operations-icon.svg";

export const CbNumbersOperationsIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <CbNumbersOperationsIconComponent {...props} />;
};
