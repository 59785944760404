import styled from '@emotion/styled';
import type { GoogleClassroom } from '@innovamat/ga-features';
import { Checkbox, Select } from '@innovamat/glimmer-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Label = styled.p<{ isDisabled?: boolean; hasFailed?: boolean }>`
  display: flex;
  align-items: center;
  width: 80%;

  color: ${({ theme, isDisabled, hasFailed }) =>
    isDisabled
      ? theme.colors.dark['03']
      : hasFailed
      ? theme.colors.semantic['03']
      : theme.colors.dark['01']};
`;

type Props = {
  checked: boolean;
  classroom: GoogleClassroom;
  onCheckClassroom: (
    classroom: GoogleClassroom,
    course: string | undefined
  ) => void;
  onSelectCourse: (option: any, classroom: GoogleClassroom) => void;
  courses: any[];
  importingHasFailed?: boolean;
  disabled?: boolean;
};

export default function ClassroomItem({
  checked,
  classroom,
  onCheckClassroom,
  onSelectCourse,
  courses,
  importingHasFailed,
  disabled,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [course, setCourse] = useState<string | undefined>(undefined);

  if (classroom.imported) {
    return (
      <tr>
        <td data-testid={`${classroom.google_id}-row`} className="firstCol">
          <Checkbox
            id={`${classroom.google_id}-checkbox`}
            data-testid={`${classroom.google_id}-checkbox`}
            checked={true}
            disabled={true}
          />
          <Label isDisabled={true}>{classroom.name}</Label>
        </td>
        <td className="secondCol">
          <Label isDisabled={!!classroom.imported}>
            {classroom.course?.name}
          </Label>
        </td>
      </tr>
    );
  }
  if (importingHasFailed) {
    return (
      <tr>
        <td data-testid={`${classroom.google_id}-row`} className="firstCol">
          <Label hasFailed>{classroom.name}</Label>
        </td>
        <td className="secondCol">
          <Label hasFailed>{classroom.course?.name || '-'}</Label>
        </td>
      </tr>
    );
  }

  return (
    <tr>
      <td data-testid={`${classroom.google_id}-row`} className="firstCol">
        <Checkbox
          id={`${classroom.google_id}-checkbox`}
          data-testid={`${classroom.google_id}-checkbox`}
          disabled={disabled}
          checked={checked}
          onChange={() => {
            if (!checked) setCourse(course || courses[0].value);
            onCheckClassroom(classroom, course);
          }}
        />
        <Label isDisabled={disabled}>{classroom.name}</Label>
      </td>
      <td className="secondCol">
        <Select
          menuPortalTarget={document.body}
          placeholder={t('modal.connect.class.pick_course', 'Elige tu curso')}
          options={courses}
          value={course}
          status={!checked ? 'disabled' : undefined}
          onChange={(option) => {
            setCourse(option!.value);
            onSelectCourse(option!, classroom);
          }}
        />
      </td>
    </tr>
  );
}
