import { Icon } from '@innovamat/glimmer-components';
import styled from '@emotion/styled';
import { usePartColors } from '../../hooks/usePartColors';
import { PreprInnerHtml } from '../PreprInnerHtml';
import { ContentBlock, PartType } from '../../types/SmartGuide';
import { IconType } from '@innovamat/glimmer-icons';
import { useSmartGuides } from '../../context/SmartGuidesProvider';
import {
  PrintableBody2,
  PrintableSubtitle1,
  PrintableSubtitle2,
} from '../../printable-styles/printable-typography';

const Title = styled(PrintableSubtitle2)`
  color: ${({ theme }) => theme.tokens.color.alias.cm.text.text.value};

  @media print {
    color: rgba(0, 0, 0, 0.65);
  }
`;

const Text = styled(PrintableBody2)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

const IconContainer = styled.div`
  width: 24px;
  min-width: 24px;
  display: grid;
  place-items: center;
  margin: 8px 4px 0 0;

  @media print {
    margin: 2px 2px 0 0;
  }
`;

const ContentBlockContainer = styled.div`
  display: flex;
  align-items: flex-start;

  max-width: 100%;

  @media print {
    break-inside: avoid;
  }
`;

const ContentBlockText = styled.div`
  padding: 8px 16px;
  border-left: 1px solid
    ${({ theme }) => theme.tokens.color.alias.cm.text.text.value};

  display: flex;
  flex-direction: column;
  gap: 8px;

  @media print {
    gap: 4px;
    padding: 4px 8px;
    color: #000000;
  }
`;

type Props = {
  contentBlocks: ContentBlock[];
  part: PartType;
};

export function ContentBlocks({ contentBlocks, part }: Props): JSX.Element {
  const { getElement } = usePartColors();
  const { t } = useSmartGuides();

  return (
    <>
      <PrintableSubtitle1 color={getElement(part)}>
        {t('digitalguides.blocks.title')}
      </PrintableSubtitle1>

      {contentBlocks?.map((contentBlock: ContentBlock, index) => {
        return (
          <ContentBlockContainer key={index}>
            <IconContainer>
              <Icon size="M" icon={contentBlock.icon as IconType} />
            </IconContainer>
            <ContentBlockText>
              <Title>{t(contentBlock.title)}</Title>
              <Text>
                <PreprInnerHtml text={contentBlock.text} />
              </Text>
            </ContentBlockText>
          </ContentBlockContainer>
        );
      })}
    </>
  );
}
