import { SVGProps } from 'react';
export const TeacherIcon_Legacy = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg height={20} width={20} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        fill="none"
        stroke="#757575"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      >
        <path
          d="M1.875 3.125a2.5 2.5 0 105 0 2.5 2.5 0 10-5 0zM4.375 6.875v5M6.25 19.375l.625-6.25h1.25v-2.5a3.75 3.75 0 00-7.5 0v2.5h1.25l.625 6.25zM10.625 13.75h7.5a1.25 1.25 0 001.25-1.25V1.875a1.25 1.25 0 00-1.25-1.25h-8.75"
          strokeWidth={1.249995}
        />
      </g>
    </svg>
  );
};
