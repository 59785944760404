import styled from '@emotion/styled';
import { createPortal } from 'react-dom';

const FullscreenContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 30;
  width: 100vw;
  height: 100vh;
  background-color: #000;
`;

type Props = {
  isInIOSFullscreen: boolean;
  children: React.ReactNode;
};

export function IOSFullScreen({ isInIOSFullscreen, children }: Props) {
  if (!isInIOSFullscreen) return <>{children}</> || null;
  return createPortal(
    <FullscreenContainer>{children}</FullscreenContainer>,
    document.body
  );
}
