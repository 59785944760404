
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as TrainingIconComponent } from "./training-icon.svg";

export const TrainingIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <TrainingIconComponent {...props} />;
};
