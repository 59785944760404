import { createGlobalStyle, css } from 'styled-components';
import { ThemeType, theme as innovaTheme } from '@innovamat/innova-components';

const fonts = {
  roboto: `'Roboto', sans-serif`,
  robotoMedium: `'Roboto Medium', sans-serif`,
  rubik: `'Rubik', sans-serif`,
  bariol: `'Bariol', sans-serif`,
  bariolBold: `'Bariol Bold', sans-serif`,
};

const colors = {
  starYellow: '#efc513',
  pearlWhite: '#f4fefe',
  mintGray: '#a3b7b5',
  brandLight: '#16c6ac',
  brandDark: '#129e89',
  greenDark: '#00524c',
  bgSpinner: '#18c6ac',
  navyBlue: '#192f2d',
};

const sizes = {
  xxsmall: '11px',
};

const mixedColors = {
  ...innovaTheme.colors,
  ...colors,
};

const mixedSizes = {
  ...innovaTheme.sizes,
  ...sizes,
};

const appHeight = 'calc(var(--vh, 1vh) * 100)';

const typography = {
  b2: css`
    font-family: ${fonts.roboto};
    font-size: ${mixedSizes.small};
    line-height: 20px;
    font-weight: 400;
  `,
  b3: css`
    font-family: ${fonts.roboto};
    font-size: ${mixedSizes.xsmall};
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
  `,
  h5: css`
    font-family: ${fonts.robotoMedium};
    font-size: ${mixedSizes.normal};
    font-weight: 500;
    line-height: 20px;
  `,
  h6: css`
    font-family: ${fonts.robotoMedium};
    font-size: ${mixedSizes.small};
    font-weight: 500;
    line-height: 16px;
  `,
  h7: css`
    font-family: ${fonts.robotoMedium};
    font-size: ${mixedSizes.xsmall};
    font-weight: 500;
    line-height: 14px;
  `,
};

export interface StyleClosetTheme extends ThemeType {
  fonts: typeof fonts;
  colors: typeof mixedColors;
  sizes: typeof mixedSizes;
  htmlTag: typeof typography;
  appHeight: typeof appHeight;
}

const theme: StyleClosetTheme = {
  ...innovaTheme,
  htmlTag: typography,
  fonts,
  colors: mixedColors,
  sizes: mixedSizes,
  appHeight,
};

export const GlobalStyle = createGlobalStyle`
  h6{
    ${theme.htmlTag.h6}
  }
`;

export { theme };
