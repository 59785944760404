import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMailSend = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
    <g
      fill="none"
      stroke="#333"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M9.374 14.377a5 5 0 1 0 10 0 5 5 0 1 0-10 0M11.874 14.377h5M16.874 14.377 15 16.252M16.874 14.377 15 12.502"
      />
      <path
        strokeWidth={1.249995}
        d="M6.874 13.127h-5a1.25 1.25 0 0 1-1.25-1.25v-10a1.25 1.25 0 0 1 1.25-1.25h15a1.25 1.25 0 0 1 1.25 1.25V7.5"
      />
      <path
        strokeWidth={1.249995}
        d="m17.84 1.083-6.786 5.222a2.756 2.756 0 0 1-3.36 0L.906 1.083"
      />
    </g>
  </svg>
);
export default SvgMailSend;
