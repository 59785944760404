import React from 'react';
import { BadgeProps } from './Badge.types';
import { BadgeWrapper, DefaultWrapper } from './Badge.styles';

function Badge({ children, ...props }: BadgeProps) {
  if (!children) return <DefaultWrapper />;
  return <BadgeWrapper {...props}>{children}</BadgeWrapper>;
}

export default Badge;
