import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLargeLeftArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M18 10.765H2M5.765 14.53 2 10.764 5.765 7"
    />
  </svg>
);
export default SvgLargeLeftArrow;
