import ca from './ca.json';
import en_us from './en-us.json';
import en from './en.json';
import es_la from './es-la.json';
import es_mx from './es-mx.json';
import es from './es.json';
import eu from './eu.json';
import fr from './fr.json';
import it from './it.json';
import pt_br from './pt-br.json';
import va_es from './va-es.json';

export const webappTranslations = {
  ca,
  en_us,
  en,
  es_la,
  es_mx,
  es,
  eu,
  fr,
  it,
  pt_br,
  va_es
};
