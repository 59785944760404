
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as FileCsvIconComponent } from "./file-csv-icon.svg";

export const FileCsvIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <FileCsvIconComponent {...props} />;
};
