import React from 'react';
import Tippy, { TippyProps } from '@tippyjs/react';

import 'tippy.js/dist/tippy.css';

type Props = TippyProps & {
  children: React.ReactNode;
};

export default function Tooltip({ children, ...rest }: Props) {
  return <Tippy {...rest}>{children}</Tippy>;
}
