import { useEffect, useState } from 'react';
import background from '../../assets/secundariaBackgrounds/summer.jpg';
import character from '../../assets/secundariaCharacter/summer.png';
import { ImageComponent } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { normalizeImgSource } from '../../..';

function VacationMode() {
  const { t } = useTranslation();

  const [triggerCharacterAnimation, setTriggerCharacterAnimation] =
    useState(false);

  const [triggerContainerAnimation, setTriggerContainerAnimation] =
    useState(false);

  useEffect(() => {
    const characterTimeout = setTimeout(() => {
      setTriggerCharacterAnimation(true);
    }, 1000);

    const containerTimeout = setTimeout(() => {
      setTriggerContainerAnimation(true);
    }, 2000);

    return () => {
      clearTimeout(characterTimeout);
      clearTimeout(containerTimeout);
    };
  }, []);

  return (
    <Background background={normalizeImgSource(background)}>
      <Relative>
        <Character
          src={normalizeImgSource(character)}
          alt="character"
          triggerCharacterAnimation={triggerCharacterAnimation}
        />
        <Container triggerContainerAnimation={triggerContainerAnimation}>
          <Dialog>
            <Content>
              <TextTitle>
                {t('weeklyPractice.vacation.summer.title')}
              </TextTitle>
              <Text>{t('weeklyPractice.vacation.summer.text')}</Text>
            </Content>
          </Dialog>
        </Container>
      </Relative>
    </Background>
  );
}

export default VacationMode;

const Relative = styled.div`
  position: relative;
`;

const Dialog = styled.div`
  display: inline-flex;
  border-radius: 12px;
  background: ${(props) => props.theme.colors.white};
  transform: skewX(-10deg);
  backdrop-filter: blur(4px);
`;

const Background = styled.div<{ background: string }>`
  overflow: hidden;
  background-position: center;
  background-size: cover;
  border-radius: 8px;
  margin-top: 16px;
  padding: 127px 0;
  background-image: ${(props) =>
    `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('${props.background}')`};
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    padding: 100px 0;
  }
`;

const Container = styled.div<{ triggerContainerAnimation: boolean }>`
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  margin-right: 384px;
  left: 2000px;
  ${(props) =>
    props.triggerContainerAnimation &&
    css`
      left: 0px;
    `}
  transition: left 1s ease-in;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-right: 300px;
  }
`;

const TextTitle = styled.div`
  margin-bottom: 24px;
  text-align: center;
  white-space: pre;
  font-size: 28px;
  font-weight: 700;
  font-family: ${(props) => props.theme.fonts.robotoMedium};
  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    font-size: 20px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: 18px;
  }
`;

const Text = styled.div`
  text-align: center;
  white-space: pre;
  font-size: 28px;
  font-weight: 400;
  font-family: ${(props) => props.theme.fonts.robotoMedium};
  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    font-size: 20px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: 18px;
  }
`;

const Content = styled.div`
  transform: skewX(10deg) !important;
  padding: 32px 128px 32px 32px;
`;

const Character = styled(ImageComponent)<{
  triggerCharacterAnimation: boolean;
}>`
  display: flex;
  position: absolute;
  overflow: hidden;
  right: 0px;
  bottom: -600px;
  ${(props) =>
    props.triggerCharacterAnimation &&
    css`
      bottom: -136px;
    `}
  transition: bottom 1s ease-in;
  z-index: 1;
  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    transform: scale(0.9);
    ${(props) =>
      props.triggerCharacterAnimation &&
      css`
        bottom: -160px;
      `}
  }
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    transform: scale(0.8);
    right: -40px;
    ${(props) =>
      props.triggerCharacterAnimation &&
      css`
        bottom: -178px;
      `}
  }
`;
