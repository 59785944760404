import { useRef, useState } from 'react';
import { Button } from '../Button';
import { Typography } from '../Typography';
import styled from '@emotion/styled';
import { theme } from '../../theme';
import { Icon } from '../Icon';

interface FileUploaderProps {
  uploadedFileDescription: string;
  buttonLabel: string;
  handleFile?: (file: File) => void;
  isDisabled?: boolean;
  restrictedFileTypes?: string;
  hasDragAndDrop?: boolean;
}

interface FileUploaderInputProps {
  filename: string;
  uploadedFileDescription: string;
  buttonLabel: string;
  isDisabled?: boolean;
  hiddenFileInput: React.RefObject<HTMLInputElement>;
  restrictedFileTypes?: string;
  handleClick?: () => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const UploadedFileWrapper = styled.button`
  all: unset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  width: fit-content;
  cursor: pointer;
`;

const FilenameWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const DragDropZone = styled.div`
  width: 100%;
  min-height: 180px;
  height: 100%;
  border: 2px dashed
    ${theme.tokens.color.alias.cm.border['border-subtle'].value};
  border-radius: 16px;
  cursor: pointer;
  display: grid;
  place-items: center;
`;

const Container = styled.div`
  width: 100%;
`;

const FileUploaderInput = ({
  filename,
  uploadedFileDescription,
  buttonLabel,
  isDisabled,
  hiddenFileInput,
  restrictedFileTypes,
  handleClick,
  handleChange,
}: FileUploaderInputProps) => {
  const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    handleClick?.();
  };
  return (
    <>
      {filename ? (
        <UploadedFileWrapper onClick={onClick}>
          <FilenameWrapper>
            <Icon
              icon="FileStandardIcon"
              iconColor={theme.tokens.color.alias.cm.text.text.value}
            />
            <Typography.Subtitle1>{filename}</Typography.Subtitle1>
          </FilenameWrapper>
          <Typography.Link2
            color={`${theme.tokens.color.alias.cm.text['text-subtle'].value} !important`}
          >
            {uploadedFileDescription}
          </Typography.Link2>
        </UploadedFileWrapper>
      ) : (
        <Button
          onClick={onClick}
          variant="secondary"
          loading={false}
          rightIcon="DownloadIcon"
          disabled={isDisabled}
        >
          {buttonLabel}
        </Button>
      )}
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
        accept={restrictedFileTypes || undefined}
        data-testid="file-input"
      />
    </>
  );
};

export const FileUploader = ({
  hasDragAndDrop,
  buttonLabel,
  uploadedFileDescription,
  handleFile,
  isDisabled,
  restrictedFileTypes,
}: FileUploaderProps) => {
  const [filename, setFilename] = useState<string | null>(null);
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (!hiddenFileInput.current) return;
    hiddenFileInput.current.click();
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event.target.files?.[0];
    if (fileUploaded) {
      setFilename(fileUploaded.name);
      handleFile?.(fileUploaded);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy';
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const fileUploaded = event.dataTransfer.files?.[0];
    if (fileUploaded) {
      setFilename(fileUploaded.name);
      handleFile?.(fileUploaded);
    }
  };

  if (hasDragAndDrop)
    return (
      <DragDropZone
        onClick={handleClick}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <FileUploaderInput
          filename={filename || ''}
          uploadedFileDescription={uploadedFileDescription}
          buttonLabel={buttonLabel}
          isDisabled={isDisabled}
          hiddenFileInput={hiddenFileInput}
          restrictedFileTypes={restrictedFileTypes}
          handleChange={handleChange}
        />
      </DragDropZone>
    );

  return (
    <Container>
      <FileUploaderInput
        filename={filename || ''}
        uploadedFileDescription={uploadedFileDescription}
        buttonLabel={buttonLabel}
        isDisabled={isDisabled}
        hiddenFileInput={hiddenFileInput}
        restrictedFileTypes={restrictedFileTypes}
        handleClick={handleClick}
        handleChange={handleChange}
      />
    </Container>
  );
};
