import React, { ComponentType, lazy, Suspense } from 'react';

const Lottie = lazy(() =>
  import('react-lottie-player').then((module) => ({
    default: module.default as ComponentType<any>,
  }))
);

type LottieAnimationProps = {
  animationData: any;
  width?: number;
  height?: number;
  loop?: boolean;
  autoplay?: boolean;
  goTo?: number;
  className?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

export function LottieAnimation({
  animationData,
  width,
  height,
  loop = true,
  autoplay = true,
  goTo,
  className,
}: LottieAnimationProps): JSX.Element {
  return (
    <Suspense fallback={<></>}>
      {animationData && (
        <Lottie
          className={className}
          animationData={animationData}
          rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
          loop={loop}
          play={autoplay}
          goTo={goTo}
          style={{ width: `${width}px`, height: `${height}px` }}
          data-testid="mock-lottie"
        />
      )}
    </Suspense>
  );
}
