import styled from '@emotion/styled';
import { useOnboardingForm } from '../context/onboarding-form-context';
import { Typography } from '@innovamat/glimmer-components';
import { OnboardingCardSelect } from '../onboarding-form.styled';
import { stages } from '@innovamat/radiance-utils';
import { useTranslation } from 'react-i18next';

type SelectCoursesStepProps = {
  isBeforeCourses?: boolean;
};

const CoursesContainer = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    gap: 32px;
    flex-direction: column;
  }
`;

const StageTitle = styled(Typography.Body1)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

const StageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export function SelectCoursesStep({
  isBeforeCourses,
}: SelectCoursesStepProps): JSX.Element {
  const { t } = useTranslation();
  const {
    courses,
    selectedActualCourses,
    setSelectedActualCourses,
    selectedBeforeCourses,
    setSelectedBeforeCourses,
  } = useOnboardingForm();

  const availableStages = Array.from(
    new Set(courses.map((course) => stages.getStageFromOrder(course?.order!)))
  );

  const handleClick = (courseOrder: number): void => {
    if (isBeforeCourses) {
      setSelectedBeforeCourses((prev: number[]) => {
        const isSelected = prev.includes(courseOrder);
        return isSelected
          ? prev.filter((c) => c !== courseOrder)
          : [...prev, courseOrder];
      });
    } else {
      setSelectedActualCourses((prev: number[]) => {
        const isSelected = prev.includes(courseOrder);
        return isSelected
          ? prev.filter((c) => c !== courseOrder)
          : [...prev, courseOrder];
      });
    }
  };

  const getCardStatus = (courseOrder: number): 'selected' | 'default' => {
    if (isBeforeCourses) {
      return selectedBeforeCourses.includes(courseOrder)
        ? 'selected'
        : 'default';
    } else {
      return selectedActualCourses.includes(courseOrder)
        ? 'selected'
        : 'default';
    }
  };

  return (
    <CoursesContainer>
      {availableStages.map((stage) => (
        <StageContainer key={stage}>
          <StageTitle>
            {t(`common.stage-${stage?.toLocaleLowerCase()}`)}
          </StageTitle>
          {courses
            .filter(
              (course) => stages.getStageFromOrder(course?.order!) === stage
            )
            .map((course, index) => (
              <OnboardingCardSelect
                key={index}
                status={getCardStatus(course.order!)}
                onClick={() => handleClick(course.order!)}
              >
                {course?.name}
              </OnboardingCardSelect>
            ))}
        </StageContainer>
      ))}
    </CoursesContainer>
  );
}
