
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as GoogleDriveIconComponent } from "./google-drive-icon.svg";

export const GoogleDriveIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <GoogleDriveIconComponent {...props} />;
};
