import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Modal, Select, InputChips } from '@innovamat/glimmer-components';

import { snack } from '@innovamat/glimmer-components';

import { newId } from '@innovamat/radiance-utils';
import styled from '@emotion/styled';
import {
  GlowApiError,
  useInviteUsersMutation,
} from '@innovamat/glow-api-client';
import { useUser } from '../../../user-management';
import { useOrganization } from '../../hooks/use-organization';
import { useQueryClient } from '@tanstack/react-query';

const schema = yup.array().of(yup.string().email()).min(1);
const individualSchema = yup.string().email();

type Props = {
  showModal: boolean;
  onClose: () => void;
  classroomId: string;
  classroomName: string;
};

const EmailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
  width: 100%;
`;

const SelectWrapper = styled.div``;

const ModalContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const WrapperInput = styled.div`
  width: 100%;
`;

export function InviteStudentsModal({
  showModal,
  classroomId,
  classroomName,
  onClose,
}: Props): JSX.Element | null {
  const { t } = useTranslation();
  const { user } = useUser();
  const { organization, availableLanguages } = useOrganization();
  const [emails, setEmails] = useState<string[]>([]);
  const [isValid, setIsValid] = useState(false);
  const queryClient = useQueryClient();

  const [language, setLanguage] = useState<string | undefined>(
    organization?.language || undefined
  );

  const { mutate: mutateInviteUsers, isPending: loading } =
    useInviteUsersMutation<GlowApiError>();

  const handleSetLanguage = (value: string): void => {
    setLanguage(value);
  };

  const handleSend = (): void => {
    const invitedEmails = emails.map((email) => ({
      email,
      id: newId(),
      invitedId: newId(),
    }));
    const invitationsBody = {
      schoolId: user?.organizationId,
      operativeRegion: organization?.operativeRegion,
      inviterId: user?.id,
      invitedEmails,
      language,
      type: 'student',
      classrooms: [{ id: classroomId, name: classroomName }],
    };
    mutateInviteUsers(
      {
        invitations: invitationsBody as any,
      },
      {
        onSuccess: () => {
          snack.success(t('common.toast.success'));
          onClose();
        },
        onError: (error) => {
          let failedEmails: string[] | undefined;

          const errors = error.response.errors;
          if (errors) {
            onClose();
            errors.forEach((error) => {
              if (error.extensions?.exception?.['code'] === 'ROLE_CONFLICT') {
                failedEmails = error.extensions?.exception?.['emails'];
              }
            });

            failedEmails?.forEach((failedEmail) =>
              snack.warning(
                t('students.inviteModal.error.roleConflict', {
                  email: failedEmail,
                })
              )
            );
            if (!failedEmails) snack.error(t('common.toast.error'));
          }
        },
        onSettled: () => {
          queryClient.refetchQueries({ queryKey: ['Students'] });
        },
      }
    );
  };

  const handleUpdateMails = async (chips: string[]): Promise<void> => {
    setEmails(chips);
    const valid = await schema.isValid(chips);
    setIsValid(valid);
  };

  const handleIsChipInvalid = (chip: string): boolean => {
    return !individualSchema.isValidSync(chip);
  };

  if (!showModal) return null;

  return (
    <Modal
      isOpen={showModal}
      modalWidth={600}
      onClose={onClose}
      closeOnClickOutside
      title={t('students.inviteModal.title')}
      buttons={[
        {
          children: t('confirmModal.action.invite'),
          onClick: handleSend,
          loading: loading,
          variant: 'accent',
          disabled: !isValid,
        },
        {
          children: t('confirmModal.action.cancel'),
          onClick: onClose,
          variant: 'secondary',
        },
      ]}
    >
      <ModalContent>
        {t('common.pasteHereEmailsAndImport')}
        <EmailsWrapper>
          <WrapperInput>
            <InputChips
              error={
                emails.length > 0 && !isValid
                  ? {
                      name: 'Invalid Emails',
                      message: t('common.pasteValidEmails'),
                    }
                  : undefined
              }
              label={t('students.inviteModal.emails')}
              isChipInvalid={(chip) => handleIsChipInvalid(chip)}
              onUpdateChips={handleUpdateMails}
              canPasteEmails
              placeholder={t('students.inviteModal.emails.placeholder')}
            />
          </WrapperInput>
        </EmailsWrapper>
        <SelectWrapper>
          <Select
            labelText={t('students.inviteModal.language')}
            menuPortalTarget={document.body}
            placeholder={t('students.inviteModal.language')}
            options={availableLanguages || []}
            value={language}
            status={!emails.length ? 'disabled' : undefined}
            onChange={(option) => {
              handleSetLanguage(option!.value);
            }}
          />
        </SelectWrapper>
      </ModalContent>
    </Modal>
  );
}
