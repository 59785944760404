
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as PreviousIconComponent } from "./previous-icon.svg";

export const PreviousIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <PreviousIconComponent {...props} />;
};
