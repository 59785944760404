import { IconType } from '@innovamat/glimmer-icons';
import { Icon } from '../Icon';
import { getInitials } from './get-initials';
import {
  AvatarBadge,
  Image,
  AvatarWrapper,
  SemanticBorder,
} from './avatar.styled';
import { SemanticType, useSemantic } from '../../hooks/useSemantic';
import { Tooltip } from '../Tooltip';
import { Typography } from '../Typography';
import { StateLayer } from '../../utils/common.styled';
import { CSSProperties } from 'react';

type Options =
  | {
      img: string;
      alt?: string;
    }
  | {
      icon: IconType;
    }
  | {
      text: string;
    }
  | {
      children: JSX.Element;
    };

export type AvatarNotificationType = 'Info';

export type Size = 'S' | 'M' | 'L';

export const Sizes: Record<Size, number> = {
  S: 24,
  M: 32,
  L: 40,
};

export type AvatarBaseProps = {
  className?: string;
  hideBadge?: boolean;
  badge?: IconType;
  badgeColor?: string;
  badgeBgColor?: string;
  semantic?: SemanticType;
  tooltipText?: string;
  size?: Size;
  isDisabled?: boolean;
  styles?: CSSProperties;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
};

export type AvatarProps = AvatarBaseProps & Options;

export function Avatar(props: AvatarProps): JSX.Element {
  const { getIcon, getColor } = useSemantic();

  const {
    className,
    semantic,
    tooltipText,
    size = 'L',
    onClick,
    badge,
    badgeColor,
    badgeBgColor,
    isDisabled,
    hideBadge,
    styles,
  } = props;

  const sizeValue = Sizes[size];

  const getContent = (): JSX.Element => {
    if ('img' in props) {
      return (
        <Image
          semantic={semantic}
          src={props.img}
          alt={props.alt}
          size={sizeValue}
        />
      );
    }

    if ('icon' in props) {
      return <Icon icon={props.icon} />;
    }

    if ('text' in props) {
      const text = getInitials(props.text);

      if (size === 'S') {
        return <Typography.Body3>{text}</Typography.Body3>;
      }
      if (size === 'M') {
        return <Typography.Subtitle2>{text}</Typography.Subtitle2>;
      }
      return <Typography.Subtitle1>{text}</Typography.Subtitle1>;
    }

    return props.children;
  };

  return (
    <Tooltip content={tooltipText} popperOptions={{ strategy: 'fixed' }}>
      <AvatarWrapper
        hasText={'text' in props}
        className={className}
        semantic={semantic}
        bgColor={getColor(semantic)}
        size={sizeValue}
        onClick={onClick}
        isDisabled={isDisabled}
        style={styles}
      >
        <StateLayer className="avatar-state-layer" />

        {getContent()}

        {semantic && !badge && !hideBadge && (
          <AvatarBadge semantic={semantic} bgColor={getColor(semantic)}>
            <Icon icon={getIcon(semantic)} size="M" />
          </AvatarBadge>
        )}

        {semantic && <SemanticBorder bgColor={getColor(semantic)} />}

        {!semantic && badge && (
          <AvatarBadge bgColor={badgeBgColor}>
            <Icon icon={badge} size="M" iconColor={badgeColor} />
          </AvatarBadge>
        )}
      </AvatarWrapper>
    </Tooltip>
  );
}
