import PhoneInput from 'react-phone-input-2';
import './style.css';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import cx from 'classnames';
import { Typography, getTypography } from '../Typography';
import { getElevation } from '../elevation';
import Flags from './flags.png';

type Props = {
  onChange: (value: string, countryData: CountryData) => void;
  phone: string;
  error?: boolean;
  label?: string;
  description?: string;
  country?: string;
  required?: boolean;
  optionalText?: string;
  className?: string;
  dataTestId?: string;
  customStyles?: string;
};

type CountryData = {
  name: string;
  dialCode: string;
  countryCode: string;
};

const getUrl = () => {
  const FlagsImport = Flags as any;

  if (typeof FlagsImport !== 'string' && 'src' in FlagsImport) {
    return FlagsImport.src;
  }
  return FlagsImport;
};

const InputContainer = styled.div<{ customStyles?: string }>`
  .react-tel-input .flag {
    width: 16px;
    height: 11px;
    background-image: url(${getUrl()});
  }

  display: flex;
  flex-direction: column-reverse;
  gap: 6px;
  position: relative;

  .inputPhone {
    ${({ theme }) =>
      css`
        border: none;
        outline: 1px solid !important;
        outline-color: ${theme.tokens.color.alias.cm.border['border-default']
          .value} !important;
        border-radius: 4px !important;

        ${getTypography(theme, 'Body 1')}

        color: ${theme.tokens.color.alias.cm.text.text.value} !important;

        padding: 8px 8px 8px 48px !important;
        height: 40px !important;
        width: 100% !important;
        padding-left: 56px !important;

        :hover {
          outline-color: ${theme.tokens.color.alias.cm.border['border-default']
            .value} !important;
        }

        :focus {
          outline: 2px solid
            ${theme.tokens.color.alias.cm.border['border-default'].value} !important;
        }

        &.hasError {
          outline-color: ${theme.tokens.color.alias.cm.icon['icon-error']
            .value} !important;
        }
      `};
  }

  .countrySelector {
    ${({ theme }) =>
      css`
        border: none;
        border-right: 1px solid !important;
        border-radius: 4px 0px 0px 4px !important;
        background-color: ${theme.tokens.color.alias.cm.surface[
          'surface-secondary'
        ].value} !important;
        border-color: ${theme.tokens.color.alias.cm.border['border-default']
          .value} !important;
        display: flex;
        justify-content: center;
        padding: 4px;

        &:hover {
          background-color: ${theme.tokens.color.global.neutral.neutral200
            .value} !important;
        }

        &:active {
          background-color: ${theme.tokens.color.global.neutral.neutral300
            .value} !important;
        }

        &.hasError {
          border-right-color: ${theme.tokens.color.alias.cm.icon['icon-error']
            .value} !important;
        }
      `};

    .selected-flag {
      background-color: transparent !important;
    }
  }

  .countriesDropdown {
    border-radius: 4px !important;
    top: 28px;
    left: 0;

    ${({ theme }) => getElevation(theme, 'elevation 4')};
  }

  ${({ customStyles }) =>
    customStyles &&
    css`
      ${customStyles}
    `}
`;

const DescriptiveText = styled(Typography.Body3)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

const TypographyDiv = styled.div`
  ${({ theme }) => getTypography(theme, 'Body 1')}
`;

export function InputPhone({
  onChange,
  phone,
  error,
  label,
  description,
  country = 'es',
  required,
  optionalText,
  className,
  dataTestId,
  customStyles,
}: Props) {
  const inputClass = cx('inputPhone', {
    hasError: error,
  });
  const selectorClass = cx('countrySelector', {
    hasError: error,
  });

  return (
    <InputContainer customStyles={customStyles}>
      {description && <DescriptiveText>{description}</DescriptiveText>}
      <TypographyDiv data-testid={dataTestId}>
        <PhoneInput
          containerClass={className}
          inputClass={inputClass}
          buttonClass={selectorClass}
          dropdownClass="countriesDropdown"
          country={country.split('-')[0]}
          countryCodeEditable={false}
          value={phone}
          onChange={(e: string, countryData: CountryData) =>
            onChange(e, countryData)
          }
        />
      </TypographyDiv>
      {label && (
        <div>
          <Typography.Body2>{label}</Typography.Body2>
          {!required && <DescriptiveText>{optionalText}</DescriptiveText>}
        </div>
      )}
    </InputContainer>
  );
}

export default InputPhone;
