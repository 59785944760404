import * as React from 'react';
import type { SVGProps } from 'react';
const SvgKingBadge = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <circle cx={10} cy={10} r={10} fill="#333" />
    <path
      fill="#fff"
      d="M14.678 7.351a.5.5 0 0 0-.552.136l-1.375 1.547a.252.252 0 0 1-.391-.021l-1.953-2.735a.52.52 0 0 0-.814 0L7.64 9.013a.252.252 0 0 1-.391.021L5.874 7.487A.5.5 0 0 0 5 7.819v5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-5a.5.5 0 0 0-.322-.468"
    />
  </svg>
);
export default SvgKingBadge;
