
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as OrdersIconComponent } from "./orders-icon.svg";

export const OrdersIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <OrdersIconComponent {...props} />;
};
