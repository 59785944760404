import styled from '@emotion/styled';
import { Grid } from '@innovamat/glimmer-components';

const Container = styled(Grid.Container)`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

const ColWithMargin = styled(Grid.Col)`
  margin-bottom: 3rem;
`;

export { Container, ColWithMargin };
