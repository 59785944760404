
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as KeyTabIconComponent } from "./key-tab-icon.svg";

export const KeyTabIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <KeyTabIconComponent {...props} />;
};
