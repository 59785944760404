import styled from '@emotion/styled';
import { Resource } from '../Materials/Resource';
import { useSmartGuides } from '../../context/SmartGuidesProvider';
import {
  PrintableBody1,
  PrintableBody2,
} from '../../printable-styles/printable-typography';
import { Icon, getTypography } from '@innovamat/glimmer-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  gap: 8px;
  border-radius: 4px;
  background-color: ${({ theme }) =>
    theme.tokens.color.global.teal.teal100.value};

  @media print {
    padding: 12px;
  }
`;

const Title = styled(PrintableBody1)`
  color: ${({ theme }) =>
    theme.tokens.color.global.teal.teal700.value} !important;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  @media print {
    gap: 4px;
  }

  svg path {
    fill: ${({ theme }) => theme.tokens.color.global.teal.teal700.value};
  }
`;

const Link = styled.span`
  ${({ theme }) => getTypography(theme, 'link 2')};

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.tokens.color.alias.cm.text.text.value};
  }
`;

const Text = styled(PrintableBody2)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text.text.value} !important;
`;

export function AssessableSummary(): JSX.Element {
  const { setIsAssessableDrawerOpen, t } = useSmartGuides();

  return (
    <Container>
      <TitleWrapper>
        <Icon icon="EvaluationIcon" size="M" />
        <Title>{t('session.assessment')}</Title>
      </TitleWrapper>
      <Resource onClick={() => setIsAssessableDrawerOpen(true)}>
        <Link>{t('session.assessment.link')}</Link>
      </Resource>
    </Container>
  );
}

export function AssessableSummaryPrintable(): JSX.Element {
  const { t } = useSmartGuides();

  return (
    <Container>
      <TitleWrapper>
        <Icon icon="EvaluationIcon" size="M" />
        <Title>{t('session.assessment')}</Title>
      </TitleWrapper>
      <Text>{t('session.assessment.print')}</Text>
    </Container>
  );
}
