import styled from '@emotion/styled';
import { Button } from '../Button';
import { Tooltip } from '../Tooltip';

const StyledButton = styled(Button)`
  pointer-events: none;
  width: fill-available;
  justify-content: flex-start;

  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};

  svg {
    path {
      fill: ${({ theme }) =>
        theme.tokens.color.alias.cm.text['text-subtle'].value};
    }
  }

  overflow: hidden;

  p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
  }
`;

type Props = {
  fileName: string;
};

export function FileNameButton({ fileName }: Props) {
  return (
    <Tooltip content={fileName} className="filename-tooltip">
      <StyledButton variant="tertiary" leftIcon="FileCsvIcon">
        <span>{fileName}</span>
      </StyledButton>
    </Tooltip>
  );
}
