import styled from '@emotion/styled';
import { Accordion } from '../Accordion';
import { PreprInnerHtml } from '../PreprInnerHtml';
import { Media } from '../Media';
import { MediaType } from '../../types/Media';
import { PartType } from '../../types/SmartGuide';
import { useSmartGuides } from '../../context/SmartGuidesProvider';
import { TableContentType } from '../../types/Table';
import { Table } from '../Table';
import { PrintableBody2 } from '../../printable-styles/printable-typography';

type Props = {
  children: string;
  part?: PartType;
  media?: MediaType[] | null;
  isPrintable?: boolean;
  tables?: TableContentType[];
};

const StyledSolution = styled(PrintableBody2)`
  color: ${({ theme }) => theme.tokens.color.alias.cm.text.text.value};
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media print {
    color: #000000;
  }
`;

export function Solution({
  children,
  part,
  media,
  isPrintable,
  tables,
}: Props): JSX.Element {
  const { t } = useSmartGuides();

  const hasTable = tables?.length && tables.length > 0;

  return (
    <Accordion
      summary={t('digitalguides.solution')}
      hasPreview
      part={part}
      isPrintable={isPrintable}
    >
      <StyledSolution>
        <PreprInnerHtml text={children} />
        {media && <Media media={media} />}
        {hasTable && <Table tables={tables} part={part} />}
      </StyledSolution>
    </Accordion>
  );
}
