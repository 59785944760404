import * as React from 'react';
import type { SVGProps } from 'react';
const SvgHugeGroup = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M14.297 13.12a1.813 1.813 0 1 0 3.625.001 1.813 1.813 0 0 0-3.625 0M13.088 19.33a3.022 3.022 0 1 1 6.044 0M8.209 12.978a1.813 1.813 0 1 0 3.627 0 1.813 1.813 0 0 0-3.627 0M5.209 4.813a1.813 1.813 0 1 0 3.626 0 1.813 1.813 0 0 0-3.626 0M11.209 4.813a1.813 1.813 0 1 0 3.626 0 1.813 1.813 0 0 0-3.626 0M7 19.187a3.022 3.022 0 0 1 6.044 0M4 11.022a3.022 3.022 0 1 1 6.044 0"
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M10 11.022a3.022 3.022 0 0 1 6.044 0M2.209 13.12a1.813 1.813 0 1 0 3.626.001 1.813 1.813 0 0 0-3.626 0M1 19.33a3.022 3.022 0 1 1 6.044 0"
    />
  </svg>
);
export default SvgHugeGroup;
