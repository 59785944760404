import { roles, Roles, TokenData } from '@innovamat/radiance-utils';
import { AppState } from '../root/root.interfaces';
import { createSelector } from 'reselect';
import { AuthUser } from './types';

export const getUser = (state: AppState): AuthUser | undefined =>
  state.auth.user;
export const getUserRegion = (state: AppState): string | undefined =>
  state.auth.user?.region;
export const getUserRoles = (state: AppState): Roles[] | undefined =>
  state.auth.user?.roles;
export const getTokenInfo = (state: AppState): TokenData =>
  state.auth.authValues as TokenData;
export const getLoggedIn = (state: AppState): boolean => !!state.auth.loggedIn;

export const isRole = createSelector(getUserRoles, (_roles) => ({
  isTeacher: roles?.hasTeacherRole(_roles),
  isOwner: roles?.hasOwnerRole(_roles),
  isAdmin: roles?.hasAdminRole(_roles),
  isStudent: roles?.hasStudentRole(_roles),
  isStudentJunior: roles?.hasStudentJuniorRole(_roles),
  isLead: roles?.hasLeadRole(_roles),
  isFree: roles?.hasFreeRole(_roles),
  isParent: roles?.hasParentRole(_roles),
  isAdvisor: roles?.hasAdvisorRole(_roles),
  isDistrictAdmin: roles?.hasDistrictAdminRole(_roles),
  isContent: roles?.hasContentAdminRole(_roles),
  isInternal: roles?.hasInternalRole(_roles),
  isManager: roles?.hasManagerRoles(_roles),
}));
