import * as React from 'react';
import type { SVGProps } from 'react';
const SvgEvaluation = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M8.697 13.124a4.247 4.247 0 1 0 8.494 0 4.247 4.247 0 0 0-8.494 0M19 19.191l-3.052-3.064"
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M6.52 17H3.084a.9.9 0 0 1-.419-.087 1.1 1.1 0 0 1-.355-.27 1.3 1.3 0 0 1-.234-.41A1.4 1.4 0 0 1 2 15.75V3.34c-.01-.344.098-.678.301-.929.203-.25.484-.399.782-.411h10.832c.297.012.579.16.782.411s.311.585.301.928V7M.667 12.917h3M.667 9.167h3M.667 5.417h3"
    />
  </svg>
);
export default SvgEvaluation;
