import { PartType } from '../../types/SmartGuide';
import styled from '@emotion/styled';
import { usePartColors } from '../../hooks/usePartColors';
import { useSmartGuides } from '../../context/SmartGuidesProvider';
import { PrintableSubtitle1 } from '../../printable-styles/printable-typography';

type Props = {
  grouping: string;
  part: PartType;
};

const GroupingTitle = styled(PrintableSubtitle1)`
  color: ${({ color }) => color} !important;
`;

export function Grouping({ grouping, part }: Props): JSX.Element {
  const { t } = useSmartGuides();
  const { getElement } = usePartColors();

  return <GroupingTitle color={getElement(part)}>{t(grouping)}</GroupingTitle>;
}
