import styled from '@emotion/styled';
import { Typography } from '@innovamat/glimmer-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const List = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Title = styled(Typography.Body1)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

export type Element<T> = {
  title: string;
  elements: T[];
};

export type GroupedListProps<T> = {
  items: Element<T>[];
  renderElement: (element: T) => JSX.Element;
};

export function GroupedList<T>({ items, renderElement }: GroupedListProps<T>) {
  return (
    <Container>
      {items.map(({ title, elements }) => (
        <Row>
          <Title>{title}</Title>
          <List>{elements?.map((element) => renderElement(element))}</List>
        </Row>
      ))}
    </Container>
  );
}
