import styled from '@emotion/styled';
import { Accordion } from '../Accordion';
import { Typography, getTypography } from '@innovamat/glimmer-components';
import { PreprInnerHtml } from '../PreprInnerHtml';
import { MediaType } from '../../types/Media';
import { Media } from '../Media';
import { useSmartGuides } from '../../context/SmartGuidesProvider';
import { TableContentType } from '../../types/Table';
import { Table } from '../Table';
import { PrintableBody2 } from '../../printable-styles/printable-typography';

type Props = {
  type: 'ampliation' | 'support';
  children: string;
  media?: MediaType[] | null;
  isPrintable?: boolean;
  tables?: TableContentType[];
};

const Text = styled(PrintableBody2)`
  color: ${({ theme }) => theme.tokens.color.alias.cm.text.text.value};
`;

const summaryMapper: Record<Props['type'], string> = {
  ampliation: 'digitalguides.ampliation',
  support: 'digitalguides.support',
};

export function Tip({
  type,
  children,
  media,
  isPrintable,
  tables,
}: Props): JSX.Element {
  const { t } = useSmartGuides();
  const hasTable = tables?.length && tables.length > 0;

  return (
    <Accordion summary={t(summaryMapper[type])} isPrintable={isPrintable}>
      <Text>
        <PreprInnerHtml text={children} />
      </Text>

      {media && <Media media={media} />}
      {hasTable && <Table tables={tables} />}
    </Accordion>
  );
}
