
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as ViewIconComponent } from "./view-icon.svg";

export const ViewIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <ViewIconComponent {...props} />;
};
