import { User } from '@innovamat/radiance-utils';
import { AxiosResponse } from 'axios';
import { JSONValue } from '../root/root.interfaces';
import { apolloFetch } from '../../utils/axiosInstance';
import { authAxiosInstance } from '@innovamat/ga-features';

export const CLIENT_ID = process.env.NX_USERS_CLIENT_ID;
export const API_PLATFORM = process.env.NX_API_PLATFORM;
export const API_SOLVER = process.env.NX_API_SOLVER;

const platformData = {
  accounts: async ({
    platformUrl,
    isParent,
    isStudentJunior,
    studentId,
  }: {
    platformUrl: string;
    isParent: boolean;
    isStudentJunior: boolean;
    studentId?: string;
  }): Promise<AxiosResponse<unknown>> => {
    let url = `${platformUrl}/api/accounts/legacy/mine`;

    if (isParent) url = `${platformUrl}/api/parents/me/legacy/students`;
    if (isStudentJunior) url = `${platformUrl}/api/users/${studentId}`;

    const response = await authAxiosInstance({
      method: 'GET',
      url,
    });

    if (isParent) {
      response.data.students.forEach((student: any) => {
        // eslint-disable-next-line no-param-reassign
        student.account.language = null;
      });
      return response;
    }

    if (isStudentJunior) {
      const { userId } = response.data;
      response.data.account = {
        profile: {
          name: 'StudentJunior',
          id: 4,
        },
        id: userId,
      };
      return response;
    }

    return response.data;
  },
  platformUrl: async (): Promise<string | undefined> => {
    let url = API_PLATFORM;
    try {
      const response = await authAxiosInstance({
        method: 'GET',
        url: `${window.__GA_CONFIG__.apiUsers}/platforms`,
      });
      url = response.data.url;
    } catch (e) {
      console.error('Error getting platform url, using default');
    }
    return url;
  },
  solverUrl: async (): Promise<string | undefined> => {
    let url = API_SOLVER;
    try {
      const response = await authAxiosInstance({
        method: 'GET',
        url: `${window.__GA_CONFIG__.apiUsers}/solvers`,
      });
      url = response.data.url;
    } catch (e) {
      console.error('Error getting solver url, using default');
    }
    return url;
  },
  regionParameters: async (user: User, stage: string): Promise<any> => {
    const { region: regionCode } = user;
    const graphQl = JSON.stringify({
      query: ` query RegionParameters($regionCode: String!, $stage: String){
        regionParameters(regionCode: $regionCode, stage: $stage)
        } `,
      variables: { regionCode, stage },
    });

    const response = await apolloFetch({ graphQl });
    return response;
  },
  skipActivity: async (
    userUuid: string,
    sessionType: string,
    codename: string
  ): Promise<any> => {
    const body = {
      userUuid,
      sessionType,
      codename,
      isSkippedByFenix: true,
    };
    const graphQl = JSON.stringify({
      query: `mutation SkipSessionActivity($body: SkipSessionActivityBody!) {
        skipSessionActivity(body: $body)
      }`,
      variables: { body },
    });

    const response = await apolloFetch({ graphQl });
    return response;
  },
  getCoinsAndGems: async (
    userId: JSONValue,
    PlatformUrl: string
  ): Promise<AxiosResponse<unknown>> => {
    const response = await authAxiosInstance({
      method: 'GET',
      url: `${PlatformUrl}/api/city/user/${userId}/biome/1`,
    });
    return response.data.currencyEntity;
  },
  getGemsLevelUp: async (
    PlatformUrl: string
  ): Promise<AxiosResponse<unknown>> => {
    const response = await authAxiosInstance({
      method: 'GET',
      url: `${PlatformUrl}/api/platform/data`,
    });
    return response;
  },
  getBiomes: async (
    userId: string,
    PlatformUrl: string
  ): Promise<AxiosResponse<unknown>> => {
    const response = await authAxiosInstance({
      method: 'GET',
      url: `${PlatformUrl}/api/cities/user/${userId}`,
    });
    return response;
  },
  getTargetEntry: async (
    user: number,
    PlatformUrl: string
  ): Promise<AxiosResponse<unknown>> => {
    try {
      const response = await authAxiosInstance({
        method: 'POST',
        data: { user },
        url: `${PlatformUrl}/api/user/authentication`,
      });
      return response.data.entry;
    } catch (e) {
      return e.response;
    }
  },
};

export default platformData;
