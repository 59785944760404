import { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { unity } from '@innovamat/radiance-utils';
import { FullScreenHandle } from 'react-full-screen';
import { manageUnityError } from '@innovamat/unity-toolkit-errors';
import { AxiosInstance } from 'axios';

type Tokens = {
  accessToken: string;
  refreshToken: string;
  idToken: string;
};

type Props = {
  style?: any;
  unityInputs: any;
  onLoad?: () => void;
  buildPath?: string;
  onCloseApp?: () => void;
  onAppletIsCompleted?: () => void;
  isApplet?: boolean;
  language: string;
  refreshToken?: string;
  accessToken?: string;
  fullscreen?: FullScreenHandle;
  setEventData: (
    eventType: string,
    eventProperties?: any,
    addUserData?: boolean
  ) => void;
  axiosInstance: AxiosInstance;
  apolloServer: string;
  onUpdateTokens?: (tokens: Tokens) => void;
  slackApiTokens?: string;
  slackApiUrl?: string;
  unityStandaloneBuildDomain: string;
  onException?: () => void;
};

export function UnityWebGL(props: Props) {
  const {
    onCloseApp,
    onLoad,
    onException,
    unityInputs,
    buildPath,
    style,
    isApplet,
    onAppletIsCompleted,
    language,
    fullscreen,
    setEventData,
    axiosInstance,
    apolloServer,
    onUpdateTokens,
    slackApiTokens,
    slackApiUrl,
    unityStandaloneBuildDomain,
  } = props;

  let metaDataInfo = { isCompleted: false };
  const version = buildPath
    ?.replace(`${unityStandaloneBuildDomain}/`, '')
    .replace('Build/index.html', '');

  const iFrame = useRef<HTMLIFrameElement | null>(null);

  const sendInputsToUnity = () => {
    const inputs = unityInputs;

    inputs.language = unity.getUnityLanguage(language);
    inputs.isSecondaryRequest = true;
    inputs.userToken = inputs.userToken || '';
    inputs.userId = inputs.userId || '1';

    if (inputs.sceneName === 'ActivityManager_GA') {
      iFrame.current?.contentWindow?.postMessage(inputs, '*');
    } else {
      iFrame.current?.contentWindow?.postMessage(JSON.stringify(inputs), '*');
    }
  };

  const onMessageIsReceived = (event: MessageEvent) => {
    if (
      event.data === 'BridgeIsReady' ||
      event.data.eventId === 'BridgeIsReady' ||
      event.data.eventId === 'SendReadyToBrowser'
    ) {
      sendInputsToUnity();
      if (onLoad) {
        setTimeout(onLoad, 300);
      }
    } else if (
      (event.data === 'AppIsClosed' ||
        event.data.eventId === 'AppIsClosed' ||
        event.data.eventId === 'SendCloseAppToBrowser') &&
      onCloseApp
    ) {
      onCloseApp();
    } else if (
      event.data === 'ActivityIsCompleted' ||
      event.data.eventId === 'ActivityIsCompleted' ||
      event.data.eventId === 'SendCompleteActivityEvent'
    ) {
      onAppletIsCompleted?.();
      metaDataInfo = { isCompleted: true };
    } else if (event.data.eventId === 'ActivityExceptionCatch') {
      manageUnityError({
        setEventData,
        axiosInstance,
        apolloServer,
        version,
        slackApiTokens: slackApiTokens!,
        slackApiUrl: slackApiUrl!,
        slackEnabled: true,
        error: {
          ...unityInputs,
          metaDataInfo: event.data.arguments,
        },
      });
      onException?.();
    } else if (event.data.eventId === 'UpdateTokens') {
      const {
        AccessToken: accessToken,
        RefreshToken: refreshToken,
        IdToken: idToken,
      } = event.data.arguments;
      onUpdateTokens?.({ accessToken, refreshToken, idToken });
    } else if (event.data.eventId === 'Error') {
      const errorMessage = event.data.arguments.message;
      if (
        typeof errorMessage === 'string' &&
        (errorMessage.includes('Exception') ||
          errorMessage.includes('exception'))
      ) {
        manageUnityError({
          setEventData,
          axiosInstance,
          apolloServer,
          version,
          slackApiTokens: slackApiTokens!,
          slackApiUrl: slackApiUrl!,
          slackEnabled: true,
          error: {
            ...unityInputs,
            errorMessage: errorMessage,
            metaDataInfo,
          },
        });
      }
    } else if (event.data.eventId === 'SendData') {
      setEventData('send_data', event.data.arguments);
    } else {
      if (event.data == null) {
        console.log('Unable to handle null event data message');
        return;
      }

      const eventMessageAsString = event.data.toString();
      if (
        eventMessageAsString.includes('Exception') ||
        eventMessageAsString.includes('exception')
      ) {
        manageUnityError({
          setEventData,
          axiosInstance,
          apolloServer,
          version,
          slackApiTokens: slackApiTokens!,
          slackApiUrl: slackApiUrl!,
          slackEnabled: true,
          error: {
            ...unityInputs,
            errorMessage: eventMessageAsString,
            metaDataInfo,
          },
        });
      } else {
        console.log('Ignoring error with event data message:');
        console.log(event.data.message);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('message', onMessageIsReceived, false);
    return () => {
      console.log('Removing event listener');

      // iFrame.current = null;
      window.removeEventListener('message', onMessageIsReceived);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const height = iFrame.current?.clientHeight || 500;
  const width = height * 2;
  const containerStyles =
    isApplet && !fullscreen?.active
      ? { maxWidth: `${width}px`, ...style }
      : style;

  return (
    <Container style={containerStyles}>
      <Iframe
        key={`${unityInputs.sceneName}-${unityInputs.packOrVariation}`}
        ref={iFrame}
        src={buildPath}
        title="UnityApplet"
        allow="clipboard-read; clipboard-write"
      />
    </Container>
  );
}

const Container = styled.div`
  border: 0;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
`;

const Iframe = styled.iframe`
  border: 0;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
`;
