
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as AppletIconComponent } from "./applet-icon.svg";

export const AppletIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <AppletIconComponent {...props} />;
};
