import styled from '@emotion/styled';
import { Icons, Icon, InnerHtml } from '@innovamat/innova-components';
import { Group } from '../../types/group';

type PillContentProps = {
  text?: string;
  icon?: keyof typeof Icons;
  selected: boolean;
  group: Group;
  pillContentHover: boolean;
};

const StyledPillContent = styled.div<PillContentProps>`
  color: ${(props) =>
    props.group === '1' && props.pillContentHover
      ? props.theme.colors.white
      : props.theme.colors.dark['01']};
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 70px;
  padding: ${(props) => props.theme.spaces.medium};
  display: none;
  flex-direction: column;
  border-radius: 4px;
  justify-content: space-between;
  div {
    align-self: flex-end;
    svg path {
      stroke: currentColor;
    }
  }
`;

const Text = styled(InnerHtml)`
  font-size: ${(props) => props.theme.sizes.xsmall};
  align-self: start !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export default function PillContent({
  group,
  text,
  icon,
  selected,
  pillContentHover,
}: PillContentProps) {
  const pareseText = () => text?.split('_').join(' ');

  return (
    <StyledPillContent
      group={group}
      selected={selected}
      pillContentHover={pillContentHover}
    >
      {text && <Text text={pareseText()} />}
      {icon && <Icon icon={icon} />}
    </StyledPillContent>
  );
}
