import { useEffect } from 'react';
import styled from '@emotion/styled';
import SimpleBlock from '../SimpleBlock';
import MaterialsBlock from '../MaterialsBlock';
import ChallengesBlockTitle from './ChallengesBlock.Title';
import ChallengesBlockVocabulary from './ChallengesBlock.Vocabulary';
import ChallengesBlockMotto from './ChallengesBlock.Motto';
import ChallengesBlockAskAndObserve from './ChallengesBlock.AskAndObserve';
import useEarlyYearGuide from '../../templates/EarlyYear/context/useEarlyYearGuide';
import {
  Challenge,
  Challenges,
} from '../../templates/EarlyYear/types/challenges';
import ChallengesBlockTips from './ChallengesBlock.Tips';
import Accordion from '../Accordion';
import {
  AccordionContent,
  AccordionItem,
  AccordionWrapper,
} from '../Accordion/Accordion.styles';
import { onClickBox } from '../../utils/openBoxes';
import { IconRecord } from '../../types/icons';
import { Stage } from '../../../types/Stage';
import { getMaterialsByStage } from '../../../utils/materialsList';

type Props = {
  challenges: Challenges;
  contentIcons: IconRecord;
};

const SummaryBlockWrapper = styled.div`
  margin: 24px 0;
`;

export default function ChallengesBlock({ challenges, contentIcons }: Props) {
  const {
    getIsOpen,
    scrollPosition,
    onScrollChallenge,
    onSelectChallenge,
    guide,
    t,
  } = useEarlyYearGuide();

  const handleOnClick = async (index: number, challenge: Challenge) => {
    onClickBox(
      index,
      challenge,
      getIsOpen,
      onSelectChallenge,
      onScrollChallenge
    );
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    const challengeId = url.searchParams.get('challengeId');

    if (challengeId) {
      const indexTest = challenges.items.map((c) => c.id).indexOf(challengeId);
      const challengeTest = challenges.items[indexTest];
      handleOnClick(indexTest, challengeTest);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AccordionWrapper>
      <Accordion
        scrollPosition={scrollPosition}
        getIsOpen={getIsOpen}
        itemsLength={challenges.items.length}
        onScroll={onScrollChallenge}
        component={AccordionItem}
        renderChildren={(index) => {
          const challenge = challenges.items[index];

          const title =
            guide?.preprType === 'earlyYear'
              ? t('digitalguides.ey.challenge.summary')
              : t('digitalguides.ey.classroom_life.activity');

          return (
            <>
              <ChallengesBlockTitle
                id={challenge.id}
                onClick={() => handleOnClick(index, challenge)}
                title={challenge.title.body}
                challengeNumber={index}
              />
              {getIsOpen(index) ? (
                <AccordionContent>
                  <SimpleBlock title={title} text={challenge.summary} />
                  <ChallengesBlockTips tips={challenge.tips} />
                  <MaterialsBlock
                    material={challenge.material}
                    items={getMaterialsByStage(Stage.EarlyYear)}
                  />
                  <ChallengesBlockVocabulary
                    keyVocabulary={challenge.key_vocabulary}
                  />
                  <ChallengesBlockMotto motto={challenge.motto} />
                  <ChallengesBlockAskAndObserve
                    contentIcons={contentIcons}
                    data={challenge.askandobserve}
                  />
                </AccordionContent>
              ) : (
                <>
                  <SummaryBlockWrapper>
                    <SimpleBlock title={title} text={challenge.summary} />
                  </SummaryBlockWrapper>
                  <ChallengesBlockMotto motto={challenge.motto} />
                </>
              )}
            </>
          );
        }}
      />
    </AccordionWrapper>
  );
}
