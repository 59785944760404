import { useEffect, useRef, useState } from 'react';

import styled from '@emotion/styled';

import { css } from '@emotion/react';
import { useDelayAction, useEventListener } from '@innovamat/hooks';
import Pill from '../Pill';
import usePlayerState from '../../providers/PlayerProviders/usePlayerState';
import usePlayerDispatch from '../../providers/PlayerProviders/usePlayerDispatch';
import { getRealStepValue } from '../../state/selectors';
import { Session, SessionResource } from '../../types/session';
import useVideoContext from '../../providers/VideoProviders/useVideoContext';
import { getPlayerIcon } from '../../utils/helpers';
import { isTouchDevice } from '../../utils/isTouchDevice';

const Wrapper = styled.div<{ isHovering: boolean }>`
  padding-bottom: 14px;
  position: relative;

  ${({ isHovering }) =>
    isHovering &&
    css`
      // display the hover overlay zone
      div:first-of-type {
        display: flex;
      }
      // expand all the pills
      > div > div > div {
        margin-bottom: 0px;
        &:first-of-type {
          transition: height 50ms;
          height: 70px;
        }
        &:last-child {
          display: flex;
        }
        //remove border to pillstep
        div {
          border: none;
        }
      }
    `}
`;

const HoverOverlayZone = styled.div<{ width: number }>`
  display: none;
  height: 70px;
  width: ${({ width }) => width}px;
  position: absolute;
  bottom: 14px;
`;

const TimelineWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${(props) => props.theme.spaces.small};
`;

export default function Timeline() {
  const {
    currentStep,
    currentResource,
    resource,
    totalSteps,
    fullscreen,
    fullscreenIOS,
  } = usePlayerState();
  const sessionResource = resource as Session;
  const dispatch = usePlayerDispatch();
  const { duration, getProgress } = useVideoContext();
  const ref = useRef<HTMLDivElement>(null);

  const isInFullscreen = !!fullscreen?.active || fullscreenIOS;

  const [timelineWidth, setTimelineWidth] = useState(0);

  const handlePillClick = (step: number, index: number) => {
    dispatch({
      type: 'RESOURCE_NAVIGATE',
      payload: {
        step,
        resource: index,
        duration,
        progress: getProgress(),
      },
    });
  };

  const getIsStepSelected = (stepIndex: number, resourceIndex: number) => {
    const currentValue = getRealStepValue(
      currentResource,
      currentStep,
      sessionResource
    );
    const stepValue = getRealStepValue(
      resourceIndex,
      stepIndex,
      sessionResource
    );
    return stepValue <= currentValue;
  };

  const getFragments = (subResource: SessionResource) => {
    if ('pages' in subResource) {
      return subResource.pages.map((p) => p.order);
    }
    return [0];
  };

  const {
    isDoingAction: isHovering,
    handleStartAction: handleEnterHover,
    handleEndAction: handleLeaveHover,
  } = useDelayAction(300);

  useEventListener('resize', () => {
    setTimelineWidth(ref.current?.clientWidth!);
  });

  if (ref.current?.clientWidth !== timelineWidth) {
    setTimelineWidth(ref.current?.clientWidth!);
  }

  useEffect(() => {
    if (isTouchDevice()) {
      if (isHovering) {
        setTimeout(() => {
          handleLeaveHover();
        }, 2000);
      }
    }
  }, [isHovering, handleLeaveHover]);

  if (totalSteps <= 1) return null;

  return (
    <Wrapper
      onMouseEnter={handleEnterHover}
      onTouchStart={isTouchDevice() ? handleEnterHover : undefined}
      onMouseLeave={handleLeaveHover}
      isHovering={isHovering}
    >
      {/* temporally disabled, see the interaction without this inside gac */}
      <HoverOverlayZone width={timelineWidth} />
      <TimelineWrapper ref={ref} data-testid="timelineResourceViewer">
        {sessionResource?.resources?.map((pill, index) => (
          <Pill
            index={index}
            id={pill.id}
            isInFullscreen={isInFullscreen}
            canClickStep
            fragments={getFragments(pill)}
            key={`${pill.id}-${index}`}
            onClick={(step) => handlePillClick(step, index)}
            icon={getPlayerIcon(pill)}
            text={pill.title}
            selected={currentResource === index}
            stepSelected={(stepIndex) => getIsStepSelected(stepIndex, index)}
            group={pill.group}
            dataTestId="playerPillResourceViewer"
          />
        ))}
      </TimelineWrapper>
    </Wrapper>
  );
}
