import * as React from 'react';
import type { SVGProps } from 'react';
const SvgVidaAula = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M17 8.583V2.167c0-.31-.13-.606-.36-.825A1.27 1.27 0 0 0 15.77 1H2.23c-.326 0-.639.123-.87.342-.23.218-.36.515-.36.825v11.666c0 .31.13.607.36.825.231.22.544.342.87.342h4.924"
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M3 9.953a1.563 1.563 0 1 0 3.125 0 1.563 1.563 0 0 0-3.125 0"
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M6.125 9.953V5.292a1.25 1.25 0 0 1 .855-1.186l3.75-1.042a1.25 1.25 0 0 1 1.645 1.186v3.828"
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M9.25 8.078a1.562 1.562 0 1 0 3.125 0 1.562 1.562 0 0 0-3.125 0M14 13.295s.363-1.17 3.475-1.293a.46.46 0 0 1 .354.114.5.5 0 0 1 .171.341v4.8a.5.5 0 0 1-.163.33.47.47 0 0 1-.337.12C14.37 17.824 14 19 14 19zm0 0s-.363-1.17-3.475-1.293a.46.46 0 0 0-.354.114.5.5 0 0 0-.171.341v4.8c.012.128.07.246.163.33a.47.47 0 0 0 .337.12C13.63 17.824 14 19 14 19"
    />
  </svg>
);
export default SvgVidaAula;
