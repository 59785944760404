import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { webappTranslations } from '@innovamat/poeditor';

i18n.use(initReactI18next).init({
  lng: 'es',
  // we init with resources
  resources: {
    es: { translations: webappTranslations.es },
    ca: { translations: webappTranslations.ca },
    en: { translations: webappTranslations.en },
    eu: { translations: webappTranslations.eu },
    va: { translations: webappTranslations.va_es },
    it_IT: { translations: webappTranslations.it },
    es_MX: { translations: webappTranslations.es },
    fr_FR: { translations: webappTranslations.fr },
    pt_BR: { translations: webappTranslations.pt_br },
    en_US: { translations: webappTranslations.en_us },
  },
  fallbackLng: (code) => {
    if (!code || code === '') return 'es';
    return code.includes('es_') ? 'es_MX' : code;
  },
  debug: false,

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',

  keySeparator: false, // we use content as keys
  nsSeparator: '::', // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
  },
});

export default i18n;
