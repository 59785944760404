import * as React from 'react';
import type { SVGProps } from 'react';
const SvgQuestionCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g fill="none" stroke="#000" strokeWidth={1.5}>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.249995}
        d="M7.5 7.5a2.5 2.5 0 1 1 3.333 2.358A1.25 1.25 0 0 0 10 11.037v.838M10 14.375a.313.313 0 1 0 .313.313.313.313 0 0 0-.313-.313"
      />
      <path
        strokeWidth={1.249995}
        d="M.625 10a9.375 9.375 0 1 0 18.75 0 9.375 9.375 0 1 0-18.75 0Z"
      />
    </g>
  </svg>
);
export default SvgQuestionCircle;
