import styled from '@emotion/styled';

const CounterBadgeWrapper = styled('div')<{
  position: string;
  variant: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding: 0px 0.406rem;
  min-width: 1.75rem;
  background-color: ${({ variant, theme }) =>
    variant === 'increment' ? theme.colors.info : theme.colors.warning};
  ${({ position }) => `${position}: -0.7rem;`}
  top: -0.375rem;
  border-radius: 50px;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.typography.regular};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
`;

export { CounterBadgeWrapper };
