
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as DeleteIconComponent } from "./delete-icon.svg";

export const DeleteIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <DeleteIconComponent {...props} />;
};
