import * as React from 'react';
import type { SVGProps } from 'react';
const SvgEnter = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M5 11.25h6.25A3.75 3.75 0 0 0 15 7.5V5.623"
      />
      <path strokeWidth={1.249995} d="M8.125 8.123 5 11.248l3.125 3.125" />
      <path strokeWidth={1.249995} d="M1.25 1.248h17.5v17.5H1.25Z" />
    </g>
  </svg>
);
export default SvgEnter;
