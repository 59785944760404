import { Link, useParams } from 'react-router-domv6';
import { Icon } from '@innovamat/innova-components';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import useGetUserRegion from '../../hooks/useGetUserRegion';
import useQueryParams from '../../hooks/useQueryParams';
import { ReactComponent as InnovamatLogo } from '../../assets/svg/innovamat.svg';
import { getRegisterLanguagesByRegion } from '@innovamat/localization';
import {
  Background,
  Container,
  FormContainer,
  FormHeader,
  LogoContainer,
  Image,
  CountryRegionButtonWrapper,
  FormBody,
  StyledNotification,
  Title,
  ErrorContainer,
  GoBackLink,
  FromDataCenter,
  FromText,
  DropdownSelection,
} from './LoginWrapper.styled';
import { Dropdown, Icon as IconGlimmer } from '@innovamat/glimmer-components';
import {
  addParamToUrl,
  addParamToUrlAndRefresh,
} from '@innovamat/radiance-utils';
import LanguageButton from './language-button/language-button';
import { isUSRegion } from '@innovamat/regional-config-variables';

export type NotificationType =
  | {
      message: string | JSX.Element;
      type: 'error' | 'warning';
    }
  | undefined;

type Props = {
  isSuccess?: boolean;
  bg?: string;
  title?: string;
  children: JSX.Element;
  error?: string | JSX.Element;
  canGoBack?: boolean;
  onSuccessBody?: JSX.Element;
  notification?: NotificationType;
  handleIsUsa?: (value: boolean) => void;
};

function LoginWrapper({
  isSuccess,
  children,
  bg,
  title,
  canGoBack,
  onSuccessBody,
  error,
  notification,
  handleIsUsa,
}: Props): JSX.Element {
  const { t, i18n } = useTranslation();
  const { lng } = useParams<{ lng: string }>();
  const params = useQueryParams();
  const language = params.get('l') || params.get('language');
  const dataCenter = params.get('dc');

  const [isPressed, setIsPressed] = useState(false);
  const [dataCenterRegion, setDataCenterRegion] = useState(dataCenter);

  const region = useGetUserRegion();
  const [country] = useState(region);

  const CORPORATE_WEBSITE = process.env.NX_CORPORATE_WEBSITE || '';

  const getAvailableLanguages = (region: string) => {
    const languages = getRegisterLanguagesByRegion(region);
    return languages;
  };

  const languages = getAvailableLanguages(region);

  useEffect(() => {
    if (lng) {
      handleSelectLanguage(lng);
      return;
    }
    const lang = languages.find((i) => i === language);
    if (lang) {
      handleSelectLanguage(lang);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, lng]);

  const handleSelectLanguage = async (languageLocale: string) => {
    addParamToUrl('l', languageLocale);
    await i18n.changeLanguage(languageLocale);
  };

  const setDataCenterRegionAndChangeUrl = (
    region: string,
    language?: string
  ) => {
    addParamToUrl('dc', region);
    addParamToUrlAndRefresh('l', language || i18n.language);
    setDataCenterRegion(region);
  };

  const getDataCenterRegion = async (
    country: string,
    setDataCenterRegion: (region: string) => void
  ): Promise<void> => {
    if (!dataCenter) {
      const isUS = await isUSRegion(CORPORATE_WEBSITE);
      if (isUS) {
        setDataCenterRegion('USA');
        handleSelectLanguage('en');
        handleIsUsa?.(true);
      } else if (
        country.toLocaleUpperCase().includes('ES') ||
        country.toLocaleUpperCase().includes('IT')
      ) {
        setDataCenterRegion('Europe');
      } else {
        setDataCenterRegion('LATAM');
      }
    }
  };

  useEffect(() => {
    getDataCenterRegion(country, setDataCenterRegion);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  return (
    <Container>
      <FormContainer>
        <FormHeader>
          <LogoContainer>
            <Link to="/">
              <InnovamatLogo />
            </Link>
          </LogoContainer>
          <CountryRegionButtonWrapper>
            <LanguageButton
              language={t(`language.${i18n.language}`)}
              handleLanguageClick={handleSelectLanguage}
            />
          </CountryRegionButtonWrapper>
        </FormHeader>
        <FormBody>
          {isSuccess && onSuccessBody ? (
            onSuccessBody
          ) : (
            <>
              {notification && (
                <StyledNotification
                  type={notification.type}
                  canClose={false}
                  text={notification.message}
                />
              )}
              {canGoBack && (
                <GoBackLink to="/">
                  <Icon icon="ArrowLeft" /> {t('Login.Tornar')}
                </GoBackLink>
              )}
              <Title>{title && <h2 className="h2">{title}</h2>}</Title>
              <FromDataCenter>
                <FromText>{t('login.datacenter.from', 'Desde')}:</FromText>
                <Dropdown closeOnSelectItem onToggle={setIsPressed}>
                  <Dropdown.Toggle>
                    <DropdownSelection isPressed={isPressed}>
                      <IconGlimmer size="S" icon="ArrangeIcon" />
                      {dataCenterRegion === 'USA' && (
                        <>
                          <IconGlimmer size="L" icon="UsaIcon" />
                          {t('login.datacenter.region.usa', 'EEUU')}
                        </>
                      )}
                      {dataCenterRegion === 'Europe' && (
                        <>
                          <IconGlimmer size="L" icon="EuropeIcon" />
                          {t('login.datacenter.region.europe', 'Europe')}
                        </>
                      )}
                      {dataCenterRegion === 'LATAM' && (
                        <>
                          <IconGlimmer size="L" icon="LatamIcon" />
                          {t('login.datacenter.region.latam', 'LATAM')}
                        </>
                      )}
                    </DropdownSelection>
                  </Dropdown.Toggle>
                  <Dropdown.Content position="bottomLeft">
                    <Dropdown.Item
                      state="active"
                      icon="UsaIcon"
                      onSelectItem={() => {
                        setDataCenterRegionAndChangeUrl('USA', 'en');
                      }}
                    >
                      {t('login.datacenter.region.usa', 'EEUU')}
                    </Dropdown.Item>
                    <Dropdown.Item
                      state="active"
                      icon="EuropeIcon"
                      onSelectItem={() => {
                        setDataCenterRegionAndChangeUrl('Europe');
                      }}
                    >
                      {t('login.datacenter.region.europe', 'Europe')}
                    </Dropdown.Item>
                    <Dropdown.Item
                      state="active"
                      icon="LatamIcon"
                      onSelectItem={() => {
                        setDataCenterRegionAndChangeUrl('LATAM');
                      }}
                    >
                      {t('login.datacenter.region.latam', 'LATAM')}
                    </Dropdown.Item>
                  </Dropdown.Content>
                </Dropdown>
              </FromDataCenter>
              <ErrorContainer>{error}</ErrorContainer>
              {children}
            </>
          )}
        </FormBody>
        <div />
      </FormContainer>
      <Background>
        <Image src={bg} alt="" />
      </Background>
    </Container>
  );
}

export default LoginWrapper;
