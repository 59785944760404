import { Icon } from '@innovamat/innova-components';

import styled from '@emotion/styled';
import { useDigitalGuides } from '../../../context';

const Wrapper = styled.div`
  display: flex;
  gap: 6px;
  padding: 4px 8px;
  background-color: ${({ theme }) => theme.colors.semantic['01b']};
  border-radius: 4px;
  font-family: ${({ theme }) => theme.fonts.robotoMedium};
  font-size: ${({ theme }) => theme.sizes.xsmall};
  align-items: center;
  height: fit-content;
  svg path {
    fill: ${({ theme }) => theme.colors.brand.dark['02']};
    stroke: ${({ theme }) => theme.colors.brand.dark['02']};
  }
  margin-top: 16px;
`;

export default function MomentBlockKeyMoment() {
  const { t } = useDigitalGuides();

  return (
    <Wrapper>
      <Icon icon="Star" size="sm" />
      <span>{t('digitalguides.se.keymoment')}</span>
    </Wrapper>
  );
}
