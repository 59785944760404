import ContentLoader from 'react-content-loader';

import styled from '@emotion/styled';

const StyledLoader = styled(ContentLoader)`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  padding-top: 2px;
`;

const MaterialCardSkeleton = () => (
  <StyledLoader
    speed={2}
    width="100%"
    height={185}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="0" width="100%" height="117" />

    <rect x="8" y="127" width="72" height="12" />
    <rect x="8" y="147" width="80" height="11" />
    <rect x="8" y="165" width="22" height="10" />

    <rect x="calc(100% - 25px)" y="149" width="17" height="12" />
    <rect x="calc(100% - 25px)" y="166" width="17" height="6" />
  </StyledLoader>
);

export { MaterialCardSkeleton };
