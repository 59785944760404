import { ApolloFetch } from "./types";

export const apolloFetch: ApolloFetch = async ({
  graphQl,
  headers,
  axiosInstance,
  apolloServer
}) => {
  const response = await axiosInstance({
    url: `${apolloServer}`,
    method: 'POST',
    data: JSON.parse(graphQl),
    headers,
  });
  return response.data;
};

export function hasNetworkError(errorMessage: any): boolean {
  if (errorMessage == null) return false;

  return errorMessage.includes('ConnectionError');
}

export function hasRelatedNetworkError(error: any): boolean {
  if (error == null) return false;

  return (
    error.errorMessage.includes('Dependency Exception') ||
    error.errorMessage.includes('Unable to load asset bundle from') ||
    error.errorMessage.includes(
      'ChainOperation failed because dependent operation failed'
    ) ||
    hasNetworkError(error.errorMessage)
  );
}

export const checkIsWarning = (message: string): boolean =>
  message.includes('UnityEngine.AddressableAssets.InvalidKeyException') ||
  message.includes('Type=UnityEngine.AudioClip') ||
  message.includes('Unable to find DTO') ||
  message.includes('Failed to load content catalog') ||
  message.includes('Unable to load ContentCatalogData') ||
  message.includes(
    'ChainOperation failed because dependent operation failed'
  ) ||
  message.includes('Unable to load runtime data at location');

export const isApplet = (sceneName?: string): boolean => {
  if (!sceneName) return false;
  switch (sceneName) {
    case 'HomeInfantil':
    case 'HomeGreen':
    case 'District_Central':
    case 'ActivityManager':
    case 'ScrollInfantil':
    case 'F1':
    case 'F2':
    case 'F3':
    case 'F4':
    case 'AppStartLoadingInnovamat':
    case 'FairHome':
    case 'CityB2B':
    case 'B2BCityShop':
    case 'CityTransitionPanelB2B':
    case 'CityRoot':
    case 'WorldMap':
    case 'CityInterface':
    case 'CityDevTool':
    case 'B2BUIAppletSelectorLoader':
    case 'ErrorScene_Innovamat':
      return false;
    // Applet case
    default:
      return true;
  }
};
