
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as FileStandardIconComponent } from "./file-standard-icon.svg";

export const FileStandardIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <FileStandardIconComponent {...props} />;
};
