
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as SearchIconComponent } from "./search-icon.svg";

export const SearchIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <SearchIconComponent {...props} />;
};
