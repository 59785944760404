
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as SessionReviewedIconComponent } from "./session-reviewed-icon.svg";

export const SessionReviewedIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <SessionReviewedIconComponent {...props} />;
};
