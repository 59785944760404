
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as RelatedItemsIconComponent } from "./related-items-icon.svg";

export const RelatedItemsIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <RelatedItemsIconComponent {...props} />;
};
