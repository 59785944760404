
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as MenuMobileIconComponent } from "./menu-mobile-icon.svg";

export const MenuMobileIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <MenuMobileIconComponent {...props} />;
};
