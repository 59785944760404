import { useSelector } from 'react-redux';
import { AppState } from '../store/root/root.interfaces';
import { addElectronData, isElectronApp } from '../utils/electronApp';

export function useUnityDataListenerForElectron(): void {
  const { unityData } = useSelector(({ uData }: AppState) => ({
    unityData: uData,
  }));

  if (isElectronApp()) {
    addElectronData(unityData);
  }
}
