
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as EvaluationIconComponent } from "./evaluation-icon.svg";

export const EvaluationIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <EvaluationIconComponent {...props} />;
};
