import { useState, useEffect } from 'react';
import { useDebounce } from './useDebounce';

export interface Size {
  width: number | undefined;
  height: number | undefined;
}

export function useWindowSize(debounceDelay = 100): Size {
  const isSSR = typeof window === 'undefined';

  const [windowSize, setWindowSize] = useState<Size>({
    width: isSSR ? undefined : window.innerWidth,
    height: isSSR ? undefined : window.innerHeight,
  });

  // The raw window size value that updates immediately, but only if not SSR
  const [rawWindowSize, setRawWindowSize] = useState<Size>({
    width: isSSR ? undefined : window.innerWidth,
    height: isSSR ? undefined : window.innerHeight,
  });

  // Debounced value of the window size
  const debouncedWindowSize = useDebounce<Size>(rawWindowSize, debounceDelay);

  useEffect(() => {
    if (isSSR) {
      // Don't run resize event handler server-side
      return;
    }

    const handleResize = (): void => {
      setRawWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    // Call handleResize initially to set the state with the current window size
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isSSR]); // Only re-run the effect if the SSR status changes (which should never happen)

  // Set the debounced window size to the state
  useEffect(() => {
    setWindowSize(debouncedWindowSize);
  }, [debouncedWindowSize]);

  return windowSize;
}
