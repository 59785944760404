import * as React from 'react';
import type { SVGProps } from 'react';
const SvgArgumentar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      clipPath="url(#argumentar_svg__a)"
    >
      <path d="M5.724 9.17a5.6 5.6 0 0 1-4.486 1.872 4.45 4.45 0 0 0 1.565-2.733A4.05 4.05 0 0 1 .625 4.917C.625 2.545 3.095.625 6.14.625c2.623 0 4.818 1.424 5.377 3.333" />
      <path d="m4.375 5.208.833.834 2.5-2.5M11.459 10.625l3.333 3.333M14.792 10.625l-3.333 3.333M16.598 16.794a5.26 5.26 0 0 0 2.777-4.5c0-2.992-2.798-5.417-6.25-5.417s-6.25 2.425-6.25 5.417 2.798 5.417 6.25 5.417q.34 0 .672-.033a5.64 5.64 0 0 0 4.328 1.697 4.32 4.32 0 0 1-1.527-2.58" />
    </g>
    <defs>
      <clipPath id="argumentar_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgArgumentar;
