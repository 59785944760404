import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLuck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="m7.104 3.217 6.021 2.408 6.02-2.408"
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M13.125 13.75a2.9 2.9 0 0 0 1.196-.25l4.312-1.917a1.25 1.25 0 0 0 .742-1.142v-6.5a1.25 1.25 0 0 0-.742-1.143L14.32.882a2.94 2.94 0 0 0-2.392 0L7.617 2.798a1.25 1.25 0 0 0-.742 1.14V6.25M6.875 11.25v8.125"
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="m.854 8.842 6.021 2.408 6.02-2.408"
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M5.68 19.12a2.94 2.94 0 0 0 2.39 0l4.313-1.916a1.25 1.25 0 0 0 .742-1.142v-6.5a1.25 1.25 0 0 0-.742-1.143L8.07 6.503a2.94 2.94 0 0 0-2.392 0L1.368 8.417a1.25 1.25 0 0 0-.743 1.146v6.5a1.25 1.25 0 0 0 .743 1.142z"
    />
    <path
      stroke="#757575"
      strokeWidth={1.25}
      d="M2.5 12.056a.312.312 0 1 1 0-.625M2.5 12.056a.312.312 0 1 0 0-.625M3.437 14.247a.312.312 0 1 1 0-.625M3.437 14.247a.312.312 0 1 0 0-.625M4.688 16.743a.312.312 0 1 1 0-.625M4.688 16.743a.312.312 0 1 0 0-.625M9.47 15.983a.312.312 0 1 1 0-.626M9.47 15.983a.312.312 0 1 0 0-.626M10.815 12.766a.312.312 0 1 1 0-.625M10.815 12.766a.312.312 0 1 0 0-.625M6.875 9.072a.312.312 0 1 1 0-.625M6.875 9.072a.312.312 0 1 0 0-.625M13.125 3.315a.312.312 0 1 1 0-.625M13.125 3.315a.312.312 0 1 0 0-.625M17.188 6.91a.312.312 0 1 1 0-.624M17.188 6.91a.312.312 0 1 0 0-.624M17.188 10.036a.313.313 0 0 1 0-.625M17.188 10.036a.313.313 0 0 0 0-.625"
    />
  </svg>
);
export default SvgLuck;
