import styled from '@emotion/styled';
import { useEventLogging } from '@innovamat/event-logging';
import { Banner } from '@innovamat/glimmer-components';
import { inlineManual } from '@innovamat/radiance-utils';
import { useNavigate, useParams } from 'react-router-domv6';
import { useCurrentNavigationValues } from '../../navigation';
import { useUser } from '../../user-management';
import { useDisplayBanners } from '../hooks/use-display-banners';

type Props = React.HTMLAttributes<HTMLDivElement>;

const BannerContainer = styled.div``;

function BannerToDisplay({ ...rest }: Props): JSX.Element | null {
  const navigate = useNavigate();
  const { setEventData } = useEventLogging();
  const { organizationId, classroomId } = useParams();
  const { user } = useUser();
  const { currentPage, getTypeOfPagePermission } = useCurrentNavigationValues();

  const { banners, onCloseBanner } = useDisplayBanners();

  const handleCloseBanner = (): void => {
    handleBtnEvent('exit');
    onCloseBanner(banner.id);
  };

  const handleBtnEvent = (action: string): void => {
    const pageId = currentPage?.fullPageId || currentPage?.demoPageId;
    if (currentPage) {
      const permissionType = getTypeOfPagePermission(currentPage);
      const pageName = currentPage.value;

      setEventData('banner_click', {
        page_id: pageId,
        page_name: pageName,
        page_type: permissionType,
        banner_name: banner.title,
        banner_id: banner.id,
        action,
      });
    }
  };

  const parseLink = (link: string): string => {
    if (link.includes('{classroomId}') && classroomId) {
      return link.replace('{classroomId}', classroomId);
    }

    const orgId = organizationId || user?.organizationId;

    if (link.includes('{organizationId}') && orgId) {
      return link.replace('{organizationId}', orgId);
    }

    return link;
  };

  const handleBtnClick = (): void => {
    handleBtnEvent('cta');

    if (banner.inlineTopicId) {
      inlineManual.activateTopic(banner.inlineTopicId);
    } else if (banner.ctaExternal) {
      window.open(parseLink(banner.ctaLink), '_blank');
    } else {
      navigate(parseLink(banner.ctaLink));
    }

    if (banner.canBeClosed) {
      onCloseBanner(banner.id);
    }
  };

  if (banners.length === 0) return null;

  const banner = banners[0];

  return (
    <BannerContainer {...rest}>
      <Banner
        btnText={banner.ctaText}
        bgColor={banner.colour}
        imgAlt={banner.icon.name}
        imgSrc={banner.icon.url}
        showBtn={banner.cta}
        text={banner.text}
        title={banner.title}
        onBtnClick={handleBtnClick}
        onBtnCloseClick={handleCloseBanner}
        canBeClosed={banner.canBeClosed}
        fill={banner.visibleAt !== 'LAUNCHER'}
      />
    </BannerContainer>
  );
}

export { BannerToDisplay };
