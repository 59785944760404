import { useRef } from 'react';
import styled from '@emotion/styled';
import { ImageModal } from './ImagesModal/ImagesModal';
import { Icon, theme } from '@innovamat/glimmer-components';
import { ImageComponent } from '@innovamat/glimmer-components';

type ImagesModalProps = {
  isBigImage: boolean;
  image: ImageModal;
  onClickImage: (image: ImageModal | null) => void;
  children?: JSX.Element;
  color?: string;
};

const Wrapper = styled.div`
  position: relative;
`;

const CoverDark = styled.div<{
  height: number | undefined;
  isBigImage: boolean;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${({ height }) => (height ? `${height}px` : '100%')};
  z-index: 2;
  background-color: transparent;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.1s ease;
  svg {
    transition: opacity 0.1s ease;
    opacity: 0;
  }
  &:hover {
    background-color: #00000016;
    svg {
      opacity: 1;
    }
  }
`;

const IconWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
  color: ${theme.colors.white};
  svg path {
    stroke: none !important;
  }
`;
const Image = styled(ImageComponent)<{ isBigImage: boolean }>`
  border-radius: 4px;
  background-color: ${theme.colors.white};
  width: 272px;
  height: 152px;
`;

export default function OverlayImage({
  isBigImage,
  image,
  onClickImage,
  children,
  color,
}: ImagesModalProps) {
  const imageRef = useRef<HTMLImageElement>(null);
  return (
    <Wrapper>
      <CoverDark
        height={imageRef.current?.clientHeight}
        onClick={() => onClickImage(image)}
        isBigImage={isBigImage}
      >
        <IconWrapper>
          <Icon icon="ZoomInIcon" size="XL" iconColor={color || 'white'} />
        </IconWrapper>
      </CoverDark>
      {children || (
        <Image
          ref={imageRef}
          src={image.url}
          alt={image.title}
          isBigImage={isBigImage}
        />
      )}
    </Wrapper>
  );
}
