import styled from '@emotion/styled';
import { normalizeImgSource } from '@innovamat/ga-features';
import {
  ImageComponent,
  Modal,
  Typography,
} from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';
import importingGif from './import-classroom.gif';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Animation = styled(ImageComponent)`
  height: 56px;
`;

const Footer = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;
`;

const StyledModal = styled(Modal)`
  padding: 72px 64px 68px 64px;
`;

const Title = styled(Typography.H4)``;

const Subtitle = styled(Typography.Body1)`
  color: ${({ theme }) => theme.colors.dark['02']};
  font-family: ${({ theme }) => theme.fonts.roboto};
`;

type Props = {
  open: boolean;
};

export default function ImportClassroomLoading({ open }: Props) {
  const { t } = useTranslation();

  const ImageSrc = normalizeImgSource(importingGif);

  return (
    <StyledModal isOpen={open} modalWidth={600}>
      <Wrapper>
        <Animation src={ImageSrc} alt="importing classrooms" />
        <Footer>
          <Title>{t('modal.import.class.animation.title')}</Title>
          <Subtitle>{t('modal.import.class.animation.subtitle')}</Subtitle>
        </Footer>
      </Wrapper>
    </StyledModal>
  );
}
