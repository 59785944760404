/* eslint-disable */
import axios, { AxiosResponse } from 'axios';
import { authAxiosInstance } from '@innovamat/ga-features';

export const axiosInstance = axios.create();

type ApolloParams = {
  graphQl: string;
};

export const apolloFetch = async ({
  graphQl,
}: ApolloParams): Promise<AxiosResponse<unknown>> => {
  const response = await authAxiosInstance({
    url: `${window.__GA_CONFIG__.apolloServer}`,
    method: 'POST',
    data: JSON.parse(graphQl),
  });
  return response.data;
};

export const onNetworkError = (): void => {
  window.location.reload();
};
