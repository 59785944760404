import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getFullName } from '../../utils/student';
import { Student } from '@innovamat/glow-api-client';
import {
  AnnouncementPanel,
  Checkbox,
  Modal,
  Typography,
} from '@innovamat/glimmer-components';
import styled from '@emotion/styled';

type Props = {
  isOpen: boolean;
  isLoading: boolean;
  student: Student | undefined;
  onDismiss: () => void;
  onConfirm: () => void;
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const CheckboxContainer = styled(Typography.Body2)`
  display: flex;
  gap: 8px;
  align-items: center;
`;

function ConfirmDeleteStudentModal({
  isOpen,
  student,
  onDismiss,
  onConfirm,
  isLoading,
}: Props): JSX.Element {
  const [isConfirmed, setIsConfirmed] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setIsConfirmed(false);
  }, [isOpen]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setIsConfirmed(e.target.checked);
  };

  if (!student) return <></>;

  return (
    <Modal
      onClose={onDismiss}
      isOpen={isOpen}
      title={t('confirmModal.message.deleteStudent')}
      buttons={[
        {
          children: t('confirmModal.action.cancel'),
          onClick: onDismiss,
        },
        {
          children: t('confirmModal.action.confirmDelete'),
          variant: 'tertiary',
          onClick: onConfirm,
          disabled: !isConfirmed,
          loading: isLoading,
        },
      ]}
    >
      <Content>
        <AnnouncementPanel
          canClose={false}
          type="warning"
          text={t('confirmModal.alert.deleteStudent')}
        />
        <Typography.Body2>
          {t('confirmModal.subMessage.deleteStudent', {
            name: getFullName(student),
          })}
        </Typography.Body2>
        <CheckboxContainer>
          <Checkbox checked={isConfirmed} onChange={handleInputChange} />
          {t('confirmModal.checkbox.deleteStudent', {
            name: getFullName(student),
          })}
        </CheckboxContainer>
      </Content>
    </Modal>
  );
}

export { ConfirmDeleteStudentModal };
