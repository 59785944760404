import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPencil = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M17.206 2.799a2.744 2.744 0 0 0-3.898.026L2.376 13.755 1 19l5.247-1.375 10.932-10.93a2.743 2.743 0 0 0 .027-3.896M12.995 3.138l3.871 3.87M11.416 4.716l3.871 3.87"
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="m2.376 13.755 2.713 1.158 1.16 2.708M13.352 6.651l-8.263 8.261"
    />
  </svg>
);
export default SvgPencil;
