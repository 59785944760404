import {
  Accordion,
  InnerHtml,
  Typography,
} from '@innovamat/glimmer-components';
import { Container } from '../parent-video';
import { useState } from 'react';
import styled from '@emotion/styled';
import { SectionEmptyState } from '../section-empty-state';
import { useTranslation } from 'react-i18next';
import { useEventLogging } from '@innovamat/event-logging';

type ParentFaqsProps = {
  questions: {
    question: string;
    answer: string;
  }[];
};

const Summary = styled(Typography.Subtitle1)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

const TextWrapper = styled(Typography.Body2)`
  p {
    margin-top: 12px !important;
  }
`;

const StyledAccordion = styled(Accordion)`
  padding: 16px;
`;

export function ParentFaqs({ questions }: ParentFaqsProps): JSX.Element {
  const { t } = useTranslation();
  const { setEventData } = useEventLogging();
  const [isExpanded, setIsExpanded] = useState<number | undefined>(undefined);

  const handleExpand = (index: number, title: string): void => {
    setIsExpanded(index);
    setEventData('parent_launcher_open_frequent_question', {
      question_title: title,
    });
  };

  return (
    <Container>
      <Typography.H3>{t('parent-launcher.faqs.title')}</Typography.H3>
      {questions && questions.length > 0 ? (
        questions.map((question, index) => (
          <StyledAccordion
            onExpand={() =>
              isExpanded === index
                ? setIsExpanded(undefined)
                : handleExpand(index, question.question)
            }
            isExpanded={isExpanded === index}
            key={index}
            size="S"
            summary={<Summary>{question.question}</Summary>}
          >
            <TextWrapper>
              <InnerHtml text={question.answer} />
            </TextWrapper>
          </StyledAccordion>
        ))
      ) : (
        <SectionEmptyState />
      )}
    </Container>
  );
}
