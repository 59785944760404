import cx from 'classnames';
import React from 'react';
import styled from '@emotion/styled';
import styles from './Button.module.scss';

const ArrowIcon = styled.span`
  width: 20px;
  height: 20px;
  display: block;
  background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBzdHJva2U9IiMzMzMiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIj48cGF0aCBkPSJtMyAxMiAxOC0uMDAwMiIvPjxwYXRoIGQ9Im0xNSA2LjU4MzE5IDYgNS40MTY3MS02IDUuNDE2NiIvPjwvZz48L3N2Zz4=);
`;

const Loading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 27px;
    height: 27px;
    margin: 3px;
    border: 3px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  div:nth-of-type(1) {
    animation-delay: -0.45s;
  }
  div:nth-of-type(2) {
    animation-delay: -0.3s;
  }
  div:nth-of-type(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType?: 'primary' | 'secondary' | 'clear' | 'icon';
  size?: 'medium' | 'large' | 'extraLarge';
  arrow?: boolean;
  hovered?: boolean;
  btnKey?: string;
  wrapperClassName?: string;
  arrowOpenInMobile?: boolean;
  loading?: boolean;
  disabled?: boolean;
}

function LoadingWrapper(): JSX.Element {
  return (
    <Loading>
      <div />
      <div />
      <div />
      <div />
    </Loading>
  );
}

const Button: React.FC<ButtonProps> = ({
  children,
  className = '',
  size = 'medium',
  buttonType = 'primary',
  arrow,
  hovered,
  btnKey,
  wrapperClassName,
  arrowOpenInMobile,
  loading,
  disabled,
  ...rest
}) => {
  const btnclass = cx(styles.button, {
    [className]: !!className,
    [styles[size]]: true,
    [styles[buttonType]]: true,
    [styles.arrowMobile]: arrowOpenInMobile,
  });

  const arrowCX = cx(styles.arrow, { [styles.arrowHover]: hovered });

  if (buttonType === 'clear')
    return (
      <div
        data-testid="button"
        className={`${styles.container} ${wrapperClassName}`}
        key={btnKey}
      >
        <button className={btnclass} {...rest} disabled={disabled}>
          {loading && <LoadingWrapper />}
          <div style={{ visibility: loading ? 'hidden' : 'visible' }}>
            {children}
          </div>
        </button>
        {arrow && (
          <div className={arrowCX}>
            <ArrowIcon />
          </div>
        )}
      </div>
    );

  return (
    <button
      data-testid="button"
      className={btnclass}
      {...rest}
      disabled={disabled}
    >
      {loading && <LoadingWrapper />}
      <div style={{ visibility: loading ? 'hidden' : 'visible' }}>
        {children}
      </div>
    </button>
  );
};

export default Button;
