import styled from '@emotion/styled';

export const StateLayer = styled.div`
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0px;
  z-index: 10;
  box-sizing: border-box;
  background-color: transparent;
  pointer-events: none;
`;
