import { useWebviewBridge } from '@innovamat/webview-bridge';
import {
  AnnouncementPanel,
  AppletCard,
  Skeleton,
  Typography,
} from '@innovamat/glimmer-components';
import styled from '@emotion/styled';
import { AppletsBySessionCodeQuery } from '@innovamat/glow-api-client';
import { useSessionDone } from '../../hooks/session-done-provider';
import { Divider } from './session-done-modal.styles';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 8px 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 0;
  }
`;

const AppletsWrapper = styled.div<{ isLoading?: boolean }>`
  display: flex;
  gap: 16px;
  overflow-x: auto;
  height: 150px;
  ${({ isLoading }) => isLoading && 'overflow-x: hidden;'}
  overflow-y: hidden;
`;

type Props = {
  applets: AppletsBySessionCodeQuery['appletsBySessionCode'] | undefined;
  isError: boolean;
  isLoadingApplets?: boolean;
};

export default function UnlockedAppletsList({
  applets,
  isError,
  isLoadingApplets,
}: Props): JSX.Element {
  const { t, onGoToApplet } = useSessionDone();

  const handleAppletClick = (appletId: string): void => {
    onGoToApplet(appletId);
    // if (isInWebview) return;
    // const path = getPathToResource('applet', appletId);
    // window.open(path, '_blank');
  };

  const { isInWebview } = useWebviewBridge();

  const renderContent = (): JSX.Element => {
    if (isLoadingApplets) {
      return (
        <AppletsWrapper isLoading>
          {Array.from(Array(4).keys()).map((index) => (
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
            >
              <Skeleton height="100" width="180" radius="8" key={index} />
              <Skeleton height="21" width="180" radius="4" key={index} />
            </div>
          ))}
        </AppletsWrapper>
      );
    }

    if (applets === null || applets === undefined || isError) {
      return (
        <AnnouncementPanel
          text={t('session.sessionApplets.messageError')}
          canClose={false}
          type="info"
        />
      );
    }

    if (applets.length === 0) {
      return (
        <AnnouncementPanel
          text={t('session.sessionApplets.already_unlocked')}
          canClose={false}
        />
      );
    }

    return (
      <AppletsWrapper data-testid="appletsContainerModal">
        {applets?.map((applet, index) => (
          <AppletCard
            key={index}
            thumbnail={applet?.data?.thumbnail!}
            name={applet?.data?.name!}
            isDisabled={isInWebview}
            onClick={() => handleAppletClick(applet?.data?.id!)}
          />
        ))}
      </AppletsWrapper>
    );
  };

  return (
    <Container>
      <Typography.Body1>
        {t('session.unlockModal.description')}
      </Typography.Body1>

      {renderContent()}
      <Divider margin="bottom" />
    </Container>
  );
}
