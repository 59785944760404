
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as Part2GuidesIconComponent } from "./part2-guides-icon.svg";

export const Part2GuidesIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <Part2GuidesIconComponent {...props} />;
};
