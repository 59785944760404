import styled from '@emotion/styled';
import Card from '../Card';

const CheckIconWrapper = styled('div')`
  margin-right: 0.5rem;
`;

const CardBody = styled(Card)<{
  className: string;
  isSelected: boolean;
}>`
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  font-size: ${({ theme }) => theme.typography.typeScale.small};
  padding: ${({ theme }) => theme.spaces.medium};

  position: relative;
  ${({ theme, isSelected }) =>
    isSelected &&
    `circle {
      fill: ${theme.colors.brand.dark['02']};
      stroke: ${theme.colors.brand.dark['02']};
    }
    path {
      fill: ${theme.colors.white};
    }
  `};
`;

const CheckCardGroupWrapper = styled('ul')`
  display: inline-flex;
  flex-direction: column;
  list-style-type: none;
`;

const CardItem = styled('li')<{ isSelected: boolean }>`
  cursor: pointer;
  margin: 0.5rem 0;
  min-width: 250px;
  .addressCardBody {
    background-color: ${({ isSelected, theme }) =>
      isSelected ? theme.colors.semantic['01b'] : theme.colors.white};

    border: ${({ isSelected, theme }) =>
      `${theme.borders.size['02']} ${
        isSelected ? theme.colors.semantic['01b'] : theme.colors.dark['05']
      }`};
  }
`;

export { CardBody, CardItem, CheckCardGroupWrapper, CheckIconWrapper };
