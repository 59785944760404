import styled from '@emotion/styled';
import { Typography, getTypography } from '@innovamat/glimmer-components';

const ModalContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const Description = styled(Typography.Body1)`
  width: 100%;
  margin-bottom: 18px;
`;

const CustomTable = styled.table`
  width: 100%;
  margin-top: 24px;

  tbody {
    width: 100%;
    display: block;
    height: 40vh;
    overflow: auto;
  }
  th,
  td {
    text-align: left;
  }
  th {
    ${({ theme }) =>
      getTypography(
        theme,
        'Body 3',
        theme.tokens.color.alias.cm.text['text-subtle'].value
      )}
  }
  tr:last-child {
    border: none;
    padding-bottom: 0;
  }
  tr {
    padding: 0 8px;
    height: 80px;
    display: flex;
    align-items: center;
  }
  thead {
    tr {
      padding: 0;
      height: fit-content;
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid;
      border-color: ${({ theme }) => theme.colors.dark['04']};
    }
  }
  .firstCol {
    display: flex;
    gap: 8px;
    width: 240px;
    align-items: center;
  }
  .secondCol {
    flex: 1;
  }
  div[class*='indicatorContainer'] {
    padding: 8px;
  }
`;

const LoaderWrapper = styled.div`
  flex: 1;
  display: grid;
  place-items: center;
`;

export { CustomTable, Description, LoaderWrapper, ModalContent };
