import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Text } from '../../Text';
import BlockContent from '../../BlockContent';

export const StyledPart = styled.div<{ bgColor: string; textColor: string }>`
  padding: 8px;
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ textColor }) => textColor};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${({ theme }) => theme.typography.rubik};
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
`;

export const StyledText = styled(Text)`
  color: ${({ theme }) => theme.colors.dark['01']};
  p,
  li {
    margin-bottom: 8px !important;
  }
`;

export const List = styled.div``;

export const ListItem = styled.div<{ textColor: string }>`
  counter-increment: list;
  position: relative;
  margin-left: 18px;

  &:before {
    content: counter(list) '.';
    position: absolute;
    left: -2.2em;
    top: 2px;
    width: 2em;
    width: 2em;
    text-align: right;
    color: ${({ textColor }) => textColor};
  }
`;

export const InnerTipBlock = styled(BlockContent)`
  position: relative;
  padding: 16px 32px;
  height: fit-content;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.dark['06']};
  margin: 16px 0;

  .blockContentTitle,
  svg g path {
    color: ${({ theme }) => theme.colors.dark['02']} !important;
    stroke: ${({ theme }) => theme.colors.dark['02']} !important;
  }
  div {
    font-family: ${({ theme }) => theme.fonts.roboto} !important;
    font-size: ${({ theme }) => theme.sizes.small} !important;
  }

  div:first-of-type {
    position: absolute;
    left: 8px;
    top: 8px;
  }
`;

export const Wrapper = styled.div<{
  color: string;
  bgColor: string;
  titleColor: string;
  noPadding: boolean;
}>`
  border-radius: 4px;

  ${({ noPadding, bgColor }) =>
    !noPadding &&
    css`
      padding: 16px;
      padding-top: 12px;
      background-color: ${bgColor};
    `}

  .blockContentTitle {
    color: ${({ titleColor }) => titleColor};
  }

  .blockContent {
    color: ${({ color }) => color};
  }

  div {
    font-family: ${({ theme }) => theme.fonts.robotoMedium};
    font-size: ${({ theme }) => theme.sizes.normal};
  }
`;

export const Container = styled.div<{
  isBigImage: boolean;
  noMargin?: boolean;
}>`
  display: flex;
  gap: 24px;
  justify-content: space-between;

  ${({ isBigImage }) =>
    isBigImage &&
    css`
      width: 100%;
      flex-direction: column;
      img {
        width: 100%;
        height: auto;
      }
    `}

  ${({ isBigImage, noMargin }) =>
    !isBigImage &&
    !noMargin &&
    css`
      div:has(> img) {
        margin-top: -16px;
      }
    `}
`;
