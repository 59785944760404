import { css } from '@emotion/react';
import styled from '@emotion/styled';

const Wrapper = styled('div')`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const ChallengeNumber = styled('div')<{
  size: string;
  bgColor: string;
  color: string;
}>`
  font-size: ${({ theme, size }) =>
    size === 'md' ? theme.sizes.caption : theme.sizes.xmedium};
  font-family: ${({ theme }) => theme.typography.rubikSemibold};
  width: ${({ size }) => (size === 'md' ? '40px' : '32px')};
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ size }) => (size === 'md' ? '40px' : '32px')};
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};
  border-radius: 4px;

  svg,
  path {
    stroke: ${({ color }) => color};
  }

  @media (max-width: ${(props: any) => props.theme.breakpoints.sm}) {
    display: none;
  }
`;

const TitleWrapper = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.dark['01']};
`;

const Heading = styled('span')<{ size: string; color: string }>`
  font-size: ${({ theme, size }) =>
    size === 'md' ? theme.sizes.xsmall : theme.sizes.micro};
  letter-spacing: 0.115em;
  line-height: ${({ size }) => (size === 'md' ? '20px' : '15px')};
  text-transform: uppercase;
  font-weight: 700;
  color: ${({ color }) => color};
  font-family: ${({ theme }) => theme.typography.medium};
`;

const Title = styled('span')<{ size: string }>`
  ${({ size, theme }) =>
    size === 'md'
      ? css`
          color: ${theme.colors.dark['01']};
          font-size: ${theme.sizes.xmedium};
          font-family: ${theme.typography.medium};
          @media (max-width: ${theme.breakpoints.lg}) {
            font-size: ${theme.sizes.xmedium};
          }
        `
      : css`
          color: ${theme.colors.dark['02']};
          font-size: ${theme.sizes.small};
          font-family: ${theme.typography.regular};
          @media (max-width: ${theme.breakpoints.lg}) {
            font-size: ${theme.sizes.xsmall};
          }
        `}

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export { ChallengeNumber, TitleWrapper, Heading, Wrapper, Title };
