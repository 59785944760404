import styled from '@emotion/styled';
import { getTypography } from '../Typography';

export type TableTagType = 'neutral' | 'catchUp' | 'accent';

export type TableTagProps = {
  type: TableTagType;
  text: string;
};

const Container = styled.div<{ type: TableTagType }>`
  padding: 2px 6px;
  width: fit-content;
  border-radius: 4px;
  ${({ theme }) => getTypography(theme, 'Body 3')};

  background-color: ${({ type, theme }) => {
    switch (type) {
      case 'neutral':
        return theme.tokens.color.global.neutral.neutral200.value;
      case 'catchUp':
        return '#EAF7FB';
      case 'accent':
        return theme.tokens.color.alias.cm.bg['bg-accent'].value;
    }
  }};

  color: ${({ type, theme }) => {
    switch (type) {
      case 'neutral':
        return theme.tokens.color.alias.cm.text['text-subtle'].value;
      case 'catchUp':
        return '#2392AB';
      case 'accent':
        return theme.tokens.color.alias.cm.text['text-accent'].value;
    }
  }};
`;

export function TableTag({
  type = 'neutral',
  text,
}: TableTagProps): JSX.Element {
  return <Container type={type}>{text}</Container>;
}
