import { Icon } from '@innovamat/innova-components';
import styled from '@emotion/styled';
import { useDigitalGuides } from '../../../context';

const Duration = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.robotoMedium};
  svg,
  path {
    stroke: ${({ theme }) => theme.colors.brand.dark['02']};
  }
`;

type Props = {
  duration: string;
};

export default function MomentBlockDuration({ duration }: Props) {
  const { t } = useDigitalGuides();

  return (
    <Duration>
      <span>
        {t('digitalguides.se.duration', {
          duration,
        })}
      </span>
      <Icon icon="VideoDuration" />
    </Duration>
  );
}
