import styled, { css } from 'styled-components';

export const Form = styled.form<{
  displayError: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 24px;
  font-family: ${({ theme }) => theme.typography.rubik};

  > div {
    padding: 0px !important;
  }
  > div > label {
    font-family: ${({ theme }) => theme.typography.rubikMedium};
  }

  ${({ displayError }) => {
    if (displayError) {
      return css`
        input {
          border: 1px solid ${({ theme }) => theme.colors.semantic['03']} !important;
        }

        label {
          color: ${({ theme }) => theme.colors.semantic['03']};
        }
      `;
    }
    return css``;
  }}
`;

export const Footer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  font-family: ${({ theme }) => theme.typography.rubik};
  font-size: ${({ theme }) => theme.sizes.small};
`;

export const StyledLink = styled.a`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 600;
  font-size: ${({ theme }) => theme.sizes.small};
  line-height: 20px;
`;

export const SeparatorWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
  align-items: center;

  span {
    margin: 0 16px;
    font-weight: 400;
    font-size: ${({ theme }) => theme.sizes.small};
    line-height: 20px;
  }
`;

export const Separator = styled.div`
  display: flex;
  border-bottom: 2px solid ${({ theme }) => theme.colors.dark['05']};
  width: 100%;
  height: 2px;
`;

export const SignInWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const KnowMore = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 64px;
  > span {
    color: ${({ theme }) => theme.colors.dark['02']};
    margin-right: 4px;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    > span {
      margin-bottom: 4px;
    }
  }
`;
