import styled from '@emotion/styled';
import { Superman } from '@innovamat/glimmer-assets';
import { Button, Typography } from '@innovamat/glimmer-components';
import { useOnboardingForm } from '../context/onboarding-form-context';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;

const FlexEndContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  width: 100%;
`;

const TextSubtle = styled(Typography.Body2)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

export function WelcomeStep(): JSX.Element {
  const { t } = useTranslation();
  const { goToNextStep } = useOnboardingForm();

  return (
    <Container>
      <Superman />
      <FlexEndContainer>
        <TextSubtle>{t('onboarding-form.welcome-step.duration')}</TextSubtle>
        <Button onClick={() => goToNextStep()}>{t('common.start')}</Button>
      </FlexEndContainer>
    </Container>
  );
}
