import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPreparem = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M9.324.625v1.25M9.324 6.875v2.5M9.324 14.375v5M16.824 6.875H3.074v-5h13.75l2.058 2.058a.626.626 0 0 1 0 .884zM3.074 9.375h13.75v5H3.074l-2.057-2.058a.626.626 0 0 1 0-.885z"
    />
  </svg>
);
export default SvgPreparem;
