import styled from '@emotion/styled';
import { useEventListener } from '@innovamat/hooks';
import { useCallback, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';

const PortalWrapper = styled.div``;

type Props = {
  containerRef: React.RefObject<HTMLDivElement>;
  rootElement?: HTMLElement;
  zIndex?: number;
};

export function usePortal({
  containerRef,
  zIndex = 1000,
  rootElement = document.body,
}: Props): {
  createPortal: (children: JSX.Element) => JSX.Element;
} {
  const [portalStyle, setPortalStyle] = useState({});

  const handleSetPortalSize = useCallback((): void => {
    if (containerRef.current) {
      const scrollTop = document.documentElement.scrollTop;
      const { bottom, width, left } =
        containerRef.current.getBoundingClientRect();

      setPortalStyle({
        top: `${bottom + scrollTop}px`,
        width: `${width}px`,
        position: 'absolute',
        zIndex: zIndex,
        left: `${left}px`,
      });
    }
  }, [containerRef, zIndex]);

  useLayoutEffect(() => {
    handleSetPortalSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef.current, rootElement]);

  useEventListener('resize', handleSetPortalSize);
  useEventListener('scroll', handleSetPortalSize);

  const handleCreatePortal = (children: JSX.Element): JSX.Element => {
    return createPortal(
      <PortalWrapper style={portalStyle}>{children}</PortalWrapper>,
      rootElement
    );
  };

  return {
    createPortal: handleCreatePortal,
  };
}
