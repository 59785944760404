import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IconButton, Popover, State } from '@innovamat/glimmer-components';
import { IconType } from '@innovamat/glimmer-icons';

const ActionsContainer = styled.div<{ isIconVisible?: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${(props) =>
    props.isIconVisible
      ? css``
      : css`
          opacity: 0;
          pointer-events: none;
        `}
`;

type ActionsCellProps = {
  dropdownId: string;
  items: Array<CreateItem>;
  icon?: IconType;
  isIconVisible?: boolean;
};

type CreateItem = {
  text: string;
  icon: IconType;
  state: State;
  onSelectItem: () => void;
};

function ActionsDropdown({
  dropdownId,
  items,
  icon = 'OptionsIcon',
  isIconVisible,
}: ActionsCellProps): JSX.Element {
  return (
    <ActionsContainer className="action-cell" isIconVisible={isIconVisible}>
      <Popover
        id={'actions-dropdown-' + dropdownId}
        trigger={<IconButton icon={icon} size="L" aria-label="open menu" />}
      >
        <>
          {items.map(({ text, icon, state, onSelectItem }) => (
            <Popover.Item
              aria-label={text}
              icon={icon}
              state={state}
              onSelectItem={onSelectItem}
            >
              {text}
            </Popover.Item>
          ))}
        </>
      </Popover>
    </ActionsContainer>
  );
}

export { ActionsDropdown };
export type { CreateItem };
