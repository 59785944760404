import styled from '@emotion/styled';
import { ImageComponent } from '@innovamat/glimmer-components';
import { ReactComponent as Icon } from './play.svg';

const Circle = styled.div`
  width: 96px;
  height: 96px;
  background: rgb(0, 0, 0, 0.65);
  opacity: 80%;
  border-radius: 50%;
  display: grid;
  place-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
`;

const Thumbnail = styled(ImageComponent)`
  border-radius: 8px;
  width: 500px;
`;

const ImageAndIcon = styled.div`
  cursor: pointer;
  position: relative;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.white};
`;

interface PlayIconProps {
  thumbnail: string;
  title?: string;
  handleClick: () => void;
}

export default function PlayIcon({
  thumbnail,
  title,
  handleClick,
}: PlayIconProps) {
  return (
    <Wrapper>
      <ImageAndIcon
        onClick={() => {
          handleClick();
        }}
      >
        <Circle>
          <Icon />
        </Circle>
        <Thumbnail src={thumbnail} />
      </ImageAndIcon>
      {title && <Title dangerouslySetInnerHTML={{ __html: title }} />}
    </Wrapper>
  );
}
