
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as CbStatisticProbabilityIconComponent } from "./cb-statistic-probability-icon.svg";

export const CbStatisticProbabilityIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <CbStatisticProbabilityIconComponent {...props} />;
};
