export enum actions {
  SET_ERROR = 'SET_ERROR',
  CLEAN_ERRORS = 'CLEAN_ERRORS',
}

export enum keys {
  LOGIN = 'login',
  CONTENTS = 'contents',
  SETTINGS = 'settings',
  RECOVERY = 'recovery',
  REGISTER = 'register',
  LINK = 'link',
  USER_INVITATION = 'user_invitation',
  ORGANIZATION = 'organization',
  WEEKLY_PRACTICE = 'weekly_practice',
  TRAINING_ZONE = 'training_zone',
  GET_PREFERENCES = 'get_preferences',
  SET_ACCOUNT_DATA = 'SET_ACCOUNT_DATA',
  SKIP_ACTIVITY = 'SKIP_ACTIVITY'
}

export const actionSetError = (key: string, error: any): any => ({
  type: actions.SET_ERROR,
  key,
  error,
});

export const actionCleanErrors = (key?: string | undefined): any => ({
  type: actions.CLEAN_ERRORS,
  key,
});
