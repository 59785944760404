import * as React from 'react';
import type { SVGProps } from 'react';
const SvgELearningMonitor = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M.625.626h18.75v15H.625ZM13.125 19.376h-6.25l.625-3.75h5zM5 19.376h10M15.625 6.25v1.876"
      />
      <path
        strokeWidth={1.249995}
        d="M13.125 7.363v3.263a4.74 4.74 0 0 1-3.12 1.25 4.8 4.8 0 0 1-3.13-1.25V7.363"
      />
      <path strokeWidth={1.249995} d="M4.375 6.25 10 8.75l5.625-2.5L10 3.75z" />
    </g>
  </svg>
);
export default SvgELearningMonitor;
