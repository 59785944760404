/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import styled from '@emotion/styled';
import InputField from '../v2/InputField';
import { InputFieldProps } from '../v2/InputField/InputField';

interface Suggestion {
  html: string;
  value: string;
}
interface AutocompleteInputProps extends InputFieldProps {
  suggestionsList: Suggestion[];
  lastRow?: string;
}

const AutocompleteInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SuggestionsList = styled.ul`
  all: unset;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin: -20px 9px 0 9px;
  max-height: 213px;
  overflow-y: scroll;
  li {
    all: unset;
    display: block;
    padding: 16px;
    cursor: pointer;
    &:hover {
      background-color: ${(props: any) => props.theme.colors.dark['06']};
    }
    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      margin-top: 4px;
      color: ${(props: any) => props.theme.colors.dark['03']};
    }
    &.lastRow {
      display: flex;
      justify-content: center;
      margin: 0 16px;
      border-top: 1px solid ${(props: any) => props.theme.colors.dark['05']};
      &:hover {
        background-color: transparent;
      }
      a {
        font-weight: 600;
        text-decoration: underline;
        margin-left: 4px;
      }
    }
  }
`;

const AutocompleteInput = (props: AutocompleteInputProps) => {
  const { suggestionsList, lastRow, ...restProps } = props;

  const [filteredSuggestions, setFilteredSuggestions] = useState([
    { value: '', html: '' },
  ]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [input, setInput] = useState('');

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const userInput = e.target.value;

    const unLinked = suggestionsList.filter(
      (suggestion) =>
        suggestion.value.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    setInput(e.target.value);
    setFilteredSuggestions(unLinked);
    setShowSuggestions(true);
  };

  const onClick = (item: string) => {
    setFilteredSuggestions([]);
    setInput(item);
    setShowSuggestions(false);
  };

  return (
    <AutocompleteInputWrapper>
      <InputField
        {...restProps}
        onChange={onChange}
        value={input}
        type="search"
      />
      {showSuggestions && input && (
        <SuggestionsList aria-label="suggestions">
          {filteredSuggestions.map((item) => (
            <li
              onClick={() => {
                onClick(item.value);
              }}
              aria-hidden
              key={item.value}
              dangerouslySetInnerHTML={{ __html: item.html }}
            />
          ))}
          {lastRow && (
            <li
              className="lastRow"
              dangerouslySetInnerHTML={{ __html: lastRow }}
            />
          )}
        </SuggestionsList>
      )}
    </AutocompleteInputWrapper>
  );
};

export default AutocompleteInput;
