import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDrawerSend = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M14.307 12.5a4.96 4.96 0 0 1-8.614 0H2.5a1.25 1.25 0 0 0-1.25 1.25v3.75a1.25 1.25 0 0 0 1.25 1.25h15a1.25 1.25 0 0 0 1.25-1.25v-3.75a1.25 1.25 0 0 0-1.25-1.25ZM8.75 10V8.75a5 5 0 0 1 5-5h3.125"
      />
      <path strokeWidth={1.249995} d="m13.75 1.25 3.125 2.5-3.125 2.5" />
    </g>
  </svg>
);
export default SvgDrawerSend;
