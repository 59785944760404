
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as SubtractIconComponent } from "./subtract-icon.svg";

export const SubtractIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <SubtractIconComponent {...props} />;
};
