import styled from '@emotion/styled';

import { Icon, Tooltip, ButtonCircle } from '@innovamat/innova-components';

const Wrapper = styled('div')`
  position: relative;
  overflow-y: visible;
  height: fit-content;
`;

const Title = styled('h5')`
  color: ${({ theme }) => theme.colors.dark['00']};
  user-select: none;
  @media (max-width: ${(props: any) => props.theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.sizes.small};
  }
`;

const Description = styled('span')`
  display: inline-block;
  user-select: none;
  font-family: ${({ theme }) => theme.typography.regular};
  font-style: normal;
  font-weight: ${({ theme }) => theme.typography.weight.regular};
  font-size: ${({ theme }) => theme.sizes.small};
  line-height: ${({ theme }) => theme.typography.lineHeight.normal};
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.dark['01']};
  @media (max-width: ${(props: any) => props.theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.sizes.xsmall};
    line-height: ${({ theme }) => theme.typography.lineHeight.small};
  }
`;

const ButtonLeftWrapper = styled('div')`
  position: absolute;
  bottom: 18%;
  left: 12px;
  @media (max-width: ${(props: any) => props.theme.breakpoints.lg}) {
    left: 4px;
  }
`;

const ButtonRightWrapper = styled('div')`
  position: absolute;
  bottom: 18%;
  right: 12px;
  @media (max-width: ${(props: any) => props.theme.breakpoints.lg}) {
    right: 4px;
  }
`;

const ButtonCircleStyle = styled(ButtonCircle)`
  width: 24px;
  height: 24px;

  &:disabled {
    background-color: ${({ theme }) => theme.colors.dark['05']};
  }
`;

const StepperWrapper = styled('div')`
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
`;

const SpaceHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const PinIcon = styled(Icon)<{ isPinned: boolean }>`
  path {
    fill: ${({ isPinned, theme }) =>
      isPinned ? theme.colors.brand['01'] : 'none'};
    stroke: ${({ isPinned, theme }) => isPinned && theme.colors.brand['01']};
  }
`;

const SpacesPinButton = styled.div`
  align-self: center;
  padding: 6px;
  margin-bottom: 4px;
  cursor: pointer;
  border-radius: 4px;

  :hover {
    background-color: #33333310;
  }
`;

const StyledTooltip = styled(Tooltip)`
  font-family: ${({ theme }) => theme.typography.regular};
  background-color: ${({ theme }) => theme.colors.dark['01']};
`;

export {
  Wrapper,
  Title,
  Description,
  ButtonLeftWrapper,
  ButtonRightWrapper,
  ButtonCircleStyle,
  StepperWrapper,
  SpaceHeaderWrapper,
  PinIcon,
  SpacesPinButton,
  StyledTooltip,
};
