import React from 'react';
import cx from 'classnames';
import * as Icons from '../SvgIcons';
import Icon from '../Icon';
import styles from './Button.module.scss';
import Loader from '../Loader';

type Props = {
  color?: 'primary' | 'secondary' | 'default';
  size?: 'medium' | 'large';
  icon?: keyof typeof Icons;
  iconPosition?: 'left' | 'right';
  iconClassName?: string;
  fill?: boolean;
  align?: 'start' | 'end';
  iconSize?: 'sm' | 'md';
  loading?: boolean;
  ripple?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

function Button({
  children,
  color = 'primary',
  size = 'medium',
  icon,
  iconPosition = 'left',
  iconClassName = '',
  type = 'button',
  fill = false,
  align,
  className,
  iconSize,
  loading,
  ripple = true,
  ...rest
}: Props) {
  const btnClass = cx(styles.button, {
    [className as string]: !!className,
    ripple: !!ripple,
    'full-width': fill,
    [styles[size]]: true,
    [styles[color]]: true,
    [styles[align as string]]: !!align,
    [styles.withIcon]: !!icon,
    [styles[iconPosition]]: !!icon,
  });

  return (
    <button {...rest} type={type} className={btnClass}>
      {icon && !loading && (
        <Icon className={iconClassName} icon={icon} size={iconSize} />
      )}
      {loading ? (
        <Loader color={color === 'primary' ? 'white' : 'black'} />
      ) : (
        <span className="h6">{children}</span>
      )}
    </button>
  );
}

export default Button;
