import {
  AvatarCard,
  CardSelect,
  ClassroomAvatar,
  Icon,
  IconButton,
  Typography,
} from '@innovamat/glimmer-components';
import { Classroom, useCourses } from '@innovamat/glow-api-client';
import { getAvatarImage } from '../../../../../utils';
import { useMemo } from 'react';
import { useJoinToClassroom } from '../join-to-classroom-context';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useUser } from '../../../../user-management';

type ClassSelectCardProps = {
  classroom: Classroom;
  status?: 'default' | 'selected' | 'disabled';
  onClick?: () => void;
  onClickEdit?: () => void;
};

const Subtitle = styled(Typography.Body3)`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: -2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

const UpdatePeriodText = styled(Typography.Body3)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-accent'].value};
  font-weight: 500;
`;

export function ClassSelectCard({
  classroom,
  onClick,
  onClickEdit,
  status = 'default',
}: ClassSelectCardProps): JSX.Element {
  const { isNewClassroom, step } = useJoinToClassroom();
  const { user } = useUser();
  const { t } = useTranslation();
  const isNew = isNewClassroom(classroom.id);

  const isYearUpdate =
    !classroom?.checked ||
    (!!classroom?.provider && !classroom?.providerChecked);

  const { getCourseByCourseOrder } = useCourses({
    organizationId: user?.organizationId!,
    regionCode: user?.region!,
  });

  const avatarImage = useMemo(
    () =>
      'courseOrder' in classroom
        ? getAvatarImage({
            avatar: classroom.avatar,
            courseOrder: classroom.courseOrder || 0,
            icon: classroom.icon,
          })
        : '',
    [classroom]
  );

  const props = isNew
    ? {
        status:
          step === 3 ? ('disabled' as ClassSelectCardProps['status']) : status,
        onClick: undefined,
      }
    : { status: status, onClick: onClick };

  const getTooltipText = () => {
    if (isNew) {
      return t('join-to-classroom.new-classroom.tooltip');
    }

    if (isYearUpdate) {
      return t('classroom.updatePeriod.tooltip');
    }

    return '';
  };

  const getStatus = () => {
    if (isYearUpdate) {
      return 'imported';
    }

    return undefined;
  };

  const getCourseSubtitle = (): JSX.Element | string | undefined => {
    if (!classroom?.courseName) return;

    if (isYearUpdate) {
      return (
        <Subtitle>
          {getCourseByCourseOrder(classroom.courseOrder! - 1)?.name}
          <Icon icon="NextWithTextIcon" size="S" />
          <UpdatePeriodText>{classroom.courseName}</UpdatePeriodText>
        </Subtitle>
      );
    }

    return classroom?.courseName;
  };

  return (
    <CardSelect {...props}>
      <AvatarCard key={classroom?.id} semantic={isNew ? 'info' : undefined}>
        <ClassroomAvatar
          avatar={avatarImage}
          status={getStatus()}
          tooltipText={getTooltipText()}
        />

        <AvatarCard.Text subtitle={getCourseSubtitle()}>
          {classroom?.name}
        </AvatarCard.Text>
        {!isYearUpdate && classroom.provider === 'google' && (
          <Icon size="S" icon="GoogleClassroomIcon" />
        )}

        {isYearUpdate && (
          <IconButton
            size="S"
            icon="EditIcon"
            iconSize="S"
            tooltipText={
              status === 'disabled'
                ? undefined
                : t('classroom.updatePeriod.edit.tooltip')
            }
            state={status === 'disabled' ? 'disabled' : undefined}
            onClick={(event) => {
              event.stopPropagation();
              onClickEdit?.();
            }}
          />
        )}
      </AvatarCard>
    </CardSelect>
  );
}
