import styled from '@emotion/styled';
import { css } from '@emotion/react';
import {
  FullScreen,
  FullScreenHandle,
  useFullScreenHandle,
} from 'react-full-screen';
import { FullScreenWrapper } from '../../ResourceViewer.styles';

type Props = {
  src: string;
  fullScreen?: FullScreenHandle;
  isGuide?: boolean;
};

const Iframe = styled.iframe<{ isGuide?: boolean }>`
  display: block;
  position: relative;
  width: 100%;
  ${({ isGuide }) =>
    !isGuide
      ? css`
          height: 100%;
          min-height: calc(calc(var(--vh, 1vh) * 100) - 200px);
        `
      : css`
          height: auto;
          min-height: calc(calc(var(--vh, 1vh) * 100) - 313px);
        `}
`;

export function SingleGeniallyViewer({ src, fullScreen, isGuide }: Props) {
  const handleFS = useFullScreenHandle();

  return (
    <FullScreenWrapper data-testid="fullscreenWrapper">
      <FullScreen handle={fullScreen || handleFS}>
        <Iframe
          title="Genially Viewer"
          frameBorder="0"
          src={src}
          allowFullScreen
          scrolling="yes"
          isGuide={isGuide}
        />
      </FullScreen>
    </FullScreenWrapper>
  );
}
export default SingleGeniallyViewer;
