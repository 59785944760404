import styled from '@emotion/styled';
import { useGlimmerTheme } from '../../theme';
import { Skeleton } from '../../utils/skeleton';
import { Icon } from '../Icon';
import { Typography } from '../Typography';

export type BreadcrumbProps = {
  breadcrumbs: (string | undefined | null)[];
  goFrom?: () => void;
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  overflow: hidden;

  p:last-of-type {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const FromBreadcrumb = styled(Typography.Body2)`
  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.tokens.color.alias.cm.text['text'].value};
  }
`;

export function Breadcrumb({
  breadcrumbs,
  goFrom,
}: BreadcrumbProps): JSX.Element {
  const theme = useGlimmerTheme();

  if (breadcrumbs.some((breadcrumb) => !breadcrumb)) {
    return <Skeleton height="24" width="100" radius="4" />;
  }

  return (
    <Container>
      <FromBreadcrumb
        onClick={goFrom ? () => goFrom() : undefined}
        color={theme.tokens.color.alias.cm.text['text-subtle'].value}
      >
        {breadcrumbs[0]}
      </FromBreadcrumb>
      <Icon
        icon="NextIcon"
        iconColor={theme.tokens.color.alias.cm.icon['icon-disabled'].value}
        size="S"
      />
      <Typography.Body2 color={theme.tokens.color.alias.cm.text['text'].value}>
        {breadcrumbs[1]}
      </Typography.Body2>
    </Container>
  );
}
