export const typography = {
  regular: '"Roboto", sans-serif',
  medium: '"Roboto Medium", sans-serif',
  rubik: `'Rubik', sans-serif`,
  rubikMedium: `'Rubik Medium', sans-serif`,
  rubikSemibold: `'Rubik Semibold', sans-serif`,

  typeScale: {
    xlarge: '3rem', //    48px
    large: '2rem', //     32px
    caption: '1.5rem', // 24px
    medium: '1.25rem', // 20px
    normal: '1rem', //    16px
    small: '0.875rem', // 14px
    xsmall: '0.75rem', // 12px
    micro: '0.625', //    10px
  },

  weight: {
    light: 300,
    regular: 400,
    medium: 500,
    bold: 700,
  },

  lineHeight: {
    xlarge: '3.5rem', //   56px
    large: '2rem', //      32px
    caption: '1.75rem', // 28px
    medium: '1.5', //      24px
    normal: '1.25rem', //  20px
    small: '1rem', //      16px
    xsmall: '0.875rem', // 14px
    micro: '0.75rem', //   12px
  },
};
