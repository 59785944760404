import { PreprInnerHtml } from '../PreprInnerHtml';
import styled from '@emotion/styled';
import { Media } from '../Media';
import { MediaType } from '../../types/Media';
import { TableContentType } from '../../types/Table';
import { Table } from '../Table';
import { PrintableBody1 } from '../../printable-styles/printable-typography';

const StyledManagementComment = styled(PrintableBody1)`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.surface['surface-secondary'].value};
  gap: 8px;
  padding: 12px 16px;
  border-radius: 4px;
  color: ${({ theme }) => theme.tokens.color.alias.cm.text.text.value};

  > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  @media print {
    padding: 8px;
  }
`;

type Props = {
  text: string;
  media?: MediaType[] | null;
  tables?: TableContentType[];
};

export function ManagementComment({ text, media, tables }: Props): JSX.Element {
  const hasTable = tables?.length && tables.length > 0;
  return (
    <StyledManagementComment>
      <PreprInnerHtml text={text} />

      {media && <Media media={media} />}
      {hasTable && <Table tables={tables} />}
    </StyledManagementComment>
  );
}
