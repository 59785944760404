import styled from '@emotion/styled';

import { BadgeProps } from './Badge.types';

const BadgeWrapper = styled('div')<BadgeProps>`
  background: ${({ backgroundColor, theme }) =>
    backgroundColor || theme.colors.red['01']};
  color: ${({ color, theme }) => (color ? `${color}` : theme.colors.white)};
  width: fit-content;
  height: fit-content;
  font-family: ${({ theme }) => theme.typography.regular};
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.1em;
  font-size: 9px;
  line-height: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 6px;
  border-radius: 4px;
`;

const DefaultWrapper = styled.div`
  background: ${({ theme }) => theme.colors.semantic['02']};
  border-radius: 50%;
  height: 6px;
  position: absolute;
  right: 0;
  top: 0;
  width: 6px;
`;

export { BadgeWrapper, DefaultWrapper };
