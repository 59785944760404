
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as Part3GuidesIconComponent } from "./part3-guides-icon.svg";

export const Part3GuidesIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <Part3GuidesIconComponent {...props} />;
};
