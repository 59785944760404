import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCheckCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <circle cx={8} cy={8} r={7} fill="#14A395" />
    <path
      fill="#fff"
      d="M11.585 5.008a1.14 1.14 0 0 0-1.501.172l-3 3.558L5.8 7.558a1.12 1.12 0 0 0-.749-.264 1.12 1.12 0 0 0-.738.288.95.95 0 0 0-.313.68.94.94 0 0 0 .287.688l2.14 1.97c.202.181.474.282.757.28h.066c.151-.008.299-.046.433-.112.135-.065.253-.155.346-.265l3.745-4.432a1 1 0 0 0 .188-.344.9.9 0 0 0-.103-.749 1 1 0 0 0-.274-.29"
    />
  </svg>
);
export default SvgCheckCircle;
