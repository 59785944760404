import styled from '@emotion/styled';
import { Notification } from '@innovamat/innova-components';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    max-width: 45%;
  }
`;

type Props = {
  text: string;
  dataTestId?: string;
};

export default function ResourceNotification({ text, dataTestId }: Props) {
  return (
    <Wrapper>
      <Notification canClose={false} type="warning" dataTestId={dataTestId}>
        {text}
      </Notification>
    </Wrapper>
  );
}
