
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as SeenInClassIconComponent } from "./seen-in-class-icon.svg";

export const SeenInClassIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <SeenInClassIconComponent {...props} />;
};
