
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as FullScreenIconComponent } from "./full-screen-icon.svg";

export const FullScreenIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <FullScreenIconComponent {...props} />;
};
