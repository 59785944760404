import { useTranslation } from 'react-i18next';
import { isNullOrUndefined } from '../../utils/common';
import { Bar, Typography } from '@innovamat/glimmer-components';
import { getFixedLocalizedScore } from '../../utils/common';
import { Tooltip } from '@innovamat/glimmer-components';
import { getReportsTypography } from '../../utils/typography';
import styled from '@emotion/styled';
import { getProgressColor } from '../../utils/getProgressColor';

type Props = {
  completedStatements: number;
  completedApplets: number;
  totalApplets: number;
  isAverageRow?: boolean;
  showProgressBar?: boolean;
  isSecundaria?: boolean;
  totalStatements: number;
};

function AppletsWorkedOn({
  completedStatements,
  completedApplets,
  isAverageRow,
  isSecundaria,
  totalApplets,
  showProgressBar,
  totalStatements,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const getPercentage = (total: number, completed: number): number =>
    Math.ceil((completed * 100) / total);

  const hasFewerThanFourCompletedApplets = !!(
    completedApplets && completedApplets < 4
  );

  const getTooltipInfo = (): string | undefined => {
    if (!completedStatements) return;
    if (isAverageRow) {
      return getAverageAppletsTooltip();
    } else if (isSecundaria) {
      return getStudentsAppletsTooltip();
    } else {
      return tooltipStudentsPEandEY;
    }
  };

  const getAverageAppletsTooltip = (): string | undefined => {
    const tooltipEYPE = t(
      completedStatements === 1
        ? 'reports.statements.tooltip.singular'
        : 'reports.statements.tooltip',
      { statements: getFixedLocalizedScore(completedStatements) }
    );
    return isSecundaria ? '' : tooltipEYPE;
  };

  const getStudentsAppletsTooltip = (): string | undefined => {
    if (isNullOrUndefined(completedStatements)) return;
    return isSecundaria
      ? t(
          completedStatements === 1
            ? 'reports.statements.tooltip.singular'
            : 'reports.statements.tooltip',
          {
            statements: `${getFixedLocalizedScore(
              completedStatements
            )}/${getFixedLocalizedScore(totalStatements)}`,
          }
        )
      : t(
          completedStatements === 1
            ? 'reports.statements.tooltip.singular'
            : 'reports.statements.tooltip',
          {
            statements: `${getFixedLocalizedScore(completedStatements)}`,
          }
        );
  };

  const tooltipStudentsPEandEY = hasFewerThanFourCompletedApplets
    ? t('reports.score.notRepresentative')
    : getStudentsAppletsTooltip();

  const renderAppletsContent = (): JSX.Element => {
    if (isNullOrUndefined(completedApplets) || completedApplets === 0) {
      return isSecundaria ? (
        <NoDataAppletsSecondary>
          --/<span>--</span>
        </NoDataAppletsSecondary>
      ) : (
        <NoDataApplets>
          -<span>-</span>
        </NoDataApplets>
      );
    }

    const appletsDoneValue = getFixedLocalizedScore(completedApplets);

    return (
      <Tooltip
        content={getTooltipInfo()}
        popperOptions={{ strategy: 'fixed' }}
        maxWidth={200}
      >
        <div>
          {isAverageRow
            ? renderAverageApplets(appletsDoneValue)
            : renderStudentsApplets(appletsDoneValue)}
        </div>
      </Tooltip>
    );
  };

  const renderAverageApplets = (
    appletsDoneValue: string | number
  ): JSX.Element => {
    const suffixAverage = t('reports.appletsDonePerStudent.cell');
    return (
      <div>
        {appletsDoneValue}
        <Sufix>
          <span>{suffixAverage}</span>
        </Sufix>
      </div>
    );
  };

  const renderStudentsApplets = (
    appletsDoneValue: string | number
  ): JSX.Element => {
    const suffixStudents = t('reports.appletsDone.cell');
    return isSecundaria ? (
      <div>
        {`${completedApplets}/`}
        <Sufix>
          {totalApplets}
          <span>{suffixStudents}</span>
        </Sufix>
      </div>
    ) : (
      <AppletsDoneValue
        hasFewerThanFourCompletedApplets={hasFewerThanFourCompletedApplets}
      >
        {appletsDoneValue}
        <Sufix>{suffixStudents}</Sufix>
      </AppletsDoneValue>
    );
  };

  const completedPercentage = getPercentage(totalApplets, completedApplets);
  const progressBarColor = getProgressColor(completedPercentage);
  const barValues = [completedPercentage];
  const barColors = [progressBarColor];

  const renderProgressBar = (): JSX.Element => {
    if (isAverageRow && isNullOrUndefined(completedApplets)) {
      return <NoProgressBar />;
    }
    return completedApplets > 0 ? (
      <Bar
        values={barValues}
        colors={barColors}
        type="percentage"
        width={64}
        height={4}
      />
    ) : (
      <NoProgressBar />
    );
  };

  return (
    <Container>
      <AppletsDone>{renderAppletsContent()}</AppletsDone>
      {showProgressBar && renderProgressBar()}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 64px;
`;

const Sufix = styled(Typography.Subtitle2)`
  display: inline;
  span {
    margin-left: 2px;
  }
`;

const NoDataApplets = styled.div`
  color: ${({ theme }) =>
    theme.tokens.color.specific.reports.semantic['no-data'].value};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NoProgressBar = styled.div`
  width: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AppletsDone = styled.div`
  ${getReportsTypography()}
  cursor: default;
`;

const AppletsDoneValue = styled.div<{
  hasFewerThanFourCompletedApplets: boolean;
}>`
  color: ${({ hasFewerThanFourCompletedApplets, theme }) =>
    hasFewerThanFourCompletedApplets
      ? `${theme.tokens.color.specific.reports.semantic.low.value}`
      : `${theme.tokens.color.alias.cm.text.text.value}`};
`;

const NoDataAppletsSecondary = styled.div`
  color: ${({ theme }) =>
    theme.tokens.color.specific.reports.semantic['no-data'].value};
  margin: 2px 0;
`;

export default AppletsWorkedOn;
