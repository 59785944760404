import styled from '@emotion/styled';
import Card from '../Card';

const Container = styled(Card)`
  min-height: fit-content;
`;

const ContentWrapper = styled('div')`
  display: flex;
  width: 100%;
  padding: 1.5rem 0 1.5rem 1.5rem;
  flex-direction: column;
`;

const Header = styled('div')``;

const Title = styled('h5')``;

const Body = styled('div')`
  max-height: calc(100vh - 400px);
  overflow-y: auto;
  margin-top: 1rem;
  scrollbar-width: thin;
  ::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0.5);
  }

  ::-webkit-scrollbar {
    height: 7px;
    width: 7px;
    border-radius: 9px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.dark['04']};
    border-radius: 10px;
  }
`;

const MessageWrapper = styled('p')`
  height: 40px;
  margin-right: 1.5rem;
  color: ${({ theme }) => theme.colors.dark['03']};
`;

const Footer = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin-top: 2.5rem;
  margin-right: 1.5rem;
`;

export {
  Body,
  Container,
  ContentWrapper,
  Footer,
  Header,
  MessageWrapper,
  Title,
};
