
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as PlayerIconComponent } from "./player-icon.svg";

export const PlayerIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <PlayerIconComponent {...props} />;
};
