import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPaginateFilter2 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path strokeWidth={1.249995} d="M3.125.622h16.25v16.25H3.125Z" />
      <path
        strokeWidth={1.249995}
        d="M16.875 19.372h-15a1.25 1.25 0 0 1-1.25-1.25v-15"
      />
      <path
        strokeWidth={1.249995}
        d="M9.583 6.863a1.82 1.82 0 0 1 1.724-1.241h0a1.817 1.817 0 0 1 1.815 1.816h0a1.65 1.65 0 0 1-.483 1.167l-3.266 3.266h3.75"
      />
    </g>
  </svg>
);
export default SvgPaginateFilter2;
