import { jwtDecode } from 'jwt-decode';
import * as storage from './storage';
import { DecodedAccessToken, DecodedIdToken } from './types/token';
import { Assessor, TokenData, User } from './types/user';

let userApi = '';
let clientId = '';
let environment = '';

export const init = (
  _userApi: string | undefined,
  _clientId: string | undefined,
  _environment: string | undefined
): void => {
  userApi = _userApi!;
  clientId = _clientId!;
  environment = _environment!;
};

export const isTokenExpiringSoon = (token?: string) => {
  if (!token) return false;
  if (environment !== 'production' && environment !== 'prod') return false;
  const decodedToken = jwtDecode(token);
  const { exp } = decodedToken;
  if (!exp) return true;
  const currentTime = new Date();
  const timeDifference = exp * 1000 - currentTime.getTime();
  const timeLimit = 2 * 60 * 60 * 1000; // 2 hours
  return timeDifference < timeLimit;
};

export const refreshToken = async (): Promise<TokenData> => {
  const tokenInfo = storage.tokenInfo.get();
  if (!tokenInfo) throw new Error('No refresh token');

  const { refresh_token } = tokenInfo;

  const url = `${userApi}/auth/openid-connect/token`;

  const body = {
    grant_type: 'refresh_token',
    client_id: clientId,
    refresh_token,
  };

  const init: RequestInit = {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(body),
  };

  const response = await fetch(url, init);

  if (!response.ok) {
    throw new Error('Failed to refresh token');
  }

  const newTokens: TokenData = await response.json();

  storage.tokenInfo.set(newTokens);

  return newTokens;
};

export const mapTokenToUser = (data: TokenData): User => {
  const decodedIdToken: DecodedIdToken = jwtDecode(data.id_token!);
  const decodedAccessToken: DecodedAccessToken = jwtDecode(data.access_token!);

  return {
    uid: decodedAccessToken.sub,
    name: decodedIdToken.name,
    firstName: decodedIdToken.given_name,
    lastName: decodedIdToken.family_name,
    email: decodedIdToken.email,
    language: decodedIdToken.locale,
    school: decodedAccessToken.school,
    roles: decodedAccessToken.roles,
    school_name: 'Not Defined',
    crmId: decodedIdToken.crm_id,
    region: decodedIdToken.region,
  };
};

export const getAssessorIntegromat = async (
  crmId: string | null,
  school: string
): Promise<Assessor> => {
  const url = 'https://hook.integromat.com/okrzprwr0jk5w4ijvnr9cbzq7tskoame';
  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify({ crmid: crmId, uuid: school }),
  }).then((r) => r.json());
};

export const decodeToken = (token: TokenData) => {
  const decodedIdToken: DecodedIdToken = jwtDecode(token!.id_token!);
  const decodedAccessToken: DecodedAccessToken = jwtDecode(
    token!.access_token!
  );
  return { ...decodedIdToken, ...decodedAccessToken };
};
