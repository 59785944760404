import React, {
  DetailedHTMLProps,
  InputHTMLAttributes,
  Ref,
  useState,
} from 'react';
import styled from '@emotion/styled';

type InputProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export interface InputFieldProps extends InputProps {
  errorMessage?: string;
  correctMessage?: string;
  status?: 'error' | 'correct';
  label?: string;
  description?: string;
  ref?: Ref<HTMLInputElement>;
}
const InputFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const Label = styled.label<{ disabled?: boolean }>`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${(props: any) =>
    props.disabled
      ? props.theme.colors.dark['04']
      : props.theme.colors.dark['01']};
`;

const Input = styled.input<InputFieldProps>`
  height: 40px;
  outline: 1px solid ${(props: any) => props.theme.colors.dark['03']};
  border-radius: 4px;
  border: none;
  padding: 9px 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${(props: any) =>
    props.disabled
      ? props.theme.colors.dark['04']
      : props.theme.colors.dark['01']};
  outline-color: ${({ theme, status, disabled }) => {
    if (disabled) return theme.colors.dark['04'];
    if (status) {
      return status === 'correct'
        ? theme.colors.brand['01']
        : theme.colors.red.dark['01'];
    }
    return theme.colors.dark['03'];
  }};

  ::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 1em;
    width: 1em;
    border-radius: 50em;
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iOCIgY3k9IjgiIHI9IjgiIHRyYW5zZm9ybT0icm90YXRlKC0xODAgOCA4KSIgZmlsbD0iIzk0OTQ5NCIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTExLjMzMzQgMTEuMzMzM0MxMS43MDE2IDEwLjk2NTEgMTEuNzAxNiAxMC4zNjgyIDExLjMzMzQgOS45OTk5N0w5LjMzMzUzIDguMDAwMDhMMTEuMzMzNiA2LjAwMDA1QzExLjcwMTggNS42MzE4NiAxMS43MDE4IDUuMDM0OTEgMTEuMzMzNiA0LjY2NjcyQzEwLjk2NTQgNC4yOTg1MyAxMC4zNjg0IDQuMjk4NTMgMTAuMDAwMiA0LjY2NjcyTDguMDAwMiA2LjY2Njc1TDYuMDAwMSA0LjY2NjY1QzUuNjMxOTEgNC4yOTg0NiA1LjAzNDk2IDQuMjk4NDYgNC42NjY3NyA0LjY2NjY0QzQuMjk4NTggNS4wMzQ4MyA0LjI5ODU4IDUuNjMxNzkgNC42NjY3NyA1Ljk5OTk4TDYuNjY2ODcgOC4wMDAwOEw0LjY2NjkxIDEwQzQuMjk4NzIgMTAuMzY4MiA0LjI5ODcyIDEwLjk2NTIgNC42NjY5MSAxMS4zMzM0QzUuMDM1MSAxMS43MDE2IDUuNjMyMDUgMTEuNzAxNiA2LjAwMDI0IDExLjMzMzRMOC4wMDAyIDkuMzMzNDFMMTAuMDAwMSAxMS4zMzMzQzEwLjM2ODMgMTEuNzAxNSAxMC45NjUyIDExLjcwMTUgMTEuMzMzNCAxMS4zMzMzWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==')
      no-repeat 50% 50%;
    background-size: contain;
    cursor: pointer;
  }

  ::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: ${({ theme }) => theme.colors.dark['04']};
  }
  :hover {
    outline-color: ${({ theme, status, disabled }) => {
      if (disabled) return theme.colors.dark['04'];
      if (status)
        return status === 'correct'
          ? theme.colors.brand['01']
          : theme.colors.red.dark['01'];
      return theme.colors.dark['01'];
    }};
  }
  :focus {
    outline-width: 2px;
    ${({ theme, status, disabled }) => {
      if (disabled) return theme.colors.dark['04'];
      if (status)
        return status === 'correct'
          ? theme.colors.brand['01']
          : theme.colors.red.dark['01'];
      return theme.colors.dark['01'];
    }};
  }
`;
const PasswordBtn = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
`;
const InputMessage = styled.p<{
  status: InputFieldProps['status'];
  disabled?: boolean;
}>`
  font-weight: 400;
  font-size: 12px !important;
  line-height: 16px;
  color: ${({ theme, status, disabled }) => {
    if (!status && disabled) return theme.colors.dark['04'];
    if (status)
      return status === 'correct'
        ? theme.colors.brand['01']
        : theme.colors.red.dark['01'];
    return theme.colors.dark['03'];
  }};
`;

const Icon = styled.span`
  width: 16px;
  height: 16px;
  display: block;
`;

const CheckIcon = styled(Icon)`
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iOCIgY3k9IjgiIHI9IjgiIGZpbGw9IiMxNEEzOTUiLz4KPHBhdGggZD0iTTExLjU4NTIgNS4wMDc2QzExLjM2MTIgNC44NDcyIDExLjA3NzIgNC43NzUyNCAxMC43OTU1IDQuODA3NTZDMTAuNTEzOSA0LjgzOTg4IDEwLjI1NzggNC45NzM4MiAxMC4wODM1IDUuMTc5OTNMNy4wODM2OCA4LjczODExTDUuNzk5NTggNy41NTgwN0M1LjU5NjczIDcuMzg0MTMgNS4zMjg0MyA3LjI4OTQzIDUuMDUxMjEgNy4yOTM5M0M0Ljc3Mzk4IDcuMjk4NDMgNC41MDk0OCA3LjQwMTc4IDQuMzEzNDIgNy41ODIyQzQuMTE3MzYgNy43NjI2MiA0LjAwNTA2IDguMDA2MDMgNC4wMDAxNyA4LjI2MTE0QzMuOTk1MjggOC41MTYyNiA0LjA5ODE4IDguNzYzMTYgNC4yODcyIDguOTQ5ODNMNi40MjczNiAxMC45MTkzQzYuNjI5MyAxMS4xMDEgNi45MDExMiAxMS4yMDE5IDcuMTgzNTUgMTEuMkg3LjI0OTU0QzcuNDAxMTIgMTEuMTkxNSA3LjU0OTAxIDExLjE1MzUgNy42ODMzOSAxMS4wODg0QzcuODE3NzcgMTEuMDIzMyA3LjkzNTU1IDEwLjkzMjcgOC4wMjg5MiAxMC44MjI1TDExLjc3NDIgNi4zOTExNUMxMS44NjA3IDYuMjg4OTUgMTEuOTI0NCA2LjE3MjA1IDExLjk2MTcgNi4wNDcxNUMxMS45OTkgNS45MjIyNSAxMi4wMDkyIDUuNzkxOCAxMS45OTE2IDUuNjYzMjhDMTEuOTc0MSA1LjUzNDc2IDExLjkyOTEgNS40MTA2OCAxMS44NTk0IDUuMjk4MTdDMTEuNzg5NiA1LjE4NTY2IDExLjY5NjQgNS4wODY5MSAxMS41ODUyIDUuMDA3NloiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=);
`;

const ErrorIcon = styled(Icon)`
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iOCIgY3k9IjgiIHI9IjgiIHRyYW5zZm9ybT0icm90YXRlKC0xODAgOCA4KSIgZmlsbD0iI0NDMUYwMCIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTExLjMzMzQgMTEuMzMzM0MxMS43MDE2IDEwLjk2NTEgMTEuNzAxNiAxMC4zNjgyIDExLjMzMzQgOS45OTk5N0w5LjMzMzUzIDguMDAwMDhMMTEuMzMzNiA2LjAwMDA1QzExLjcwMTggNS42MzE4NiAxMS43MDE4IDUuMDM0OTEgMTEuMzMzNiA0LjY2NjcyQzEwLjk2NTQgNC4yOTg1MyAxMC4zNjg0IDQuMjk4NTMgMTAuMDAwMiA0LjY2NjcyTDguMDAwMiA2LjY2Njc1TDYuMDAwMSA0LjY2NjY1QzUuNjMxOTEgNC4yOTg0NiA1LjAzNDk2IDQuMjk4NDYgNC42NjY3NyA0LjY2NjY0QzQuMjk4NTggNS4wMzQ4MyA0LjI5ODU4IDUuNjMxNzkgNC42NjY3NyA1Ljk5OTk4TDYuNjY2ODcgOC4wMDAwOEw0LjY2NjkxIDEwQzQuMjk4NzIgMTAuMzY4MiA0LjI5ODcyIDEwLjk2NTIgNC42NjY5MSAxMS4zMzM0QzUuMDM1MSAxMS43MDE2IDUuNjMyMDUgMTEuNzAxNiA2LjAwMDI0IDExLjMzMzRMOC4wMDAyIDkuMzMzNDFMMTAuMDAwMSAxMS4zMzMzQzEwLjM2ODMgMTEuNzAxNSAxMC45NjUyIDExLjcwMTUgMTEuMzMzNCAxMS4zMzMzWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==);
`;

const PassHiddenIcon = styled(Icon)`
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEzLjYyOCA1LjgxODI0QzE0LjE1NzIgNi4yNTg4OSAxNC42NTczIDYuNzMzNDEgMTUuMTI1MSA3LjIzODgxQzE1LjMyMDUgNy40NDQwNiAxNS40Mjk0IDcuNzE2NTggMTUuNDI5NCA3Ljk5OTk1QzE1LjQyOTQgOC4yODMzMiAxNS4zMjA1IDguNTU1ODQgMTUuMTI1MSA4Ljc2MTA5QzEzLjkyMDUgMTAuMDU3MSAxMS4xODQ1IDEyLjU3MTQgOC4wMDA1NSAxMi41NzE0QzcuNjIzNDMgMTIuNTcwNSA3LjI0NzEzIDEyLjUzNjUgNi44NzU5OCAxMi40Njk3IiBzdHJva2U9IiM5NDk0OTQiIHN0cm9rZS13aWR0aD0iMS4xNDI4NiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik0yLjcyMDE5IDEwLjQ2MTZDMi4wNjEwMSA5Ljk0NDc5IDEuNDQ0MTkgOS4zNzYxMyAwLjg3NTYxNiA4Ljc2MTA0QzAuNjgwMjUgOC41NTU3OCAwLjU3MTI4OSA4LjI4MzI2IDAuNTcxMjg5IDcuOTk5OUMwLjU3MTI4OSA3LjcxNjUzIDAuNjgwMjUgNy40NDQwMSAwLjg3NTYxNiA3LjIzODc1QzIuMDgwMTkgNS45NDI3NSA0LjgxNjE5IDMuNDI4NDcgOC4wMDAxOSAzLjQyODQ3QzguNTU5NjggMy40MzA0OCA5LjExNjYgMy41MDQyMiA5LjY1NzMzIDMuNjQ3OSIgc3Ryb2tlPSIjOTQ5NDk0IiBzdHJva2Utd2lkdGg9IjEuMTQyODYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMTAuMjE4NyA4LjU1NDJDMTAuMTE4MyA4Ljk1NjM4IDkuOTEwNCA5LjMyMzY4IDkuNjE3MjggOS42MTY3OUM5LjMyNDE3IDkuOTA5OTEgOC45NTY4NyAxMC4xMTc4IDguNTU0NjkgMTAuMjE4MiIgc3Ryb2tlPSIjOTQ5NDk0IiBzdHJva2Utd2lkdGg9IjEuMTQyODYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNNS43MTQ4NCA3Ljk5OTk1QzUuNzE0ODQgNy4zOTM3NCA1Ljk1NTY2IDYuODEyMzYgNi4zODQzMSA2LjM4MzdDNi44MTI5NyA1Ljk1NTA1IDcuMzk0MzUgNS43MTQyMyA4LjAwMDU2IDUuNzE0MjMiIHN0cm9rZT0iIzk0OTQ5NCIgc3Ryb2tlLXdpZHRoPSIxLjE0Mjg2IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTE0LjI4NjMgMS43MTQyM0wxLjcxNDg0IDE0LjI4NTciIHN0cm9rZT0iIzk0OTQ5NCIgc3Ryb2tlLXdpZHRoPSIxLjE0Mjg2IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==);
`;

const PassIcon = styled(Icon)`
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1LjEyNDggNy4yMzg4N0MxNS4zMjAxIDcuNDQ0MTMgMTUuNDI5MSA3LjcxNjY1IDE1LjQyOTEgOC4wMDAwMkMxNS40MjkxIDguMjgzMzkgMTUuMzIwMSA4LjU1NTkgMTUuMTI0OCA4Ljc2MTE2QzEzLjkyMDIgMTAuMDU3MiAxMS4xODQyIDEyLjU3MTQgOC4wMDAxOSAxMi41NzE0QzQuODE2MTkgMTIuNTcxNCAyLjA4MDE5IDEwLjA1NzIgMC44NzU2MTYgOC43NjExNkMwLjY4MDI1IDguNTU1OSAwLjU3MTI4OSA4LjI4MzM5IDAuNTcxMjg5IDguMDAwMDJDMC41NzEyODkgNy43MTY2NSAwLjY4MDI1IDcuNDQ0MTMgMC44NzU2MTYgNy4yMzg4N0MyLjA4MDE5IDUuOTQyODcgNC44MTYxOSAzLjQyODU5IDguMDAwMTkgMy40Mjg1OUMxMS4xODQyIDMuNDI4NTkgMTMuOTIwMiA1Ljk0Mjg3IDE1LjEyNDggNy4yMzg4N1oiIHN0cm9rZT0iIzk0OTQ5NCIgc3Ryb2tlLXdpZHRoPSIxLjE0Mjg2IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTUuNzE0ODQgOC4wMDAwN0M1LjcxNDg0IDguNjA2MjggNS45NTU2NiA5LjE4NzY2IDYuMzg0MzEgOS42MTYzMUM2LjgxMjk3IDEwLjA0NSA3LjM5NDM1IDEwLjI4NTggOC4wMDA1NiAxMC4yODU4QzguNjA2NzcgMTAuMjg1OCA5LjE4ODE1IDEwLjA0NSA5LjYxNjggOS42MTYzMUMxMC4wNDU1IDkuMTg3NjYgMTAuMjg2MyA4LjYwNjI4IDEwLjI4NjMgOC4wMDAwN0MxMC4yODYzIDcuMzkzODYgMTAuMDQ1NSA2LjgxMjQ4IDkuNjE2OCA2LjM4MzgzQzkuMTg4MTUgNS45NTUxNyA4LjYwNjc3IDUuNzE0MzYgOC4wMDA1NiA1LjcxNDM2QzcuMzk0MzUgNS43MTQzNiA2LjgxMjk3IDUuOTU1MTcgNi4zODQzMSA2LjM4MzgzQzUuOTU1NjYgNi44MTI0OCA1LjcxNDg0IDcuMzkzODYgNS43MTQ4NCA4LjAwMDA3VjguMDAwMDdaIiBzdHJva2U9IiM5NDk0OTQiIHN0cm9rZS13aWR0aD0iMS4xNDI4NiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
`;

const InputMessageRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
`;

const InputField = ({
  errorMessage,
  label,
  description,
  correctMessage,
  type,
  ...rest
}: InputFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleType = () => {
    if (type === 'password') return showPassword ? 'text' : 'password';
    return type;
  };

  const customStyle = {
    width: '100%',
    ...(type === 'password' ? { paddingRight: 30 } : undefined),
  };

  return (
    <InputFieldContainer>
      <Label disabled={rest.disabled}>{label}</Label>
      <div style={{ position: 'relative', width: '100%' }}>
        <Input
          errorMessage={errorMessage}
          correctMessage={correctMessage}
          type={handleType()}
          style={customStyle}
          {...rest}
        />
        {type === 'password' && (
          <PasswordBtn
            title="password-icon"
            onClick={() => setShowPassword((s) => !s)}
          >
            {showPassword ? <PassHiddenIcon /> : <PassIcon />}
          </PasswordBtn>
        )}
      </div>
      <InputMessageRow>
        {rest.status &&
          (rest.status === 'correct' ? <CheckIcon /> : <ErrorIcon />)}
        <InputMessage status={rest.status} disabled={rest.disabled}>
          {(rest.status === 'correct' ? correctMessage : errorMessage) ??
            description}
        </InputMessage>
      </InputMessageRow>
    </InputFieldContainer>
  );
};

export default InputField;
