import {
  IconButton,
  Tooltip,
  SessionDone,
} from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useSessionReviewQuery } from '@innovamat/glow-api-client';
import { useMarkSessionDone, useSessionDone } from '../../../session-done';
import { ContentResource } from '../..';
import {
  StudentViewButton,
  useShowStudentView,
} from '../../../user-management';

const StyledSessionDoneContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    position: fixed;
    bottom: 16px;
    right: 16px;
    z-index: 1;
    box-shadow: ${({ theme }) => theme.elevations['01']};
    border-radius: 4px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

type Props = {
  resource: ContentResource | undefined;
};

export default function NewLayoutActions({ resource }: Props): JSX.Element {
  const { onMarkSession, onToggleSolutions } = useMarkSessionDone();
  const { onShowOnlyRatingModal, academicYearId, canRateSession, courseOrder } =
    useSessionDone();

  const { showStudentViewButton } = useShowStudentView({
    courseOrder: courseOrder,
    menu: 'curriculum',
  });

  const { t } = useTranslation();

  const onCheckListElement = (value: boolean): void => {
    if (!resource) return;
    onMarkSession({ resource, value });
  };

  const handleToggleSolutions = (value: boolean): void => {
    if (!resource) return;
    onToggleSolutions({ resource, value });
  };

  const handleRateSession = (): void => {
    if (!resource) return;
    onShowOnlyRatingModal(resource);
  };

  const { data } = useSessionReviewQuery(
    { id: resource?.id!, academicYearId },
    {
      enabled: Boolean(academicYearId && resource?.id),
    }
  );
  const isAlreadyRated = Boolean(data?.sessionReview?.rating);

  const canRate = canRateSession(resource!);

  function getTooltipContent(
    isAlreadyRated: boolean,
    canRate: boolean
  ): string {
    if (isAlreadyRated) {
      return 'session.sesion-review.reviewed';
    } else if (canRate) {
      return 'Valorar sesión';
    } else {
      return 'session.sesion-review.not-available';
    }
  }

  return (
    <Wrapper id="header-actions">
      {resource?.is_completable && (
        <StyledSessionDoneContainer>
          <SessionDone
            checked={resource?.is_done}
            onCheck={onCheckListElement}
            isActive={resource?.solutions_enabled || !resource?.has_solutions}
            onActive={handleToggleSolutions}
            textSessionComplete={t('session.completed', 'Sesion finalizada')}
            textActivateSolution={t(
              'contents.activateSolutions.message',
              'Activate solutions'
            )}
            showSolutions={resource?.has_solutions}
            dataTestIdButton="sessionDoneButton"
          />
        </StyledSessionDoneContainer>
      )}

      <Tooltip content={t(getTooltipContent(isAlreadyRated, canRate))}>
        <IconButton
          state={canRate ? undefined : 'disabled'}
          icon={isAlreadyRated ? 'SessionReviewedIcon' : 'FeedbackIcon'}
          onClick={handleRateSession}
        />
      </Tooltip>

      {showStudentViewButton && <StudentViewButton />}
    </Wrapper>
  );
}
