import * as React from 'react';
import type { SVGProps } from 'react';
const SvgEvaluate = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M8.38 5.733a3.733 3.733 0 1 0 7.467 0 3.733 3.733 0 0 0-7.467 0M17.447 11.067l-2.694-2.694M9.46 10.8a2.933 2.933 0 1 1-2.934-2.933M11.053 19.2A4.802 4.802 0 0 0 2 19.2M15.257 11.936a2.47 2.47 0 0 1-4.205 1.762"
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M16.6 19.2a4.043 4.043 0 0 0-6.535-1.64"
    />
  </svg>
);
export default SvgEvaluate;
