import { AppAction } from '../root/root.interfaces';
import { actions } from './loading.actions';
import { initialLoadingState, LoadingState } from './loading.interfaces';

function loadingReducer(
  state: LoadingState = initialLoadingState,
  action: AppAction
): any {
  switch (action.type) {
    case actions.SET_LOADING:
      return {
        ...state,
        [action.key]: true,
      };
    case actions.REMOVE_LOADING:
      // eslint-disable-next-line no-case-declarations
      const { [action.key]: deletedKey, ...otherKeys } = state;
      return {
        ...otherKeys,
      };
    default:
      return state;
  }
}

export default loadingReducer;
