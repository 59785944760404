import { useState, useEffect, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { InputChips, Modal } from '@innovamat/glimmer-components';
import styled from '@emotion/styled';

import { useInviteTeachersToOrganization } from '../../../../hooks/use-invite-teachers-to-organization';
import { teacherEmailValidationSnack } from './teacher-email-validation-snack';

const schema = yup.array().of(yup.string().email()).min(1);
const individualSchema = yup.string().email();

type Props = {
  onClose: () => void;
  onSuccess: () => void;
};

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

function InviteTeachersToOrganizationModal({
  onSuccess,
  onClose,
}: Props): JSX.Element | null {
  const { t } = useTranslation();

  const [emails, setEmails] = useState<string[]>([]);
  const [isValid, setIsValid] = useState(false);

  const handleSuccess = (): void => {
    onClose();
    onSuccess();
  };

  const { onInviteTeachers, isPending } = useInviteTeachersToOrganization({
    onError: (errors) => teacherEmailValidationSnack(errors, t),
    onSuccess: handleSuccess,
  });

  const handleUpdateMails = useCallback(
    async (chips: string[]): Promise<void> => {
      setEmails(chips);
      const valid = await schema.isValid(chips);
      setIsValid(valid);
    },
    []
  );

  const handleIsChipInvalid = (chip: string): boolean => {
    return !individualSchema.isValidSync(chip);
  };

  useEffect(() => {
    const validateEmails = async (): Promise<void> => {
      const valid = await schema.isValid(emails);
      setIsValid(valid);
    };

    validateEmails();
  }, [emails]);

  return (
    <Modal
      buttons={[
        {
          children: t('confirmModal.action.invite'),
          disabled: !isValid,
          loading: isPending,
          variant: 'accent',
          onClick: () => onInviteTeachers(emails),
        },
        {
          children: t('confirmModal.action.cancel'),
          variant: 'tertiary',
          onClick: onClose,
        },
      ]}
      isOpen
      modalWidth={600}
      onClose={onClose}
      title={t('inviteTeachersModal.addTeacher')}
    >
      <ModalContainer>
        {t('addTeachersModal.pasteEmails.info')}
        <InputChips
          canPasteEmails
          error={
            emails.length > 0 && !isValid
              ? {
                  name: 'Invalid Emails',
                  message: t('common.pasteValidEmails'),
                }
              : undefined
          }
          isChipInvalid={handleIsChipInvalid}
          onUpdateChips={handleUpdateMails}
        />
      </ModalContainer>
    </Modal>
  );
}

export { InviteTeachersToOrganizationModal };
