import styled from 'styled-components';

const StyledButton = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.dark['03']};
  color: ${({ theme }) => theme.colors.dark['01']};
  display: flex;
  flex-direction: row;
  font-size: ${({ theme }) => theme.sizes.small};
  line-height: 16px;
  gap: 12px;
  justify-content: center;
  padding: 12px;
  width: 100%;
  cursor: pointer;
  border-radius: 4px;
  position: relative;
  font-weight: bold;
  img {
    position: absolute;
    left: 15px;
  }
  @media screen and (max-width: 768px) {
    img {
      left: 8px;
    }
  }
`;

type Props = {
  img: string;
  text: string;
  onClick: () => void;
};

function SignInButton(props: Props): JSX.Element {
  const { img, text, onClick } = props;

  return (
    <StyledButton onClick={onClick}>
      <img src={img} alt={text} />
      {text}
    </StyledButton>
  );
}

export default SignInButton;
