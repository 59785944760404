import React from 'react';

type Props = {
  svgWidth: number;
  svgHeight: number;
  width: number | string;
  height: number | string;
  className?: string;
  element: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

const SVG = ({
  svgWidth,
  svgHeight,
  width,
  height,
  className,
  element,
  ...rest
}: Props) => {
  const Element = element;
  return (
    <Element
      className={className}
      viewBox={`0 0 ${svgWidth} ${svgHeight}`}
      style={{ width, height }}
      {...rest}
    />
  );
};

export default SVG;
