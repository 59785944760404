
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as JumpableIconComponent } from "./jumpable-icon.svg";

export const JumpableIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <JumpableIconComponent {...props} />;
};
