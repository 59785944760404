import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMapsMark = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M.625 5.217a1.25 1.25 0 0 1 .786-1.161l4.484-1.793a2.5 2.5 0 0 1 1.944.036l3.74 1.662a2.5 2.5 0 0 0 1.87.065l4.25-1.546a1.25 1.25 0 0 1 1.677 1.175v11.09a1.25 1.25 0 0 1-.823 1.174l-5.104 1.857a2.5 2.5 0 0 1-1.87-.065l-3.74-1.662a2.5 2.5 0 0 0-1.944-.036l-3.556 1.422a1.25 1.25 0 0 1-1.714-1.16Z"
      />
      <path
        strokeWidth={1.249995}
        d="M10.313 9.37a.313.313 0 0 1 .312.313M10 9.683a.313.313 0 0 1 .313-.313M10.313 9.995A.313.313 0 0 1 10 9.683M10.625 9.683a.313.313 0 0 1-.312.312M7.813 11.458a.313.313 0 0 1 .312.313M7.5 11.77a.313.313 0 0 1 .313-.312M7.813 12.083a.313.313 0 0 1-.313-.312"
      />
      <path
        strokeWidth={1.249995}
        d="M8.125 11.77a.313.313 0 0 1-.312.313M5.313 9.583a.313.313 0 0 1 .312.313M5 9.896a.313.313 0 0 1 .313-.313M5.313 10.208A.313.313 0 0 1 5 9.896M5.625 9.896a.313.313 0 0 1-.312.312M3.438 12.083a.313.313 0 0 1 .312.313M3.125 12.396a.313.313 0 0 1 .313-.313M3.438 12.708a.313.313 0 0 1-.313-.312M3.75 12.396a.313.313 0 0 1-.312.312M13.125 6.87l3.75 3.75M13.125 10.62l3.75-3.75"
      />
    </g>
  </svg>
);
export default SvgMapsMark;
