
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as UserIconComponent } from "./user-icon.svg";

export const UserIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <UserIconComponent {...props} />;
};
