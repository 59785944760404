import React, { useState, useMemo, useEffect, useCallback } from 'react';

type BaseImageProps = {
  src: string;
  quality?: number;
  placeholder?: string;
  isLazyLoading?: boolean;
  alt?: string;
};

type OptimizedImageProps = BaseImageProps & {
  hasOptimization: true;
  baseUrl: string;
};

type NonOptimizedImageProps = BaseImageProps & {
  hasOptimization?: false;
};

export type ImageProps =
  | (OptimizedImageProps & React.HTMLProps<HTMLImageElement>)
  | (NonOptimizedImageProps & React.HTMLProps<HTMLImageElement>);

function isOptimizedProps(props: ImageProps): props is OptimizedImageProps {
  return !!props.hasOptimization;
}

function getImageUrl(baseUrl: string, src: string): string {
  return `${baseUrl}/_next/image?url=${encodeURIComponent(src)}`;
}

// Utility to check if baseUrl is a valid URL
function isValidUrl(url: string): boolean {
  try {
    new URL(url);
    return true;
  } catch {
    return false;
  }
}

export function ImageComponent(props: ImageProps): JSX.Element {
  const {
    src,
    quality = 75,
    placeholder,
    alt,
    isLazyLoading = false,
    hasOptimization = false,
    ...rest
  } = props;

  const [loaded, setLoaded] = useState(false);
  const [isNextJs, setIsNextJs] = useState(false);
  const [NextImage, setNextImage] = useState<any>(null);

  let baseUrl = '';
  let imgProps = { ...rest };

  if (isOptimizedProps(props)) {
    baseUrl = props.baseUrl;
    const { baseUrl: _, hasOptimization: __, ...optimizedRestProps } = props;
    imgProps = optimizedRestProps;
  }

  const optimizedSrc = isOptimizedProps(props)
    ? `${getImageUrl(baseUrl, src)}&w=3840&q=${quality}`
    : src;

  const isAbsoluteUrl = /^(https?:)\/\//i.test(src);

  const widths = [640, 750, 828, 1080, 1200, 1920, 2048, 3840];
  const srcset = useMemo(() => {
    if (isOptimizedProps(props)) {
      return widths
        .map(
          (width) =>
            `${getImageUrl(baseUrl, src)}&w=${width}&q=${quality} ${width}w`
        )
        .join(', ');
    }
    return undefined;
  }, [baseUrl, src, quality]);

  const currentSrc = loaded || !placeholder ? optimizedSrc : placeholder;

  const imageStyles = useMemo(() => {
    if (!loaded && placeholder) {
      return { filter: 'blur(5px)' };
    }
    return {};
  }, [loaded, placeholder]);

  // Detect if the app is using Next.js and dynamically import `next/image`
  useEffect(() => {
    // Check if baseUrl is non-empty and valid
    if (baseUrl && isValidUrl(baseUrl)) {
      setIsNextJs(false);
      return; // No need to proceed with Next.js detection
    }

    // Otherwise, dynamically import next/image and check for Next.js environment
    import('next/image')
      .then((mod) => {
        setNextImage(() => mod.default); // Set next/image component if import succeeds
        setIsNextJs(true); // Mark as Next.js environment
      })
      .catch(() => setIsNextJs(false)); // If import fails, mark as non-Next.js
  }, [baseUrl]);

  // Fallback image render
  const renderFallbackImage = useCallback(
    () => (
      <>
        {isAbsoluteUrl ? (
          <img
            {...imgProps}
            alt={alt || ''}
            src={currentSrc}
            srcSet={srcset}
            sizes={hasOptimization ? '100vw' : undefined}
            onLoad={() => setLoaded(true)}
            loading={isLazyLoading ? 'lazy' : 'eager'}
            style={imageStyles}
          />
        ) : (
          <img {...imgProps} alt={alt || ''} src={src} />
        )}
      </>
    ),
    [
      imgProps,
      alt,
      currentSrc,
      srcset,
      hasOptimization,
      isLazyLoading,
      imageStyles,
      isAbsoluteUrl,
      src,
    ]
  );

  // Render next/image or fallback image
  return (
    <>
      {isNextJs && NextImage && baseUrl !== '' ? (
        <NextImage
          {...imgProps}
          src={src}
          alt={alt || ''}
          layout="responsive"
          width={1920} // Default or passed in width
          height={1080} // Default or passed in height
          quality={quality}
          placeholder={placeholder ? 'blur' : undefined}
          blurDataURL={placeholder}
          loading={isLazyLoading ? 'lazy' : 'eager'}
        />
      ) : (
        renderFallbackImage()
      )}
    </>
  );
}
