import { storage } from '@innovamat/radiance-utils';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-domv6';

import { getUser } from '../store/auth/selectors';
import authActions from '../store/auth/actions';
import useAppSelector from './useAppSelector';

export default function useAuthentication(): void {
  const user = useAppSelector(getUser);
  const location = useLocation();
  const tokenValues = useAppSelector((state: any) => state.auth.authValues);
  const dispatch = useDispatch();
  const isErrorPage = location.pathname === '/error';

  useEffect(() => {
    if (tokenValues?.access_token && !user?.uid && !isErrorPage) {
      dispatch(authActions.setUserRequest(tokenValues));
      dispatch(
        authActions.deviceCodeSuccess({
          device_code: storage.device.get() || '',
        })
      );
    }
  }, [tokenValues]);
}
