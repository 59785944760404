
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as OpenNewTabIconComponent } from "./open-new-tab-icon.svg";

export const OpenNewTabIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <OpenNewTabIconComponent {...props} />;
};
