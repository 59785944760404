import { all, put, takeLatest } from 'redux-saga/effects';
import { authService, storage } from '@innovamat/radiance-utils';
import { eventLogging } from '@innovamat/event-logging';
import { actionGetAccountPlatformData } from '../unityData/unityData.actions';
import authActions from './actions';
import authActionTypes from './actionTypes';
import { actionSetError, keys as errorKeys } from '../errors/errors.actions';

function* runSetUserRequest({
  payload,
}: ReturnType<typeof authActions.setUserRequest>): Generator {
  try {
    const user = authService.mapTokenToUser(payload);
    yield put(actionGetAccountPlatformData(user));
    storage.language.set(user.language);
    yield put(authActions.setUserRequestSuccess(user));
  } catch (error: any) {
    if (
      error?.response?.data?.type === 'user.is_disabled' ||
      error.message === 'user.is_not_allowed'
    ) {
      yield put(
        actionSetError(errorKeys.LOGIN, {
          error: 'user.is_disabled',
        })
      );
    }
    yield put(authActions.setUserError(error));
  }
}

export default function* authSagas(): Generator {
  yield all([takeLatest(authActionTypes.SET_USER_REQUEST, runSetUserRequest)]);
}
