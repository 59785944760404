import { useRef, useEffect } from 'react';

export function useUpdateEffect(callback: () => void, dependencies: any) {
  const isFirstRender = useRef(true);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      return callback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
}
