import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCamera = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M7.5 10.625a4.375 4.375 0 1 0 8.75 0 4.375 4.375 0 1 0-8.75 0"
      />
      <path
        strokeWidth={1.249995}
        d="m16.25 5-.904-1.81a1.25 1.25 0 0 0-1.118-.69H9.523a1.25 1.25 0 0 0-1.119.69L7.5 5H1.875a1.25 1.25 0 0 0-1.25 1.25v10a1.25 1.25 0 0 0 1.25 1.25h16.25a1.25 1.25 0 0 0 1.25-1.25v-10A1.25 1.25 0 0 0 18.125 5Z"
      />
      <path
        strokeWidth={1.249995}
        d="M3.438 7.5a.313.313 0 1 1-.313.313.313.313 0 0 1 .313-.313M4.375 5V3.75"
      />
    </g>
  </svg>
);
export default SvgCamera;
