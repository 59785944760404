import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import { ErrorPage, useUser as _useUser } from '@innovamat/ga-features';
import {
  Grounder,
  HeadingSection,
  Input,
  Loader,
} from '@innovamat/glimmer-components';
import { useDebounce } from '@innovamat/hooks';

import { CoursesDropdown } from './components/courses-dropdown';
import { FamilyPaymentEmptyState } from './components/family-payments-empty-state';
import { FamilyPaymentsTable } from './components/family-payments-table';
import { FamilyPaymentsTableSkeleton } from './components/family-payments-table-skeleton';

import { useInfiniteFamilyPayments } from './hooks/use-infinite-family-payments';

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const SearchContainer = styled.div`
  width: 17.5rem;
`;

const FamilyPayments = ({ useUser = _useUser }): JSX.Element => {
  const [searchedStudentName, setSearchedStudentName] = useState('');
  const [selectedCourseOrder, setSelectedCourseOrder] = useState('');

  const { user } = useUser();

  const { t } = useTranslation();

  const debouncedSearch = useDebounce(searchedStudentName, 300);

  const courseOrder =
    selectedCourseOrder === '' ? undefined : Number(selectedCourseOrder);

  const {
    familyPayments,
    isLoading,
    isError,
    isFetchedAfterMount,
    isFetchingNextPage,
  } = useInfiniteFamilyPayments({
    courseOrder,
    searchedStudentName: debouncedSearch,
    user,
  });

  const renderContent = (): JSX.Element => {
    if (isError) {
      return <ErrorPage errorType="" />;
    }

    if (isLoading || !isFetchedAfterMount) {
      return <FamilyPaymentsTableSkeleton />;
    }

    if (!familyPayments.length) {
      return (
        <FamilyPaymentEmptyState
          hasSearchedStudent={debouncedSearch.trim().length > 0}
        />
      );
    }

    return <FamilyPaymentsTable familyPayments={familyPayments} />;
  };

  return (
    <>
      <HeadingSection
        title={t('familyPayments.title')}
        actions={
          <ActionsContainer>
            <CoursesDropdown
              onSelectCourseOrder={setSelectedCourseOrder}
              selectedCourseOrder={selectedCourseOrder}
              user={user}
            />
            <SearchContainer>
              <Input
                leftIcon="SearchIcon"
                onChange={(e) => setSearchedStudentName(e.target.value)}
                placeholder={t('familyPayments.searchBoxPlaceHolder')}
                type="search"
                value={searchedStudentName}
              />
            </SearchContainer>
          </ActionsContainer>
        }
      />

      {renderContent()}

      {isFetchingNextPage && (
        <LoaderContainer>
          <Loader size="md" />
        </LoaderContainer>
      )}

      <Grounder />
    </>
  );
};

export { FamilyPayments };
