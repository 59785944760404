import { Course, useSitemapLevelsQuery } from '../../generated/types';

export enum Level {
  DEFAULT = 'DEFAULT',
  BASIC = 'BASIC',
}

type Props = {
  region: string;
  enabled: boolean;
};

type SitemapLevel = {
  label: string;
  value: string;
};

type useSitemapsValue = {
  getSitemapLevelsOptions: (
    courses: Course[],
    courseId: string
  ) => SitemapLevel[];
};

const levelLabels = {
  [Level.DEFAULT]: 'Default',
  [Level.BASIC]: 'Test',
};

export function useSitemapLevels({ region, enabled }: Props): useSitemapsValue {
  const { data } = useSitemapLevelsQuery(
    {
      region,
    },
    { enabled }
  );

  const getSitemapLevelsOptions = (
    courses: Course[],
    courseId: string
  ): SitemapLevel[] => {
    const courseOrder =
      courses.find((course) => course.id === courseId)?.order || '';

    const selectedLevel: SitemapLevel[] =
      data?.sitemapLevels
        .find((level) => level.key === `${courseOrder}`)
        ?.value.map((level) => ({
          label: levelLabels[level as keyof typeof levelLabels],
          value: level,
        })) || [];

    return selectedLevel;
  };

  return {
    getSitemapLevelsOptions,
  };
}
