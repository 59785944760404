import { authService } from '@innovamat/radiance-utils';
import { eventLogging } from '@innovamat/event-logging';

const init = (apiUsers: string): void => {
  authService.init(
    apiUsers,
    process.env.NX_USERS_CLIENT_ID,
    process.env.NX_CUSTOM_NODE_ENV
  );
  eventLogging.init({
    rudderstackApiKey: process.env.NX_RUDDERSTACK_APIKEY,
    rudderstackUrl: process.env.NX_RUDDERSTACK_URL,
  });
};

export default init;
