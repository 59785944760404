import styled from '@emotion/styled';
import { MinorText, ReportsTypography } from '../../admin-dashboard.styled';
import { Tooltip } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';

type Props = {
  completed: number;
  rowIsNull: boolean;
  lab?: number;
  adv?: number;
  total: number;
  classroomName: string;
  courseOrder: number;
};

const PlaceholderSessions = styled(ReportsTypography)`
  display: flex;
  align-items: flex-end;
  color: ${({ theme }) =>
    theme.tokens.color.specific.reports.semantic['no-data'].value};
`;

const DoubleDash = styled(ReportsTypography)`
  color: ${({ theme }) =>
    theme.tokens.color.specific.reports.semantic['no-data'].value};
`;

function CompletedSessions({
  completed,
  total,
  lab,
  adv,
  rowIsNull,
  courseOrder,
  classroomName,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const getTooltipContent = (): JSX.Element | undefined => {
    if (!lab && !adv) return;
    return (
      <>
        {!!lab && (
          <p>
            {t('reports.admin.session.lab')}: {lab}
          </p>
        )}
        {!!adv && (
          <p>
            {t('reports.admin.session.adv')}: {adv}
          </p>
        )}
      </>
    );
  };

  if (rowIsNull)
    return (
      <PlaceholderSessions>
        <DoubleDash>--</DoubleDash>/<MinorText>{total}</MinorText>
      </PlaceholderSessions>
    );
  return (
    <Tooltip content={getTooltipContent()}>
      <ReportsTypography
        data-testid={`${courseOrder}_${classroomName}_COMPLETEDSESSIONS`}
      >
        {completed}/<MinorText>{total}</MinorText>
      </ReportsTypography>
    </Tooltip>
  );
}

export default CompletedSessions;
