import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDownload = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M17.55 4.634c.235.234.367.552.367.883V17.5a1.877 1.877 0 0 1-1.877 1.873H3.956A1.875 1.875 0 0 1 2.083 17.5v-15A1.876 1.876 0 0 1 3.956.625h9.068c.332 0 .65.132.884.366z"
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M12.917.625v3.75a1.25 1.25 0 0 0 1.25 1.25h3.75M6.667 12.083l3.125 3.125 3.125-3.125M9.792 5.833v9.375"
    />
  </svg>
);
export default SvgDownload;
