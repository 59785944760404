import styled from '@emotion/styled';
import { Skeleton } from '@innovamat/glimmer-components';
import { useEffect } from 'react';

const Wrapper = styled.div`
  height: calc(calc(var(--vh, 1vh) * 100));
  max-width: 1440px;
  padding: 0 24px;

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    padding: 0 60px;
    width: 100%;
    margin: 0 auto;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    padding: 0 24px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: 0 16px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 24px;
  margin-bottom: 24px;
  padding-top: 24px;
`;

const HeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 300px;
`;

const Container = styled.div`
  padding: 24px 0px 64px 0px;
  display: flex;
  flex-direction: column;
  gap: 64px;
  max-width: 1440px;
  margin: 0 auto;
  min-height: calc(101vh - var(--heightHeader, 1vh));
`;

const GuideArea = styled.div`
  display: grid;
  grid-template-columns: 60% 1fr;
  gap: 24px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    grid-template-columns: 100%;

    > div {
      width: 100%;
    }
  }
`;

const PlayerBlockCol = styled.div``;

const StickyContainer = styled.div`
  width: 100%;
  position: sticky;
  top: calc(var(--heightHeader, 1vh) + 16px);
`;

const PartsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Section = styled.section`
  display: flex;
  gap: 48px;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    gap: 24px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column;

    > div {
      width: 100%;
    }
  }
`;

const SummaryTagsContainer = styled.div`
  display: flex;
  gap: 48px;
  min-width: 50%;
  flex: 1;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    gap: 24px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    flex-direction: column;
    width: 55%;

    > div {
      width: 100%;
    }
  }
`;

const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 60%;
`;

const TagsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 40%;
`;

const MaterialsContainer = styled.div`
  width: 420px;
  min-width: 260px;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    width: auto;
    max-width: 420px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    max-width: 100%;
  }
`;

const ContentBlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
`;

function ContentBlockSkeleton() {
  return (
    <ContentBlockContainer>
      <Skeleton width="150px" height="14" radius="4" />
      <Skeleton width="100%" height="88" radius="4" />
    </ContentBlockContainer>
  );
}

function BlockSkeleton() {
  return (
    <PartsContainer>
      <Skeleton width="150px" height="14" radius="4" />
      <Skeleton width="100%" height="44" radius="4" />
    </PartsContainer>
  );
}

export function SmartGuideSkeleton() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Wrapper>
      <Header>
        <HeaderTitle>
          <Skeleton width="100%" height="33" radius="4" />
          <Skeleton width="100%" height="14" radius="4" />
        </HeaderTitle>
        <Skeleton width="150px" height="14" radius="4" />
      </Header>
      <Container>
        <Section>
          <SummaryTagsContainer>
            <SummaryContainer>
              <BlockSkeleton />
              <BlockSkeleton />
            </SummaryContainer>
            <TagsContainer>
              <ContentBlockSkeleton />
              <ContentBlockSkeleton />
            </TagsContainer>
          </SummaryTagsContainer>
          <MaterialsContainer>
            <Skeleton width="100%" height="300" radius="4" />
          </MaterialsContainer>
        </Section>
        <GuideArea>
          <PartsContainer>
            <Skeleton width="150px" height="24" radius="4" />
            <Skeleton width="100%" height="88" radius="4" />
            <Skeleton width="100%" height="88" radius="4" />
            <Skeleton width="100%" height="88" radius="4" />
          </PartsContainer>
          <PlayerBlockCol>
            <StickyContainer>
              <Skeleton width="100%" height="366px" radius="4" />
            </StickyContainer>
          </PlayerBlockCol>
        </GuideArea>
      </Container>
    </Wrapper>
  );
}
