const ACTION_TYPES = {
  INCREMENT: 'increment',
  DECREMENT: 'decrement',
  NOTIFICATION: 'notification',
} as const;

type ActionType = (typeof ACTION_TYPES)[keyof typeof ACTION_TYPES];

type Material = {
  controlsCounterValue: number;
  course: string;
  flagName: string;
  image: string;
  language: string;
  maxReturnableItems: number;
  name: string;
  quantity: number;
};

export type { ActionType, Material };
export { ACTION_TYPES };
