import styled from '@emotion/styled';

const Wrapper = styled('span')<{
  isOpaque: boolean;
  clampNumber: number;
  hasElipsis: boolean;
}>`
  ${({ hasElipsis, clampNumber }) =>
    hasElipsis &&
    `    
    -webkit-line-clamp:${clampNumber};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    `}

  color: ${({ theme, isOpaque }) =>
    isOpaque ? theme.colors.dark['03'] : theme.colors.dark['01']};

  margin-bottom: ${({ theme }) => theme.spaces.small};

  display: -webkit-box;
  &:first-of-type {
    margin-top: 2px;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export { Wrapper };
