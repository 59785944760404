import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import {
  Grid,
  Grounder,
  Modal,
  Typography,
} from '@innovamat/glimmer-components';

import { useParentLauncher } from './hooks/use-parent-launcher';

import {
  CardContainer,
  CardInfo,
  ErrorText,
} from './components/students-info/student-info.styled';
import {
  ParentFaqs,
  ParentShortcuts,
  ParentVideo,
  StudentsInfo,
} from './components';
import {
  LandingPageParentSkeleton,
  StudentsInfoSkeleton,
} from './landing-page-parent-skeleton';

export const ParentLandingContainer = styled(Grid.Container)`
  margin-top: 1.5rem;
`;

export const MainCol = styled(Grid.Col)`
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

export const StudentsCol = styled(Grid.Col)`
  height: 100%;
  position: sticky;
  top: 4rem;
`;

const StudentsDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StudentsDivDesktop = styled(StudentsDiv)`
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

export const StudentsDivMobile = styled.div`
  display: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: flex;
  }
`;

function LandingPageParent(): JSX.Element {
  const [isNoAccessModalOpen, setIsNoAccessModalOpen] = useState(false);
  const [studentName, setStudentName] = useState('');
  const { t } = useTranslation();
  const {
    data,
    isStudentError,
    isPreprLoading,
    isStudentsLoading,
    refetchStudentsInfo,
  } = useParentLauncher();

  const handleStudentNoAccessModal = (studentName: string): void => {
    setStudentName(studentName);
    setIsNoAccessModalOpen(true);
  };

  const renderStudentsInfo = ({
    isMobile,
  }: {
    isMobile: boolean;
  }): JSX.Element => {
    if (isStudentsLoading) {
      return <StudentsInfoSkeleton isMobile={isMobile} />;
    }

    if (isStudentError) {
      return (
        <>
          <Typography.H3>{t('parent-launcher.students.title')}</Typography.H3>
          <CardContainer>
            <CardInfo>
              <Typography.Subtitle1>
                {t('parent-launcher.students.error.title')}
              </Typography.Subtitle1>
              <ErrorText>
                <Trans
                  i18nKey="parent-launcher.students.error.description"
                  components={{
                    a: (
                      <Typography.Link1
                        role="link"
                        onClick={refetchStudentsInfo}
                      />
                    ),
                  }}
                />
              </ErrorText>
            </CardInfo>
          </CardContainer>
        </>
      );
    }

    return (
      <StudentsInfo
        onNoAccessClick={handleStudentNoAccessModal}
        students={data?.students}
      />
    );
  };

  if (isPreprLoading) {
    return <LandingPageParentSkeleton />;
  }

  return (
    <ParentLandingContainer>
      <Grid.Row justifyContent="center">
        <MainCol md={8} lg={7}>
          <ParentVideo
            title={data?.title}
            url={data?.video?.url}
            thumbnail={data?.video?.cover}
            link={data?.more_info_url}
          />
          <StudentsDivMobile>
            {renderStudentsInfo({ isMobile: true })}
          </StudentsDivMobile>

          <ParentShortcuts shortcuts={data?.shortcuts} />
          <ParentFaqs questions={data?.faqs} />
        </MainCol>

        <StudentsCol md={4} lg={3}>
          <StudentsDivDesktop>
            {renderStudentsInfo({ isMobile: false })}
          </StudentsDivDesktop>
        </StudentsCol>
      </Grid.Row>
      <Grounder />

      {isNoAccessModalOpen && (
        <Modal
          title={t('noHomeAccessModal.title')}
          isOpen={isNoAccessModalOpen}
          onClose={() => setIsNoAccessModalOpen(false)}
          modalWidth={700}
          buttons={[
            {
              children: t('common.close'),
              onClick: () => setIsNoAccessModalOpen(false),
            },
          ]}
        >
          <Typography.Body2>
            {t('noHomeAccessModal.message', { studentName })}
          </Typography.Body2>
        </Modal>
      )}
    </ParentLandingContainer>
  );
}

export { LandingPageParent };
