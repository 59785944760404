// const AMPLIFY_DOMAIN = 'amplifyapp.com';
const INNOVAMAT_DOMAIN = 'innovamat.com';
const INNOVAMAT_DOMAIN_PRE = 'innovamat.cloud';

const isProd =
  typeof document !== 'undefined'
    ? document.domain?.split('.').reverse().splice(0, 2).reverse().join('.') ===
      INNOVAMAT_DOMAIN
    : false;

// const isOnDemandEnv =
//   typeof document !== 'undefined'
//     ? document.domain?.split('.').reverse().splice(0, 2).reverse().join('.') ===
//       AMPLIFY_DOMAIN
//     : false;

export const getDomain = (): string => {
  // if (isOnDemandEnv) return `.${AMPLIFY_DOMAIN}`;
  if (isProd) return `.${INNOVAMAT_DOMAIN}`;
  return `.${INNOVAMAT_DOMAIN_PRE}`;
};

export function getRootDomain(): string {
  const hostname = window.location.hostname;
  const parts = hostname.split('.');
  if (parts.length > 2) {
    return parts.slice(-2).join('.');
  }
  return hostname;
}
