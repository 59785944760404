import styled from '@emotion/styled';

export const Text = styled.div`
  table {
    border-spacing: 4px;
    font-size: 14px;
  }

  td {
    color: ${({ theme }) => theme.colors.dark['01']};
    padding: 8px;
    vertical-align: text-top;
  }

  ul,
  ol {
    margin-left: 16px;
  }

  th {
    color: ${({ theme }) => theme.colors.dark['01']};
    background-color: ${({ theme }) => theme.colors.dark['06']};
    padding: 16px 0;
    border-radius: 4px;
  }
`;
