import { useEffect } from 'react';

function useAppHeight(): void {
  useEffect(() => {
    // Hack for mobile browsers to simulate 100vh considering address bar
    function appHeight(): void {
      const doc = document.documentElement;
      doc.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    }
    window.addEventListener('resize', appHeight);
    appHeight();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', appHeight);
  }, []);
}

export default useAppHeight;
