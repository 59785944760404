import styled from '@emotion/styled';
import { Avatar, AvatarBaseProps } from '../avatar';
import { getColorByStage } from './get-color-by-stage';

export type AvatarStageProps = {
  courseName: string;
  courseOrder: number;
} & AvatarBaseProps;

const StyledAvatar = styled(Avatar)<{ courseOrder: number }>`
  color: ${({ theme }) => theme.tokens.color.global.white.value};
  background-color: ${({ theme, courseOrder }) =>
    getColorByStage(courseOrder, theme)};
`;

export function AvatarStage({
  courseName,
  courseOrder,
  ...rest
}: AvatarStageProps): JSX.Element {
  return <StyledAvatar text={courseName} courseOrder={courseOrder} {...rest} />;
}
