import styled from '@emotion/styled';
import {
  Button,
  Checkbox,
  Grid,
  Grounder,
  Input,
  ToggleButton,
  Typography,
} from '@innovamat/glimmer-components';
import { useSubscriptionsByEmailQuery } from '@innovamat/glow-api-client';
import { validators } from '@innovamat/radiance-utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEmailPreferences } from '../../hooks';
import { BlocksPreferences, ElectrifiedCat } from '@innovamat/glimmer-assets';

type EmailPreferencesProps = {
  email: string;
};

const Col = styled(Grid.Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const Subtitle = styled(Typography.Body1)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

const TitleWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const EmailWrapper = styled.section`
  width: 100%;
`;

const SubscriptionsWrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const CancelWrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
`;

const ButtonsWrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-bottom: 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    flex-direction: column;
  }
`;

const Description = styled(Typography.Body2)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

const SubscriptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

const ImageWrapper = styled.div`
  position: fixed;
  right: 60px;
  bottom: -10px;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    display: none;
  }
`;

export function EmailPreferences({
  email,
}: EmailPreferencesProps): JSX.Element {
  const { t } = useTranslation();

  const {
    updatedSubscriptions,
    unsubscribeAll,
    hasSomethingChanged,
    isEverythingUnsubscribed,
    toggleSubscription,
    resetValues,
    handleSave,
    isPending,
  } = useEmailPreferences({ email });

  return (
    <Grid.Container>
      <Grid.Row justifyContent="center">
        <Col xs={6} lg={5}>
          <TitleWrapper>
            <Typography.H2> {t('settings.emailing.title')} </Typography.H2>
            <Subtitle> {t('settings.emailing.subtitle')} </Subtitle>
          </TitleWrapper>

          <EmailWrapper>
            {email && (
              <Input
                labelText={t('settings.emailing.email')}
                status="autofilled"
                value={email}
              />
            )}
          </EmailWrapper>

          <SubscriptionsWrapper>
            {updatedSubscriptions.map((subscription) => (
              <SubscriptionContainer key={subscription.value}>
                <div>
                  <Typography.Subtitle2>
                    {t(subscription.name)}
                  </Typography.Subtitle2>
                  <Description>{t(subscription.description)}</Description>
                </div>
                <Checkbox
                  checked={subscription.active}
                  onChange={() => {
                    toggleSubscription(subscription.value);
                  }}
                />
              </SubscriptionContainer>
            ))}
          </SubscriptionsWrapper>

          <CancelWrapper>
            <Typography.H4>
              {t('settings.emailing.unsubscribe_all')}
            </Typography.H4>
            <ToggleButton
              checked={isEverythingUnsubscribed}
              onToggle={() => unsubscribeAll()}
            />
          </CancelWrapper>

          <ButtonsWrapper>
            <Button
              variant="tertiary"
              disabled={!hasSomethingChanged || isPending}
              onClick={() => resetValues()}
            >
              {t('settings.emailing.CTA.reset_changes')}
            </Button>
            <Button
              loading={isPending}
              disabled={!hasSomethingChanged || isPending}
              onClick={() => handleSave()}
            >
              {t('settings.emailing.CTA.save')}
            </Button>
          </ButtonsWrapper>
        </Col>
      </Grid.Row>
      <ImageWrapper>
        <BlocksPreferences />
      </ImageWrapper>
    </Grid.Container>
  );
}
