import { storage } from '@innovamat/radiance-utils';
import { AuthActionType } from './actions';
import authActionTypes from './actionTypes';
import { AuthState } from './types';

export const inititalAuthState: AuthState = {
  loading: false,
  loggedIn: !!storage.tokenInfo.get(),
  user: undefined,
  authValues: storage.tokenInfo.get(),
  deviceCode: undefined,
  assessor: undefined,
};

function authReducer(
  state = inititalAuthState,
  action: AuthActionType
): AuthState {
  switch (action.type) {
    case authActionTypes.SET_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case authActionTypes.LOGIN_SUCCESS:
    case authActionTypes.REFRESH_TOKEN:
      return {
        ...state,
        authValues: action.payload,
      };
    case authActionTypes.DEVICE_CODE_SUCCESS:
      return {
        ...state,
        deviceCode: action.payload.device_code,
      };
    case authActionTypes.SET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: {
          ...state.user,
          ...action.payload,
        },
        loggedIn: true,
      };

    default:
      return state;
  }
}

export default authReducer;
