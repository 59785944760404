import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Typography } from '../Typography/Typography';
import { LittleCheck } from '../LittleCheck';
import { StateLayer } from '../../utils/common.styled';

type ListElementState = 'active' | 'disabled' | 'selected';

export type ListElementProps = {
  state: ListElementState;
  isChecked: boolean;
  isCheckDisabled?: boolean;
  onCheck?: (value: boolean) => void;
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  customStyles?: string;
  hasCheckbox?: boolean;
  solutions?: boolean;
};

const StyledListElement = styled.div<{
  state: ListElementState;
  customStyles?: string;
}>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;

  ${({ state, theme }) =>
    state === 'active' &&
    css`
      color: ${theme.tokens.color.alias.cm.text['text-subtle'].value};

      &:hover .listElement-stateLayer {
        border-radius: 4px;
        background-color: ${theme.tokens.color.specific['state-layer'][
          'state-hover-darker'
        ].value};
      }

      &:active .listElement-stateLayer {
        border-radius: 4px;
        background-color: ${theme.tokens.color.specific['state-layer'][
          'state-press-darker'
        ].value};
      }
    `}

  ${({ state, theme }) =>
    state === 'selected' &&
    css`
      background-color: ${theme.tokens.color.alias.cm.bg['bg-accent'].value};
      color: ${theme.tokens.color.alias.cm.text['text-accent'].value};
    `}

  ${({ state, theme }) =>
    state === 'disabled' &&
    css`
      pointer-events: none;
      cursor: default;
      background-color: ${theme.tokens.color.alias.cm.surface['surface-primary']
        .value};
      color: ${theme.tokens.color.alias.cm.text['text-disabled'].value};
    `}

    ${({ customStyles }) =>
    customStyles &&
    css`
      ${customStyles}
    `}
`;

const Text = styled(Typography.Body2)`
  :has(.overflow) {
    overflow: hidden;
  }
  .overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export function ListElement({
  state,
  children,
  isChecked,
  isCheckDisabled,
  onCheck,
  onClick,
  customStyles,
  hasCheckbox = true,
  solutions,
}: ListElementProps) {
  return (
    <StyledListElement
      state={state}
      onClick={onClick}
      customStyles={customStyles}
    >
      {state !== 'disabled' && (
        <StateLayer className="listElement-stateLayer" />
      )}
      {hasCheckbox && (
        <LittleCheck
          checked={isChecked}
          onCheck={onCheck}
          disabled={state === 'disabled' || isCheckDisabled}
          isConfettiAnimationEnabled={true}
          solutions={solutions}
        />
      )}

      <Text>{children}</Text>
    </StyledListElement>
  );
}
