import styled from '@emotion/styled';
import {
  ImageComponent,
  InnerHtml,
  Tooltip,
} from '@innovamat/glimmer-components';

const StyledTippy = styled(Tooltip)`
  background-color: transparent;
  border-radius: 8px;
  .tippy-content {
    padding: 4px;
  }
`;

const ImgContainer = styled.div`
  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.bg['bg-neutral-inverted'].value};
  border-radius: 8px;
  padding: 4px;
`;

const TextContainer = styled.div`
  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.bg['bg-neutral-inverted'].value};
  border-radius: 4px;
  padding: 4px 8px;
  text-align: center;
  width: 100%;
`;

const Wrapper = styled.div<{ hasImage: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: ${({ hasImage }) => (hasImage ? 'min-content' : 'auto')};
`;

const StyledImage = styled(ImageComponent)`
  display: block;
  width: auto;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
`;

type Props = {
  image?: string;
  text?: string;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  children: JSX.Element;
};

export function ImageTooltip({
  image,
  text,
  placement = 'top',
  children,
}: Props): JSX.Element {
  if (!image && !text) return children;

  return (
    <StyledTippy
      content={
        <Wrapper hasImage={!!image}>
          {image && (
            <ImgContainer>
              <StyledImage src={image} />
            </ImgContainer>
          )}
          {text && (
            <TextContainer>
              <InnerHtml text={text} />
            </TextContainer>
          )}
        </Wrapper>
      }
      placement={placement ? placement : !image ? undefined : 'top'}
      offset={[0, 4]}
      touch={false}
      hideOnClick={false}
    >
      {children}
    </StyledTippy>
  );
}
