import React from 'react';

import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';

import { theme } from './theme';
import { grid } from './grid';

type ThemeProviderProps = {
  children: JSX.Element | JSX.Element[];
};

function ThemeProviderNoGlobals({ children }: ThemeProviderProps) {
  return (
    <EmotionThemeProvider theme={{ ...theme, grid }}>
      {children}
    </EmotionThemeProvider>
  );
}

export default ThemeProviderNoGlobals;
