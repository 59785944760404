import * as React from 'react';
import type { SVGProps } from 'react';
const SvgHandsOn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      clipPath="url(#Hands-on_svg__a)"
    >
      <path d="M3.532 10.014a2.021 2.021 0 1 0 0-4.043 2.021 2.021 0 0 0 0 4.043M16.468 10.014a2.021 2.021 0 1 0 0-4.043 2.021 2.021 0 0 0 0 4.043" />
      <path d="M13.636 19.194V13.63a5.25 5.25 0 0 1-4.496-3.57l-.696-2.087a1.213 1.213 0 0 1 .964-1.583 1.26 1.26 0 0 1 1.357.878l.676 2.027a2.83 2.83 0 0 0 2.684 1.932h3.553a1.616 1.616 0 0 1 1.619 1.617v6.355" />
      <path d="m8.721 8.8-.165.49a2.83 2.83 0 0 1-2.688 1.936h-3.55A1.617 1.617 0 0 0 .702 12.84v6.354M6.364 19.194V13.63A5.25 5.25 0 0 0 10 11.653M9.587.806v1.653M12.822 2.53l-1.17 1.168M6.352 2.53l1.169 1.168" />
    </g>
    <defs>
      <clipPath id="Hands-on_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgHandsOn;
