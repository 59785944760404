import styled from '@emotion/styled';

import { HeadingSection } from '../heading-section';

type SectionProps = {
  actions?: React.ReactNode;
  children: React.ReactNode;
  hasBackground?: boolean;
  subtitle?: string;
  title: string;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Background = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
`;

const BackgroundWithColor = styled(Background)`
  padding: 1rem;
  border: ${({ theme }) =>
    `1px solid ${theme.tokens.color.alias.cm.border['border-subtle'].value}`};
  border-radius: 1rem;
  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.surface['surface-secondary'].value};
`;

const Section = ({
  actions,
  children,
  hasBackground,
  subtitle,
  title,
}: SectionProps): JSX.Element => {
  return (
    <Container role="presentation">
      <HeadingSection actions={actions} subtitle={subtitle} title={title} />
      {hasBackground ? (
        <BackgroundWithColor>{children}</BackgroundWithColor>
      ) : (
        <Background>{children}</Background>
      )}
    </Container>
  );
};

export { Section };
export type { SectionProps };
