import * as AllGeneratedIcons from './lib/generated';
import * as AllFlags from './lib/flags';
import * as RegionFlags from './lib/flags-region';
import * as LegacyIcons from './lib/legacy';

export * from './types';
export const Icons = {
  ...AllGeneratedIcons,
  ...AllFlags,
  ...RegionFlags,
  ...LegacyIcons,
};
