import { SVGProps } from 'react';

export const VlcFlag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 12"
    fill="none"
    {...props}
  >
    <g clip-path="url(#clip0_2124_1847)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.05176e-05 0.375H18V11.625H3.05176e-05V0.375Z"
        fill="#FFD066"
      />
      <path d="M18 6.62485H0V7.87485H18V6.62485Z" fill="#CC1F00" />
      <path d="M18 9.12493H0V10.3749H18V9.12493Z" fill="#CC1F00" />
      <path d="M18 4.12477H0V5.37477H18V4.12477Z" fill="#CC1F00" />
      <path d="M18 1.62469H0V2.87469H18V1.62469Z" fill="#CC1F00" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 0.375H4.6V11.625H0V0.375Z"
        fill="#2365AB"
      />
    </g>
    <defs>
      <clipPath id="clip0_2124_1847">
        <rect y="0.375" width="18" height="11.25" rx="1.125" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
