const authActionTypes = {
  LOGIN_SUCCESS: 'LOGIN_SUCCESS' as const,

  SET_USER_REQUEST: 'SET_USER_REQUEST' as const,
  SET_USER_SUCCESS: 'SET_USER_SUCCESS' as const,
  SET_USER_ERROR: 'SET_USER_ERROR' as const,

  REFRESH_TOKEN: 'REFRESH_TOKEN' as const,

  DEVICE_CODE_SUCCESS: 'DEVICE_CODE_SUCCESS' as const,
};

export default authActionTypes;
