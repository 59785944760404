import * as React from 'react';
import type { SVGProps } from 'react';
const SvgOpenArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M10.334 7.999H2.999M6.332 11.333 2.998 7.999l3.334-3.334M13 12V3.999"
    />
  </svg>
);
export default SvgOpenArrow;
