import { JSONObject } from '../root/root.interfaces';
import { getElectronData, isElectronApp } from '../../utils/electronApp';
import { API_SOLVER, API_PLATFORM } from './unityData.dataSource';

export interface UnityDataState {
  Tokens: {
    AccessToken: string | undefined;
    RefreshToken: string | undefined;
    IdToken: string | undefined;
    clientID: string | undefined;
  };
  language: string | undefined;
  sceneName: string | undefined;
  previousSceneName?: string | undefined;
  packOrVariation?: string | undefined;
  SceneName: string | undefined;
  bridgeIsReady: boolean;
  Account?: JSONObject;
  Classrooms?: JSONObject;
  Students?: JSONObject;
  selectedStage: string;
  PlatformUrl: string | undefined;
  SolverUrl: string | undefined;
  HasPlayedSession?: boolean;
  sessionEntity?: SessionEntity;
  quizSessionEntity?: QuizSessionEntity;
  regionParameters?: JSONObject;
  User?: JSONObject;
  userCoins?: number;
  userGems?: number;
  startLoadingTime?: {
    fromScene: string | undefined;
    time: number;
  };
  biomeId: number;
  sessionType?: string;
  quizNextActivity: boolean;
  show403Modal?: boolean;
}

export type SessionEntity = {
  // eslint-disable-next-line camelcase
  activities: { scene_name: string; pack: number; variation: number }[];
  session: number;
};

export type QuizSessionEntity = {
  activitiesList: string[];
};

export const DEFAULT_UNITY_STATE = {
  Tokens: {
    AccessToken: undefined,
    RefreshToken: undefined,
    IdToken: undefined,
    clientID: undefined,
  },
  language: undefined,
  sceneName: undefined,
  packOrVariation: undefined,
  SceneName: undefined,
  bridgeIsReady: false,
  quizNextActivity: false,
  selectedStage: 'primary',
  PlatformUrl: process.env.NX_API_PLATFORM,
  SolverUrl: process.env.NX_API_SOLVER,
  sessionEntity: undefined,
  biomeId: 0,
  Account: {
    profile: {
      id: 2,
      name: 'Home',
    },
  },
  show403Modal: false,
};

export const initialUnityDataState = (): UnityDataState => {
  if (isElectronApp()) {
    console.log('From local storage!!!');
    const localStorageUnityState = getElectronData();

    const parsedUnityState = JSON.parse(localStorageUnityState);
    if (parsedUnityState) {
      return parsedUnityState;
    }
  }
  return DEFAULT_UNITY_STATE;
};
