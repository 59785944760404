import { AxiosInstance } from 'axios';
import { Applet } from './types';
import { apolloFetch } from './utils';

export const addAppletToWarnings = async (
  data: Applet,
  axiosInstance: AxiosInstance,
  apolloServer: string
): Promise<void> => {
  const graphQl = JSON.stringify({
    query: `mutation AddAppletToWarningList($data: AppletBlacklistBody){
      addAppletToWarningList(data: $data)
    }`,
    variables: { data },
  });

  await apolloFetch({ graphQl, axiosInstance, apolloServer });
};

export const addAppletToBlacklist = async (
  data: Applet,
  axiosInstance: AxiosInstance,
  apolloServer: string
): Promise<void> => {
  const graphQl = JSON.stringify({
    query: `mutation SetAppletToBlacklist($data: AppletBlacklistBody){
      setAppletToBlacklist(data: $data)
    }`,
    variables: { data },
  });

  await apolloFetch({ graphQl, axiosInstance, apolloServer });
};

export const notifyToSlackChannel = async (
  message: any,
  apiUrl: string,
  apiTokens: string
): Promise<void> => {
  const url = apiUrl + apiTokens;

  const iconToShow = ':alert:  - ERROR';
  const text = `${iconToShow} \n
    School:   ${message.school} \n
    SceneName:   ${message.sceneName} \n
    Pack:   ${message.packOrVariation} \n
    Error: \n
    ${JSON.stringify(message.error)}`;
  const body = JSON.stringify({
    text,
  });

  fetch(url, {
    method: 'POST',
    headers: { contentType: 'application/json' },
    mode: 'no-cors',
    body,
    redirect: 'follow',
  }).catch((error) => console.log('error', error));
};
