import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { AuthError } from '../utils/AuthError';
import { fromExternalLoginRequest } from '../utils/api';
import { LoginHookProps, LoginResponse } from '../types/login';
import { InteractionStatus } from '@azure/msal-browser';

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
const loginRequest = {
  scopes: ['User.Read'],
};

export function useMsalLogin({
  dispatch,
  credentials,
  autologin,
  usingRedirect,
}: LoginHookProps) {
  const [isAutoLogin, setIsAutoLogin] = useState(autologin);
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [isButtonPressedMsal, setIsPressed] = useState(false);
  const { mutate } = useMutation<LoginResponse, AuthError, string>({
    mutationFn: (token) =>
      fromExternalLoginRequest(token, 'azure_token', credentials),
  });

  const handleSetLoading = (loading: boolean) => {
    dispatch({
      type: 'SET_IS_LOADING',
      payload: {
        loading,
        key: { type: 'msal' },
      },
    });
  };

  const getAccess = () => {
    handleSetLoading(true);
    const accessTokenRequest = {
      scopes: ['user.read'],
      account: accounts[0],
    };
    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        mutate(accessTokenResponse.accessToken, {
          onSuccess(data) {
            dispatch({
              type: 'SET_LOGIN_INFO',
              payload: {
                loggedFrom: { type: 'msal' },
                response: data,
              },
            });
            handleSetLoading(false);
          },
          onError(error) {
            dispatch({ type: 'SET_ERROR', payload: error });
            handleSetLoading(false);
          },
        });
      })
      .catch((e) => {
        const error = new AuthError({
          title:
            e.errorCode === 'interaction_required' ? 'msal_auth_invalid' : '',
        });
        dispatch({ type: 'SET_ERROR', payload: error });
        handleSetLoading(false);
      });
  };

  const onLoginMsal = () => {
    setIsPressed(true);
    // Save isPressed to true in local storage for when using redirect
    if (usingRedirect) {
      localStorage.setItem('isButtonPressedMsal', 'true');
    }

    if (isAuthenticated) {
      getAccess();
    } else {
      const loginMethod = usingRedirect ? 'loginRedirect' : 'loginPopup';
      instance[loginMethod](loginRequest).catch((e) => {
        if (e.errorCode !== 'user_cancelled') {
          dispatch({
            type: 'SET_ERROR',
            payload: new AuthError({
              title: 'access_denied',
            }),
          });
        }

        setIsAutoLogin(false);
      });
    }
  };

  useEffect(() => {
    if (
      (isAuthenticated && (isButtonPressedMsal || isAutoLogin)) ||
      (isAuthenticated &&
        usingRedirect &&
        inProgress === 'none' &&
        localStorage.getItem('isButtonPressedMsal') === 'true')
    ) {
      // Save isPressed to false in local storage for when using redirect
      if (usingRedirect) {
        localStorage.setItem('isButtonPressedMsal', 'false');
      }
      getAccess();
    }
    if (inProgress !== InteractionStatus.None) return;

    if (!isAuthenticated && isAutoLogin) {
      onLoginMsal();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAuthenticated,
    isButtonPressedMsal,
    isAutoLogin,
    usingRedirect,
    inProgress,
  ]);

  return onLoginMsal;
}
