import * as React from 'react';
import type { SVGProps } from 'react';
const SvgInteraction = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      clipPath="url(#interaction_svg__a)"
    >
      <path d="M3.125 13.123a2.5 2.5 0 0 1-2.5-2.5v-7.5a2.5 2.5 0 0 1 2.5-2.5h13.75a2.5 2.5 0 0 1 2.5 2.5v7.5c0 .608-.221 1.197-.624 1.653" />
      <path d="m9.375 19.373-3.208-4.277a1.28 1.28 0 0 1-.042-1.475v0a1.28 1.28 0 0 1 1.83-.314l1.416 1.066V6.25A1.25 1.25 0 0 1 10.625 5v0a1.25 1.25 0 0 1 1.25 1.25v5l3.478.773a2.5 2.5 0 0 1 1.948 2.667l-.426 4.685z" />
    </g>
    <defs>
      <clipPath id="interaction_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgInteraction;
