import * as React from 'react';
import type { SVGProps } from 'react';
const SvgJumpActivity = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M11.875 5a2.188 2.188 0 1 0 0-4.375 2.188 2.188 0 0 0 0 4.375"
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="m16.667 8.324 2.219-1.708a1.25 1.25 0 0 0-1.523-1.982l-2.219 1.708a1.2 1.2 0 0 1-.986.244l-5.841-1.82a3.75 3.75 0 0 0-3.046.363l-2.167 1.3a1.25 1.25 0 0 0 1.287 2.143l2.167-1.298a1.26 1.26 0 0 1 1.015-.122l1.625.506-1.416 3.304-2.814 1.406a1.25 1.25 0 0 1-.558.132H1.875a1.25 1.25 0 0 0 0 2.5H4.41a3.8 3.8 0 0 0 1.673-.396l3.044-1.52 3.23-.461c.305-.046.615.024.87.196l2.955 1.97a1.252 1.252 0 0 0 1.386-2.083l-2.955-1.97a3.76 3.76 0 0 0-2.611-.593l-1.227.175.822-1.916m.278 7.223v3.75m-2.5 0h5"
    />
  </svg>
);
export default SvgJumpActivity;
