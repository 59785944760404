import * as React from 'react';
import type { SVGProps } from 'react';
const SvgGoogleClassroom = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g clipPath="url(#google-classroom_svg__a)">
      <path fill="#0F9D58" d="M1.818 3.182h16.364v13.636H1.818z" />
      <path
        fill="#57BB8A"
        d="M13.636 10.455a1.023 1.023 0 1 0 0-2.047 1.023 1.023 0 0 0 0 2.047m0 .681c-1.094 0-2.272.58-2.272 1.3v.746h4.545v-.746c0-.72-1.178-1.3-2.273-1.3m-7.272-.681a1.023 1.023 0 1 0-.002-2.047 1.023 1.023 0 0 0 .002 2.047m0 .681c-1.095 0-2.273.58-2.273 1.3v.746h4.545v-.746c0-.72-1.178-1.3-2.272-1.3"
      />
      <path
        fill="#F7F7F7"
        d="M10 9.545a1.363 1.363 0 1 0 0-2.727 1.363 1.363 0 1 0 0 2.727m0 .91c-1.534 0-3.182.814-3.182 1.818v.909h6.364v-.91c0-1.003-1.648-1.818-3.182-1.818"
      />
      <path fill="#F1F1F1" d="M11.818 15.91h4.091v.908h-4.09z" />
      <path
        fill="#F4B400"
        d="M18.636 1.364H1.364C.61 1.364 0 1.974 0 2.727v14.546c0 .753.61 1.363 1.364 1.363h17.272c.754 0 1.364-.61 1.364-1.363V2.727c0-.754-.61-1.363-1.364-1.363m-.454 15.454H1.818V3.182h16.364z"
      />
      <path
        fill="#fff"
        d="M18.636 1.364H1.364C.61 1.364 0 1.974 0 2.727v.114c0-.754.61-1.364 1.364-1.364h17.272c.754 0 1.364.61 1.364 1.364v-.114c0-.754-.61-1.363-1.364-1.363"
        opacity={0.2}
      />
      <path
        fill="#BF360C"
        d="M18.636 18.523H1.364C.61 18.523 0 17.913 0 17.159v.114c0 .753.61 1.363 1.364 1.363h17.272c.754 0 1.364-.61 1.364-1.363v-.114c0 .754-.61 1.364-1.364 1.364"
        opacity={0.2}
      />
      <path
        fill="url(#google-classroom_svg__b)"
        d="M15.902 16.818H11.81l1.818 1.818h4.087z"
      />
      <path fill="#263238" d="M1.818 3.068h16.364v.114H1.818z" opacity={0.2} />
      <path fill="#fff" d="M1.818 16.818h16.364v.114H1.818z" opacity={0.2} />
      <path
        fill="url(#google-classroom_svg__c)"
        d="M18.636 1.364H1.364C.61 1.364 0 1.974 0 2.727v14.546c0 .753.61 1.363 1.364 1.363h17.272c.754 0 1.364-.61 1.364-1.363V2.727c0-.754-.61-1.363-1.364-1.363"
      />
    </g>
    <defs>
      <radialGradient
        id="google-classroom_svg__c"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="matrix(82.5201 0 0 82.5198 .589 1.768)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" stopOpacity={0.1} />
        <stop offset={1} stopColor="#fff" stopOpacity={0} />
      </radialGradient>
      <linearGradient
        id="google-classroom_svg__b"
        x1={14.763}
        x2={14.763}
        y1={16.843}
        y2={18.655}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BF360C" stopOpacity={0.2} />
        <stop offset={1} stopColor="#BF360C" stopOpacity={0.02} />
      </linearGradient>
      <clipPath id="google-classroom_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgGoogleClassroom;
