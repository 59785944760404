import { SVG } from '@innovamat/innova-components';
import { useEffect } from 'react';
import { ReactComponent as StarSVG } from '../../assets/svg/star.svg';
import styled from '@emotion/styled';

type Props = {
  success?: boolean;
  size: number | string;
  opacity?: boolean;
  audio?: string;
};

const Star = ({ success, size, opacity, audio }: Props) => {
  const playAudio = (source: string) => {
    const audio = new Audio(source);
    try {
      audio?.play();
    } catch (e) {
      console.log('Error playing audio', e);
    }
  };

  useEffect(() => {
    if (success && audio) {
      playAudio(`${audio}`);
    }
  }, [success, audio]);

  return (
    <StyledStar
      element={StarSVG}
      svgWidth={46}
      svgHeight={41}
      width={size}
      height="100%"
      success={success}
      opacity={opacity}
    />
  );
};

export default Star;

const StyledStar = styled(SVG)<{ success?: boolean; opacity?: boolean }>`
  display: flex;
  > path {
    transition: fill 1.5s linear;
    fill: ${(props) =>
      props.success
        ? '#efc513'
        : props.theme.colors.dark['04']};
    fill-opacity: ${(props) => props.success && 1};
    opacity: ${(props) => props.opacity && !props.success && 0.5};
  }
`;
