/* eslint-disable no-case-declarations */
import {
  DEFAULT_UNITY_STATE,
  initialUnityDataState,
  UnityDataState,
} from '../unityData/unityData.interfaces';
import { AppAction } from '../root/root.interfaces';
import { actions } from '../unityData/unityData.actions';
import { getProperHomePerSelectedStage } from '../../components/BuildWrapper/utils';

function unityDataReducer(
  state: UnityDataState = initialUnityDataState(),
  action: AppAction
): UnityDataState {
  const {
    sessionEntity,
    sceneName,
    packOrVariation,
    previousSceneName,
    quizSessionEntity,
    sessionType,
  } = state;

  switch (action.type) {
    case actions.INITIALIZE_UNITY_DATA:
      return DEFAULT_UNITY_STATE;

    case actions.SET_UNITY_DATA:
      const newState = {
        ...state,
        ...action.payload,
        bridgeIsReady: false,
        sceneName: action.payload.SceneName || action.payload.sceneName,
        startLoadingTime: {
          time: Date.now(),
          fromScene: sceneName || 'First load',
        },
      };
      console.log('LoadingTime Start SetUnityData', Date.now());
      return {
        ...newState,
      };

    case actions.SET_UNITY_TOKENS:
      return {
        ...state,
        Tokens: {
          ...state.Tokens,
          ...action.payload,
        },
      };

    case actions.SET_BRIDGE_IS_READY:
      return {
        ...state,
        bridgeIsReady: action.payload,
      };

    case actions.SET_QUIZ_NEXT_ACTIVITY:
      return {
        ...state,
        quizNextActivity: action.payload,
      };

    case actions.UPDATE_UNITY_DATA:
      return {
        ...state,
        ...action.payload,
      };

    case actions.SET_ACCOUNT_DATA:
      const { account, classrooms, students } = action.payload;
      classrooms?.forEach((classroom: any) => {
        classroom.users = classroom.users?.filter((user: any) => user);
      });
      return {
        ...state,
        Account: {
          ...state.Account,
          ...account,
        },
        Classrooms: classrooms,
        Students: students,
      };

    case actions.SET_SELECTED_STAGE:
      const newScene = getProperHomePerSelectedStage(action.payload);
      console.log('LoadingTime Start', Date.now());
      return {
        ...state,
        selectedStage: action.payload,
        sceneName: newScene,
        SceneName: newScene,
        startLoadingTime: {
          time: Date.now(),
          fromScene: sceneName || 'First load',
        },
      };

    case actions.SET_PLATFORM_URL:
      const { platformUrl } = action;
      return {
        ...state,
        PlatformUrl: platformUrl,
      };

    case actions.SET_SOLVER_URL:
      const { solverUrl } = action;
      return {
        ...state,
        SolverUrl: solverUrl,
      };

    case actions.SET_PREVIOUS_SCENE:
      console.log('LoadingTime Start', Date.now());
      return {
        ...state,
        sceneName: previousSceneName,
        SceneName: previousSceneName,
        bridgeIsReady: false,
        startLoadingTime: {
          time: Date.now(),
          fromScene: sceneName,
        },
      };

    case actions.ACTIVITY_IS_COMPLETED:
      const isQuiz = sessionType === 'Quiz';
      const activities = isQuiz
        ? quizSessionEntity?.activitiesList
        : sessionEntity?.activities;
      const isAppletSelector = previousSceneName === 'ActivityManager';

      if (!sceneName || !packOrVariation || !activities || isAppletSelector) {
        console.log(
          'ERROR NO SCENE/PACK/ACTIVITIES OR COMING FROM APPLET SELECTOR'
        );
        console.log('LoadingTime Start', Date.now());
        return {
          ...state,
          ...action.payload,
          sceneName: previousSceneName,
          SceneName: previousSceneName,
          bridgeIsReady: false,
          startLoadingTime: {
            time: Date.now(),
            fromScene: sceneName,
          },
        };
      }

      if (activities.length === 1) {
        console.log('NO MORE ACTIVITIES, RETURN');
        console.log('LoadingTime Start', Date.now());
        return {
          ...state,
          ...action.payload,
          sceneName: previousSceneName,
          SceneName: previousSceneName,
          HasPlayedSession: true,
          bridgeIsReady: false,
          startLoadingTime: {
            time: Date.now(),
            fromScene: sceneName,
          },
        };
      }
      const nextActivitySession = sessionEntity?.activities[1];
      console.log('LoadingTime Start', Date.now());
      if (isQuiz) {
        return {
          ...state,
          ...action.payload,
          quizNextActivity: true,
          bridgeIsReady: false,
          startLoadingTime: {
            time: Date.now(),
            fromScene: sceneName,
          },
          quizSessionEntity: {
            ...quizSessionEntity,
            activitiesList: quizSessionEntity?.activitiesList.splice(1),
          },
        };
      } else {
        return {
          ...state,
          ...action.payload,
          bridgeIsReady: false,
          startLoadingTime: {
            time: Date.now(),
            fromScene: sceneName,
          },
          sceneName: nextActivitySession?.scene_name,
          SceneName: nextActivitySession?.scene_name,
          sessionEntity: {
            ...sessionEntity,
            activities: sessionEntity?.activities?.splice(1),
          },
          packOrVariation:
            nextActivitySession?.pack !== -1
              ? nextActivitySession?.pack.toString()
              : nextActivitySession?.variation.toString(),
        };
      }

    case actions.SET_REGION_PARAMETERS:
      const { regionParameters } = action;
      return {
        ...state,
        regionParameters,
      };

    case actions.SET_COINS_GEMS:
      const { coins, gems } = action.payload;
      return {
        ...state,
        userCoins: coins,
        userGems: gems,
      };

    case 'SHOW_403_MODAL':
      return {
        ...state,
        show403Modal: true,
      };

    case 'HIDE_403_MODAL':
      return {
        ...state,
        show403Modal: false,
      };

    default:
      return state;
  }
}

export default unityDataReducer;
