import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDownloadFile = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M9.375 14.375a5 5 0 1 0 10 0 5 5 0 1 0-10 0M14.375 11.875v5M14.375 16.875 12.5 15M14.375 16.875 16.25 15M3.125 5.625h8.75M3.125 9.375h5M3.125 13.125H6.25"
      />
      <path
        strokeWidth={1.249995}
        d="M6.25 16.875H1.875a1.25 1.25 0 0 1-1.25-1.25V1.875a1.25 1.25 0 0 1 1.25-1.25h8.858a1.25 1.25 0 0 1 .883.366l2.393 2.393a1.25 1.25 0 0 1 .366.884V6.25"
      />
    </g>
  </svg>
);
export default SvgDownloadFile;
