import React, { useEffect, useRef, useState } from 'react';
import { Document, Page as PDFPage, pdfjs } from 'react-pdf';

import { FullScreen, useFullScreenHandle } from 'react-full-screen';

import { PDFPageProxy } from 'react-pdf/dist/Page';
import PdfToolbar from './PdfToolbar';
import styles from './SinglePdfViewer.module.scss';
import { Loader } from '@innovamat/glimmer-components';
import ResourceNotification from '../../components/ResourceNotification';
import {
  FullScreenWrapper,
  LoaderContainer,
} from '../../ResourceViewer.styles';

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.js`;

const MAX_PDF_SIZE_FS_FOR_WEBVIEW = 3.0;

const Page = PDFPage as any;

type Props = {
  path: string;
  page?: number;
  isGuide?: boolean;
  isPageBlocked?: (page: number) => boolean;
  translations: Record<string, string>;
  isInWebview?: boolean;
};

export const SinglePdfViewer = (props: Props) => {
  const { path, page, isGuide, isPageBlocked, translations, isInWebview } =
    props;

  const containerRef = useRef<HTMLDivElement>(null);
  const showToolbar = true;

  const [numPages, setNumPages] = useState(1);
  const [currentPageNumber, setPageNumber] = useState(page || 1);
  const [zoomScale, setZoom] = useState(1);
  const [FSscale, setFSscale] = useState(1);
  const [initialZoom, setInitialZoom] = useState(1);
  const [parentWidth, setParentWidth] = useState(0);
  const [doublePage, setDoublePage] = useState(true);
  const [pageRefs, setPageRefs] = useState<any[]>([]);
  const [observers, setObservers] = useState<any[]>([]);
  const [landscapePages, setLandscapePages] = useState<any[]>([]);
  const [keepPageNumber, setKeepPageNumber] = useState(false);
  const handleFS = useFullScreenHandle();
  const [fullScreenButton, setFullScreenButton] = useState(true);

  const createObserver = (index: number, ratio: number) =>
    new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && entry.intersectionRatio >= ratio) {
          setPageNumber(index);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: ratio,
      }
    );

  useEffect(() => {
    const newPageRefs: any[] = [];
    const newObservers: any[] = [];

    if (observers.length > 1) {
      observers.forEach((observer) => {
        observer.disconnect();
      });
    }

    for (let i = 1; i <= numPages; i += 1) {
      newPageRefs.push(React.createRef());
      newObservers.push(createObserver(i, doublePage ? 0.7 : 0.3));
    }
    setPageRefs(newPageRefs);
    setObservers(newObservers);
  }, [numPages, doublePage]);

  useEffect(() => {
    if (containerRef.current && containerRef.current.clientWidth > 0) {
      const pWidth = containerRef.current.clientWidth - 17;

      // TODO: Idk why getting same clientwidth and offsetwidth, but those are not the real width. With -17 is ok
      setParentWidth(pWidth);
    }
  }, [containerRef.current]);

  useEffect(() => {
    pageRefs.forEach((ref, index) => {
      if (ref && ref.current !== null) {
        observers[index]?.observe(ref);
      }
    });
  }, [pageRefs, numPages]);

  useEffect(() => {
    async function fetchPDFSizeInMB() {
      const pdfInfo = await fetch(path, { method: 'HEAD' });
      const pdfSizeInBytes = pdfInfo.headers.get('Content-Length');
      const pdfSizeInMB = Number(pdfSizeInBytes) / 1000000;
      if (pdfSizeInMB > MAX_PDF_SIZE_FS_FOR_WEBVIEW && isInWebview)
        setFullScreenButton(false);
    }
    fetchPDFSizeInMB();
  }, [path]);

  const onDocumentLoadSuccess = async (pdf: any) => {
    if (!page) {
      const pageView = await pdf.getPage(currentPageNumber);
      const pdfInitialWidth = pageView.view[2] - pageView.view[0];
      const pdfInitialHeight = pageView.view[3] - pageView.view[1];
      const initialZoomScale = parentWidth / pdfInitialWidth;

      setInitialZoom(initialZoomScale);
      setZoom(initialZoomScale);
      setPageNumber(1);
      if (pdf.numPages === 1 && pdfInitialHeight < pdfInitialWidth) {
        setLandscapePages([1]);
      }
    }
    setNumPages(pdf.numPages);
    if (pdf.numPages === 1) {
      setDoublePage(false);
    }
  };

  const setScale = () => {
    if (handleFS.active) {
      const pdfInitialWidth = parentWidth / initialZoom;
      const newParentWidth =
        containerRef?.current?.clientWidth || pdfInitialWidth;
      const fixWidth = doublePage ? 1 : 0; // TODO: TRICKY
      const fsScale = (newParentWidth - fixWidth) / pdfInitialWidth;
      setFSscale(fsScale);
      setZoom(fsScale);
    } else if (!doublePage && !page && isGuide && landscapePages.length === 0) {
      setZoom(initialZoom - 0.4);
    } else {
      setZoom(initialZoom);
    }
  };

  useEffect(() => {
    setScale();
  }, [doublePage]);

  const handleChangePageNumber = (pageNumber: number) => {
    setPageNumber(pageNumber);
    pageRefs[pageNumber - 1]?.scrollIntoView({
      block: 'start',
      inline: 'start',
    });
  };

  const checkIfPageIsLandscape = (PDFpage: PDFPageProxy, numPage: number) => {
    if (PDFpage.width > PDFpage.height) {
      const newLandscapePages = [...landscapePages];
      if (!newLandscapePages.includes(numPage)) {
        newLandscapePages.push(numPage);
        setLandscapePages(newLandscapePages);
      }
    }
  };

  const handleChangeDoublePage = () => {
    setDoublePage((dblePage) => !dblePage);
    setKeepPageNumber(true);
  };

  const BlockedPage = () => (
    <div className={styles.bloquedPage}>
      <ResourceNotification
        text={translations['session.bloquedResource.message']}
        dataTestId="warningResourceNotEnabled"
      />
    </div>
  );

  const PdfDocumentAllPages = () => {
    // eslint-disable-next-line prefer-spread
    const pagesArray = Array.apply(null, Array(numPages)).map((_x, i) => i + 1);
    let doublePageArray;

    if (doublePage && numPages > 1) {
      doublePageArray = pagesArray.reduce(
        (rows: any, key, index) =>
          (index % 2 === 0
            ? rows.push([key])
            : rows[rows.length - 1].push(key)) && rows,
        []
      );
    }

    return (
      <Document
        file={path}
        loading={
          <LoaderContainer>
            <Loader color="primary" />
          </LoaderContainer>
        }
        onLoadSuccess={onDocumentLoadSuccess}
        externalLinkTarget="_blank"
      >
        {doublePageArray
          ? doublePageArray.map((group: [], indexGroup: number) => (
              <div
                key={`group-${indexGroup}`}
                className={`
                        ${
                          group.some((t) => landscapePages.includes(t))
                            ? styles.landscape
                            : styles.doublePage
                        }
                        `}
              >
                {group.map((pageNumber: number, index: number) => (
                  <div
                    key={`item-${index}`}
                    className={`
                                ${indexGroup !== 0 ? styles.topBorder : ''}
                                ${index !== 0 ? styles.leftBorder : ''}
                                `}
                    ref={(el) => (pageRefs[pageNumber - 1] = el)}
                  >
                    <Page
                      pageNumber={pageNumber}
                      scale={
                        group.some((t) => landscapePages.includes(t))
                          ? zoomScale
                          : zoomScale / 2
                      }
                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                      width={undefined}
                      onLoadSuccess={(PDFpage: any) => {
                        checkIfPageIsLandscape(PDFpage, pageNumber);
                        if (
                          keepPageNumber &&
                          pageNumber === currentPageNumber
                        ) {
                          handleChangePageNumber(
                            pageNumber % 2 === 0 ? pageNumber : pageNumber + 1
                          );
                          setKeepPageNumber(false);
                        }
                      }}
                    >
                      {isPageBlocked?.(pageNumber) && <BlockedPage />}
                    </Page>
                  </div>
                ))}
              </div>
            ))
          : pagesArray.map((pageNumber: number, index: number) => (
              <div
                key={index}
                className={index !== 0 ? styles.topBorder : ''}
                ref={(el) => (pageRefs[pageNumber - 1] = el)}
              >
                <Page
                  pageNumber={pageNumber}
                  scale={zoomScale}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  width={undefined}
                  onLoadSuccess={() => {
                    if (keepPageNumber && pageNumber === currentPageNumber) {
                      handleChangePageNumber(pageNumber);
                      setKeepPageNumber(false);
                    }
                  }}
                >
                  {isPageBlocked?.(pageNumber) && <BlockedPage />}
                </Page>
              </div>
            ))}
      </Document>
    );
  };

  const PdfDocument = (
    <Document
      file={path}
      loading={<Loader color="primary" />}
      onLoadSuccess={onDocumentLoadSuccess}
      externalLinkTarget="_blank"
    >
      <Page
        pageNumber={page}
        scale={zoomScale}
        renderTextLayer={false}
        renderAnnotationLayer={false}
        width={window.innerWidth}
      >
        {isPageBlocked?.(page || 1) && <BlockedPage />}
      </Page>
    </Document>
  );

  return (
    <FullScreenWrapper data-testid="fullscreenWrapper">
      <FullScreen handle={handleFS} onChange={setScale}>
        <div
          className={`
          ${styles.wrapper}
          ${page ? styles.fitHeight : ''}
          ${zoomScale === initialZoom ? styles.zeroZoom : ''}
          ${
            zoomScale < initialZoom || (handleFS.active && zoomScale < FSscale)
              ? styles.zoomOut
              : ''
          }`}
          ref={containerRef}
          onContextMenu={(e) => e.preventDefault()}
        >
          {page ? (
            PdfDocument
          ) : (
            <>
              <div>{PdfDocumentAllPages()}</div>
              {showToolbar && (
                <PdfToolbar
                  isGuide={isGuide}
                  setZoom={setZoom}
                  setPageNumber={handleChangePageNumber}
                  currentPageNumber={currentPageNumber}
                  numPages={numPages}
                  zoomScale={zoomScale}
                  fullScreen={fullScreenButton ? handleFS : undefined}
                  doublePage={doublePage}
                  handleChangeDoublePage={handleChangeDoublePage}
                />
              )}
            </>
          )}
        </div>
      </FullScreen>
    </FullScreenWrapper>
  );
};

export default SinglePdfViewer;
