import { useEffect, useState } from 'react';
import Button from '../Button';

import {
  Body,
  Container,
  ContentWrapper,
  Footer,
  Header,
  MessageWrapper,
  Title,
} from './Cart.styles';
import CartSection from './CartSection';
import type { Material } from './Cart.types';

type CartProps = {
  cancelButtonLabel: string;
  confirmButtonLabel: string;
  materials: Material[];
  noModificationsMessage: string;
  onAccept: () => void;
  onCancel: () => void;
  ordersLabel: string;
  returnsLabel: string;
  title: string;
};

function Cart({
  cancelButtonLabel,
  confirmButtonLabel,
  materials,
  noModificationsMessage,
  onAccept,
  onCancel,
  ordersLabel,
  returnsLabel,
  title,
}: CartProps) {
  const [addMaterials, setAddMaterials] = useState<Material[]>([]);
  const [hasMaterials, setHasMaterials] = useState(false);
  const [subtractMaterials, setSubtractMaterials] = useState<Material[]>([]);

  const getSubtractingMaterials = (materialsToSubtract: Material[]) =>
    materialsToSubtract
      .filter((material) => material.totalOperationQuantity < 0)
      .sort(sortByStageOrder)
      .sort(sortByCourseOrder)
      .sort(sortByProductTypeOfMaterial);

  const getAddingMaterials = (materialsToAdd: Material[]) =>
    materialsToAdd
      .filter((material) => material.totalOperationQuantity > 0)
      .sort(sortByStageOrder)
      .sort(sortByCourseOrder)
      .sort(sortByProductTypeOfMaterial);

  const sortByStageOrder = (a: Material, b: Material) => {
    if (a.stageOrder < b.stageOrder) {
      return -1;
    }
    if (a.stageOrder > b.stageOrder) {
      return 1;
    }
    return 0;
  };

  const sortByCourseOrder = (a: Material, b: Material) => {
    if (a.courseOrder < b.courseOrder) {
      return -1;
    }
    if (a.courseOrder > b.courseOrder) {
      return 1;
    }
    return 0;
  };

  const sortByProductTypeOfMaterial = (a: Material, b: Material) => {
    if (a.productTypeOfMaterial < b.productTypeOfMaterial) {
      return -1;
    }
    if (a.productTypeOfMaterial > b.productTypeOfMaterial) {
      return 1;
    }
    return 0;
  };

  useEffect(() => {
    setHasMaterials(materials.length > 0);
    setAddMaterials(() => getAddingMaterials(materials));
    setSubtractMaterials(() => getSubtractingMaterials(materials));
  }, [materials]);

  return (
    <Container width="324px" elevation="03" borderRadius="md">
      <ContentWrapper>
        <Header>
          <Title>{title}</Title>
        </Header>

        <Body>
          {!hasMaterials && (
            <MessageWrapper>{noModificationsMessage}</MessageWrapper>
          )}

          {addMaterials.length > 0 && (
            <CartSection
              title={ordersLabel}
              materials={addMaterials}
              isPositive
            />
          )}

          {subtractMaterials.length > 0 && (
            <CartSection
              isPositive={false}
              title={returnsLabel}
              materials={subtractMaterials}
            />
          )}
        </Body>

        <Footer>
          <Button color="secondary" onClick={onCancel}>
            {cancelButtonLabel}
          </Button>
          <Button disabled={!hasMaterials} onClick={onAccept}>
            {confirmButtonLabel}
          </Button>
        </Footer>
      </ContentWrapper>
    </Container>
  );
}

export default Cart;
