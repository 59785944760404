import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSamCultural = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M2.917 7.904a6.25 6.25 0 1 0 12.5 0 6.25 6.25 0 0 0-12.5 0"
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M15.354 1.718A8.75 8.75 0 0 1 2.98 14.092M16.238.833l-.884.884M2.98 14.092l-.884.884M9.167 16.654v2.5M4.167 19.154h10"
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M3.437 5.404h1.355a1.25 1.25 0 0 1 1.25 1.25v1.358a1.25 1.25 0 0 0 .365.884l.963.962a1.25 1.25 0 0 1 .302 1.28l-.257.771a1.25 1.25 0 0 1-.436.604l-1.013.76M11.167 1.982c-.34.271-.701.552-1.022.8a1.25 1.25 0 0 0-.237 1.74l.759 1.007a1.25 1.25 0 0 0 1 .5h.732c.332 0 .65.132.884.367l.962.962a1.25 1.25 0 0 0 1.167.334"
    />
  </svg>
);
export default SvgSamCultural;
