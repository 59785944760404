import Cookies from 'universal-cookie/es6';
import { decryptWithAES, encryptWithAES } from './crypto';

export const CookieKeys = {
  accessToken: 'inm_AT',
  assessor: 'inm_A',
  deviceCode: 'inm_D',
  expiresIn: 'inm_EI',
  idToken: 'inm_IDT',
  language: 'inm_L',
  preferences: 'inm_P',
  refreshToken: 'inm_RT',
  schoolName: 'inm_SN',
  shownMaxReturnableItemsDialog: 'inm_SMRI',
  tokenType: 'inm_TT',
  version: 'inm_V',
} as const;

export type CookieKeysType = (typeof CookieKeys)[keyof typeof CookieKeys];

export const getCookie = (key: CookieKeysType) => {
  try {
    const cookies = new Cookies();
    const value = cookies.get(key);
    return value ? decryptWithAES(value) : null;
  } catch (error) {
    throw new Error(`Error getting cookie: ${error}`);
  }
};

export const setCookie = ({
  domain,
  key,
  maxAge = 365 * 24 * 60 * 60,
  value,
}: {
  domain?: string;
  key: CookieKeysType;
  maxAge?: number;
  value: string;
}) => {
  const cookies = new Cookies();

  cookies.set(key, encryptWithAES(value), {
    domain,
    maxAge,
    path: '/',
  });
};
