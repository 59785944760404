import {
  SmartGuidesProvider,
  SmartGuidesProviderProps,
} from '../../context/SmartGuidesProvider';

import { Parts } from '../Parts';
import { PlayerBlock } from '../PlayerBlock';
import { SummarySection } from '../SummarySection';
import styled from '@emotion/styled';
import { AssessableDrawer } from '../assessables';

const Container = styled.div`
  padding: 24px 0px 64px 0px;
  display: flex;
  flex-direction: column;
  gap: 64px;
  max-width: 1440px;
  margin: 0 auto;
  min-height: calc(101vh - var(--heightHeader, 1vh));
`;

const GuideArea = styled.div`
  display: grid;
  grid-template-columns: 60% 1fr;
  gap: 24px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    grid-template-columns: 100%;

    > div {
      width: 100%;
    }
  }
`;

const PlayerBlockCol = styled.div``;
const StudentPlayerBlockCol = styled.div`
  margin-top: 24px;
`;

const StickyContainer = styled.div`
  width: 100%;
  position: sticky;
  top: calc(var(--heightHeader, 1vh) + 16px);
`;

const ListsSection = styled.div`
  margin-top: -64px;
`;

type Props = Omit<SmartGuidesProviderProps, 'children'>;

export function SmartGuide(props: Props) {
  if (props.playerInfo?.isStudent) {
    return (
      <SmartGuidesProvider {...props}>
        <StudentPlayerBlockCol>
          <PlayerBlock />
        </StudentPlayerBlockCol>
      </SmartGuidesProvider>
    );
  }

  return (
    <SmartGuidesProvider {...props}>
      <AssessableDrawer />
      <Container>
        <SummarySection />
        <GuideArea>
          <Parts />
          <PlayerBlockCol>
            <StickyContainer>
              <PlayerBlock />
            </StickyContainer>
          </PlayerBlockCol>
        </GuideArea>
        <ListsSection>
          {props.unblockedAppletsComponent}
          {props.relatedItemsComponent}
        </ListsSection>
      </Container>
    </SmartGuidesProvider>
  );
}
