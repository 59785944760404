export const sizes = {
  xlarge: '48px',
  large: '32px',
  caption: '24px',
  medium: '20px',
  xmedium: '18px',
  normal: '16px',
  small: '14px',
  xsmall: '12px',
  micro: '10px',
};
