import styled from '@emotion/styled';
import { createPortal } from 'react-dom';

const Border = styled.div`
  z-index: 60;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  border: 8px solid
    ${({ theme }) => theme.tokens.color.alias.cm.icon['icon-warning'].value};
`;

const StickyBar = styled.div`
  padding: 8px 24px;
  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.icon['icon-warning'].value};
  color: ${({ theme }) => theme.tokens.color.global.white.value};
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 14px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  z-index: 60;
`;

type Props = {
  wrapper?: HTMLElement | null;
  content?: JSX.Element | null;
};

export function BorderOverlay({ wrapper, content }: Props) {
  const element = wrapper ?? document.querySelector('body');

  let modalRoot = document.getElementById('modal') as HTMLElement;
  if (!modalRoot) {
    modalRoot = document.createElement('div');
    modalRoot.setAttribute('id', 'modal');
    document.body.appendChild(modalRoot);
  }

  if (!element) return null;

  return createPortal(
    <>
      <Border data-testid="border-overlay" />
      {content && <StickyBar data-testid="sticky-bar">{content}</StickyBar>}
    </>,
    element
  );
}
