import styled from '@emotion/styled';

export const PlayerSectionWrapper = styled.div`
  width: 100%;
  height: calc(calc(var(--vh, 1vh) * 100) - 220px);
  margin-top: 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-height: calc(100vh - var(--heightHeader, 0vh));
  }
`;
