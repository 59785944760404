
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as MenuListIconComponent } from "./menu-list-icon.svg";

export const MenuListIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <MenuListIconComponent {...props} />;
};
