import styled from '@emotion/styled';
import { Icon, Tooltip } from '@innovamat/glimmer-components';
import { PartType } from '../../types/SmartGuide';
import { usePartColors } from '../../hooks/usePartColors';
import { useSmartGuides } from '../../context/SmartGuidesProvider';

const Container = styled.div`
  position: relative;
`;

const Triangle = styled.div<{ color: string }>`
  position: absolute;

  border-right: calc(35px / 2) solid transparent;
  border-top: calc(35px / 2) solid ${({ color }) => color};
  border-left: calc(35px / 2) solid ${({ color }) => color};
  border-bottom: calc(35px / 2) solid transparent;
  border-top-left-radius: 3px;
`;

const StarIcon = styled(Icon)`
  position: absolute;
  width: 12px;
  height: 12px;
  top: 4px;
  left: 4px;
`;

type Props = {
  part: PartType;
};

export function KeyMoment({ part }: Props) {
  const { getElement } = usePartColors();
  const { t } = useSmartGuides();

  return (
    <Tooltip content={t('digitalguides.se.keymoment')}>
      <Container>
        <Triangle color={getElement(part)} />

        <StarIcon icon="KeyMomentStarIcon" iconColor="white" size="M" />
      </Container>
    </Tooltip>
  );
}
