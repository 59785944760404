import styled from '@emotion/styled';
import { HELP_MAIL } from '@innovamat/ga-features';
import { ElectrifiedCat } from '@innovamat/glimmer-assets';
import { Typography } from '@innovamat/glimmer-components';
import { Trans, useTranslation } from 'react-i18next';

const BackgroundContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 24px;
  gap: 48px;
  border-radius: 8px;
  border: 1px solid
    ${({ theme }) => theme.tokens.color.alias.cm.border['border-subtle'].value};
  background: ${({ theme }) =>
    theme.tokens.color.alias.cm.surface['surface-secondary'].value};
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ImgContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 168px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

export function SectionEmptyState(): JSX.Element {
  const { t } = useTranslation();
  return (
    <BackgroundContainer>
      <TextContainer>
        <Typography.H4>{t('error-page.not-available')}</Typography.H4>
        <Typography.Body1>
          <Trans
            i18nKey="error-page.not-available-description"
            components={{
              a: <a className="mail" href={`mailto: ${HELP_MAIL}`} />,
            }}
          />
        </Typography.Body1>
      </TextContainer>
      <ImgContainer>
        <ElectrifiedCat
          width="168"
          height="97"
          viewBox="0 0 300 173"
          preserveAspectRatio="xMidYMid meet"
          style={{ width: '100%', height: 'auto' }}
        />
      </ImgContainer>
    </BackgroundContainer>
  );
}
