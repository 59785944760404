import styled from '@emotion/styled';

const Container = styled.div`
  height: 4.5rem;
  width: 100%;
`;

export function Grounder(): JSX.Element {
  return <Container />;
}
