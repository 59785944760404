import styled from '@emotion/styled';
import { PrintableBody1 } from '../../printable-styles/printable-typography';

const Title = styled(PrintableBody1)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
  margin-bottom: 4px;

  @media print {
    margin-bottom: 2px;
    font-weight: 500;
  }
`;

const Children = styled(PrintableBody1)`
  color: ${({ theme }) => theme.tokens.color.alias.cm.text.text.value};

  @media print {
    color: #000000;
  }
`;

type Props = {
  title?: string;
  children?: React.ReactNode;
  id?: string;
};

export function SimpleBlock({ title, children, id }: Props): JSX.Element {
  const getId = (value: string): string | undefined => {
    if (!id) return undefined;
    return `${id}-${value}`;
  };

  return (
    <div id={id}>
      {title && <Title id={getId('title')}>{title}</Title>}
      {children && <Children id={getId('content')}>{children}</Children>}
    </div>
  );
}
