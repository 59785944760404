import React, { useState } from 'react';
import Icon from '../Icon/Icon';
import IconItem from '../../types/icons';
import styles from './Notification.module.scss';

type Props = {
  children: JSX.Element | string;
  canClose?: boolean;
  type?: 'info' | 'warning' | 'error';
  dataTestId?: string;
} & React.HTMLAttributes<HTMLDivElement>;

type IconType = {
  [key: string]: IconItem;
};

const notificationIcons: IconType = {
  info: 'Info',
  error: 'Error',
  warning: 'Warning',
};

function Notification({
  canClose = true,
  children,
  type = 'info',
  className,
  dataTestId,
}: Props) {
  const [show, setShow] = useState(true);

  const getIcon = notificationIcons[type];

  if (!show) {
    return null;
  }
  return (
    <div
      className={`${styles.notification} ${styles[type]} ${className}`}
      data-testid={dataTestId}
    >
      <div>
        <Icon icon={getIcon} size="sm" />
        <span>{children}</span>
      </div>
      {canClose && (
        <Icon
          onClick={() => setShow(false)}
          className={styles.closeIcon}
          icon="Close"
        />
      )}
    </div>
  );
}

export default Notification;
