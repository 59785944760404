import styled from '@emotion/styled';
import React, { useState } from 'react';
import {
  ChallengeCheckbox,
  ArrowActionButton,
} from '@innovamat/innova-components';
import {
  ConfettiAnimation as Confetti,
  Typography,
  useGlimmerTheme,
} from '@innovamat/glimmer-components';
import useEarlyYearGuide from '../../templates/EarlyYear/context/useEarlyYearGuide';
import { PrintableSubtitle1 } from '../../../printable-styles/printable-typography';

type Props = {
  id: string;
  title: string;
  challengeNumber: number;
  onClick?: () => void;
};

const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  gap: 12px;
  align-items: center;

  @media print {
    break-inside: avoid;
  }
`;

const TitleWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 12px;
  color: ${({ theme }) => theme.colors.dark['01']};
`;

const Actions = styled.div`
  display: flex;
  gap: 12px;
  position: relative;
`;

const ConfettiWrapper = styled.div`
  position: absolute;
  bottom: -64px;
  right: -28px;
  z-index: 0;
`;

const NumBox = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.brand['03']};
  color: ${({ theme }) => theme.colors.brand.dark['02']};
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Rubik';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
`;

const Title = styled(Typography.SuperTitle)`
  letter-spacing: 0.5px !important;
`;

export default function ChallengesBlockTitle({
  id,
  title,
  challengeNumber,
  onClick,
}: Props) {
  const theme = useGlimmerTheme();
  const [isConfettiAnimation, setConfettiAnimation] = useState(false);
  const {
    getIsOpen,
    isChallengeDone,
    onMarkChallengeAsDone,
    canMarkAsDone,
    guide,
    t,
  } = useEarlyYearGuide();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onMarkChallengeAsDone?.(id, e.target.checked);
    if (e.target.checked) {
      setConfettiAnimation(true);
      setTimeout(() => {
        setConfettiAnimation(false);
      }, 1500);
    }
  };

  const formatNumber = challengeNumber + 1;

  const challengeTitle =
    guide?.preprType === 'earlyYear'
      ? 'digitalguides.ey.challenge'
      : 'digitalguides.ey.classroom_life.activity.list';

  return (
    <Wrapper onClick={onClick}>
      <TitleWrapper>
        <NumBox>
          <span>{formatNumber}</span>
        </NumBox>
        <div>
          <Title color={theme.tokens.color.global.teal.teal700.value}>
            {t(challengeTitle, {
              num: formatNumber,
            })}
          </Title>
          <PrintableSubtitle1>{title}</PrintableSubtitle1>
        </div>
      </TitleWrapper>
      <Actions>
        {isConfettiAnimation && (
          <ConfettiWrapper>
            <Confetti width={200} />
          </ConfettiWrapper>
        )}
        {canMarkAsDone && (
          <ChallengeCheckbox
            label={
              isChallengeDone(id)
                ? t('digitalguides.ey.challenge.done', 'Finalitzat')
                : t('digitalguides.ey.challenge.sessiondone', 'Finalitzat')
            }
            size="md"
            onChange={handleChange}
            checked={isChallengeDone(id)}
          />
        )}
        {onClick && (
          <ArrowActionButton
            isOpen={getIsOpen(challengeNumber)}
            className="actionButton"
          />
        )}
      </Actions>
    </Wrapper>
  );
}
