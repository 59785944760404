import React from 'react';
import { CardWrapper } from './card-select.styled';
import { StateLayer } from '../../utils/common.styled';

type CardSelectProps = {
  children: React.ReactNode;
  status?: CardSelectStatus;
} & React.HTMLAttributes<HTMLDivElement>;

export type CardSelectStatus = 'default' | 'selected' | 'disabled';

export function CardSelect({
  children,
  status = 'default',
  ...rest
}: CardSelectProps): JSX.Element {
  return (
    <CardWrapper status={status} {...rest}>
      <StateLayer className="avatar-card-state-layer" />
      {children}
    </CardWrapper>
  );
}
