import * as React from 'react';
import type { SVGProps } from 'react';
const SvgTrust = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <circle
      cx={10}
      cy={10}
      r={10}
      fill="#006AFF"
      transform="rotate(-180 10 10)"
    />
    <path
      fill="#fff"
      d="M15 6.55c0-.41-.145-.804-.402-1.095A1.3 1.3 0 0 0 13.626 5H6.374a1.3 1.3 0 0 0-.972.455C5.145 5.745 5 6.14 5 6.55v2.179a8.7 8.7 0 0 0 1.24 4.489c.803 1.322 1.941 2.343 3.266 2.93.318.138.67.138.988 0 1.325-.587 2.463-1.608 3.267-2.93A8.7 8.7 0 0 0 15 8.727zM6.429 8.727v-1.92c0-.053.018-.104.052-.142a.17.17 0 0 1 .126-.059h3.214a.17.17 0 0 1 .127.059.21.21 0 0 1 .052.142v7.528a.2.2 0 0 1-.022.096.2.2 0 0 1-.06.073.162.162 0 0 1-.17.014c-.988-.507-1.826-1.322-2.414-2.349a7 7 0 0 1-.905-3.442"
    />
  </svg>
);
export default SvgTrust;
