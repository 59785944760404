import { AppAction } from '../root/root.interfaces';
import {
  initialErrorsState,
  ErrorsState,
} from '../errors/errors.interfaces';
import { actions } from '../errors/errors.actions';

function errorsReducer(
  state: ErrorsState = initialErrorsState,
  action: AppAction
): any {
  switch (action.type) {
    case actions.SET_ERROR:
      return {
        ...state,
        [action.key]: action.error || 'Something went wrong',
        // [action.key]: state[action.key] ? [...state[action.key], action.error] : action.error,
      };
    case actions.CLEAN_ERRORS:
      if (action.key) {
        const { [action.key]: deletedKey, ...otherKeys } = state;
        return {
          ...otherKeys,
        };
      }
      return {
        ...initialErrorsState,
      };
    default:
      return state;
  }
}

export default errorsReducer;
