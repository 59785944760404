
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as CbRelationshipsChangeIconComponent } from "./cb-relationships-change-icon.svg";

export const CbRelationshipsChangeIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <CbRelationshipsChangeIconComponent {...props} />;
};
