import { Icon, Typography } from '@innovamat/glimmer-components';
import { Trans } from 'react-i18next';
import styled from '@emotion/styled';

function TableLegend() {
  return (
    <Container>
      <div>
        <Icon icon="KeyEnterIcon" />
        <Typography.Body3>
          <Trans
            i18nKey="table.legend.enter"
            components={{
              b: <b />,
            }}
          />
        </Typography.Body3>
      </div>
      <div>
        <Icon icon="KeyTabIcon" />
        <Typography.Body3>
          <Trans
            i18nKey="table.legend.keyboard"
            components={{
              b: <b />,
            }}
          />
        </Typography.Body3>
      </div>
    </Container>
  );
}

export default TableLegend;

const Container = styled.div`
  margin-top: 32px;
  display: flex;
  gap: 24px;
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};

  > div {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  svg {
    path {
      fill: ${({ theme }) =>
        theme.tokens.color.alias.cm.text['text-subtle'].value};
    }
  }
`;
