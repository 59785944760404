import styled from '@emotion/styled';

import { useUser } from '@innovamat/ga-features';
import { Grid, Grounder } from '@innovamat/glimmer-components';

import { Shortcuts, ShortcutsSkeleton } from '../../../../contents';
import { MyClasses, MyClassesSkeleton } from '../../../components/my-classes';

const Container = styled(Grid.Container)`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

export function LandingPageTeacher(): JSX.Element {
  const { user } = useUser();

  return (
    <Container>
      <Grid.Row justifyContent="center">
        <Grid.Col md={10} lg={8}>
          {user ? <MyClasses /> : <MyClassesSkeleton />}
        </Grid.Col>
      </Grid.Row>

      <Grid.Row justifyContent="center">
        <Grid.Col md={10} lg={8}>
          {user ? (
            <Shortcuts
              organizationId={user.organizationId}
              roles={user.roles}
              showFamilyPayments
            />
          ) : (
            <ShortcutsSkeleton />
          )}
        </Grid.Col>
      </Grid.Row>
      <Grounder />
    </Container>
  );
}
