/* eslint-disable camelcase */
import { eventLogging } from '@innovamat/event-logging';
import { AuthUser } from '../store/auth/types';
import { JSONObject } from '../store/root/root.interfaces';
import { store } from '../store/store';

export function getCurrentUser(): AuthUser {
  return store.getState().auth.user;
}

export function getPlatformUser(): JSONObject {
  return store.getState().uData.User || store.getState().uData.Account;
}

export const setEventData = (
  eventType: string,
  eventProperties: any = {},
  addUserData = true
): void => {
  const eventData: any = {};

  const {
    fromScene,
    loadingTime,
    packOrVariationToLoad,
    sceneToLoad,
    ...rest
  } = eventProperties;

  if (addUserData) {
    const user = getCurrentUser();
    const platformUser = getPlatformUser();

    if (user) {
      const role = user.roles ? user.roles?.join() : 'Not defined';
      const school_id = user.school ? user.school : 'Not defined';
      const { crmId, language, region, uid } = user;
      eventData.username = user?.email;
      eventData.role = role;
      eventData.school_id = school_id;
      eventData.crm_id = crmId;
      eventData.language = language;
      eventData.region = region;
      eventData.user_uuid = uid;
    }
    if (platformUser) {
      eventData.platform_student_id = platformUser.id;
    }
  }
  eventData.from_scene = fromScene;
  eventData.loading_time = loadingTime;
  eventData.pack_or_variation = packOrVariationToLoad;
  eventData.scene_name = sceneToLoad;
  eventData.app_version = 'web-app';
  eventData.payload = JSON.stringify(rest);

  eventLogging.log(eventType, eventData);
};
