import styled from '@emotion/styled';

import Icon from '../Icon';

const ArrowIcon = styled(Icon)``;

const Wrapper = styled('div')<{ isOrder: boolean }>`
  display: flex;
  span {
    padding-right: 0.5rem;
  }

  g,
  path {
    fill: ${({ isOrder, theme }) =>
      isOrder ? theme.colors.semantic['04'] : theme.colors.semantic['02']};
  }
`;

export { ArrowIcon, Wrapper };
