import styled from '@emotion/styled';
import { ContentBlocks } from '../ContentBlocks/ContentBlocks';
import { PreprInnerHtml } from '../PreprInnerHtml';
import { useSmartGuides } from '../../context/SmartGuidesProvider';
import { Media } from '../Media';
import { CollapsablePartPrintable } from '../CollapsablePart/CollapsablePartPrintable';
import { RenderStep } from './RenderStep';
import { PrintableBody1 } from '../../printable-styles/printable-typography';
import { VideoThemes } from '../VideoThemes';
import { NumberedStep } from '../NumberedStep';
import { Trans } from 'react-i18next';

const PartsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .tooltip-span {
    display: inline;
  }

  a {
    /* color: inherit; */
  }
`;

const getPrintableSupportExtensionText = (
  hasSupport?: boolean,
  hasExtension?: boolean
): string => {
  if (!hasSupport) return '';
  return hasExtension
    ? 'smartguides.parts.support-and-extension.printable'
    : 'smartguides.parts.support.printable';
};

export function PartsPrintable() {
  const {
    smartGuide: { parts },
    t,
  } = useSmartGuides();

  return (
    <PartsContainer>
      {parts.map((part, index: number) => {
        if (part.partNumber === 0) {
          return (
            <div key={`part-printable-${index}`}>
              <CollapsablePartPrintable
                part={part.partNumber}
                momentTitle={t(part.title)}
                activityTitle={t(part.oneLineSummary || '')}
                partIcon={part.partIcon}
              >
                <PrintableBody1>
                  <PreprInnerHtml text={part.beforeStartContent?.content} />
                </PrintableBody1>
                <Media media={part.beforeStartContent?.media} />
              </CollapsablePartPrintable>
            </div>
          );
        }

        if (part.id === 'smartGuidePartVideo') {
          return (
            <CollapsablePartPrintable
              part={4}
              momentTitle={t(part.title || '')}
              activityTitle={t(part.chapterName || '')}
              activityTitleExtra={`(${part.chapterDuration} min.)`}
            >
              <VideoThemes chapterContent={part.videoChapterContent} />
            </CollapsablePartPrintable>
          );
        }

        return (
          <div key={`part-printable-${index}`}>
            <CollapsablePartPrintable
              part={+part.partNumber}
              momentTitle={t(part.title, {
                num: part.activityNumber,
              })}
              activityTitle={t(part.oneLineSummary || '')}
              partIcon={part.partIcon}
              evaluable={part.evaluable}
            >
              {part.contentBlocks && (
                <ContentBlocks
                  part={+part.partNumber}
                  contentBlocks={part.contentBlocks}
                />
              )}

              {(part.hasSupport || part.hasExtension) && (
                <NumberedStep
                  content={[
                    {
                      __typename: 'Text',
                      text: (
                        <Trans
                          i18nKey={getPrintableSupportExtensionText(
                            part.hasSupport,
                            part.hasExtension
                          )}
                          components={{
                            b: <b />,
                          }}
                        />
                      ) as unknown as string,
                    },
                  ]}
                  part={+part.partNumber}
                />
              )}

              {part.steps?.map((step, index: number) => (
                <RenderStep
                  key={`step-${index}`}
                  step={step}
                  index={index}
                  partNumber={+part.partNumber}
                  isPrintable
                />
              ))}
            </CollapsablePartPrintable>
          </div>
        );
      })}
    </PartsContainer>
  );
}
