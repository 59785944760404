import { useContext } from 'react';
import { AuthStateContext } from '../providers/AuthProvider';

export function useAuthState() {
  const context = useContext(AuthStateContext);

  if (context === undefined) {
    throw new Error('useAuthState must be used within a AuthProvider');
  }

  return context;
}
