import { Icon } from '@innovamat/innova-components';
import { ImageComponent } from '@innovamat/glimmer-components';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const Background = styled.div<{ img: string }>`
  height: 100vh;
  padding: 10% 20%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('${props.img}') no-repeat center center fixed`};
  background-size: cover;
`;

export const Container = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Thumbnail = styled(ImageComponent)`
  max-width: 100%;
  display: block;
  width: 100%;
  height: 100%;
`;

export const ThumbnailContainer = styled.div`
  border-radius: 8px;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.white};
  padding: 5px;
  max-width: 400px;
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
`;

export const StarsContainer = styled.div<{ zoomIn: boolean }>`
  display: flex;
  gap: 24px;
  margin-top: 10%;
  max-width: 80%;
  transition: transform 1.5s linear;
  ${(props) =>
    props.zoomIn &&
    css`
      transform: scale(1.8);
    `}
`;

export const ButtonsContainer = styled.div<{ visible: boolean }>`
  display: flex;
  align-items: center;
  gap: 200px;
  justify-content: center;
  width: 100%;
  margin-top: 200px;
  transition: opacity 1s ease-in;
  opacity: ${(props) => (props.visible ? '1' : '0')};
`;

export const ButtonStyled = styled.button`
  background: transparent;
  border: 0;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.robotoMedium};
  font-weight: 500;
  font-size: ${({ theme }) => theme.sizes.normal};
  cursor: pointer;
  gap: 8px;
`;

export const ButtonNextActivity = styled.button`
  font-family: ${({ theme }) => theme.fonts.robotoMedium};
  font-weight: 500;
  font-size: ${({ theme }) => theme.sizes.normal};
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.dark['01']};
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const IconStyled = styled(Icon)`
  path {
    fill: ${({ theme }) => theme.colors.white} !important;
  }
`;
