
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as ScoreIconComponent } from "./score-icon.svg";

export const ScoreIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <ScoreIconComponent {...props} />;
};
