import { AuthAction } from './actions';
import { AuthState } from './types';

export const initState: AuthState = {
  loginResponse: undefined,
  loading: undefined,
  error: undefined,
  loggedFrom:
    typeof window !== 'undefined'
      ? localStorage.getItem('loggedFrom') || undefined
      : undefined,
};

function authReducer(state: AuthState, action: AuthAction): AuthState {
  switch (action.type) {
    case 'SET_LOGIN_INFO': {
      localStorage.setItem('loggedFrom', action.payload.loggedFrom.type);
      return {
        ...state,
        loggedFrom: action.payload.loggedFrom.type,
        loginResponse: action.payload.response,
      };
    }
    case 'SET_IS_LOADING': {
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.payload.key.type]: action.payload.loading,
        },
      };
    }
    case 'CLEAR': {
      return {
        ...state,
        loginResponse: undefined,
        loading: undefined,
        error: undefined,
      };
    }
    case 'SET_ERROR': {
      return {
        ...state,
        error: action.payload,
      };
    }
    default:
      return state;
  }
}

export default authReducer;
