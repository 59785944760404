import { TokenData } from '@innovamat/radiance-utils';
import authActionTypes from './actionTypes';
import { AuthUser, DeviceCodeData } from './types';

const authActions = {
  loginSuccess: (payload: TokenData) => ({
    type: authActionTypes.LOGIN_SUCCESS,
    payload,
  }),
  deviceCodeSuccess: (payload: DeviceCodeData) => ({
    type: authActionTypes.DEVICE_CODE_SUCCESS,
    payload,
  }),
  setUserRequest: (payload: TokenData) => ({
    type: authActionTypes.SET_USER_REQUEST,
    payload,
  }),
  setUserRequestSuccess: (payload: AuthUser) => ({
    type: authActionTypes.SET_USER_SUCCESS,
    payload,
  }),
  setUserError: (payload: any) => ({
    type: authActionTypes.SET_USER_ERROR,
    payload,
  }),

  refreshToken: (payload: TokenData) => ({
    type: authActionTypes.REFRESH_TOKEN,
    payload,
  }),
};

export type AuthActionType =
  | ReturnType<typeof authActions.loginSuccess>
  | ReturnType<typeof authActions.deviceCodeSuccess>
  | ReturnType<typeof authActions.setUserRequest>
  | ReturnType<typeof authActions.setUserRequestSuccess>
  | ReturnType<typeof authActions.setUserError>
  | ReturnType<typeof authActions.refreshToken>;

export default authActions;
