import styled from '@emotion/styled';

import { Typography, useGlimmerTheme } from '@innovamat/glimmer-components';

type Props = {
  children: React.ReactNode | string;
  title?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const Title = styled(Typography.SuperTitle)`
  @media print {
    font-size: 10px !important;
    letter-spacing: 0.5px !important;
  }
`;

const Wrapper = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.sizes.small};
  line-height: ${({ theme }) => theme.sizes.medium};
  height: 100%;
  margin-top: 4px;

  @media print {
    margin-top: 0px;
  }
`;

export default function BlockContent({ children, title, ...rest }: Props) {
  const theme = useGlimmerTheme();

  return (
    <Wrapper {...rest}>
      {title && (
        <Title
          color={theme.tokens.color.alias.cm.text['text-subtle'].value}
          className="blockContentTitle"
        >
          {title}
        </Title>
      )}
      {children}
    </Wrapper>
  );
}
