import { useTranslation } from 'react-i18next';
import {
  Drawer,
  IconButton,
  Typography,
  theme,
} from '@innovamat/glimmer-components';
import styled from '@emotion/styled';

type Props = {
  size: 'sm' | 'md' | 'lg';
  title: string;
  date: string;
  children: JSX.Element;
  showDrawer: boolean;
  handleClose: () => void;
};

function SummaryDrawer({
  size,
  title,
  date,
  children,
  showDrawer,
  handleClose,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <Drawer
      open={showDrawer}
      position="right"
      roundedBorder
      customStyles={{
        padding: '24px',
        width: `${size === 'sm' ? '40%' : '60%'}`,
      }}
      closeOnClickOutside={true}
      onClose={handleClose}
    >
      <ActionsWrapper>
        <IconButton
          tooltipText={t('reports.asideModal.close')}
          onClick={handleClose}
          icon="CloseIcon"
        />
      </ActionsWrapper>
      <ModalHeader>
        <Typography.SuperTitle
          color={`${theme.tokens.color.alias.cm.text['text-subtle'].value}`}
        >
          {title}
        </Typography.SuperTitle>
        <Date color={`${theme.tokens.color.alias.cm.text.text.value}`}>
          {date}
        </Date>
      </ModalHeader>
      {children}
    </Drawer>
  );
}

const ActionsWrapper = styled.div`
  display: flex;
`;

const ModalHeader = styled.div`
  padding: 24px 0 8px;
  border-bottom: 2px solid
    ${({ theme }) =>
      theme.tokens.color.alias.cm.border['border-inverted'].value};
`;

const Date = styled(Typography.H3)`
  margin-top: 4px;
`;

export default SummaryDrawer;
