import { BorderOverlay } from '@innovamat/glimmer-components';
import { Trans } from 'react-i18next';
import styled from '@emotion/styled';
import { useUser } from '../providers';

const GoBack = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

function StudentViewOverlay(): JSX.Element {
  const { isStudentViewActive, onToggleStudentView } = useUser();

  if (!isStudentViewActive) return <></>;

  return (
    <BorderOverlay
      content={
        <Trans
          i18nKey="student_view.overlay"
          components={{
            a: <GoBack onClick={() => onToggleStudentView('overlay')} />,
          }}
        />
      }
    />
  );
}

export { StudentViewOverlay };
