import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Modal, getTypography } from '@innovamat/glimmer-components';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const NoActivitiesPopUp = ({ isOpen, onClose }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('secondaryPractice.noActivitiesPopup.title')}
      semantic={{ type: 'warning', text: t('common.modal.important') }}
      isOpen={isOpen}
      closeOnClickOutside
      closeOnEsc
      closeButton="inner"
      onClose={onClose}
      buttons={[
        {
          children: t('teacher.onboarding.createProfileFilled.error.button'),
          variant: 'accent',
          onClick: onClose,
        },
      ]}
      modalWidth={700}
    >
      <ModalInformation>
        {t('secondaryPractice.noActivitiesPopup.body')}
      </ModalInformation>
    </Modal>
  );
};

const ModalInformation = styled.div`
  ${({ theme }) => getTypography(theme, 'Body 1')}
  margin-bottom: 16px;
`;
