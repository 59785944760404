import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSchoolBoardMaths = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M.625.625h18.75v12.5H.625ZM3.125 13.125v6.25M16.875 13.125v6.25M3.125 16.875h13.75"
      />
      <path
        strokeWidth={1.249995}
        d="M3.75 5.625A1.25 1.25 0 0 1 5 4.375h0a1.25 1.25 0 0 1 1.25 1.25h0a1.78 1.78 0 0 1-.39 1.113L3.75 9.375h2.5M14.375 4.375H15a.625.625 0 0 1 .625.625v4.375M14.375 9.375h2.5M8.75 6.875h3.75M10.625 5v3.75"
      />
    </g>
  </svg>
);
export default SvgSchoolBoardMaths;
