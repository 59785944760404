import { ThemeProvider } from 'styled-components';
import React from 'react';
import { ThemeProvider as ThemeProviderInnovacomponents } from '@innovamat/innova-components';
import { FontsStyle } from './fonts';
import { GlobalStyle, theme } from './theme';
import { ThemeProvider as ThemeProviderGlimmerComponents } from '@innovamat/glimmer-components';

type Props = {
  children: React.ReactNode;
};

export default function ThemeWrapper({ children }: Props): JSX.Element {
  return (
    <ThemeProviderInnovacomponents>
      <ThemeProvider theme={theme}>
        <ThemeProviderGlimmerComponents type={'light'}>
          <>
            <FontsStyle />
            <GlobalStyle />
            {children}
          </>
        </ThemeProviderGlimmerComponents>
      </ThemeProvider>
    </ThemeProviderInnovacomponents>
  );
}
