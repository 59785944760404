import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Tooltip, IconButton } from '@innovamat/glimmer-components';
import useSpaces from '../useSpaces';
import { withBreakpoint } from '../../digital-guides';

const Title = styled.div`
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-weight: 400;
  line-height: 23.44px;
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding-right: 91px;

  span {
    font-weight: 600;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding-right: 0;
  }
`;

const HeaderWrapper = styled.div<{ isSticky: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 52px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark['05']};
  color: ${({ theme }) => theme.colors.dark['01']};
`;

const Wrapper = styled.div`
  width: 100%;
  height: 52px;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 60px;
  ${withBreakpoint(css`
    padding: 0 24px;
  `)}
`;

const DefaultHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 52px;

  > button {
    margin-bottom: 4px;
    margin-top: 2px;
    font-weight: 600;
    @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
      margin-left: 6px !important;
    }
  }
`;

const Sticky = styled.div`
  z-index: 12;
  width: 100%;
`;

type HeaderProps = {
  title: string;
  onGoBack?: () => void;
};

export default function LayoutSpacesHeader({ title, onGoBack }: HeaderProps) {
  const { t } = useSpaces();

  const splitTitle = title.split('-');

  return (
    <Sticky>
      <HeaderWrapper isSticky={true}>
        <Wrapper>
          <DefaultHeader>
            {onGoBack && (
              <Tooltip content={t('back.button')}>
                <IconButton
                  icon="BackIcon"
                  onClick={onGoBack}
                  data-inlinemanual="courseselector"
                />
              </Tooltip>
            )}

            <Title>
              <b>{splitTitle[0]}</b>
              {splitTitle.length > 1 && ` -${splitTitle[1]}`}
            </Title>
          </DefaultHeader>
        </Wrapper>
      </HeaderWrapper>
    </Sticky>
  );
}
