import styled from '@emotion/styled';
import { Loader } from '@innovamat/glimmer-components';

export default function MainLoader() {
  return (
    <LoaderWrapper>
      <Loader size="md" color="white" data-testid="playerLoader" />
    </LoaderWrapper>
  );
}

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.black};
  z-index: 1;
`;
