import styled from '@emotion/styled';
import { useUpdateEffect } from '@innovamat/hooks';
import { useEffect, useRef, useState } from 'react';
import { useBeforeunload } from 'react-beforeunload';
import { FullScreenHandle } from 'react-full-screen';

import ReactPlayer from 'react-player';
import LoaderContainer from '../../components/LoaderContainer';
import usePlayerDispatch from '../../providers/PlayerProviders/usePlayerDispatch';
import useVideoContext from '../../providers/VideoProviders/useVideoContext';
import { VideoOptions } from '../../types/resourceViewer';
import { detectIsInIOS } from '@innovamat/webview-bridge';

type Props = {
  videoId: string;
  url: string;
  fullscreen: FullScreenHandle;
  onEnded?: (duration: number) => void;
  defaultProgress?: number;
  videoOptions?: VideoOptions;
  autoplay?: boolean;
  dataTestId?: string;
};

enum EventType {
  PLAYER_STARTED = 'PLAYER_STARTED',
  PLAYER_PAUSED = 'PLAYER_PAUSED',
  PLAYER_ENDED = 'PLAYER_ENDED',
  ENTER_FULLSCREEN = 'ENTER_FULLSCREEN',
  EXIT_FULLSCREEN = 'EXIT_FULLSCREEN',
  VIDEO_LEFT = 'VIDEO_LEFT',
}
const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  /* workaround to fix the cards overlay issue https://innovamat.atlassian.net/browse/GAM-3665 :
  Here https://help.vimeo.com/hc/en-us/articles/12426192274961-Add-cards-to-videos in section
  section "Embed code for videos with cards" says that to support cards in all viewing environments,
  they add a javascript wrapper to the regular embed code. This wrapper has a "position: relative;"
  breaking our layout. Since we already have a position relative in some of the parent's div, I've removed the position relative
  related to the wrapper. Not promise this could eventually lead into more troubles. Forgive me Lord. */
  .react-player {
    > div {
      > div {
        position: inherit !important;
      }
    }
  }
`;

export default function VideoPlayer({
  videoId,
  url,
  fullscreen,
  onEnded,
  defaultProgress,
  videoOptions,
  autoplay = true,
  dataTestId,
}: Props) {
  const dispatch = usePlayerDispatch();
  const {
    videoRef,
    getProgress,
    duration,
    setDuration,
    progressList,
    setProgressList,
  } = useVideoContext();
  const [isLoading, setIsLoading] = useState(true);

  // TRICKY REFS TO SEND EVENT WITH THIS DATA WHEN THE USER LEFT THE VIDEO
  const progressRef = useRef(0);
  const playingRef = useRef(true);
  const durationRef = useRef(0);

  useEffect(() => {
    durationRef.current = duration;

    if (defaultProgress === undefined) return;

    const timeToStart =
      defaultProgress >= 100 ? 0 : (defaultProgress * duration) / 100;

    videoRef.current?.seekTo(timeToStart, 'seconds');
  }, [duration]);

  useUpdateEffect(() => {
    handleDispatch(
      fullscreen?.active
        ? EventType.ENTER_FULLSCREEN
        : EventType.EXIT_FULLSCREEN
    );
  }, [fullscreen?.active]);

  const handleDispatch = (eventType: EventType) => {
    dispatch({
      type: eventType,
      payload: {
        duration,
        progress: getProgress(),
      },
    });
    setProgressList({
      ...progressList,
      [url]: (getProgress() * 100) / duration,
    });
  };

  const handleOnPlay = () => {
    playingRef.current = true;
    handleDispatch(EventType.PLAYER_STARTED);
  };

  const handleOnPause = () => {
    playingRef.current = false;
    const progress = getProgress();
    if (duration !== Math.round(progress)) {
      handleDispatch(EventType.PLAYER_PAUSED);
    }
  };

  const handleOnEnded = () => {
    handleDispatch(EventType.PLAYER_ENDED);
    onEnded?.(duration);
  };

  useBeforeunload(() => {
    // IDK why the player object is not typed inside the ReactPlayer
    const { player } = videoRef.current as any;
    if (player.isPlaying) {
      handleDispatch(EventType.VIDEO_LEFT);
    }
  });

  useEffect(
    () => () => {
      if (durationRef.current && progressRef.current) {
        dispatch({
          type: EventType.VIDEO_LEFT,
          payload: {
            duration: durationRef.current,
            progress: progressRef.current,
            videoId: videoId,
          },
        });
        setProgressList({
          ...progressList,
          [url]: (progressRef.current * 100) / durationRef.current,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleDuration = (value: number) => {
    if (duration === 0) {
      setDuration(value);
    }
  };

  const handleOnProgress = (e: { playedSeconds: number }) => {
    progressRef.current = e.playedSeconds;
  };

  return (
    <Wrapper key={url} data-testid={dataTestId}>
      {isLoading && <LoaderContainer />}
      <ReactPlayer
        ref={videoRef}
        url={url}
        playing={playingRef.current && autoplay && !detectIsInIOS()}
        width="100%"
        height="100%"
        onPlay={handleOnPlay}
        controls
        onEnded={handleOnEnded}
        onPause={handleOnPause}
        onProgress={handleOnProgress}
        onDuration={handleDuration}
        onReady={() => setIsLoading(false)}
        stopOnUnmount={false}
        config={{
          vimeo: { playerOptions: videoOptions },
        }}
        className="react-player"
      />
    </Wrapper>
  );
}
