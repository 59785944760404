import * as React from 'react';
import type { SVGProps } from 'react';
const SvgWhatsapp = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <path
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.249995}
      d="M10 .625a9.35 9.35 0 0 0-9.383 9.3 9.17 9.17 0 0 0 1.783 5.45l-1.167 3.458 3.6-1.141a9.383 9.383 0 0 0 14.534-7.767A9.35 9.35 0 0 0 10 .625m5.058 11.592c-.058-.1-.225-.167-.466-.284s-1.45-.708-1.667-.791-.392-.125-.55.125-.417.608-.558.766-.284.184-.534.059a7.67 7.67 0 0 1-3.45-2.984c-.141-.241 0-.375.109-.491.408-.409.416-.35.516-.55a.44.44 0 0 0 0-.425c-.125-.142-.583-1.325-.791-1.809S6.408 5.258 6 5.708c-4.333 4.567 6.225 12.392 8.925 7.625a1.18 1.18 0 0 0 .133-1.1"
    />
  </svg>
);
export default SvgWhatsapp;
