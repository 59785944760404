
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as GuideIconComponent } from "./guide-icon.svg";

export const GuideIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <GuideIconComponent {...props} />;
};
