import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAbacus = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M1.25.625h17.5v18.75H1.25ZM6.25.625v2.5M6.25 5.625v5M6.25 16.875v2.5"
      />
      <path
        strokeWidth={1.249995}
        d="M4.375 3.125h3.75v2.5h-3.75ZM4.375 10.625h3.75v2.5h-3.75ZM4.375 14.375h3.75v2.5h-3.75ZM13.75 19.375v-2.5M13.75 14.375v-5M13.75 3.125v-2.5M11.875 14.375h3.75v2.5h-3.75ZM11.875 6.875h3.75v2.5h-3.75ZM11.875 3.125h3.75v2.5h-3.75ZM13.75 5.625v1.25M6.25 13.125v1.25"
      />
    </g>
  </svg>
);
export default SvgAbacus;
