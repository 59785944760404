export const consoleVersion = (): void => {
  console.log(`                                                                            
  ██     ██ ███████ ██████   █████  ██████  ██████                             
  ██     ██ ██      ██   ██ ██   ██ ██   ██ ██   ██                            
  ██  █  ██ █████   ██████  ███████ ██████  ██████                             
  ██ ███ ██ ██      ██   ██ ██   ██ ██      ██                                 
   ███ ███  ███████ ██████  ██   ██ ██      ██                                 
                                                   
`);

  console.log(`
  ██    ██ ███████ ██████  ███████ ██  ██████  ███    ██ 
██    ██ ██      ██   ██ ██      ██ ██    ██ ████   ██ 
██    ██ █████   ██████  ███████ ██ ██    ██ ██ ██  ██ 
 ██  ██  ██      ██   ██      ██ ██ ██    ██ ██  ██ ██ 
  ████   ███████ ██   ██ ███████ ██  ██████  ██   ████ 
                                                       
                                                       
 ██     ██████      ██████                             
███    ██  ████    ██  ████                            
 ██    ██ ██ ██    ██ ██ ██                            
 ██    ████  ██    ████  ██                            
 ██ ██  ██████  ██  ██████  ██                         
  `);
};

// https://patorjk.com/software/taag/#p=display&f=ANSI%20Regular&t=webapp
