
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as ExpandLessIconComponent } from "./expand-less-icon.svg";

export const ExpandLessIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <ExpandLessIconComponent {...props} />;
};
