import { Typography } from '@innovamat/glimmer-components';
import styled from '@emotion/styled';
import { isNullOrUndefined } from '../utils/common';
import { getReportsTypography } from '../utils/typography';

export const TableContainer = styled.div`
  position: relative;
  display: grid;
  padding-right: 24px;
`;

export const BubblesContainer = styled.div`
  display: flex;
  margin-right: 18px;
`;

export const BubblesSubLabelsWrapper = styled.span`
  width: 88px;
  margin: 0 4px;
  :first-child {
    margin-left: 0;
  }
`;

export const Text = styled.span``;

export const InnerWrapper = styled.span`
  width: 88px;
  margin: 0 4px;
`;

export const Container = styled.div`
  display: flex;
`;

export const ContainerStatements = styled.div<{ totalApplets: number }>`
  display: flex;
  ${({ totalApplets }) =>
    totalApplets === 0 || isNullOrUndefined(totalApplets)
      ? `
      justify-content: center;
      align-items: center;
    `
      : ''}
`;

export const EllipsableText = styled(Typography.Body2)`
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100px;
  }
`;

export const SpacedContainer = styled.div`
  display: flex;
  gap: 8px;
  flex: 0 0 50%;
  cursor: default;
`;

export const SublabelContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: -18px;
  flex: 0 0 50%;
`;

export const AppletSelectorContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const AdaptedPlansContainer = styled.div``;

export const AdaptedPlansTitle = styled(Typography.H4)`
  margin-top: 40px;
`;

export const NoApplets = styled.div`
  color: ${({ theme }) =>
    theme.tokens.color.specific.reports.semantic['no-data'].value};
  flex: 0 0 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${getReportsTypography()}
`;

export const NumberOfApplets = styled.div`
  color: ${({ theme }) => theme.tokens.color.alias.cm.text['text'].value};
  ${getReportsTypography()}
  flex: 0 0 50%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

export const Suffix = styled(Typography.Subtitle2)`
  display: inline;
  padding-left: 2px;
`;

export const PrintArea = styled.div``;
