import styled from '@emotion/styled';
import { getDimensionsColor } from '../utils/colorsSelector';
import { css } from '@emotion/react';
import { getTypography } from '@innovamat/glimmer-components';

export const Badge = styled.div<{
  isDisabled?: boolean;
  type?: string;
  hasTooltip?: boolean;
}>`
  display: flex;
  padding: 5px 12px;
  width: fit-content;
  border-radius: 20px;
  white-space: nowrap;
  align-items: center;
  box-sizing: border-box;

  cursor: default;
  ${({ type, isDisabled, theme }) => {
    if (isDisabled) {
      return css`
        background-color: ${theme.colors.dark['06']};
        color: ${theme.colors.dark['04']};

        cursor: default;
      `;
    }

    return css`
      color: ${getDimensionsColor(type)?.dark};
      background-color: ${getDimensionsColor(type)?.light};
      border: 2px solid ${getDimensionsColor(type)?.medium}; /* borde de 2px de ancho */
    `;
  }}

  font-family: ${({ theme }) => theme.typography.rubikMedium};
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
`;
