import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Icon } from '../Icon';
import { ChangeEvent, InputHTMLAttributes, useState } from 'react';
import { useGlimmerTheme } from '../../theme';
import { StateLayer } from '../../utils/common.styled';
import { ConfettiAnimation } from '../ConfettiAnimation/ConfettiAnimation';

const StyledCheckbox = styled.input<{ solutions: boolean }>`
  cursor: pointer;
  position: relative;
  height: 18px;
  width: 18px;
  appearance: none;

  ${({ theme, solutions }) =>
    css`
      ::before {
        background-color: transparent;
        border: 1.5px solid
          ${theme.tokens.color.alias.cm.border['border-default'].value};
        box-sizing: border-box;
        border-radius: 3px;
        content: '';
        display: block;
        height: 18px;
        width: 18px;
        position: absolute;
        transition: background-color 0.2s, border-color 0.2s;
        box-sizing: border-box;
      }

      :disabled::before {
        cursor: default;
        border: 1.5px solid
          ${theme.tokens.color.alias.cm.border['border-disabled'].value};
      }

      :checked::before {
        background-color: ${solutions
          ? theme.tokens.color.alias.cm.bg['bg-accent-inverted'].value
          : theme.tokens.color.alias.cm.bg['bg-neutral-inverted'].value};

        border: 1.5px solid ${solutions ? 'transparent' : 'transparent'};
      }

      :disabled:checked::before {
        background-color: ${theme.tokens.color.alias.cm.bg['bg-disabled']
          .value};
        border: 1.5px solid ${solutions ? 'transparent' : 'transparent'};
      }
    `}
`;

const Check = styled(Icon)<{ disabled?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
`;

const CheckboxContainer = styled.div<{
  disabled: boolean;
  checked: boolean;
  solutions: boolean;
  customStyles?: string;
}>`
  position: relative;
  height: 18px;
  width: 18px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.surface['surface-primary'].value};

  &:hover .checkbox-stateLayer {
    top: 0;
    left: 0;
    border-radius: 3px;

    background-color: ${({ theme, disabled, checked, solutions }) =>
      !disabled &&
      ((checked &&
        !solutions &&
        theme.tokens.color.specific['state-layer']['state-hover-lighter']
          .value) ||
        theme.tokens.color.specific['state-layer']['state-hover-darker']
          .value)};
  }

  ${({ customStyles }) =>
    customStyles &&
    css`
      ${customStyles}
    `}
`;

const ConfettiWrapper = styled.div`
  position: absolute;
  bottom: -67px;
  right: -75px;
  z-index: 11;
`;
interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  checked?: boolean;
  onCheck?: (value: boolean) => void;
  solutions?: boolean;
  customStyles?: string;
  isConfettiAnimationEnabled?: boolean;
}

export function Checkbox({
  checked: controlledChecked,
  defaultChecked,
  onCheck,
  disabled,
  solutions,
  customStyles,
  isConfettiAnimationEnabled = false,
  ...rest
}: CheckboxProps) {
  const theme = useGlimmerTheme();
  const [uncontrolledChecked, setUncontrolledChecked] = useState(
    defaultChecked || false
  );
  const [isConfettiAnimation, setConfettiAnimation] = useState(false);
  const checked =
    controlledChecked !== undefined ? controlledChecked : uncontrolledChecked;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newChecked = event.target.checked;

    if (isConfettiAnimationEnabled && newChecked) {
      setConfettiAnimation(true);
      setTimeout(() => {
        setConfettiAnimation(false);
      }, 1500);
    }

    if (onCheck) {
      onCheck(newChecked);
    } else {
      setUncontrolledChecked(newChecked);
    }
  };

  const changeColor = () => {
    return disabled
      ? theme.tokens.color.alias.cm.icon['icon-disabled'].value
      : theme.tokens.color.alias.cm.icon['icon-inverted'].value;
  };

  return (
    <CheckboxContainer
      disabled={!!disabled}
      solutions={!!solutions}
      checked={!!checked}
      customStyles={customStyles}
    >
      <StateLayer className="checkbox-stateLayer" />
      {isConfettiAnimation && (
        <ConfettiWrapper>
          <ConfettiAnimation width={164} />
        </ConfettiWrapper>
      )}
      <StyledCheckbox
        data-testid="checkbox"
        type="checkbox"
        className="checkbox"
        disabled={disabled}
        checked={checked}
        solutions={!!solutions}
        onChange={handleChange}
        {...rest}
      />

      {checked && (
        <Check className="check" icon="CheckIcon" iconColor={changeColor()} />
      )}
    </CheckboxContainer>
  );
}
