import { useEffect, useRef, useState } from 'react';
import { UnityDataState } from '../../store/unityData/unityData.interfaces';
import styled from 'styled-components';
import { BuildData } from '../BuildWrapper/types';

const WebGLContent = styled.div`
  iframe {
    border: 0;
    height: 100%;
    height: ${({ theme }) => theme.appHeight};
    margin: 0;
    overflow: hidden;
    width: 100%;
  }
`;

type props = {
  unityDataInput: UnityDataState;
  bridgeFunction: (data: any) => void;
  buildData: BuildData;
};

function UnityWebGL(props: props): JSX.Element {
  const {
    buildData,
    unityDataInput,
    unityDataInput: {
      bridgeIsReady,
      sceneName,
      startLoadingTime,
      packOrVariation,
    },
    bridgeFunction,
  } = props;
  const iFrame = useRef<HTMLIFrameElement | null>(null);

  // Debug purposes code
  const [sendInputTimes, setSendInputTimes] = useState(0);

  function destroyIframe(): void {
    iFrame.current?.contentWindow?.postMessage(
      {
        eventId: 'Destroy',
      },
      '*'
    );
    // iFrame.current = null;
  }

  useEffect(() => {
    console.log('Mounting with data', unityDataInput);
    window.addEventListener('message', bridgeFunction, false);

    return () => {
      console.log('Unmount component with data', unityDataInput?.sceneName);
      if (sceneName) {
        console.log('Destroying iframe from unmount');
        destroyIframe();
        iFrame.current = null;
      }
      window.removeEventListener('message', bridgeFunction);
    };
  }, []);

  useEffect(() => {
    if (bridgeIsReady) {
      let message = {};
      message = {
        eventId: 'SendUnityMessage',
        arguments: unityDataInput,
      };
      console.log(
        'Sending message from react:',
        unityDataInput,
        sendInputTimes
      );
      iFrame.current?.contentWindow?.postMessage(message, '*');
      setSendInputTimes(sendInputTimes + 1);
    }
  }, [bridgeIsReady]);

  function initIframe(): void {
    iFrame.current?.contentWindow?.postMessage(
      {
        eventId: 'Create',
        arguments: {
          buildData: { ...buildData, startLoadingTime },
        },
      },
      '*'
    );
  }

  useEffect(() => {
    if (!bridgeIsReady) {
      console.log('Destroying iframe from scene');
      destroyIframe();
    }
  }, [bridgeIsReady]);

  return (
    <WebGLContent>
      <iframe
        ref={iFrame}
        src="public/builds.html"
        title="UnityApplet"
        onLoad={initIframe}
        key={`${sceneName}-${packOrVariation}`}
      />
    </WebGLContent>
  );
}

export default UnityWebGL;
