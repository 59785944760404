
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as StudentsIconComponent } from "./students-icon.svg";

export const StudentsIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <StudentsIconComponent {...props} />;
};
