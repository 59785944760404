import { useEffect, useState, useRef } from 'react';

declare global {
  interface Window {
    GGBApplet: any;
  }
}

const loadScript = (url: string, id: string) =>
  new Promise((resolve, reject) => {
    let ready = false;
    if (!document) {
      reject(new Error('Document was not defined'));
    }

    const tag = document.getElementsByTagName('script')[0];
    const script: any = document.createElement('script');

    script.crossOrigin = '';
    script.id = `${id}-script`;
    script.type = 'text/javascript';
    script.src = url;
    script.onreadystatechange = () => {
      if (!ready) {
        ready = true;
        resolve(script);
      }
    };
    script.onload = script.onreadystatechange;

    script.onerror = () => {
      reject(new Error('Error loading script.'));
    };

    script.onabort = () => {
      reject(new Error('Script loading aborted.'));
    };

    if (tag.parentNode != null) {
      //tag.parentNode.insertBefore(script, tag);
      tag.parentNode.insertBefore(script, tag);
    }
  });

const Geogebra = (props: any) => {
  const refProps = useRef(props);

  let { id, debug } = refProps.current;

  const { onReady, appletOnLoad } = refProps.current;

  if (!id) {
    id = 'ggb-applet';
  }
  if (!debug) {
    debug = false;
  }
  //If a JSX Component is not given as a prop, use h3 with children

  const url = 'https://geogebra.org/apps/deployggb.js';
  const [deployggbLoaded, setDeployggbLoaded] = useState(false);
  const [, setLoading] = useState(true);

  const [watchPropsChange, setWatchPropsChange] = useState(false);
  //gets called by GeoGebra after the Applet is ready
  const onAppletReady = () => {
    if (appletOnLoad) appletOnLoad();
    if (onReady) onReady();
    debug && console.log(`Applet with id "${id}" is ready`);
  };

  useEffect(() => {
    !deployggbLoaded &&
      loadScript(url, id)
        .then(() => {
          debug &&
            console.log(`script from "${url}" succesfull loaded into the DOM`);
          setDeployggbLoaded(true);
        })
        .catch((err) => console.error(err));

    return () => {
      setDeployggbLoaded(false);
      //removeScript(id);
      const tag = document.getElementById(`${id}-holder`);
      if (tag?.lastChild) {
        tag.lastChild.textContent = '';
      }
    };
  }, []);

  useEffect(() => {
    if (window.GGBApplet) {
      const parameter = JSON.parse(JSON.stringify(refProps.current));
      parameter.appletOnLoad = onAppletReady;
      const ggbApp = new window.GGBApplet(parameter, true);
      ggbApp.inject(id);
      setLoading(false);
      setWatchPropsChange(false);
      debug &&
        console.log(`applet with id "${id}" succesfull injected into the DOM`);
    }
    return () => {
      const tag = document.getElementById(`${id}-holder`);
      if (tag?.lastChild) {
        tag.lastChild.textContent = '';
      }
    };
  }, [deployggbLoaded, watchPropsChange]);

  return (
    <div
      style={{
        margin: 'auto',
      }}
      id={id}
    ></div>
  );
};

Geogebra.defaultProps = {
  appName: 'classic',
  width: 800,
  height: 600,
  showToolBar: true,
  showAlgebraInput: true,
  showMenuBar: true,
  reloadOnPropChange: false,
};

export default Geogebra;
