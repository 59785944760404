import styled from '@emotion/styled';
import { Loader } from '@innovamat/glimmer-components';

const LoaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.black};
  z-index: 60;
`;

function LoaderContainer() {
  return (
    <LoaderWrapper>
      <Loader size="md" color="white" data-testid="playerLoader" />
    </LoaderWrapper>
  );
}

export default LoaderContainer;
