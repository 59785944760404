import styled from '@emotion/styled';
import { IconButton, ImageComponent } from '@innovamat/glimmer-components';

type Props = { src: string; alt: string; onClose: (e?: any) => void };

const Image = styled(ImageComponent)`
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
`;

const TopActions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 4px;
`;

const CloseButton = styled(IconButton)`
  svg,
  path {
    fill: 'white';
  }

  &:hover .icon-stateLayer {
    background-color: ${({ theme }) =>
      theme.tokens.color.specific['state-layer']['state-hover-lighter'].value};
  }
`;

export function MediaModal({ src, alt, onClose }: Props) {
  return (
    <>
      <TopActions>
        <CloseButton
          icon="CloseIcon"
          size="M"
          iconColor="white"
          onClick={onClose}
        />
      </TopActions>
      <Image src={src} alt={alt} />
    </>
  );
}
