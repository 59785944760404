import * as rudderanalytics from 'rudder-sdk-js';
import iterable from './iterable';

export interface EventLoggingConfig {
  rudderstackApiKey?: string;
  iterableApiKey?: string;
  rudderstackUrl?: string;
  isInWebview?: boolean;
  isInIOS?: boolean;
  isInLocal?: boolean;
}

type Value = string | number | undefined | null;

export type Payload = Record<string, string | Value>;

let _iterableApiKey: string | undefined = undefined;
let _rudderstackApiKey: string | undefined = undefined;
let _isInWebview = false;
let _isInIOS = false;
let _isInLocal = false;

export const eventLogging = {
  init: ({
    iterableApiKey,
    rudderstackApiKey,
    rudderstackUrl,
    isInWebview,
    isInIOS,
    isInLocal,
  }: EventLoggingConfig) => {
    _iterableApiKey = iterableApiKey;
    _rudderstackApiKey = rudderstackApiKey;
    _isInWebview = isInWebview || false;
    _isInIOS = isInIOS || false;
    _isInLocal = isInLocal || false;

    if (iterableApiKey) {
      iterable.getInstance().init(iterableApiKey);
    }

    if (rudderstackApiKey && rudderstackUrl) {
      rudderanalytics.load(rudderstackApiKey, rudderstackUrl);
    }
  },
  setDevice: (deviceId: string) => {
    if (_iterableApiKey) {
      iterable.getInstance().setDevice(deviceId);
    }

    if (_rudderstackApiKey) {
      //todo
    }
  },
  log: (eventType: string, payload: Payload) => {
    // Do not log on local
    if (_isInLocal) return;

    // Add info OS if is webview
    if (_isInWebview) {
      payload['os'] = _isInIOS ? 'ios' : 'android';
    }

    if (_rudderstackApiKey) {
      // RUDDER STACK NO DEJA ENVIAR NULL, PERO NOSOTROS SI LOS ESTAMOS USANDO EN ALGUNOS EVENTOS
      rudderanalytics.track(eventType, payload as any);
    }

    if (_iterableApiKey) {
      iterable.getInstance().logEvent(eventType, payload);
    }
  },
  clear: () => {
    if (_rudderstackApiKey) {
      //todo clear
    }

    if (_iterableApiKey) {
      iterable.getInstance().clear();
    }
  },
  //METHODS TO REMOVE ASAP
  setIterableEmail: (email: string) => {
    iterable.getInstance().setUserEmail(email);
  },
};
