import styled from '@emotion/styled';
import { ImageTooltip } from '../../../components/Materials/ImageTooltip';
import { InnerHtml } from '@innovamat/glimmer-components';

const StyledSpan = styled(InnerHtml)`
  display: inline;
  color: ${({ theme }) => theme.tokens.color.specific.element.link.value};
  text-decoration: underline;

  :hover {
    color: ${({ theme }) =>
      theme.tokens.color.specific.element['link-hovered'].value};
  }

  @media print {
    /* color: inherit; */
    text-decoration: none;
  }
`;

type Props = {
  label: string;
  image?: string;
  text: string;
};

export function MaterialSpan({ label, image, text }: Props) {
  return (
    <ImageTooltip image={image} text={text} placement="bottom">
      <StyledSpan text={label} />
    </ImageTooltip>
  );
}
