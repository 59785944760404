import { AnyAction, Dispatch } from 'redux';
import { initializeUnityData } from '../store/unityData/unityData.actions';

export const KEY = 'unityData';

declare global {
  interface Window {
    api: any;
  }
}

function initializeElectronData(dispatch: Dispatch<AnyAction>): void {
  window.localStorage.removeItem(KEY);
  dispatch(initializeUnityData());
}

function addElectronData(data: any): void {
  window.localStorage.setItem(KEY, JSON.stringify(data));
}

function getElectronData(): any {
  return window.localStorage.getItem(KEY);
}

function isElectronApp(): boolean {
  return window.api !== undefined;
}

function triggerElectronChangeSceneEvent(sceneName: string): void {
  window.api.changeScene(sceneName);
}

export {
  initializeElectronData,
  addElectronData,
  getElectronData,
  isElectronApp,
  triggerElectronChangeSceneEvent,
};
