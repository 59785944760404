
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as AddPictureIconComponent } from "./add-picture-icon.svg";

export const AddPictureIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <AddPictureIconComponent {...props} />;
};
