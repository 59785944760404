import styled from '@emotion/styled';

export const AccordionItem = styled.div`
  padding: 24px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  scroll-margin-top: calc(var(--heightHeader) + 32px);
  &:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.24);
    .actionButton {
      background-color: ${({ theme }) => theme.colors.dark['05']};
    }
  }
`;

export const AccordionWrapper = styled.div`
  display: flex;
  gap: 32px;
  flex-direction: column;
`;

export const AccordionContent = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
