import { PreprPlayer } from '@innovamat/prepr.io';
import styled from '@emotion/styled';
import { AssetType } from '../../../types/prepr';
import { ImageModal } from '../../ImagesModal/ImagesModal';
import OverlayImage from '../../OverlayImage';

type Props = {
  asset: AssetType;
  id: string;
  onClickImage: (image: ImageModal | null) => void;
  isBigAsset?: boolean;
  width?: number;
  height?: number;
  color?: string;
};

const Wrapper = styled.div<{ width?: number; height?: number }>`
  width: ${({ width }) => (width ? `${width}px` : `${100}%`)};
  height: ${({ height }) => (height ? `${height}px` : `${100}%`)};
`;

export default function MomentBlockAsset({
  asset,
  id,
  onClickImage,
  isBigAsset,
  width,
  height,
  color,
}: Props) {
  if (!asset) return null;

  if (asset.label === 'Photo') {
    const overlayImage = {
      url: asset.cdn_files.items[0].url,
      title: asset.caption || '',
      id: asset.cdn_files.items[0].id,
    };

    return (
      <OverlayImage
        key={`image-${id}`}
        isBigImage={!!isBigAsset}
        image={overlayImage}
        onClickImage={onClickImage}
        color={color}
      />
    );
  }

  if (asset.label === 'Video') {
    return (
      <Wrapper width={width} height={height}>
        <PreprPlayer
          metadata={{
            videoId: asset.reference_id,
            videoTitle: asset.name,
          }}
          playbackId={asset.playback_id}
        />
      </Wrapper>
    );
  }

  return <span>This Asset is not implemented</span>;
}
