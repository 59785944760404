import styled from '@emotion/styled';

const TitleContainer = styled('div')<{ hasSeparator: boolean }>`
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  min-height: 55px;
  padding-bottom: 10px;

  ${({ theme, hasSeparator }) =>
    hasSeparator
      ? `border-bottom : ${theme.borders.size['01']} ${theme.colors.dark['05']}`
      : ''}
`;

const Title = styled('h2')``;

export { Title, TitleContainer };
