
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as ProjectableIconComponent } from "./projectable-icon.svg";

export const ProjectableIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <ProjectableIconComponent {...props} />;
};
