import * as React from 'react';
import type { SVGProps } from 'react';
const SvgStar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#757575"
      d="M19.913 7.2a1.3 1.3 0 0 0-.47-.613 1.3 1.3 0 0 0-.74-.236h-5.227a.43.43 0 0 1-.403-.28L11.202.806a1.3 1.3 0 0 0-.48-.587 1.288 1.288 0 0 0-1.467.015A1.27 1.27 0 0 0 8.79.85L6.91 6.087a.425.425 0 0 1-.403.28H1.281c-.266.001-.524.083-.741.235s-.38.367-.47.615a1.28 1.28 0 0 0 .387 1.4l4.446 3.651a.42.42 0 0 1 .137.459L3.169 18.27a1.26 1.26 0 0 0 .459 1.437 1.3 1.3 0 0 0 1.524-.002l4.582-3.329a.424.424 0 0 1 .507 0l4.591 3.329a1.296 1.296 0 0 0 2.056-.613 1.26 1.26 0 0 0-.03-.822l-1.871-5.544a.42.42 0 0 1 .137-.459l4.454-3.66a1.27 1.27 0 0 0 .335-1.41"
    />
  </svg>
);
export default SvgStar;
