
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as HistoryIconComponent } from "./history-icon.svg";

export const HistoryIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <HistoryIconComponent {...props} />;
};
