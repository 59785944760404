/* eslint-disable @typescript-eslint/no-this-alias */
let instance: Iterable;

class Iterable {
  apiKey?: string;
  email?: string;
  deviceId?: string;

  constructor() {
    if (!instance) {
      instance = this;
    }

    return instance;
  }

  getInstance() {
    return new Iterable();
  }

  init(key: string) {
    this.apiKey = key;
  }

  logEvent(event: string, payload = {}) {
    fetch(
      'https://api.iterable.com/api/events/track',
      this.headers(event, payload)
    );
  }
  setDevice(installationToken: string) {
    this.deviceId = installationToken;
  }

  setUserEmail(email: string) {
    this.email = email;
  }

  clear() {
    this.email = undefined;
    this.deviceId = undefined;
  }

  headers(event: string, payload: any) {
    return {
      headers: {
        'api-key': this.apiKey,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        eventName: event,
        dataFields: payload,
        email: this.email || payload?.username,
        userId: this.deviceId,
      }),
    } as RequestInit;
  }
}

const IterableSingleton = (function () {
  let instance: Iterable;

  function createInstance() {
    const object = new Iterable();
    return object;
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

export default IterableSingleton;
