import { useTheme } from '@emotion/react';
import { useEffect, useState } from 'react';

// Updated useMediaQuery hook to safely handle SSR
function useMediaQuery(query: string) {
  const [matches, setMatches] = useState(false); // Default to false

  useEffect(() => {
    const mediaQuery = window.matchMedia(query);
    const handleMatch = (e: MediaQueryListEvent) => setMatches(e.matches);

    mediaQuery.addEventListener('change', handleMatch);
    // Set initial value based on the query
    setMatches(mediaQuery.matches);

    return () => mediaQuery.removeEventListener('change', handleMatch);
  }, [query]);

  return matches;
}

// Updated isTouchDevice function to safely handle SSR
function isTouchDevice() {
  // Ensure navigator is defined
  const isChromebook =
    typeof navigator !== 'undefined' && /cros/i.test(navigator.userAgent);
  if (isChromebook) {
    return false;
  }
  return (
    !!(
      typeof window !== 'undefined' &&
      ('ontouchstart' in window ||
        ((window as any).DocumentTouch &&
          typeof document !== 'undefined' &&
          document instanceof (window as any).DocumentTouch))
    ) ||
    !!(
      typeof navigator !== 'undefined' &&
      (navigator.maxTouchPoints || (navigator as any).msMaxTouchPoints)
    )
  );
}

export function useDevice() {
  const theme = useTheme();
  // State initialized without assuming the availability of window
  const [isTouchDetected, setIsTouchDetected] = useState(false);

  useEffect(() => {
    // Check and set isTouchDetected safely within useEffect
    setIsTouchDetected(isTouchDevice());

    function handlePointerDown(event: PointerEvent): void {
      event.pointerType !== 'mouse'
        ? setIsTouchDetected(true)
        : setIsTouchDetected(false);
    }

    // Safely add event listener if window is defined
    if (typeof window !== 'undefined') {
      window.addEventListener('pointerdown', handlePointerDown);
      return () => window.removeEventListener('pointerdown', handlePointerDown);
    }

    return;
  }, []);

  // Use media queries with theme breakpoints
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const isTablet = useMediaQuery(`(max-width: ${theme.breakpoints.lg})`);
  const isSmallScreen = useMediaQuery(`(max-width: ${theme.breakpoints.xl})`);

  const isTouchableMobileOrTablet = isTouchDevice() || isTouchDetected;
  const isTouchable = isTouchDevice();

  return {
    isTablet,
    isMobile,
    isTouchableMobileOrTablet,
    isTouchable,
    isSmallScreen,
  };
}
