
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as CollapseAllIconComponent } from "./collapse-all-icon.svg";

export const CollapseAllIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <CollapseAllIconComponent {...props} />;
};
