import * as React from 'react';
import type { SVGProps } from 'react';
const SvgChair = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M13.583 5.625H6.417A1.25 1.25 0 0 1 5.176 4.22l.312-2.5A1.25 1.25 0 0 1 6.728.625h6.544a1.25 1.25 0 0 1 1.24 1.095l.312 2.5a1.25 1.25 0 0 1-1.24 1.405M10 14.375a8.48 8.48 0 0 1-6.014-2.242 1.25 1.25 0 1 1 1.77-1.765A6.07 6.07 0 0 0 10 11.875a6.07 6.07 0 0 0 4.244-1.507 1.25 1.25 0 1 1 1.77 1.765A8.48 8.48 0 0 1 10 14.375"
      />
      <path
        strokeWidth={1.249995}
        d="m6.25 5.615-.63 4.635a1 1 0 0 1 .136.118M14.244 10.368a1 1 0 0 1 .137-.118l-.631-4.636M15.625 19.375l-.854-6.265M5.23 13.11l-.855 6.265"
      />
    </g>
  </svg>
);
export default SvgChair;
