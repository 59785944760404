import styled from '@emotion/styled';
import { Typography } from '@innovamat/glimmer-components';

const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  opacity: 0;
`;

const InvitationEmail = styled(Typography.Body2)`
  color: ${(props) =>
    props.theme.tokens.color.alias.cm.text['text-disabled'].value};
`;

export { ActionsContainer, InvitationEmail };
