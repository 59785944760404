import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDepart = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="m1.429 5.428 1.858-.6a.6.6 0 0 1 .694.232l1.282 2 7.034-3.48a4.414 4.414 0 0 1 6.358 2.26 1.2 1.2 0 0 1-.8 1.498l-5.078 1.64-.872 2.988a.65.65 0 0 1-.426.44l-2.078.678a.656.656 0 0 1-.846-.74l.382-2.12-4.81 1.556a1.18 1.18 0 0 1-1.506-.716L1.027 6.16a.6.6 0 0 1 .402-.732M19 17H1"
    />
  </svg>
);
export default SvgDepart;
