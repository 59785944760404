
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as DownloadFilesIconComponent } from "./download-files-icon.svg";

export const DownloadFilesIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <DownloadFilesIconComponent {...props} />;
};
