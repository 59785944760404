import React, { useEffect, useRef, useState } from 'react';
import { useEventListener, useKeyPress } from '@innovamat/hooks';
import { MailChip } from '../mail-chip';
import styled from '@emotion/styled';
import { Typography, getTypography } from '../Typography';
import { getElevation } from '../elevation';
import { AvatarCard } from '../avatar-card';
import { StateLayer } from '../../utils/common.styled';
import { usePortal } from '../../hooks/use-portal';

type SuggestOption = {
  id: string;
  title: string;
  subtitle: string;
  image: string;
};

type Props = {
  error?: Error;
  description?: string;
  label?: string;
  name?: string;
  defaultValue?: string[];
  disabled?: boolean;
  placeholder?: string;
  canPasteEmails?: boolean;
  options?: SuggestOption[];
  loadingOptions?: boolean;
  suggestOptions?: boolean;
  isChipInvalid?: (chip: string) => boolean;
  onUpdateChips?: (chips: string[]) => void;
  onInputChange?: (value: string) => void;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
`;

const ChipsContainer = styled.div`
  height: 96px;
  padding: 8px;
  outline: 1px solid
    ${({ theme }) => theme.tokens.color.alias.cm.border['border-default'].value};
  border-radius: 4px;
  outline-offset: -1px;

  :hover {
    cursor: text;
  }

  :focus-within {
    outline: 2px solid
      ${({ theme }) =>
        theme.tokens.color.alias.cm.border['border-default'].value};
    outline-offset: -2px;
  }

  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;

  gap: 4px;

  overflow-y: auto;
`;

const TextArea = styled.textarea<{ isEmpty: boolean }>`
  border: none;
  outline: none;
  resize: none;
  font-family: inherit;
  flex: 1;
  width: 100%;
  height: ${({ isEmpty }) => (isEmpty ? '100%' : 'auto')};

  ${({ theme }) => getTypography(theme, 'Body 2')}
  color: ${({ theme }) => theme.tokens.color.alias.cm.text.text.value};
`;

const ErrorMessage = styled(Typography.Body2)`
  color: ${({ theme }) => theme.tokens.color.alias.cm.icon['icon-error'].value};
`;

const Label = styled(Typography.Subtitle2)``;

const Options = styled.div`
  overflow-y: auto;
  max-height: 300px;
  border-radius: 4px;
  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.surface['surface-primary'].value};
  display: flex;
  flex-direction: column;
  gap: 4px;
  ${({ theme }) => getElevation(theme, 'elevation 2')};
`;

const NoOptions = styled.div`
  padding: 10px;
`;

const Item = styled.div`
  padding: 8px 16px;
  cursor: pointer;
  position: relative;

  :hover .itemStateLayer {
    background-color: ${({ theme }) =>
      theme.tokens.color.specific['state-layer']['state-hover-darker'].value};
  }
`;

export function InputChips({
  error,
  label,
  name,
  description,
  defaultValue = [],
  placeholder,
  disabled,
  canPasteEmails,
  options,
  loadingOptions,
  suggestOptions = false,
  isChipInvalid,
  onUpdateChips,
  onInputChange,
}: Props): JSX.Element {
  const [chips, setChips] = useState<string[]>(defaultValue);
  const [value, setValue] = useState<string>('');
  const [focused, setFocused] = useState(false);
  const optionsRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const handleDelete = (selectedChip: string): void => {
    if (disabled) return;
    setChips((prevChips) => prevChips.filter((chip) => chip !== selectedChip));
  };

  const handleInputChange = (e: React.FormEvent<HTMLTextAreaElement>): void => {
    if (disabled) return;
    setValue(e.currentTarget.value);

    if (!suggestOptions) return;
    onInputChange?.(e.currentTarget.value);
    if (!focused) setFocused(true);
  };

  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLTextAreaElement>
  ): boolean => {
    if (disabled) return false;

    if (event.key === 'Enter' && value.trim() !== '') {
      setChips((prevChips) => [...prevChips, value.trim()]);
      setValue('');
      event.preventDefault();
    }
    return false;
  };

  useKeyPress({
    Escape: () => setFocused(false),
  });

  useEffect(() => {
    onUpdateChips?.(chips);
    setFocused(false);
  }, [chips, onUpdateChips]);

  const handlePaste = (e: React.ClipboardEvent): void => {
    if (!canPasteEmails) return;
    e.preventDefault();
    const text = e.clipboardData.getData('Text');
    const mails = text.match(
      /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi
    ) as string[];
    if (Array.isArray(mails)) {
      const newEmails = Array.from(new Set([...chips, ...mails]));
      setChips(newEmails);
    }
  };

  const handleFocus = (): void => {
    if (!suggestOptions) return;
    setFocused(true);
  };

  const handleBlur = (): void => {
    if (suggestOptions) return;
    if (value.trim() !== '') {
      setChips((prevChips) => [...prevChips, value.trim()]);
      setValue('');
    }
  };

  const handleOptionClick = (email: string): void => {
    if (chips.some((chip) => chip === email.trim())) return;
    setChips((prevChips) => [...prevChips, email.trim()]);
    inputRef.current?.focus();
  };

  useEventListener('mousedown', (event): void => {
    if (!suggestOptions) return;
    const isOptionClicked = optionsRef.current
      ? optionsRef.current.contains(event.target as HTMLDivElement)
      : false;
    setValue('');
    setFocused(isOptionClicked);
  });

  const { createPortal } = usePortal({
    containerRef,
  });

  const removeSelected = (option: SuggestOption): boolean =>
    !chips.includes(option.subtitle);

  return (
    <Container>
      {label && <Label>{label}</Label>}

      <ChipsContainer ref={containerRef}>
        {chips.map((chip) => (
          <MailChip
            onClose={() => handleDelete(chip)}
            text={chip}
            key={chip}
            isError={isChipInvalid ? isChipInvalid(chip) : false}
          />
        ))}
        <TextArea
          ref={inputRef}
          isEmpty={chips.length === 0}
          placeholder={chips.length ? '' : placeholder}
          rows={1}
          name={name}
          value={value}
          onFocus={handleFocus}
          onChange={handleInputChange}
          onPaste={handlePaste}
          onKeyPress={handleKeyPress}
          onBlur={handleBlur}
        />
      </ChipsContainer>

      {focused &&
        createPortal(
          <Options ref={optionsRef} role="listbox">
            {options
              ?.filter(removeSelected)
              ?.map(({ title, subtitle }, index) => (
                <Item
                  key={index}
                  role="option"
                  onClick={() => handleOptionClick(subtitle)}
                >
                  <StateLayer className="itemStateLayer" />
                  <AvatarCard>
                    <AvatarCard.Avatar text={title} />
                    <AvatarCard.Text subtitle={subtitle}>
                      {title}
                    </AvatarCard.Text>
                  </AvatarCard>
                </Item>
              ))}
            {loadingOptions && <NoOptions>Loading</NoOptions>}
            {!options?.length && !loadingOptions && (
              <NoOptions>No options</NoOptions>
            )}
          </Options>
        )}
      {!error && description && (
        <Typography.Body2>{description}</Typography.Body2>
      )}
      {error && <ErrorMessage role="alert">{error.message}</ErrorMessage>}
    </Container>
  );
}
