import * as React from 'react';
import type { SVGProps } from 'react';
const SvgRegister = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g clipPath="url(#Register_svg__a)">
      <path
        stroke="#757575"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.25}
        d="M6.898 16.818h-4.96A1.24 1.24 0 0 1 .7 15.578V1.943A1.24 1.24 0 0 1 1.94.702h12.397a1.24 1.24 0 0 1 1.24 1.24v5.579m-8.678-3.1h4.958m-7.438 3.72h7.438M4.418 11.86h6.199m8.166.512-6.304 6.306-3.099.62.62-3.1 6.307-6.305a1.747 1.747 0 0 1 2.472 0l.007.006a1.75 1.75 0 0 1-.003 2.473"
      />
    </g>
    <defs>
      <clipPath id="Register_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgRegister;
