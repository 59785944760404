import Icon from '../Icon';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

export const Action = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
  min-width: 40px;
  width: fit-content;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.dark['06']};
`;

const Wrapper = styled(Action)<{ isOpen: boolean }>`
  &:hover {
    background-color: ${({ theme }) => theme.colors.dark['05']};
  }
  svg path {
    fill: ${({ theme }) => theme.colors.dark['02']};
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(180deg);
    `};
`;

type Props = {
  isOpen: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export default function ArrowActionButton({ isOpen, ...rest }: Props) {
  return (
    <Wrapper {...rest} isOpen={isOpen}>
      <Icon icon="ArrowDownMedium" />
    </Wrapper>
  );
}
