
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as NotDoneClassIconComponent } from "./not-done-class-icon.svg";

export const NotDoneClassIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <NotDoneClassIconComponent {...props} />;
};
