
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as ManipulativeEnvironmentIconComponent } from "./manipulative-environment-icon.svg";

export const ManipulativeEnvironmentIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <ManipulativeEnvironmentIconComponent {...props} />;
};
