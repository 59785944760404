import * as React from 'react';
import type { SVGProps } from 'react';
const SvgTimeStopwatch = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="m14.982 6.268 1.58-1.58M16.25 4.375l.625.625M10 4.375V.625M11.875.625h-3.75M2.5 11.875a7.5 7.5 0 1 0 15 0 7.5 7.5 0 1 0-15 0M10 6.875"
      />
      <path
        strokeWidth={1.249995}
        d="M10 6.875a.313.313 0 1 0 .313.313.313.313 0 0 0-.313-.313M10 16.25a.313.313 0 1 0 .313.313.313.313 0 0 0-.313-.313M5.313 11.563a.313.313 0 1 0 .312.312.313.313 0 0 0-.312-.312M14.688 11.563a.313.313 0 1 0 .312.312.313.313 0 0 0-.312-.312M6.686 8.25a.313.313 0 0 0-.222.533.313.313 0 1 0 .443-.441.3.3 0 0 0-.221-.092M13.314 14.878a.3.3 0 0 0-.22.091.313.313 0 1 0 .22-.091M6.686 14.878a.313.313 0 0 0-.222.533.313.313 0 1 0 .443-.442.3.3 0 0 0-.221-.091M13.536 8.34a.31.31 0 0 0-.222-.092h0a.3.3 0 0 0-.22.092.313.313 0 1 0 .442 0"
      />
    </g>
  </svg>
);
export default SvgTimeStopwatch;
