import styled from '@emotion/styled';

import { getRealStepValue } from '../../../state/selectors';
import usePlayerState from '../../../providers/PlayerProviders/usePlayerState';
import useResourceNavigation from '../../../hooks/useResourceNavigation';
import { IconButton } from '@innovamat/glimmer-components';
import { css } from '@emotion/react';

const PaginationWrapper = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spaces.medium};
  align-items: center;
`;

const StyledCounter = styled.span<{ isInFullscreen: boolean }>`
  color: ${(props) =>
    props.isInFullscreen
      ? props.theme.colors.white
      : props.theme.colors.dark['02']};
  font-size: ${(props) => props.theme.sizes.small};
`;

const StyledIconButton = styled(IconButton)<{
  isInFullscreen: boolean;
}>`
  ${({ isInFullscreen, state, theme }) =>
    state === 'disabled'
      ? css`
          svg,
          path {
            fill: ${isInFullscreen ? theme.colors.dark['01'] : undefined};
          }
        `
      : css`
          svg,
          path {
            fill: ${isInFullscreen ? 'white' : undefined};
          }

          :hover .icon-stateLayer {
            background-color: ${isInFullscreen &&
            theme.tokens.color.specific['state-layer']['state-hover-lighter']
              .value};
          }

          :active .icon-stateLayer {
            background-color: ${isInFullscreen &&
            theme.tokens.color.specific['state-layer']['state-press-lighter']
              .value};
          }
        `}
`;

export default function Pagination() {
  const { translations, fullscreen, fullscreenIOS } = usePlayerState();
  const { handleNext, handlePrev } = useResourceNavigation();
  const isInFullscreen = fullscreen?.active || fullscreenIOS;

  const { totalSteps, currentResource, currentStep, resource } =
    usePlayerState();

  const realStepValue = getRealStepValue(
    currentResource,
    currentStep,
    resource
  );

  const getLabel = () => `${realStepValue} / ${totalSteps}`;

  const prevBtnDisabled = realStepValue === 1;
  const nextBtnDisabled = realStepValue === totalSteps;

  return (
    <PaginationWrapper>
      <StyledIconButton
        tooltipText={translations['pagination.player.tooltip.previous']}
        isInFullscreen={isInFullscreen}
        size="M"
        icon="PreviousIcon"
        state={prevBtnDisabled ? 'disabled' : undefined}
        onClick={!prevBtnDisabled ? handlePrev : undefined}
        dataTestId="prevResourceButton"
      />

      <StyledCounter isInFullscreen={isInFullscreen}>
        {getLabel()}
      </StyledCounter>

      <StyledIconButton
        tooltipText={translations['pagination.player.tooltip.next']}
        isInFullscreen={isInFullscreen}
        size="M"
        icon="NextIcon"
        state={nextBtnDisabled ? 'disabled' : undefined}
        onClick={!nextBtnDisabled ? handleNext : undefined}
        dataTestId="nextResourceButton"
      />
    </PaginationWrapper>
  );
}
