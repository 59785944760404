import React, { useState } from 'react';

import styled from '@emotion/styled';
import { css } from '@emotion/react';

import PillStep from './PillStep';
import PillContent from './PillContent';
import { Icons } from '@innovamat/innova-components';
import { Group } from '../../types/group';

type PillContainerProps = {
  onClick?: (order: number) => void;
  text?: string;
  icon?: keyof typeof Icons;
  fragments: number[];
  stepSelected: (index: number) => boolean;
  dataTestId?: string;
  index: number;
} & PillProps;

type PillProps = {
  id: string;
  selected: boolean;
  group: Group;
  canClickStep?: boolean;
  isInFullscreen: boolean;
};

type StyledPillProps = {
  selected: boolean;
  group: Group;
  canClickStep?: boolean;
  isInFullscreen: boolean;
};

const PillContainer = styled.div`
  position: relative;
  width: 100%;
  cursor: pointer;
  height: 32px;
  display: flex;
  align-items: flex-end;
`;

const StyledPill = styled.div<StyledPillProps>`
  width: 100%;
  height: ${(props) => (props.selected ? '16px' : '8px')};
  display: flex;
  margin-bottom: ${(props) => (props.selected ? '' : '4px')};

  > div {
    ${({ isInFullscreen, theme }) =>
      isInFullscreen
        ? css`
            border-right: 0.5px solid ${theme.colors.black};
            border-left: 0.5px solid ${theme.colors.black};
          `
        : css`
            border-right: 0.5px solid ${theme.colors.white};
            border-left: 0.5px solid ${theme.colors.white};
          `}

    &:first-of-type {
      border-radius: 4px 0 0 4px;
      border-left: 0px;
    }
    &:last-child {
      border-radius: 0 4px 4px 0;
      border-right: 0px;
    }
    &:only-child {
      border-radius: 4px;
      border-right: 0px;
      border-left: 0px;
    }
  }
`;

const copyStepId = (stepId: string) => {
  const stepID = stepId;

  const tempInput = document.createElement('input');
  tempInput.value = stepID;
  document.body.appendChild(tempInput);

  tempInput.select();
  document.execCommand('copy');

  document.body.removeChild(tempInput);
};

function Pill({
  onClick,
  text,
  icon,
  selected,
  stepSelected,
  group,
  fragments,
  canClickStep,
  isInFullscreen,
  id,
  dataTestId,
  index,
}: PillContainerProps) {
  const width = 100 / fragments.length;
  const [pillContentHover, setPillContentHover] = useState(false);

  const handleOnClick = (
    order: number,
    stepId: string,
    event: React.MouseEvent<HTMLInputElement>
  ) => {
    onClick?.(canClickStep ? order : 0);
    if (event.metaKey || event.ctrlKey) {
      copyStepId(stepId);
    }
  };

  return (
    <PillContainer data-testid={dataTestId}>
      <StyledPill
        id={`pill-${index}`}
        isInFullscreen={isInFullscreen}
        selected={selected}
        group={group}
        canClickStep={canClickStep}
      >
        {fragments.map((order) => {
          const stepId = `${id}-${order}`;
          return (
            <PillStep
              id={stepId}
              key={order}
              onClick={(event) => handleOnClick(order, stepId, event)}
              group={group}
              size={width}
              selected={stepSelected(order)}
              setPillContentHover={setPillContentHover}
            />
          );
        })}
      </StyledPill>
      <PillContent
        group={group}
        selected={selected}
        icon={icon as any}
        text={text}
        pillContentHover={pillContentHover}
      />
    </PillContainer>
  );
}

export default Pill;
