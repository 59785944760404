import { ThemeProvider as EmotionThemeProvider, Global } from '@emotion/react';

import { theme } from './theme';
import { grid } from './grid';
import { GlobalStyles } from './GlobalStyles';
import lightTokens from '../theme/tokens/theme/light.json';
// import darkTokens from '../theme/tokens/theme/dark.json';

type ThemeProviderProps = {
  children: JSX.Element | JSX.Element[];
  type: 'dark' | 'light';
};

export function ThemeProvider({
  children,
  type = 'light',
}: ThemeProviderProps) {
  const tokens = type === 'light' ? lightTokens : lightTokens; //TODO: darkTokens
  const mixedTheme = { ...theme, grid, tokens };

  return (
    <EmotionThemeProvider theme={mixedTheme}>
      <Global styles={GlobalStyles} />
      {children}
    </EmotionThemeProvider>
  );
}
