import styled from '@emotion/styled';
import React from 'react';

type Props = {
  text?: string;
  className?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const Wrapper = styled.div`
  p {
    margin: 0;
  }
`;

function InnerHtml({ text, className, ...rest }: Props) {
  return text ? (
    <Wrapper
      className={className}
      dangerouslySetInnerHTML={{ __html: text }}
      {...rest}
    />
  ) : null;
}

export default InnerHtml;
