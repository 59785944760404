import styled from '@emotion/styled';
import { theme } from '@innovamat/glimmer-components';
import BlockContent from '../BlockContent';
import BlockWithIcon from '../BlockWithIcon';
import { PrepElement } from '../../types/prepr';
import { PrintableSubtitle1 } from '../../../printable-styles/printable-typography';
import { useDigitalGuides } from '../../context';

type Props = {
  motto: PrepElement;
};

const Wrapper = styled.div`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.brand['04']};
  padding: 16px;
  .blockContentTitle {
    color: ${({ theme }) => theme.colors.corp};
  }
  color: ${({ theme }) => theme.colors.brand.dark['01']};
  font-family: ${({ theme }) => theme.fonts.robotoMedium};

  @media print {
    padding: 12px;
    break-inside: avoid;
  }
`;

export default function ChallengesBlockMotto({ motto }: Props) {
  const { t } = useDigitalGuides();

  if (!motto) return null;

  return (
    <BlockWithIcon icon="Chat" color={theme.colors.brand.dark['02']}>
      <Wrapper>
        <BlockContent title={t('digitalguides.ey.instruction')}>
          <PrintableSubtitle1>{motto.body}</PrintableSubtitle1>
        </BlockContent>
      </Wrapper>
    </BlockWithIcon>
  );
}
