
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as CbSpaceFormIconComponent } from "./cb-space-form-icon.svg";

export const CbSpaceFormIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <CbSpaceFormIconComponent {...props} />;
};
