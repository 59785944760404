import styled from '@emotion/styled';

import { PrintableSubtitle1 } from '../../printable-styles/printable-typography';
import { Text } from '../components/Text';

type Props = {
  title?: string;
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export default function Block({ title, children, id, ...rest }: Props) {
  return (
    <Wrapper id={id} {...rest}>
      {title && (
        <PrintableSubtitle1 id={`${id}-title`}>{title}</PrintableSubtitle1>
      )}
      <Text>{children}</Text>
    </Wrapper>
  );
}
