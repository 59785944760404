import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLand = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      clipPath="url(#land_svg__a)"
    >
      <path d="m3.866 1.566 1.91.41a.6.6 0 0 1 .484.547l.11 2.373 7.832.503a4.414 4.414 0 0 1 4.376 5.137 1.2 1.2 0 0 1-1.441.897l-5.218-1.119-2.25 2.152a.65.65 0 0 1-.588.168l-2.139-.452a.656.656 0 0 1-.362-1.064l1.39-1.645-4.943-1.057a1.18 1.18 0 0 1-.946-1.373l1.071-5.044a.6.6 0 0 1 .714-.433M19 17H1" />
    </g>
    <defs>
      <clipPath id="land_svg__a">
        <path fill="#fff" d="M20 0H0v20h20z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgLand;
