import React from 'react';

import {
  StepperWrapper,
  Step,
  Circle,
  CustomCheck,
  StepContainer,
} from './Stepper.styles';
import { StepperProps } from './Stepper.types';

function Stepper({
  steps,
  infinite,
  active,
  onActive,
  checkedArray,
  ...rest
}: StepperProps) {
  const handleClick = (event: any, index: number) => {
    event.stopPropagation();
    onActive && onActive(index);
  };

  return (
    <StepperWrapper {...rest}>
      {[...Array(steps)].map((_, index) => (
        <StepContainer
          key={index}
          onClick={(event: any) => handleClick(event, index)}
        >
          <Step checked={checkedArray[index]}>
            {checkedArray[index] && <CustomCheck />}
          </Step>
        </StepContainer>
      ))}
      <Circle active={active as number} />
    </StepperWrapper>
  );
}

export default Stepper;
