
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as CulturalReferenceIconComponent } from "./cultural-reference-icon.svg";

export const CulturalReferenceIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <CulturalReferenceIconComponent {...props} />;
};
