import styled from '@emotion/styled';

const Container = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Title = styled('h5')`
  color: ${({ theme }) => theme.colors.dark['03']};
  line-height: ${({ theme }) => theme.typography.lineHeight.medium};
  padding-bottom: 0.594rem;
  &:nth-of-type(1) {
    margin-top: 1rem !important;
  }
`;

const List = styled('ul')`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const MaterialItem = styled('li')<{ isPositive: boolean }>`
  display: grid;
  grid-template-columns: 28% 47% 13% 8%;
  text-align: left;
  font-weight: ${({ theme }) => theme.typography.weight.regular};
  font-size: ${({ theme }) => theme.typography.typeScale.xsmall};
  line-height: ${({ theme }) => theme.typography.lineHeight.small};
  padding-bottom: 0.11rem;
  padding-top: 0.11rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark['05']};
  margin-right: 1.5rem;

  .materialName {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
  span {
    padding-right: 0.54rem;
    align-self: center;
  }
  .totalOperationQuantity {
    color: ${({ isPositive, theme }) =>
      isPositive ? theme.colors.info : theme.colors.warning};
    span {
      padding-right: 3px;
    }
  }
`;

export { Container, Title, List, MaterialItem };
