import { useWebviewBridge } from '@innovamat/webview-bridge';

import { Typography, theme, GridRow } from '@innovamat/glimmer-components';

import { Trans, useTranslation } from 'react-i18next';
import { StyledCol, StyledGridContainer } from '@innovamat/smart-guides';
import {
  Applet,
  useAppletsBySessionCodeQuery,
  useCompletedResources,
} from '@innovamat/glow-api-client';

import { createContentListByApplets } from './utils';
import styled from '@emotion/styled';
import { ContentList, ContentListSkeleton } from '../content-list';
import { useUser } from '../../../user-management';
import { useCurrentNavigationValues, useNavigation } from '../../../navigation';
import { useMarkSessionDone } from '../../../session-done';
import { stages } from '@innovamat/radiance-utils';

type UnblockedAppletsListProps = {
  appletsCode: string;
  resourceId: string;
};

const UnblockedAppletsTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const UnblockedAppletsHeading = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 64px;
  margin-bottom: 12px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-bottom: 4px;
  }
`;

const StyledRowLoader = styled.div`
  margin-top: 64px;
`;

const INNOVAMAT_BLOG_URL =
  'https://blog.innovamat.com/practica-digital-matematiques-app-innovamat/';

export function UnblockedAppletsList({
  appletsCode,
  resourceId,
}: UnblockedAppletsListProps): JSX.Element | null {
  const { t } = useTranslation();
  const { user, isRole } = useUser();
  const { locale, organization } = user!;
  const { currentCourse, currentClassroom } = useCurrentNavigationValues();
  const { isInWebview } = useWebviewBridge();
  const { onMarkSession, onToggleSolutions } = useMarkSessionDone();
  const { goToResource } = useNavigation();

  const classroomId = currentClassroom?.id;

  const completedResources = useCompletedResources({
    classroomId,
    academicYearId: organization?.academicYearId,
    isStudent: isRole.Student,
  });

  const completedValues = completedResources!.find(
    (completed) => completed.resourceId === resourceId
  );

  const isSessionDone = !!completedValues;

  const { data, isPending } = useAppletsBySessionCodeQuery(
    {
      classroomId: classroomId!,
      locale: locale || '',
      courseId: currentClassroom?.courseId || currentCourse?.id || '',
      courseOrder: currentClassroom?.courseOrder || currentCourse?.order || 0,
      sessionCode: appletsCode,
    },
    {
      enabled: !!appletsCode && !!classroomId,
    }
  );

  const handleClick = (appletId: string): void => {
    if (isInWebview) return;
    goToResource({
      type: 'applet',
      resourceId: appletId,
      openInNewTab: true,
    });
  };

  if (!appletsCode || !classroomId) return null;

  if (isPending)
    return (
      <StyledGridContainer>
        <StyledRowLoader>
          <ContentListSkeleton />
        </StyledRowLoader>
      </StyledGridContainer>
    );

  const applets = data?.appletsBySessionCode as Applet[];

  if (!applets || applets?.length === 0) return null;

  const appletsList = createContentListByApplets(applets, isSessionDone);

  return (
    <StyledGridContainer>
      <GridRow>
        <StyledCol sm={7}>
          <UnblockedAppletsHeading>
            <UnblockedAppletsTitle>
              <Typography.H4>
                {stages.isPrimaria(currentClassroom?.courseOrder)
                  ? t('session.unblocked_applets.pe.title')
                  : t('session.unblocked_applets.title')}
              </Typography.H4>
            </UnblockedAppletsTitle>
            <Typography.Body2
              color={theme.tokens.color.alias.cm.text['text-subtle'].value}
            >
              <Trans
                i18nKey={
                  stages.isPrimaria(currentClassroom?.courseOrder)
                    ? t('session.unblocked_applets.pe.description')
                    : t('session.unblocked_applets.description')
                }
                components={{
                  linktoblog: (
                    <Typography.Link2
                      href={INNOVAMAT_BLOG_URL}
                      target="_blank"
                      rel="noreferrer"
                      color={theme.tokens.color.specific.element.link.value}
                    />
                  ),
                }}
              />
            </Typography.Body2>
          </UnblockedAppletsHeading>
        </StyledCol>
      </GridRow>

      <GridRow>
        <StyledCol>
          <ContentList
            list={appletsList}
            onResourceClick={(resource) => handleClick(resource.id)}
            onMarkSession={onMarkSession}
            onToggleSolutions={onToggleSolutions}
          />
        </StyledCol>
      </GridRow>
    </StyledGridContainer>
  );
}
