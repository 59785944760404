import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.bgSpinner};
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 60;
`;

export const LoaderBox = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100%;
`;

export const Loader = styled.div`
  margin-left: -16%;
  max-width: 100%;

  svg {
    height: auto;
    max-width: 100%;
    width: 500px;
  }

  @media (max-width: 520px) {
    margin: 40px;
    margin-left: -30%;
  }
`;
