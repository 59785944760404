import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Image from '../Image';
import { withBreakpoint } from '../../utils/withBreakpoint';
import { theme } from '@innovamat/glimmer-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: sticky;
  top: calc(var(--heightHeader) + 32px);
  right: 0;
  z-index: 1;
  img {
    object-fit: cover !important;
  }
`;

const BlockImage = styled(Image)`
  position: relative;
  width: 100%;
  object-fit: cover;
  background-color: ${theme.colors.white};
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme.colors.white};
  justify-content: space-between;
  font-size: ${theme.sizes.small};
  user-select: none;
  span {
    ${() =>
      withBreakpoint(
        css`
          font-size: ${theme.sizes.xsmall};
        `
      )}
  }
  span > span {
    font-family: ${theme.typography.medium};
  }
`;

const Arrows = styled.div<{ stroke?: string }>`
  display: flex;
  gap: 8px;

  svg path {
    stroke: ${theme.colors.white};
  }
`;

const Arrow = styled.div<{ isDisabled: boolean }>`
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'all')};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.3 : 1)};
`;

export { Arrow, Arrows, Footer, BlockImage, Wrapper };
