import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLogout = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M6.25 10.003h13.125M16.25 13.128l3.125-3.125-3.125-3.125M13.125 13.75v3.75a1.2 1.2 0 0 1-1.137 1.25H1.761A1.2 1.2 0 0 1 .625 17.5v-15a1.197 1.197 0 0 1 1.136-1.25h10.227a1.2 1.2 0 0 1 1.137 1.25v3.75"
      />
    </g>
  </svg>
);
export default SvgLogout;
