
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as Part_4in1GuidesIconComponent } from "./part-4in1-guides-icon.svg";

export const Part_4in1GuidesIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <Part_4in1GuidesIconComponent {...props} />;
};
