import BuildWrapper from '../components/BuildWrapper';
import Login from '../components/Login';
import StageSelector from '../components/StageSelector';
import useAppSelector from '../hooks/useAppSelector';
import useAuthentication from '../hooks/useAuthentication';
import { storage, roles, initDatadog } from '@innovamat/radiance-utils';
import { Routes, Route, Navigate } from 'react-router-domv6';
import { getLoggedIn, getUser } from '../store/auth/selectors';
import init from '../utils/init';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useAppHeight from '../hooks/useAppHeight';
import ErrorPage from '../components/ErrorPage';
import { consoleVersion } from '../utils/consoleVersion';
import { useRegionalConfigVariables } from '@innovamat/regional-config-variables';

const classroomManager = process.env.NX_CLASSROOM_MANAGER;

export default function AppRouter(): JSX.Element {
  const { apiUsers } = useRegionalConfigVariables();
  init(apiUsers);
  useAuthentication();
  useAppHeight();

  const isLoggedIn = useAppSelector(getLoggedIn);
  const user = useAppSelector(getUser);
  const isStudent = roles.hasStudentRole(user?.roles);
  const { i18n } = useTranslation();

  useEffect(() => {
    consoleVersion();
  }, []);

  useEffect(() => {
    if (isStudent) {
      storage.clear();
      window.location.href = classroomManager || 'https://app.innovamat.com';
    }
  }, [isStudent]);

  useEffect(() => {
    if (isLoggedIn && user) {
      i18n.changeLanguage(user.language);

      // Init Datadog RUM Monitoring
      const sampleRates = {
        sessionSampleRate: 5,
        sessionReplaySampleRate: 5,
      };
      const envVariables = {
        DATADOG_APPLICATION_ID: process.env.NX_DATADOG_WEBAPP_APPLICATION_ID,
        DATADOG_CLIENT_TOKEN: process.env.NX_DATADOG_WEBAPP_CLIENT_TOKEN,
        DATADOG_ENVIRONMENT: process.env.NX_DATADOG_WEBAPP_ENVIRONMENT,
        DATADOG_SERVICE: process.env.NX_DATADOG_WEBAPP_SERVICE,
        COMMIT_ID: process.env.NX_WEBAPP_COMMIT_ID,
        CONFIG_CAT_KEY: process.env.NX_CONFIG_CAT_KEY,
      };
      initDatadog(envVariables, sampleRates, {
        uid: user.uid,
        school: user.school,
      });
    }
  }, [isLoggedIn, user]);

  document.addEventListener(
    'wheel',
    function touchHandler(e) {
      if (e.ctrlKey) {
        e.preventDefault();
      }
    },
    { passive: false }
  );

  // TODO refactir with proper auth components
  return (
    <Routes>
      <Route path="/" element={!isLoggedIn ? <Login /> : <StageSelector />} />
      {isLoggedIn && <Route path="/home" element={<BuildWrapper />} />}
      {isLoggedIn && <Route path="/error" element={<ErrorPage />} />}
      <Route path="/*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}
