import * as React from 'react';
import type { SVGProps } from 'react';
const SvgEditStudent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M18.858 12.39 12.5 18.75l-3.125.625L10 16.25l6.358-6.36a1.763 1.763 0 0 1 2.494 0l.006.008a1.76 1.76 0 0 1 0 2.493M2.813 4.063a3.437 3.437 0 1 0 6.874 0 3.437 3.437 0 0 0-6.874 0M11.115 11.6a5.607 5.607 0 0 0-10.49 2.775"
    />
  </svg>
);
export default SvgEditStudent;
