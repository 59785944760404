import { useMutation } from '@tanstack/react-query';
import { LoginHookProps, LoginResponse } from '../types/login';
import { fromExternalLoginRequest } from '../utils/api';
import { AuthError } from '../utils/AuthError';
import {
  useGoogleLogin as useLoginFromGoogle,
  CodeResponse,
  CredentialResponse,
} from '@react-oauth/google';
import axios from 'axios';
import { useEffect } from 'react';

const CONTROLED_ERRORS = [
  'popup_closed_by_user',
  'idpiframe_initialization_failed',
];

export function useGoogleLogin({
  dispatch,
  credentials,
  usingRedirect,
}: LoginHookProps) {
  const { mutate } = useMutation<LoginResponse, AuthError, string>({
    mutationFn: (token) =>
      fromExternalLoginRequest(token, 'google_token', credentials),
  });

  const handleGoogleFailure = (error: any) => {
    if (!CONTROLED_ERRORS.includes(error.error)) {
      dispatch({ type: 'SET_ERROR', payload: error });
      handleSetLoading(false);
    }
  };

  const getToken = async (response: CodeResponse): Promise<string> => {
    const redirectUri = `${window.location.origin}`;
    const { googleSecretId, googleClientId } = credentials;

    const res = await axios.post(
      `https://oauth2.googleapis.com/token?client_id=${encodeURIComponent(
        googleClientId
      )}&client_secret=${encodeURIComponent(
        googleSecretId || ''
      )}&code=${encodeURIComponent(
        response.code
      )}&grant_type=authorization_code&redirect_uri=${encodeURIComponent(
        redirectUri
      )}`
    );

    return res.data.id_token;
  };

  const handleLoginToUsers = async (tokenId: string) => {
    mutate(tokenId, {
      onSuccess(data) {
        dispatch({
          type: 'SET_LOGIN_INFO',
          payload: {
            response: data,
            loggedFrom: { type: 'google' },
          },
        });
        handleSetLoading(false);
      },
      onError(error) {
        dispatch({ type: 'SET_ERROR', payload: error });
        handleSetLoading(false);
      },
    });
  };

  const handleGoogleSuccess = async (response: CodeResponse) => {
    try {
      const tokenId = await getToken(response);
      handleSetLoading(true);
      handleLoginToUsers(tokenId);
    } catch (error: any) {
      dispatch({ type: 'SET_ERROR', payload: error });
      handleSetLoading(false);
    }
  };

  const signIn = useLoginFromGoogle({
    onSuccess: handleGoogleSuccess,
    onError: handleGoogleFailure,
    flow: 'auth-code',
    ux_mode: usingRedirect ? 'redirect' : 'popup',
    redirect_uri: usingRedirect ? `${window.location.origin}` : undefined,
    state: usingRedirect
      ? (() => {
          // Get all current URL parameters
          const urlParams = new URLSearchParams(window.location.search);

          // Convert the URL parameters to an object
          const paramsObject: Record<string, string> = {};
          urlParams.forEach((value, key) => {
            paramsObject[key] = value;
          });

          // Encode the object into a JSON string and then URI encode it
          return encodeURIComponent(JSON.stringify(paramsObject));
        })()
      : undefined,
  });

  const handleSetLoading = (loading: boolean) => {
    dispatch({
      type: 'SET_IS_LOADING',
      payload: {
        loading,
        key: { type: 'google' },
      },
    });
  };

  const handleSignIn = () => {
    if (usingRedirect) {
      // Save isPressed to true in local storage for when using redirect
      localStorage.setItem('isButtonPressedGoogle', 'true');
    }

    // Start the Google sign-in process
    signIn();
  };

  const handleGoogleAutologinFailure = () => {
    dispatch({
      type: 'SET_ERROR',
      payload: {
        detail: '',
        status: 401,
        title: '',
        type: '',
      },
    });
    handleSetLoading(false);
  };

  const hasCode = new URL(window.location.href).searchParams.has('code');
  const isButtonPressed =
    localStorage.getItem('isButtonPressedGoogle') === 'true';

  useEffect(() => {
    if (isButtonPressed) {
      const urlParams = new URL(window.location.href).searchParams;

      // Retrieve the state parameter
      const state = urlParams.get('state');
      if (state) {
        const restoredParams = state
          ? JSON.parse(decodeURIComponent(state))
          : {};

        // Add the original `code` and `scope` parameters to the restoredParams
        restoredParams['code'] = urlParams.get('code') || '';
        restoredParams['scope'] = urlParams.get('scope') || '';

        // Construct the new URL with the combined parameters
        const newUrlParams = new URLSearchParams(restoredParams);
        const newUrl = `${window.location.origin}${
          window.location.pathname
        }?${newUrlParams.toString()}`;

        // Replace the current URL with the new one
        window.location.href = newUrl;
      } else if (hasCode) {
        localStorage.setItem('isButtonPressedGoogle', 'false');

        // Continue with the OAuth process
        handleGoogleSuccess({
          code: urlParams.get('code') || '',
          scope: urlParams.get('scope') || '',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasCode, isButtonPressed]);

  const handleGoogleAutologinSuccess = (response: CredentialResponse) => {
    handleSetLoading(true);
    if (!response.credential) return;
    handleLoginToUsers(response.credential);
  };

  return {
    onGoogleSignIn: handleSignIn,
    onGoogleAutologinFailure: handleGoogleAutologinFailure,
    onGoogleAutologinSuccess: handleGoogleAutologinSuccess,
  };
}
