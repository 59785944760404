import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCube = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M13.858 19.375a1.25 1.25 0 0 0 .883-.366l4.268-4.268a1.25 1.25 0 0 0 .366-.884V1.875a1.25 1.25 0 0 0-1.25-1.25H6.143a1.25 1.25 0 0 0-.884.366L.991 5.259a1.25 1.25 0 0 0-.366.884v11.982a1.25 1.25 0 0 0 1.25 1.25ZM14.375 19.263V5.624M5.625 14.375h13.638M.99 19.01l4.635-4.635M14.375 5.625 19.009.991M14.375 5.625H.738M5.625.738v13.637"
      />
    </g>
  </svg>
);
export default SvgCube;
