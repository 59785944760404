import React, { createContext, useCallback, useContext, useState } from 'react';
import { Checkbox } from '../Checkbox';
import { Avatar, AvatarProps, Size } from '../avatar';
import {
  AvatarCardWrapper,
  Content,
  Description,
  StyledTooltip,
  ContentWithIcon,
} from './avatar-card.styled';
import { AvatarStage, AvatarStageProps } from '../avatar-stage';
import { SemanticType } from '../../hooks/useSemantic';
import { Typography } from '../Typography';
import { useCheckOverflow } from '../../hooks/use-check-overflow';
import { IconType } from '@innovamat/glimmer-icons';
import { Icon } from '../Icon';

interface AvatarCardContextType {
  semantic?: SemanticType;
  size?: Size;
}

const AvatarCardContext = createContext<AvatarCardContextType>({});

type AvatarCardProps = {
  children: React.ReactNode;
  semantic?: SemanticType;
  size?: Size;
} & React.HTMLAttributes<HTMLDivElement>;

export type AvatarCardStatus = 'default' | 'selected' | 'disabled';

export function AvatarCard({
  children,
  semantic,
  size = 'L',
  ...rest
}: AvatarCardProps): JSX.Element {
  return (
    <AvatarCardContext.Provider value={{ semantic, size }}>
      <AvatarCardWrapper {...rest}>{children}</AvatarCardWrapper>
    </AvatarCardContext.Provider>
  );
}

type TextProps = {
  children: React.ReactNode;
  subtitle?: JSX.Element | string;
  variant?: 'h4';
  rightElement?: JSX.Element;
} & React.HTMLAttributes<HTMLDivElement>;

const AvatarCardText = ({
  children,
  subtitle,
  variant,
  rightElement,
  ...rest
}: TextProps): JSX.Element => {
  const { size } = useContext(AvatarCardContext);

  const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);
  const onSetContainerRef = useCallback((ref: HTMLElement | null) => {
    setContainerRef(ref);
  }, []);

  const { hasOverflow: showTooltip } = useCheckOverflow({
    text: containerRef?.children[0],
    container: containerRef?.children[0],
    dependencies: [onSetContainerRef],
  });

  const getTitle = (): JSX.Element => {
    if (variant === 'h4') {
      return <Typography.H4 {...rest}>{children}</Typography.H4>;
    }

    if (size === 'S') {
      return <Typography.Subtitle2 {...rest}>{children}</Typography.Subtitle2>;
    }

    return <Typography.Subtitle1 {...rest}>{children}</Typography.Subtitle1>;
  };

  return (
    <StyledTooltip
      content={showTooltip ? children : undefined}
      popperOptions={{ strategy: 'fixed' }}
    >
      <ContentWithIcon>
        <Content ref={onSetContainerRef}>
          {getTitle()}

          {subtitle && size !== 'S' && <Description>{subtitle}</Description>}
        </Content>
        {rightElement}
      </ContentWithIcon>
    </StyledTooltip>
  );
};

const AvatarCardAvatar = (props: AvatarProps): JSX.Element => {
  const { semantic, size } = useContext(AvatarCardContext);
  return <Avatar {...props} semantic={semantic} size={size} />;
};

const AvatarCardStageAvatar = (props: AvatarStageProps): JSX.Element => {
  const { semantic, size } = useContext(AvatarCardContext);
  return <AvatarStage {...props} semantic={semantic} size={size} />;
};

AvatarCard.Checkbox = Checkbox;
AvatarCard.Avatar = AvatarCardAvatar;
AvatarCard.Text = AvatarCardText;

AvatarCard.AvatarStage = AvatarCardStageAvatar;
