import styled from '@emotion/styled';
import {
  Button,
  Checkbox,
  Modal,
  Typography,
} from '@innovamat/glimmer-components';
import { useLocalStorage } from '@innovamat/hooks';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
};

const Content = styled.div`
  ol {
    margin: 24px;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Label = styled(Typography.Body2)``;

function InviteParentsInfoModal({ showModal, setShowModal }: Props) {
  const { t } = useTranslation();

  const [checkValue, setCheckValue] = useState(false);

  const [persistedValue, setPersistedValue] = useLocalStorage(
    'parent-invitation-check',
    false
  );

  const handleCloseModal = () => {
    if (checkValue) {
      setPersistedValue(true);
    }
    setShowModal(false);
  };

  if (!showModal || persistedValue) {
    return null;
  }

  return (
    <Modal
      closeButton="inner"
      modalWidth={700}
      isOpen={showModal}
      onClose={handleCloseModal}
      title={t('students.inviteParentsInfoModal.title')}
    >
      <>
        <Content>
          <Trans
            i18nKey="students.inviteParentsInfoModal.content"
            components={{
              a: (
                <Typography.Link1
                  href={t('students.inviteParentsInfoModal.email')}
                  target="_blank"
                />
              ),
              ol: <ol />,
              li: <li />,
            }}
          />
        </Content>
        <Footer>
          <CheckboxContainer>
            <Checkbox
              checked={checkValue}
              onChange={() => setCheckValue(!checkValue)}
            />
            <Label>{t('students.inviteParentsInfoModal.checkbox')}</Label>
          </CheckboxContainer>
          <Button onClick={handleCloseModal}>
            {t('students.inviteParentsInfoModal.confirm')}
          </Button>
        </Footer>
      </>
    </Modal>
  );
}

export { InviteParentsInfoModal };
