import { SVGProps } from 'react';

export const EspFlag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 12"
    fill="none"
    {...props}
  >
    <rect y="0.375" width="18" height="11.25" rx="1.125" fill="#FFD066" />
    <rect y="1.49951" width="18" height="1.35" fill="#CC1F00" />
    <rect y="4.06798" width="18" height="1.35" fill="#CC1F00" />
    <rect y="6.63591" width="18" height="1.35" fill="#CC1F00" />
    <rect y="9.2033" width="18" height="1.35" fill="#CC1F00" />
    <rect y="0.375" width="18" height="11.25" rx="1.125" fill="#CC1F00" />
    <rect y="3.81005" width="18" height="4.37908" fill="#FFD066" />
  </svg>
);
