import { User } from '@innovamat/radiance-utils';
import { JSONValue } from '../root/root.interfaces';

export enum actions {
  SET_UNITY_DATA = 'SET_UNITY_DATA',
  UPDATE_UNITY_DATA = 'UPDATE_UNITY_DATA',
  SET_BRIDGE_IS_READY = 'SET_BRIDGE_IS_READY',
  GET_ACCOUNT_PLATFORM_DATA = 'GET_ACCOUNT_PLATFORM_DATA',
  SET_PLATFORM_URL = 'SET_PLATFORM_URL',
  SET_ACCOUNT_DATA = 'SET_ACCOUNT_DATA',
  ACTIVITY_IS_COMPLETED = 'ACTIVITY_IS_COMPLETED',
  SET_SELECTED_STAGE = 'SET_SELECTED_STAGE',
  GET_REGION_PARAMETERS = 'GET_REGION_PARAMETERS',
  SET_REGION_PARAMETERS = 'SET_REGION_PARAMETERS',
  INITIALIZE_UNITY_DATA = 'INITIALIZE_UNITY_DATA',
  SET_PREVIOUS_SCENE = 'SET_PREVIOUS_SCENE',
  UPDATE_COINS_GEMS = 'UPDATE_COINS_GEMS',
  SET_COINS_GEMS = 'SET_COINS_GEMS',
  SET_UNITY_TOKENS = 'SET_UNITY_TOKENS',
  SET_SOLVER_URL = 'SET_SOLVER_URL',
  SET_QUIZ_NEXT_ACTIVITY = 'SET_QUIZ_NEXT_ACTIVITY',
  SKIP_ACTIVITY = 'SKIP_ACTIVITY',
  SHOW_403_MODAL = 'SHOW_403_MODAL',
  HIDE_403_MODAL = 'HIDE_403_MODAL',
}

export const initializeUnityData = (): any => ({
  type: actions.INITIALIZE_UNITY_DATA,
});

export const actionSetUnityData = (payload: any): any => ({
  type: actions.SET_UNITY_DATA,
  payload,
});

export const actionUpdateUnityData = (payload: any): any => ({
  type: actions.UPDATE_UNITY_DATA,
  payload,
});

export const actionSetBridgeIsReady = (payload: boolean): any => ({
  type: actions.SET_BRIDGE_IS_READY,
  payload,
});

export const actionGetAccountPlatformData = (user: User): any => ({
  type: actions.GET_ACCOUNT_PLATFORM_DATA,
  user,
});

export const actionSetPlatformUrl = (platformUrl: string): any => ({
  type: actions.SET_PLATFORM_URL,
  platformUrl,
});

export const actionSetSolverUrl = (solverUrl: string): any => ({
  type: actions.SET_SOLVER_URL,
  solverUrl,
});

export const actionSetAccountData = (payload: unknown): any => ({
  type: actions.SET_ACCOUNT_DATA,
  payload,
});

export const actionActivityIsCompleted = (payload?: any): any => ({
  type: actions.ACTIVITY_IS_COMPLETED,
  payload,
});

export const actionSetSelectedStage = (payload: string): any => ({
  type: actions.SET_SELECTED_STAGE,
  payload,
});

export const actionGetRegionParameters = (user: User, stage: string): any => ({
  type: actions.GET_REGION_PARAMETERS,
  user,
  stage,
});

export const actionSetRegionParameters = (regionParameters: any): any => ({
  type: actions.SET_REGION_PARAMETERS,
  regionParameters,
});

export const actionSetPreviousScene = (): any => ({
  type: actions.SET_PREVIOUS_SCENE,
});

export const actionUpdateCoinsAndGems = (
  userId: JSONValue,
  PlatformUrl: string
): any => ({
  type: actions.UPDATE_COINS_GEMS,
  userId,
  PlatformUrl,
});

export const actionSetCoinsAndGems = (payload: unknown): any => ({
  type: actions.SET_COINS_GEMS,
  payload,
});

export const actionSetQuizNextActivity = (payload: boolean): any => ({
  type: actions.SET_QUIZ_NEXT_ACTIVITY,
  payload,
});

export const actionSkipActivity = (
  userUuid: string,
  sessionType: string,
  codename: string
): any => ({
  type: actions.SKIP_ACTIVITY,
  userUuid,
  sessionType,
  codename,
});

export const actionShow403Modal = () => ({
  type: actions.SHOW_403_MODAL,
});

export const actionHide403Modal = () => ({
  type: actions.HIDE_403_MODAL,
});
