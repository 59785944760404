import styled from '@emotion/styled';
import { Typography } from '@innovamat/glimmer-components';
import {
  getProcessedTeacherName,
  isDateMoreThanXDaysAgo,
} from '../../../../utils/common';
import { FlexContainer } from '../../admin-dashboard.styled';
import WarningIcon from '../warning-icon';
import { useTranslation } from 'react-i18next';
import { dates } from '@innovamat/radiance-utils';

export type TeachersProps = {
  firstName: string;
  lastName: string;
  lastConnection: string;
  courseOrder: number;
  classroomName: string;
};

const TeacherName = styled(Typography.Body2)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

const TeachersContainer = styled.div`
  margin: -4px 0;
  max-width: 100%;
`;

const TeachersFlexContainer = styled(FlexContainer)`
  min-width: 120px;
`;

function Teachers({
  firstName,
  lastName,
  lastConnection,
  courseOrder,
  classroomName,
}: TeachersProps): JSX.Element {
  const { t } = useTranslation();

  const WARNING_TOOLTIP = t('reports.admin.warningTooltip.teachers');
  const WARNING_TRESHOLD = 30;
  const hasWarning = isDateMoreThanXDaysAgo(lastConnection, WARNING_TRESHOLD);

  return (
    <TeachersFlexContainer>
      <TeachersContainer>
        <Typography.Subtitle2
          data-testid={`${courseOrder}_${classroomName}_TEACHERDATE`}
        >
          {dates.getFormattedDate(lastConnection.replace(' ', 'T'))}
        </Typography.Subtitle2>
        {firstName !== '' && (
          <TeacherName
            data-testid={`${courseOrder}_${classroomName}_TEACHERNAME`}
          >
            {getProcessedTeacherName(firstName, lastName)}
          </TeacherName>
        )}
      </TeachersContainer>
      {hasWarning && <WarningIcon tooltipText={WARNING_TOOLTIP} />}
    </TeachersFlexContainer>
  );
}

export default Teachers;
