import styled from '@emotion/styled';

const TableWrapperStyled = styled.div`
  font-size: ${({ theme }) => theme.typography.typeScale.small};
  border-radius: ${({ theme }) => theme.borders.radius['sm']};
  border: ${({ theme }) =>
    `${theme.borders.size['01']} ${theme.colors.dark['05']}`};
  width: 100%;
`;

const TableComponentStyled = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  text-align: left;

  tr {
    border-bottom: ${({ theme }) =>
      `${theme.borders.size['01']} ${theme.colors.dark['05']}`};
  }
`;

const TableBodyStyled = styled.tbody``;
const TableFooterStyled = styled.tfoot``;

const TableCellStyled = styled.td`
  padding: 1.5rem 1rem;
`;

const TableFooterCellStyled = styled.td`
  padding: 1.5rem 1rem;
`;

const TableHeaderCellStyled = styled.th`
  padding: 1.5rem 1rem;
`;

const TableHeaderStyled = styled.thead`
  border-bottom: ${({ theme }) =>
    `${theme.borders.size['01']} ${theme.colors.dark['05']}`};
`;

const BasicRow = styled('tr')<{ isExpanded?: boolean }>`
  background-color: ${({ theme, isExpanded }) =>
    isExpanded ? theme.colors.dark['07'] : 'white'};
  &:last-child {
    border-bottom: none;
  }
`;

const TableSubRowStyled = styled(BasicRow)`
  vertical-align: top;
  td {
    padding-top: 0;

    .subRowTitle {
      margin-bottom: 0.5rem;
    }
  }
`;

const TableRowStyled = styled(BasicRow)`
  border-bottom: ${({ isExpanded }) => (isExpanded ? 'none !important' : '')};
`;

const TableBody = (props: any) => <TableBodyStyled {...props} />;
const TableCell = (props: any) => <TableCellStyled {...props} />;
const TableComponent = (props: any) => <TableComponentStyled {...props} />;
const TableFooter = (props: any) => <TableFooterStyled {...props} />;
const TableFooterCell = (props: any) => <TableFooterCellStyled {...props} />;
const TableHeader = (props: any) => <TableHeaderStyled {...props} />;
const TableHeaderCell = (props: any) => <TableHeaderCellStyled {...props} />;
const TableRow = (props: any) => <TableRowStyled {...props} />;
const TableSubRow = (props: any) => <TableSubRowStyled {...props} />;
const TableWrapper = (props: any) => <TableWrapperStyled {...props} />;

export {
  TableBody,
  TableCell,
  TableComponent,
  TableFooter,
  TableFooterCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableSubRow,
  TableWrapper,
};
