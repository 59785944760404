import styled from '@emotion/styled';
import { IconButton } from '../IconButton';
import { ModalOverlay } from '../modal-overlay';

import { useDevice } from '../../hooks/useDevice';
import { SemanticType } from '../../hooks/useSemantic';
import { Button, ButtonProps } from '../Button';
import { getElevation } from '../elevation';
import { Typography } from '../Typography';
import { SemanticMessage } from './modal.semantic-message';

const TopActions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 4px;
`;

const OuterCloseButton = styled(IconButton)`
  svg,
  path {
    fill: 'white';
  }

  &:hover .icon-stateLayer {
    background-color: ${({ theme }) =>
      theme.tokens.color.specific['state-layer']['state-hover-lighter'].value};
  }
`;

const InnerCloseButton = styled(IconButton)`
  position: absolute;
  top: 1rem;
  right: 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    top: 0.5rem;
    right: 0.5rem;
  }
`;

const Container = styled.div<{
  height?: string;
  isMobile?: boolean;
  width?: string;
}>`
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: space-between;
  padding: ${({ isMobile }) => (isMobile ? `24px` : `48px`)};
  background-color: white;
  border-radius: 0.5rem;
  position: relative;
  width: ${({ width }) => (width ? width : `auto`)};
  height: ${({ height }) => (height ? height : `auto`)};
  max-width: 95vw;
  overflow-y: auto;

  ${({ theme }) => getElevation(theme, 'elevation 4')};

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 1.5rem;
  }

  transition: height 0.2s ease-in-out;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
  gap: 1rem;
  width: 100%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ChildrenWrapper = styled.div``;

const CloseButtonType = {
  inner: 'inner',
  outer: 'outer',
  none: 'none',
} as const;

type CloseButtonType = (typeof CloseButtonType)[keyof typeof CloseButtonType];

type Semantic = { type: Exclude<SemanticType, 'info'>; text: string };

type ElementProps = {
  closeButton?: CloseButtonType;
  children?: JSX.Element;
  onClose?: () => void;
  buttons?: ButtonProps[];
  title?: string;
  semantic?: Semantic;
  content?: JSX.Element;
  modalWidth?: string | number;
  modalHeight?: string | number;
  customTitle?: JSX.Element;
  customFooter?: JSX.Element;
} & React.HTMLAttributes<HTMLDivElement>;

const ModalElement = ({
  closeButton,
  onClose,
  buttons,
  title,
  semantic,
  children,
  modalWidth,
  modalHeight,
  customTitle,
  customFooter,
  ...rest
}: ElementProps): JSX.Element => {
  const { isMobile } = useDevice();

  const handleSizeProps = (
    size: string | number | undefined
  ): string | undefined => {
    if (typeof size === 'number') {
      return `${size}px`;
    }
    return size;
  };

  return (
    <>
      {closeButton === 'outer' && (
        <TopActions>
          <OuterCloseButton
            icon="CloseIcon"
            size="M"
            iconColor="white"
            onClick={onClose}
          />
        </TopActions>
      )}
      <Container
        role="dialog"
        height={handleSizeProps(modalHeight)}
        isMobile={isMobile}
        width={handleSizeProps(modalWidth)}
        style={rest.style}
      >
        {closeButton === 'inner' && (
          <InnerCloseButton
            aria-label="close"
            icon="CloseIcon"
            onClick={onClose}
            size="M"
          />
        )}
        {title && (
          <TitleWrapper>
            {semantic && (
              <SemanticMessage type={semantic.type} text={semantic.text} />
            )}
            {customTitle ? (
              customTitle
            ) : (
              <Typography.H3 role="heading">{title}</Typography.H3>
            )}
          </TitleWrapper>
        )}
        <ChildrenWrapper>{children}</ChildrenWrapper>
        {customFooter && customFooter}
        {buttons && (
          <ActionButtons>
            {buttons.map((buttonProps, index) => (
              <Button key={index} {...buttonProps} />
            ))}
          </ActionButtons>
        )}
      </Container>
    </>
  );
};

type Props = {
  isOpen: boolean;
  overlayTestId?: string;
  closeOnEsc?: boolean;
  closeOnClickOutside?: boolean;
  zIndex?: number;
} & ElementProps;

export function Modal({
  isOpen,
  onClose,
  closeButton,
  buttons,
  title,
  semantic,
  children,
  modalWidth,
  modalHeight,
  overlayTestId,
  closeOnEsc,
  closeOnClickOutside,
  customTitle,
  customFooter,
  zIndex,
  ...rest
}: Props): JSX.Element {
  return (
    <ModalOverlay
      isOpen={isOpen}
      onClose={onClose}
      dataTestId={overlayTestId}
      closeOnEsc={closeOnEsc}
      closeOnClickOutside={closeOnClickOutside}
      zIndex={zIndex}
      element={
        <ModalElement
          semantic={semantic}
          title={title}
          onClose={onClose}
          closeButton={closeButton}
          buttons={buttons}
          children={children}
          modalWidth={modalWidth}
          modalHeight={modalHeight}
          customTitle={customTitle}
          customFooter={customFooter}
          {...rest}
        />
      }
    />
  );
}
