import styled from '@emotion/styled';
import { Typography, getTypography } from '@innovamat/glimmer-components';

export const PrintableBody1 = styled(Typography.Body1)`
  @media print {
    ${({ theme }) => getTypography(theme, 'Body 3')}
    line-height: 14px;
    color: #000000;
  }
`;

export const PrintableBody2 = styled(Typography.Body2)`
  @media print {
    ${({ theme }) => getTypography(theme, 'Body 3')}
    line-height: 14px;
    color: #000000;
  }
`;

export const PrintableSubtitle1 = styled(Typography.Subtitle1)`
  @media print {
    ${({ theme }) => getTypography(theme, 'Subtitle 2')}

    font-size: 12px;
    line-height: 14px;
  }
`;

export const PrintableSubtitle2 = styled(Typography.Subtitle2)`
  @media print {
    font-size: 12px;
    line-height: 14px;

    color: ${({ color, theme }) =>
      color === undefined ||
      color === theme.tokens.color.alias.cm.text['text-subtle'].value
        ? '#000000'
        : color};
  }
`;
