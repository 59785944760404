import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDownloadFileGuides = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M9.4 14.2a4.8 4.8 0 1 0 9.6 0 4.8 4.8 0 0 0-9.6 0M14.2 11.8v4.8M14.2 16.6l1.8-1.8M14.2 16.6l-1.8-1.8"
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M7 16.6H2.2A1.2 1.2 0 0 1 1 15.4V2.2A1.2 1.2 0 0 1 2.2 1h8.503a1.2 1.2 0 0 1 .848.351L13.85 3.65a1.2 1.2 0 0 1 .351.848V7"
    />
  </svg>
);
export default SvgDownloadFileGuides;
