import styled from '@emotion/styled';
import { IconButton, Typography } from '@innovamat/glimmer-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Title = styled(Typography.H2)`
  position: relative;
  display: flex;
  align-items: center;

  gap: 8px;
`;

const CourseTitle = styled(Typography.Body1)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

const Subtitle = styled(Typography.Body1)``;

const CourseBlocks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ClassesToAssign = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const ClassesToAssignWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ClassroomsPerCourse = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    grid-template-columns: 1fr;
  }
`;

const BackButton = styled(IconButton)`
  position: absolute;
  left: -44px;
  transform: translateX(-50%);

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    left: 0;
    transform: none;
    position: relative;
  }
`;

const Footer = styled.div`
  z-index: 20;
  display: flex;
  align-items: center;
  height: 72px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid
    ${({ theme }) => theme.tokens.color.alias.cm.border['border-subtle'].value};
  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.surface['surface-primary'].value};
`;

const FooterActions = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  width: 100%;
  gap: 16px;
`;

const BlockTitle = styled(Typography.Body1)`
  margin-bottom: 8px;
`;

const Wrapper = styled.div`
  margin: 16px auto 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;

  align-items: center;
  gap: 24px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const PaddingBottom = styled.div`
  padding-bottom: 32px;
`;

const FlexEndContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const TitleWithButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export {
  Container,
  TitleContainer,
  Title,
  CourseTitle,
  Subtitle,
  CourseBlocks,
  ClassesToAssign,
  ClassesToAssignWrapper,
  ClassroomsPerCourse,
  BackButton,
  Footer,
  FooterActions,
  BlockTitle,
  Wrapper,
  TextWrapper,
  PaddingBottom,
  FlexEndContainer,
  TitleWithButton,
};
