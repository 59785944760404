import styled from '@emotion/styled';
import { Typography, getTypography } from '@innovamat/glimmer-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const GapContent = styled(Content)`
  gap: 24px;
`;

export const Solutions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ActivateSolutionsWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: flex-start;
`;

export const BeforeReviewContent = styled.div<{
  isVisible: boolean;
  height: number;
}>`
  max-height: ${({ isVisible, height }) => (isVisible ? `${height}px` : 0)};
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  overflow: hidden;
  top: 0;

  transition: all 0.3s ease-in-out;
`;

export const AfterReviewContent = styled.div<{
  isVisible: boolean;
  height: number;
}>`
  max-height: ${({ isVisible, height }) => (isVisible ? `${height}px` : 0)};
  overflow: hidden;

  transition: max-height 0.3s ease-in-out;
`;

export const Optional = styled(Typography.Body2)`
  display: inline;
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

export const TextArea = styled.textarea`
  width: 100%;
  border-radius: 8px;
  border: 1px solid
    ${({ theme }) => theme.tokens.color.alias.cm.border['border-default'].value};
  resize: none;
  padding: 8px 12px;

  ::placeholder {
    color: ${({ theme }) =>
      theme.tokens.color.alias.cm.text['text-disabled'].value};
    font-family: inherit;
  }

  ${({ theme }) => getTypography(theme, 'Body 1')}
`;

export const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Divider = styled.div<{ margin?: 'top' | 'bottom' }>`
  margin: ${({ margin }) =>
    margin === 'top'
      ? '24px 0 0 0'
      : margin === 'bottom'
      ? '0 0 24px 0'
      : '24px 0'};
  border-bottom: 1px solid
    ${({ theme }) => theme.tokens.color.alias.cm.border['border-subtle'].value};
`;

export const QuestionTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    align-self: center;
    justify-content: center;
  }
  button:last-of-type {
    margin-left: auto;
  }
`;
