
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as ArrangeIconComponent } from "./arrange-icon.svg";

export const ArrangeIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <ArrangeIconComponent {...props} />;
};
