import React, { useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import cx from 'classnames';
import styled from '@emotion/styled';
import Icon from '../Icon';
import Button from '../Button';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import styles from './Modal.module.scss';

type ButtonTypes = 'button' | 'submit' | 'reset' | undefined;

const CloseButton = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
`;

type Props = {
  open: boolean;
  title?: string | JSX.Element;
  clickOutsideClose?: boolean;
  children?: React.ReactNode;
  loading?: boolean;
  cancelText?: string;
  submitText?: string;
  showSubmitBtn?: boolean;
  showCancelBtn?: boolean;
  showCloseBtn?: boolean;
  size?: 'auto' | 'normal' | 'small';
  customFooter?: JSX.Element;
  customTitle?: JSX.Element;
  isForm?: boolean;
  disableSubmit?: boolean;
  width?: number | string;
  height?: number | string;
  onClose?: () => void;
  onSubmit?: (event: React.FormEvent<HTMLFormElement> | undefined) => void;
  dataInlineManual?: string;
  className?: string;
  closeButtonTestId?: string;
  cancelButtonTestId?: string;
  submitButtonTestId?: string;
  overlayTestId?: string;
};

function Modal({
  open,
  title,
  clickOutsideClose = false,
  children,
  loading = false,
  cancelText,
  submitText,
  showSubmitBtn = true,
  showCancelBtn = true,
  showCloseBtn = false,
  size = 'normal',
  customFooter,
  customTitle,
  isForm = false,
  disableSubmit = false,
  width,
  height,
  onClose,
  onSubmit,
  dataInlineManual,
  className,
  closeButtonTestId,
  cancelButtonTestId,
  submitButtonTestId,
  overlayTestId,
}: Props) {
  const ref = useRef(null);

  useOnClickOutside(ref, () => {
    if (clickOutsideClose && !loading) {
      onClose!();
    }
  });

  // if the modal is not a form, return a onlick method for submit button
  const handleClickOnSubmit = () => (isForm ? undefined : onSubmit!(undefined));

  const submitButtonType: ButtonTypes = isForm ? 'submit' : 'button';

  const allredyOneModalOpen = useMemo(
    () => document.getElementById('modal')?.childElementCount! >= 2,
    []
  );

  const overlayClass = cx(styles.overlay, {
    [styles.overlayBg]: !allredyOneModalOpen,
  });

  const modalClass = cx(styles.modal, {
    [className as string]: !!className,
  });

  if (!open) return null;

  const modalRoot = document.getElementById('modal') as HTMLElement;
  const hasTitle = title || customTitle;
  return createPortal(
    <>
      <div className={overlayClass} data-testid={overlayTestId} />
      <div
        ref={ref}
        style={{ width, height }}
        className={`${modalClass} ${styles[size]}`}
        data-inlinemanual={dataInlineManual}
      >
        {showCloseBtn && (
          <CloseButton onClick={onClose} data-testid={closeButtonTestId}>
            <Icon icon="Close" />
          </CloseButton>
        )}

        {hasTitle &&
          (customTitle || (
            <div className={styles.title}>
              <h2>{title}</h2>
            </div>
          ))}
        {children && <div className={styles.content}>{children}</div>}
        {customFooter || (
          <div className={styles.footer}>
            {showCancelBtn && (
              <Button
                size="large"
                color="secondary"
                disabled={loading}
                onClick={onClose}
                data-inlinemanual="cancel"
                data-testid={cancelButtonTestId}
              >
                {cancelText}
              </Button>
            )}
            {showSubmitBtn && (
              <Button
                size="large"
                type={submitButtonType}
                disabled={disableSubmit}
                loading={loading}
                onClick={handleClickOnSubmit}
                data-inlinemanual="submit"
                data-testid={submitButtonTestId}
              >
                {submitText}
              </Button>
            )}
          </div>
        )}
      </div>
    </>,
    modalRoot
  );
}

export default Modal;
