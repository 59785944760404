import * as React from 'react';
import type { SVGProps } from 'react';
const SvgApertura = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      clipPath="url(#Apertura_svg__a)"
    >
      <path d="M.625 19.375V3.125l3.75-2.5v18.75M.625 11.875h3.75M.625 8.125h5M.625 15.625h6.9M13.125 1.25l-1.13 1.417a6.25 6.25 0 0 0-1.37 3.9v11.558h5V6.567a6.25 6.25 0 0 0-1.37-3.9z" />
      <path d="m10.625 18.125-2.846.797a.625.625 0 0 1-.904-.559v-.273a5 5 0 0 1 2.764-4.472l.986-.493z" />
      <path d="m10.625 18.125-2.846.797a.625.625 0 0 1-.904-.559v-.273a5 5 0 0 1 2.764-4.472l.986-.493zM15.625 18.125l2.846.797a.625.625 0 0 0 .904-.559v-.273a5 5 0 0 0-2.764-4.472l-.986-.493z" />
      <path d="m15.625 18.125 2.846.797a.625.625 0 0 0 .904-.559v-.273a5 5 0 0 0-2.764-4.472l-.986-.493z" />
    </g>
    <defs>
      <clipPath id="Apertura_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgApertura;
