import styled from '@emotion/styled';

const List = styled('ul')`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const MaterialRow = styled('li')`
  color: ${({ theme }) => theme.colors.dark['03']};
  display: grid;
  grid-template-columns: 20% 60% 10% 10%;
  text-align: left;
  padding-bottom: 0.11rem;
  padding-top: 0.11rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark['05']};

  .materialName {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
  }

  span {
    align-self: center;
    padding-right: 1rem;
    &:last-of-type {
      padding-right: 0;
    }
  }

  .totalOperationQuantity {
    text-align: right;
    span {
      padding-left: 3px;
      padding-right: 3px;
    }
  }
`;

export { MaterialRow, List };
