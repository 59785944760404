import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import {
  Loader,
  SuccessAnimation,
  useGlimmerTheme,
} from '@innovamat/glimmer-components';

type Props = {
  duration: number;
};

const AnimationContainer = styled.span<{ isVisible: boolean }>`
  position: absolute;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.5s ease-in-out;
`;

const SUCCESS_DURATION = 2500;

export function LoadingManagerAnimation({ duration }: Props): JSX.Element {
  const [isSuccess, setIsSuccess] = useState(false);
  const theme = useGlimmerTheme();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsSuccess(true);
    }, duration - SUCCESS_DURATION);

    return () => {
      clearTimeout(timeout);
    };
  }, [duration]);

  return (
    <>
      <AnimationContainer isVisible={!isSuccess}>
        <Loader
          size="lg"
          color={theme.tokens.color.alias.cm.border['border-subtle'].value}
        />
      </AnimationContainer>
      <AnimationContainer isVisible={isSuccess}>
        <SuccessAnimation
          autoplay={isSuccess}
          loop={false}
          width={136}
          height={136}
        />
      </AnimationContainer>
    </>
  );
}
