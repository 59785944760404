import { css } from '@emotion/react';
import type { SerializedStyles, Theme } from '@emotion/react';

type ElevationType = keyof Theme['tokens']['elevation'] | 'none';

const getElevation = (
  theme: Theme,
  searchedElevation: ElevationType
): SerializedStyles => {
  if (searchedElevation === 'none') {
    return css`
      box-shadow: none;
    `;
  }

  const { x, y, blur, spread, color } =
    theme.tokens.elevation[searchedElevation].value;

  return css`
    box-shadow: ${x}px ${y}px ${blur}px ${spread}px ${color};
  `;
};

export { getElevation };

export type { ElevationType };
