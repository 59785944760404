
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as WhatsAppLogoIconComponent } from "./whats-app-logo-icon.svg";

export const WhatsAppLogoIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <WhatsAppLogoIconComponent {...props} />;
};
