import styled from '@emotion/styled';
import { Check } from '../SvgIcons';

const StepperWrapper = styled('div')`
  background: ${({ theme }) => theme.colors.white};
  justify-content: space-between;
  display: flex;
  width: fit-content;
  position: relative;
  height: 20px;
  padding: 0 7px;
`;

export const StepContainer = styled('div')`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`;

const Step = styled('div')<{ checked: boolean }>`
  width: 6px;
  height: 6px;
  display: inline-block;
  border-radius: 3px;
  z-index: 1;
  position: relative;

  background-color: ${({ checked, theme }) =>
    checked ? null : theme.colors.brand.dark['02']};

  margin: auto 0;
`;

const Circle = styled('div')<{ active: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;

  background-color: ${({ theme }) => theme.colors.brand['03']};

  transform: translate(${({ active }) => active * 20 + 7}px);
  transition-duration: 0.5s;
`;

const CustomCheck = styled(Check)`
  position: absolute;
  top: -120%;
  left: -120%;
  transform: scale(0.5);

  path {
    fill: ${({ theme }) => theme.colors.brand.dark['02']};
    stroke: ${({ theme }) => theme.colors.brand.dark['02']};
  }
`;

export { StepperWrapper, Step, Circle, CustomCheck };
