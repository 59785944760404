
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as CloseAppletsIconComponent } from "./close-applets-icon.svg";

export const CloseAppletsIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <CloseAppletsIconComponent {...props} />;
};
