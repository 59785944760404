import styled from '@emotion/styled';
import Tippy from '@tippyjs/react';
import { InnerHtml } from '@innovamat/innova-components';
import BlockContent from './BlockContent';
import BlockWithIcon from './BlockWithIcon';
import { Material, MaterialList } from '../types/materials';
import ImageTooltip from './ImageTooltip';
import { mapMaterials, MappedMaterialItem } from '../utils/mapMaterials';
import { Typography } from '@innovamat/glimmer-components';
import { useDigitalGuides } from '../context';
import { PrintableBody2 } from '../../printable-styles/printable-typography';

type Props = {
  material: Material;
  hideIcon?: boolean;
  items: MaterialList;
  groupMaterials?: boolean;
  studentItems?: MaterialList;
};

const StyledInnerHtml = styled(InnerHtml)`
  display: inline;
`;

const Content = styled.div`
  color: ${({ theme }) =>
    theme.tokens.color.specific['smart-guides']['part0-element'].value};
  display: flex;
  flex-direction: column;

  @media print {
    gap: 2px;
  }
`;

const BaseMaterial = styled.div`
  color: ${({ theme }) =>
    theme.tokens.color.specific['smart-guides']['part0-element'].value};
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const StyledTippy = styled(Tippy)`
  background-color: ${({ theme }) =>
    theme.tokens.color.specific['smart-guides']['part0-element'].value};
  display: flex;
  align-items: center;
  justify-content: center;
  .tippy-arrow {
    color: ${({ theme }) =>
      theme.tokens.color.specific['smart-guides']['part0-element'].value};
  }
  .tippy-content {
    padding: 4px;
  }
`;

const ImageTooltipWrapper = styled.div`
  background: ${({ theme }) =>
    theme.tokens.color.specific['smart-guides']['part0-element'].value};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  border-radius: 4px;
`;

const StyledMaterial = styled.span`
  cursor: default;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  span {
    margin-left: 4px;
  }
`;

const NoMaterials = styled(BlockWithIcon)`
  display: flex;
  align-items: center;
  svg {
    margin-top: 0px !important;
  }
`;

const StyledPrintableBody2 = styled(PrintableBody2)`
  @media print {
    color: ${({ theme }) =>
      theme.tokens.color.specific['smart-guides']['part0-element'].value};
  }
`;

export default function MaterialsBlock({
  material,
  hideIcon = false,
  items,
  groupMaterials,
  studentItems,
}: Props) {
  const { materialList, classroomMaterial, digitalResources, studentMaterial } =
    mapMaterials(material);
  const { t } = useDigitalGuides();

  const renderBaseMaterial = (
    item: MappedMaterialItem,
    index: number,
    listItems: MaterialList = items
  ) => {
    const { image, key } = listItems[item.text] || {};

    const material = () => {
      if (key) {
        return (
          <StyledPrintableBody2>
            <StyledTippy
              content={
                <ImageTooltipWrapper>
                  <ImageTooltip image={image} />
                </ImageTooltipWrapper>
              }
              interactive
              placement="right"
              offset={[0, 20]}
              maxWidth="auto"
              touch={false}
            >
              <StyledMaterial>
                <StyledInnerHtml text={t(key)} />
                {item.additionalText && (
                  <span>&#40;{item.additionalText}&#41;</span>
                )}
                {item.optional && (
                  <span>&#40;{t('digitalguides.material.optional')}&#41;</span>
                )}
              </StyledMaterial>
            </StyledTippy>
          </StyledPrintableBody2>
        );
      }
      return renderMaterial(item, index);
    };

    return <div key={`${item.id}-${index}`}>{material()}</div>;
  };

  const renderMaterial = (item: MappedMaterialItem, index: number) => {
    return (
      <BaseMaterial key={`${item.id}-${index}`}>
        <PrintableBody2>
          <InnerHtml text={item.text} />
        </PrintableBody2>
      </BaseMaterial>
    );
  };

  const renderMainBlock = (list: MappedMaterialItem[]) => {
    return (
      <BlockWithIcon icon={'ShipmentUpload'} hideIcon={hideIcon}>
        <List>
          <BlockContent title={t('digitalguides.material.innovamat')}>
            <Content>
              {list.map((item, i) => renderBaseMaterial(item, i))}
            </Content>
          </BlockContent>
        </List>
      </BlockWithIcon>
    );
  };

  const renderStudentBlock = (list: MappedMaterialItem[]) => {
    return (
      <BlockWithIcon icon={'Rule'} hideIcon={hideIcon}>
        <List>
          <BlockContent title={t('digitalguides.pe.material.student_material')}>
            <Content>
              {list.map((item, i) => renderBaseMaterial(item, i, studentItems))}
            </Content>
          </BlockContent>
        </List>
      </BlockWithIcon>
    );
  };

  if (!material) {
    return (
      <NoMaterials icon="Material">
        <Typography.Body2>{t('materials.no_materials')}</Typography.Body2>
      </NoMaterials>
    );
  }

  if (groupMaterials) {
    const allMaterials = [
      ...materialList,
      ...classroomMaterial,
      ...digitalResources,
    ];
    return <>{renderMainBlock(allMaterials)}</>;
  }

  return (
    <>
      {materialList.length > 0 && renderMainBlock(materialList)}

      {classroomMaterial.length > 0 && (
        <BlockWithIcon icon="Material" hideIcon={hideIcon}>
          <List>
            <BlockContent title={t('digitalguides.material.classroom')}>
              <>{classroomMaterial.map(renderMaterial)}</>
            </BlockContent>
          </List>
        </BlockWithIcon>
      )}

      {studentMaterial.length > 0 && renderStudentBlock(studentMaterial)}

      {digitalResources.length > 0 && (
        <BlockWithIcon icon="DigitalResources" hideIcon={hideIcon}>
          <List>
            <BlockContent title={t('digitalguides.material.digital')}>
              <BaseMaterial>
                {digitalResources.map(renderMaterial)}
              </BaseMaterial>
            </BlockContent>
          </List>
        </BlockWithIcon>
      )}
    </>
  );
}
