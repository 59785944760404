import styled from '@emotion/styled';

import type { CardProps } from './Card.types';

const CardWrapper = styled('div')<CardProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ height }) => `height: ${height}`};
  ${({ width }) => `width: ${width}`};

  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor || theme.colors.white};

  ${({ hasBorder, theme }) =>
    hasBorder
      ? `border: ${theme.borders.size['02']} ${theme.colors.dark['05']}`
      : ''};

  border-radius: ${({ borderRadius, theme }) =>
    borderRadius
      ? theme.borders.radius[borderRadius]
      : theme.borders.radius.sm};

  box-shadow: ${({ elevation, theme }) =>
    elevation ? theme.elevations?.[elevation] : theme.elevations['01']};
`;

export { CardWrapper };
