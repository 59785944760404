import styled from '@emotion/styled';
import { Skeleton } from '@innovamat/glimmer-components';

const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

export function LateralMenuSkeleton(): JSX.Element {
  return (
    <SkeletonContainer>
      <Skeleton width="100%" height="32" radius="4" />
      <Skeleton width="100%" height="32" radius="4" />
      <Skeleton width="100%" height="32" radius="4" />
      <Skeleton width="100%" height="32" radius="4" />
      <Skeleton width="100%" height="32" radius="4" />
    </SkeletonContainer>
  );
}
