
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as DownloadIconComponent } from "./download-icon.svg";

export const DownloadIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <DownloadIconComponent {...props} />;
};
