import styled from '@emotion/styled';
import { TableContentType } from '../../../types/momentContent';
import { ImageModal } from '../../ImagesModal/ImagesModal';
import { InnerHtmlParser } from '../../InnerHtmlParser';
import OverlayImage from '../../OverlayImage';
import { PreprTable } from '../helpers';
import { ImageComponent } from '@innovamat/glimmer-components';

type Props = {
  momentTable: PreprTable | undefined;
  color: string | undefined;
  onClickImage: (image: ImageModal | null) => void;
};

const Table = styled.table`
  width: 100%;
  border-spacing: 4px !important;
`;

const Image = styled(ImageComponent)`
  width: 100%;
`;

type Cell = {
  bgColor: string | undefined;
};

const Th = styled.th<Cell>`
  background-color: ${({ bgColor, theme }) =>
    bgColor || theme.colors.dark['06']};
  color: ${({ theme }) => theme.colors.dark['01']};
  border-radius: 4px;
  padding: 16px;
  border: none;
  * {
    font-size: 14px !important;
    font-weight: 500;
    line-height: 16px;
  }
`;

const Td = styled.td<Cell>`
  border-radius: 4px;
  padding: 8px;
  border: none;
  vertical-align: top;
  * {
    font-size: 14px !important;
    font-weight: 400;
    line-height: 20px;
  }
  ul {
    margin-left: 24px;
    line-height: 24px;
  }
`;

export default function MomentBlockTable({
  momentTable,
  color,
  onClickImage,
}: Props) {
  const tablePercentage = momentTable?.configuration || [];

  const getComponent = (item: TableContentType, index: number) => {
    const isHeader = item.items.header_cell?.value;
    const text = item.items.text_cell_content;
    const image = item.items.image_cell_content;
    const width = `${tablePercentage[index]}%`;
    const Wrapper = isHeader ? Th : Td;

    let overlayImage = null;

    if (image && image.items[0].label === 'Photo') {
      overlayImage = {
        url: image.items[0].cdn_files.items[0].url,
        title: image.items[0].caption || '',
        id: image.items[0].cdn_files.items[0].id,
      };
    }

    const getColor = () => {
      if (!isHeader) return 'transparent';
      if (!color) return undefined;
      return color;
    };

    return (
      <Wrapper width={width} bgColor={getColor()} key={index}>
        {text && <InnerHtmlParser text={item.items.text_cell_content} />}

        {overlayImage && (
          <OverlayImage
            image={overlayImage}
            onClickImage={onClickImage}
            isBigImage={false}
            color={color}
          >
            <Image src={overlayImage.url} alt={overlayImage.title} />
          </OverlayImage>
        )}
      </Wrapper>
    );
  };

  return (
    <Table className="custom">
      <tbody>
        {momentTable?.tableContent?.map((row, index) => (
          <tr key={index}>{row.map(getComponent)}</tr>
        ))}
      </tbody>
    </Table>
  );
}
