import styled from 'styled-components';
import { Tooltip, Typography, theme } from '@innovamat/glimmer-components';

type Props = {
  subtitle?: string;
  description?: string;
  students?: string[];
  scenes?: string[];
  isStudentsWithDifficulties?: boolean;
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0px;
  cursor: default;
`;

const ListOfStudents = styled.ul`
  color: ${theme.tokens.color.alias.cm.text['text-subtle'].value};
  list-style-position: inside;
  margin-left: 4px;
`;

const StudentWrapper = styled(Typography.Body2)`
  span {
    margin-left: 4px;
  }
`;

const Scenes = styled(Typography.Subtitle2)`
  display: inline-block;
  margin-left: 2px;
`;
const Subtitle = styled(Typography.Subtitle2)`
  display: inline-block;
`;

const Description = styled(Typography.Body2)`
  margin-bottom: 16px;
`;

function NotesOfTheWeekSubtitle({
  subtitle,
  description,
  students,
  scenes,
  isStudentsWithDifficulties,
}: Props): JSX.Element {
  const getStudent = () => {
    if (!students) return;
    return students.map((student: string) => {
      return (
        <StudentWrapper
          color={theme.tokens.color.alias.cm.text['text-subtle'].value}
        >
          <ListOfStudents>
            <li>{student}</li>
          </ListOfStudents>
        </StudentWrapper>
      );
    });
  };

  const renderScenes = (): string | undefined => {
    if(!scenes?.length) return '';
    if (scenes && scenes.length > 4) {
      return `(${scenes.slice(0, 4).join(', ') + '...'})`;
    } else {
      return `(${scenes?.join(', ')})`;
    }
  };

  const getTooltipScenes = (): string | undefined => {
    if (scenes && scenes.length > 4) {
      return scenes.join(', ');
    }
    return;
  };

  const renderSubtitle = (): string | undefined => {
    if (isStudentsWithDifficulties) {
      return students && students.length > 0 ? subtitle : '';
    }
    return subtitle;
  };

  return (
    <>
      <Wrapper>
        <Subtitle>
          {renderSubtitle()}
          {scenes && (
            <Tooltip
              content={getTooltipScenes()}
              popperOptions={{ strategy: 'fixed' }}
            >
              <Scenes
                color={theme.tokens.color.alias.cm.text['text-subtle'].value}
              >
                {renderScenes()}
              </Scenes>
            </Tooltip>
          )}
        </Subtitle>
      </Wrapper>
      {description && (
        <Description
          color={theme.tokens.color.alias.cm.text['text-subtle'].value}
        >
          {description}
        </Description>
      )}
      {getStudent()}
    </>
  );
}

export default NotesOfTheWeekSubtitle;
