import styled from '@emotion/styled';
import { SummarySectionPrintable } from '../SummarySection/SummarySectionPrintable';
import { PartsPrintable } from '../Parts/PartsPrintable';
import { TitlePrintable } from '../TitlePrintable';
import {
  SmartGuidesProvider,
  SmartGuidesProviderProps,
} from '../../context/SmartGuidesProvider';

const Wrapper = styled.div`
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.colors.dark['02']};
  margin: 0 auto;
  max-width: 1240px;

  @media print {
    padding: 0;
    border: none;
  }
`;

const Printable = styled.div`
  width: 100%;

  .printableContent {
    column-count: 2;
    column-fill: balance;

    @media print {
      column-fill: auto;
    }
  }

  #prepr-video {
    display: none;
  }
`;

type Props = Omit<SmartGuidesProviderProps, 'children'>;

export function SmartGuidePrintable(props: Props): JSX.Element {
  const { session, smartGuide } = props;

  return (
    <SmartGuidesProvider {...props}>
      <Wrapper>
        <Printable>
          <TitlePrintable
            title={session.title}
            subtitle={smartGuide.subtitle}
          />

          <div className="printableContent">
            <SummarySectionPrintable />
            <PartsPrintable />
          </div>
        </Printable>
      </Wrapper>
    </SmartGuidesProvider>
  );
}
