import { IconType } from '@innovamat/glimmer-icons';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Tooltip } from '../Tooltip';
import { Icon } from '../Icon';
import { StateLayer } from '../../utils/common.styled';

export const POSITION = {
  left: 'left',
  right: 'right',
  top: 'top',
  bottom: 'bottom',
};

export type Position = (typeof POSITION)[keyof typeof POSITION];

type Props = {
  icon: IconType;
  position: Position;
  tooltip?: string;
};

const cornerPositionStyles = (position: Position) => {
  if (position === POSITION.left) {
    return css`
      left: 0;
      bottom: 0;
      border-top-right-radius: 100%;
      border-bottom-left-radius: 4px;
    `;
  }

  if (position === POSITION.right) {
    return css`
      right: 0;
      bottom: 0;
      border-top-left-radius: 100%;
      border-bottom-right-radius: 4px;
    `;
  }

  return css`
    top: 0;
    right: 0;
    border-bottom-left-radius: 100%;
    border-top-right-radius: 4px;
  `;
};

const Container = styled.div<{ position: Position }>`
  width: 40px;
  height: 40px;
  position: absolute;
  overflow: hidden;
  background-color: ${({ theme, position }) =>
    position === POSITION.top
      ? theme.tokens.color.specific.element['thumbnail-tag'].value
      : theme.tokens.color.alias.cm.surface['surface-primary'].value};

  z-index: ${({ position }) => (position === 'right' ? 0 : 10)};

  ${({ position }) => cornerPositionStyles(position)}

  svg, path {
    fill: ${({ theme, position }) =>
      position === POSITION.top
        ? theme.tokens.color.alias.cm.icon['icon-inverted'].value
        : theme.tokens.color.alias.cm.icon['icon-default'].value};
  }

  .tooltip {
    display: inline;
  }

  :hover .resource-icon-state-layer {
    background-color: ${({ theme, position }) =>
      position === POSITION.top
        ? theme.tokens.color.specific['state-layer']['state-hover-lighter']
            .value
        : theme.tokens.color.specific['state-layer']['state-hover-darker']
            .value};
  }
`;

const Wrapper = styled.div<{ position: Position }>`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ position }) => {
    if (position === POSITION.left) {
      return css`
        transform: translate(-4px, 4px);
      `;
    }

    if (position === POSITION.right) {
      return css`
        transform: translate(4px, 4px);
      `;
    }

    return css`
      transform: translate(4px, -4px);
    `;
  }}
`;

export function ResourceCardIcon({
  icon,
  position,
  tooltip,
}: Props): JSX.Element {
  return (
    <Container position={position}>
      <StateLayer className="resource-icon-state-layer" />
      <Tooltip className="tooltip" content={tooltip}>
        <Wrapper position={position}>
          <Icon icon={icon} className="icon" size="M" />
        </Wrapper>
      </Tooltip>
    </Container>
  );
}
