import { Theme } from '@emotion/react';
import styled from '@emotion/styled';

import type { OrderStatus } from './OrderStatusTag.types';

const getColorByStatus = (orderStatus: OrderStatus, theme: Theme) => {
  switch (orderStatus) {
    case 'cancel':
    case 'returned':
      return theme.colors.dark['06'];
    case 'completed':
      return theme.colors.semantic['01b'];
    case 'expand':
      return theme.colors.semantic['04b'];
    case 'return':
      return theme.colors.semantic['02b'];
    default:
      return '';
  }
};

const Wrapper = styled('div')<{ orderStatus: OrderStatus }>`
  align-items: center;
  background-color: ${({ orderStatus, theme }) =>
    getColorByStatus(orderStatus, theme)};
  border-radius: 1rem;
  display: inline-flex;
  justify-content: center;
  padding: 0.5rem 1rem;
`;

export { Wrapper };
