import * as React from 'react';
import type { SVGProps } from 'react';
const SvgTab = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M12.5 9.998H5M9.375 13.123 12.5 9.998 9.375 6.873"
      />
      <path
        strokeWidth={1.249995}
        d="M18.75 18.748H1.25v-17.5h17.5zM15 13.748v-7.5"
      />
    </g>
  </svg>
);
export default SvgTab;
