import styled from '@emotion/styled';
import { useCallback, useMemo, useState } from 'react';
import { Session as SessionType } from '@innovamat/resource-viewer';
import { convertObjectToQueryParams } from '@innovamat/radiance-utils';
import useEarlyYearGuide from '../templates/EarlyYear/context/useEarlyYearGuide';
import UploadIcon from '../assets/icons/UploadIcon';
import { theme } from '@innovamat/glimmer-components';
import { getTypeformLangId } from '@innovamat/localization';
import { useDigitalGuides } from '../context/digital-guides-provider';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: 8px;
  margin-top: auto;
  background-color: transparent;
  border: 1.5px dashed ${theme.colors.brand.dark['02']};
  transition: background-color 0.1s ease;
  &:hover {
    border: 1.5px dashed ${theme.colors.brand.dark['01']};
    background-color: #e8f9f8;
  }
`;

const Link = styled.a`
  text-decoration: none;
`;

type Props = {
  sessionId: string;
};

const baseUrl = 'https://matematicas.typeform.com/to/';

export default function UploadImage({ sessionId }: Props) {
  const { guide, resource, onEvent } = useEarlyYearGuide();
  const session_id = sessionId;

  const { user } = useDigitalGuides();

  const [isHover, setIsHover] = useState(false);

  const payload = {
    session_id,
    school_id: user?.organizationId,
    school: user?.organizationName,
    email: user?.email,
    name: user?.name,
    course_order: user?.courseOrder,
    region: user?.region,
  };

  const lang = getTypeformLangId(user?.language!);
  const url = `${baseUrl + lang}#${convertObjectToQueryParams(payload)}`;

  const dataEvent = useMemo(
    () => ({
      space_id: (resource as SessionType).guide?.id || '',
      space_name: guide?.title?.body,
      session_id,
      session_name: resource.title,
    }),
    [resource, guide, session_id]
  );

  const sendLoggingEvent = useCallback(() => {
    onEvent('send_variation', dataEvent);
  }, [dataEvent]);

  return (
    <Link
      href={url}
      target="_blank"
      onClick={sendLoggingEvent}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <Wrapper data-inlinemanual="variations">
        <UploadIcon
          stroke={theme.colors.brand.dark[`${isHover ? '01' : '02'}`]}
        />
      </Wrapper>
    </Link>
  );
}
