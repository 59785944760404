import styled from '@emotion/styled';
import { PartType } from '../../types/SmartGuide';
import { usePartColors } from '../../hooks/usePartColors';
import { PrintableSubtitle1 } from '../../printable-styles/printable-typography';

const PartDividerContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 32px;

  @media print {
    padding: 4px 28px;
  }
`;

const Divider = styled.div`
  flex: 1;
  height: 1px;
  background-color: ${({ color }) => color};
`;

const Title = styled(PrintableSubtitle1)`
  margin: 0 24px;
  color: ${({ color }) => color};
`;

type Props = {
  text: string;
  part: PartType;
};

export function PartDivider({ text, part }: Props): JSX.Element {
  const { getElement } = usePartColors();
  const color = getElement(part);

  return (
    <PartDividerContainer>
      <Divider color={color} />
      <Title color={color}>{text}</Title>
      <Divider color={color} />
    </PartDividerContainer>
  );
}
