import { Trans } from 'react-i18next';

import styled from '@emotion/styled';

import { useEventLogging } from '@innovamat/event-logging';

import { AnnouncementPanel, Typography } from '@innovamat/glimmer-components';

export const Container = styled.div`
  margin-top: 0.5rem;
`;

type TrackNavigationEventParameters = {
  classroom_id?: string;
  course?: string;
  school_id?: string;
  tags?: string;
  user_id?: string;
};

type Props = {
  classroomId?: string;
  userId?: string;
};

const TypesOfTestsAnnouncementPanel = ({
  classroomId,
  userId,
}: Props): JSX.Element => {
  const { setEventData } = useEventLogging();

  const trackNavigationEvent =
    (parameters: TrackNavigationEventParameters) =>
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      setEventData('navigate_to', {
        classroom_id: classroomId,
        url: event.currentTarget.href,
        user_id: userId,
        ...parameters,
      });
    };

  return (
    <Container>
      <AnnouncementPanel
        canClose={false}
        text={
          <Trans
            i18nKey="typesOfTestsAnnouncementPanel.text"
            components={{
              linkConMat: (
                <Typography.Link2
                  target="_blank"
                  onClick={trackNavigationEvent({ tags: 'conmat' })}
                />
              ),
              linkCosmos: (
                <Typography.Link2
                  target="_blank"
                  onClick={trackNavigationEvent({ tags: 'cosmos' })}
                />
              ),
            }}
          />
        }
      />
    </Container>
  );
};

export { TypesOfTestsAnnouncementPanel };
