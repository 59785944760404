import { combineReducers, Reducer } from 'redux';
import { connectRouter } from 'connected-react-router';
import { AppAction, AppState } from '../root/root.interfaces';
import errors from '../errors/errors.reducer';
import authReducer from '../auth/reducer';
import loading from '../loading/loading.reducer';
import history from '../history';
import unityDataReducer from '../unityData/unityData.reducer';

const appReducer: Reducer = combineReducers({
  router: connectRouter(history),
  errors,
  loading,
  auth: authReducer,
  uData: unityDataReducer,
});

const rootReducer: Reducer = (state: AppState, action: AppAction) =>
  appReducer(state, action);

export default rootReducer;
