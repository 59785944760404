import { useTranslation } from 'react-i18next';

import styled from '@emotion/styled';

import type { CustomColumnRenderConfig } from '@innovamat/glimmer-components';
import {
  Chip,
  TableComponent,
  Typography,
  useColumnsCustomRenderConfig,
} from '@innovamat/glimmer-components';
import { dates } from '@innovamat/radiance-utils';

import { useFamilyPaymentsColumnsConfiguration } from '../hooks/use-family-payments-columns-configuration';

import { EllipsisCell } from '../../../../classrooms-management/components/common-table-components/ellipsis-cell/ellipsis-cell';
import type { FamilyPayment } from '../types/family-payment';

export const Container = styled.div`
  margin-top: 2.5rem;
  margin-bottom: 1rem;
`;

type Props = {
  familyPayments: FamilyPayment[];
};

// todo move to utils
const formatDate = (date: string): string => {
  if (!date) {
    return '--/--/----'; // todo check format for different regions
  }

  return dates.formatDateFromSql(date);
};

const FamilyPaymentsTable = ({ familyPayments }: Props): JSX.Element => {
  const configurationWithoutRender = useFamilyPaymentsColumnsConfiguration();
  const { t } = useTranslation();

  const customColumnsRenderConfig: CustomColumnRenderConfig<FamilyPayment> = {
    'student.name': (_, row) => (
      <Typography.Body2>
        <EllipsisCell text={row.student.name || '-'} />
      </Typography.Body2>
    ),
    'product.name': (_, row) => (
      <Typography.Body2>
        <EllipsisCell text={row.product.name} />
      </Typography.Body2>
    ),
    'product.courseName': (_, row) => (
      <Typography.Body2>
        <EllipsisCell text={row.product.courseName} />
      </Typography.Body2>
    ),
    'product.language': (_, row) => (
      <Typography.Body2>
        <EllipsisCell
          text={
            row.product.language
              ? t(`common.language.${row.product.language}`)
              : '-'
          }
        />
      </Typography.Body2>
    ),
    'product.quantity': (_, row) => (
      <Typography.Body2>
        <EllipsisCell text={row.product.quantity.toString()} />
      </Typography.Body2>
    ),
    'order.readableExternalId': (_, row) => (
      <Typography.Body2>
        <EllipsisCell text={row.order.readableExternalId} />
      </Typography.Body2>
    ),
    'order.payoutDate': (_, row) => formatDate(row.order.payoutDate),
    'order.financialStatus': () => (
      <Chip type="success" text={t('common.payed')} />
    ),
  };

  const columns = useColumnsCustomRenderConfig<FamilyPayment>(
    customColumnsRenderConfig,
    configurationWithoutRender
  );

  return (
    <Container>
      <TableComponent
        columns={columns}
        id="family-payments-table"
        key="family-payments-table"
        rows={familyPayments}
      />
    </Container>
  );
};

export { FamilyPaymentsTable };
