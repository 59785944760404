import React from 'react';

import styled from '@emotion/styled';
import { getColorByGroup } from '../../utils/helpers';
import { Group } from '../../types/group';

type PillStepsProps = {
  id: string;
  size: number;
  group: Group;
  selected: boolean;
  onClick: (event: React.MouseEvent<HTMLInputElement>) => void;
  setPillContentHover: (pillContent: boolean) => void;
};
type StyledPillStepsProps = {
  size: number;
  group: Group;
  selected: boolean;
  onClick: (event: React.MouseEvent<HTMLInputElement>) => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
};

const StyledPillStep = styled.div<StyledPillStepsProps>`
  width: ${(props) => `${props.size}%`};
  height: auto;
  background-color: ${(props) => {
    const { group } = props;
    return props.selected
      ? `${getColorByGroup(group)?.medium}`
      : `${getColorByGroup(group)?.light}`;
  }};

  &:hover {
    background-color: ${(props) => `${getColorByGroup(props.group)?.dark}`};
  }
`;

export default function PillStep({
  group,
  size,
  selected,
  onClick,
  setPillContentHover,
  id,
}: PillStepsProps) {
  return (
    <StyledPillStep
      id={id}
      onClick={onClick}
      group={group}
      size={size}
      selected={selected}
      onMouseEnter={() => setPillContentHover(true)}
      onMouseLeave={() => setPillContentHover(false)}
    />
  );
}
