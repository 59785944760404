
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as CatalogIconComponent } from "./catalog-icon.svg";

export const CatalogIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <CatalogIconComponent {...props} />;
};
