import { SVGProps } from 'react';

export const UkFlag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 12"
    fill="none"
    {...props}
  >
    <g clip-path="url(#clip0_2124_1825)">
      <rect
        x="0.05625"
        y="0.43125"
        width="17.8875"
        height="11.1375"
        rx="1.06875"
        fill="white"
        stroke="#949494"
        stroke-width="0.1125"
      />
      <path
        d="M-0.38147 1.96733V3.74484H2.5518L-0.38147 1.96733ZM2.04937 11.8634H6.39402V9.23074L2.04937 11.8634ZM11.6059 9.23119V11.8634H15.9501L11.6059 9.23119ZM-0.38147 8.25516V10.0327L2.55284 8.25516H-0.38147ZM15.9511 0.136597H11.6059V2.76926L15.9511 0.136597ZM18.3814 10.0331V8.25516H15.4466L18.3814 10.0331ZM18.3814 3.74484V1.96733L15.4476 3.74484H18.3814ZM6.39402 0.136597H2.04937L6.39402 2.76926V0.136597Z"
        fill="#2365AB"
      />
      <path
        d="M12.7216 8.25461L17.7834 11.3221C18.0306 11.1017 18.2083 10.8301 18.2994 10.5332L14.539 8.25461H12.7216ZM6.39435 8.25461H5.27847L0.217184 11.3216C0.488725 11.5607 0.836881 11.7316 1.22725 11.811L6.39435 8.67993V8.25461ZM11.6063 3.7443H12.7221L17.7834 0.677286C17.5072 0.434756 17.1571 0.265056 16.7739 0.187918L11.6063 3.31898V3.7443ZM5.27847 3.7443L0.217184 0.677286C-0.0298849 0.897829 -0.207683 1.16938 -0.299316 1.46614L3.46108 3.7443H5.27847Z"
        fill="#CC1F00"
      />
      <path
        d="M10.5635 4.64691V0.136597H7.4364V4.64691H-0.38147V7.35309H7.4364V11.8634H10.5635V7.35309H18.3814V4.64691H10.5635Z"
        fill="#CC1F00"
      />
    </g>
    <defs>
      <clipPath id="clip0_2124_1825">
        <rect y="0.375" width="18" height="11.25" rx="1.125" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
