import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { hideRootElement, showRootElement } from '@innovamat/radiance-utils';
import LayoutSpacesHeader from './LayoutSpaces.header';
import { withBreakpoint } from '../../digital-guides';

const Wrapper = styled.div`
  background-color: white;
  width: 100%;
  height: var(--realVh);
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.dark['01']};
  overflow-y: auto;
`;

const Content = styled.div`
  z-index: 11;
  background-color: white;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 40px 60px;
  ${withBreakpoint(css`
    padding: 40px 24px;
  `)}
`;

type LayoutProps = {
  title: string;
  children: React.ReactNode;
  onGoBack?: () => void;
};

export default function LayoutSpaces({
  title,
  onGoBack,
  children,
}: LayoutProps) {
  useEffect(() => {
    hideRootElement();
    return () => {
      showRootElement();
    };
  }, []);

  const modalRoot = document.getElementById('modal');
  if (!modalRoot) {
    return (
      <Wrapper>
        <LayoutSpacesHeader title={title} onGoBack={onGoBack} />
        <Content>{children}</Content>
      </Wrapper>
    );
  }

  return createPortal(
    <Wrapper>
      <LayoutSpacesHeader title={title} onGoBack={onGoBack} />
      <Content>{children}</Content>
    </Wrapper>,
    modalRoot
  );
}
