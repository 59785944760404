import * as React from 'react';
import type { SVGProps } from 'react';
const SvgInfo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <circle cx={10} cy={10} r={10} fill="#14A395" />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M10 5a1.25 1.25 0 1 0 0 2.5A1.25 1.25 0 0 0 10 5m0 3.75c-.69 0-1.25.56-1.25 1.25v3.75a1.25 1.25 0 1 0 2.5 0V10c0-.69-.56-1.25-1.25-1.25"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgInfo;
