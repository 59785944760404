import { Credentials } from '../types/credentials';
import { LoginRequest, LoginResponse } from '../types/login';
import { AuthError } from './AuthError';

const BASE_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json;charset=utf-8',
};

export const usersLoginRequest = async (
  request: LoginRequest,
  credentials: Credentials
): Promise<LoginResponse> => {
  try {
    const url = `${credentials.apiUsersUrl}/auth/openid-connect/token`;

    const body = JSON.stringify({
      grant_type: 'password',
      client_id: credentials.usersClientId,
      username: request.username,
      password: request.password,
      scope: 'openid profile email',
    });

    const response = await fetch(url, {
      method: 'POST',
      body,
      headers: { ...BASE_HEADERS },
    });

    const data = await response.json();

    if (response.ok) return data;

    throw new AuthError(data);
  } catch (error: any) {
    throw new AuthError({
      title: error.message,
      detail: error.stack,
    });
  }
};

export const usersLoginRequestByAccessToken = async (
  accessToken: string,
  credentials: Credentials
): Promise<LoginResponse> => {
  try {
    const url = `${credentials.apiUsersUrl}/auth/openid-connect/token`;

    const body = JSON.stringify({
      grant_type: 'access_token',
      client_id: credentials.usersClientId,
      token: accessToken,
      scope: 'openid profile email',
    });

    const response = await fetch(url, {
      method: 'POST',
      body,
      headers: { ...BASE_HEADERS },
    });

    const data = await response.json();

    if (response.ok) return data;

    throw new AuthError(data);
  } catch (error: any) {
    throw new AuthError({
      title: error.message,
      detail: error.stack,
    });
  }
};

export const fromExternalLoginRequest = async (
  request: string,
  grantType: 'azure_token' | 'google_token',
  credentials: Credentials
): Promise<LoginResponse> => {
  try {
    const url = `${credentials.apiUsersUrl}/auth/openid-connect/token`;
    const body = JSON.stringify({
      grant_type: grantType,
      client_id: credentials.usersClientId,
      token: request,
    });

    const response = await fetch(url, {
      method: 'POST',
      body,
      headers: { ...BASE_HEADERS },
    });

    const data = await response.json();

    if (response.ok) return data;

    throw new AuthError(data);
  } catch (error: any) {
    throw new AuthError({
      title: error.message,
      detail: error.stack,
    });
  }
};
