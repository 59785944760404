import * as React from 'react';
import type { SVGProps } from 'react';
const SvgImportCsv = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g fill="#757575" clipPath="url(#import_csv_svg__a)">
      <path d="M16.667 15.833v-7.5a.833.833 0 0 0-.834-.833h-15A.833.833 0 0 0 0 8.333v7.5a.833.833 0 0 0 .833.834h15a.833.833 0 0 0 .834-.834m-11.25-1.766a.525.525 0 0 1 0 1.041 3.025 3.025 0 0 1 0-6.041.525.525 0 0 1 0 1.041 1.985 1.985 0 0 0-1.485 3.427 1.98 1.98 0 0 0 1.485.532m2.441-2.925 1.534 1.016a1.617 1.617 0 0 1-.892 2.95H7.083a.525.525 0 0 1 0-1.041H8.5a.567.567 0 0 0 .308-1.042l-1.533-1.017a1.616 1.616 0 0 1-.24-2.473 1.6 1.6 0 0 1 1.132-.468h1.416a.525.525 0 0 1 0 1.041H8.167a.567.567 0 0 0-.567.559.57.57 0 0 0 .258.475m6.409-.309a6.8 6.8 0 0 1-1.35 4.067.525.525 0 0 1-.834 0 6.8 6.8 0 0 1-1.35-4.067v-1.25a.517.517 0 0 1 1.034 0v1.25c0 .866.193 1.72.566 2.5a.2.2 0 0 0 .325 0 5.7 5.7 0 0 0 .575-2.5v-1.25a.517.517 0 0 1 1.034 0z" />
      <path d="M19.758 4.758 15.242.242A.83.83 0 0 0 14.658 0H5a1.667 1.667 0 0 0-1.667 1.667v4.791a.21.21 0 0 0 .209.209h1.25A.21.21 0 0 0 5 6.458V2.083a.417.417 0 0 1 .417-.416h8.541a.21.21 0 0 1 .209.208v2.292a1.667 1.667 0 0 0 1.666 1.666h2.292a.21.21 0 0 1 .208.209v11.875a.417.417 0 0 1-.416.416H5.208A.21.21 0 0 1 5 18.125v-.417a.21.21 0 0 0-.208-.208h-1.25a.21.21 0 0 0-.209.208v.625A1.666 1.666 0 0 0 5 20h13.333A1.667 1.667 0 0 0 20 18.333V5.342a.83.83 0 0 0-.242-.584" />
    </g>
    <defs>
      <clipPath id="import_csv_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgImportCsv;
