import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import {
  AnnouncementPanel,
  Column,
  Loader,
  TableComponent,
  Typography,
} from '@innovamat/glimmer-components';
import { useCourses } from '@innovamat/glow-api-client';

import { useReadCsvFile } from '../hooks/use-read-csv-file';
import { useCheckStudentRow } from '../hooks/use-check-student-row';
import { useImportCSV } from '../providers';
import { ERRORS, type StudentValidation } from '../types';

import { ConfirmValidationModal } from './confirm-validation-modal';
import { FooterSticky } from './footer-sticky';
import { ImportErrorPage } from './import-error-page';
import { RevalidateButton } from './revalidate-button';
import { ProgressBar } from './progress-bar';
import { useCheckStudentsForEmailAndSisDuplicates } from '../hooks/use-check-students-for-email-and-sis-duplicates';
import { useCheckStudentsValidationsHaveConflict } from '../hooks/use-check-students-validations-have-conflict';

const Title = styled(Typography.Subtitle1)<{ hasError: boolean }>`
  color: ${({ theme, hasError }) =>
    hasError
      ? theme.tokens.color.alias.cm.text['text-error'].value
      : 'inherit'};
`;

const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 5rem;
`;

const List = styled('ul')`
  list-style-position: outside;
  padding-left: 1.5rem;
  margin-top: 0rem;
`;

const ListItem = styled('li')`
  display: list-item;
  list-style-type: disc !important;
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

type Props = {
  csvFile: File;
  onUploadFile: () => void;
  onGoBack: () => void;
};

const ErrorText = styled('span')`
  display: inline;
  color: ${({ theme }) => theme.tokens.color.alias.cm.text['text-error'].value};
`;

function StudentsValidation({
  csvFile,
  onUploadFile,
  onGoBack,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { csvData } = useReadCsvFile({ csvFile });

  const { organizationId, region } = useImportCSV();
  const [showModal, setShowModal] = useState(false);

  const { courses } = useCourses({ organizationId, regionCode: region });

  const { duplicates, isChecking } =
    useCheckStudentsForEmailAndSisDuplicates(csvData);
  const hasDuplicates =
    duplicates.sis_id.length > 0 || duplicates.email.length > 0;

  const {
    csvHasErrors,
    onClear,
    setStudents: setStudentsValidations,
    students: studentsValidations,
  } = useCheckStudentRow({
    csvData: csvData,
    shouldValidate: !hasDuplicates && !isChecking,
  });

  const hasConflicts = useCheckStudentsValidationsHaveConflict(
    studentsValidations.filter(Boolean) as StudentValidation[]
  );

  const canContinue =
    studentsValidations.every(
      (student) => student?.errorType !== ERRORS.NOT_VALIDATED
    ) && !hasConflicts;

  const columns: Column<StudentValidation>[] = [
    {
      id: 'rowNumber',
      subLabel: t('students.import.column.row-number'),
      width: 5,
      render: (_, { rowNumber }) => {
        return (
          <Typography.Body2>
            {t('students.import.table.row-number', { rowNumber })}
          </Typography.Body2>
        );
      },
    },
    {
      id: 'studentName',
      subLabel: t('students.import.column.studentName'),
      width: 10,
      render: (_, { studentName, errorType }) => {
        return (
          <Title hasError={errorType === ERRORS.NOT_VALIDATED}>
            {studentName}
          </Title>
        );
      },
    },
    {
      id: 'sisId',
      subLabel: t('students.import.column.sis-id'),
      width: 10,
      render: (_, { sisId }) => {
        return <Typography.Body2>{sisId}</Typography.Body2>;
      },
    },
    {
      id: 'errorType',
      subLabel: t('students.import.column.error-type'),
      width: 10,
      render: (_, { errorType }) => {
        return (
          <Typography.Body2>
            {t(`students.import.error-type.${errorType}`)}
          </Typography.Body2>
        );
      },
    },
    {
      id: 'overwrites',
      subLabel: t('students.import.column.overwrites'),
      width: 100,
      render: (
        _,
        { overwrites = {}, errorType, sisId: currentSisId, email: currentEmail }
      ) => {
        if (!overwrites && errorType === ERRORS.NOT_VALIDATED) {
          return (
            <RevalidateButton
              email={currentEmail}
              setStudents={setStudentsValidations}
              sisId={currentSisId}
            />
          );
        }

        if (!overwrites) return null;

        const { classroom, courseOrder, email, firstName, lastName, sisId } =
          overwrites;

        const courseName = courses.find(
          (course) => course.order === courseOrder
        )?.name;

        const translationKey = `students.import.table.overwrites${
          errorType === ERRORS.SIS_ID_EXISTS ? '' : '.email' // TODO CHANGE THE KEY FOR sis_id ERROR BEFORE MERGING
        }`;

        return (
          <Typography.Body2>
            <Trans
              i18nKey={translationKey}
              components={{ error: <ErrorText /> }}
              values={{
                classroom,
                course: courseName,
                email,
                firstName,
                lastName,
                sisId,
              }}
            />
          </Typography.Body2>
        );
      },
    },
  ];

  const handleContinue = (): void => {
    setShowModal(true);
  };

  function countUniqueEntries(
    validations: Array<StudentValidation | null>
  ): number {
    const uniqueRowNumbers = new Set();
    let nullCount = 0;

    validations.forEach((item: StudentValidation | null) => {
      if (item === null) {
        nullCount += 1;
      } else {
        uniqueRowNumbers.add(item.rowNumber);
      }
    });

    return uniqueRowNumbers.size + nullCount;
  }

  const numberOfValidatedStudents = countUniqueEntries(studentsValidations);
  const totalOfStudents = csvData.length - 1;

  const percentage = Math.round(
    (numberOfValidatedStudents / totalOfStudents) * 100
  );

  const rows = studentsValidations.filter(Boolean) as StudentValidation[];

  const processFinished = numberOfValidatedStudents === totalOfStudents;

  const processFinishedWithErrors = processFinished && rows.length > 0;
  const processFinishedWithoutErrors = processFinished && rows.length === 0;

  useEffect(() => {
    return () => {
      onClear();
    };
  }, []);

  useEffect(() => {
    if (processFinishedWithoutErrors) {
      onUploadFile();
    }
  }, [onUploadFile, processFinishedWithoutErrors]);

  if (isChecking) {
    return (
      <LoaderWrapper>
        <Loader size="lg" />
        <Typography.Body1>
          {t('school.import_csv.validating_duplicates')}
        </Typography.Body1>
      </LoaderWrapper>
    );
  }

  if (!isChecking && hasDuplicates) {
    const { sis_id: sisIdDuplicates, email: emailDuplicates } = duplicates;

    return (
      <>
        <AnnouncementPanel
          text={t('school.import_csv.uploaded.duplicates_error')}
          canClose={false}
          type="error"
        />

        {sisIdDuplicates.length > 0 && (
          <>
            <Typography.Subtitle1>
              {t('students.import.duplicateSisIds.title')}:
            </Typography.Subtitle1>

            <List>
              {sisIdDuplicates.map((sisId) => (
                <ListItem key={sisId}>
                  <Typography.Body1>
                    {t('students.import.listItem.duplicateSisId.listItem')}:{' '}
                    {sisId}
                  </Typography.Body1>
                </ListItem>
              ))}
            </List>
          </>
        )}

        {emailDuplicates.length > 0 && (
          <>
            <Typography.Subtitle1>
              {t('students.import.duplicateEmails.title')}:
            </Typography.Subtitle1>
            <List>
              {emailDuplicates.map((email) => (
                <ListItem key={email}>
                  <Typography.Body1>
                    {t('students.import.duplicateEmail.listItem')}: {email}
                  </Typography.Body1>
                </ListItem>
              ))}
            </List>
          </>
        )}
      </>
    );
  }

  if (csvHasErrors) {
    return <ImportErrorPage onGoBack={onGoBack} />;
  }

  return (
    <>
      <ProgressBar percentage={percentage === 0 ? 1 : percentage} />

      {rows.length > 0 && (
        <TableComponent id="validations-table" columns={columns} rows={rows} />
      )}

      {processFinishedWithErrors && (
        <FooterSticky
          canContinue={canContinue}
          handleContinue={handleContinue}
          hasStudentsWithConflicts={hasConflicts}
          onGoBack={onGoBack}
        />
      )}
      {showModal && (
        <ConfirmValidationModal
          onClose={() => setShowModal(false)}
          showModal={showModal}
          onConfirm={onUploadFile}
        />
      )}
    </>
  );
}

export { StudentsValidation };
