import ContentLoader from 'react-content-loader';
import { TableComponent, useGlimmerTheme } from '@innovamat/glimmer-components';

import styled from '@emotion/styled';
import { SchoolStudent } from '../../types/student';
import { skeletonTableStyles } from '../../../../components/common-table-components/table-styles';
import { useGetColumns } from '../../hooks/use-get-columns';
import { useMemo } from 'react';

const Container = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  align-content: center;
`;

const CellSkeleton = ({ isLastRow }: { isLastRow: boolean }): JSX.Element => {
  const theme = useGlimmerTheme();
  const backgroundColor = isLastRow
    ? theme.tokens.color.global.neutral.neutral100.value + 50
    : theme.tokens.color.global.neutral.neutral100.value;

  return (
    <Container>
      <ContentLoader
        speed={2}
        width="100%"
        height="24px"
        backgroundColor={backgroundColor}
        foregroundColor={backgroundColor}
      >
        <rect x="0" y="0" width="100%" height="24px" rx="8" />
      </ContentLoader>
    </Container>
  );
};

function generateRows(count: number): SchoolStudent[] {
  const result: SchoolStudent[] = [];

  for (let i = 0; i < count; i++) {
    result.push({
      id: (i + 1).toString(),
      alias: '',
      avatar: '',
      classroomDeletedAt: '',
      classroomId: '',
      classroomName: '',
      courseName: '',
      courseOrder: 0,
      deletedAt: '',
      firstName: '',
      lastName: '',
    });
  }

  return result;
}

function SchoolStudentsTableSkeleton(): JSX.Element {
  const theme = useGlimmerTheme();
  const rowsNumber = 4;
  const rows = generateRows(rowsNumber);
  const { columns } = useGetColumns();

  const skeletonColumns = useMemo(() => {
    return columns.map((column) => ({
      ...column,
      render: (_: any, row: SchoolStudent) => {
        const id = row.id;
        if (!id) return <></>;
        return <CellSkeleton isLastRow={id.toString() === `${rowsNumber}`} />;
      },
    }));
  }, [columns]);

  return (
    <TableComponent
      columns={skeletonColumns}
      id="classroom-teachers-table"
      key="teachers-table-skeleton"
      rows={rows}
      sx={skeletonTableStyles(theme)}
    />
  );
}

export { SchoolStudentsTableSkeleton };
