import { IconType } from '@innovamat/glimmer-icons';
import { useGlimmerTheme } from '../theme';

export const SEMANTIC_TYPE = {
  DEFAULT: 'default',
  ERROR: 'error',
  IMPORTED: 'imported',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
} as const;

type SemanticType = (typeof SEMANTIC_TYPE)[keyof typeof SEMANTIC_TYPE];

type ReturnType = {
  getBackground: (type?: SemanticType) => string;
  getColor: (type?: SemanticType) => string;
  getIcon: (type: SemanticType) => IconType;
  SEMANTIC_TYPE: typeof SEMANTIC_TYPE;
};

export function useSemantic(): ReturnType {
  const theme = useGlimmerTheme();

  const bgToken = theme.tokens.color.alias.cm.bg;
  const iconToken = theme.tokens.color.alias.cm.icon;

  const defaultColor = theme.tokens.color.global.neutral.neutral500.value;
  const defaultBackground =
    theme.tokens.color.alias.cm.surface['surface-secondary'].value;

  const semanticIcons = {
    default: 'InfoIcon',
    error: 'ErrorIcon',
    imported: 'InfoIcon',
    info: 'InfoIcon',
    success: 'CheckIcon',
    warning: 'WarningIcon',
  } as const;

  function getBackground(type?: SemanticType): string {
    if (!type || type === SEMANTIC_TYPE.DEFAULT) {
      return defaultBackground;
    }

    return bgToken[`bg-${type}`].value;
  }

  function getColor(type?: SemanticType): string {
    if (!type || type === SEMANTIC_TYPE.DEFAULT) {
      return defaultColor;
    }

    return iconToken[`icon-${type}`].value;
  }

  function getIcon(type: SemanticType): IconType {
    if (!type || type === SEMANTIC_TYPE.DEFAULT) {
      return 'InfoIcon';
    }

    return semanticIcons[type] as IconType;
  }

  return { getBackground, getColor, getIcon, SEMANTIC_TYPE };
}

export type { SemanticType };
