import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNotes = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M17.504 11.296 11.4 17.4l-3 .6.6-3 6.104-6.104a1.693 1.693 0 0 1 2.394 0l.006.007a1.69 1.69 0 0 1 0 2.393M6.417 16.006H3.084A1.056 1.056 0 0 1 2 14.923V4.167a1.125 1.125 0 0 1 1.083-1.16h10.832A1.125 1.125 0 0 1 15 4.167V6.34M4.6 2v3.6M8.2 2v3.6M11.8 2v3.6"
    />
  </svg>
);
export default SvgNotes;
