import { useRef, useEffect, RefObject } from 'react';

interface HTMLEventTypes extends HTMLElementEventMap {
  eventFromWebview: Event;
}

export function useEventListener<
  K extends keyof HTMLEventTypes,
  T extends HTMLElement = HTMLDivElement
>(
  eventName: K,
  handler: (event: HTMLEventTypes[K]) => void,
  element?: RefObject<T>
): void {
  const savedHandler = useRef<(event: HTMLEventTypes[K]) => void>();

  useEffect(() => {
    // Check if running on the server by checking if window is defined
    if (typeof window === 'undefined') {
      return undefined; // Exit the effect if it's server-side to prevent errors
    }

    // Use the provided element or default to window if not provided
    const targetElement: T | Window = element?.current || window;

    // Ensure targetElement supports addEventListener, this check is mostly
    // for completeness as window and HTMLElements support this method
    if (!targetElement.addEventListener) {
      return undefined;
    }

    if (savedHandler.current !== handler) {
      savedHandler.current = handler;
    }

    const eventListener = (event: Event): void => {
      // Call the saved handler if it exists
      savedHandler?.current?.(event as HTMLEventTypes[K]);
    };

    // Add the event listener
    targetElement.addEventListener(eventName, eventListener);

    // Return a cleanup function that removes the event listener
    return () => {
      targetElement.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element, handler]);
}
