import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

import Card from '../../Card';

const SlideDown = keyframes`
 0%{
    transform: translateY(-50px);
    opacity: 0;
  }   
  10%{
    opacity: 0;
  }
  100%{  
    transform: translateY(0px);
    opacity: 1;
  }
`;

const SlideUp = keyframes`
 0%{
    transform: translateY(0px);
  } 
  70%{
    opacity: 0;
  }

  100%{  
    transform: translateY(-50px);
    opacity: 0;
  }
`;

type ContainerProps = {
  isOpened: boolean;
};

const Container = styled(Card)<ContainerProps>`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.dark['06']};
  bottom: 0px;
  animation: ${({ isOpened }) => (isOpened ? SlideDown : SlideUp)} 0.2s
    cubic-bezier(0.46, 0.03, 0.52, 0.96);
`;

const ContentWrapper = styled('div')`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 8px;
  width: 100%;
  height: 100%;
`;

const CounterWrapper = styled('h5')`
  background-color: ${({ theme }) => theme.colors.white};
  width: 30px;
  height: 20px;
  text-align: center;
  border-radius: ${({ theme }) => theme.borders.radius.sm};
`;

export { Container, ContentWrapper, CounterWrapper };
