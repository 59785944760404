
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as AddUpIconComponent } from "./add-up-icon.svg";

export const AddUpIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <AddUpIconComponent {...props} />;
};
