import { Icon, Typography, getTypography } from '@innovamat/glimmer-components';
import styled from '@emotion/styled';
import { PreprInnerHtml } from '../PreprInnerHtml';
import { IconType } from '@innovamat/glimmer-icons';
import { useSmartGuides } from '../../context/SmartGuidesProvider';
import { ChapterContent } from '../../types/SmartGuide';
import {
  PrintableBody2,
  PrintableSubtitle2,
} from '../../printable-styles/printable-typography';

const Title = styled(PrintableSubtitle2)`
  color: ${({ theme }) => theme.tokens.color.alias.cm.text.text.value};
`;

const Text = styled(PrintableBody2)`
  margin-top: 8px;
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

const IconContainer = styled.div`
  width: 24px;
  min-width: 24px;
  display: grid;
  place-items: center;
  margin: 8px 4px 0 0;
`;

const VideoThemeContainer = styled.div`
  display: flex;
  align-items: flex-start;

  max-width: 100%;

  @media print {
    break-inside: avoid;
  }
`;

const VideoThemeText = styled.div`
  padding: 8px 16px;
  border-left: 1px solid
    ${({ theme }) => theme.tokens.color.alias.cm.text.text.value};

  @media print {
    padding: 4px 8px;
    color: #000000;
  }
`;

type Props = {
  chapterContent: ChapterContent[];
};

export function VideoThemes({ chapterContent }: Props) {
  const { t } = useSmartGuides();
  return (
    <>
      {chapterContent?.map((chapterContent: ChapterContent, index: number) => {
        return (
          <VideoThemeContainer key={index}>
            <IconContainer>
              <Icon size="M" icon={chapterContent.icon as IconType} />
            </IconContainer>
            <VideoThemeText>
              <Title>{t(chapterContent.key)}</Title>
              <Text>
                <PreprInnerHtml text={chapterContent.content} />
              </Text>
            </VideoThemeText>
          </VideoThemeContainer>
        );
      })}
    </>
  );
}
