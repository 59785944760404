import styled, { css } from 'styled-components';

export const FloatWrapper = styled.div`
  padding: 0;
  position: relative;
  width: 100%;
`;

export const Label = styled.label<{
  active: boolean;
}>`
  color: ${({ theme }) => theme.colors.mintGray};
  font-size: ${({ theme }) => theme.sizes.normal};
  position: absolute;
  transform: translate(0, 8px) scale(1);
  transform-origin: top left;
  transition: all 0.1s ease-in-out;

  ${({ active }) => {
    if (active) {
      return css`
        transform: translate(0, -10px) scale(0.75);
      `;
    }
    return css``;
  }}
`;

export const Input = styled.input<{
  isPasswordCorrect: boolean;
}>`
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.navyBlue};
  color: ${({ theme }) => theme.colors.navyBlue};
  font-size: ${({ theme }) => theme.sizes.normal};
  outline: 0;
  padding: 10px 0 5px;
  width: 100%;

  ${({ isPasswordCorrect }) => {
    if (isPasswordCorrect) {
      return css``;
    }
    return css`
      color: red;
    `;
  }}
`;
