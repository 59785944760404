import { useTranslation } from 'react-i18next';
import { Chip, Typography } from '@innovamat/glimmer-components';
import { DateTime } from 'luxon';

import { dates } from '@innovamat/radiance-utils';
import styled from '@emotion/styled';

type Props = {
  isInvitation?: boolean;
  lastLogin?: string;
};

const Date = styled(Typography.Caption)`
  color: ${(props) =>
    props.theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const formatDate = (date: string): string => {
  if (!date) return '';

  return DateTime?.fromFormat(date, 'yyyy-MM-dd HH:mm:ss').toFormat(
    dates.getDateFormat()
  );
};

const hasMoreThanAMonthPassed = (dateString: string): boolean => {
  const inputDate = DateTime.fromSQL(dateString, { zone: 'utc' });

  const datePlusOneMonth = inputDate.plus({ months: 1 });

  const now = DateTime.utc();

  return now > datePlusOneMonth;
};

export function StudentStatusCell({
  isInvitation,
  lastLogin,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const getText = (): string => {
    if (!lastLogin) {
      return t('dates.never', 'Nunca');
    }

    const hasMoreThanAMonth = hasMoreThanAMonthPassed(lastLogin);

    if (hasMoreThanAMonth) {
      return t('dates.moreThanMonth', 'Más de un mes');
    }

    return formatDate(lastLogin ?? '');
  };

  return (
    <Date>
      {isInvitation ? (
        <StatusContainer>
          <Chip text={t('generic.status.pending', 'Pendiente')} />
        </StatusContainer>
      ) : (
        <StatusContainer>
          <Chip
            text={t('generic.status.activeAccess', 'Acceso activo')}
            type="success"
          />
          <span>
            {t('dates.lastActivity', 'Ultima actividad')}: {getText()}
          </span>
        </StatusContainer>
      )}
    </Date>
  );
}
