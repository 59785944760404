import * as React from 'react';
import type { SVGProps } from 'react';
const SvgEditFamily = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M18.858 12.39 12.5 18.75l-3.125.625L10 16.25l6.358-6.36a1.763 1.763 0 0 1 2.494 0l.006.008a1.76 1.76 0 0 1 0 2.493M2.5 3.125a2.5 2.5 0 1 0 5 0 2.5 2.5 0 0 0-5 0M9.375 11.25a4.375 4.375 0 0 0-8.75 0v1.875H2.5l.625 6.25h3.75l.625-6.25h1.875zM11.563 2.813a2.188 2.188 0 1 0 4.375 0 2.188 2.188 0 0 0-4.376 0"
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M17 7.5a3.75 3.75 0 0 0-6.07-.592"
    />
  </svg>
);
export default SvgEditFamily;
