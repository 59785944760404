import { Icon } from '@innovamat/innova-components';
import { Button } from '@innovamat/glimmer-components';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import defaultAppletThumbnail from '../../assets/images/defaultAppletThumbnailGray.png';
import styles from './activities-slider.module.scss';
import EndingDialog from './ending-dialog';
import Star from '../star';
import { useWebviewBridge } from '@innovamat/webview-bridge';
import { ImageComponent } from '@innovamat/glimmer-components';
import { Applet } from '@innovamat/glow-api-client';
import { sliderResponsiveSettings } from '../../../contents';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { normalizeImgSource } from '@innovamat/ga-features';

type Props = {
  weeklyPracticeActivities?: any[] | null; // TODO: Should be Applet[]
  handleStartPractice: () => void;
  loading: boolean;
  nextActivityIndex: number;
  backgroundImg: string;
  handleStartApplet: (applet: Applet) => void;
  weeklyPracticeFinished: boolean;
  courseOrder: number;
  firstName: string;
};

const ActivitiesSlider = (props: Props) => {
  const {
    weeklyPracticeActivities,
    handleStartPractice,
    loading,
    nextActivityIndex,
    backgroundImg,
    handleStartApplet,
    weeklyPracticeFinished,
    courseOrder,
    firstName,
  } = props;

  const { t } = useTranslation();

  const onStartApplet = (
    _e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    applet: Applet
  ) => {
    !applet?.completed && handleStartApplet(applet);
  };

  const getBlurStyle = (
    index: number,
    isRightLine?: boolean,
    isCompleted?: boolean,
    hasThumbnail?: boolean
  ) => {
    if (hasThumbnail && index !== nextActivityIndex) return '';
    if (!index && !weeklyPracticeActivities && !isRightLine) {
      return styles.startBlur;
    }
    if (index && !weeklyPracticeActivities) {
      return styles.inactiveBlur;
    }
    if (weeklyPracticeActivities) {
      if (isRightLine && index === nextActivityIndex) {
        return styles.inactiveBlur;
      }
      if (index <= nextActivityIndex || isCompleted) {
        return styles.completedBlur;
      }
      return styles.inactiveBlur;
    }
    return styles.inactiveBlur;
  };

  const getCircleStyle = (index: number) => {
    if (
      index < nextActivityIndex ||
      index > nextActivityIndex ||
      !weeklyPracticeActivities
    ) {
      return styles.miniCircle;
    }
    return styles.bigCircle;
  };

  const activities = weeklyPracticeActivities || [...Array(10)];

  const practiceSlider = useRef<Slider>(null);
  const currentSlideRef = useRef<HTMLDivElement>(null);
  const [endingRef, setEndingRef] = useState<any>(null); // We store the ref into state to get update when setting ref

  const onSetEndingRef = useCallback((ref: any) => {
    setEndingRef(ref);
  }, []);

  const { isInWebview } = useWebviewBridge();

  useEffect(() => {
    if (nextActivityIndex !== 0 && !loading) {
      if (isInWebview) {
        practiceSlider.current?.slickGoTo(nextActivityIndex);
      } else {
        if (weeklyPracticeFinished && endingRef) {
          endingRef.scrollIntoView({
            inline: 'end',
            behavior: 'smooth',
            block: 'end',
          });
        } else if (!weeklyPracticeFinished) {
          currentSlideRef.current?.scrollIntoView({
            inline: 'center',
            behavior: 'smooth',
            block: 'center',
          });
        }
      }
    }
  }, [
    nextActivityIndex,
    weeklyPracticeFinished,
    endingRef,
    loading,
    isInWebview,
  ]);

  const [starsCount, setStarsCount] = useState<{
    [key: number]: number;
  } | null>(null);
  const [starsValue, setStarsValue] = useState<{
    [key: number]: number;
  } | null>(null);

  useEffect(() => {
    if (weeklyPracticeFinished) {
      const count = {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
      };
      weeklyPracticeActivities?.forEach((activity) => {
        if (activity.stars) count[activity.stars] += 1;
      });
      setStarsCount(count);
      setStarsValue({
        0: 0 * count[0],
        1: 1 * count[1],
        2: 2 * count[2],
        3: 3 * count[3],
      });
    }
  }, [weeklyPracticeFinished]);

  return (
    <div
      className={styles.background}
      style={{
        backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('${backgroundImg}')`,
      }}
    >
      <Slider
        dots={false}
        infinite={false}
        speed={500}
        slidesToShow={5}
        slidesToScroll={2}
        responsive={sliderResponsiveSettings}
        className={`${styles.slider} ${
          isInWebview ? '' : styles.sliderScroll
        } ${weeklyPracticeFinished && styles.sliderComplete}`}
        arrows={false}
        draggable={false}
        ref={practiceSlider}
      >
        {activities.map((activity, index) => (
          <div
            className={styles.activityDivisor}
            ref={index === nextActivityIndex ? currentSlideRef : undefined}
            key={index}
          >
            <div
              className={`
                ${styles.activityContainer}
                ${getBlurStyle(index, false, activity?.completed, !!activity)}
                ${
                  index === nextActivityIndex && weeklyPracticeActivities
                    ? styles.currentActivityContainer
                    : ''
                }
                ${
                  activity?.sceneName && !activity.completed
                    ? styles.selectableActivity
                    : ''
                }
                `}
            >
              {!weeklyPracticeActivities && !index && (
                <Button
                  onClick={!loading ? handleStartPractice : () => {}}
                  loading={loading}
                >
                  {t('weeklyPractice.button.start')}
                </Button>
              )}
              {weeklyPracticeActivities && (
                <ThumbnailContainer>
                  <Thumbnail
                    src={
                      weeklyPracticeActivities[index].data?.thumbnail ||
                      normalizeImgSource(defaultAppletThumbnail)
                    }
                    alt={`thumb-${index}`}
                    onClick={(e) => onStartApplet(e, activity)}
                    completed={activity?.completed}
                  />
                </ThumbnailContainer>
              )}
              {activity?.completed && (
                <StarsContainer>
                  <Star size={40} success={activity.stars >= 1} opacity />
                  <Star size={40} success={activity.stars >= 2} opacity />
                  <Star size={40} success={activity.stars >= 3} opacity />
                </StarsContainer>
              )}
            </div>
            <div
              className={`${styles.lineContainer}  ${
                (!weeklyPracticeActivities && !index) ||
                index !== nextActivityIndex
                  ? styles.fixMarginTop
                  : ''
              }
              `}
            >
              <div
                className={`${styles.connectorLine} ${getBlurStyle(index)} ${
                  styles.leftLine
                } ${!index ? styles.hidden : ''}`}
              />
              <div
                className={`
                  ${styles.circle} ${getCircleStyle(index)}
                  ${getBlurStyle(index, false, activity?.completed)}
                  ${index === nextActivityIndex ? styles.clickEffect : ''}
                `}
                onClick={(e) => onStartApplet(e, activity)}
              >
                {activity?.completed && (
                  <Icon icon="Check" containerClassName={styles.checkIcon} />
                )}
                {weeklyPracticeActivities &&
                  index === nextActivityIndex &&
                  !activity?.completed && (
                    <Icon icon="Play2" style={{ width: '100%' }} />
                  )}
              </div>
              <div
                className={`${styles.connectorLine} ${getBlurStyle(
                  index,
                  true
                )} ${styles.rightLine} ${
                  index === activities.length - 1 ? styles.hidden : ''
                }`}
              />
            </div>
          </div>
        ))}
        {weeklyPracticeFinished &&
          weeklyPracticeActivities &&
          starsCount &&
          starsValue && (
            <>
              <Helper id="helper" ref={onSetEndingRef} />
              <EndingDialog
                starsCount={starsCount}
                starsValue={starsValue}
                starsTotal={weeklyPracticeActivities.length * 3}
                courseOrder={courseOrder}
                firstName={firstName}
              />
            </>
          )}
      </Slider>
    </div>
  );
};

export default ActivitiesSlider;

const Thumbnail = styled(ImageComponent)<{ completed: boolean }>`
  cursor: ${(props) => !props.completed && 'pointer'};
  width: 100%;
  height: 100%;
  filter: ${(props) => props.completed && 'brightness(0.3) blur(1.5px)'};
`;

const StarsContainer = styled.div`
  position: absolute;
  display: flex;
  gap: 8px;
`;

const Helper = styled.div`
  right: -450px;
  position: absolute;
`;

const ThumbnailContainer = styled.div`
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;
