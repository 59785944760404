import * as React from 'react';
import type { SVGProps } from 'react';
const SvgArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g clipPath="url(#arrow_right_svg__a)">
      <path
        fill="#333"
        d="M14.394 10.017a1.44 1.44 0 0 1-.487 1.081l-6.97 6.132a1.154 1.154 0 0 1-1.525-1.733l6.095-5.362a.156.156 0 0 0 0-.235L5.412 4.538a1.154 1.154 0 1 1 1.525-1.733l6.967 6.13a1.44 1.44 0 0 1 .49 1.082"
      />
    </g>
    <defs>
      <clipPath id="arrow_right_svg__a">
        <path fill="#fff" d="M2.5 17.5v-15h15v15z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgArrowRight;
