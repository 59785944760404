import styled from '@emotion/styled';

import Image from './Image';
import { InnerHtmlParser } from './InnerHtmlParser';

const Item = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

const VariationImage = styled(Image)`
  width: 100%;
`;

const Title = styled.span`
  font-family: ${({ theme }) => theme.fonts.robotoMedium};
  font-weight: 500;
  line-height: 16px;
  font-size: ${({ theme }) => theme.sizes.normal};
`;

const Description = styled.div`
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.dark['02']};
  margin-top: 4px;
  margin-bottom: 16px;
  font-size: ${({ theme }) => theme.sizes.normal};
  line-height: 24px;
`;

type Props = {
  title: string | null | undefined;
  description: string | undefined;
  // TODO -> any A React.NodeElement
  image: string | any | undefined;
};

export default function VariationItem({ title, description, image }: Props) {
  const isAnImageUrl = typeof image === 'string';
  return (
    <Item>
      <div>
        <Title>{title}</Title>
        <Description>
          <InnerHtmlParser
            text={{
              body: description || '',
              id: 'variations',
            }}
          />
        </Description>
      </div>
      {isAnImageUrl ? <VariationImage src={image} alt="" /> : image}
    </Item>
  );
}
