
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as FileZipIconComponent } from "./file-zip-icon.svg";

export const FileZipIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <FileZipIconComponent {...props} />;
};
