import { generateRandomString, validators } from '@innovamat/radiance-utils';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Input,
  RadioGroup,
  Select,
  snack,
} from '@innovamat/glimmer-components';
import styled from '@emotion/styled';
import type { CompleteUserInvitation } from '../invite-parents-modal/types';
import { useOrganization } from '../../hooks/use-organization';
import {
  GlowApiError,
  useCompleteParentInvitationMutation,
  useCompleteUserInvitationMutation,
} from '@innovamat/glow-api-client';
import { checkIfIsUSOrganization } from '../../utils/region';
import { QueryClient, useQueryClient } from '@tanstack/react-query';

const CompleteInvitationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

type Body = {
  firstName: string;
  lastName: string;
  email: string | undefined;
  password: string;
  invitationToken: string | undefined;
  invitationId: string | undefined;
  phoneNumber: null;
  language?: string;
};

type Props = {
  showModal: boolean;
  invitation: CompleteUserInvitation | undefined;
  onClose: () => void;
  onSuccess: () => void;
  type: 'user' | 'parent';
};

const initForm = {
  name: '',
  surname: '',
  password: '',
  language: '',
};

export function CompleteInvitationModal({
  showModal,
  onClose,
  type,
  invitation,
  onSuccess,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { organization } = useOrganization();
  const [form, setForm] = useState(initForm);
  const [loginMode, setLoginMode] = useState<string | undefined>(undefined);
  const queryClient = useQueryClient();
  const [isInvalidatingCache, setIsInvalidatingCache] = useState(false);
  const { mutate: mutateParent, isPending: loadingParentInvitation } =
    useCompleteParentInvitationMutation<GlowApiError>();
  const { mutate: mutateUser, isPending: loadingUserInvitation } =
    useCompleteUserInvitationMutation<GlowApiError>();

  useEffect(() => {
    setForm(initForm);
    setLoginMode(undefined);
  }, [showModal]);

  const handleUserComplete = async (body: Body): Promise<void> => {
    mutateUser(
      {
        body,
      },
      {
        onSuccess: async () => {
          setIsInvalidatingCache(true);
          await queryClient.refetchQueries({ queryKey: ['Students'] });
          setIsInvalidatingCache(false);
          snack.success(t('students.completeInvitationModal.success'));
          onSuccess();
        },
        onError: handleShowError,
      }
    );
  };

  const handleParentComplete = async (body: Body): Promise<void> => {
    mutateParent(
      {
        body,
      },
      {
        onSuccess: async () => {
          setIsInvalidatingCache(true);
          await queryClient.refetchQueries({ queryKey: ['Students'] });
          setIsInvalidatingCache(false);
          snack.success(t('students.completeInvitationModal.success'));
          onSuccess();
        },
        onError: handleShowError,
      }
    );
  };

  const handleShowError = (errors: GlowApiError): void => {
    if (errors) {
      errors.response.errors.forEach((error) => {
        if (error.extensions?.response?.['status'] === 409) {
          snack.error(
            t('students.inviteParentsModal.error.roleConflict', {
              email: error.extensions?.exception?.['emails'],
            })
          );
        } else {
          snack.error(t('common.toast.error'));
        }
      });
      return;
    }
  };

  const { availableLanguages } = useOrganization();

  const handleSend = (): void => {
    const body: Body = {
      firstName: form.name,
      lastName: form.surname,
      email: invitation?.email,
      password: form.password,
      invitationToken: invitation?.token,
      invitationId: invitation?.id,
      phoneNumber: null,
    };

    if (type === 'parent') {
      body['language'] = form.language;
    }

    if (type === 'user') {
      handleUserComplete(body);
    } else {
      handleParentComplete(body);
    }
  };

  const handleClose = (): void => {
    setLoginMode(undefined);
    onClose();
  };

  const handleInputChange = (name: string, value: string): void => {
    setForm((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const isValid = () => {
    return (
      !Object.keys(form).some((key) => {
        const element = form[key as keyof typeof form];
        if (key === 'language' && type === 'user') return false;
        return element === '' || element === undefined;
      }) && !showErrorValid
    );
  };

  const handleLoginMode = (value: string): void => {
    setLoginMode(value);
    if (value === 'microsoft') {
      const randomPassword = generateRandomString();
      handleInputChange('password', randomPassword);
    }
    if (value === 'emailAndPassword') {
      handleInputChange('password', '');
    }
  };

  const showErrorValid = Boolean(
    form.password && !validators.passwordIsValid(form.password)
  );

  const radioGroupOptions = [
    {
      label: t(
        checkIfIsUSOrganization(organization!)
          ? 'loginMode.microsoft.us'
          : 'loginMode.microsoft'
      ),
      value: 'microsoft',
      disabled: false,
    },
    {
      label: t('loginMode.password', 'Contraseña'),
      value: 'emailAndPassword',
      disabled: false,
    },
  ];

  const isLoading = useMemo(
    () =>
      (type === 'user' ? loadingUserInvitation : loadingParentInvitation) ||
      isInvalidatingCache,
    [type, loadingUserInvitation, loadingParentInvitation, isInvalidatingCache]
  );
  return (
    <Modal
      isOpen={showModal}
      onClose={() => handleClose()}
      closeOnClickOutside
      modalWidth={540}
      title={t('students.completeInvitationModal.title')}
      buttons={[
        {
          children: t('confirmModal.action.complete'),
          onClick: () => handleSend(),
          loading: isLoading,
          variant: 'accent',
          disabled: !isValid(),
        },
        {
          children: t('confirmModal.action.cancel'),
          onClick: onClose,
          variant: 'secondary',
        },
      ]}
    >
      <CompleteInvitationWrapper>
        {type === 'parent' && (
          <Select
            menuPortalTarget={document.body}
            labelText={t('students.table.labels.language')}
            options={availableLanguages || []}
            onChange={(option) => handleInputChange('language', option!.value)}
            value={form.language}
            placeholder={t('students.table.placeholder.selectLanguage')}
          />
        )}
        <Input
          labelText={t('students.table.labels.name')}
          value={form.name}
          placeholder={t('common.name.placeholder')}
          onChange={(e) => handleInputChange('name', e.target.value)}
        />
        <Input
          labelText={t('students.table.labels.lastName')}
          placeholder={t('common.lastname.placeholder')}
          value={form.surname}
          onChange={(e) => handleInputChange('surname', e.target.value)}
        />

        <RadioGroup
          value={loginMode}
          title={t('loginMode.title', '¿Cómo va a iniciar sesión?')}
          groupName="loginMode"
          options={radioGroupOptions}
          handleEvent={handleLoginMode}
        />

        {loginMode === 'emailAndPassword' && (
          <>
            <Input
              labelText={t('students.table.changePassord.field.password')}
              descriptiveText={t(
                'students.table.changePassord.password.tagline'
              )}
              placeholder={t('common.password.placeholder')}
              value={form.password}
              onChange={(e) => handleInputChange('password', e.target.value)}
              status={showErrorValid ? 'error' : undefined}
            />
          </>
        )}
      </CompleteInvitationWrapper>
    </Modal>
  );
}
