import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPaginateFilter5 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path strokeWidth={1.249995} d="M3.125.622h16.25v16.25H3.125Z" />
      <path
        strokeWidth={1.249995}
        d="M16.875 19.372h-15a1.25 1.25 0 0 1-1.25-1.25v-15"
      />
      <path
        strokeWidth={1.249995}
        d="M9.375 11.872h2.188a1.563 1.563 0 0 0 1.562-1.563c0-2.777-3.75-.347-3.75-3.125V5.622h3.75"
      />
    </g>
  </svg>
);
export default SvgPaginateFilter5;
