import { isNullOrUndefined } from '../../utils/common';
import { getScoreColor } from '../../utils/getScoreColor';
import { getFixedLocalizedScore } from '../../utils/common';
import { Tooltip } from '@innovamat/glimmer-components';
import { Typography, theme } from '@innovamat/glimmer-components';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

type Props = {
  score: number | null;
  type: 'underline' | 'box';
  size?: 'sm' | 'lg';
  hasOpacity?: boolean;
  align?: 'left' | 'center' | 'right';
  isNotRepresentativeScore?: boolean;
  dataTestId?: string;
};

const Container = styled.div<{ align: string }>`
  display: flex;
  ${({ align }) => {
    switch (align) {
      case 'right':
        return `
          justify-content: flex-end;
          align-items: flex-end;
        `;
      case 'center':
        return `
          justify-content: center;
          align-items: center;
        `;
      default:
        return `
          justify-content: flex-start;
          align-items: flex-start;
        `;
    }
  }}
`;

const ScoreWrapper = styled.div<{
  size: string;
  score: string | undefined;
  hasOpacity?: boolean;
}>`
  ${({ size, score, hasOpacity, theme }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    font-weight: 700;
    color: ${theme.tokens.color.alias.cm.surface['surface-primary'].value};
    margin-top: 1px;
    height: 32px;
    width: ${size === 'sm' ? '32px' : '64px'};
    background-color: ${score};
    opacity: ${hasOpacity ? 0.3 : 1};
  `}
`;

const ScoreUnderlineWrapper = styled(Typography.Subtitle2)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
`;

const LineScore = styled.div<{ score: string | undefined }>`
  height: 4px;
  width: 32px;
  background-color: ${({ score }) => score};
  display: block;
  border-radius: 4px;
  margin-top: 3px;
`;

const NoScoreLine = styled.div`
  color: ${theme.tokens.color.specific.reports.semantic['no-data'].value};
`;

const SmallNumberDisplay = styled(Typography.Subtitle2)`
  cursor: default;
`;

const RegularNumberDisplay = styled(Typography.Subtitle1)`
  cursor: default;
`;

function Score({
  score,
  type = 'box',
  size = 'sm',
  hasOpacity,
  align = 'left',
  isNotRepresentativeScore,
  dataTestId,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const getScoreStyle = (type: string, score: number | null): JSX.Element => {
    const getValue = (): JSX.Element | string | number => {
      if (isNullOrUndefined(score)) return <NoScoreLine>-</NoScoreLine>;
      return getFixedLocalizedScore(score!);
    };

    const renderTooltip = (
      score: number | null,
      children: JSX.Element
    ): JSX.Element => {
      if (isNullOrUndefined(score)) return <>{children}</>;
      return (
        <Tooltip
          content={
            isNotRepresentativeScore ? t('reports.score.notRepresentative') : ''
          }
          maxWidth={200}
        >
          {children}
        </Tooltip>
      );
    };

    switch (type) {
      case 'box':
        return (
          <Container align={align} data-testid={dataTestId}>
            {renderTooltip(
              score,
              <ScoreWrapper
                size={size}
                score={getScoreColor(score, { isNotRepresentativeScore })}
                hasOpacity={hasOpacity}
              >
                {size === 'sm' ? (
                  <SmallNumberDisplay>{getValue()}</SmallNumberDisplay>
                ) : (
                  <RegularNumberDisplay>{getValue()}</RegularNumberDisplay>
                )}
              </ScoreWrapper>
            )}
          </Container>
        );
      default:
        return (
          <ScoreUnderlineWrapper data-testid={dataTestId}>
            <Tooltip content="">
              <div>{getValue()}</div>
            </Tooltip>
            <LineScore score={getScoreColor(score)} />
          </ScoreUnderlineWrapper>
        );
    }
  };

  return getScoreStyle(type, score);
}

export default Score;
