type ExpressionData = {
  input: string;
  output: string;
};

type ProcessedExpressionsInput = {
  expressions: string[];
  regex: RegExp;
};

type ProcessedExpressionsOutput = {
  processedExpressions: string[];
};

const extractExpressions = (content: string, regex: RegExp): string[] => {
  const regexG = new RegExp(regex, 'g');
  const expressions = content.match(regexG);

  if (!expressions) return [];

  return expressions;
};

const getSpanByType = (type: string, label: string, id: string): string => {
  if (type === 'Player') {
    return `<playercomponent label="${label}" videoid="${id}"></playercomponent>`;
  }

  if (type === 'Dimension') {
    return `<dimensioncomponent dimensionid="${id}">${label}</dimensioncomponent>`;
  }

  if (type === 'Material') {
    return `<materialcomponent label="${label}" materialid="${id}"></materialcomponent>`;
  }

  return label;
};

const processNestedExpressions = (
  externalExpression: string,
  regex: RegExp
): ExpressionData[] => {
  const nestedExpressions = extractExpressions(externalExpression, regex);

  return nestedExpressions.map((nestedExpression, nestedIndex) => {
    const nestedMatch = regex.exec(nestedExpression);

    if (!nestedMatch || !nestedMatch[1] || !nestedMatch[2] || !nestedMatch[3]) {
      return { input: nestedExpression, output: nestedExpression };
    }

    const [, type, label, id] = nestedMatch;
    const output = getSpanByType(type, label, id);

    return { input: nestedExpression, output };
  });
};

const processExpressions = ({
  expressions,
  regex,
}: ProcessedExpressionsInput): ProcessedExpressionsOutput => {
  const processedExpressions = expressions.map((expression, index) => {
    const match = regex.exec(expression);

    if (!match) return expression;
    if (!match[1] || !match[2] || !match[3]) return match[2] || '';

    const [, type, label, id] = match;
    const processedNestedExpressions = processNestedExpressions(label, regex);

    const updatedLabel = processedNestedExpressions.reduce(
      (acc, { input, output }) => acc.replace(input, output),
      label
    );

    return getSpanByType(type, updatedLabel, id);
  });

  return { processedExpressions };
};

const replaceExpressions = (
  expressions: string[],
  processedExpressions: string[],
  content: string
): string => {
  expressions.forEach((expression, index) => {
    content = content.replace(expression, processedExpressions[index]);
  });

  return content;
};

export const replaceHTMLElements = (text: string | undefined): string => {
  if (!text) return '';

  const content = text?.replace(/\[\[Space\]\]/g, '&nbsp;');

  const regex =
    /\[\[(\w+)=((?:[^[\]]|\[\[\w+.*?\|.*?\]\])*)\|(.*?)\]\](?![^[[]*\]\])/;

  const expressions = extractExpressions(content, regex);
  if (!expressions) return content;

  const { processedExpressions } = processExpressions({
    expressions,
    regex,
  });

  const processedContent = replaceExpressions(
    expressions,
    processedExpressions,
    content
  );

  return processedContent;
};
