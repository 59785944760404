import { useState, useEffect, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { snack } from '@innovamat/glimmer-components'; // TODO - use to show the error

import { InputChips, Modal, Select } from '@innovamat/glimmer-components';
import styled from '@emotion/styled';

import {
  type Organization,
  useSuggestTeachersQuery,
} from '@innovamat/glow-api-client';

import { useAssignTeachersToClassroom } from '../../../../hooks/use-assign-teachers-to-classroom';

const schema = yup.array().of(yup.string().email()).min(1);
const individualSchema = yup.string().email();

type Props = {
  classroomId: string;
  inviterId: string;
  organization: Organization;
  classroomName: string;
  onClose: () => void;
  onSuccess: () => void;
};

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

function AssignTeachersToClassroomModal({
  classroomId,
  inviterId,
  organization,
  classroomName,
  onSuccess,
  onClose,
}: Props): JSX.Element | null {
  const { t } = useTranslation();

  const [emails, setEmails] = useState<string[]>([]);
  const [isValid, setIsValid] = useState(false);
  const [emailInputValue, setEmailInputValue] = useState<string>('');

  const { data: suggestTeachersQuery, isLoading: isLoadingSuggestTeachers } =
    useSuggestTeachersQuery({
      name: emailInputValue,
    });

  const options = suggestTeachersQuery?.suggestTeachers?.map((teacher) => ({
    id: teacher?.id!,
    title: `${teacher?.firstName} ${teacher?.lastName}`,
    subtitle: teacher?.email!,
    image: 'teacher.ava',
  }));

  const handleInputChange = (value: string): void => {
    setEmailInputValue(value);
  };

  const handleSuccess = (): void => {
    onClose();
    onSuccess();
  };

  const handleErrorWithSnack = (): void => {
    snack.error(
      t(
        'teachers.error.assignTeachersClassroom',
        'Error assigning teachers to classroom'
      )
    );
  };

  const { handleAssignTeacherToClassrooms, isPending } =
    useAssignTeachersToClassroom({
      inviterId,
      organization,
      onSuccess: handleSuccess,
      onError: handleErrorWithSnack,
    });

  const handleUpdateMails = useCallback(
    async (chips: string[]): Promise<void> => {
      setEmails(chips);
      const valid = await schema.isValid(chips);
      setIsValid(valid);
    },
    []
  );

  const handleIsChipInvalid = (chip: string): boolean => {
    return !individualSchema.isValidSync(chip);
  };

  useEffect(() => {
    const validateEmails = async (): Promise<void> => {
      const valid = await schema.isValid(emails);
      setIsValid(valid);
    };

    validateEmails();
  }, [emails]);

  return (
    <Modal
      buttons={[
        {
          children: t('confirmModal.action.invite'),
          disabled: !classroomId || !isValid,
          loading: isPending,
          variant: 'accent',
          onClick: () =>
            handleAssignTeacherToClassrooms({
              classroomId,
              classroomName,
              emails,
            }),
        },
        {
          children: t('confirmModal.action.cancel'),
          variant: 'tertiary',
          onClick: onClose,
        },
      ]}
      isOpen
      modalWidth={600}
      onClose={onClose}
      title={t('teachers.addTeachers')}
    >
      <ModalContainer>
        {t('addTeachersModal.pasteEmails.info')}
        <InputChips
          canPasteEmails
          error={
            emails.length > 0 && !isValid
              ? {
                  name: 'Invalid Emails',
                  message: t('common.pasteValidEmails'),
                }
              : undefined
          }
          isChipInvalid={handleIsChipInvalid}
          loadingOptions={isLoadingSuggestTeachers}
          onInputChange={(value) => handleInputChange(value)}
          onUpdateChips={handleUpdateMails}
          options={options}
          suggestOptions
        />
        <Select
          labelText={t('common.classroom')}
          menuPortalTarget={document.body}
          onChange={() => {}}
          options={[{ label: classroomName, value: classroomId }]}
          placeholder={t('addTeachersModal.selectClassroom')}
          status="disabled"
          value={classroomId}
        />
      </ModalContainer>
    </Modal>
  );
}

export { AssignTeachersToClassroomModal };
