import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLearning = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M6.94 14.968a16.8 16.8 0 0 0-4.81-.85A1.194 1.194 0 0 1 1 12.915V2.205a1.203 1.203 0 0 1 1.275-1.203c5.074.265 7.736 2.077 7.736 3.455 0-1.37 2.982-3.166 7.728-3.447a1.194 1.194 0 0 1 1.29 1.203v10.1M10.011 4.457v3.119"
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M7.879 5.612A19.4 19.4 0 0 0 3.412 4.57M15.952 7.752c.28-.048.56-.088.857-.12M7.879 8.666a20 20 0 0 0-4.466-1.034M7.878 11.913a20.2 20.2 0 0 0-4.505-1.05M12.336 5.62a20.2 20.2 0 0 1 4.513-1.05M18.942 19l.08-.898a2.405 2.405 0 0 0-1.876-2.565l-2.926-.65v-4.601a1.507 1.507 0 1 0-3.007 0v7.375l-1.202-.89a1.227 1.227 0 0 0-1.716 1.724l.377.505"
    />
  </svg>
);
export default SvgLearning;
