import styled from '@emotion/styled';
import { useState } from 'react';
import useResourceTimer from '../../hooks/useResourceTimer';
import LoaderContainer from '../../components/LoaderContainer';

type Props = {
  src: string;
};

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;

  iframe {
    height: 100%;
    width: 100%;
  }
`;

export default function GeniallyViewer({ src }: Props) {
  const [isLoading, setIsLoading] = useState(true);
  useResourceTimer();
  return (
    <Wrapper>
      {isLoading && <LoaderContainer />}

      <iframe
        title="Genially Viewer"
        frameBorder="0"
        src={src}
        allowFullScreen
        scrolling="yes"
        onLoad={() => setIsLoading(false)}
      />
    </Wrapper>
  );
}
