import React from 'react';
import styled from '@emotion/styled';

interface RadioBoxProps {
  id: string;
  index?: number;
  name: string;
  children?: JSX.Element;
  onClick?: (id: string, index: number) => void;
}

const LabelBox = styled.label`
  width: 424px;
  height: 72px;
  border: 1px solid ${(props: any) => props.theme.colors.dark['04']};
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 22px;
`;

const RadioButton = styled.input`
  display: none;
  :checked + label {
    border: 2px solid ${(props: any) => props.theme.colors.dark['01']};
  }
`;

const RadioBox = ({ id, name, children, onClick, index }: RadioBoxProps) => (
  <>
    <RadioButton id={id} name={name} type="radio" />
    <LabelBox
      htmlFor={id}
      onClick={() => onClick?.(id, index ?? 0)}
      aria-hidden="true"
    >
      {children}
    </LabelBox>
  </>
);

export default RadioBox;
