import { useMutation } from '@tanstack/react-query';
import { AuthError } from '../utils/AuthError';
import { LoginHookProps, LoginResponse } from '../types/login';
import { usersLoginRequestByAccessToken } from '../utils/api';

export function useUsersLoginWebview({
  dispatch,
  credentials,
}: LoginHookProps) {
  const { mutate } = useMutation<LoginResponse, AuthError, string>({
    mutationFn: (request) => {
      return usersLoginRequestByAccessToken(request as string, credentials);
    },
  });

  const handleSetLoading = (loading: boolean) => {
    dispatch({
      type: 'SET_IS_LOADING',
      payload: {
        loading,
        key: { type: 'login_from_webview' },
      },
    });
  };

  const handleLogin = (request: string) => {
    handleSetLoading(true);
    mutate(request, {
      onSuccess(data) {
        dispatch({
          type: 'SET_LOGIN_INFO',
          payload: {
            loggedFrom: { type: 'login_from_webview' },
            response: data,
          },
        });
        handleSetLoading(false);
      },
      onError(error) {
        dispatch({ type: 'SET_ERROR', payload: error });
        handleSetLoading(false);
      },
    });
  };

  return handleLogin;
}
