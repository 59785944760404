import styled from '@emotion/styled';
import { Slider, createTheme } from '@mui/material';
import { ThemeProvider as MaterialProvider } from '@mui/material/styles';

type InputRangeSliderProps = {
  value: number;
  min: number;
  max: number;
  step: number;
  disabled?: boolean;
  onChange: (e: number) => void;
};

const StyledSlider = styled(Slider)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.bg['bg-accent-inverted'].value};
  height: 6px;

  .MuiSlider-rail {
    background-color: ${({ theme }) =>
      theme.tokens.color.alias.cm.border['border-subtle'].value};
    opacity: 1;
  }

  .MuiSlider-thumb {
    width: 16px;
    height: 16px;

    :hover,
    .Mui-focusVisible {
      box-shadow: 0px 0px 0px 8px
        ${({ theme }) =>
          theme.tokens.color.specific['state-layer']['state-hover-darker']
            .value};
    }
    &.Mui-active {
      box-shadow: 0px 0px 0px 14px
        ${({ theme }) =>
          theme.tokens.color.specific['state-layer']['state-hover-darker']
            .value};
    }
  }
`;

export function InputRangeSlider({
  value,
  min,
  max,
  step,
  disabled,
  onChange,
}: InputRangeSliderProps): JSX.Element {
  const materialTheme = createTheme({});

  return (
    <MaterialProvider theme={materialTheme}>
      <StyledSlider
        min={min}
        max={max}
        step={step}
        onChange={(_, value) => onChange(value as number)}
        disabled={disabled}
        value={value}
        aria-label="Default"
      />
    </MaterialProvider>
  );
}
