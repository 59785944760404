/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import Icon from '../../Icon';
import { ButtonCircleProps } from './ButtonCircle.types';
import { ButtonWrapper } from './ButtonCircle.styles';

function ButtonCircle({ icon, className, ...rest }: ButtonCircleProps) {
  return (
    <ButtonWrapper {...rest} type="button" className={`${className} ripple`}>
      <Icon icon={icon} />
    </ButtonWrapper>
  );
}

export default ButtonCircle;
