
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as NotAvailableIconComponent } from "./not-available-icon.svg";

export const NotAvailableIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <NotAvailableIconComponent {...props} />;
};
