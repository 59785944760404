import type { FunctionComponent, ReactNode, SVGProps } from 'react';
import styled from '@emotion/styled';

import { Typography } from '../Typography';
import { Grid } from '../grid';

const Container = styled(Grid.Col)`
  margin-top: 3.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
`;

const Text = styled.div`
  text-align: center;
  gap: 0.5rem;
`;

const Subtitle = styled(Typography.Body1)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

type EmptyStateProps = {
  action?: ReactNode;
  Image?: FunctionComponent<SVGProps<SVGSVGElement>>;
  subtitle?: string;
  title: string;
};

function EmptyState({
  action,
  Image,
  subtitle,
  title,
}: EmptyStateProps): JSX.Element {
  return (
    <Container>
      {Image && (
        <div role="img">
          <Image />
        </div>
      )}
      <Text>
        <Typography.H4>{title}</Typography.H4>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </Text>
      {action && action}
    </Container>
  );
}

export { EmptyState };
export type { EmptyStateProps };
