import styled from '@emotion/styled';
import {
  Icon,
  SemanticType,
  Typography,
  useSemantic,
} from '@innovamat/glimmer-components';

type Props = {
  title: string | JSX.Element;
  type?: SemanticType;
  lessonsDone?: string[];
  reminderText?: string;
};

const Container = styled.div<{ backgroundColor: string }>`
  width: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 12px 16px;
  border-radius: 8px;
  display: flex;
  gap: 12px;
  margin-top: 24px;
`;

const IconContainer = styled.div`
  margin-top: 4px;
`;

const LessonContainer = styled.div`
  margin-top: 12px;
`;

const LessonWrapper = styled.div<{
  showTopLine: boolean;
  showBottomLine: boolean;
}>`
  display: flex;
  width: 100%;
  border-top: ${({ showTopLine, theme }) =>
    showTopLine
      ? `1px solid ${theme.tokens.color.specific.reports.tables['divider-secondary'].value}`
      : 'none'};
  :first-child {
    border-top: none;
  }
  :last-child {
    border-bottom: ${({ showBottomLine, theme }) =>
      showBottomLine
        ? `1px solid ${theme.tokens.color.specific.reports.tables['divider-secondary'].value}`
        : 'none'};
  }
`;

const NoSessionDoneDescription = styled(Typography.Body2)`
  margin-top: 16px;
  width: 100%;
  font-style: italic;
`;

const Lesson = styled(Typography.Body2)`
  padding: 12px 0;
  width: 100%;
`;

function LessonsPanel({
  title,
  type = 'success',
  lessonsDone,
  reminderText,
}: Props): JSX.Element | null {
  const { getIcon, getBackground, getColor } = useSemantic();
  return (
    <Container backgroundColor={getBackground(type)}>
      <IconContainer>
        <Icon icon={getIcon(type)} bgColor={getColor(type)} size="M" />
      </IconContainer>
      <div>
        <Typography.Body1>{title}</Typography.Body1>
        {lessonsDone && lessonsDone.length > 0 && (
          <LessonContainer>
            {lessonsDone
              .filter((lesson) => lesson.trim() !== '')
              .map((lesson, index) => (
                <LessonWrapper
                  key={index}
                  showTopLine={index !== lessonsDone.length}
                  showBottomLine={lessonsDone.length === 1}
                >
                  <Lesson>{lesson}</Lesson>
                </LessonWrapper>
              ))}
          </LessonContainer>
        )}
        {reminderText && (
          <NoSessionDoneDescription>{reminderText}</NoSessionDoneDescription>
        )}
      </div>
    </Container>
  );
}

export default LessonsPanel;
