import * as React from 'react';
import type { SVGProps } from 'react';
const SvgOpenBook = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M10.833 19.088a14.16 14.16 0 0 1 7.38-3.241 1.33 1.33 0 0 0 1.16-1.323V5.175a1.343 1.343 0 0 0-1.53-1.333 14.2 14.2 0 0 0-7.01 3.193 1.36 1.36 0 0 1-1.671 0 14.2 14.2 0 0 0-7.008-3.19 1.343 1.343 0 0 0-1.529 1.33v9.35a1.33 1.33 0 0 0 1.16 1.322 14.16 14.16 0 0 1 7.382 3.241 1.36 1.36 0 0 0 1.666 0"
      />
      <path
        strokeWidth={1.249995}
        d="M16.683.625a14.7 14.7 0 0 0-5.85 2.97 1.36 1.36 0 0 1-1.671 0A14.7 14.7 0 0 0 3.32.626M10 7.32v12.053"
      />
    </g>
  </svg>
);
export default SvgOpenBook;
