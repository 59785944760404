import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSamHistory = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      clipPath="url(#sam_history_svg__a)"
    >
      <path d="M11.882 6.875h-3.75M8.132 4.375h3.75M14.375 9.375v10M5.625 19.375v-10M8.125 9.375v10M11.875 9.375v10M3.145 3.633c.31-.264.71-.397 1.115-.37a1.96 1.96 0 0 1 1.833 2.083 2.45 2.45 0 0 1-2.602 2.283A3.06 3.06 0 0 1 .63 4.38v-.004a3.75 3.75 0 0 1 3.75-3.75h11.237a3.75 3.75 0 0 1 3.75 3.75v.005a3.06 3.06 0 0 1-2.859 3.25 2.447 2.447 0 0 1-2.599-2.288 1.96 1.96 0 0 1 1.833-2.08 1.56 1.56 0 0 1 1.135.388" />
    </g>
    <defs>
      <clipPath id="sam_history_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSamHistory;
