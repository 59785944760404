import styled from '@emotion/styled';
import { FullScreen } from 'react-full-screen';

export const Wrapper = styled.div<{ isInFullscreen: boolean }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ isInFullscreen, theme }) =>
    isInFullscreen ? theme.colors.dark['00'] : theme.colors.white};
  justify-content: space-between;
  padding-bottom: 8px;
`;

export const StyledFullScreen = styled(FullScreen)`
  height: 100%;
  width: 100%;
`;

export const TimelineContainer = styled.div<{ isInFullscreen: boolean }>`
  display: flex;
  flex-direction: column;

  padding: ${({ isInFullscreen }) => (isInFullscreen ? '16px' : '0px 8px')};
`;

export const NoTimeLineSeparator = styled.div`
  margin-top: 8px;
`;

export const ContainerParent = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
`;

export const FullScreenWrapper = styled.div`
  height: 100%;

  .fullscreen-enabled {
    overflow: auto;
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  width: 100%;
`;
