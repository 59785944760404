import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDefaultAvatar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <circle cx={20} cy={20} r={20} fill="#CCC" />
    <mask
      id="defaultAvatar_svg__a"
      width={40}
      height={40}
      x={0}
      y={0}
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
    >
      <circle cx={20} cy={20} r={20} fill="#CCC" />
    </mask>
    <g mask="url(#defaultAvatar_svg__a)">
      <path
        fill="#E5E5E5"
        d="M20 50a29 29 0 1 0 0-57.999A29 29 0 0 0 20 50M20-.75a21.72 21.72 0 0 1 14.5 37.918v-.762a13.59 13.59 0 0 0-9.902-13.074 9.063 9.063 0 1 0-9.19 0A13.59 13.59 0 0 0 5.5 36.406c-.001 2.962 3.283 3.694 0 .761A21.72 21.72 0 0 1 20-.75"
      />
    </g>
  </svg>
);
export default SvgDefaultAvatar;
