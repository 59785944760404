import * as React from 'react';
import type { SVGProps } from 'react';
const SvgUserPairs = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M3.91 7a3 3 0 1 0 6 0 3 3 0 0 0-6 0M2 17a4.909 4.909 0 0 1 9.818 0M11.819 4.484a3 3 0 1 1-.005 5.029M12.364 12.212a4.914 4.914 0 0 1 6 4.788"
    />
  </svg>
);
export default SvgUserPairs;
