
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as PlayIconComponent } from "./play-icon.svg";

export const PlayIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <PlayIconComponent {...props} />;
};
