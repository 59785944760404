export function getInitials(name: string, maxInitials = 2): string {
  const words: string[] = name.split(' ');
  let initials = '';

  for (const word of words) {
    if (word.length > 0) {
      initials += word[0].toUpperCase();
    }

    if (initials.length >= maxInitials) {
      break;
    }
  }

  return initials;
}
