import styled from '@emotion/styled';
import { AnnouncementPanel, Button } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

type Props = {
  onGoBack: () => void;
  children?: React.ReactNode;
};

function ImportErrorPage({ onGoBack, children }: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      <AnnouncementPanel
        text={t('school.import_csv.uploaded.validation_error')}
        canClose={false}
        type="error"
      />
      {children}
      <Footer>
        <Button variant="accent" type="button" onClick={onGoBack}>
          {t('school.import_csv.cta.try_again')}
        </Button>
      </Footer>
    </>
  );
}

export { ImportErrorPage };
