import { Icon, IconBase, Tooltip } from '@innovamat/glimmer-components';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useDimensionColors } from '../../hooks/useDimensionColors';
import { Dimension } from '../../types/Dimension';
import { useSmartGuides } from '../../context/SmartGuidesProvider';

type StyledProps = {
  iconColor: string;
  bgColor: string;
};

const StyledButton = styled.div`
  display: flex;
  height: 100%;
`;

const StyledIconEvaluable = styled(IconBase)<StyledProps>`
  height: auto;
  cursor: pointer;
  height: 100%;
  ${({ iconColor, bgColor }) =>
    css`
      svg,
      path {
        fill: ${iconColor};
      }
      background-color: ${bgColor};
    `}
`;

const IconContainer = styled.div<{ size: 'M' | 'S' }>`
  display: flex;
  width: ${({ size }) => (size === 'M' ? '40px' : '24px')};
  height: 40px;
  align-items: center;
  justify-content: center;
`;

type Props = {
  dimension?: Dimension;
  className?: string;
};

export function IconEvaluable({ dimension }: Props): JSX.Element {
  const { setIsAssessableDrawerOpen, t } = useSmartGuides();
  const { getDimensionElement, getDimensionBackground } = useDimensionColors();

  const dimensionKeys = {
    1: 'problem_solving',
    2: 'reasoning_and_proof',
    3: 'connections',
    4: 'communication_and_representation',
  };

  const handleOnClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event?.stopPropagation();
    setIsAssessableDrawerOpen(true);
  };

  if (!dimension) {
    return (
      <Tooltip content={t('session.assessment.contents')}>
        <IconContainer size="M">
          <Icon icon="EvaluationIcon" size="M" />
        </IconContainer>
      </Tooltip>
    );
  }

  return (
    <Tooltip
      content={
        <span style={{ textWrap: 'nowrap' }}>
          {t('session.assessment.link.tooltip', {
            dimension: t(
              `digitalguides.dimensions.${dimensionKeys[dimension]}`
            ),
          })}
        </span>
      }
    >
      <StyledButton onClick={(e) => handleOnClick(e)}>
        <StyledIconEvaluable
          icon="EvaluationIcon"
          iconColor={getDimensionElement(dimension)}
          bgColor={getDimensionBackground(dimension)}
          size="L"
          iconSize="M"
        />
      </StyledButton>
    </Tooltip>
  );
}

export function IconEvaluablePrintable({
  dimension,
  className,
}: Props): JSX.Element {
  const { getDimensionElement, getDimensionBackground } = useDimensionColors();

  if (!dimension) {
    return (
      <IconContainer size="S">
        <Icon icon="EvaluationIcon" size="M" />
      </IconContainer>
    );
  }

  return (
    <StyledButton className={className}>
      <StyledIconEvaluable
        icon="EvaluationIcon"
        iconColor={getDimensionElement(dimension)}
        bgColor={getDimensionBackground(dimension)}
        size="S"
        iconSize="M"
      />
    </StyledButton>
  );
}
