import { jwtDecode } from 'jwt-decode';
import { LoginResponse } from '../types/login';
import {
  DecodedAccessToken,
  DecodedIdToken,
  DecodedTokens,
} from '../types/token';

export const decodeTodenData = (data: LoginResponse): DecodedTokens => {
  const decodedIdToken: DecodedIdToken = jwtDecode(data.id_token);
  const decodedAccessToken: DecodedAccessToken = jwtDecode(data.access_token);

  return {
    decodedIdToken,
    decodedAccessToken,
  };
};
