export type Error = {
  error: number | string;
  [index: string]: any;
  code: number;
  status: number;
};

export interface ErrorsState {
  [index: string]: Error;
}

export const initialErrorsState: ErrorsState = {};
