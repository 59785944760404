import styled from '@emotion/styled';
import { MediaType, MediaWidth } from '../../types/Media';
import { PreprPlayer } from '../PreprPlayer';
import {
  Icon,
  ImageComponent,
  ModalOverlay,
  StateLayer,
} from '@innovamat/glimmer-components';
import { useEffect, useRef, useState } from 'react';
import { MediaModal } from './media-modal';

const Container = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const ImageContainer = styled.div<{
  imgWidth: MediaWidth;
  imgHeight?: number;
}>`
  position: relative;
  width: ${({ imgWidth }) => imgWidth}%;
  height: ${({ imgHeight }) => imgHeight}px;
  cursor: pointer;
  transition: opacity 0.1s ease;

  &:hover .image-stateLayer {
    border-radius: 4px;
    background-color: ${({ theme }) =>
      theme.tokens.color.specific['smart-guides']['images-hover'].value};
  }

  svg {
    transition: opacity 0.1s ease;
    opacity: 0;
  }
  &:hover {
    svg {
      opacity: 1;
    }
  }

  @media print {
    height: auto;
  }
`;

const ZoomIcon = styled.div`
  pointer-events: none;
  position: absolute;
  z-index: 11;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Image = styled(ImageComponent)`
  width: 100%;
  object-fit: cover;
  border-radius: 4px;
`;

const PreprPlayerWrapper = styled.div<{ width: MediaWidth }>`
  width: ${({ width }) => width}%;
`;

type Props = {
  media?: MediaType[] | null;
};

export function Media({ media }: Props): JSX.Element | null {
  const [modalStates, setModalStates] = useState<boolean[]>([]);
  const imageRefs = useRef<Record<number, HTMLImageElement | null>>({});

  useEffect(() => {
    setModalStates(Array(media?.length).fill(false));
  }, [media]);

  const openModal = (e: any, index: number) => {
    e.stopPropagation();
    setModalStates((prevModalStates) => {
      const newModalStates = [...prevModalStates];
      newModalStates[index] = true;
      return newModalStates;
    });
  };

  const closeModal = (e: any) => {
    e.stopPropagation();
    const newModalStates = modalStates.map(() => false);
    setModalStates(newModalStates);
  };

  if (media?.length === 0) return null;

  return (
    <Container className="mediaContainer">
      {media?.map((item, index) =>
        item.type === 'Photo' ? (
          <ImageContainer
            imgWidth={item.width}
            imgHeight={imageRefs[index]?.clientHeight}
          >
            <ZoomIcon>
              <Icon icon="ZoomInIcon" iconColor="white" size="XL" />
            </ZoomIcon>
            <StateLayer className="image-stateLayer" />
            <ModalOverlay
              element={
                <MediaModal
                  src={item.url}
                  alt={item.name}
                  onClose={closeModal}
                />
              }
              isOpen={modalStates[index] || false}
              onClose={closeModal}
            />
            <Image
              ref={(el) => (imageRefs[index] = el)}
              key={item.url}
              src={item.url}
              alt={item.name}
              onClick={(e) => openModal(e, index)}
            />
          </ImageContainer>
        ) : (
          <PreprPlayerWrapper width={item.width} id="prepr-video">
            <PreprPlayer
              metadata={{
                videoId: item.id,
                videoTitle: item.name,
              }}
              playbackId={item.playbackId!}
            />
          </PreprPlayerWrapper>
        )
      )}
    </Container>
  );
}
