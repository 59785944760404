import styled from '@emotion/styled';
import { Button, Typography, theme } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';
import type { ReportType } from '../../utils/render-tables';

type Props = {
  isWarningList: boolean;
  isCurrentWeek: boolean;
  onPreviousWeek: () => void;
  showPreviousWeekButton: boolean;
  reportType: ReportType;
};

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 58px;
`;

const ButtonContainer = styled(Button)`
  margin-top: 24px;
`;

const MESSAGE_KEYS = {
  EMPTY_GENERAL: 'reports.message.emptyGeneralProgress',
  CURRENT_WEEK: 'reports.message.thisWeek.classDontDoDPYet',
  DURING_WEEK: 'reports.message.duringThisWeek.classDidntDoDP',
  WARNING_LIST_TITLE: 'reports.warningList.title',
  WARNING_LIST_DESCRIPTION: 'reports.warningList.description',
  WEEKLY_REMINDER: 'reports.digitalPractice.weeklyReminder',
  PREVIOUS_WEEK_BUTTON: 'reports.weekly.button.previousWeek',
} as const;

function MessageReports({
  isWarningList,
  isCurrentWeek,
  onPreviousWeek,
  showPreviousWeekButton,
  reportType,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const getMessageReports = (
    reportType: ReportType,
    isCurrentWeek: boolean,
    t: (key: string) => string
  ): string => {
    if (reportType === 'general') {
      return t(MESSAGE_KEYS.EMPTY_GENERAL);
    }
    return t(
      isCurrentWeek ? MESSAGE_KEYS.CURRENT_WEEK : MESSAGE_KEYS.DURING_WEEK
    );
  };

  const getTitleAndDescription = (
    isWarningList: boolean,
    messageReports: string,
    t: (key: string) => string
  ): {
    title: string;
    description: string;
  } => {
    const title = isWarningList
      ? t(MESSAGE_KEYS.WARNING_LIST_TITLE)
      : messageReports;
    const description = isWarningList
      ? t(MESSAGE_KEYS.WARNING_LIST_DESCRIPTION)
      : t(MESSAGE_KEYS.WEEKLY_REMINDER);

    return { title, description };
  };

  const getTextColor = (isWarningList: boolean) => {
    const { specific, alias } = theme.tokens.color;
    return isWarningList
      ? specific.reports.semantic.low.value
      : alias.cm.text['text-subtle'].value;
  };

  const messageReports = getMessageReports(reportType, isCurrentWeek, t);
  const { title, description } = getTitleAndDescription(
    isWarningList,
    messageReports,
    t
  );
  const textColor = getTextColor(isWarningList);

  return (
    <MessageContainer>
      <Typography.H3
        color={
          isWarningList
            ? theme.tokens.color.specific.reports.semantic.low.value
            : ''
        }
      >
        {title}
      </Typography.H3>
      <Typography.Body1 color={textColor}>{description}</Typography.Body1>
      {showPreviousWeekButton && (
        <ButtonContainer onClick={onPreviousWeek}>
          {t('reports.weekly.button.previousWeek')}
        </ButtonContainer>
      )}
    </MessageContainer>
  );
}

export default MessageReports;
