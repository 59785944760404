
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as KeyEnterIconComponent } from "./key-enter-icon.svg";

export const KeyEnterIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <KeyEnterIconComponent {...props} />;
};
