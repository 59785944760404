import { useEffect, useMemo, useRef, useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import * as yup from 'yup';

import {
  AnnouncementPanel,
  Button,
  Column,
  Input,
  Modal,
  snack,
  TableComponent,
  Typography,
} from '@innovamat/glimmer-components';

import { Classroom, newId } from '@innovamat/radiance-utils';

import {
  GlowApiError,
  Member,
  useChangeUserPasswordMutation,
  useInviteUsersMutation,
  useResendParentsInvitationMutation,
  useUnlinkParentMutation,
} from '@innovamat/glow-api-client';
import { useUser } from '../../../user-management';
import { useConfirmationModal } from '../../hooks/use-confirmation-modal';
import { useOrganization } from '../../hooks/use-organization';
import { StudentRow } from '../../views';
import { ChangePasswordModal } from '../change-password-modal';
import { StatusCell } from '../common-table-components/status-cell';
import { ConfirmationModal } from '../confirmation-modal';
import { InviteParentsInfoModal } from './invite-parents-info-modal';
import { ActionsCell } from './invite-parents-modal.actions-cell';
import { ContentWrapper, EmailsGrid } from './invite-parents-modal.styled';
import { CompleteUserInvitation, ErrorType, InvitationBody } from './types';
import { useCreateConfirmationOptions } from './use-create-confirmation-options';
import {
  getExistsErrorMessage,
  getInvalidErrorMessage,
  hasRoleConflictError,
  validateRoleConflictNotParentOrStudentJr,
} from './validations';

type Props = {
  classroom: Classroom | undefined;
  onClose: (hasParents?: boolean) => void;
  onCompleteManually: (invitation: CompleteUserInvitation) => void;
  onInviteParents: (parents: Member[], studentId: string) => void;
  onUnlinkParent: (parent: Member, studentRow: StudentRow) => void;
  showModal: boolean;
  student: StudentRow | undefined;
};

const emailSchema = yup.string().required().email();

type EmailRow = {
  id: string;
  email: string;
  status: string;
};

export function InviteParentsModal({
  classroom,
  onClose,
  onCompleteManually,
  onInviteParents,
  onUnlinkParent,
  showModal,
  student,
}: Props): JSX.Element | null {
  const { id, firstName, parents, language } = student || {};
  const { t } = useTranslation();
  const { user } = useUser();

  const { mutate: mutateInviteUsers, isPending: isLoadingInvite } =
    useInviteUsersMutation<GlowApiError>();

  const { mutate: mutateUnlinkParent, isPending: isLoadingUnlink } =
    useUnlinkParentMutation<GlowApiError>();

  const { mutate: resendInvitation } = useResendParentsInvitationMutation();

  const { mutate: changePassword, isPending: changingPassword } =
    useChangeUserPasswordMutation();

  const { onGetConfirmationOptions } = useCreateConfirmationOptions();

  const [errorPanel, setErrorPanel] = useState<(JSX.Element | null)[]>([]);
  const [currentEmail, setCurrentEmail] = useState('');

  const [parentToChangePass, setParentToChangePass] = useState<
    string | undefined
  >(undefined);
  const [showInfoModal, setShowInfoModal] = useState(false);

  const {
    requestConfirmation,
    handleCloseConfirmationModal,
    confirmationModalProps,
    isConfirmationOpen,
  } = useConfirmationModal();
  const { organization } = useOrganization();

  const [emailIsValid, setEmailIsValid] = useState(false);

  const previousParentsCountRef = useRef(parents ? parents.length : 0);

  useEffect(() => {
    if (
      showModal &&
      previousParentsCountRef.current > 0 &&
      parents?.length === 0
    ) {
      onClose(false);
    }

    previousParentsCountRef.current = parents?.length || 0;
  }, [parents, showModal, onClose]);

  useEffect(() => {
    emailSchema
      .isValid(currentEmail)
      .then((valid) =>
        setEmailIsValid(
          valid &&
            !parents
              ?.map((parent) => parent?.userInfo?.email)
              .includes(currentEmail)
        )
      );
  }, [currentEmail, emailSchema, parents]);

  useEffect(() => {
    setCurrentEmail('');
  }, [showModal]);

  const handleSend = ({
    newEmail,
    forceRoleChange = false,
  }: {
    newEmail: string;
    forceRoleChange?: boolean;
  }) => {
    const newInvitedEmail = {
      email: newEmail,
      id: newId(),
      invitedId: newId(),
    };

    const invitationsBody: InvitationBody = {
      operativeRegion: organization?.operativeRegion,
      forceRoleChange,
      schoolId: user?.organizationId,
      inviterId: user?.id,
      invitedEmails: [newInvitedEmail],
      students: [
        {
          id,
          name: firstName,
          schoolName: organization?.name,
          courseName: classroom?.courseName,
          className: classroom?.name,
        },
      ],
      language,
      type: 'parent',
    };

    mutateInviteUsers(
      {
        invitations: invitationsBody as any,
      },
      {
        onError: (error) => {
          const errors = error.response.errors;

          if (!errors) return;

          for (const error of errors) {
            if (!hasRoleConflictError(error))
              snack.error(t('common.toast.error'));

            const validations = validateRoleConflictNotParentOrStudentJr(error);

            if (validations) {
              const { errors } = validations;

              const existsErrors = getExistsErrorMessage(errors);
              const invalidErrors = getInvalidErrorMessage(errors);

              const errorPanels = [existsErrors, invalidErrors]
                .filter(Boolean)
                .map((error) => {
                  const { key, values } = error as ErrorType;
                  return (
                    <Trans
                      i18nKey={key}
                      values={values}
                      components={{
                        b: <b />,
                      }}
                    />
                  );
                });
              setErrorPanel(errorPanels);
              return;
            }

            const confirmationOptions = onGetConfirmationOptions(error);

            if (confirmationOptions) {
              requestConfirmation({
                title: confirmationOptions.title,
                message: confirmationOptions.message,
                cancelText: confirmationOptions.cancelText,
                confirmText: confirmationOptions.confirmText,
                onConfirm() {
                  handleSend({ newEmail: newEmail, forceRoleChange: true });
                },
              });
            }
          }
        },
        onSuccess: (data, variables) => {
          setShowInfoModal(true);

          const newParents = data?.inviteUsers?.filter((invited) =>
            variables.invitations?.invitedEmails?.some(
              (invitedEmail) => invitedEmail?.email === invited?.userInfo?.email
            )
          ) as Member[];

          onInviteParents(newParents, student?.id!);
        },
      }
    );
  };

  const handleClose = (forceHasParents = false) => {
    const hasParents =
      forceHasParents || !!(parents?.length && parents?.length > 0);
    onClose(hasParents);
  };

  const handleAddEmail = async (email: string) => {
    if (!emailIsValid) return;
    setErrorPanel([]);
    setCurrentEmail('');

    handleSend({ newEmail: email });
  };

  const handleUnlinkParent = async (email: string) => {
    requestConfirmation({
      title: t('students.inviteParentsModal.unlinkConfirmModal.title'),
      message: t('students.inviteParentsModal.unlinkConfirmModal.message', {
        email,
      }),
      cancelText: t('confirmModal.action.cancel'),
      confirmText: t('students.inviteParentsModal.unlinkConfirmModal.confirm'),
      onConfirm: () => {
        const parent = parents?.find(
          (_parent) => _parent?.userInfo?.email === email
        ) as Member;

        if (!parent) return;
        const isInvitation = !!parent.userInfo?.token;

        mutateUnlinkParent(
          {
            body: { parentId: parent?.id!, studentId: id!, isInvitation },
          },
          {
            onSuccess: () => {
              onUnlinkParent(parent, student!);
              handleCloseConfirmationModal();
              snack.success(t('common.toast.success'));
            },
          }
        );
      },
    });
  };

  const handleCompleteManually = (email: string) => {
    const currentParent = parents?.find(
      (parent) => parent?.userInfo?.email === email
    );
    if (currentParent) {
      onCompleteManually({
        email: currentParent?.userInfo?.email!,
        id: currentParent.id!,
        token: currentParent?.userInfo?.token!,
      });
    }
  };

  const isInvitation = (email: string) => {
    const currentParent = parents?.find(
      (parent) => parent?.userInfo?.email === email
    );

    if (!currentParent) return true;

    return !!currentParent?.userInfo?.token;
  };

  const isNewEmail = (email: string) => {
    const currentParent = parents?.find(
      (parent) => parent?.userInfo?.email === email
    );
    return !currentParent?.userInfo?.firstName;
  };

  const handleSetNewPassword = (password: string) => {
    const userId = parents?.find(
      (p) => p?.userInfo?.email === parentToChangePass
    )?.id;

    changePassword(
      {
        body: {
          password,
          userId: userId!,
        },
      },
      {
        onSuccess: () => {
          snack.success(t('common.toast.success.updatePassword'));
          setParentToChangePass(undefined);
        },
        onError: () => {
          snack.error(t('common.toast.error.updatePassword'));
        },
      }
    );
  };

  useEffect(() => {
    setErrorPanel([]);
  }, [showModal]);

  const handleResendInvitation = (email: string) => {
    const invitation = parents
      ?.filter((parent) => parent?.userInfo?.email === email)
      .map((parent) => ({
        id: parent?.id!,
        token: parent?.userInfo?.token!,
      }));

    if (invitation) {
      resendInvitation(
        {
          body: invitation,
        },
        {
          onSuccess: () => {
            snack.success(t('userStatus.toast.success.invitationForwarded'));
          },
          onError: () => {
            snack.error(t('common.toast.error'));
          },
        }
      );
    }
  };

  const columns: Column<EmailRow>[] = [
    {
      id: 'email',
      width: 80,
      label: t('students.inviteParentsModal.title'),
      render: (_, { email }) => {
        if (email === '') {
          return (
            <Input
              value={currentEmail}
              placeholder={t('common.sampleEmail')}
              onChange={(e) => setCurrentEmail(e.target.value)}
            />
          );
        }

        return <Typography.Body2>{email}</Typography.Body2>;
      },
    },
    {
      id: 'status',
      width: 30,
      label: t('common.status'),
      render: (_, { email }) => {
        if (email === '') {
          return null;
        }

        return <StatusCell isInvitation={isInvitation(email)} />;
      },
    },
    {
      id: 'actions',
      width: 10,
      render: (_, { email }) => {
        if (email === '') {
          return (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                disabled={!emailIsValid}
                onClick={() => handleAddEmail(currentEmail)}
                loading={isLoadingInvite}
              >
                {t('confirmModal.action.invite')}
              </Button>
            </div>
          );
        }

        return (
          <ActionsCell
            email={email}
            isInvitation={isInvitation(email)}
            isNewEmail={isNewEmail(email)}
            onCompleteManually={handleCompleteManually}
            onResendInvitation={handleResendInvitation}
            onSetParentToChangePass={setParentToChangePass}
            onUnlinkParent={handleUnlinkParent}
          />
        );
      },
    },
  ];

  const rows = useMemo(() => {
    const parentsEmails =
      parents?.map((parent) => parent?.userInfo?.email) || [];

    const elements =
      parentsEmails?.map(
        (email) =>
          ({
            id: email || '',
            email: email || '',
            status: '',
          } satisfies EmailRow)
      ) || [];

    if (parentsEmails.length >= 3) return elements;

    const emptyRow = {
      email: '',
      status: '',
      id: '',
    };

    return [...elements, emptyRow];
  }, [parents]);

  if (!showModal) return null;

  return (
    <>
      <Modal
        onClose={() => handleClose()}
        title={t('students.inviteParentsModal.title')}
        isOpen={showModal}
        zIndex={29}
        closeButton="inner"
        modalWidth={770}
        style={{ overflow: 'visible' }}
      >
        <ContentWrapper>
          <Typography.Body1>
            {t('students.inviteParentsModal.subtitle')}
          </Typography.Body1>
          {errorPanel?.filter(Boolean).map((error) => (
            <AnnouncementPanel
              text={error || ''}
              type="error"
              canClose={false}
            />
          ))}
          <EmailsGrid>
            <TableComponent id="parents-table" columns={columns} rows={rows} />
          </EmailsGrid>
        </ContentWrapper>
      </Modal>
      <InviteParentsInfoModal
        showModal={showInfoModal}
        setShowModal={setShowInfoModal}
      />
      <ChangePasswordModal
        loading={changingPassword}
        open={Boolean(parentToChangePass)}
        onClose={() => setParentToChangePass(undefined)}
        onSubmit={handleSetNewPassword}
      />
      <ConfirmationModal
        {...confirmationModalProps}
        isOpen={isConfirmationOpen}
        onClose={handleCloseConfirmationModal}
        isPending={isLoadingInvite || isLoadingUnlink}
      />
    </>
  );
}
