import { useTranslation } from 'react-i18next';

import styled from '@emotion/styled';

import {
  Column,
  Password,
  TableComponent,
  Typography,
  useColumnsCustomRenderConfig,
  type CustomColumnRenderConfig,
} from '@innovamat/glimmer-components';
import { dates } from '@innovamat/radiance-utils';

import { EllipsisCell } from '../../../components/common-table-components/ellipsis-cell';
import type { Test, TestsAndResults } from '../tests-and-results';

import { TestStatusCell } from './test-status-cell';

export const Container = styled.div`
  margin-top: 2.5rem;
  margin-bottom: 1rem;
`;

type Props = {
  data: TestsAndResults;
};

// todo move to utils
const formatDate = (date: string): string => {
  if (!date) {
    return '--/--/----'; // todo check format for different regions
  }

  return dates.formatDateFromSql(date);
};

export const useTestAndResultsColumnsConfiguration = (): Column<Test>[] => {
  const { t } = useTranslation();

  return [
    {
      id: 'name',
      label: t('testsAndResultsView.testNameColumnLabel'),
      sortable: true,
      sortIgnoreCase: true,
      width: 300,
      minWidth: 300,
    },
    {
      id: 'status',
      label: t('common.status'),
      width: 150,
      minWidth: 150,
    },
    {
      id: 'password',
      label: t('common.password'),
      width: 150,
      minWidth: 150,
    },
    {
      id: 'startDate',
      width: 1,
      separator: true,
    },
    {
      id: 'startDate',
      label: t('common.availableFrom'),
      isLargeLabel: false,
      sortable: true,
      width: 150,
      minWidth: 150,
    },
    {
      id: 'endDate',
      label: t('common.availableUntil'),
      isLargeLabel: false,
      sortable: true,
      width: 150,
      minWidth: 150,
    },
  ];
};

const customColumnsRenderConfig: CustomColumnRenderConfig<Test> = {
  name: (_, row) => (
    <Typography.Body2>
      <EllipsisCell text={row.name} />
    </Typography.Body2>
  ),
  status: (_, row) => <TestStatusCell status={row.status} />,
  password: (_, row) => <Password value={row.password} />,
  startDate: (_, row) => formatDate(row.startDate),
  endDate: (_, row) => formatDate(row.endDate),
};

const TestsAndResultsTable = ({ data }: Props): JSX.Element => {
  const configurationWithoutRender = useTestAndResultsColumnsConfiguration();

  const columns = useColumnsCustomRenderConfig<Test>(
    customColumnsRenderConfig,
    configurationWithoutRender
  );

  return (
    <Container>
      <TableComponent
        columns={columns}
        id="tests-and-results-table"
        key="tests-and-results-table"
        rows={data}
      />
    </Container>
  );
};

export { TestsAndResultsTable };
