import styled from '@emotion/styled';
import useControls from '../../hooks/useControls';

import FullScreenIcon from './FullScreenIcon';
import Pagination from './Pagination';
import usePlayerState from '../../providers/PlayerProviders/usePlayerState';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export default function Controls() {
  const { resource, preventArrowKeysEvents, isSinglePdfOrGenially } =
    usePlayerState();
  useControls({ preventArrowKeysEvents });

  const showPagination = resource && 'resources' in resource;
  const showFullScreen = !isSinglePdfOrGenially;

  return (
    <Wrapper>
      {showPagination ? <Pagination /> : <div />}
      {showFullScreen && <FullScreenIcon />}
    </Wrapper>
  );
}
