import { DimensionTag } from '../types/SmartGuide';
import { Dimensions } from '../types/Dimension';

export const DimensionsMap = {
  problem_solving: Dimensions.PROBLEM,
  reasoning_and_proof: Dimensions.REASONING,
  connections: Dimensions.CONNECTIONS,
  communication_and_representation: Dimensions.COMMUNICATION,
};

export function mapDimensionTags(dimensions: any): DimensionTag[] {
  if (!dimensions) return [];

  return Object.entries(dimensions).map(([key, value]) => {
    return {
      textKey: `digitalguides.dimensions.${key}`,
      isEnabled: value as boolean,
      dimension: DimensionsMap[key],
    } as unknown as DimensionTag;
  });
}
