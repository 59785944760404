import styled from '@emotion/styled';
import { Card } from '../card';

const Container = styled(Card)`
  min-height: fit-content;
`;

const ContentWrapper = styled('div')`
  display: flex;
  width: 100%;
  padding: 1.5rem 0 1.5rem 1.5rem;
  flex-direction: column;
`;

const Header = styled('div')``;

const Body = styled('div')`
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    max-height: 17rem;
  }
  @media (max-height: ${({ theme }) =>
      theme.breakpoints.sm}) and (orientation: landscape) {
    min-height: 10rem;
  }
  max-height: calc(100vh - 25rem);
  overflow-y: auto;
  margin-top: 1rem;
  scrollbar-width: thin;
  ::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0.5);
  }

  ::-webkit-scrollbar {
    height: 7px;
    width: 7px;
    border-radius: 9px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.dark['04']};
    border-radius: 10px;
  }
`;

const MessageWrapper = styled('p')`
  min-height: 40px;
  margin-right: 1.5rem;
  color: ${({ theme }) => theme.colors.dark['03']};
`;

const Footer = styled('div')`
  display: flex;
  justify-content: space-between;
  grid-gap: 1rem;
  margin-top: 2.5rem;
  margin-right: 1.5rem;
`;

export { Body, Container, ContentWrapper, Footer, Header, MessageWrapper };
