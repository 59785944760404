import { SVGProps } from 'react';

export const ClassIcon_Legacy = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg height={20} width={20} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        fill="none"
        stroke="#757575"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      >
        <path
          d="M18.438 13.158H1.563a.624.624 0 01-.606-.474l-.313-1.25a.624.624 0 01.606-.776h17.5a.624.624 0 01.606.776l-.313 1.25a.624.624 0 01-.605.474zM2.5 13.158l-1.25 6.25M17.5 13.158l1.25 6.25M11.875 10.658a2.5 2.5 0 015 0"
          strokeWidth={1.249995}
        />
        <path
          d="M12.5 1.908a1.875 1.875 0 011.875 1.875v4.375"
          strokeWidth={1.249995}
        />
        <path
          d="M9.02 1.282a2.5 2.5 0 012.855 4.106zM8.728 10.658H3.125a1.25 1.25 0 010-2.5h5.603M7.478 8.158v2.5M8.728 8.158H3.125a1.25 1.25 0 010-2.5h5.603M7.478 5.657v2.5"
          strokeWidth={1.249995}
        />
      </g>
    </svg>
  );
};
