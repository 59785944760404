import styled from '@emotion/styled';
import { Icon, Tooltip, useSemantic } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';

const TooltipContent = styled.div`
  text-align: center;
`;

function ErrorsIcon(): JSX.Element {
  const { getColor } = useSemantic();
  const { t } = useTranslation();

  return (
    <Tooltip
      content={<TooltipContent>{t('imports.errors.tooltip')}</TooltipContent>}
      appendTo="parent"
    >
      <Icon icon="WarningIcon" size="S" bgColor={getColor('warning')} />
    </Tooltip>
  );
}

export { ErrorsIcon };
