import styled from '@emotion/styled';
import { Typography } from '@innovamat/glimmer-components';
import { usePartColors } from '../../hooks/usePartColors';
import { IconPart } from '../IconPart';
import { PartType } from '../../types/SmartGuide';
import { useSmartGuides } from '../../context/SmartGuidesProvider';
import { IconType } from '@innovamat/glimmer-icons';
import { PrintableBody2 } from '../../printable-styles/printable-typography';
import { Dimension } from '../../types/Dimension';
import { IconEvaluablePrintable } from '../IconEvaluable';

type CollapsablePartProps = {
  part: PartType;
  momentTitle: string;
  activityTitle: string;
  activityTitleExtra?: string;
  children: React.ReactNode;
  partIcon?: IconType | undefined;
  evaluable?: {
    isEvaluable: boolean;
    dimension?: Dimension;
  };
};

const Summary = styled.div`
  break-inside: avoid;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const MomentTitle = styled(Typography.SuperTitle)<{ color: string }>`
  color: ${({ color }) => color};
  font-size: 12px;

  @media print {
    font-size: 10px;
  }
`;

const ActivityTitle = styled(PrintableBody2)``;

const ActivityTitleExtra = styled(PrintableBody2)`
  display: inline;
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};

  @media print {
    color: ${({ theme }) =>
      theme.tokens.color.alias.cm.text['text-subtle'].value};
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledIconEvaluablePrintable = styled(IconEvaluablePrintable)`
  height: auto;
`;

export function CollapsablePartPrintable({
  part,
  momentTitle,
  activityTitle,
  activityTitleExtra,
  children,
  partIcon,
  evaluable,
}: CollapsablePartProps): JSX.Element {
  const { stage } = useSmartGuides();

  const { getElement } = usePartColors();

  const getSummary = (): JSX.Element => {
    return (
      <Summary>
        <TitleContainer>
          <IconPart part={part} stage={stage} icon={partIcon} />
          <Title>
            <MomentTitle color={getElement(part)}>{momentTitle}</MomentTitle>
            <ActivityTitle>
              {activityTitle}
              <ActivityTitleExtra>
                &nbsp;{activityTitleExtra}
              </ActivityTitleExtra>
            </ActivityTitle>
          </Title>
        </TitleContainer>

        {evaluable?.isEvaluable && (
          <StyledIconEvaluablePrintable dimension={evaluable.dimension} />
        )}
      </Summary>
    );
  };

  return (
    <Wrapper>
      {getSummary()}
      <Content>{children}</Content>
    </Wrapper>
  );
}
