import React from 'react';

import { CardProps } from './Card.types';
import { CardWrapper } from './Card.styles';

function Card({ children, ...props }: CardProps) {
  return <CardWrapper {...props}>{children}</CardWrapper>;
}

export default Card;
