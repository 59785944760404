import styled from '@emotion/styled';
import { Tooltip } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';
import { StandardTag } from '../../types/SmartGuide';
import { SimpleBlock } from '../SimpleBlock';
import { Tag } from '../Tag';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  @media print {
    gap: 4px;
  }
`;

type Props = {
  tags?: StandardTag[];
};

export function StandardTags({ tags }: Props) {
  const { t } = useTranslation();

  if (!tags || !tags.length) {
    return null;
  }

  const tagsComesFromPrepr = tags.some((tag) => tag.isFromPrepr);

  const title = !tagsComesFromPrepr
    ? `DYNAMIC: ${t('digitalguides.standards')}`
    : t('digitalguides.standards');

  return (
    <SimpleBlock title={title}>
      <Container>
        {tags.map((tag, index) => {
          return (
            <Tooltip content={t(tag.tooltipKey)}>
              <Tag key={`${tag}-${index}`} text={tag.text} isEnabled={true} />
            </Tooltip>
          );
        })}
      </Container>
    </SimpleBlock>
  );
}
