import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-domv6';

import styled from '@emotion/styled';

import { useQueryParams } from '@innovamat/hooks';
import {
  type Classroom,
  useClassroomsQuery,
  useCourses,
} from '@innovamat/glow-api-client';
import {
  AnnouncementPanel,
  ClassCard,
  ClassCardOptions,
  Grounder,
  HeadingSection,
  Tabs,
  Typography,
} from '@innovamat/glimmer-components';
import { Stage, stages } from '@innovamat/radiance-utils';

import { useUser } from '../../../user-management';
import { useManageClassrooms } from '../../hooks/use-manage-classrooms';
import {
  AddClassroomModal,
  getAvatarImage,
  useNavigation,
  useGoogleClassroomSync,
} from '../../..';

import { AddClassroomDropdown } from '../../components/add-classroom-dropdown';
import { ConfirmationModal } from '../../components/confirmation-modal';

import {
  UpdatePeriodAnnouncementPanel,
  useCheckIsUpdatePeriod,
} from '../../../components/update-period-announcement-panel';

import { SchoolClassroomsEmptyState } from './school-classrooms.empty-state';
import { SchoolClassroomsSkeleton } from './school-classrooms.skeleton';

const TabsWrapper = styled.div`
  margin-top: 0.5rem;
  border-bottom: 1px solid
    ${({ theme }) => theme.tokens.color.alias.cm.border['border-subtle'].value};
`;

export const ListContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const CourseBlock = styled('div')<{ isDisabled?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  ${({ isDisabled }) =>
    isDisabled &&
    `
    pointer-events: none;
    opacity: 0.5;
    filter: grayscale(1);
  `}
`;

export const ClassroomGrid = styled.div`
  display: grid;
  gap: 1rem;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    grid-template-columns: 1fr;
  }
`;

const EmptyStateWrapper = styled.div`
  margin-top: 3.5rem;
`;

const UncheckedClassesAfterYearUpgradeAnnouncementPanel = styled(
  AnnouncementPanel
)`
  margin-top: 1rem;
`;

export function SchoolClassrooms(): JSX.Element {
  const { t } = useTranslation();
  const { user } = useUser();
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const [selectedStage, setSelectedStage] = useState<string>('');
  const queryClient = useQueryClient();

  const { handleRemoveClassroomConnection, removeClassroomConnectionStatus } =
    useGoogleClassroomSync();

  const { courses, groupCoursesByStage, getYearUpdateCourse } = useCourses({
    organizationId: user?.organizationId!,
    regionCode: user?.region!,
  });

  const {
    handleDeleteClassWithConfirmation,
    confirmModalData,
    editModalData,
    openEditModal,
  } = useManageClassrooms();

  const { goToSchoolClassroom } = useNavigation();

  const {
    data: classroomsData,
    isLoading,
    isSuccess,
  } = useClassroomsQuery({ orgId: user?.organizationId!, mine: false });

  const isUpdatePeriod = useCheckIsUpdatePeriod();

  const classrooms = useMemo(
    () => classroomsData?.classrooms as Classroom[],
    [classroomsData]
  );

  const isEmpty = !isLoading && isSuccess && classrooms?.length === 0;

  const availableStages = useMemo(
    () =>
      Array.from(
        new Set(
          classrooms
            ?.filter((classroom) => classroom?.courseOrder)
            ?.sort((a, b) => a?.courseOrder! - b?.courseOrder!)
            .map((classroom) => {
              return stages.getStageFromOrder(classroom?.courseOrder!);
            })
        )
      ),
    [classrooms]
  );

  useEffect(() => {
    if (availableStages.length === 0) return;
    const stage = queryParams.get('stage');

    if (stage && availableStages.includes(stage as Stage)) {
      setSelectedStage(stage);
    } else if (selectedStage === '') {
      setSelectedStage(availableStages[0]!);
    }
    navigate({ search: '' }, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableStages]);

  useEffect(() => {
    if (removeClassroomConnectionStatus.isSuccess) {
      queryClient.invalidateQueries({ queryKey: ['Classrooms'] });
    }
  }, [removeClassroomConnectionStatus]);

  const tabs = availableStages.map((stage) => ({
    id: stage,
    text: t(`common.stage-${stage?.toLocaleLowerCase()}`),
    onClick: () => {
      setSelectedStage(stage as Stage);
    },
    selected: selectedStage === stage,
  }));

  const groupedCourses = groupCoursesByStage(courses);

  const getClassroomsByCourse = useCallback(
    (courseId: string): Classroom[] =>
      classrooms?.filter(
        (classroom) => classroom?.courseId === courseId
      ) as Classroom[],
    [classrooms]
  );

  const handleNavigateToClassroom = (classroomId: string): void => {
    goToSchoolClassroom(classroomId);
  };

  const hasUncheckedClasses = classrooms?.some((classroom) => {
    return (
      !classroom.checked || (classroom.provider && !classroom.providerChecked)
    );
  });

  const createOptions = (classroom: Classroom): ClassCardOptions => {
    const options: ClassCardOptions = {
      edit: {
        text: t('common.edit'),
        onClick: () => {
          openEditModal(classroom);
        },
      },
    };

    if (classroom.provider === 'google') {
      options.unsynchronize = {
        text: t('classrooms.google_classroom.desynchronize'),
        subtitle: t('classrooms.google_classroom.desynchronize.warning'),
        onClick: () => handleRemoveClassroomConnection(classroom.id),
      };
    } else {
      options.delete = {
        text: t('classrooms.deleteClassroom'),
        subtitle: t('my-classes.delete-classroom.warning'),
        onClick: () =>
          handleDeleteClassWithConfirmation({
            classroomName: classroom.name!,
            classroomId: classroom.id!,
          }),
      };
    }
    return options;
  };

  return (
    <>
      <HeadingSection
        title={t('sidebar.items.classrooms-list')}
        actions={
          <AddClassroomDropdown
            disabled={!user || isUpdatePeriod}
            organizationId={user?.organizationId!}
            region={user?.region!}
            options={['add', 'import', 'join']} // TODO: add csv import
          />
        }
      />
      {availableStages.length > 1 && (
        <TabsWrapper>
          <Tabs tabs={tabs} />
        </TabsWrapper>
      )}
      {hasUncheckedClasses && isSuccess && !isUpdatePeriod && (
        <UncheckedClassesAfterYearUpgradeAnnouncementPanel
          text={t('classrooms.updatePeriod.announcement')}
          canClose={false}
          type="imported"
        />
      )}

      <UpdatePeriodAnnouncementPanel />

      <ListContainer>
        {isLoading && <SchoolClassroomsSkeleton />}
        {isEmpty && (
          <EmptyStateWrapper>
            <SchoolClassroomsEmptyState />
          </EmptyStateWrapper>
        )}
        {isSuccess &&
          groupedCourses
            .filter((group) => group.stage === selectedStage)
            .map((group) =>
              group.courses.map((course) => {
                return (
                  getClassroomsByCourse(course.id).length > 0 && (
                    <CourseBlock key={course.id} isDisabled={isUpdatePeriod}>
                      <Typography.H4>{course.name}</Typography.H4>
                      <ClassroomGrid>
                        {getClassroomsByCourse(course.id).map((classroom) => (
                          <ClassCard
                            isDisabled={isUpdatePeriod}
                            isUpdatePeriod={isUpdatePeriod}
                            key={classroom.id}
                            name={classroom.name || ''}
                            course={classroom.courseName || ''}
                            teachers={
                              (classroom.teachersNames as string[]) || []
                            }
                            students={classroom.numberOfStudents || 0}
                            avatar={getAvatarImage({
                              avatar: classroom.avatar,
                              icon: classroom.icon,
                              courseOrder: classroom.courseOrder!,
                            })}
                            t={t}
                            isImportedFromGoogle={
                              classroom.provider === 'google'
                            }
                            yearUpdateNextCourse={getYearUpdateCourse(
                              classroom
                            )}
                            options={createOptions(classroom)}
                            onClick={() =>
                              handleNavigateToClassroom(classroom.id)
                            }
                          />
                        ))}
                      </ClassroomGrid>
                    </CourseBlock>
                  )
                );
              })
            )}
        <Grounder />
      </ListContainer>
      <ConfirmationModal {...confirmModalData} />
      <AddClassroomModal {...editModalData} />
    </>
  );
}
