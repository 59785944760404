import styled from '@emotion/styled';
import { Icon } from '../Icon';
import { Typography } from '../Typography';
import { SemanticType, useSemantic } from '../../hooks/useSemantic';
import { useState } from 'react';

export type Props = React.HTMLAttributes<HTMLDivElement> & {
  text: string | JSX.Element;
  canClose?: boolean;
  type?: SemanticType;
};

const Container = styled.div<{ backgroundColor: string }>`
  width: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 12px 16px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const LeftWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const IconContainer = styled.div`
  padding: 2.5px 0;
`;

export function AnnouncementPanel({
  text,
  canClose = true,
  type = 'info',
  ...rest
}: Props): JSX.Element | null {
  const { getIcon, getBackground, getColor } = useSemantic();
  const [show, setShow] = useState(true);

  if (show === false) return null;
  return (
    <Container role="alert" backgroundColor={getBackground(type)} {...rest}>
      <LeftWrapper>
        <IconContainer>
          <Icon icon={getIcon(type)} bgColor={getColor(type)} size="M" />
        </IconContainer>
        <Typography.Body2>{text}</Typography.Body2>
      </LeftWrapper>
      {canClose && (
        <CloseButton data-testid="close-button" onClick={() => setShow(false)}>
          <Icon icon="CloseIcon" size="M" />
        </CloseButton>
      )}
    </Container>
  );
}
