import styled from '@emotion/styled';

const CountryLanguage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

const CountrySelected = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 6px;
  @media (max-width: ${(props: any) => props.theme.breakpoints.md}) {
    min-width: 160px;
  }
`;

const CountryLanguageTexts = styled.div<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'row' : 'column')};
  align-items: baseline;
  gap: 8px;
`;

const Region = styled.h3`
  font-weight: 600;
  font-size: 14px;
  line-height: 114%;
  /* color: ${({ theme }) => theme.colors.dark['01']}; */
  font-family: ${({ theme }) => theme.typography.rubik};
`;

const Language = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 114%;
  /* color: ${({ theme }) => theme.colors.dark['02']}; */
  font-family: ${({ theme }) => theme.typography.rubik};
`;

export {
  CountryLanguage,
  CountrySelected,
  CountryLanguageTexts,
  Region,
  Language,
};
