import styled from 'styled-components';

import primariaImage from '../../assets/images/primaria_back_button.png';

export const Wrapper = styled.div`
  background: linear-gradient(
    180deg,
    rgba(210, 255, 114, 0.7) -83.33%,
    rgba(24, 198, 172, 0.7) 47.99%
  );
  display: flex;
  flex-direction: column;
  height: ${({ theme }) => theme.appHeight};
`;

export const StyledButton = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  border-radius: 30px;
  box-shadow: 0 7px 15px rgba(0, 0, 0, 0.15);
  color: ${({ theme }) => theme.colors.mintGray};
  display: flex;
  font-family: ${({ theme }) => theme.fonts.bariolBold};
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  padding: 12px 24px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;

  svg {
    fill: ${({ theme }) => theme.colors.mintGray};
    margin-right: 20px;
    transform: rotate(180deg);
  }
`;

export const SelectorArea = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 50px;
  justify-content: center;
  padding: 35px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 6.3vw;
    line-height: 6vw;
  }
  @media (max-width: 420px) {
    flex-direction: column;
  }
  @media (max-height: 770px) {
    gap: 50px;
    justify-content: center;
    padding: 0;
  }
`;

export const Selector = styled.div`
  background: ${({ theme }) => theme.colors.white};
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${primariaImage});
  border-radius: 30px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
  color: ${({ theme }) => theme.colors.brandDark};
  font-family: ${({ theme }) => theme.fonts.bariolBold};
  font-size: 4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 70px;
  max-height: 100%;
  max-width: 100%;
  padding: 25px;
  text-align: center;
  text-transform: uppercase;
  width: 50%;
  height: 60%;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    font-size: 2.5rem;
    line-height: 45px;
  }
  @media (max-height: 770px) {
    height: 55vh;
    width: 380px;
    font-size: 2rem;
    line-height: 45px;
  }
`;
