import { css } from '@emotion/react';

import { typography } from './typography';
import { colors } from './colors';
const robotoBold = 'https://files.innovamat.com/fonts/roboto/roboto-bold.woff2';
const robotoItalic =
  'https://files.innovamat.com/fonts/roboto/roboto-italic.woff2';
const robotoLight =
  'https://files.innovamat.com/fonts/roboto/roboto-light.woff2';
const robotoRegular =
  'https://files.innovamat.com/fonts/roboto/roboto-regular.woff2';
const robotoMedium =
  'https://files.innovamat.com/fonts/roboto/roboto-medium.woff2';
const rubik01 = 'https://files.innovamat.com/fonts/rubik/rubik01.woff2';
const rubik02 = 'https://files.innovamat.com/fonts/rubik/rubik02.woff2';
const rubik03 = 'https://files.innovamat.com/fonts/rubik/rubik03.woff2';
const rubik04 = 'https://files.innovamat.com/fonts/rubik/rubik04.woff2';
const rubik05 = 'https://files.innovamat.com/fonts/rubik/rubik05.woff2';

export const GlobalStyles = css`
  @font-face {
    font-family: 'Roboto';
    src: url(${robotoBold}) format('woff2');
    font-weight: bold;
    font-display: swap;
    font-style: normal;
  }
  @font-face {
    font-family: 'Roboto';
    src: url(${robotoItalic}) format('woff2');
    font-weight: normal;
    font-display: swap;
    font-style: italic;
  }
  @font-face {
    font-family: 'Roboto';
    src: url(${robotoLight}) format('woff2');
    font-weight: 300;
    font-display: swap;
    font-style: normal;
  }
  @font-face {
    font-family: 'Roboto';
    src: url(${robotoRegular}) format('woff2');
    font-weight: normal;
    font-display: swap;
    font-style: normal;
  }
  @font-face {
    font-family: 'Roboto';
    src: url(${robotoMedium}) format('woff2');
    font-weight: 500;
    font-display: swap;
    font-style: normal;
  }
  @font-face {
    font-family: 'Roboto Medium';
    src: url(${robotoMedium}) format('woff2');
    font-weight: 500;
    font-display: swap;
    font-style: normal;
  }

  /* cyrillic-ext */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(${rubik03}) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
      U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(${rubik05}) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* hebrew */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(${rubik04}) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(${rubik02}) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
      U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(${rubik01}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }
  /* cyrillic-ext */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(${rubik03}) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
      U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(${rubik05}) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* hebrew */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(${rubik04}) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(${rubik02}) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
      U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(${rubik01}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }
  /* cyrillic-ext */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(${rubik03}) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
      U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(${rubik05}) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* hebrew */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(${rubik04}) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(${rubik02}) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
      U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(${rubik01}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }
  /* cyrillic-ext */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(${rubik03}) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
      U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(${rubik05}) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* hebrew */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(${rubik04}) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(${rubik02}) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
      U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(${rubik01}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }
  /* cyrillic-ext */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(${rubik03}) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
      U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(${rubik05}) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* hebrew */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(${rubik04}) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(${rubik02}) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
      U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(${rubik01}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  input,
  textarea,
  button,
  select {
    outline: none;
  }

  body {
    color: ${colors.black};
    font-family: ${typography.regular};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  button {
    color: ${colors.black};
    font-family: ${typography.regular};
  }

  input,
  textarea {
    line-height: ${typography.lineHeight.medium};
  }

  a {
    text-decoration: none;
    color: ${colors.brand.dark['02']};
  }

  h1 {
    font-size: ${typography.typeScale.xlarge};
    font-weight: ${typography.weight.regular};
    line-height: ${typography.lineHeight.xlarge};
  }

  h2 {
    font-size: ${typography.typeScale.large};
    font-weight: ${typography.weight.regular};
    line-height: ${typography.lineHeight.large};
  }

  h3 {
    font-size: ${typography.typeScale.caption};
    font-weight: ${typography.weight.regular};
    line-height: ${typography.lineHeight.caption};
  }

  h4,
  .h4 {
    font-size: ${typography.typeScale.medium};
    font-weight: ${typography.weight.regular};
    line-height: ${typography.lineHeight.medium};
  }

  h5,
  .h5 {
    font-family: ${typography.medium};
    font-size: ${typography.typeScale.normal};
    font-weight: ${typography.weight.medium};
    line-height: ${typography.lineHeight.normal};
  }

  h6,
  .h6 {
    font-family: ${typography.medium};
    font-size: ${typography.typeScale.small};
    font-weight: ${typography.weight.medium};
    line-height: ${typography.lineHeight.small};
  }

  .h7 {
    font-family: ${typography.medium};
    font-size: ${typography.typeScale.xsmall};
    font-weight: ${typography.weight.medium};
    line-height: ${typography.lineHeight.xsmall};
  }

  .h8 {
    font-family: ${typography.medium};
    font-size: ${typography.typeScale.micro};
    font-weight: ${typography.weight.medium};
    line-height: ${typography.lineHeight.micro};
  }

  label {
    font-family: ${typography.medium};
    font-size: ${typography.typeScale.small};
    line-height: ${typography.lineHeight.small};
  }

  .full-width {
    width: 100%;
  }

  .ripple {
    position: relative;
    overflow: hidden;

    &::after {
      display: none;
      content: '';
      position: absolute;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.2);
      width: 100px;
      height: 100px;
      margin-top: -50px;
      margin-left: -50px;
      top: 50%;
      left: 50%;
      animation: ripple 1.5s;
      opacity: 0;
    }

    &:focus:not(:active)::after {
      display: block;
    }
  }

  .no-radius {
    border-radius: 0 !important;
  }

  @keyframes ripple {
    from {
      opacity: 1;
      transform: scale(0);
    }

    to {
      opacity: 0;
      transform: scale(10);
    }
  }
`;
