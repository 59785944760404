import { ReactNode, useEffect, useState } from 'react';

import { IConfigCatClient } from 'configcat-common';

import create from '../../utils/featureFlags';

import FeatureFlagsContext from './FeatureFlagsContext';

type Props = {
  children: ReactNode;
};

function FeatureFlagsProvider({ children }: Props): JSX.Element {
  const [featureFlags, setFeatureFlags] = useState<IConfigCatClient>(
    {} as IConfigCatClient
  );

  useEffect(() => {
    const refetchOnceAnHour = 3200;
    const featureFlagsClient = create(refetchOnceAnHour);
    setFeatureFlags(featureFlagsClient);
  }, []);

  return (
    <FeatureFlagsContext.Provider value={featureFlags}>
      {children}
    </FeatureFlagsContext.Provider>
  );
}

export default FeatureFlagsProvider;
