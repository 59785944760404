export const breakpoints = {
  xs: '16px',
  sm: '600px',
  md: '960px',
  lg: '1280px',
  xl: '1920px',
};

function pixelToRem(value: number) {
  return value / 16;
}

export const breakpointsRem = Object.assign(
  {},
  {},
  ...Object.keys(breakpoints).map((key) => {
    return {
      [key]: pixelToRem(
        Number(breakpoints[key as keyof typeof breakpoints].replace('px', ''))
      ),
    };
  })
);
