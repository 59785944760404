import * as React from 'react';
import type { SVGProps } from 'react';
const SvgWorld = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#333"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.933}
      d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14"
    />
    <path
      stroke="#333"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.933}
      d="M6.68 14.876c-.976-1.37-1.636-3.939-1.636-6.845s.66-5.537 1.637-6.875M9.32 14.876c.976-1.37 1.636-3.939 1.636-6.845s-.66-5.537-1.637-6.875M1 8h14M1.81 11.267H14.19M1.81 4.733H14.19"
    />
    <script type="text/javascript" />
  </svg>
);
export default SvgWorld;
