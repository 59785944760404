import { ImageComponent } from '@innovamat/glimmer-components';
import styled from '@emotion/styled';

const StyledImage = styled(ImageComponent)`
  display: block;
  width: auto;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
  border-color: ${({ theme }) => theme.colors.brand.dark['01']};
`;
// TODO -> any A React.NodeElement
type Props = {
  image: string | any | undefined;
};

export default function ImageTooltip({ image }: Props) {
  const isAnImageUrl = typeof image === 'string';
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{isAnImageUrl ? <StyledImage src={image} alt="" /> : image}</>;
}
