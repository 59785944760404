import defaultAppletThumbnail from './defaultApplet.png';
import { useLazyLoad } from '@innovamat/hooks';
import { useTranslation } from 'react-i18next';
import {
  Tooltip,
  Icon,
  theme,
  ResourceCard,
} from '@innovamat/glimmer-components';
import { Applet, WeeklyPractice } from '@innovamat/glow-api-client';
import { ContentListSkeleton } from '../../../contents';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { normalizeImgSource } from '@innovamat/ga-features';

const Title = styled.h4`
  display: flex;
  gap: 16px;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 16px;
`;

const Container = styled.div<{ isDisabled: boolean }>`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;

  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : '')};

  img {
    z-index: 0 !important;
    min-height: auto !important;
  }

  ${({ theme }) =>
    css`
      @media (max-width: ${theme.breakpoints.xl}) {
        grid-template-columns: repeat(4, 1fr);
      }

      @media (max-width: ${theme.breakpoints.lg}) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media (max-width: ${theme.breakpoints.md}) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: ${theme.breakpoints.sm}) {
        grid-template-columns: 1fr;
      }
    `};
`;

export const LoaderContainer = styled.div`
  > div {
    padding: 0px !important;
  }
`;

const RelativeContainer = styled.div`
  position: relative;
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

type Props = {
  session: WeeklyPractice | null | undefined;
  handleStartApplet: (applet: Applet) => void;
  loading: boolean;
  loadMore: boolean;
  isStudentViewActive: boolean;
};

function CatchUpZone({
  session,
  handleStartApplet,
  loading,
  loadMore,
  isStudentViewActive,
}: Props) {
  const { t } = useTranslation();
  if (!session?.activities?.length && !loadMore) {
    return null;
  }
  return (
    <>
      <Title>
        {t('weeklyPractice.subtitle.catchUpZone')}
        <RelativeContainer>
          <Tooltip
            content={
              isStudentViewActive
                ? t('weeklyPractice.toolTip.catchUpZone.studentViewMode')
                : t('weeklyPractice.toolTip.catchUpZone')
            }
            popperOptions={{ strategy: 'fixed' }}
            placement="right"
            maxWidth={165}
          >
            <StyledIcon
              icon="InfoIcon"
              size="M"
              hasStateLayer
              iconColor="white"
              bgColor={theme.tokens.color.alias.cm.icon['icon-info'].value}
            />
          </Tooltip>
        </RelativeContainer>
      </Title>
      <Container isDisabled={isStudentViewActive}>
        {session?.activities?.map((applet) => (
          <ResourceCard
            title={applet?.data?.name || applet?.sceneName || ''}
            type="applet"
            description={applet?.data?.description || ''}
            thumbnail={
              applet?.data?.thumbnail ||
              normalizeImgSource(defaultAppletThumbnail)
            }
            key={`${applet?.pack}${applet?.sceneName}`}
            onClick={() => handleStartApplet(applet!)}
            t={t}
            stars={applet?.stars}
          />
        ))}
      </Container>
      {loading && (
        <LoaderContainer>
          <ContentListSkeleton showTitle={false} />
        </LoaderContainer>
      )}
    </>
  );
}

export default CatchUpZone;
