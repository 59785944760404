import styled from '@emotion/styled';

import {
  ChallengeTitle,
  ArrowActionButton,
  InnerHtml,
} from '@innovamat/innova-components';
import { getMomentColor } from '../../utils/colorsSelector';
import MomentBlockKeyMoment from './MomentBlock/MomentBlock.KeyMoment';

type Props = {
  id: string;
  title: string;
  index: number;
  heading: string;
  action: JSX.Element | undefined;
  onClick: () => void;
  keyMoment: boolean;
  momentType: string;
  getIsOpen: (key: number) => boolean;
  icon: JSX.Element | undefined;
};

const TitleWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
`;

const Actions = styled.div`
  display: flex;
  gap: 12px;
`;

export default function MomentBlockTitle({
  title,
  index,
  heading,
  onClick,
  action,
  keyMoment,
  momentType,
  getIsOpen,
  icon,
}: Props) {
  const getColor = () => {
    const colors = getMomentColor(momentType);
    return {
      bgColor: colors.light,
      color: colors.dark,
    };
  };

  //TODO any to avoid internal type error
  const getTitle = (): any => {
    return <InnerHtml text={title} />;
  };

  return (
    <Wrapper onClick={onClick}>
      <TitleWrapper>
        <ChallengeTitle
          challengeBoxChildren={icon}
          title={getTitle()}
          challengeTitle={heading}
          size="md"
          {...getColor()}
        />
        {keyMoment && <MomentBlockKeyMoment />}
      </TitleWrapper>
      <Actions>
        {action}
        <ArrowActionButton isOpen={getIsOpen(index)} className="actionButton" />
      </Actions>
    </Wrapper>
  );
}
