import { useTheme } from '@emotion/react';
import { Dimension } from '../types/Dimension';

export function useDimensionColors() {
  const theme = useTheme();
  const guideTokens = theme.tokens.color.specific['smart-guides'];

  const getDimensionBackground = (dimension?: Dimension) => {
    switch (dimension) {
      case 1:
        return guideTokens['problem-background'].value;
      case 2:
        return guideTokens['reasoning-background'].value;
      case 3:
        return guideTokens['connections-background'].value;
      case 4:
        return guideTokens['communication-background'].value;
      default:
        return theme.tokens.color.alias.cm.surface['surface-secondary'].value;
    }
  };

  const getDimensionHighlightedBackground = (dimension?: Dimension) => {
    switch (dimension) {
      case 1:
        return guideTokens['reasoning-background'].value;
      case 2:
        return guideTokens['communication-background'].value;
      case 3:
        return guideTokens['problem-background'].value;
      case 4:
        return guideTokens['connections-background'].value;
      default:
        return theme.tokens.color.alias.cm.surface['surface-secondary'].value;
    }
  };

  const getDimensionElement = (dimension?: Dimension) => {
    switch (dimension) {
      case 1:
        return guideTokens['problem-element'].value;
      case 2:
        return guideTokens['reasoning-element'].value;
      case 3:
        return guideTokens['connections-element'].value;
      case 4:
        return guideTokens['communication-element'].value;
      default:
        return theme.tokens.color.alias.cm.icon['icon-default'].value;
    }
  };

  return {
    getDimensionBackground,
    getDimensionElement,
    getDimensionHighlightedBackground,
  };
}
