import { datadogRum } from '@datadog/browser-rum';
import { getClient } from 'configcat-js';
import { User as UserConfiCat } from 'configcat-common';
import { User } from './types/user';

type Flags = 'rumSampleRateConfiguration';

export async function getFlagValue(
  ApiKey: string,
  key: Flags,
  defaultValue?: boolean,
  user?: UserConfiCat | undefined
): Promise<boolean> {
  const configCatClient = getClient(ApiKey);
  return await configCatClient.getValueAsync(key, defaultValue || false, user);
}

async function getSessionSampleRate(
  configCatApiKey: string,
  environment: string,
  sessionSampleRate: number,
  organizationId?: string,
  userId?: string
): Promise<number> {
  const isFlaggedUser = await getFlagValue(
    configCatApiKey,
    'rumSampleRateConfiguration',
    false,
    {
      identifier: '',
      custom: {
        schoolId: organizationId || '',
        userId: userId || '',
      },
    }
  );
  return isFlaggedUser || environment === 'staging' ? 100 : sessionSampleRate;
}

export function initDatadog(
  envVariables: any,
  sampleRates: {
    sessionSampleRate: number;
    sessionReplaySampleRate: number;
  },
  { uid, school: organizationId }: Partial<User>
): void {
  const {
    DATADOG_CLIENT_TOKEN,
    DATADOG_APPLICATION_ID,
    DATADOG_ENVIRONMENT,
    DATADOG_SERVICE,
    COMMIT_ID,
    CONFIG_CAT_KEY,
  } = envVariables;

  const { sessionSampleRate, sessionReplaySampleRate } = sampleRates;
  getSessionSampleRate(
    CONFIG_CAT_KEY,
    DATADOG_ENVIRONMENT,
    sessionSampleRate,
    organizationId,
    uid
  ).then((conditionalSampleRate) => {
    datadogRum.init({
      clientToken: DATADOG_CLIENT_TOKEN,
      applicationId: DATADOG_APPLICATION_ID,
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: 'datadoghq.eu',
      service: DATADOG_SERVICE,
      env: DATADOG_ENVIRONMENT,
      // Specify a version number to identify the deployed version of your application in Datadog
      version: COMMIT_ID,
      sessionSampleRate: conditionalSampleRate,
      sessionReplaySampleRate: sessionReplaySampleRate,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      useSecureSessionCookie: true,
      usePartitionedCrossSiteSessionCookie: true,
      /* allowedTracingUrls: [
        /https:\/\/.*\.innovamat\.com/,
        /https:\/\/.*\.innovamat\.cloud/,
      ],
      beforeSend: (event) => {
        if (
          event.type === 'error' &&
          event.error.message.includes('Ignored attempt to cancel')
        ) {
          return false;
        }
        // Ensure a boolean is always returned
        return true;
      }, */
    });

    datadogRum.setUser({
      id: uid,
      organization: organizationId,
    });
    // Init network detection issues
    networkDetection();
  });
}

export function networkDetection(): void {
  //Network detection
  let offlineTimestamp: number | null = null;
  let onlineTimestamp = null;
  // - offline
  window.addEventListener('offline', function () {
    offlineTimestamp = Date.now();
    datadogRum.addAction('network_status', {
      status: 'offline',
      timestamp: offlineTimestamp,
    });
  });

  // - online
  window.addEventListener('online', function () {
    onlineTimestamp = Date.now();
    datadogRum.addAction('network_status', {
      status: 'online',
      timestamp: onlineTimestamp,
    });

    if (offlineTimestamp) {
      const elapsedTimeInSeconds = (onlineTimestamp - offlineTimestamp) / 1000;
      const error = new Error(
        `User lost connection. Time offline: ${elapsedTimeInSeconds} seconds`
      );
      error.name = 'ConnectionError';
      datadogRum.addError(error, {
        duration: elapsedTimeInSeconds,
      });
      // Reset the offline timestamp
      offlineTimestamp = null;
    }
  });
}
