import '@emotion/react';

import { borders } from './borders';
import { breakpoints } from './breakpoints';
import { colors } from './colors';
import { elevations } from './elevations';
import { sizes } from './sizes';
import { spaces } from './spaces';
import { typography } from './typography';
import tokens from '../theme/tokens/theme/light.json';

const fonts = {
  roboto: `'Roboto', sans-serif`,
  robotoMedium: `'Roboto Medium', sans-serif`,
  rubik: `'Rubik', sans-serif`,
  bariol: `'Bariol', sans-serif`,
  bariolBold: `'Bariol Bold', sans-serif`,
};

interface ThemeType {
  borders: typeof borders;
  breakpoints: typeof breakpoints;
  colors: typeof colors;
  elevations: typeof elevations;
  typography: typeof typography;
  spaces: typeof spaces;
  sizes: typeof sizes;
  tokens: typeof tokens;
  fonts: typeof fonts;
}

const theme: ThemeType = {
  borders,
  breakpoints,
  colors,
  elevations,
  typography,
  spaces,
  sizes,
  tokens,
  fonts,
};

export { type ThemeType, theme };
