import styled from '@emotion/styled';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-domv6';

import type { Organization, Teacher } from '@innovamat/glow-api-client';
import {
  useClassroomQuery,
  useOrganizationQuery,
  useTeachersQuery,
} from '@innovamat/glow-api-client';

import {
  AnnouncementPanel,
  Button,
  HeadingSection,
  Typography,
} from '@innovamat/glimmer-components';

import { useCurrentNavigationValues } from '../../../contents';
import { APP_PATHS, useNavigation } from '../../../navigation';
import { useUser } from '../../../user-management';

import {
  UpdatePeriodAnnouncementPanel,
  useCheckIsUpdatePeriod,
} from '../../../components/update-period-announcement-panel';

import { ClassroomTeachersTable } from './components/classroom-teachers-table';
import { AssignTeachersToClassroomModal } from './components/assign-teachers-to-classroom-modal';
import { ClassroomTeachersTableSkeleton } from './components/classroom-teachers-table/classroom-teachers-table.skeleton';

const ChaeckClassroomAfterUpdatePeriodAnnouncementPanel = styled(
  AnnouncementPanel
)`
  margin-top: 1rem;
`;

const ClassroomTeachers = (): JSX.Element => {
  const { classroomId } = useParams<{ classroomId: string }>();
  const [
    isAddTeacherToClassroomModalOpen,
    setIsAddTeacherToClassroomModalOpen,
  ] = useState(false);
  const { t } = useTranslation();
  const { user } = useUser();
  const queryClient = useQueryClient();
  const { currentClassroom } = useCurrentNavigationValues();
  const { navigateInSameNavType } = useNavigation();
  const isUpdatePeriod = useCheckIsUpdatePeriod();

  const organizationId = user?.organizationId ?? '';

  const { data: organizationQuery } = useOrganizationQuery(
    { id: organizationId },
    { enabled: !!organizationId, staleTime: Infinity }
  );

  const isPlatformEnabled =
    organizationQuery?.organization?.platformEnabled ?? false;

  const { data } = useClassroomQuery(
    {
      id: classroomId ?? '',
      orgId: organizationQuery?.organization?.id ?? '',
    },
    {
      enabled: !!classroomId && !!organizationQuery?.organization?.id,
    }
  );

  const { data: teachersQuery, isLoading: isLoadingTeachers } =
    useTeachersQuery({
      classroomId: classroomId ?? '',
      organizationId,
      isPlatformEnabled,
    });

  const classroomName = data?.classroom?.name ?? '';

  const onSuccessAddTeachers = (): void => {
    const queryKey = useTeachersQuery.getKey({
      classroomId: classroomId ?? '',
      organizationId,
      isPlatformEnabled,
    });

    queryClient.refetchQueries({ queryKey });
  };

  const isClassroomCheckedAfterUpdatePeriod =
    !currentClassroom?.checked ||
    (currentClassroom.provider && !currentClassroom.providerChecked);

  return (
    <>
      <HeadingSection
        title={t('reports.admin.title.teachers')}
        actions={
          <Button
            disabled={isUpdatePeriod}
            onClick={() => setIsAddTeacherToClassroomModalOpen(true)}
            rightIcon="AddUpIcon"
            variant="secondary"
          >
            {t('teachers.buttons.addTeachers')}
          </Button>
        }
      />
      {isClassroomCheckedAfterUpdatePeriod && (
        <ChaeckClassroomAfterUpdatePeriodAnnouncementPanel
          text={
            <Trans
              i18nKey="classroom.updatePeriod.announcement"
              components={{
                a: (
                  <Typography.Link2
                    onClick={() =>
                      navigateInSameNavType(APP_PATHS.CLASSROOM_STUDENTS_URL)
                    }
                  />
                ),
              }}
            />
          }
          type="imported"
          canClose={false}
        />
      )}

      <UpdatePeriodAnnouncementPanel />

      {isLoadingTeachers || !classroomId ? (
        <ClassroomTeachersTableSkeleton />
      ) : (
        <ClassroomTeachersTable
          classroomId={classroomId!}
          classroomName={classroomName}
          isDisabled={isUpdatePeriod}
          isPlatformEnabled={isPlatformEnabled}
          onAddTeachers={() => setIsAddTeacherToClassroomModalOpen(true)}
          organizationId={organizationId}
          teachers={(teachersQuery?.teachers as Teacher[]) ?? []}
        />
      )}

      {isAddTeacherToClassroomModalOpen && (
        <AssignTeachersToClassroomModal
          classroomId={classroomId!}
          classroomName={classroomName}
          inviterId={user?.id ?? ''}
          onClose={() => setIsAddTeacherToClassroomModalOpen(false)}
          onSuccess={onSuccessAddTeachers}
          organization={organizationQuery?.organization as Organization}
        />
      )}
    </>
  );
};

export { ClassroomTeachers };
