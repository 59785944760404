import { SVGProps } from 'react';

export const CatFlag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19 12"
    fill="none"
    {...props}
  >
    <g clip-path="url(#clip0_2124_1798)">
      <path d="M18.5 0.375H0.5V11.625H18.5V0.375Z" fill="#FFD066" />
      <path
        d="M0.5 2.25H18.5H0.5ZM18.5 4.75H0.5H18.5ZM0.5 7.25H18.5H0.5ZM18.5 9.75H0.5H18.5Z"
        fill="black"
      />
      <path
        d="M0.5 2.25H18.5M18.5 4.75H0.5M0.5 7.25H18.5M18.5 9.75H0.5"
        stroke="#CC1F00"
        stroke-width="1.25"
      />
    </g>
    <defs>
      <clipPath id="clip0_2124_1798">
        <rect
          x="0.5"
          y="0.375"
          width="18"
          height="11.25"
          rx="1.125"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);
