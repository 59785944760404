import { SVGProps } from 'react';

export const FraFlag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 12"
    fill="none"
    {...props}
  >
    <g clip-path="url(#clip0_2124_1815)">
      <path
        d="M17.9971 0.375067H11.9971V11.6251H17.9971V0.375067Z"
        fill="#CC1F00"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M-0.0043335 11.6247H5.99566V0.374731H-0.0043335V11.6247Z"
        fill="#2365AB"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.00006 11.6242H12.0001V0.374243H6.00006V11.6242Z"
        fill="white"
      />
      <path
        d="M5.99585 0.429512H11.9958"
        stroke="#949494"
        stroke-width="0.1125"
      />
      <path
        d="M5.99585 11.5663H11.9958"
        stroke="#949494"
        stroke-width="0.1125"
      />
    </g>
    <defs>
      <clipPath id="clip0_2124_1815">
        <rect
          x="6.10352e-05"
          y="0.375"
          width="18"
          height="11.25"
          rx="1.125"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);
