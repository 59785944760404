import { Input, Modal } from '@innovamat/glimmer-components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty, validators } from '@innovamat/radiance-utils';
import styled from '@emotion/styled';

type Props = {
  open: boolean;
  loading?: boolean;
  onClose: () => void;
  onSubmit: (password: string) => void;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export function ChangePasswordModal({
  open,
  loading,
  onClose,
  onSubmit,
}: Props) {
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleSubmit = () => {
    onSubmit(password);
  };

  useEffect(() => {
    setPassword('');
    setConfirmPassword('');
    setShowPassword(false);
    setShowConfirmPassword(false);
  }, [open]);

  const isValid =
    !isEmpty(password) &&
    !isEmpty(confirmPassword) &&
    validators.passwordIsValid(password) &&
    password === confirmPassword;

  return (
    <Modal
      title={t('common.updatePassword')}
      isOpen={open}
      modalWidth={400}
      buttons={[
        {
          children: t('common.saveAnSend'),
          onClick: loading ? undefined : handleSubmit,
          disabled: !isValid,
          variant: 'accent',
          loading,
        },
        {
          children: t('common.cancel'),
          onClick: onClose,
          variant: 'tertiary',
        },
      ]}
      onClose={onClose}
    >
      <Container>
        <Input
          autoComplete="new-password"
          labelText={t('students.table.changePassord.field.password')}
          descriptiveText={
            !validators.passwordIsValid(password)
              ? t('students.table.changePassord.password.error')
              : t('students.table.changePassord.password.tagline')
          }
          required
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          rightIcon={showPassword ? 'ViewOffIcon' : 'ViewIcon'}
          onIconClick={() => setShowPassword((prevState) => !prevState)}
          status={
            password && !validators.passwordIsValid(password)
              ? 'error'
              : undefined
          }
        />
        <Input
          autoComplete="new-password"
          labelText={t('students.table.changePassord.field.confirmPassword')}
          required
          type={showConfirmPassword ? 'text' : 'password'}
          descriptiveText={
            confirmPassword !== password
              ? t('students.table.changePassord.confirmPassword.error')
              : undefined
          }
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          rightIcon={showConfirmPassword ? 'ViewOffIcon' : 'ViewIcon'}
          onIconClick={() => setShowConfirmPassword((prevState) => !prevState)}
          status={
            confirmPassword && confirmPassword !== password
              ? 'error'
              : undefined
          }
        />
      </Container>
    </Modal>
  );
}
