/* eslint-disable */
// @ts-nocheck
const DEFAULT_REGION: RootRegion = 'ES';

// List of base regions used on the corporate website
const ROOT_REGIONS = ['ES', 'IT', 'US', 'CL', 'EC', 'MX', 'CO', 'IN2'] as const;

type RootRegion = typeof ROOT_REGIONS[number];

const rootRegionToRegion = (region: string = DEFAULT_REGION) => {
  if (region === 'EC') return 'EC-CO';
  if (region === 'CO') return 'CO-A';
  return region;
};

const isValidRegion = (region: string) => {
  const mappedRegion = rootRegionToRegion(region);
  return !!REGIONS.includes(mappedRegion as Region);
};

const TEST_LATAM_FALLBACK = 'es-mx,en;q=0.9,en_US;q=0.8';
const TESTS_LANGUAGE_MAPPING = {
  es_419: TEST_LATAM_FALLBACK,
  es_EC: TEST_LATAM_FALLBACK,
  es_MX: TEST_LATAM_FALLBACK,
  es_CO: TEST_LATAM_FALLBACK,
  es_CL: TEST_LATAM_FALLBACK,
  it_IT: 'it,en;q=0.9,en_US;q=0.8',
  va: 'va-es,en;q=0.9,en_US;q=0.8',
};

// Languages to display in backoffice
const CONTENT_LANGUAGES: string[] = [
  'es',
  'ca',
  'eu',
  'va',
  'en',
  'es_419',
  'es_EC',
  'fr_FR',
  'es_MX',
  'it_IT',
  'en_US',
  'pt_BR',
  'sq',
  'be',
  'es_CO',
];

const LANGUAGES = {
  region: {
    ES: {
      locale: {
        es: {
          fallback: [],
        },
        eu: {
          fallback: ['es'],
        },
        en: {
          fallback: ['en_US', 'es'],
        },
        ca: {
          fallback: ['es'],
        },
        va: {
          fallback: ['ca', 'es'],
        },
      },
    },
    IT: {
      locale: {
        it_IT: {
          fallback: ['en', 'en_US'],
        },
        en: {
          fallback: ['en_US', 'it_IT'],
        },
      },
    },
    'CO-A': {
      locale: {
        es_CO: {
          fallback: ['es_419', 'es'],
        },
        en_US: {
          fallback: ['en', 'es_CO', 'es_419', 'es'],
        },
      },
    },
    'CO-B': {
      locale: {
        es_CO: {
          fallback: ['es_419', 'es'],
        },
        en_US: {
          fallback: ['en', 'es_CO', 'es_419', 'es'],
        },
      },
    },
    'EC-CO': {
      locale: {
        es_EC: {
          fallback: ['es_419', 'es'],
        },
        en_US: {
          fallback: ['en', 'es_EC', 'es_419', 'es'],
        },
      },
    },
    'EC-SI': {
      locale: {
        es_EC: {
          fallback: ['es_419', 'es'],
        },
        en_US: {
          fallback: ['en', 'es_EC', 'es_419', 'es'],
        },
      },
    },
    CL: {
      locale: {
        es_CL: {
          fallback: ['es_419', 'es'],
        },
        en_US: {
          fallback: ['en', 'es_CL', 'es_419', 'es'],
        },
      },
    },
    MX: {
      locale: {
        es_MX: {
          fallback: ['es_419', 'es'],
        },
        en_US: {
          fallback: ['en', 'es_MX', 'es_419', 'es'],
        },
      },
    },
    BR: {
      locale: {
        pt_BR: {
          fallback: [],
        },
        en_US: {
          fallback: ['pt_BR'],
        },
      },
    },
    US: {
      locale: {
        en_US: {
          fallback: ['en'],
        },
        es_MX: {
          fallback: ['es'],
        },
      },
    },
    'US-LIT': {
      locale: {
        en_US: {
          fallback: ['en'],
        },
        es_MX: {
          fallback: ['es'],
        },
      },
    },
    'US-EDR': {
      locale: {
        en_US: {
          fallback: ['en'],
        },
        es_MX: {
          fallback: ['es'],
        },
      },
    },
  },
} as const;

type Region = keyof typeof LANGUAGES['region'];

const REGIONS = Object.keys(LANGUAGES.region) as Region[];
const REGISTER_LANGUAGES = transformLanguages(LANGUAGES);

const getRegisterLanguagesByRegion = (
  region: string = DEFAULT_REGION
): string[] => {
  const mappedRegion = rootRegionToRegion(region);
  const clonedRegisterLanguages = deepClone(REGISTER_LANGUAGES);

  if (!clonedRegisterLanguages[mappedRegion]) {
    return clonedRegisterLanguages[DEFAULT_REGION];
  }
  return clonedRegisterLanguages[mappedRegion];
};

function transformLanguages(
  languages: typeof LANGUAGES
): Record<Region, string[]> {
  const registerLanguages: Record<Region, string[]> = {} as Record<
    Region,
    string[]
  >;

  for (const region in languages.region) {
    if (languages.region.hasOwnProperty(region)) {
      const locales = languages.region[region as Region].locale;
      registerLanguages[region as Region] = Object.keys(locales);
    }
  }

  return registerLanguages;
}

const deepClone = (obj: any) => JSON.parse(JSON.stringify(obj));

const publicAvailableLanguages = [
  { label: 'Español', value: 'es' },
  { label: 'Euskara', value: 'eu' },
  { label: 'English', value: 'en' },
  { label: 'Català', value: 'ca' },
  { label: 'Valencià', value: 'va' },
  { label: 'Italiano', value: 'it_IT' },
  { label: 'Português', value: 'pt_BR' },
] as const;

export {
  CONTENT_LANGUAGES,
  DEFAULT_REGION,
  LANGUAGES,
  REGIONS,
  REGISTER_LANGUAGES,
  ROOT_REGIONS,
  TESTS_LANGUAGE_MAPPING,
  getRegisterLanguagesByRegion,
  isValidRegion,
  rootRegionToRegion,
  publicAvailableLanguages,
};

export type { Region, RootRegion };
