import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDoneinclass = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M9.375 14.375a5 5 0 1 0 10 0 5 5 0 0 0-10 0"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="m16.603 12.922-2.42 3.227a.627.627 0 0 1-.943.068l-1.25-1.25M7.52 17H4.084a.9.9 0 0 1-.419-.087 1.1 1.1 0 0 1-.355-.27 1.3 1.3 0 0 1-.234-.41A1.4 1.4 0 0 1 3 15.75V3.34c-.01-.344.098-.678.301-.929.203-.25.484-.399.782-.411h10.832c.297.012.579.16.782.411s.311.585.301.928V7M1.667 12.917h3M1.667 9.167h3M1.667 5.417h3"
    />
  </svg>
);
export default SvgDoneinclass;
