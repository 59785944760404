import { Trans, useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { SxProps, Theme } from '@mui/material/styles';

import {
  Column,
  EmptyState,
  HeadingSection,
  TableComponent,
  ThemeType,
  useGlimmerTheme,
} from '@innovamat/glimmer-components';
import {
  AnnouncementPanel,
  Chip,
  FileNameButton,
  Typography,
} from '@innovamat/glimmer-components';
import { dates } from '@innovamat/radiance-utils';

import { ErrorsIcon, ImportDropdown } from '../components';
import { useImportCSV } from '../providers';
import { useGetCSVImports } from '../hooks';
import { statusToSemantic } from '../utils';
import { UpdatePeriodAnnouncementPanel } from '../../components/update-period-announcement-panel';

const tableStyles = (theme: ThemeType): SxProps<Theme> => ({
  'th > div > h6': {
    color: theme.tokens.color.alias.cm.text['text-subtle'].value,
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: 400,
  },
  'th > div': {
    minHeight: '2rem !important',
  },
  'tbody tr:hover': {
    backgroundColor:
      theme.tokens.color.alias.cm.surface['surface-secondary'].value,
  },
  th: {
    borderColor:
      theme.tokens.color.specific.reports.tables['divider-secondary'].value,
  },
  td: {
    height: '4rem',
  },

  '.filename-tooltip': {
    display: 'block',
    overflow: 'hidden',
  },

  '.MuiTableCell-root': {
    backgroundColor: 'transparent !important',
  },
});

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const Wrapper = styled.div`
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  table {
    margin-top: 0px;

    td {
      padding: 0.25rem 0;
    }

    color: ${({ theme }) =>
      theme.tokens.color.alias.cm.text['text-subtle'].value};
  }
`;

const NumOfErrorsWrapper = styled(Typography.Body3)`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

const AnnouncementAnchor = styled.a`
  color: ${({ theme }) => theme.tokens.color.alias.cm.text.text.value};
  text-decoration: underline;
`;

type ImportsRow = {
  fileName: string;
  uploadDate: string;
  numOfErrors: number | null;
  status: string;
};

function Imports(): JSX.Element {
  const { organizationId } = useImportCSV();
  const theme = useGlimmerTheme();
  const {
    data: imports,
    isLoading,
    isError,
    isSuccess,
  } = useGetCSVImports({ organizationId });

  const { t } = useTranslation();

  const rows: ImportsRow[] =
    imports?.map((data) => ({
      fileName: data.originalFilename,
      uploadDate: dates.getFormattedTimeStamp(data.createdAt),
      numOfErrors: data.items?.filter((item) => item.status === 'failed')
        .length,
      status: data.status,
    })) || [];

  const columns: Column<ImportsRow>[] = [
    {
      id: 'fileName',
      subLabel: t('imports.column.file', 'File name'),
      width: 50,
      render: (_, { fileName }) => {
        return <FileNameButton fileName={fileName} />;
      },
    },
    {
      id: 'uploadDate',
      subLabel: t('imports.column.upload', 'Upload date'),
      width: 15,
      render: (_, { uploadDate }) => {
        return <Typography.Body3>{uploadDate}</Typography.Body3>;
      },
    },
    {
      id: 'numOfErrors',
      subLabel: t('imports.column.errors', 'nº of errors'),
      width: 12,
      render: (_, { numOfErrors }) => {
        if (!numOfErrors || numOfErrors === 0) return null;
        return (
          <NumOfErrorsWrapper>
            <span>{numOfErrors}</span>
            <ErrorsIcon />
          </NumOfErrorsWrapper>
        );
      },
    },
    {
      id: 'status',
      subLabel: t('imports.column.status', 'Status'),
      width: 12,
      render: (_, { status }) => {
        return (
          <Chip
            type={statusToSemantic(status)}
            text={t(`imports.status.${status}`)}
          />
        );
      },
    },
  ];

  const tableIsEmpty = isSuccess && rows.length === 0;

  return (
    <SectionWrapper>
      <HeadingSection
        title={t('school.imports', 'Imports')}
        actions={
          <ImportDropdown
            addNewImportButtonText={t('school.button.new_import')}
          />
        }
      />
      <UpdatePeriodAnnouncementPanel />
      <Wrapper>
        {!isError && (
          <AnnouncementPanel
            text={
              <Trans
                i18nKey="imports.reminder"
                components={{
                  a: (
                    <AnnouncementAnchor
                      href={t('school.import_csv.select.specification_url')}
                      target="_blank"
                    />
                  ),
                }}
              />
            }
          />
        )}
        {!isLoading && tableIsEmpty ? (
          <EmptyState
            title={t('imports.table.no_imports')}
            subtitle={t('imports.table.no_imports.subtitle')}
            action={
              <ImportDropdown
                addNewImportButtonText={t('school.button.new_import')}
              />
            }
          />
        ) : (
          <div>
            <TableComponent
              id="imports-table"
              columns={columns}
              rows={rows}
              sx={tableStyles(theme)}
            />
          </div>
        )}
        {isError && (
          <Trans
            i18nKey="imports.table.error"
            components={{
              b: <b />,
            }}
          />
        )}
      </Wrapper>
    </SectionWrapper>
  );
}

export { Imports };
