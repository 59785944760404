import * as React from 'react';
import type { SVGProps } from 'react';
const SvgChangingAgrupation = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      clipPath="url(#changing-agrupation_svg__a)"
    >
      <path d="M5.455 17.874a9.09 9.09 0 0 1 7.024-16.623M14.546 2.126a9.091 9.091 0 0 1-6.943 16.647" />
      <path d="m2.632 18.629 2.823-.756-.757-2.823M17.368 1.371l-2.822.756.756 2.823M10.149 9.793a2.686 2.686 0 1 0 0-5.371 2.686 2.686 0 0 0 0 5.371M14.327 13.926a4.397 4.397 0 0 0-8.264 0" />
    </g>
    <defs>
      <clipPath id="changing-agrupation_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgChangingAgrupation;
