import {
  IconButton,
  ModalOverlay,
  Typography,
} from '@innovamat/glimmer-components';
import { PlayButton } from './play-button';
import { SectionEmptyState } from '../section-empty-state';
import { useEffect, useRef, useState } from 'react';
import { PreprPlayer } from '@innovamat/prepr.io';
import { extractPlaybackId } from '@innovamat/radiance-utils';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useEventLogging } from '@innovamat/event-logging';

type ParentVideoProps = {
  title?: string;
  url?: string;
  link?: string;
  thumbnail?: string;
};

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 152px;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;

  &:hover .video-bg {
    filter: brightness(0.7) blur(3px);
    width: 104%;
    height: 104%;
  }

  :hover .play-button {
    transform: translate(-50%, -50%) scale(1.1);
  }
`;

const VideoContainerBackground = styled.div<{ imgUrl?: string }>`
  background-color: #f0f0f0;
  background-image: url(${({ imgUrl }) => imgUrl});
  filter: brightness(0.8) blur(2px);
  background-position: center;
  background-size: cover;
  width: 102%;
  height: 102%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.2s ease;
`;

const TimeTag = styled(Typography.Body3)`
  position: absolute;
  bottom: 16px;
  right: 16px;
  background-color: rgba(204, 204, 204, 0.7);

  padding: 4px 8px;
  border-radius: 4px;
`;

const PlayButtonWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.2s ease;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const StyledLink = styled(Typography.Link2)`
  white-space: nowrap;
`;

const PlayerWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 4px;
  flex-direction: column;
  align-items: flex-end;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 90vw;
  }
`;

export function ParentVideo({
  title,
  url,
  link,
  thumbnail,
}: ParentVideoProps): JSX.Element {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [duration, setDuration] = useState<string | null>(null);
  const { setEventData } = useEventLogging();

  const thumbnailUrl =
    thumbnail ||
    (url && `https://image.mux.com/${extractPlaybackId(url)}/thumbnail.jpg`);

  const handleLoadedMetadata = (event: any) => {
    const videoDuration = event.target.duration;
    const formattedDuration = new Date(videoDuration * 1000)
      .toISOString()
      .substr(14, 5);
    setDuration(formattedDuration);
  };

  const playerRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (isOpen && playerRef.current) {
      playerRef.current.play();
    }
    if (!isOpen && playerRef.current) {
      playerRef.current.pause();
    }
  }, [isOpen]);

  const handleOpen = () => {
    setIsOpen(true);
    setEventData('parent_launcher_video_start', { video_url: url });
  };

  return (
    <>
      <Container>
        <TitleWrapper>
          <Typography.H3>
            {title || t('error-page.not-available')}
          </Typography.H3>
          {link && (
            <StyledLink
              onClick={() => {
                setEventData('parent_launcher_know_more', { url: link });
              }}
              href={link}
              target="_blank"
            >
              {t('parent-launcher.more-info')}
            </StyledLink>
          )}
        </TitleWrapper>
        {url ? (
          <VideoContainer onClick={handleOpen}>
            <VideoContainerBackground
              className="video-bg"
              imgUrl={thumbnailUrl}
            />
            <PlayButtonWrapper className="play-button">
              <PlayButton onClick={() => {}} />
            </PlayButtonWrapper>
            {duration && <TimeTag>{duration}</TimeTag>}
          </VideoContainer>
        ) : (
          <SectionEmptyState />
        )}
      </Container>
      {url && (
        <ModalOverlay
          keepMounted
          isOpen={isOpen}
          closeOnClickOutside
          closeOnEsc
          onClose={() => setIsOpen(false)}
          element={
            <PlayerWrapper>
              <IconButton
                onClick={() => setIsOpen(false)}
                icon="CloseIcon"
                iconColor="white"
              />
              <PreprPlayer
                myRef={playerRef}
                playbackId={extractPlaybackId(url)!}
                onLoadedMetadata={handleLoadedMetadata}
                metadata={{
                  videoId: extractPlaybackId(url)!,
                  videoTitle: title!,
                }}
              />
            </PlayerWrapper>
          }
        />
      )}
    </>
  );
}
