import styled from '@emotion/styled';
import { ClassCardHeader } from '../class-card/class-card.header';
import { StateLayer } from '../../utils/common.styled';
import { IconType } from '@innovamat/glimmer-icons';
import { Icon } from '../Icon';
import { Typography } from '../Typography';

export type NavItemProps = {
  name: string;
  description?: string;
  avatar?: string;
  selected?: boolean;
  icon?: IconType;
  fitContent?: boolean;
  onClick?: () => void;
  className?: string;
  isCourse?: boolean;
  hasBorder?: boolean;
};

const Container = styled.div<{
  fitContent: boolean;
  selected: boolean;
  hasBorder?: boolean;
  isCourse?: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: ${({ isCourse }) => (isCourse ? '16px' : '8px')};
  border-radius: 4px;
  cursor: ${({ selected }) => (selected ? 'default' : 'pointer')};
  background-color: ${({ theme, selected }) =>
    selected
      ? theme.tokens.color.alias.cm.bg['bg-accent'].value
      : theme.tokens.color.alias.cm.surface['surface-primary'].value};
  width: ${({ fitContent }) => (fitContent ? 'fit-content' : '100%')};
  height: 50px;
  border: ${({ hasBorder, theme }) =>
    hasBorder
      ? `1px solid ${theme.tokens.color.alias.cm.border['border-subtle'].value}`
      : 'none'};

  ${({ selected, theme }) =>
    selected
      ? `
    .avatar-container {
      border: 1px solid ${theme.tokens.color.alias.cm.border['border-accent'].value};
    }
  `
      : `
  :hover {
    .class-item-state-layer {
      background-color: ${theme.tokens.color.specific['state-layer']['state-hover-darker'].value};
      border-radius: 4px;
    }
  }

  :active {
    .class-item-state-layer {
      background-color: ${theme.tokens.color.specific['state-layer']['state-press-darker'].value};
    }
    border-radius: 4px;
  `};
`;

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const RightSideWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 8px;
`;

const Dot = styled.div`
  min-width: 5px;
  min-height: 5px;
  border-radius: 50%;
  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.bg['bg-accent-inverted'].value};
`;

export function NavItem({
  name,
  description,
  avatar,
  selected = false,
  icon,
  fitContent = false,
  onClick,
  className,
  hasBorder,
  isCourse,
}: NavItemProps): JSX.Element {
  return (
    <Container
      fitContent={fitContent}
      selected={selected}
      onClick={onClick}
      className={className}
      hasBorder={hasBorder}
      isCourse={isCourse}
    >
      <StateLayer className="class-item-state-layer" />
      {isCourse ? (
        <ItemContainer>
          <Typography.Subtitle2>{name}</Typography.Subtitle2>
          <RightSideWrapper>
            {selected && <Dot />}
            {icon && <Icon icon={icon} size="S" />}
          </RightSideWrapper>
        </ItemContainer>
      ) : (
        <ClassCardHeader
          name={name}
          course={description}
          avatar={avatar}
          rightSide={
            <RightSideWrapper>
              {selected && <Dot />}
              {icon && <Icon icon={icon} size="S" />}
            </RightSideWrapper>
          }
        />
      )}
    </Container>
  );
}
