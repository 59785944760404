import styled from '@emotion/styled';

const CountryLanguage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

const CountrySelected = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 6px;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    min-width: 160px;
  }
`;

const CountryLanguageTexts = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Language = styled.h3`
  font-weight: 600;
  font-size: 14px;
  line-height: 114%;
  font-family: ${({ theme }) => theme.typography.rubik};
`;

export { CountryLanguage, CountrySelected, CountryLanguageTexts, Language };
