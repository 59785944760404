import { Icons } from '@innovamat/innova-components';
import styled from '@emotion/styled';
import BlockContent from '../BlockContent';
import BlockWithIcon from '../BlockWithIcon';
import { Text } from '../Text';
import { VideoChapterContent } from '../../types/videoChapter';
import { InnerHtmlParser } from '../InnerHtmlParser';
import { useDigitalGuides } from '../../context';

const StyledText = styled(Text)`
  color: ${({ theme }) => theme.colors.dark['01']};
`;

type Props = {
  videoChapter: VideoChapterContent;
};

export default function MomentsBlockVideoChapter({ videoChapter }: Props) {
  const { t } = useDigitalGuides();

  const getIcon = (itemNumber?: string): keyof typeof Icons => {
    switch (itemNumber) {
      case '1':
        return 'SamSocioafectives';
      case '2':
        return 'SamMath';
      case '3':
        return 'SamHistory';
      default:
        return 'SamCultural';
    }
  };

  const getTitle = (itemNumber?: string) => {
    const baseKey = 'digitalguides.se.moment.video';
    switch (itemNumber) {
      case '1':
        return t(`${baseKey}.social`);
      case '2':
        return t(`${baseKey}.math`);
      case '3':
        return t(`${baseKey}.history`);
      default:
        return t(`${baseKey}.cultural`);
    }
  };

  const getItems = () => {
    const items = videoChapter?.items.filter((item) => 'content' in item.items);

    return items.map((item, index) => {
      if (!item?.items?.content) return null;

      const itemNumber = item.items.themes?.body;
      return (
        <BlockWithIcon icon={getIcon(itemNumber)} key={index}>
          <BlockContent title={t(getTitle(itemNumber))}>
            <StyledText>
              <InnerHtmlParser text={item.items.content} />
            </StyledText>
          </BlockContent>
        </BlockWithIcon>
      );
    });
  };

  return <>{getItems()}</>;
}
