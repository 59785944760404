import styled from '@emotion/styled';

const NavigationWrapper = styled.div`
  display: flex;
  align-items: start;
  margin-left: auto;
  margin-right: 0px !important;
  .navigation-dropdown-content {
    width: 388px;
    max-height: 368px;
    padding: 0 24px 24px 24px;
    overflow-y: auto;
    gap: 16px;
    margin-top: 8px;

    display: flex;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
    border-radius: 4px;

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      width: 300px;
      max-width: calc(100vw - 12px);
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      width: 200px;
      margin-left: 12px;
    }

    .supertitle {
      padding: 4px 0;
    }
  }
`;

const StickyTitle = styled.div`
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 11;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Divider = styled.div`
  margin-top: -11px;
  width: 323px;
  height: 0px;
  border-bottom: 1px solid #ededed;
  max-width: 100%;
`;

const NavigationListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export { NavigationWrapper, Divider, NavigationListWrapper, StickyTitle };
