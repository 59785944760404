import styled from '@emotion/styled';
import { useEventLogging } from '@innovamat/event-logging';
import {
  Dropdown,
  getTypography,
  NavItem,
  Skeleton,
} from '@innovamat/glimmer-components';
import { SelectCourse, useCourses } from '@innovamat/glow-api-client';
import { getNextAcademicYearId } from '@innovamat/ga-features';
import { useUser } from '../../../user-management';

type CourseNavigatorProps = {
  courseId: string;
  onSelectCourse: (courseId: string) => void;
  onToggle?: (isOpen: boolean) => void;
};

const DropdownContainer = styled(Dropdown.Content)`
  width: 292px;
  padding: 8px;
  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.surface['surface-primary'].value};
  gap: 4px;
  z-index: 20;

  h6 {
    ${({ theme }) => getTypography(theme, 'Body 2')}
  }
`;

const NavItemWrapper = styled(Dropdown.Item)`
  display: block;
  padding: 0;
  width: 100%;

  :hover {
    .dropdownItem-stateLayer {
      background-color: transparent;
    }
  }
`;

const ListContainer = styled.div`
  width: 100%;
  height: 292px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export function CourseNavigator({
  courseId,
  onSelectCourse,
  onToggle,
}: CourseNavigatorProps): JSX.Element {
  const { user } = useUser();

  const nextAcademicYearId = user?.organization?.academicYearId
    ? getNextAcademicYearId(user?.organization?.academicYearId)
    : undefined;
  const { coursesForSelectCoursePage } = useCourses({
    nextAcademicYearId: nextAcademicYearId,
    activeLevel: 'MANAGER',
    organizationId: user?.organizationId!,
    regionCode: user?.region!,
  });

  const { setEventData } = useEventLogging();

  const selectedCourse = coursesForSelectCoursePage.find(
    (course) => course?.id === courseId
  );

  const courses = coursesForSelectCoursePage.sort(
    (a, b) => a?.order! - b?.order!
  );

  const handleSelectCourse = (selectedCourse: SelectCourse) => {
    setEventData('change_course', {
      from_course: selectedCourse.order,
    });
    onSelectCourse(selectedCourse.id!);
  };

  if (selectedCourse) {
    return (
      <Dropdown closeOnSelectItem onToggle={(value) => onToggle?.(value)}>
        <Dropdown.Toggle>
          <NavItem
            className="navigator-toggle"
            name={selectedCourse.name!}
            icon="ArrangeIcon"
            isCourse
            hasBorder
          />
        </Dropdown.Toggle>

        {courses && (
          <DropdownContainer
            className="navigator-content"
            contentSpace="none"
            elevation="elevation 4"
            borderRadius="8px"
          >
            <ListContainer>
              {courses.map((course) => (
                <NavItemWrapper
                  state="active"
                  onSelectItem={() => handleSelectCourse(course)}
                >
                  <NavItem
                    key={course?.id}
                    name={course?.name!}
                    selected={course?.id === selectedCourse.id}
                    isCourse
                    icon={course.available ? undefined : 'LockedIcon'}
                  />
                </NavItemWrapper>
              ))}
            </ListContainer>
          </DropdownContainer>
        )}
      </Dropdown>
    );
  }

  return <Skeleton width="100%" height="48px" />;
}
