import { useContentLists } from '@innovamat/glow-api-client';

import styled from '@emotion/styled';
import { Loader } from '@innovamat/glimmer-components';
import { ContentListType } from '../../types';
import AssessableResourcesList from './assessable-resources.list';

const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

type AssessableResourcesProps = {
  assessableLists: string[];
  sessionId: string;
  sessionName: string | undefined;
  classroomId: string;
  setEventData: (event: string, data: Record<string, any>) => void;
  academicYearId?: string;
  isStudent: boolean;
  language: string;
};

export function AssessableResources({
  assessableLists,
  sessionId,
  sessionName,
  classroomId,
  setEventData,
  academicYearId,
  isStudent,
  language,
}: AssessableResourcesProps) {
  const { data: translatedLists, isLoading } = useContentLists({
    contentListsIds: assessableLists,
    classroomId,
    academicYearId: academicYearId!,
    isStudentView: false,
  });

  if (isLoading) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  return (
    <>
      {translatedLists &&
        translatedLists.map((section: ContentListType) => (
          <AssessableResourcesList
            sectionName={section.name}
            resources={section.resources}
            sessionName={sessionName}
            sessionId={sessionId}
            classroomId={classroomId}
            setEventData={setEventData}
            key={section.id}
            academicYearId={academicYearId}
            isStudent={isStudent}
            language={language}
          />
        ))}
    </>
  );
}
