import { InnerHtml, Typography } from '@innovamat/glimmer-components';
import styled from '@emotion/styled';
import AssessableResourcesElement from './assessable-resources.element';
import { ContentResource } from '../../types';

type AssessableResourcesListProps = {
  sectionName: string;
  resources: ContentResource[];
  sessionId: string;
  sessionName: string | undefined;
  classroomId: string;
  setEventData: (event: string, data: Record<string, unknown>) => void;
  academicYearId?: string;
  isStudent: boolean;
  language: string;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
`;

const Subtitle = styled(Typography.H4)`
  width: 100%;
  height: 36px;
  padding: 4px 0px;
  gap: 8px;

  border-bottom: 1px solid
    ${({ theme }) => theme.tokens.color.alias.cm.border['border-subtle'].value};
`;

const FilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

export default function AssessableResourcesList({
  sectionName,
  resources,
  sessionId,
  sessionName,
  classroomId,
  setEventData,
  academicYearId,
  isStudent,
  language,
}: AssessableResourcesListProps): JSX.Element {
  return (
    <Wrapper>
      <Subtitle className="list-subtitle">
        <InnerHtml text={sectionName} />
      </Subtitle>
      <FilesContainer>
        {resources.map((resource: ContentResource) => (
          <AssessableResourcesElement
            resource={resource}
            sessionId={sessionId}
            sessionName={sessionName}
            classroomId={classroomId}
            setEventData={setEventData}
            academicYearId={academicYearId}
            isStudent={isStudent}
            language={language}
          />
        ))}
      </FilesContainer>
    </Wrapper>
  );
}
