import { SVGProps } from 'react';

export const ItaFlag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 12"
    fill="none"
    {...props}
  >
    <g clip-path="url(#clip0_2124_1801)">
      <path
        d="M6.10352e-05 0.375H18.0001V11.625H6.10352e-05V0.375Z"
        fill="#51A329"
      />
      <path d="M6.00006 0.375H18.0001V11.625H6.00006V0.375Z" fill="white" />
      <path d="M12.0001 0.375H18.0001V11.625H12.0001V0.375Z" fill="#CC1F00" />
      <path
        d="M6.00024 0.42334H12.0002"
        stroke="#949494"
        stroke-width="0.1125"
      />
      <path
        d="M6.00024 11.569H12.0002"
        stroke="#949494"
        stroke-width="0.1125"
      />
    </g>
    <defs>
      <clipPath id="clip0_2124_1801">
        <rect
          x="6.10352e-05"
          y="0.375"
          width="18"
          height="11.25"
          rx="1.125"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);
