import * as React from 'react';
import type { SVGProps } from 'react';
const SvgFileXls = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="m4 10 2.4 6M6.4 10 4 16M8.798 10.002v4.8a1.2 1.2 0 0 0 1.2 1.2h1.2M15.998 10.002h-1.2a1.2 1.2 0 0 0-1.2 1.2c0 1.8 2.4 1.8 2.4 3.6a1.2 1.2 0 0 1-1.2 1.2h-1.2"
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M18.649 4.849a1.2 1.2 0 0 1 .351.848v11.505A1.8 1.8 0 0 1 17.198 19h-14.4A1.8 1.8 0 0 1 1 17.202v-14.4A1.8 1.8 0 0 1 2.798 1h11.505a1.2 1.2 0 0 1 .848.351z"
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M14.2 1v3.6a1.2 1.2 0 0 0 1.2 1.2H19"
    />
  </svg>
);
export default SvgFileXls;
