import styled from '@emotion/styled';
import { css } from '@emotion/react';

import type { CardProps } from './card.types';
import { getElevation } from '../elevation';

const CardWrapper = styled.div<CardProps>(
  ({
    backgroundColor,
    borderRadius,
    elevation = 'elevation 2',
    hasBorder,
    height,
    theme,
    width,
  }) => {
    return css`
      align-items: center;
      background-color: ${backgroundColor ||
      theme.tokens.color.alias.cm.surface['surface-primary'].value};
      border-radius: ${borderRadius
        ? theme.borders.radius[borderRadius]
        : theme.borders.radius.sm};
      border: ${hasBorder
        ? `${theme.borders.size['02']} ${theme.colors.dark['05']}`
        : 'none'};
      display: flex;
      height: ${height};
      justify-content: center;
      width: ${width};
      ${getElevation(theme, elevation)};
    `;
  }
);

export { CardWrapper };
