import { useContext } from 'react';

import { AuthDispatchContext } from '../providers/AuthProvider';

export function useAuthDispatch() {
  const context = useContext(AuthDispatchContext);

  if (context === undefined) {
    throw new Error('usePlayerDispatch must be used within a AuthProvider');
  }

  return context;
}
