import styled from '@emotion/styled';
import { Avatar, AvatarProps } from '../avatar';
import { css } from '@emotion/react';
import { createContext, useContext } from 'react';

const AvatarGroupWrapper = styled.div<{
  isOverlapEnabled: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  isDisabled?: boolean;
}>`
  display: flex;

  ${({ isOverlapEnabled }) =>
    isOverlapEnabled &&
    css`
      > div {
        margin-left: 0;

        &:not(:first-child) {
          margin-left: -20px;
        }
      }
    `}

  ${({ onClick }) =>
    !!onClick &&
    css`
      cursor: pointer;
    `}

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      pointer-events: none;
    `}
`;

const StyledAvatar = styled(Avatar)`
  &:not(:first-child) {
    box-shadow: 0 0 0 2px
      ${({ theme }) => theme.tokens.color.global.white.value};
  }
`;

type Props<T> = {
  isDisabled?: boolean;
  isOverlapEnabled?: boolean;
  maxElements?: number;
  items: T[];
  render: (item: T) => JSX.Element;
} & React.HTMLAttributes<HTMLDivElement>;

const AvatarGroupContext = createContext<{ isDisabled?: boolean }>({});

export function AvatarGroup<T>({
  items,
  render,
  isOverlapEnabled = true,
  maxElements = 3,
  isDisabled,
  ...rest
}: Props<T>): JSX.Element {
  return (
    <AvatarGroupContext.Provider value={{ isDisabled }}>
      <AvatarGroupWrapper
        isOverlapEnabled={isOverlapEnabled}
        isDisabled={isDisabled}
        {...rest}
      >
        {items.slice(0, maxElements).map((item) => render(item))}
      </AvatarGroupWrapper>
    </AvatarGroupContext.Provider>
  );
}

function AvatarGroupAvatar(props: AvatarProps): JSX.Element {
  const { isDisabled } = useContext(AvatarGroupContext);
  return <StyledAvatar isDisabled={isDisabled} size="M" {...props} />;
}

AvatarGroup.Avatar = AvatarGroupAvatar;
