import * as React from 'react';
import type { SVGProps } from 'react';
const SvgArrowUpReports = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={12}
    fill="none"
    {...props}
  >
    <path
      fill="#51A329"
      d="M5.47.47a.75.75 0 0 1 1.06 0l4.773 4.773a.75.75 0 1 1-1.06 1.06L6 2.061 1.757 6.303a.75.75 0 0 1-1.06-1.06zM6.75 11a.75.75 0 0 1-1.5 0zm0-10v10h-1.5V1z"
    />
  </svg>
);
export default SvgArrowUpReports;
