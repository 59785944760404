import * as React from 'react';
import type { SVGProps } from 'react';
const SvgChatUser = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      transform="scale(.83333)"
    >
      <path d="M12.75 15.75h3v4.5l4.5-4.5h1.494a1.506 1.506 0 0 0 1.506-1.506V2.25a1.5 1.5 0 0 0-1.5-1.5h-12a1.5 1.5 0 0 0-1.5 1.5v4.5m11.625 1.125" />
      <path d="M19.875 7.875a.375.375 0 1 0 .375.375.375.375 0 0 0-.375-.375m-7.5 0a.375.375 0 1 0 .375.375.375.375 0 0 0-.375-.375m3.75 0a.375.375 0 1 0 .375.375.375.375 0 0 0-.375-.375" />
      <circle cx={6.75} cy={13.125} r={3.375} />
      <path d="M12.75 23.25a6.054 6.054 0 0 0-12 0" />
    </g>
  </svg>
);
export default SvgChatUser;
