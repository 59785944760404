import { Typography, useGlimmerTheme } from '@innovamat/glimmer-components';
import { MinorText } from '../../admin-dashboard.styled';
import styled from '@emotion/styled';

type Props = {
  avgInnovamatScore: number;
};

const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const AverageText = styled(Typography.Subtitle1)<{ color: string }>`
  color: ${({ color }) => color};
`;

const LOWER_PERCENTAGE = -5;
const LOW_PERCENTAGE = 0;
const MEDIUM_PERCENTAGE = 5;

const AverageInnovamat = ({ avgInnovamatScore }: Props): JSX.Element => {
  const theme = useGlimmerTheme();

  const getColorAverage = (avgInnovamatScore: number): string => {
    if (avgInnovamatScore < LOWER_PERCENTAGE) {
      return theme.tokens.color.specific.reports.semantic.low.value;
    }
    if (avgInnovamatScore < LOW_PERCENTAGE) {
      return theme.tokens.color.specific.reports.semantic.medium.value;
    }
    if (avgInnovamatScore < MEDIUM_PERCENTAGE) {
      return theme.tokens.color.specific.reports.semantic.high.value;
    }

    return theme.tokens.color.specific.reports.semantic['super-high'].value;
  };

  return (
    <Container>
      <AverageText color={getColorAverage(avgInnovamatScore)}>
        {avgInnovamatScore > 0 && '+'}
        {avgInnovamatScore}
        <MinorText>%</MinorText>
      </AverageText>
    </Container>
  );
};
export default AverageInnovamat;
