import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBigZoomIn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <g
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.5}
      clipPath="url(#big-zoom-in_svg__a)"
    >
      <path d="M31.458 16.352a15.104 15.104 0 1 1-30.208.003 15.104 15.104 0 0 1 30.208-.003M8.75 16.248h15M16.25 23.748v-15M27.035 27.032 38.75 38.748" />
    </g>
    <defs>
      <clipPath id="big-zoom-in_svg__a">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgBigZoomIn;
