interface AuthErrorType {
  detail?: string;
  status?: number;
  title?: string;
  type?: string;
}

export class AuthError implements AuthErrorType {
  detail: string | undefined;

  status: number | undefined;

  title: string | undefined;

  type: string | undefined;

  constructor(response: AuthErrorType) {
    this.detail = response.detail;
    this.status = response.status;
    this.title = response.title;
    this.type = response.type;
  }
}
