import { useEventListener } from '@innovamat/hooks';
import { useCallback, useLayoutEffect, useState } from 'react';

export function useIsExpandable(
  hasPreview: boolean,
  expanded: boolean,
  contentRef: React.RefObject<HTMLDivElement>,
  previewHeight: number
) {
  const [isExpandable, setIsExpandable] = useState(false);

  const handleIsExpandable = useCallback(() => {
    const INNER_PADDING = 8;

    if (!hasPreview) return setIsExpandable(true);
    if (!expanded && hasPreview && !!contentRef.current) {
      const contentWidth =
        contentRef.current.querySelector('p')?.querySelector('p')
          ?.scrollWidth ||
        contentRef.current.querySelector('span')?.scrollWidth;
      const divWidth = contentRef.current!.clientWidth;
      const divHeight = contentRef.current!.clientHeight;

      setIsExpandable(
        contentWidth! + INNER_PADDING > divWidth! || divHeight! > previewHeight!
      );
    }
  }, [hasPreview, expanded, contentRef, previewHeight]);

  useEventListener('resize', handleIsExpandable);

  useLayoutEffect(() => {
    setTimeout(() => {
      handleIsExpandable();
    }, 500);
  }, [previewHeight, handleIsExpandable]);

  const hasImage = !!contentRef?.current?.querySelector('.mediaContainer');
  if (hasImage) return true;

  return isExpandable;
}
