import styled from '@emotion/styled';

import usePlayerState from '../../../providers/PlayerProviders/usePlayerState';
import { IconButton } from '@innovamat/glimmer-components';

import { css } from '@emotion/react';
import { useWebviewBridge } from '@innovamat/webview-bridge';

const StyledIconButton = styled(IconButton)<{
  isInFullScreen: boolean;
}>`
  ${({ isInFullScreen, theme }) =>
    isInFullScreen &&
    css`
      svg,
      path {
        fill: white;
      }
      border-radius: 4px;

      :hover .icon-stateLayer {
        background-color: ${theme.tokens.color.specific['state-layer'][
          'state-hover-lighter'
        ].value};
      }

      :active .icon-stateLayer {
        background-color: ${theme.tokens.color.specific['state-layer'][
          'state-press-lighter'
        ].value};
      }
    `}
`;

export default function FullScreenIcon() {
  const { isInIOS } = useWebviewBridge();
  const { translations, fullscreen, fullscreenIOS, handleFullScreenInIOS } =
    usePlayerState();
  const isInFullScreen = fullscreen?.active || fullscreenIOS;

  const handleFullScreen = () => {
    if (fullscreen?.active) {
      fullscreen.exit();
    } else {
      fullscreen?.enter();
    }
  };

  return (
    <StyledIconButton
      tooltipText={
        isInFullScreen
          ? `${translations['iconFullscreen.player.tooltip.minimise']}`
          : `${translations['iconFullscreen.player.tooltip.expand']}`
      }
      isInFullScreen={isInFullScreen}
      size="M"
      icon={isInFullScreen ? 'ShrinkIcon' : 'FullScreenIcon'}
      onClick={isInIOS ? handleFullScreenInIOS : handleFullScreen}
      dataTestId="fullscreenButton"
    />
  );
}
