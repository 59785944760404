import { IConfigCatClient } from 'configcat-common';
import * as configcat from 'configcat-js';

function create(cacheTimeToLiveSeconds = 60): IConfigCatClient {
  const { NX_CONFIG_CAT_KEY } = process.env;

  return configcat.getClient(
    NX_CONFIG_CAT_KEY || '',
    configcat.PollingMode.AutoPoll,
    { pollIntervalSeconds: cacheTimeToLiveSeconds }
  );
}

export default create;
