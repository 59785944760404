import { ImageComponent } from '@innovamat/glimmer-components';
import styled from '@emotion/styled';

const Image = styled(ImageComponent)`
  aspect-ratio: 16/9;
  border-radius: 8px;
  object-fit: cover;
`;

export default Image;
