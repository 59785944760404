import * as React from 'react';
import type { SVGProps } from 'react';
const SvgFileSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M3.125 5.625h8.75M3.125 9.375H6.25M3.125 13.125H6.25M6.25 16.875H1.875a1.25 1.25 0 0 1-1.25-1.25V1.875a1.25 1.25 0 0 1 1.25-1.25h8.858a1.25 1.25 0 0 1 .883.366l2.393 2.393a1.25 1.25 0 0 1 .366.884V6.25"
      />
      <path
        strokeWidth={1.249995}
        d="M8.762 13.125a4.375 4.375 0 1 0 8.75 0 4.375 4.375 0 1 0-8.75 0M19.375 19.375l-3.144-3.157"
      />
    </g>
  </svg>
);
export default SvgFileSearch;
