import styled from '@emotion/styled';
import { Tooltip, Typography } from '@innovamat/glimmer-components';

const Background = styled.div`
  display: flex;
  padding: 0.75rem;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 0.75rem;
  border: 1px solid
    ${({ theme }) => theme.tokens.color.alias.cm.border['border-subtle'].value};
  background: ${({ theme }) =>
    theme.tokens.color.alias.cm.surface['surface-secondary'].value};
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 2rem;
  border-radius: 0.5rem;
  border: 1px solid
    ${({ theme }) => theme.tokens.color.alias.cm.border['border-subtle'].value};
  background: ${({ theme }) =>
    theme.tokens.color.alias.cm.surface['surface-primary'].value};
`;

const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
`;

const CardInfoRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
  min-width: 0;
`;

const CardInfoItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;

const SubtleCaptionText = styled(Typography.Caption)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

const InfoItemTooltip = styled(Tooltip)`
  max-width: 100%;
`;

const InfoItemText = styled(Typography.Body3)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

const ErrorText = styled(Typography.Body1)`
  text-align: start;
`;

export {
  Background,
  CardContainer,
  CardInfo,
  CardInfoRow,
  CardInfoItemWrapper,
  SubtleCaptionText,
  InfoItemTooltip,
  InfoItemText,
  ErrorText,
};
