import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Card } from '../card';
import { Typography } from '../Typography';

import { ACTION_TYPES } from './material-card.types';
import type { ActionType } from './material-card.types';

const CardWrapper = styled.div<{ isOpened: boolean }>(({ isOpened }) => {
  const height = isOpened ? '13.813rem' : '11.563rem';
  return css`
    height: ${height};
    position: relative;
    min-width: 100%;
    transition: height 0.2s forwards;
  `;
});

const CardBody = styled('div')`
  height: 100%;
  min-width: 100%;
  position: relative;
  padding: 0.25rem;
`;

const CardContent = styled(Card)`
  position: absolute;
`;

const Controls = styled(Card)`
  z-index: -1;
  bottom: 0;
`;

const Image = styled('img')`
  width: 100%;
  height: 7.0625rem;
  object-fit: cover;
  object-position: center;
  border-radius: 0.25rem;
`;

const Title = styled(Typography.Subtitle2)`
  padding: 0 0.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const InfoContainer = styled('div')`
  display: flex;
  padding: 0 0.25rem;
  height: 2.065rem;
`;

const ColumnLeft = styled('div')`
  display: flex;
  gap: 0.19rem 0;
  flex-direction: column;
  max-width: calc(100% - 1.5rem);
  height: 100%;
  flex-grow: 1;
`;

const ColumnRight = styled('div')`
  display: flex;
  flex-direction: column;
  min-width: 1.5rem;
  align-items: end;
`;

const Description = styled(Typography.Body3)(({ theme }) => {
  return css`
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${theme.tokens.color.alias.cm.text['text-subtle'].value};
  `;
});

const Name = styled(Description)`
  text-overflow: ellipsis;
`;

const Language = styled(Typography.Caption)(({ theme }) => {
  return css`
    color: ${theme.tokens.color.alias.cm.text['text-subtle'].value};
    line-height: 0.8rem;

    @media (max-width: ${theme.breakpoints.sm}) {
      line-height: 0.8rem;
    }
  `;
});

const LanguageWrapper = styled('div')`
  gap: 0.25rem;
  display: flex;
  flex-direction: row;
  align-items: self-start;
`;

const Units = styled(Typography.Caption)(({ theme }) => {
  return css`
    text-align: center;
    padding-right: 0;
    color: ${theme.tokens.color.alias.cm.text['text-subtle'].value};
  `;
});

const Counter = styled(Typography.Subtitle2)<{
  variant: ActionType | '';
}>(({ theme, variant }) => {
  const getColor = (): string => {
    const colorContext = theme.tokens.color.alias.cm.icon;

    switch (variant) {
      case ACTION_TYPES.INCREMENT:
        return colorContext['icon-info'].value;
      case ACTION_TYPES.DECREMENT:
        return colorContext['icon-warning'].value;
      default:
        return theme.tokens.color.alias.cm.text.text.value;
    }
  };

  return css`
    text-align: center;
    color: ${getColor()};
    line-height: 1rem;

    @media (max-width: ${theme.breakpoints.sm}) {
      line-height: 1rem;
    }
  `;
});

export {
  CardBody,
  CardContent,
  CardWrapper,
  ColumnLeft,
  ColumnRight,
  Controls,
  Counter,
  Description,
  Image,
  InfoContainer,
  Language,
  LanguageWrapper,
  Name,
  Title,
  Units,
};
