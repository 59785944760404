import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { GridRow, GridContainer } from '@innovamat/glimmer-components';

export const CardContainer = styled.div`
  cursor: pointer;
  width: auto;
  height: 410px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    height: 285px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    height: 100%;
  }
`;
export const PinnedSpacesSection = styled.div`
  min-height: 440px;
  padding-top: 40px;
  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    max-width: 1440px;
    margin: 0 auto;
  }
`;

export const SpacesSection = styled.div`
  padding-top: 24px;
  border-top: 1px solid #e5e5e5;
  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    max-width: 1440px;
    margin: 0 auto;
  }
`;

export const Title = styled.div`
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-weight: ${({ theme }) => theme.typography.weight.bold};
  line-height: ${({ theme }) => theme.typography.lineHeight.medium};
  font-size: ${({ theme }) => theme.sizes.medium};
  color: ${({ theme }) => theme.colors.dark['01']};
  padding: 0 8px;
  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    padding: 0 16px;
  }
`;

export const GridRowWrapper = styled(GridRow)`
  row-gap: 32px;

  ${({ theme }) =>
    css`
      @media (min-width: ${theme.breakpoints.sm}) and (max-width: ${theme
          .breakpoints.lg}) {
        row-gap: 108px;
      }

      @media (min-width: ${theme.breakpoints.xl}) {
        row-gap: 68px;
      }
    `}
`;

export const GridContainerWrapper = styled(GridContainer)`
  margin-top: 24px;
  margin-bottom: 32px;
  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    max-width: 1440px !important;
  }
`;

export const SkeletonWrapper = styled.div`
  margin-bottom: 48px;
  justify-content: space-between;
  gap: 23px;
  display: flex;
  flex-wrap: wrap;
`;
