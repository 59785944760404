
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as MediaIconComponent } from "./media-icon.svg";

export const MediaIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <MediaIconComponent {...props} />;
};
