export const getUnityLanguage = (language: string) => {
  let expectedLanguage;
  switch (language) {
    case 'ca':
      expectedLanguage = 'Catalan';
      break;
    case 'en':
      expectedLanguage = 'English';
      break;
    case 'en_US':
      expectedLanguage = 'EnglishUS';
      break;
    case 'eu':
      expectedLanguage = 'Basque';
      break;
    case 'va':
      expectedLanguage = 'Valencian';
      break;
    case 'es_MX':
      expectedLanguage = 'SpanishMX';
      break;
    case 'it_IT':
      expectedLanguage = 'Italian';
      break;
    case 'pt_BR':
      expectedLanguage = 'Portuguese';
      break;
    default:
      expectedLanguage = 'Spanish';
      break;
  }
  return expectedLanguage;
};
