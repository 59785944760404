export const colors = {
  corp: '#18c6b5',
  black: '#333',
  white: '#fff',
  pink: {
    dark: {
      '01': '#D4118A',
    },
    '01': '#F042AD',
    '02': '#F471C2',
    '03': '#F7A1D6',
    '04': '#FBD0EB',
    '05': '#FDE7F5',
  },

  warning: '#ED6C02',
  info: '#2196F3',

  brand: {
    dark: {
      '01': '#108478',
      '02': '#14A395',
    },
    '01': '#18C6B5',
    '02': '#8BE2DA',
    '03': '#D1F4F0',
    '04': '#E8F9F8',
  },

  yellow: {
    dark: {
      '01': '#CC8D00',
    },
    '01': '#FFC033',
    '02': '#FFD066',
    '03': '#FFE099',
    '04': '#FFEFCC',
    '05': '#FFF8E5',
  },

  dark: {
    '00': '#000000',
    '01': '#333333',
    '02': '#757575',
    '03': '#949494',
    '04': '#ccc',
    '05': '#e5e5e5',
    '06': '#f5f5f5',
    '07': '#fafafa',
  },

  stage: {
    '01': '#f9745f',
    '02': '#6ecbe0',
    '03': '#ffc74a',
  },

  semantic: {
    '01': '#14a395',
    '01b': '#d9f0ee',
    '02': '#f90',
    '02b': '#ffefd6',
    '03': '#e8304f',
    '03b': '#fbdee3',
    '04': '#006aff',
    '04b': '#d6e7ff',
  },

  green: {
    dark: {
      '01': '#51A329',
    },
    '01': '#84D65C',
    '02': '#A3E184',
    '03': '#C2EBAD',
    '04': '#E0F5D6',
    '05': '#F0FAEB',
  },

  comp: {
    '01': '#73affb',
    '02': '#5b81b6',
  },

  navy: {
    dark: {
      '01': '#2365AB',
    },
    '01': '#5797DB',
    '02': '#81B1E4',
    '03': '#ABCBED',
    '04': '#D5E5F6',
    '05': '#EAF1FB',
  },

  purple: {
    dark: {
      '01': '#8B4185',
    },
    '01': '#AD52A6',
    '02': '#CE97CA',
    '03': '#DEBADC',
    '04': '#EFDCED',
    '05': '#F7EEF6',
  },

  orange: {
    dark: {
      '01': '#CC6600',
    },
    '01': '#FF9933',
    '02': '#FFB266',
    '03': '#FFCC99',
    '04': '#FFE5CC',
    '05': '#FFF2E5',
  },

  red: {
    dark: {
      '01': '#CC1F00',
    },
    '01': '#FF5233',
    '02': '#FF7D66',
    '03': '#FFA899',
    '04': '#FFD4CC',
    '05': '#FFE9E5',
  },

  blue: {
    dark: {
      '01': '#2392AB',
    },
    '01': '#57C3DB',
    '02': '#81D2E4',
    '03': '#ABE1ED',
    '04': '#D5F0F6',
    '05': '#EAF7FB',
  },
};
