import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLittleGroupRegister = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      clipPath="url(#little-group-register_svg__a)"
    >
      <path
        strokeWidth={0.8}
        d="M15.865 9.304a1.209 1.209 0 1 0 2.417 0 1.209 1.209 0 0 0-2.418 0M15.059 14a2.015 2.015 0 0 1 4.029 0M11.835 7.209a1.209 1.209 0 1 0 2.418 0 1.209 1.209 0 0 0-2.418 0M11.03 11.905a2.015 2.015 0 0 1 4.029 0M7.806 9.304a1.209 1.209 0 1 0 2.417 0 1.209 1.209 0 0 0-2.417 0M7 14a2.015 2.015 0 0 1 4.03 0"
      />
      <path
        strokeWidth={0.7}
        d="M3.658 5.532A1.29 1.29 0 0 0 2.37 6.838v7.274l1.287 2.203 1.288-2.203.001-7.275a1.29 1.29 0 0 0-1.288-1.305M2.37 7.046h2.576M2.37 8.096h2.576"
      />
      <path
        strokeWidth={0.7}
        d="m2.37 14.112 1.287-.517 1.288.515M3.658 8.096v5.499"
      />
    </g>
    <defs>
      <clipPath id="little-group-register_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgLittleGroupRegister;
