import styled from '@emotion/styled';
import { Typography } from '../Typography';
import { Icon } from '../Icon';
import { IconButton } from '../IconButton';
import { useGlimmerTheme } from '../../theme';

const Container = styled.div<{ isError?: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0 10px;
  width: fit-content;
  min-width: fit-content;
  height: 24px;
  border-radius: 20px;
  outline: ${({ isError, theme }) =>
    isError
      ? `1px solid ${theme.tokens.color.alias.cm.icon['icon-error'].value};`
      : `1px solid ${theme.tokens.color.alias.cm.border['border-subtle'].value};`};
  outline-offset: -1px;
`;

const IconContainer = styled.div`
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.bg['bg-accent'].value};
  position: relative;

  > div {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

const Text = styled(Typography.Body3)`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

export type MailChipProps = {
  text: string;
  showClose?: boolean;
  onClose?: () => void;
  isError?: boolean;
};

export function MailChip({
  text,
  showClose = true,
  onClose,
  isError,
}: MailChipProps): JSX.Element {
  const theme = useGlimmerTheme();

  return (
    <Container data-testid="mail-chip" isError={isError} role="presentation">
      {!isError && (
        <IconContainer>
          <Icon
            icon="UserIcon"
            size="S"
            iconColor={theme.tokens.color.alias.cm.icon['icon-accent'].value}
          />
        </IconContainer>
      )}
      <Text>{text}</Text>
      {showClose && (
        <IconButton
          icon="CloseIcon"
          aria-label="delete email"
          size="XS"
          onClick={onClose}
        />
      )}
    </Container>
  );
}
