import React from 'react';

import Checkbox from '../Checkbox';
import { ChallengeCheckboxProps } from './ChallengeCheckbox.types';
import styles from './ChallengeCheckbox.module.scss';

import { CheckAction } from './ChallengeCheckbox.styles';

function ChallengeCheckbox({
  label,
  checked,
  onChange,
  size,
  ...rest
}: ChallengeCheckboxProps) {
  return (
    <CheckAction
      size={size}
      data-testid="challenge-checkbox"
      {...rest}
      onClick={(e) => e.stopPropagation()}
    >
      <Checkbox
        checkMarkStyle={
          size === 'sm' ? styles.checkMarkStyleSm : styles.checkMarkStyleMd
        }
        label={label}
        miniBorder
        checked={checked}
        onChange={onChange}
      />
    </CheckAction>
  );
}

export default ChallengeCheckbox;
