import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPackage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      clipPath="url(#package_svg__a)"
    >
      <path d="M10 .625.625 4.375 10 8.125l9.375-3.75z" />
      <path d="M.625 4.375v11.25L10 19.375V8.125zM19.375 4.375v11.25L10 19.375V8.125zM15.156 6.063 5.78 2.313M17.188 13.75l-1.563.625" />
    </g>
    <defs>
      <clipPath id="package_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgPackage;
