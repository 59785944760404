
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as SocialAfectiveIconComponent } from "./social-afective-icon.svg";

export const SocialAfectiveIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <SocialAfectiveIconComponent {...props} />;
};
