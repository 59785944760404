import { theme } from '@innovamat/glimmer-components';

const HIGH_PERCENTAGE = 100;
const MEDIUM_PERCENTAGE = 50;

const baseTokenReport = theme.tokens.color.specific.reports['progress-bar'];

export const getProgressColor = (completed: number): string => {
  if (completed >= HIGH_PERCENTAGE) {
    return baseTokenReport.completed.value;
  }
  if (completed >= MEDIUM_PERCENTAGE) {
    return baseTokenReport.completed.value;
  }
  return baseTokenReport.low.value;
};
