
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as LockedIconComponent } from "./locked-icon.svg";

export const LockedIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <LockedIconComponent {...props} />;
};
