import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCheckList = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M1.333.625h17.334c.39 0 .708.317.708.708v17.334a.71.71 0 0 1-.708.708H1.333a.71.71 0 0 1-.708-.708V1.333c0-.39.317-.708.708-.708"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M10 3.333 6 8.667 3.333 6M12 6.667h4M10 11.333l-4 5.334L3.333 14M12 14.667h4"
    />
  </svg>
);
export default SvgCheckList;
