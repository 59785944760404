// List of school Ids that can't acces .cloud domain
export const schoolsWithFirewall = [
  'b522612f-f7f5-415c-8462-b7af30ccb3f6',
  '509b51a3-bc6c-4f2e-9470-aedd827c705c',
  '020c98ab-51bb-44a4-b300-98882255340f',
  'c4173559-0b2a-4282-bc3a-5e1610c8986c',
  'd13c91f8-dff0-45f9-b1a6-bd908d26f689',
  '0bfe7d80-3fe8-4482-9c11-cc0cae4876b7',
  'a1d3b189-988c-445e-8d49-ece6725e9d57',
  'ded05457-d5df-4610-8eeb-694557d808cf',
  'd5b8422f-6450-4f4f-9646-b47e617df6eb',
  '3f4162af-b32a-4f73-a85e-81748c034910',
  '9aa40c49-146a-4c3e-8a39-e4716140518f',
  '2f158f5e-46dd-427a-b7e5-f22bc6747a5e',
  '5e192816-e11e-411b-8d04-7343bb0716ec',
  '63f94af7-ab0d-48dc-b4e7-30005bceec62',
  'f44b4d02-257a-49c7-963f-cf8e2454536b',
  'fd8791ea-836f-4dbc-bc45-b1d5b7a997d6',
  'fb81b2d8-d67d-4781-84db-5601141809e6',
  '74611c23-5f11-4355-bacb-6b3923050afe',
];
