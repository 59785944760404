import styled from '@emotion/styled';
import { StudentSearchInput } from './student-search-input';
import { AddStudentDropdown } from './add-student-dropdown';
import { useSchoolStudents } from '../providers/school-students-provider';

const HeaderWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-self: normal;
  align-items: center;
`;

type Props = {
  isDisabled?: boolean;
};

function SchoolStudentsHeader({ isDisabled }: Props): JSX.Element {
  const { isDeletedStudentsPage, search, handleSearch } = useSchoolStudents();
  return (
    <HeaderWrapper>
      <StudentSearchInput
        handleSearch={handleSearch}
        isDisabled={isDisabled}
        search={search}
      />
      <AddStudentDropdown
        isDeletedStudentsPage={isDeletedStudentsPage}
        isDisabled={isDisabled}
      />
    </HeaderWrapper>
  );
}

export { SchoolStudentsHeader };
