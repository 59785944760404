import * as React from 'react';
import type { SVGProps } from 'react';
const SvgExtension = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={21}
    fill="none"
    {...props}
  >
    <g
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      clipPath="url(#extension_svg__a)"
    >
      <path d="M20.015 10a9 9 0 1 0-18.001.03 9 9 0 0 0 18-.03" />
      <path d="m11.476 5.2 3.558 4.433a.6.6 0 0 1-.467.976l-1.752.003.007 4.2a.6.6 0 0 1-.599.6l-2.4.004a.6.6 0 0 1-.6-.599l-.008-4.2-1.752.003a.6.6 0 0 1-.47-.974L10.539 5.2a.602.602 0 0 1 .938-.002" />
    </g>
    <defs>
      <clipPath id="extension_svg__a">
        <path fill="#fff" d="m21 20-20 .032-.032-20 20-.032z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgExtension;
