import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPin = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M4.63 8.332h-.002a.642.642 0 0 1 .128-1.008 5.73 5.73 0 0 1 4.216-.616c.26.063.53-.047.674-.272L13.154.922a.642.642 0 0 1 .995-.109h0l4.934 4.934a.64.64 0 0 1-.11.995h0L13.46 10.25a.625.625 0 0 0-.272.673 5.73 5.73 0 0 1-.616 4.217.64.64 0 0 1-1.007.127h0zM7.653 12.242.895 19"
    />
  </svg>
);
export default SvgPin;
