import styled from '@emotion/styled';
import {
  Button,
  ClassCardHeader,
  Drawer,
  IconButton,
  Input,
  NavItem,
  Typography,
  snack,
} from '@innovamat/glimmer-components';
import { Student, useClassroomQuery } from '@innovamat/glow-api-client';
import { useTranslation } from 'react-i18next';

import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { getAvatarImage } from '../../../../utils';
import { useUser } from '../../../user-management';
import { useClassrooms } from '../../hooks/use-classrooms';
import { useMoveStudent } from '../../hooks/use-move-student';
import { SchoolStudent } from '../../views/school-students/types/student';
import { SearchOrganizationInput } from '../search-organization-input';

const Wrapper = styled.div`
  height: 100%;
  padding: 24px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const DrawerHeader = styled.div`
  margin-bottom: 24px;
`;

const Subtitle = styled(Typography.Body2)`
  margin-top: 16px;
  margin-bottom: 8px;
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  gap: 24px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  > div {
    display: flex;
    flex-direction: column;
  }
  border-bottom: 1px solid
    ${({ theme }) => theme.tokens.color.alias.cm.border['border-subtle'].value};
`;

const ClassroomsContent = styled.div`
  margin-top: 8px;
  overflow-y: auto;
  flex: 1;
  gap: 8px;
  display: flex;
  flex-direction: column;
`;

type Props = {
  showDrawer: boolean;
  student: SchoolStudent | undefined;
  onClose: () => void;
  onSuccess?: (student: Student, oldStudent: Student) => void;
};

function MoveStudentToClassroomDrawer({
  showDrawer,
  student,
  onClose,
  onSuccess,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { user, isRole } = useUser();

  const [currentOrganizationId, setCurrentOrganizationId] = useState<string>(
    user?.organizationId!
  );

  const queryClient = useQueryClient();

  const { classroomsByStage } = useClassrooms({
    mine: false,
    organizationId: currentOrganizationId,
  });

  const [classroomId, setClassroomId] = useState<string | undefined>(undefined);
  const [search, setSearch] = useState<string>('');

  const { onMoveStudent, isLoading } = useMoveStudent({
    currentStudent: student,
    onError: (errors) => {
      errors.response.errors.forEach((error) => {
        if (error.extensions?.response?.['status'] === 409) {
          if (
            JSON.parse(error.extensions?.response?.body).type ===
            'student.cannot_change_organization_to_another_data_center'
          ) {
            snack.error(t('errors.change-student-classroom.data-center'));
          } else {
            snack.error(t('students.alert.maxStudentsLength'));
          }
        } else {
          snack.error(t('errors.generic.title'));
        }
      });
    },
    onSuccess: (newStudent, oldStudent) => {
      //move this to the parent component
      queryClient.refetchQueries({ queryKey: ['school-students'] });

      onClose();
      onSuccess?.(newStudent, oldStudent);
    },
  });

  useEffect(() => {
    setClassroomId(undefined);
    setSearch('');
  }, [showDrawer]);

  const { data } = useClassroomQuery(
    {
      id: student?.classroomId!,
      orgId: user?.organizationId!,
    },
    {
      enabled: !!student?.classroomId && !!user?.organizationId,
    }
  );

  const filterGoogleClassrooms = true;

  const clasroooms = classroomsByStage(
    student?.courseOrder!,
    filterGoogleClassrooms
  );

  const filteredClassrooms = useMemo(() => {
    return clasroooms
      .filter((classroom) =>
        classroom.name?.toLowerCase().includes(search.toLowerCase())
      )
      .filter((classroom) => classroom.id !== student?.classroomId);
  }, [clasroooms, search, student?.classroomId]);

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const classroom = data?.classroom;

  const handleMove = () => {
    const classroom = filteredClassrooms.find((c) => c.id === classroomId);
    if (!classroom) return;
    onMoveStudent(
      classroom,
      currentOrganizationId !== user?.organizationId
        ? currentOrganizationId
        : undefined
    );
  };

  if (!classroom) return <></>;

  return (
    <Drawer open={showDrawer} customStyles={{ width: '470px' }} roundedBorder>
      <Wrapper>
        <DrawerHeader>
          <div>
            <IconButton icon="CloseIcon" onClick={onClose} />
          </div>
          <Header>
            <div>
              <Typography.H4>
                {student?.firstName} {student?.lastName}
              </Typography.H4>
              <Subtitle>{t('students.drawer.current-classroom')}</Subtitle>
              <ClassCardHeader
                name={classroom.name ?? ''}
                avatar={getAvatarImage({
                  avatar: classroom.avatar,
                  icon: classroom.icon,
                  courseOrder: classroom.courseOrder!,
                })}
                course={classroom.courseName ?? ''}
              />
            </div>
          </Header>
        </DrawerHeader>

        {isRole.Advisor && (
          <>
            <SearchOrganizationInput
              defaultValue={user?.organizationName}
              onSelect={(organization) => {
                setCurrentOrganizationId(organization.id!);
              }}
            />
            <br />
          </>
        )}

        <Input
          labelText={t('common.move-to')}
          value={search}
          onChange={handleChangeSearch}
          leftIcon="SearchIcon"
        />

        <ClassroomsContent>
          {filteredClassrooms.map((classroom) => (
            <NavItem
              name={classroom.name ?? ''}
              avatar={getAvatarImage({
                avatar: classroom.avatar,
                icon: classroom.icon,
                courseOrder: classroom.courseOrder!,
              })}
              selected={classroomId === classroom.id}
              description={classroom.courseName ?? ''}
              onClick={() => setClassroomId(classroom.id)}
            />
          ))}
        </ClassroomsContent>

        <Footer>
          <Button
            fill
            variant="tertiary"
            onClick={() => setClassroomId(undefined)}
            disabled={!classroomId}
          >
            {t('common.cancel')}
          </Button>
          <Button
            fill
            disabled={!classroomId}
            onClick={handleMove}
            loading={isLoading}
          >
            {t('common.move')}
          </Button>
        </Footer>
      </Wrapper>
    </Drawer>
  );
}

export { MoveStudentToClassroomDrawer };
