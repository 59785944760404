import { SVGProps } from 'react';

export const PorFlag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19 12"
    fill="none"
    {...props}
  >
    <rect
      x="0.997803"
      y="0.375021"
      width="18.0021"
      height="11.25"
      rx="1.12499"
      fill="#51A329"
    />
    <path
      d="M8.50195 0.375021H17.8727C18.494 0.375021 18.9977 0.878698 18.9977 1.50002V10.5C18.9977 11.1213 18.494 11.625 17.8727 11.625H8.50195V0.375021Z"
      fill="#CC1F00"
    />
    <path
      d="M8.50194 8.14286C9.68537 8.14286 10.6448 7.18345 10.6448 6C10.6448 4.81653 9.68537 3.85712 8.50194 3.85712C7.31846 3.85712 6.35907 4.81653 6.35907 6C6.35907 7.18345 7.31846 8.14286 8.50194 8.14286Z"
      stroke="#FFD066"
      stroke-width="0.857142"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.64227 4.92834C7.64227 4.81 7.73821 4.71405 7.85656 4.71405H9.14224C9.26059 4.71405 9.35657 4.81 9.35657 4.92834V6.42838C9.35657 6.90172 8.97279 7.2855 8.49939 7.2855C8.02602 7.2855 7.64227 6.90172 7.64227 6.42838V4.92834Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.50153 6.42827C8.73823 6.42827 8.93005 5.80779 8.93005 5.5711C8.93005 5.3344 8.73823 5.14252 8.50153 5.14252C8.26483 5.14252 8.07294 5.3344 8.07294 5.5711C8.07294 5.80779 8.26483 6.42827 8.50153 6.42827Z"
      fill="#2365AB"
    />
  </svg>
);
