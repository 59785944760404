import { jwtDecode } from 'jwt-decode';
import Cookies from 'universal-cookie/es6';
import { decryptWithAES, encryptWithAES } from './crypto';
import { getRootDomain } from './environment';
import { Classroom } from './types/classroom';
import { Course } from './types/course';
import { TokenData } from './types/user';

export const StorageKeys = {
  Region: 'region',
  Language: 'language',
  CurrentClassroom: 'currentClassroom',
  CurrentCourse: 'currentCourse',
  OrganizationName: 'organizationName',
  Version: 'version',
  Device: 'device',
  AccessToken: '__inv_at',
  RefreshToken: '__inv_rt',
  IdToken: '__inv_it',
  ExpiresIn: '__inv_ei',
  TokenType: '__inv_tt',
};

const YEAR = 365 * 24 * 60 * 60;

export const getCookie = (key: string): string | null => {
  const cookies = new Cookies();
  const value = cookies.get(key);
  return value ? decryptWithAES(value) : null;
};

export const setCookie = (key: string, value: string): void => {
  const cookies = new Cookies();

  cookies.set(key, encryptWithAES(value), {
    path: '/',
    domain: getRootDomain(),
    maxAge: YEAR,
  });
};

export const removeCookie = (key: string, domain = getRootDomain()): void => {
  const cookies = new Cookies();
  cookies.remove(key, {
    path: '/',
    domain,
    maxAge: YEAR,
  });
};

const setInLocalStorage = (key: string, value: string): void => {
  localStorage.setItem(key, value);
};

const getFromLocalStorage = (key: string): string | null => {
  return localStorage.getItem(key);
};

const removeFromLocalStorage = (key: string): void => {
  localStorage.removeItem(key);
};

export const region = {
  get: (): string | null => getFromLocalStorage(StorageKeys.Region),
  set: (region: string): void => setInLocalStorage(StorageKeys.Region, region),
  remove: (): void => removeFromLocalStorage(StorageKeys.Region),
};

export const language = {
  get: () => getFromLocalStorage(StorageKeys.Language),
  set: (language: string) => setInLocalStorage(StorageKeys.Language, language),
  remove: () => removeFromLocalStorage(StorageKeys.Language),
};

export const classroom = {
  get: (): Classroom | null => {
    const classroom = getFromLocalStorage(StorageKeys.CurrentClassroom);
    if (!classroom) return null;
    return JSON.parse(classroom);
  },
  set: (classroom: Classroom): void =>
    setInLocalStorage(StorageKeys.CurrentClassroom, JSON.stringify(classroom)),
  remove: (): void => removeFromLocalStorage(StorageKeys.CurrentClassroom),
};

export const course = {
  get: (): Course | null => {
    const course = getFromLocalStorage(StorageKeys.CurrentCourse);
    if (!course) return null;
    return JSON.parse(course);
  },
  set: (course: Course): void =>
    setInLocalStorage(StorageKeys.CurrentCourse, JSON.stringify(course)),
  remove: (): void => removeFromLocalStorage(StorageKeys.CurrentCourse),
};

export const organizationName = {
  get: (): string | null => getFromLocalStorage(StorageKeys.OrganizationName),
  set: (organizationName: string): void =>
    setInLocalStorage(StorageKeys.OrganizationName, organizationName),
  remove: (): void => removeFromLocalStorage(StorageKeys.OrganizationName),
};

export const tokenInfo = {
  get: (): TokenData | undefined => {
    const accessToken = getCookie(StorageKeys.AccessToken);
    const refreshToken = getCookie(StorageKeys.RefreshToken);
    const idToken = getCookie(StorageKeys.IdToken);
    const tokenType = getCookie(StorageKeys.TokenType);
    const expiresIn = getCookie(StorageKeys.ExpiresIn);

    if (accessToken && refreshToken && idToken && tokenType && expiresIn) {
      const tokenData = {
        access_token: accessToken,
        refresh_token: refreshToken,
        id_token: idToken,
        token_type: tokenType,
        expires_in: +expiresIn,
      };

      return tokenData;
    }

    return undefined;
  },
  set: (auth: TokenData): void => {
    setCookie(StorageKeys.AccessToken, auth.access_token);
    setCookie(StorageKeys.RefreshToken, auth.refresh_token);
    setCookie(StorageKeys.IdToken, auth.id_token);
    setCookie(StorageKeys.TokenType, auth.token_type);
    setCookie(StorageKeys.ExpiresIn, `${auth.expires_in}`);
  },
  remove: (): void => {
    const domain = getRootDomain();

    removeCookie(StorageKeys.AccessToken);
    removeCookie(StorageKeys.RefreshToken);
    removeCookie(StorageKeys.IdToken);
    removeCookie(StorageKeys.TokenType);
    removeCookie(StorageKeys.ExpiresIn);

    removeCookie(StorageKeys.AccessToken, domain);
    removeCookie(StorageKeys.RefreshToken, domain);
    removeCookie(StorageKeys.IdToken, domain);
    removeCookie(StorageKeys.TokenType, domain);
    removeCookie(StorageKeys.ExpiresIn, domain);
  },
  hasTokenAndIsInvalid: (): boolean => {
    const token = getCookie(StorageKeys.AccessToken);

    if (!token) {
      return false;
    }

    const jwtRegex = /^[A-Za-z0-9-_]+\.[A-Za-z0-9-_]+\.[A-Za-z0-9-_]+$/;

    if (!jwtRegex.test(token)) {
      console.error('Token exists but has invalid format');
      return true;
    }

    const cookies = new Cookies();
    const allCookies = cookies.getAll();
    const seenCookies = new Set<string>();

    const hasDuplicates = Object.keys(allCookies).some((name) => {
      if (seenCookies.has(name)) {
        return true;
      }
      seenCookies.add(name);
      return false;
    });

    if (hasDuplicates) {
      console.error('Duplicate cookies found');
      return true;
    }

    try {
      jwtDecode(token);

      return false;
    } catch (error) {
      console.error('Token exists but has invalid structure', error);
      return true;
    }
  },
};

export const cookieVersion = {
  currentVersion: '3', // UPDATE THIS VALUE WHENEVER YOU CHANGE THE COOKIE STRUCTURE
  get: (): string | null => getFromLocalStorage(StorageKeys.Version),
  set: (version: string): void =>
    setInLocalStorage(StorageKeys.Version, version),
  remove: (): void => removeFromLocalStorage(StorageKeys.Version),
  check: (): boolean => {
    const isValid = cookieVersion.get() === cookieVersion.currentVersion;
    return isValid;
  },
  hasDuplicates(): boolean {
    const cookieString = document.cookie;
    const cookies = cookieString.split(';');
    const invKeys: any = {};

    cookies.forEach((cookie) => {
      const [key] = cookie.trim().split('=');
      if (key === StorageKeys.AccessToken) {
        invKeys[key] = (invKeys[key] || 0) + 1;
      }
    });
    const hasDuplicates = Object.values(invKeys).some(
      (count: any) => count > 1
    );
    return hasDuplicates;
  },
};

export const initRedirectIfTokenIsInvalid = ({
  redirectUrl,
}: {
  redirectUrl: string;
}): void => {
  if (cookieVersion.hasDuplicates() || !cookieVersion.check()) {
    clear();
    cookieVersion.set(cookieVersion.currentVersion);
    window.location.href = redirectUrl;
    return;
  }

  if (tokenInfo.hasTokenAndIsInvalid()) {
    tokenInfo.remove();
    window.location.href = redirectUrl;
  }
};

export const device = {
  get: (): string | null => getCookie(StorageKeys.Device),
  set: (device: string): void => setCookie(StorageKeys.Device, device),
  remove: (): void => removeCookie(StorageKeys.Device),
};

export const clear = (): void => {
  region.remove();
  language.remove();
  classroom.remove();
  course.remove();
  organizationName.remove();
  device.remove();
  tokenInfo.remove();
};
