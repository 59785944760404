import { useState } from 'react';
import styled from '@emotion/styled';
import { FullScreenHandle } from 'react-full-screen';
import LoaderContainer from '../../components/LoaderContainer';
import PlayIcon from '../AppletResource/PlayIcon';
import { AxiosInstance } from 'axios';
import { AppletResource } from '../AppletResource';
import { AppletsInfo } from '../../types/resourceViewer';

const ManipulativeWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
`;

type Props = {
  language: string;
  fullscreen?: FullScreenHandle;
  thumbnail?: string;
  title?: string;
  setEventData: (
    eventType: string,
    eventProperties?: any,
    addUserData?: boolean
  ) => void;
  axiosInstance: AxiosInstance;
  apolloServer: string;
  appletInfo: AppletsInfo;
  school: string;
  uid: string;
};

export function ManipulativeZone({
  language,
  fullscreen,
  thumbnail,
  title,
  setEventData,
  axiosInstance,
  apolloServer,
  appletInfo,
  school,
  uid,
}: Props) {
  const { handlePlayButton, onlyShowPlayButton } = appletInfo || {};

  const [isLoading, setIsLoading] = useState(true);

  const packOrVariation = 0;
  const sceneName = 'ManipulativeZone';

  return (
    <ManipulativeWrapper>
      {onlyShowPlayButton && handlePlayButton ? (
        <div>
          <PlayIcon
            thumbnail={thumbnail || ''}
            title={title || ''}
            handleClick={() => {
              handlePlayButton(packOrVariation, sceneName);
            }}
          />
        </div>
      ) : (
        <>
          {isLoading && <LoaderContainer />}
          <AppletResource
            apolloServer={apolloServer}
            axiosInstance={axiosInstance}
            sceneName={sceneName}
            packOrVariation="0"
            onLoad={() => setIsLoading(false)}
            fullscreen={fullscreen}
            language={language}
            setEventData={setEventData}
            appletInfo={appletInfo}
            school={school}
            uid={uid}
          />
        </>
      )}
    </ManipulativeWrapper>
  );
}
