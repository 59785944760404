import styled from '@emotion/styled';
import { css } from '@emotion/react';
import {
  InputHTMLAttributes,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Checkbox } from '../Checkbox';
import { Typography } from '../Typography/Typography';
import { ToggleButton } from '../ToggleButton';

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SessionDoneContainer = styled.div<{
  checked?: boolean;
  width: number | null;
  customStyles?: string;
  isInThumbnail?: boolean;
  hasSolutions?: boolean;
  solutionsEnabled?: boolean;
  hoverOnThumbnail?: boolean;
}>`
  display: flex;
  padding: 0px 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  height: 40px;
  position: relative;

  ${({ theme, width }) =>
    css`
      background-color: ${theme.tokens.color.alias.cm.surface[
        'surface-secondary'
      ].value};
      color: ${theme.tokens.color.alias.cm.text['text-subtle'].value};

      max-width: ${width ? `${width}px` : 'fit-content'};
      min-width: ${width ? `${width}px` : 'fit-content'};
      transition: max-width 0.2s ease-out, min-width 0.2s ease-out;
    `}

  ${({ isInThumbnail, checked, theme, hasSolutions, width }) =>
    isInThumbnail &&
    css`
      padding: ${isInThumbnail ? '0px 11px' : '0px 8px'};
      color: ${theme.tokens.color.alias.cm.text['text-inverted'].value};
      border-radius: 0 4px 0 4px;
      background-color: transparent;
      max-width: 34px;
      min-width: 34px;
      overflow: ${checked ? 'visible' : 'hidden'};

      .session-complete-text {
        opacity: 0;
        pointer-events: none;
      }

      :hover {
        background-color: ${theme.tokens.color.specific.element['thumbnail-tag']
          .value};
        .session-complete-text {
          opacity: ${!checked || (checked && !hasSolutions) ? 1 : 0};
        }
        max-width: ${width ? `${width}px` : 'fit-content'};
        min-width: ${width ? `${width}px` : 'fit-content'};
      }

      @media screen and (max-width: ${theme.breakpoints.md}) {
        :hover {
          background-color: transparent;
          .session-complete-text {
            opacity: 0;
          }
        }
      }
    `}

      ${({ hoverOnThumbnail, width, theme, checked, hasSolutions }) =>
    hoverOnThumbnail &&
    hasSolutions &&
    checked &&
    css`
      background-color: ${theme.tokens.color.specific.element['thumbnail-tag']
        .value};
      max-width: ${`${width}px`};
      min-width: ${`${width}px`};

      .show-solutions {
        opacity: 1 !important;
      }
    `}

    ${({ isInThumbnail, checked, theme, hasSolutions }) =>
    isInThumbnail &&
    hasSolutions &&
    checked &&
    css`
      .show-solutions {
        opacity: 0;
        pointer-events: none;
      }

      :hover {
        .show-solutions {
          opacity: 1;
          pointer-events: all;
        }
      }

      @media screen and (max-width: ${theme.breakpoints.md}) {
        :hover {
          background-color: ${theme.tokens.color.specific.element[
            'thumbnail-tag'
          ].value};
        }
      }
    `}

    ${({
    isInThumbnail,
    checked,
    hasSolutions,
    solutionsEnabled,
    theme,
    width,
  }) =>
    isInThumbnail &&
    checked &&
    hasSolutions &&
    !solutionsEnabled &&
    css`
      background-color: ${theme.tokens.color.specific.element['thumbnail-tag']
        .value};
      max-width: ${`${width}px`};
      min-width: ${`${width}px`};

      .show-solutions {
        opacity: 1 !important;
        pointer-events: all !important;
      }
    `}

    transition: all 0.2s ease-in-out;

  ${({ customStyles }) =>
    customStyles &&
    css`
      ${customStyles}
    `};
`;

const SessionComplete = styled.div<{
  checked?: boolean;
  disabled?: boolean;
  showSolutions?: boolean;
}>`
  ${({ theme, checked, disabled, showSolutions }) => css`
    display: flex;
    opacity: ${checked && showSolutions ? '0' : '1'};
    transition: opacity ${checked ? '' : '0.2s ease-in'};
    position: absolute;
    left: 38px;
    width: max-content;

    color: ${disabled
      ? theme.tokens.color.alias.cm.text['text-disabled'].value
      : ''};
  `}
`;

const ShowSolutions = styled.div<{ checked?: boolean }>`
  align-items: center;
  gap: 8px;

  ${({ checked }) => css`
    display: flex;
    position: absolute;
    left: 34px;
    opacity: ${checked ? '1' : '0'};
    transition: opacity ${checked ? '0.2s ease-in' : ''};
    width: max-content;
  `}
`;

const ShowSolutionsText = styled.div<{ checked?: boolean; disabled?: boolean }>`
  ${({ theme, checked, disabled }) => css`
    opacity: ${checked ? '1' : '0'};
    transition: opacity 0.2s ${checked ? '0.2s' : ''};
    width: max-content;

    color: ${disabled
      ? theme.tokens.color.alias.cm.text['text-disabled'].value
      : ''};
  `}
`;

const Divider = styled.div`
  width: 1px;
  height: 20px;
  border-radius: 100px;

  ${({ theme }) =>
    css`
      background-color: ${theme.tokens.color.alias.cm.border['border-subtle']
        .value};
    `}
`;

interface SessionDoneProps extends InputHTMLAttributes<HTMLInputElement> {
  checked?: boolean;
  isActive?: boolean;
  disabled?: boolean;
  showSolutions?: boolean;
  onCheck?: (value: boolean) => void;
  onActive?: (value: boolean) => void;
  textSessionComplete: string;
  textActivateSolution: string;
  customStyles?: string;
  dataTestIdButton?: string;
  isInThumbnail?: boolean;
  hoverOnThumbnail?: boolean;
}

export function SessionDone({
  checked,
  isActive,
  disabled = false,
  showSolutions = true,
  onCheck,
  onActive,
  customStyles,
  textSessionComplete,
  textActivateSolution,
  dataTestIdButton,
  isInThumbnail,
  hoverOnThumbnail,
}: SessionDoneProps) {
  const refSessionComplete = useRef<HTMLDivElement>(null);
  const refShowSolutions = useRef<HTMLDivElement>(null);

  const extraWidthSessionComplete = 50;
  const extraWidthShowSolutions = 55;

  const getWidth = useCallback(() => {
    return checked && showSolutions
      ? refShowSolutions.current
        ? refShowSolutions.current?.offsetWidth + extraWidthShowSolutions
        : 0
      : refSessionComplete.current
      ? refSessionComplete.current?.offsetWidth + extraWidthSessionComplete
      : 0;
  }, [checked, showSolutions, refSessionComplete, refShowSolutions]);

  const [width, setWidth] = useState<number | null>(getWidth());

  useLayoutEffect(() => {
    setWidth(getWidth());
  }, [checked, refShowSolutions.current, refSessionComplete.current, getWidth]);

  return (
    <Wrapper>
      <SessionDoneContainer
        hoverOnThumbnail={hoverOnThumbnail}
        checked={checked}
        customStyles={customStyles}
        width={width}
        isInThumbnail={isInThumbnail}
        hasSolutions={showSolutions}
        solutionsEnabled={isActive}
      >
        <Checkbox
          checked={checked}
          onCheck={onCheck}
          solutions={isActive}
          disabled={disabled}
          isConfettiAnimationEnabled
          data-testid={dataTestIdButton}
          aria-label="Session done checkbox"
        />
        <SessionComplete
          className="session-complete-text"
          checked={checked}
          disabled={disabled}
          showSolutions={showSolutions}
          ref={refSessionComplete}
        >
          <Typography.Subtitle2>{textSessionComplete}</Typography.Subtitle2>
        </SessionComplete>
        {showSolutions && (
          <ShowSolutions
            className="show-solutions"
            checked={checked}
            ref={refShowSolutions}
          >
            <Divider className="solutions-divider" />
            <ToggleButton
              className="toggle-solutions"
              isActive={isActive}
              onToggle={onActive}
              disabled={disabled || !checked}
              aria-label="Toggle solutions button"
            />
            <ShowSolutionsText
              className="show-solutions-text"
              checked={checked}
              disabled={disabled}
            >
              <Typography.Subtitle2>
                {textActivateSolution}
              </Typography.Subtitle2>
            </ShowSolutionsText>
          </ShowSolutions>
        )}
      </SessionDoneContainer>
    </Wrapper>
  );
}
