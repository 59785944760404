import styled from '@emotion/styled';
import { Challenges } from '../../templates';
import { IconRecord } from '../../types';
import { BlockImage } from '../ImagesModal/ImagesModal.styles';
import ChallengesBlockTitle from './ChallengesBlock.Title';
import SimpleBlock from '../SimpleBlock';
import ChallengesBlockTips from './ChallengesBlock.Tips';
import useEarlyYearGuide from '../../templates/EarlyYear/context/useEarlyYearGuide';
import { getMaterialsByStage } from '../../../utils/materialsList';
import { Stage } from '../../../types/Stage';
import MaterialsBlock from '../MaterialsBlock';
import ChallengesBlockVocabulary from './ChallengesBlock.Vocabulary';
import ChallengesBlockMotto from './ChallengesBlock.Motto';
import ChallengesBlockAskAndObserve from './ChallengesBlock.AskAndObserve';

type Props = {
  challenges: Challenges;
  contentIcons: IconRecord;
};

const ChallengeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media print {
    break-inside: avoid;
  }
`;

export function PrintableChallengesBlock({ challenges, contentIcons }: Props) {
  const { t } = useEarlyYearGuide();

  return (
    <Wrapper>
      {challenges.items.map((challenge, index) => {
        const image = challenge.image.items[0].cdn_files.items[0].url;

        return (
          <ChallengeWrapper>
            <TitleWrapper>
              <ChallengesBlockTitle
                id={challenge.id}
                title={challenge.title.body}
                challengeNumber={index}
              />
              <BlockImage src={image} alt="" />
              <SimpleBlock
                title={t('digitalguides.ey.challenge.summary')}
                text={challenge.summary}
              />
            </TitleWrapper>
            <ChallengesBlockTips tips={challenge.tips} />
            <MaterialsBlock
              material={challenge.material}
              items={getMaterialsByStage(Stage.EarlyYear)}
            />
            <ChallengesBlockVocabulary
              keyVocabulary={challenge.key_vocabulary}
            />
            <ChallengesBlockMotto motto={challenge.motto} />
            <ChallengesBlockAskAndObserve
              contentIcons={contentIcons}
              data={challenge.askandobserve}
              isPrintable={true}
            />
          </ChallengeWrapper>
        );
      })}
    </Wrapper>
  );
}
