import { RegionParameters } from '@innovamat/glow-api-client';
import { TFunction } from 'i18next';

export const CURRENCY = {
  EUR: 'EUR',
  USD: 'USD',
  BRL: 'BRL',
  CLP: 'CLP',
  GBP: 'GBP',
} as const;

type Currency = (typeof CURRENCY)[keyof typeof CURRENCY];

const CURRENCY_SYMBOL: Partial<Record<Currency, string>> = {
  EUR: '€',
  USD: '$',
  BRL: 'R$',
};

const CENT_SYMBOL = '¢';

function parseCurrency(
  value: string,
  regionParams: RegionParameters | undefined
): string {
  switch (regionParams?.currency) {
    case CURRENCY.CLP:
    case CURRENCY.USD:
      return `${CURRENCY_SYMBOL.USD}${value}`;

    case CURRENCY.BRL:
      return `${CURRENCY_SYMBOL.BRL}${value}`;

    default:
      return `${value}${CURRENCY_SYMBOL.EUR}`;
  }
}

function parseCurrencyCents(
  numValue: number,
  regionParams: RegionParameters | undefined,
  t: TFunction<'translation', undefined, 'translation'>
): string {
  const centsValue = (numValue * 100).toFixed(0);

  switch (regionParams?.currency) {
    case CURRENCY.CLP:
    case CURRENCY.USD:
      return `${CENT_SYMBOL}${centsValue}`;

    case CURRENCY.BRL:
      return `${centsValue} ${t('currency.cents_of')} ${CURRENCY_SYMBOL.BRL}`;

    default:
      return `${centsValue} ${t('currency.cents_of')} ${CURRENCY_SYMBOL.EUR}`;
  }
}

export function replaceLocalization(
  text: string | undefined,
  t: TFunction<'translation', undefined, 'translation'>,
  regionParams?: RegionParameters
): string {
  if (!text) return '';

  const thSeparator = regionParams?.thousandSeparator || ' ';
  const dcSeparator = regionParams?.decimalSeparator || ',';
  const divSeparator = regionParams?.shortDivisionSign || '/';

  let replacedText = text
    .replace(/{{th}}/g, thSeparator)
    .replace(/{{dc}}/g, dcSeparator)
    .replace(/{{div}}/g, divSeparator);

  const coinRegex = /\[\[Coin=(.*?)\]\]/g;

  replacedText = replacedText.replace(coinRegex, (match, value) => {
    const CENT_REGEX = '|cents';
    const isCent = value.includes(CENT_REGEX);

    const cleanValue = value.replace(CENT_REGEX, '');

    const cleanNumValue = cleanValue
      .replace(new RegExp(`\\${thSeparator}`, 'g'), '')
      .replace(new RegExp(`\\${dcSeparator}`, 'g'), '.');
    const numValue = parseFloat(cleanNumValue);

    if (isCent) {
      return parseCurrencyCents(numValue, regionParams, t);
    } else {
      return parseCurrency(cleanValue, regionParams);
    }
  });

  return replacedText;
}
