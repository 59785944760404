import { useTranslation } from 'react-i18next';
import {
  Button,
  Dropdown,
  InnerHtml,
  State,
  DatePickerInput,
  Typography,
  Select,
} from '@innovamat/glimmer-components';
import { IconType } from '@innovamat/glimmer-icons';
import { DownloadReportType } from '../../hooks/use-download-reports';
import styled from '@emotion/styled';
import { ReportDate } from './general-progress';
import { standards } from '../../utils/standards';
import { useUser } from '../../../user-management';
import BannerLastYear from '../../components/banner-last-year/banner-last-year';

type Props = {
  loadingTable: boolean;
  reportAvailable: boolean;
  reportsStartDate: Date | null;
  reportsEndDate: Date | null;
  handleDateChange: (date: Date, type: 'from' | 'to') => void;
  handleDownload: (type: DownloadReportType) => void;
  googleDriveLoading: boolean;
  reportsDate: ReportDate;
  isLastYearReport?: boolean;
  handleChangeStandard: (standard: string) => void;
  standardStructure: string;
  isPrimary: boolean;
  hasLastYearReport?: boolean | string;
};

function GeneralProgressHeader({
  loadingTable,
  reportAvailable,
  reportsStartDate,
  reportsEndDate,
  handleDateChange,
  handleDownload,
  googleDriveLoading,
  reportsDate,
  isLastYearReport,
  handleChangeStandard,
  standardStructure,
  isPrimary,
  hasLastYearReport,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { isRole, user } = useUser();

  const mapDownloadOptions = [
    {
      children: <InnerHtml text={t('reports.button.downloadReportExcel')} />,
      onSelectItem: () => handleDownload('excel'),
      icon: 'FileXlsIcon' as IconType,
      state: 'active' as State,
    },
    {
      children: <InnerHtml text={t('reports.button.downloadReportPdf')} />,
      onSelectItem: () => handleDownload('pdf'),
      icon: 'FilePdfIcon' as IconType,
      state: 'active' as State,
    },
    {
      children: t('reports.button.downloadReportDrive'),
      onSelectItem: () => handleDownload('drive'),
      icon: 'GoogleDriveIcon' as IconType,
      state: googleDriveLoading ? 'disabled' : ('active' as State),
    },
  ];

  const isDisabled = loadingTable || !reportAvailable;
  const openDropdownOnHover = !loadingTable && reportAvailable;
  const isAdvisor = isRole.Advisor;
  const isInnovamat = user?.email.includes('@innovamat.com');

  return (
    <>
      <ReportsHeader>
        {hasLastYearReport && !isLastYearReport && <BannerLastYear />}
        <ReportsActions>
          <LeftSideContainer>
            <DateContainer>
              {reportsStartDate && (
                <TitleDate>{t('reports.date.filter.initialDate')}</TitleDate>
              )}
              {reportsStartDate && (
                <DatePickerInput
                  defaultMonth={reportsStartDate}
                  handleDateChange={(date) => handleDateChange(date, 'from')}
                  initialDate={reportsStartDate}
                  fromDate={reportsStartDate}
                  toDate={new Date(reportsDate.to)}
                  readOnly={isLastYearReport}
                  modifiers={{
                    disabled: [
                      { before: reportsStartDate },
                      { after: new Date(reportsDate.to) },
                      {
                        dayOfWeek: [0, 2, 3, 4, 5, 6],
                      },
                    ],
                  }}
                  dataTestId={'GERE_INITIALDATEBUTTON'}
                  dataTestIdCalendar={'GERE_INITIALCALENDARDIV'}
                />
              )}
            </DateContainer>
            <DateContainer>
              {reportsEndDate && (
                <TitleDate>{t('reports.date.filter.finalDate')}</TitleDate>
              )}
              {reportsEndDate && (
                <DatePickerInput
                  handleDateChange={(date) => handleDateChange(date, 'to')}
                  initialDate={reportsEndDate}
                  fromDate={new Date(reportsDate.from)}
                  toDate={reportsEndDate}
                  readOnly={isLastYearReport}
                  modifiers={{
                    disabled: [
                      { before: new Date(reportsDate.from) },
                      { after: reportsEndDate },
                      { dayOfWeek: [1, 2, 3, 4, 5, 6] },
                    ],
                  }}
                  dataTestId={'GERE_FINALDATEBUTTON'}
                  dataTestIdCalendar={'GERE_FINALCALENDARDIV'}
                />
              )}
            </DateContainer>
          </LeftSideContainer>
          <RightSideContainer>
            {isAdvisor && isInnovamat && isPrimary && (
              <StyledSelect
                onChange={(option) => handleChangeStandard(option!.value)}
                options={standards}
                value={standardStructure}
                labelText="Standard Structure"
                placeholder={standardStructure}
              />
            )}
            <Dropdown openOnHover={openDropdownOnHover}>
              <Dropdown.Toggle>
                <Button
                  variant="secondary"
                  loading={false}
                  onClick={() => {}}
                  leftIcon="DownloadIcon"
                  rightIcon="ExpandMoreIcon"
                  disabled={isDisabled}
                  dataTestId="GERE_DOWNLOADBUTTON"
                />
              </Dropdown.Toggle>
              <DropdownContent position={'bottomRight'}>
                {mapDownloadOptions.map((option, index) => (
                  <Dropdown.Item
                    key={index}
                    {...option}
                    dataTestId={`GERE_DOWNLOADITEM_${index + 1}`}
                  />
                ))}
              </DropdownContent>
            </Dropdown>
          </RightSideContainer>
        </ReportsActions>
      </ReportsHeader>
    </>
  );
}

export default GeneralProgressHeader;

const ReportsHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 24px;
  margin-top: 8px;
`;

const ReportsActions = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin: 0px 0px 12px;
  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    max-width: 1920px;
  }
`;

const LeftSideContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

const RightSideContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: end;
`;

const DropdownContent = styled(Dropdown.Content)`
  z-index: 20 !important;
`;

const DateContainer = styled.div``;

const TitleDate = styled(Typography.Body2)`
  margin-bottom: 4px;
`;

const StyledSelect = styled(Select)`
  width: 200px;
  z-index: 12;
  .react-select__control {
    background: orange;
  }
`;
