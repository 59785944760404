import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

type ErrorPanelTypes = 'error' | 'warning' | 'success';

interface ErrorPanelProps {
  text: string | JSX.Element;
  type?: ErrorPanelTypes;
  closeButton?: boolean;
}

const ErrorPanelWrapper = styled.div<{
  type?: ErrorPanelTypes;
  isVisible: boolean;
}>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px;
  background-color: ${({ theme, type }) => {
    if (type === 'error') return theme.colors.red['05'];
    if (type === 'warning') return theme.colors.orange['05'];
    return theme.colors.brand['04'];
  }};
  background-size: contain;
  border-radius: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  visibility: visible;
  opacity: 1;
  ${(props: any) =>
    !props.isVisible &&
    css`
      opacity: 0;
      transition: opacity 1s ease;
    `}
`;

const Icon = styled.span<{ type?: ErrorPanelTypes }>`
  width: 19px;
  height: 16px;
  margin-right: 8px;
  background: ${({ type }) => {
      if (type === 'error')
        return "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iOCIgY3k9IjgiIHI9IjgiIHRyYW5zZm9ybT0icm90YXRlKC0xODAgOCA4KSIgZmlsbD0iI0NDMUYwMCIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTExLjMzMzQgMTEuMzMzM0MxMS43MDE2IDEwLjk2NTEgMTEuNzAxNiAxMC4zNjgyIDExLjMzMzQgOS45OTk5N0w5LjMzMzUzIDguMDAwMDhMMTEuMzMzNiA2LjAwMDA1QzExLjcwMTggNS42MzE4NiAxMS43MDE4IDUuMDM0OTEgMTEuMzMzNiA0LjY2NjcyQzEwLjk2NTQgNC4yOTg1MyAxMC4zNjg0IDQuMjk4NTMgMTAuMDAwMiA0LjY2NjcyTDguMDAwMiA2LjY2Njc1TDYuMDAwMSA0LjY2NjY1QzUuNjMxOTEgNC4yOTg0NiA1LjAzNDk2IDQuMjk4NDYgNC42NjY3NyA0LjY2NjY0QzQuMjk4NTggNS4wMzQ4MyA0LjI5ODU4IDUuNjMxNzkgNC42NjY3NyA1Ljk5OTk4TDYuNjY2ODcgOC4wMDAwOEw0LjY2NjkxIDEwQzQuMjk4NzIgMTAuMzY4MiA0LjI5ODcyIDEwLjk2NTIgNC42NjY5MSAxMS4zMzM0QzUuMDM1MSAxMS43MDE2IDUuNjMyMDUgMTEuNzAxNiA2LjAwMDI0IDExLjMzMzRMOC4wMDAyIDkuMzMzNDFMMTAuMDAwMSAxMS4zMzMzQzEwLjM2ODMgMTEuNzAxNSAxMC45NjUyIDExLjcwMTUgMTEuMzMzNCAxMS4zMzMzWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==')";
      if (type === 'warning')
        return "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iOCIgY3k9IjgiIHI9IjgiIHRyYW5zZm9ybT0icm90YXRlKC0xODAgOCA4KSIgZmlsbD0iI0ZGOTkzMyIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTggOUM4LjU1MjI4IDkgOSA4LjU1MjI4IDkgOEw5IDVDOSA0LjQ0NzcyIDguNTUyMjkgNCA4IDRDNy40NDc3MiA0IDcgNC40NDc3MiA3IDVWOEM3IDguNTUyMjggNy40NDc3MiA5IDggOVpNOCAxMkM4LjU1MjI4IDEyIDkgMTEuNTUyMyA5IDExQzkgMTAuNDQ3NyA4LjU1MjI4IDEwIDggMTBDNy40NDc3MiAxMCA3IDEwLjQ0NzcgNyAxMUM3IDExLjU1MjMgNy40NDc3MiAxMiA4IDEyWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==')";
      return "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iOCIgY3k9IjgiIHI9IjgiIGZpbGw9IiMxNEEzOTUiLz4KPHBhdGggZD0iTTExLjU4NTIgNS4wMDc2QzExLjM2MTIgNC44NDcyIDExLjA3NzIgNC43NzUyNCAxMC43OTU1IDQuODA3NTZDMTAuNTEzOSA0LjgzOTg4IDEwLjI1NzggNC45NzM4MiAxMC4wODM1IDUuMTc5OTNMNy4wODM2OCA4LjczODExTDUuNzk5NTggNy41NTgwN0M1LjU5NjczIDcuMzg0MTMgNS4zMjg0MyA3LjI4OTQzIDUuMDUxMjEgNy4yOTM5M0M0Ljc3Mzk4IDcuMjk4NDMgNC41MDk0OCA3LjQwMTc4IDQuMzEzNDIgNy41ODIyQzQuMTE3MzYgNy43NjI2MiA0LjAwNTA2IDguMDA2MDMgNC4wMDAxNyA4LjI2MTE0QzMuOTk1MjggOC41MTYyNiA0LjA5ODE4IDguNzYzMTYgNC4yODcyIDguOTQ5ODNMNi40MjczNiAxMC45MTkzQzYuNjI5MyAxMS4xMDEgNi45MDExMiAxMS4yMDE5IDcuMTgzNTUgMTEuMkg3LjI0OTU0QzcuNDAxMTIgMTEuMTkxNSA3LjU0OTAxIDExLjE1MzUgNy42ODMzOSAxMS4wODg0QzcuODE3NzcgMTEuMDIzMyA3LjkzNTU1IDEwLjkzMjcgOC4wMjg5MiAxMC44MjI1TDExLjc3NDIgNi4zOTExNUMxMS44NjA3IDYuMjg4OTUgMTEuOTI0NCA2LjE3MjA1IDExLjk2MTcgNi4wNDcxNUMxMS45OTkgNS45MjIyNSAxMi4wMDkyIDUuNzkxOCAxMS45OTE2IDUuNjYzMjhDMTEuOTc0MSA1LjUzNDc2IDExLjkyOTEgNS40MTA2OCAxMS44NTk0IDUuMjk4MTdDMTEuNzg5NiA1LjE4NTY2IDExLjY5NjQgNS4wODY5MSAxMS41ODUyIDUuMDA3NloiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=')";
    }}
    no-repeat;
`;

const IconClose = styled.button`
  all: unset;
  width: 12px;
  height: 10px;
  cursor: pointer;
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjIuMDIwMyIgaGVpZ2h0PSIxMi4xMjE4IiByeD0iMS4wMTAxNSIgdHJhbnNmb3JtPSJtYXRyaXgoLTAuNzA3MTA2IDAuNzA3MTA4IC0wLjcwNzEwNiAtMC43MDcxMDggMTAgOC41NzE0MSkiIGZpbGw9IiMzMzMzMzMiLz4KPHJlY3Qgd2lkdGg9IjIuMDIwMyIgaGVpZ2h0PSIxMi4xMjE4IiByeD0iMS4wMTAxNSIgdHJhbnNmb3JtPSJtYXRyaXgoLTAuNzA3MTA2IC0wLjcwNzEwOCAwLjcwNzEwNiAtMC43MDcxMDcgMS40Mjg3MSAxMCkiIGZpbGw9IiMzMzMzMzMiLz4KPC9zdmc+Cg==')
    no-repeat;
`;

const Text = styled.div`
  width: 100%;
`;

const ErrorPanel = ({
  text,
  type = 'success',
  closeButton,
}: ErrorPanelProps) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleCloseClick = () => {
    setIsVisible((prev) => !prev);
  };

  return (
    <ErrorPanelWrapper
      type={type}
      isVisible={isVisible}
      data-testid="error-panel"
    >
      <Icon type={type} />
      <Text>{text}</Text>
      {closeButton && (
        <IconClose data-testid="close-button" onClick={handleCloseClick} />
      )}
    </ErrorPanelWrapper>
  );
};

export default ErrorPanel;
