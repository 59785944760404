
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as OptionsIconComponent } from "./options-icon.svg";

export const OptionsIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <OptionsIconComponent {...props} />;
};
