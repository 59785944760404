import { Link } from 'react-router-domv6';
import styled from 'styled-components';
import image from '../../assets/images/iconapps.png';
import { AnnouncementPanel, Typography } from '@innovamat/glimmer-components';

export const Container = styled.div`
  display: flex;
  height: 100vh;
  overflow: auto;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const FormContainer = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  @media screen and (max-width: 880px) {
    width: 100%;
  }
`;

export const FormHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 60px 0;
  width: 100%;
  @media screen and (max-width: 600px) {
    padding: 16px 16px 0;
  }
`;

export const LogoContainer = styled.div`
  cursor: pointer;
  max-width: 160px;
`;

export const Background = styled.div`
  background-image: url(${image});
  background-origin: content-box;
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  justify-content: flex-end;
  width: 50%;
  @media screen and (max-width: 880px) {
    display: none;
  }
`;

export const Image = styled.img`
  height: 100vh;
`;

export const CountryRegionButtonWrapper = styled.div`
  align-self: center;
  @media screen and (max-width: 600px) {
    > div > div {
      min-width: 120px;
    }
  }
`;

export const FormBody = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 64px 180px;
  width: 100%;
  @media screen and (max-width: 1400px) {
    padding: 64px 20%;
  }
  @media screen and (max-width: 880px) {
    padding: 64px 20%;
  }
`;

export const StyledNotification = styled(AnnouncementPanel)`
  background-color: ${({ theme }) => theme.colors.red['05']} !important;
  margin-bottom: 24px;
  span {
    font-family: ${({ theme }) => theme.typography.robotoMedium};
    font-size: ${({ theme }) => theme.sizes.small};
  }
  a {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.dark['01']} !important;
  }
`;

export const Title = styled.div`
  h2 {
    margin-top: 8px;
    font-family: ${({ theme }) => theme.typography.rubikSemibold};
    font-size: 32px;
    line-height: 32px;
    font-weight: 600;
  }
`;

export const ErrorContainer = styled.div`
  font-size: ${({ theme }) => theme.sizes.xsmall};
  color: ${({ theme }) => theme.colors.semantic['03']};
`;

export const GoBackLink = styled(Link)`
  display: flex;
  margin-bottom: 19px;
  svg {
    path {
      fill: ${({ theme }) => theme.colors.dark['02']};
    }
  }
`;

export const FromDataCenter = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  margin-top: 8px;
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-bottom: 8px;
  }
`;

export const FromText = styled(Typography.Body2)``;

export const DropdownSelection = styled.div<{ isPressed: boolean }>`
  display: flex;
  padding: 0px 2px;
  align-items: center;
  gap: 2px;
`;
