import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCalendarDate = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M5.625 10.625h.625a.625.625 0 0 1 .625.625v4.375M5.625 15.625h2.5M11.527 10.625h1.598a.626.626 0 0 1 .583.845l-1.558 4.155"
      />
      <path
        strokeWidth={1.249995}
        d="M.625 3.125h18.75v16.25H.625ZM.625 8.125h18.75M5.625 5V.625M14.375 5V.625"
      />
    </g>
  </svg>
);
export default SvgCalendarDate;
