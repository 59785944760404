import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const NeedHelpButtonWrapper = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.brand['04']};
  border: ${({ theme }) => `1px solid ${theme.colors.brand.dark['02']}`};
  border-radius: 4px;
  padding: 8px;
  gap: 8px;
  cursor: pointer;
  width: fit-content;
  max-height: 100%;
`;

const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TitleWrapper = styled.h2`
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
`;

const DescriptionWrapper = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;

const NeedHelpContent = styled.div<{ isResponsive?: boolean }>`
  display: 'flex';
  flex-direction: 'column';
  align-items: center;

  ${({ isResponsive, theme }) =>
    isResponsive &&
    css`
      @media (max-width: ${theme.breakpoints.md}) {
        display: none;
      }
    `}
`;

const Avatar = ({ children }: { children: any }) => {
  return <AvatarWrapper>{children}</AvatarWrapper>;
};

const Content = ({
  children,
  isResponsive,
}: {
  children: React.ReactNode;
  isResponsive?: boolean;
}) => {
  return (
    <NeedHelpContent isResponsive={isResponsive}>{children}</NeedHelpContent>
  );
};

const Title = ({ children }: { children: any }) => {
  return <TitleWrapper>{children}</TitleWrapper>;
};

const Description = ({ children }: { children: any }) => {
  return <DescriptionWrapper>{children}</DescriptionWrapper>;
};

export const NeedHelpButton = ({
  children,
  onClick,
}: {
  children: any;
  onClick: any;
}) => {
  return (
    <NeedHelpButtonWrapper onClick={onClick}>{children}</NeedHelpButtonWrapper>
  );
};

NeedHelpButton.Avatar = Avatar;
NeedHelpButton.Title = Title;
NeedHelpButton.Description = Description;
NeedHelpButton.Content = Content;
