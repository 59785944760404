
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as KeyMomentStarIconComponent } from "./key-moment-star-icon.svg";

export const KeyMomentStarIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <KeyMomentStarIconComponent {...props} />;
};
