import styled from '@emotion/styled';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

const CountryName = styled.h3`
  font-family: ${({ theme }) => theme.typography.rubikMedium};
  font-size: 20px;
`;

export { Wrapper, CountryName };
