import styled from '@emotion/styled';
import { useGlimmerTheme } from '../../theme';

import { Card } from '../card';
import { Icon } from '../Icon';
import { TextRow } from '../text-row';

const CheckIconWrapper = styled('div')`
  margin-right: 0.5rem;
`;

const CardBody = styled(Card)<{
  className: string;
  isSelected: boolean;
}>`
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  font-size: ${({ theme }) => theme.typography.typeScale.small};
  padding: ${({ theme }) => theme.spaces.medium};
  position: relative;
`;

const CheckCardGroupWrapper = styled('ul')`
  display: inline-flex;
  flex-direction: column;
  list-style-type: none;
`;

const CardItem = styled('li')<{ isSelected: boolean }>`
  cursor: pointer;
  margin: 0.5rem 0;
  min-width: 15.625rem;
  .addressCardBody {
    background-color: ${({ isSelected, theme }) =>
      isSelected
        ? theme.tokens.color.global.teal.teal200.value
        : theme.tokens.color.alias.cm.surface['surface-primary'].value};

    border: ${({ isSelected, theme }) =>
      `${theme.borders.size['02']} ${
        isSelected
          ? theme.tokens.color.global.teal.teal200.value
          : theme.tokens.color.alias.cm.border['border-subtle'].value
      }`};
  }
`;

type CheckCardProps = {
  id: string;
  isSelected: boolean;
  onClick?: (id: string) => void;
  rows: string[];
};

type Data = {
  id: string;
  rows: string[];
};

export type CheckCardGroupProps = {
  data: Data[];
  onChange: (id: string) => void;
  selectedId?: string;
};

function CheckCard({
  id,
  isSelected,
  rows,
  onClick,
}: CheckCardProps): JSX.Element {
  const theme = useGlimmerTheme();

  return (
    <CardItem key={id} isSelected={isSelected}>
      <CardBody
        borderRadius="md"
        className="addressCardBody"
        elevation="none"
        isSelected={isSelected}
        onClick={() => onClick && onClick(id)}
      >
        <CheckIconWrapper>
          {isSelected ? (
            <Icon
              size="M"
              icon="SeenInClassIcon"
              iconColor={theme.tokens.color.alias.cm.icon['icon-info'].value}
            />
          ) : (
            <Icon
              size="M"
              icon="SeenInClassIcon"
              bgColor={theme.tokens.color.alias.cm.icon['icon-disabled'].value}
            />
          )}
        </CheckIconWrapper>
        <div>
          {rows.map((line, index) => (
            <TextRow key={index}>{line}</TextRow>
          ))}
        </div>
      </CardBody>
    </CardItem>
  );
}

function CheckCardGroup({ data, onChange, selectedId }: CheckCardGroupProps) {
  const handleClick = (id: string): void => {
    onChange(id);
  };
  return (
    <CheckCardGroupWrapper>
      {data.map((item) => (
        <CheckCard
          id={item.id}
          isSelected={selectedId === item.id}
          key={item.id}
          onClick={handleClick}
          rows={item.rows}
        />
      ))}
    </CheckCardGroupWrapper>
  );
}

export { CheckCardGroup };
