import { Test, TestData, useTestQuery } from '@innovamat/glow-api-client';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { ReactComponent as TestsImage } from '../../assets/svg/tests-banner.svg';
import {
  Button,
  Input,
  Modal,
  getTypography,
} from '@innovamat/glimmer-components';
import { useEffect, useState } from 'react';
import { crypto } from '@innovamat/radiance-utils';
import { useQueryClient } from '@tanstack/react-query';

type Props = {
  availableTests: Test[];
  onTestDataReceived: (testData: TestData) => void;
  setEventData: (eventType: string, eventProperties?: any) => void;
};

export const TestsBanner = ({
  availableTests,
  onTestDataReceived,
  setEventData,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [passwordInput, setPasswordInput] = useState('');
  const [selectedTest, setSelectedTest] = useState<Test | undefined>();
  const [showEaster, setShowEaster] = useState(false);
  const backgroundImg =
    'https://s3.abcstatics.com/abc/www/multimedia/cultura/2024/06/11/frog-RTSOT3RdrHnILn89JsqxtPJ-1200x840@diario_abc.jpg';

  const queryClient = useQueryClient();

  const checkEasterEgg = (password: string) => {
    if (password === '3LM4N0') {
      setShowEaster(true);
    } else {
      setShowEaster(false);
    }
  };

  const {
    data: testData,
    refetch,
    isLoading,
  } = useTestQuery(
    {
      userTestUuid: selectedTest?.userTestUuid || '',
      testUuid: selectedTest?.testUuid || '',
    },
    {
      enabled: false,
      retry: false,
      queryKey: ['testsData'],
    }
  );

  const onCloseModal = (): void => {
    setIsModalOpen(false);
    setPasswordInput('');
    setSelectedTest(undefined);
    queryClient.resetQueries({
      queryKey: ['testsData'],
    });
  };

  const onOpenModal = (): void => {
    setIsModalOpen(true);
  };

  const onInputChange = (input: string): void => {
    setPasswordInput(input);
  };

  const validatePassword = async (password: string) => {
    const hashPassword = await crypto.hashPassword(password);
    const test = availableTests.find(
      (availableTest) => availableTest.password === hashPassword
    );
    checkEasterEgg(password);
    setSelectedTest(test);
  };

  const maxPasswordLength = 6;
  const minPasswordLength = 4;

  useEffect(() => {
    if (
      passwordInput.length >= minPasswordLength &&
      passwordInput.length <= maxPasswordLength
    )
      validatePassword(passwordInput);
  }, [passwordInput]);

  const error = passwordInput.length >= minPasswordLength && !selectedTest;
  const startButtonDisabled =
    error || passwordInput.length < minPasswordLength || isLoading;

  const onStartTest = () => {
    refetch();
    setEventData('digital_practice_test_start', {
      ...selectedTest,
    });
  };

  useEffect(() => {
    if (testData?.test) {
      onTestDataReceived(testData.test);
      onCloseModal();
    }
  }, [testData]);

  useEffect(() => {
    if (error) {
      setEventData('digital_practice_test_wrong_password');
    }
  }, [error]);

  return (
    <>
      <Subtitle>{t('student.test.section.title')}</Subtitle>
      <Banner>
        <TestsImage />
        <Container>
          <Title>{t('student.test.banner.title')}</Title>
          <BannerInstructions>
            {t('student.test.banner.instructions')}
          </BannerInstructions>
          <Button onClick={onOpenModal}>
            {t('student.test.banner.button')}
          </Button>
        </Container>
      </Banner>
      <Modal
        title={t('student.test.popUp.title')}
        semantic={{ type: 'warning', text: t('common.modal.important') }}
        isOpen={isModalOpen}
        closeOnClickOutside
        closeOnEsc
        onClose={onCloseModal}
        style={
          showEaster
            ? {
                backgroundImage: `url(${backgroundImg})`,
              }
            : {}
        }
        buttons={[
          {
            children: t('student.test.popUp.startButton'),
            variant: 'accent',
            disabled: startButtonDisabled,
            onClick: onStartTest,
          },
          {
            children: t('common.cancel'),
            variant: 'tertiary',
            onClick: onCloseModal,
          },
        ]}
        modalWidth={680}
      >
        <>
          <ModalInstructions>
            {t('student.test.popUp.instructions')}
          </ModalInstructions>
          <InputContainer>
            <Input
              onChange={(e) => onInputChange(e.target.value)}
              maxLength={maxPasswordLength}
              value={passwordInput}
              labelText={t('student.test.popUp.codeInput.title')}
              descriptiveText={
                error
                  ? t('student.test.popUp.codeInput.error')
                  : t('student.test.popUp.codeInput.instructions')
              }
              status={error ? 'error' : undefined}
            />
          </InputContainer>
        </>
      </Modal>
    </>
  );
};

const Subtitle = styled.h4`
  display: flex;
  gap: 16px;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 16px;
  ${({ theme }) => getTypography(theme, 'H4')}
`;

const Banner = styled.div`
  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.surface['surface-secondary'].value};
  display: flex;
  max-width: 821px;
  height: 174px;
  padding: 24px;
  align-items: center;
  gap: 24px;
  flex-shrink: 0;
  border-radius: 8px;
  block-size: auto;
  overflow: hidden;
  border: ${({ theme }) =>
    `1px solid ${theme.tokens.color.alias.cm.border['border-subtle'].value}`};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h4`
  ${({ theme }) => getTypography(theme, 'H4')}
`;

const BannerInstructions = styled.div`
  ${({ theme }) => getTypography(theme, 'Body 2')}
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
  margin-bottom: 16px;
`;

const ModalInstructions = styled.div`
  ${({ theme }) => getTypography(theme, 'Body 1')}
  margin-bottom: 16px;
`;

const InputContainer = styled.div`
  width: 100%;
  @media screen and (min-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 50%;
  }
`;
