import styled from '@emotion/styled';
import { EmptyClassroom } from '@innovamat/glimmer-assets';
import { Typography } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';
import { useJoinToClassroom } from '../join-to-classroom-context';
import { AddClassroomDropdown } from '../../../components/add-classroom-dropdown';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 24px;
`;

const SubtleText = styled(Typography.Body1)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

export function SelectClassroomEmptyState(): JSX.Element {
  const { t } = useTranslation();
  const { orgId, region, handleSuccessNewClassroom } = useJoinToClassroom();

  return (
    <Container>
      <EmptyClassroom />
      <div>
        <Typography.H4>
          {t(
            'select-classrooms.select-classroom.empty-state.title',
            'Este centro aún no tiene clases creadas'
          )}
        </Typography.H4>
        <SubtleText>
          {t(
            'select-classrooms.select-classroom.empty-state.description',
            'Crea una nueva clase usando el botón de "añadir clase"'
          )}
        </SubtleText>
      </div>
      <AddClassroomDropdown
        options={['add', 'import']}
        organizationId={orgId}
        region={region}
        size="M"
        variant="accent"
        onSuccess={handleSuccessNewClassroom}
      />
    </Container>
  );
}
