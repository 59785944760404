import { ReactComponent as LoadingAnimation } from './animation.svg';
import './animation.css';

import { Container, LoaderBox, Loader } from './SceneSpinner.styled';

function SceneSpinner(): JSX.Element {
  return (
    <Container>
      <LoaderBox>
        <Loader>
          <LoadingAnimation />
        </Loader>
      </LoaderBox>
    </Container>
  );
}

export default SceneSpinner;
