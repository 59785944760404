import styled from '@emotion/styled';
import { Icon } from '@innovamat/glimmer-components';

const StyledSpan = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  color: ${({ theme }) => theme.tokens.color.specific.element.link.value};
  text-decoration: underline;
  cursor: pointer;

  :hover {
    color: ${({ theme }) =>
      theme.tokens.color.specific.element['link-hovered'].value};
    svg path {
      fill: ${({ theme }) =>
        theme.tokens.color.specific.element['link-hovered'].value};
    }
  }

  svg path {
    fill: ${({ theme }) => theme.tokens.color.specific.element.link.value};
  }

  @media print {
    /* color: inherit; */
    text-decoration: none;

    div {
      display: none;
    }
  }
`;

const StyledIcon = styled(Icon)`
  transform: translateY(2px);
`;

type Props = {
  label: string;
  id: string;
  navigateToResource: (id: string) => void;
};

export function PlayerLink({
  label,
  id,
  navigateToResource,
}: Props): JSX.Element {
  const handleClick = (event: React.MouseEvent): void => {
    event.stopPropagation();
    navigateToResource(id);
  };

  return (
    <>
      <StyledSpan onClick={handleClick}>
        {label}
        <StyledIcon icon="ProjectableIcon" size="S" />
      </StyledSpan>
    </>
  );
}
