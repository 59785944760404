import styled from '@emotion/styled';
import { Grid, useDevice } from '@innovamat/glimmer-components';
import type { User } from '..';
import {
  MySettingsContent,
  MySettingsContentDrawer,
} from '../components/my-settings.content';
import { MySettingHeader } from '../components/my-settings.header';
import { MySettingsMenu } from '../components/my-settings.menu';
import { MySettingsModalSave } from '../components/my-settings.modal-save';
import { MySettingsProvider } from '../providers/my-settings-provider';
import { useEnvVariables } from '../../env-variables-manager';

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.surface['surface-primary'].value};
  position: fixed;
  z-index: 21;
  top: 0;
  left: 0;
`;

const ContentWrapper = styled.div`
  max-width: ${({ theme }) => theme.breakpoints.xl};
  width: 100%;
  margin: 0 auto;
  overflow: auto;
  height: calc(100% - 4rem);
  padding-bottom: 5rem;
`;

const MenuStickyContainer = styled.div`
  position: sticky;
  top: 0;
`;

type MySettingsProps = {
  user: User;
  goBack: () => void;
  onSuccess: () => void;
  useGoogleClassroomSync: any;
};

export function MySettings(props: MySettingsProps): JSX.Element {
  const { isMobile } = useDevice();
  const { goBack } = props;

  return (
    <MySettingsProvider {...props}>
      <MySettingsModalSave goBack={goBack} />
      <Container>
        <MySettingHeader />
        <ContentWrapper>
          <Grid.Container>
            <Grid.Row>
              <Grid.Col xs={4} lg={3}>
                <MenuStickyContainer>
                  <MySettingsMenu />
                </MenuStickyContainer>
              </Grid.Col>

              {isMobile ? <MySettingsContentDrawer /> : <MySettingsContent />}
            </Grid.Row>
          </Grid.Container>
        </ContentWrapper>
      </Container>
    </MySettingsProvider>
  );
}
