
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as FilePdfIconComponent } from "./file-pdf-icon.svg";

export const FilePdfIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <FilePdfIconComponent {...props} />;
};
