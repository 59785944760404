import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import type { ButtonProps } from '@innovamat/glimmer-components';
import {
  AnnouncementPanel,
  Button,
  FileUploader,
  ImportCSVAnimation,
  theme,
  TitleHeader,
  Typography,
} from '@innovamat/glimmer-components';

import { useImportCSVProcess } from '../hooks/use-import-csv-process';
import { useImportCSV } from '../providers/use-import-csv-provider';
import { IMPORT_TYPES, Step, STEPS, type ImportType } from '../types';

import { StudentsValidation } from '../components';
import { ConfirmSkipValidationModal } from '../components/confirm-skip-validation-modal';
import { ImportErrorPage } from '../components/import-error-page';

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 1.5rem;
`;

const Description = styled.div`
  margin-top: 0.5rem;
  a {
    cursor: pointer;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 1.5rem;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SkipStepFooter = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 0.5rem;
`;

const AnimationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 11.25rem;
  padding-top: 3.5rem;
  gap: 2rem;
`;

type Props = {
  type: ImportType;
};

const SkipValidationButton = ({
  onClick,
  variant = 'accent',
}: {
  onClick: () => void;
  variant?: ButtonProps['variant'];
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Button variant={variant} onClick={onClick}>
      {t('school.import_csv.skipValidationButton')}
    </Button>
  );
};

function SelectStep({
  type,
  formData,
  handleSetFile,
  handleSubmit,
}: {
  type: ImportType;
  formData: FormData | null;
  handleSetFile: (file: File) => void;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}): JSX.Element {
  const { t } = useTranslation();

  const translation_key =
    type === IMPORT_TYPES.STUDENT ? 'students' : 'teachers_classrooms';

  return (
    <>
      <Description>
        <Typography.Body1>
          <Trans
            i18nKey={`school.import_csv.select.description.${translation_key}`}
            components={{
              a: (
                <Typography.Link1
                  color={theme.tokens.color.specific.element.link.value}
                  href={t('school.import_csv.select.specification_url')}
                  rel="noopener noreferrer"
                  target="_blank"
                />
              ),
              csv: (
                <Typography.Link1
                  color={theme.tokens.color.specific.element.link.value}
                  href={
                    type === 'classroom'
                      ? 'https://3dt0kb75phz7.b-cdn.net/5e6vii75llyz-class-list-example.csv'
                      : 'https://3dt0kb75phz7.b-cdn.net/5lfegu5pgs0-ejemplo-teachers.csv'
                  }
                  rel="noopener noreferrer"
                  target="_blank"
                />
              ),
              csvEy: (
                <Typography.Link1
                  color={theme.tokens.color.specific.element.link.value}
                  href="https://3dt0kb75phz7.b-cdn.net/564lq55starm-ejemplo-students-eype.csv"
                  rel="noopener noreferrer"
                  target="_blank"
                />
              ),
              csvSe: (
                <Typography.Link1
                  color={theme.tokens.color.specific.element.link.value}
                  href="https://3dt0kb75phz7.b-cdn.net/4qec4rw10f2v-ejemplo-students-se.csv"
                  rel="noopener noreferrer"
                  target="_blank"
                />
              ),
            }}
          />
        </Typography.Body1>
      </Description>

      <Form onSubmit={handleSubmit}>
        <FileUploader
          buttonLabel={t('school.import_csv.select.upload')}
          handleFile={handleSetFile}
          hasDragAndDrop
          restrictedFileTypes=".csv"
          uploadedFileDescription={t(
            'school.import_csv.select.choose_other_file'
          )}
        />
        <Footer>
          <Button
            disabled={!formData?.get('csv')}
            type="submit"
            variant="accent"
          >
            {t('school.import_csv.select.continue')}
          </Button>
        </Footer>
      </Form>
    </>
  );
}

function ImportingStep(): JSX.Element {
  const { t } = useTranslation();

  return (
    <AnimationWrapper>
      <ImportCSVAnimation />
      <Typography.Subtitle1>
        {t('school.import_csv.importing')}
      </Typography.Subtitle1>
    </AnimationWrapper>
  );
}

function UploadedStep({
  handleResetForm,
  goToImportsPage,
  importCSVStatus,
}: {
  handleResetForm: () => void;
  goToImportsPage: () => void;
  importCSVStatus: { isError: boolean };
}): JSX.Element {
  const { t } = useTranslation();

  if (importCSVStatus.isError) {
    return <ImportErrorPage onGoBack={handleResetForm} />;
  }

  return (
    <>
      <AnnouncementPanel
        canClose={false}
        text={t('school.import_csv.uploaded.notification')}
      />
      <Description>
        <Typography.Body1>
          <Trans
            i18nKey="school.import_csv.uploaded.description"
            components={{
              importsPageLink: (
                <Typography.Link1
                  color={theme.tokens.color.specific.element.link.value}
                  onClick={goToImportsPage}
                  rel="noopener noreferrer"
                  target="_blank"
                />
              ),
            }}
          />
        </Typography.Body1>
      </Description>
      <Footer>
        <Button variant="accent" type="button" onClick={goToImportsPage}>
          {t('school.import_csv.cta.back_to_lists')}
        </Button>
      </Footer>
    </>
  );
}

function SkipValidationStep({
  handleSetStep,
  setShowConfirmSkipModal,
}: {
  handleSetStep: (step: Step) => void;
  setShowConfirmSkipModal: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <Description>
        <Typography.Body1>
          {t('school.import_csv.skipValidation.description')}
        </Typography.Body1>
      </Description>
      <Typography.Subtitle1>
        {t('school.import_csv.skipValidation.subtitle')}
      </Typography.Subtitle1>
      <SkipStepFooter>
        <SkipValidationButton
          onClick={() => setShowConfirmSkipModal(true)}
          variant="secondary"
        />
        <Button
          onClick={() => {
            handleSetStep(STEPS.VALIDATION);
          }}
          type="button"
          variant="accent"
        >
          {t('common.continue')}
        </Button>
      </SkipStepFooter>
    </>
  );
}

function ImportCSV({ type }: Props): JSX.Element {
  const [showConfirmSkipModal, setShowConfirmSkipModal] = useState(false);

  const { goToImportsPage, onGoBack, canSkipValidationStep } = useImportCSV();
  const { t } = useTranslation();

  const {
    csvFile,
    formData,
    importCSVStatus,
    UIState,
    handleResetForm,
    handleSetFile,
    handleSetStep,
    handleUploadFile,
  } = useImportCSVProcess();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    if (!type || !formData) return;

    if (canSkipValidationStep) {
      handleSetStep(STEPS.SKIP_VALIDATION);
    } else if (type === 'student' || type === 'schoolStudent') {
      handleSetStep(STEPS.VALIDATION);
    } else {
      handleUploadFile(type);
    }
  };

  const goBack = (): void => {
    onGoBack();
  };

  const renderUIState = (): JSX.Element => {
    switch (UIState) {
      case STEPS.SELECT:
        return (
          <SelectStep
            type={type}
            formData={formData}
            handleSetFile={handleSetFile}
            handleSubmit={handleSubmit}
          />
        );
      case STEPS.IMPORTING:
        return <ImportingStep />;

      case STEPS.UPLOADED:
        return (
          <UploadedStep
            handleResetForm={handleResetForm}
            goToImportsPage={goToImportsPage}
            importCSVStatus={importCSVStatus}
          />
        );

      case STEPS.SKIP_VALIDATION:
        return (
          <SkipValidationStep
            handleSetStep={handleSetStep}
            setShowConfirmSkipModal={setShowConfirmSkipModal}
          />
        );

      case STEPS.VALIDATION:
        return (
          <StudentsValidation
            csvFile={csvFile!}
            onGoBack={handleResetForm}
            onUploadFile={() => handleUploadFile(type)}
          />
        );
      default:
        return <></>;
    }
  };

  if (!type) {
    return <></>;
  }

  return (
    <>
      <TitleHeader
        actions={
          canSkipValidationStep && UIState === STEPS.VALIDATION ? (
            <SkipValidationButton
              onClick={() => setShowConfirmSkipModal(true)}
            />
          ) : undefined
        }
        hasSeparator={false}
        hasMargin
        onGoBack={goBack}
        title={`${t(`school.import_csv.${type}`)} ${t(
          'school.import_csv.title'
        )}`}
      />
      <SectionWrapper>{renderUIState()}</SectionWrapper>

      {showConfirmSkipModal && (
        <ConfirmSkipValidationModal
          onClose={() => setShowConfirmSkipModal(false)}
          onConfirm={() => {
            handleUploadFile(type);
            setShowConfirmSkipModal(false);
          }}
          showModal={showConfirmSkipModal}
        />
      )}
    </>
  );
}

export { ImportCSV };
