import styled from '@emotion/styled';
import { Typography } from '../Typography';

const Container = styled.div`
  min-height: 2.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    gap: 1rem;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

const HeadingWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 0.5rem;
`;

const Subtitle = styled(Typography.Body1)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

const ActionsWrapper = styled.div``;

type HeadingSectionProps = {
  actions?: React.ReactNode;
  subtitle?: string;
  title: string;
};

const HeadingSection = ({
  actions,
  subtitle,
  title,
}: HeadingSectionProps): JSX.Element => {
  return (
    <Container>
      <HeadingWrapper>
        <Typography.H3> {title}</Typography.H3>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </HeadingWrapper>

      {actions && <ActionsWrapper>{actions}</ActionsWrapper>}
    </Container>
  );
};

export { HeadingSection };
export type { HeadingSectionProps };
