import styled from '@emotion/styled';

export const NoSpacesMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 68px;
`;

export const NoSpacesMainMessage = styled.div`
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-weight: ${({ theme }) => theme.typography.weight.bold};
  line-height: ${({ theme }) => theme.typography.lineHeight.medium};
  font-size: ${({ theme }) => theme.sizes.xmedium};
  color: ${({ theme }) => theme.colors.dark['01']};
  margin: auto;
  margin-top: 31px;
`;

export const NoMessageSecondaryMessage = styled.div`
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-weight: ${({ theme }) => theme.typography.weight.regular};
  line-height: ${({ theme }) => theme.typography.lineHeight.normal};
  font-size: ${({ theme }) => theme.sizes.normal};
  color: ${({ theme }) => theme.colors.dark['02']};
  margin: auto;
  margin-top: 8px;
  max-width: 648px;
  text-align: center;
`;

export const PinWrapper = styled.div`
  width: 96px;
  height: 96px;
  background-color: ${({ theme }) => theme.colors.semantic['01b']};
  border-radius: 50%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  path {
    fill: ${({ theme }) => theme.colors.brand['01']};
    stroke: ${({ theme }) => theme.colors.brand['01']};
    width: 96px;
    height: 96px;
  }

  > div {
    transform: scale(2.15);
  }
`;
