import styled from '@emotion/styled';
import { Bar, useGlimmerTheme } from '@innovamat/glimmer-components';
import { MinorText, ReportsTypography } from '../../admin-dashboard.styled';
import PlaceholderCell from '../placeholder-cell/placeholder-cell';

type Props = {
  value: number;
  rowIsNull: boolean;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  width: fit-content;
`;

const AverageScoreBar = ({ value, rowIsNull }: Props): JSX.Element => {
  const theme = useGlimmerTheme();

  const barColor =
    value > 40
      ? theme.tokens.color.specific.reports['progress-bar'].completed.value
      : theme.tokens.color.specific.reports.semantic.low.value;

  if (rowIsNull) return <PlaceholderCell left leftMargin={20} />;

  return (
    <Container>
      <ReportsTypography>
        {value}
        <MinorText>%</MinorText>
      </ReportsTypography>

      <Bar colors={[barColor]} values={[value]} />
    </Container>
  );
};
export default AverageScoreBar;
