import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

type Props = {
  size?: 'sm' | 'md' | 'lg';
  color?: 'black' | 'white' | 'primary' | string;
} & React.ButtonHTMLAttributes<HTMLDivElement>;

const StyledLoader = styled.div<Props>`
  ${({ size }) => {
    const multiplier =
      size === 'sm' ? 1 : size === 'md' ? 2 : size === 'lg' ? 3 : 1;
    const sizeValue = 20 * multiplier;
    const borderValue = 1.5 * multiplier;
    return css`
      width: ${sizeValue}px;
      height: ${sizeValue}px;
      border: ${borderValue}px solid transparent;
      border-radius: 50%;
      border-left: ${borderValue}px solid;
      border-top: ${borderValue}px solid;
      border-right: ${borderValue}px solid;
    `;
  }};

  ${({ color, theme }) => {
    const borderColor =
      color === 'black'
        ? theme.tokens.color.alias.cm.border['border-default'].value
        : color === 'white'
        ? theme.tokens.color.alias.cm.border['border-inverted'].value
        : color === 'primary'
        ? theme.tokens.color.alias.cm.border['border-accent'].value
        : color;

    return css`
      border-left-color: ${borderColor};
      border-top-color: ${borderColor};
      border-right-color: ${borderColor};
    `;
  }}

  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export function Loader({ size = 'sm', color = 'black', ...props }: Props) {
  return <StyledLoader {...props} size={size} color={color} />;
}
