import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSuport = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      clipPath="url(#suport_svg__a)"
    >
      <path d="M1 10a9 9 0 1 0 18 0 9 9 0 0 0-18 0" />
      <path d="M9.531 14.814 5.98 10.375a.6.6 0 0 1 .468-.975H8.2V5.2a.6.6 0 0 1 .6-.6h2.4a.6.6 0 0 1 .6.6v4.2h1.752a.6.6 0 0 1 .468.975l-3.551 4.44a.6.6 0 0 1-.938 0" />
    </g>
    <defs>
      <clipPath id="suport_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSuport;
