import CryptoES from 'crypto-es';

export const ENCRYPT_VALUE = 'inm2023';

export const encryptWithAES = (text: string) =>
  CryptoES.AES.encrypt(text, ENCRYPT_VALUE).toString();

export const decryptWithAES = (ciphertext: string) => {
  const bytes = CryptoES.AES.decrypt(ciphertext, ENCRYPT_VALUE);
  const originalText = bytes.toString(CryptoES.enc.Utf8);
  return originalText;
};

export async function hashPassword(password: string) {
  const encoder = new TextEncoder();
  const data = encoder.encode(password);
  const hash = await crypto.subtle.digest('SHA-256', data);
  return Array.from(new Uint8Array(hash))
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
}