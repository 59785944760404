import * as React from 'react';
import type { SVGProps } from 'react';
const SvgViewDisabled = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="m2.32 17.5 15.624-15M7.47 15.876a8.3 8.3 0 0 0 2.53.374c3.417.058 6.882-2.344 9.02-4.697a1.386 1.386 0 0 0 0-1.856A17 17 0 0 0 16.458 7.4M12.01 5.235A8 8 0 0 0 10 5C6.64 4.944 3.167 7.293.983 9.696a1.386 1.386 0 0 0 0 1.855 17.3 17.3 0 0 0 2.166 2"
      />
      <path
        strokeWidth={1.249995}
        d="M6.875 10.625A3.124 3.124 0 0 1 10 7.5M13.125 10.624A3.125 3.125 0 0 1 10 13.75"
      />
    </g>
  </svg>
);
export default SvgViewDisabled;
