
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as ExpandMoreIconComponent } from "./expand-more-icon.svg";

export const ExpandMoreIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <ExpandMoreIconComponent {...props} />;
};
