import React from 'react';
import styled from '@emotion/styled';

interface LinkBoxProps {
  children?: JSX.Element;
  withArrow?: boolean;
}

const LinkBoxWrapper = styled.label`
  width: 424px;
  height: 72px;
  border: 1px solid ${(props: any) => props.theme.colors.dark['04']};
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 22px;
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  width: 100%;
`;

const IconArrow = styled.button`
  all: unset;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background: url('data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBzdHJva2U9IiMzMzMiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIj48cGF0aCBkPSJtMyAxMiAxOC0uMDAwMiIvPjxwYXRoIGQ9Im0xNSA2LjU4MzE5IDYgNS40MTY3MS02IDUuNDE2NiIvPjwvZz48L3N2Zz4=')
    no-repeat;
`;

const LinkBox = ({ children, withArrow }: LinkBoxProps) => (
  <LinkBoxWrapper>
    <Content>{children}</Content>
    {withArrow && <IconArrow />}
  </LinkBoxWrapper>
);

export default LinkBox;
