import styled from '@emotion/styled';

const Action = styled('div')<{ size: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
  min-width: ${({ size }) => (size === 'md' ? '40px' : '32px')};
  width: fit-content;
  height: ${({ size }) => (size === 'md' ? '40px' : '32px')};
  background-color: ${({ theme }) => theme.colors.dark['06']};
`;

const CheckAction = styled(Action)`
  padding-left: ${({ size }) => (size === 'md' ? '10px' : '8px')};
  padding-right: ${({ size }) => (size === 'md' ? '10px' : '8px')};

  span {
    font-size: 0;
    opacity: 0;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.dark['05']};

    span {
      visibility: visible;
      opacity: 1;
      transition: font-size 0.1s linear;
      font-size: 12px;
      margin-right: 8px;
    }
  }
`;

export { CheckAction };
