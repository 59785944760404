import styled from '@emotion/styled';
import { SimpleBlock } from '../SimpleBlock';
import { Tag } from '../Tag';
import { Dimensions } from '../../types/Dimension';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  @media print {
    gap: 4px;
  }
`;

export type DimensionTag = {
  textKey: string;
  isEnabled: boolean;
  dimension: Dimensions;
};

type Props = {
  tags: DimensionTag[];
};

export function DimensionTags({ tags }: Props) {
  const { t } = useTranslation();

  const areAllTagsDisabled = tags.every((tag) => !tag.isEnabled);

  if (areAllTagsDisabled) {
    return null;
  }

  return (
    <SimpleBlock title={t('digitalguides.dimensions')}>
      <Container>
        {tags.map((tag: DimensionTag, index) => {
          return (
            <Tag
              key={`${tag.textKey}-${index}`}
              text={t(tag.textKey)}
              isEnabled={tag.isEnabled}
              dimension={tag.dimension}
            />
          );
        })}
      </Container>
    </SimpleBlock>
  );
}
