import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPill = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="m11.838 1.975-9.723 9.723a4.375 4.375 0 0 0 6.187 6.187l9.723-9.718a4.377 4.377 0 0 0-6.187-6.192M12.722 13.466 6.534 7.278"
      />
      <path
        strokeWidth={1.249995}
        d="M13.167 4.185a1.874 1.874 0 0 1 2.651 0h0"
      />
    </g>
  </svg>
);
export default SvgPill;
