
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as EditIconComponent } from "./edit-icon.svg";

export const EditIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <EditIconComponent {...props} />;
};
