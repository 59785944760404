import React from 'react';
import cx from 'classnames';
import styles from './Checkbox.module.scss';
import { Check } from '../SvgIcons';

type Props = {
  label?: string;
  align?: 'start' | 'center' | 'end';
  miniBorder?: boolean;
  color?: 'primary' | 'default';
  checkMarkStyle?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

function Checkbox({
  label,
  align,
  name,
  miniBorder,
  color = 'primary',
  checkMarkStyle,
  ...rest
}: Props) {
  const classNames = cx(styles.container, {
    [styles[align as string]]: !!align,
  });

  const checkMark = cx(styles.checkmark, {
    [styles.miniBorder]: miniBorder,
    [styles[color]]: true,
    [checkMarkStyle as string]: !!checkMarkStyle,
  });

  return (
    <label className={classNames} htmlFor={name}>
      <span>{label}</span>
      <input {...rest} type="checkbox" />
      <div className={checkMark}>
        <Check name={name} />
      </div>
    </label>
  );
}

export default Checkbox;
