import styled from '@emotion/styled';
import { Grid } from '../grid';

const Footer = styled.div`
  z-index: 20;
  display: flex;
  align-items: center;
  height: 72px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid
    ${({ theme }) => theme.tokens.color.alias.cm.border['border-subtle'].value};
  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.surface['surface-primary'].value};
`;

const FooterActions = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  gap: 16px;
`;

const Actions = styled.div`
  display: flex;
  gap: 16px;
`;

type StepFooterProps = {
  currentStep: number;
  leftFooterActions?: React.ReactNode;
  rightFooterActions?: React.ReactNode;
};

export function StepFooter({
  currentStep,
  leftFooterActions,
  rightFooterActions,
}: StepFooterProps) {
  return (
    <Footer>
      <Grid.Container>
        <Grid.Row justifyContent="center">
          <Grid.Col xs={8}>
            <FooterActions>
              <Actions>{leftFooterActions && leftFooterActions}</Actions>
              <Actions>{rightFooterActions && rightFooterActions}</Actions>
            </FooterActions>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </Footer>
  );
}
