import { Drawer, IconButton, Typography } from '@innovamat/glimmer-components';
import { useSmartGuides } from '../../context/SmartGuidesProvider';
import { useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { AssessableResources } from '@innovamat/ga-features';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  max-width: 488px;
  min-width: 488px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  padding-bottom: 8px;
  border-bottom: 1px solid
    ${({ theme }) => theme.tokens.color.alias.cm.border['border-subtle'].value};
`;

const Subtitle = styled(Typography.SuperTitle)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

const Title = styled(Typography.H3)``;

const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Lists = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  .list-subtitle {
    border-bottom: none;
    padding: 0;
    height: auto;
  }
`;

export function AssessableDrawer(): JSX.Element {
  const {
    isAssessableDrawerOpen,
    setIsAssessableDrawerOpen,
    smartGuide,
    session,
    setEventData,
    classroomId,
  } = useSmartGuides();

  const assessableLists = useMemo(
    () => smartGuide?.summary.assessables,
    [smartGuide]
  );

  const onClose = (): void => {
    setIsAssessableDrawerOpen(false);
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    const listType = url.searchParams.get('listType');

    if (listType === 'assessable') {
      setIsAssessableDrawerOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Drawer
      closeOnClickOutside
      roundedBorder
      open={isAssessableDrawerOpen}
      onClose={onClose}
    >
      <Container>
        <Header>
          <IconButton icon="CloseIcon" onClick={onClose} />
          <HeaderText>
            <Subtitle>{smartGuide.subtitle}</Subtitle>
            <Title>{session.title}</Title>
          </HeaderText>
        </Header>

        <Lists>
          <AssessableResources
            assessableLists={assessableLists || []}
            sessionName={session.title}
            sessionId={session.id}
            classroomId={classroomId || ''}
            setEventData={setEventData}
            language={session.language}
            isStudent={false}
          />
        </Lists>
      </Container>
    </Drawer>
  );
}
