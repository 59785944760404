
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as RemoveIconComponent } from "./remove-icon.svg";

export const RemoveIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <RemoveIconComponent {...props} />;
};
