import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNotesTask = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M13.125 3.75h3.125A1.25 1.25 0 0 1 17.5 5v13.125a1.25 1.25 0 0 1-1.25 1.25H3.75a1.25 1.25 0 0 1-1.25-1.25V5a1.25 1.25 0 0 1 1.25-1.25h3.125a3.125 3.125 0 0 1 6.25 0"
      />
      <path
        strokeWidth={1.249995}
        d="M10 3.125a.313.313 0 1 1-.312.313.313.313 0 0 1 .312-.313M5.625 8.75H10M12.5 8.75h1.875M14.375 11.875H10M7.5 11.875H5.625M5.625 15H10M12.5 15h1.875"
      />
    </g>
  </svg>
);
export default SvgNotesTask;
