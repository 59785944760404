import React, { ChangeEvent } from 'react';
import { Icon } from '../Icon';
import { IconType } from '@innovamat/glimmer-icons';
import { Typography } from '../Typography';
import {
  Wrapper,
  InputContainer,
  StyledInput,
  DescriptiveText,
  WrapperButton,
  StyledTooltip,
} from './input.styled';

export type InputStatus = 'disabled' | 'error' | 'autofilled';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  leftIcon?: IconType;
  rightIcon?: IconType;
  rightIconTooltip?: string;
  labelText?: string;
  descriptiveText?: string | JSX.Element;
  status?: InputStatus;
  onIconClick?: () => void;
  fill?: boolean;
  keepIconColor?: boolean;
  dataTestId?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      leftIcon,
      rightIcon,
      rightIconTooltip,
      labelText,
      descriptiveText,
      status,
      fill,
      onIconClick,
      keepIconColor,
      dataTestId,
      ...props
    },
    ref
  ) => {
    return (
      <Wrapper status={status} fill={fill} keepIconColor={keepIconColor}>
        {labelText && <Typography.Body2>{labelText}</Typography.Body2>}
        <InputContainer className="inputContainer">
          {leftIcon && <Icon icon={leftIcon} size="L" />}
          <StyledInput
            ref={ref}
            disabled={status === 'disabled' || status === 'autofilled'}
            data-testid={dataTestId}
            {...props}
          />
          {rightIcon && (
            <StyledTooltip content={rightIconTooltip}>
              <WrapperButton onClick={onIconClick}>
                <Icon icon={rightIcon} size="L" />
              </WrapperButton>
            </StyledTooltip>
          )}
        </InputContainer>
        {descriptiveText &&
          (typeof descriptiveText === 'string' ? (
            <DescriptiveText isError={status === 'error'}>
              {descriptiveText}
            </DescriptiveText>
          ) : (
            <Typography.Body3>{descriptiveText}</Typography.Body3>
          ))}
      </Wrapper>
    );
  }
);

export { Input };
