import { TokenData, cookies } from '@innovamat/radiance-utils';
import axios, { AxiosResponse } from 'axios';
import { DeviceCodeData, LoginRequest } from './types';

const { CookieKeys } = cookies;

export const CLIENT_ID = process.env.NX_USERS_CLIENT_ID;
export const deviceCodeCookie = CookieKeys.deviceCode;

const authDataSource = {
  login: async (
    data: LoginRequest,
    apiUsers: string
  ): Promise<AxiosResponse<TokenData>> => {
    const url = `${apiUsers}/auth/openid-connect/token`;
    const axiosInstance = axios.create();
    const response = await axiosInstance({
      method: 'POST',
      url,
      headers: {},
      data: {
        grant_type: 'password',
        client_id: CLIENT_ID,
        username: data.username,
        password: data.password,
        scope: 'openid profile email',
      },
    });

    return response;
  },
  meDevice: async (
    token: string,
    apiUsers: string
  ): Promise<AxiosResponse<DeviceCodeData>> => {
    const url = `${apiUsers}/users/me/device`;
    const axiosInstance = axios.create();
    const response = await axiosInstance({
      method: 'POST',
      url,
      headers: {
        authorization: `Bearer ${token}`,
      },
      data: {},
    });

    return response;
  },
  deviceLogin: async (deviceCode: string): Promise<TokenData> => {
    const apiUsers = window.__GA_CONFIG__.apiUsers;
    const url = `${apiUsers}/auth/openid-connect/token`;
    const axiosInstance = axios.create();
    const response = await axiosInstance({
      method: 'POST',
      url,
      headers: {},
      data: {
        grant_type: 'device_code',
        device_code: deviceCode,
        client_id: CLIENT_ID,
      },
    });

    return response.data;
  },
  msalLogin: async (
    data: string,
    apiUsers: string
  ): Promise<AxiosResponse<TokenData>> => {
    const url = `${apiUsers}/auth/openid-connect/token`;
    const axiosInstance = axios.create();
    const response = await axiosInstance({
      method: 'POST',
      url,
      headers: {},
      data: {
        grant_type: 'azure_token',
        client_id: CLIENT_ID,
        token: data,
      },
    });

    return response;
  },
};

export default authDataSource;
