import * as React from 'react';
import type { SVGProps } from 'react';
const SvgTramo8 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g clipPath="url(#tramo-8_svg__a)">
      <path
        stroke="#757575"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.25}
        d="M16.445 18.786H2.383a1.17 1.17 0 0 1-1.172-1.172V3.552m9.96 4.98a1.758 1.758 0 1 1 .001 3.515 1.758 1.758 0 0 1 0-3.515m0 0a1.318 1.318 0 1 1-.001-2.636 1.318 1.318 0 0 1 .002 2.636M4.728 1.208h12.89s1.172 0 1.172 1.172v12.89s0 1.172-1.172 1.172H4.727s-1.172 0-1.172-1.172V2.38s0-1.172 1.172-1.172"
      />
    </g>
    <defs>
      <clipPath id="tramo-8_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgTramo8;
