import { css } from "@emotion/react";
import { theme } from "@innovamat/glimmer-components";

export const getReportsTypography = () => {
  const reportsTokenKey =
    theme.tokens.typography.Reports['data-highlight'].value;

  return css`
    font-family: '${reportsTokenKey.fontFamily}', sans-serif;
    font-weight: ${reportsTokenKey.fontWeight};
    font-size: ${reportsTokenKey.fontSize};
    line-height: ${reportsTokenKey.lineHeight};

    font-style: 'normal';
  `;
};
