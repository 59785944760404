import {
  addAppletToBlacklist,
  addAppletToWarnings,
  notifyToSlackChannel,
} from './dataSources';
import { UnityToolkitErrorsProps } from './types';
import {
  checkIsWarning,
  hasNetworkError,
  hasRelatedNetworkError,
} from './utils';

let lastMessages: any[] = [];

type Connection = {
  effectiveType: string;
  downlink: number;
  rtt: number;
  saveData: boolean;
};
declare global {
  interface Navigator {
    connection?: Connection;
    mozConnection?: Connection;
    webkitConnection?: Connection;
  }
}

export function manageUnityError(props: UnityToolkitErrorsProps): void {
  const {
    error,
    setEventData,
    axiosInstance,
    slackApiUrl,
    slackApiTokens,
    apolloServer,
    slackEnabled,
    warningEnabled,
    origin,
    version,
  } = props;

  const connection =
    navigator.connection ||
    navigator.mozConnection ||
    navigator.webkitConnection;
  const { effectiveType, downlink, rtt } = connection || {};
  const userAgent = navigator.userAgent;
  const platform = navigator.platform;

  const eventOrigin = origin ? `${origin}_` : '';

  if (lastMessages.length === 0) {
    setTimeout(purgeErrorMessages, 5000);
  }
  lastMessages.push(error);

  function purgeErrorMessages(): void {
    const isNetworkError = lastMessages.some((error: any) =>
      hasNetworkError(error?.errorMessage)
    );
    let relevantMessages = [];

    if (isNetworkError) {
      lastMessages.forEach((error) => {
        if (!hasRelatedNetworkError(error)) {
          relevantMessages.push(error);
        }
      });
    } else {
      relevantMessages = lastMessages;
    }

    const nonRedundantMessages: Map<string, any> = new Map();
    relevantMessages.forEach((error) => {
      if (
        error?.errorMessage != null &&
        !nonRedundantMessages.has(error.errorMessage)
      ) {
        nonRedundantMessages.set(error.errorMessage, error);
      }
    });
    console.log('Trying to send error messages:', nonRedundantMessages);

    for (const [, value] of nonRedundantMessages.entries()) {
      sendErrorToSystem(value);
    }

    lastMessages = [];
  }

  const sendErrorToSystem = (error: any): void => {
    console.log('Sending error to the system');

    if (checkIsWarning(error.errorMessage)) {
      console.log('Error actionsWhenWarning');
      if (warningEnabled) actionsWhenWarning(error);
    } else {
      console.log('Error actionsWhenError');
      setEventData(`${eventOrigin}sys_applet_exception`, {
        error,
      });
      actionsWhenError(error);
    }
  };

  const actionsWhenError = (error: any): void => {
    const { packOrVariation, sceneName, stage, school, uid } = error;
    if (
      error != null &&
      error.metaDataInfo != null &&
      !error.metaDataInfo.isCompleted
    ) {
      if (slackEnabled && slackApiTokens && slackApiUrl)
        notifyToSlackChannel(
          { school, sceneName, packOrVariation, error },
          slackApiUrl,
          slackApiTokens
        );
      if (sceneName !== 'ErrorScene_Innovamat') {
        addAppletToBlacklist(
          {
            pack: packOrVariation,
            sceneName,
            error: JSON.stringify(error),
            stage,
            version,
            connection: JSON.stringify({ effectiveType, downlink, rtt }),
            userAgent,
            platform,
          },
          axiosInstance,
          apolloServer
        );
      }
    }
  };

  const actionsWhenWarning = (error: any): void => {
    const { packOrVariation, sceneName, stage, errorMessage } = error;

    addAppletToWarnings(
      {
        pack: packOrVariation,
        sceneName,
        error: errorMessage,
        stage,
      },
      axiosInstance,
      apolloServer
    );
  };
}
