import styled from '@emotion/styled';
import { ElectrifiedCat } from '@innovamat/glimmer-assets';
import { Typography } from '@innovamat/glimmer-components';
import { Trans, useTranslation } from 'react-i18next';

export const ERROR_TYPE = {
  NOT_FOUND: 'NOT_FOUND',
  NOT_AVAILABLE: 'NOT_AVAILABLE',
  ERROR_500: 'ERROR_500',
};

type ErrorType = (typeof ERROR_TYPE)[keyof typeof ERROR_TYPE];

type Props = {
  errorType: ErrorType;
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 106px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 48px;
  max-width: 824px;
  width: 80%;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    flex-direction: column-reverse;
    gap: 32px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 8px;
  flex: 1;

  .mail {
    color: ${({ theme }) => theme.tokens.color.alias.cm.text.text.value};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    align-items: center;
    text-align: center;
  }
`;

export const HELP_MAIL = 'help@innovamat.com';

export function ErrorPage({ errorType }: Props): JSX.Element {
  const { t } = useTranslation();

  const getErrorMessage = () => {
    switch (errorType) {
      case ERROR_TYPE.NOT_FOUND:
        return {
          title: t('error-page.not-found'),
          description: t('error-page.not-found-description'),
        };
      case ERROR_TYPE.NOT_AVAILABLE:
        return {
          title: t('error-page.not-available'),
          description: (
            <Trans
              i18nKey="error-page.not-available-description"
              components={{
                a: <a className="mail" href={`mailto: ${HELP_MAIL}`} />,
              }}
            />
          ),
        };
      default:
        return {
          title: t('error-page.error-500'),
          description: (
            <Trans
              i18nKey="error-page.error-500-description"
              components={{
                a: <a className="mail" href={`mailto: ${HELP_MAIL}`} />,
                l: <Typography.Link1 href="/" />,
              }}
            />
          ),
        };
    }
  };

  const errorMessage = getErrorMessage();

  return (
    <Wrapper>
      <Container>
        <TextContainer>
          <Typography.H2>{errorMessage.title}</Typography.H2>
          <Typography.Body1>{errorMessage.description}</Typography.Body1>
        </TextContainer>
        <ElectrifiedCat />
      </Container>
    </Wrapper>
  );
}
