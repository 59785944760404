import styled from '@emotion/styled';
import { InnerHtml, Tooltip } from '@innovamat/glimmer-components';
import { useDimensionColors } from '../../hooks/useDimensionColors';
import { useTranslation } from 'react-i18next';
import { Dimensions } from '../../types/Dimension';

type Props = {
  type: 'block' | 'dimension';
  text: string;
  tooltip: string;
  id: number;
};

const StyledSpan = styled.span<{ color: string }>`
  background-color: ${({ color }) => color};
  border-radius: 4px;

  padding: 0 2px;
  margin: 0 -2px;

  cursor: default;

  &:hover {
    filter: saturate(2);
  }

  @media print {
    border-radius: 2px;
    padding: 0 2px;
    margin: 0 -2px;
  }
`;

const StyledInnerHtml = styled(InnerHtml)`
  display: inline;
`;

export function HighlightSpan({ type, text, tooltip, id }: Props): JSX.Element {
  const { getDimensionHighlightedBackground } = useDimensionColors();
  const { t } = useTranslation();

  // dimensions are mapped differently in the new and old version
  const mapDimensions = {
    1: Dimensions.CONNECTIONS,
    2: Dimensions.PROBLEM,
    3: Dimensions.COMMUNICATION,
    4: Dimensions.REASONING,
  };

  return (
    <Tooltip content={t(tooltip)} className="tooltip-span">
      <StyledSpan color={getDimensionHighlightedBackground(mapDimensions[id])}>
        <StyledInnerHtml text={text} />
      </StyledSpan>
    </Tooltip>
  );
}
