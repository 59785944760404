import { getProperHomePerSelectedStage } from '../BuildWrapper/utils';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-domv6';
import authActions from '../../store/auth/actions';
import { AppState } from '../../store/root/root.interfaces';
import { actionUpdateUnityData } from '../../store/unityData/unityData.actions';

import {
  Wrapper,
  SelectorArea,
  Selector,
  StyledButton,
} from './ErrorPage.styled';

function ErrorPage(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    unityData: { selectedStage, Account, Students },
    token,
  } = useSelector(({ uData, auth }: AppState) => ({
    unityData: uData,
    token: auth.authValues,
  }));

  const isConnectionError = !Account?.id && !Students;

  const handleGoHome = (): void => {
    const home = getProperHomePerSelectedStage(selectedStage);
    dispatch(
      actionUpdateUnityData({
        sceneName: home,
        SceneName: home,
        bridgeIsReady: false,
      })
    );
    navigate('/home');
  };

  const handleConnectionError = (): void => {
    if (token) dispatch(authActions.setUserRequest(token));
    navigate('/');
  };

  const handleGoBack = (): void => {
    if (isConnectionError) {
      handleConnectionError();
    } else handleGoHome();
  };

  return (
    <Wrapper>
      <SelectorArea>
        <Selector>
          {isConnectionError
            ? t('error.connection', 'Oops, you lost the connection!')
            : t('Ooops.something.wrong', 'Oops, something went wrong')}
        </Selector>
        <StyledButton onClick={() => handleGoBack()}>
          {t('Login.Tornar')}
        </StyledButton>
      </SelectorArea>
    </Wrapper>
  );
}

export default ErrorPage;
