'use client';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import { useEventLogging } from '@innovamat/event-logging';

import { ToolCard, Typography } from '@innovamat/glimmer-components';
import { Container } from '../parent-video';
import { IconType } from '@innovamat/glimmer-icons';
import { stripHTMLTags } from '@innovamat/radiance-utils';
import { SectionEmptyState } from '../section-empty-state';

export const ToolsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    grid-template-columns: 1fr;
  }
`;

type Props = {
  shortcuts?: {
    title?: string;
    description?: string;
    link?: string;
    icon?: IconType;
  }[];
};

export function ParentShortcuts({ shortcuts }: Props): JSX.Element {
  const { t } = useTranslation();
  const { setEventData } = useEventLogging();

  const openInNewTab = (url: string): void => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const handleShortcutClick = (link: string) => {
    setEventData('parent_launcher_shortcut', { link });
    openInNewTab(link);
  };

  return (
    <Container>
      <Typography.H3>{t('parent-launcher.shortcuts.title')}</Typography.H3>

      {shortcuts && shortcuts.length > 0 ? (
        <ToolsContainer>
          {shortcuts.map((shortcut, index) => (
            <ToolCard
              key={index}
              icon={shortcut.icon || 'FileStandardIcon'}
              title={shortcut.title || ''}
              description={stripHTMLTags(shortcut.description) || ''}
              color="teal"
              onClick={() => handleShortcutClick(shortcut.link || '')}
              rightIcon="OpenNewTabIcon"
            />
          ))}
        </ToolsContainer>
      ) : (
        <SectionEmptyState />
      )}
    </Container>
  );
}
