import styled from '@emotion/styled';
import { InnerHtml, Typography } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 16px;
  align-self: stretch;
  width: 100%;
  justify-content: space-between;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 640px;
  padding-right: 16px;
`;

const Description = styled(Typography.Body1)`
  color: ${(props) =>
    props.theme.tokens.color.alias.cm.text['text-subtle'].value};
`;
const ShowMoreLink = styled(Typography.Subtitle2)`
  margin-right: 72px;
  color: ${(props) => props.theme.tokens.color.specific.element.link.value};
  cursor: pointer;

  :hover {
    color: ${(props) =>
      props.theme.tokens.color.specific.element['link-hovered'].value};
  }
`;

type Props = {
  name: string | undefined;
  description: string | undefined;
  onShowMoreClick?: () => void;
};

export function ListTitle({
  name,
  description,
  onShowMoreClick,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <Container>
      <TitleContainer>
        <Typography.H4>
          <InnerHtml text={name} />
        </Typography.H4>
        <Description>
          <InnerHtml text={description} />
        </Description>
      </TitleContainer>
      {onShowMoreClick && (
        <ShowMoreLink onClick={onShowMoreClick} role="button">
          {t('contentList.title.showAll')}
        </ShowMoreLink>
      )}
    </Container>
  );
}
