import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import rootReducer from './root/root.reducer';
import rootSaga from './root/root.saga';
import { AppState } from './root/root.interfaces';
import { initialErrorsState } from './errors/errors.interfaces';
import { initialLoadingState } from './loading/loading.interfaces';
import history from './history';
import { inititalAuthState } from './auth/reducer';
import { initialUnityDataState } from './unityData/unityData.interfaces';

const initialState: AppState = {
  errors: initialErrorsState,
  loading: initialLoadingState,
  auth: inititalAuthState,
  uData: initialUnityDataState(),
};

export default function configureStore(): any {
  const sagaMiddleware = createSagaMiddleware();
  const composeEnhancers =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware))
  );
  sagaMiddleware.run(rootSaga);
  return store;
}

export const store = configureStore();
