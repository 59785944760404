import { useWebviewBridge } from '@innovamat/webview-bridge';
import { InnerHtmlParser } from '@innovamat/smart-guides';
import { FullScreenIcon, HeaderTitleWrapper } from './header.styled';
import {
  Tooltip,
  Typography,
  useCheckOverflow,
} from '@innovamat/glimmer-components';
import { useCallback, useState } from 'react';
import styled from '@emotion/styled';

const StyledTooltip = styled(Tooltip)`
  overflow: hidden;
  display: block;
`;

type HeaderTitleProps = {
  isSticky: boolean;
  titleText: string | undefined;
  showFSButton: boolean;
  onFullScreenButtonClick?: () => void;
  dataInlinemanual?: string;
};

const HeaderTitle = ({
  isSticky,
  titleText,
  showFSButton,
  onFullScreenButtonClick,
  dataInlinemanual,
}: HeaderTitleProps): JSX.Element => {
  const { isInWebview } = useWebviewBridge();
  const showFSButtonConsideringApp = showFSButton && !isInWebview;

  const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);

  const onSetContainerRef = useCallback((ref: HTMLElement | null) => {
    setContainerRef(ref);
  }, []);

  const text = containerRef?.querySelector('h3')?.querySelector('div');
  const container = containerRef?.querySelector('h3');

  const { hasOverflow } = useCheckOverflow({
    text,
    container,
    dependencies: [onSetContainerRef],
  });

  return (
    <StyledTooltip
      content={hasOverflow ? titleText : undefined}
      popperOptions={{ strategy: 'fixed' }}
    >
      <HeaderTitleWrapper ref={onSetContainerRef} isSticky={isSticky}>
        <Typography.H3>
          <InnerHtmlParser
            text={{
              body: titleText || '',
              id: 'titleText',
            }}
          />
        </Typography.H3>

        {showFSButtonConsideringApp && onFullScreenButtonClick && (
          <FullScreenIcon
            icon="Expand"
            onClick={() => onFullScreenButtonClick()}
            data-inlinemanual={dataInlinemanual}
            data-testid="fullscreenResource"
          />
        )}
      </HeaderTitleWrapper>
    </StyledTooltip>
  );
};

export default HeaderTitle;
