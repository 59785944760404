import { Modal } from '@innovamat/innova-components';
import styled from 'styled-components';

export const ModalContent = styled(Modal)`
  border-radius: 20px !important;
  color: ${({ theme }) => theme.colors.greenDark};
  font-family: ${({ theme }) => theme.fonts.bariolBold};
  padding: 24px 96px !important;
  text-align: center;

  // Override Modal component styles
  div[class*='footer'] {
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 10px;

    button {
      border-radius: 40px;
      box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2);
      margin: 0 10px;
      padding: 12px 50px;

      span {
        font-family: ${({ theme }) => theme.fonts.bariolBold};
        font-size: ${({ theme }) => theme.sizes.medium};
      }
    }

    button[class*='secondary'] {
      border: none;
    }

    button[class*='primary'] {
      background-color: ${({ theme }) => theme.colors.brandLight};

      &:disabled {
        background-color: ${({ theme }) => theme.colors.mintGray};
      }
    }
  }
`;

export const Title = styled.div`
  font-size: ${({ theme }) => theme.sizes.large};
  padding: 20px 0;
`;

export const Subtitle = styled.div`
  font-size: ${({ theme }) => theme.sizes.caption};
  padding: 0 0 30px;
`;
