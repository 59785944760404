
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as LanguageIconComponent } from "./language-icon.svg";

export const LanguageIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <LanguageIconComponent {...props} />;
};
