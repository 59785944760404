
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as SchoolIconComponent } from "./school-icon.svg";

export const SchoolIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <SchoolIconComponent {...props} />;
};
