import { Icons } from '@innovamat/innova-components';
import { Material } from './materials';
import { MomentContent } from './momentContent';
import { BooleanElement, Item, PrepElement } from './prepr';
import { Standard } from './standard';
import { VideoChapterContent } from './videoChapter';

export type Moments = Item<Moment[]>;

export type Moment = {
  id: string;
  key_moment: BooleanElement;
  moment: PrepElement;
  title: PrepElement;
  video_chapter_content?: VideoChapterContent;
  summary?: PrepElement;
  moment_content?: MomentContent;
  material?: Material;
  standard?: Standard;
  recap?: PrepElement;
};

export const MomentTypes = {
  Takeoff: '1',
  Flight: '2',
  Landing: '3',
  Video: '4',
};

export const MOMENT_CONTENT_TYPES_TEXT = {
  '1': 'RegisterTip',
  '2': 'ClassManagementTip',
  '3': 'AssessmentTip',
  '4': 'AmpliationTip',
  '5': 'SupportTip',
  '6': 'SolutionsTip',
  '7': 'IndividualTask',
  '8': 'CoupleTask',
  '9': 'SmallTeamTast',
  '10': 'BigGroupTask',
  '11': 'PoolingTask',
  '12': 'IndividualRegistering',
  '13': 'CoupleRegistering',
  '14': 'SmallGroupRegistering',
  '15': 'BigGroupRegistering',
  '16': 'ShiftingAgrupation',
  '17': 'Observation',
};

export const MOMENT_CONTENT_TYPES_ICONS: Record<string, keyof typeof Icons> = {
  '1': 'Pencil',
  '2': 'Touch',
  '3': 'Evaluate',
  '4': 'Extension',
  '5': 'Suport',
  '6': 'Solutions',
  '7': 'User',
  '8': 'UserPairs',
  '9': 'Littlegroup',
  '10': 'HugeGroup',
  '11': 'Chat',
  '12': 'UserRegister',
  '13': 'UserPairsRegister',
  '14': 'LittleGroupRegister',
  '15': 'RegisterHugeGroup',
  '16': 'Evaluate',
  '17': 'ChangingAgrupation',
};
