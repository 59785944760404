import styled from '@emotion/styled';

import { Icon } from '@innovamat/glimmer-components';
import Image from './Image';
import useEarlyYearGuide from '../templates/EarlyYear/context/useEarlyYearGuide';
import { withBreakpoint } from '../utils/withBreakpoint';

import { ImageModal } from './ImagesModal/ImagesModal';
import { PreprPlayer } from '@innovamat/prepr.io';
import { theme } from '@innovamat/glimmer-components';
import { css } from '@emotion/react';

type AssetBlockProps = {
  onClickAsset?: (image: ImageModal | null) => void;
  isClassroomLife?: boolean;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: sticky;
  top: calc(var(--heightHeader) + 32px);
  right: 0;
  z-index: 1;
`;

const ImgWrapper = styled.div`
  position: relative;
`;

const CoverDark = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 2;
  background-color: transparent;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.1s ease;
  svg {
    transition: opacity 0.1s ease;
    opacity: 0;
  }
  &:hover {
    background-color: #00000016;
    svg {
      opacity: 1;
    }
  }
`;

const BlockImage = styled(Image)`
  position: relative;
  width: 100%;
  object-fit: cover;
`;

const Footer = styled.div<{ color?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ color }) => color || theme.colors.dark['02']};
  justify-content: space-between;
  font-size: ${theme.sizes.small};
  user-select: none;
  span {
    ${() =>
      withBreakpoint(
        css`
          font-size: ${theme.sizes.xsmall};
        `
      )}
  }
  span > span {
    font-family: ${theme.typography.medium};
  }
`;

const Arrows = styled.div<{ stroke?: string }>`
  display: flex;
  gap: 8px;

  svg path {
    stroke: none;
  }
`;

const Arrow = styled.div<{ isDisabled: boolean }>`
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'all')};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.3 : 1)};
`;

const IconWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
  color: ${theme.colors.white};
  svg {
    display: none;
  }
  &:hover {
    svg {
      display: block;
    }
  }
  svg path {
    fill: ${theme.colors.white};
  }
`;

export default function AssetBlock({
  onClickAsset,
  isClassroomLife,
}: AssetBlockProps) {
  const {
    currentChallenge,
    currentAsset,
    isFirstImage,
    isLastImage,
    onPrevImage,
    onNextImage,
    genericAssetThubmnailTime,
    t,
  } = useEarlyYearGuide();
  const challengeNumber = currentChallenge?.challengeNumber || 0;

  const handleClick = () => {
    if (image && imageId) {
      onClickAsset?.({ url: image, title: imageName || '', id: imageId });
    }
  };

  const footerKey = isClassroomLife
    ? 'digitalguides.ey.classroom_life.activity.list'
    : 'digitalguides.ey.challenge';

  const renderFooter = () => {
    return (
      currentChallenge && (
        <Footer>
          <span>
            <span>
              {t(footerKey, {
                num: challengeNumber + 1,
              })}
            </span>
            {' - '}
            {currentChallenge.title.body}
          </span>
          {!(isFirstImage && isLastImage) && (
            <Arrows>
              <Arrow isDisabled={isFirstImage} onClick={onPrevImage}>
                <Icon icon="PreviousWithTextIcon" />
              </Arrow>
              <Arrow isDisabled={isLastImage} onClick={onNextImage}>
                <Icon icon="NextWithTextIcon" />
              </Arrow>
            </Arrows>
          )}
        </Footer>
      )
    );
  };

  if (!currentAsset) return null;

  if (currentAsset.label === 'Video') {
    return (
      <Wrapper>
        <PreprPlayer
          metadata={{
            videoId: currentAsset.reference_id,
            videoTitle: currentAsset.name,
          }}
          playbackId={currentAsset.playback_id}
          thumbnailTime={genericAssetThubmnailTime}
        />
        {renderFooter()}
      </Wrapper>
    );
  }

  const image = currentAsset?.cdn_files?.items[0].url;
  const imageName = currentAsset?.caption;
  const imageId = currentAsset?.cdn_files?.items[0].id;

  return (
    <Wrapper>
      <ImgWrapper>
        <CoverDark onClick={handleClick}>
          <IconWrapper>
            <Icon icon="ZoomInIcon" size="XL" />
          </IconWrapper>
        </CoverDark>

        <BlockImage src={image} alt="" />
      </ImgWrapper>
      {renderFooter()}
    </Wrapper>
  );
}
