import { useEffect, useState } from 'react';
import { Notification } from '@innovamat/innova-components';
import { t } from 'i18next';
import styled from 'styled-components';
import { createPortal } from 'react-dom';

const Container = styled.div`
  background: rgb(0 0 0 / 60%);
  height: 100%;
  left: 0;
  padding: 30px;
  position: absolute;
  top: 0;
  width: 100%;
`;

function OfflineMessage(): JSX.Element | null {
  const [isOffLine, setStatus] = useState(false);

  let offlineTime: number | null = null;
  const reactionTimeThreshold = 10000;

  function addOfflineListener(): void {
    setStatus(true);
    offlineTime = new Date().getTime();
  }

  function addOnlineListener(): void {
    setStatus(false);
    if (!offlineTime) return;
    const currentTime = new Date().getTime();
    const reactionTime = currentTime - offlineTime;
    if (reactionTime > reactionTimeThreshold) window.location.reload();
  }

  function disableListeners(): void {
    window.removeEventListener('offline', addOfflineListener);
    window.removeEventListener('online', addOnlineListener);
  }

  useEffect(() => {
    window.addEventListener('offline', addOfflineListener);
    window.addEventListener('online', addOnlineListener);

    return () => disableListeners();
  }, []);

  const notificationRoot = document.getElementById(
    'notification'
  ) as HTMLElement;

  if (isOffLine) {
    return createPortal(
      <Container>
        <Notification type="error" canClose={false}>
          {t('Login.messageError.login.isNetworkError')}
        </Notification>
      </Container>,
      notificationRoot
    );
  }
  return null;
}

export default OfflineMessage;
