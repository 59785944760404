import { Modal } from '@innovamat/innova-components';
import styled from 'styled-components';

import infantilImage from '../../assets/images/infantil_back_button.png';
import primariaImage from '../../assets/images/primaria_back_button.png';

export const Wrapper = styled.div`
  background: linear-gradient(
    180deg,
    rgba(210, 255, 114, 0.7) -83.33%,
    rgba(24, 198, 172, 0.7) 47.99%
  );
  display: flex;
  flex-direction: column;
  height: ${({ theme }) => theme.appHeight};
`;

export const BackButtonWrapper = styled.div`
  padding: 30px;
`;

export const StyledButton = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  border-radius: 30px;
  box-shadow: 0 7px 15px rgba(0, 0, 0, 0.15);
  color: ${({ theme }) => theme.colors.mintGray};
  display: flex;
  font-family: ${({ theme }) => theme.fonts.bariolBold};
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  padding: 12px 24px;
  text-align: center;
  text-transform: uppercase;

  svg {
    fill: ${({ theme }) => theme.colors.mintGray};
    margin-right: 20px;
    transform: rotate(180deg);
  }
`;

export const SelectorArea = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: 50px;
  justify-content: center;
  padding: 35px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 6.3vw;
    line-height: 6vw;
  }
  @media (max-width: 420px) {
    flex-direction: column;
  }
  @media (max-height: 770px) {
    gap: 50px;
    justify-content: center;
    padding: 0;
  }
`;

export const Selector = styled.div`
  aspect-ratio: 9/10;
  background: ${({ theme }) => theme.colors.white};
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 30px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
  color: ${({ theme }) => theme.colors.brandDark};
  font-family: ${({ theme }) => theme.fonts.bariolBold};
  font-size: 3.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 66px;
  max-height: 100%;
  max-width: 100%;
  padding: 25px;
  text-align: center;
  text-transform: uppercase;
  width: 480px;

  &:first-child {
    background-image: url(${infantilImage});
  }

  &:last-child {
    background-image: url(${primariaImage});
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 6.3vw;
    line-height: 6vw;
  }
  @media (max-height: 770px) {
    height: 55vh;
    width: 380px;
    font-size: 2.75rem;
    line-height: 45px;
  }
`;

export const Footer = styled.div`
  color: ${({ theme }) => theme.colors.pearlWhite};
  font-family: ${({ theme }) => theme.fonts.bariolBold};
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  padding: 32px;
  text-align: center;
  text-transform: uppercase;
`;

export const ModalContent = styled(Modal)`
  border-radius: 20px !important;
  color: ${({ theme }) => theme.colors.greenDark};
  font-family: ${({ theme }) => theme.fonts.bariolBold};
  padding: 24px 96px !important;
  text-align: center;

  // Override Modal component styles
  div[class*='footer'] {
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 10px;

    button {
      border-radius: 40px;
      box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2);
      margin: 0 10px;
      padding: 12px 50px;

      span {
        font-family: ${({ theme }) => theme.fonts.bariolBold};
        font-size: ${({ theme }) => theme.sizes.medium};
      }
    }

    button[class*='secondary'] {
      border: none;
    }

    button[class*='primary'] {
      background-color: ${({ theme }) => theme.colors.brandLight};

      &:disabled {
        background-color: ${({ theme }) => theme.colors.mintGray};
      }
    }
  }
`;

export const Title = styled.div`
  font-size: ${({ theme }) => theme.sizes.large};
  padding: 20px 0;
`;

export const Subtitle = styled.div`
  font-size: ${({ theme }) => theme.sizes.caption};
  padding: 0 0 30px;
`;

export const ForgotPassword = styled.div`
  margin-top: 20px;
  text-align: right;
`;
