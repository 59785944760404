import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDigitalResources = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M14.806 13.565h3.173a1.686 1.686 0 0 0 1.687-1.69V4.69A1.69 1.69 0 0 0 17.979 3h-7.17A1.686 1.686 0 0 0 9.12 4.69v1.403"
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M13 11V6l4 2.5zM2.016 7.728l.67-.2c1.113-.333 2.383.637 2.715 1.75l.1.337M3.082 12.535l.4 1.345M2.611 13.408l1.343-.401M8.65 11.602a.175.175 0 0 1 .218.117"
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M8.534 11.82a.175.175 0 0 1 .118-.218M8.75 11.938a.175.175 0 0 1-.217-.118M8.869 11.72a.175.175 0 0 1-.118.218M9.357 10.293a.175.175 0 0 1 .218.117"
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M9.24 10.51a.175.175 0 0 1 .117-.217M9.458 10.628a.175.175 0 0 1-.218-.117M9.576 10.41a.175.175 0 0 1-.118.218"
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="m11.636 11.808-.501-1.681a2.104 2.104 0 0 0-2.614-1.415l-6.04 1.806a2.1 2.1 0 0 0-1.413 2.62l1.151 3.864a1.56 1.56 0 0 0 1.5 1.15A1.49 1.49 0 0 0 5.15 16.86l.441-2.147 2.624-.785 1.497 1.501a1.56 1.56 0 0 0 1.34.556 1.49 1.49 0 0 0 1.305-1.268 1.5 1.5 0 0 0-.046-.645z"
    />
  </svg>
);
export default SvgDigitalResources;
