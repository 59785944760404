import * as React from 'react';
import type { SVGProps } from 'react';
const SvgArrowLeft3 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path d="M15 8.25H5.872l4.193-4.192L9 3 3 9l6 6 1.057-1.057L5.873 9.75H15z" />
  </svg>
);
export default SvgArrowLeft3;
