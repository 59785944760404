import styled from '@emotion/styled';
import { Tooltip } from '@innovamat/glimmer-components';
import { Dimension } from '../../../types/Dimension';
import { useDimensionColors } from '../../../hooks/useDimensionColors';
import { useTranslation } from 'react-i18next';

const StyledSpan = styled.span<{ color: string }>`
  background-color: ${({ color }) => color};
  border-radius: 4px;

  padding: 0 2px;
  margin: 0 -2px;

  &:hover {
    filter: saturate(2);
  }

  @media print {
    border-radius: 2px;
    padding: 0 2px;
    margin: 0 -2px;
  }
`;

type Props = {
  label: string;
  dimension: Dimension;
};

const dimensionsKeys = {
  1: 'reasoning_and_proof',
  2: 'communication_and_representation',
  3: 'problem_solving',
  4: 'connections',
};

export function DimensionSpan({ label, dimension }: Props): JSX.Element {
  const { t } = useTranslation();
  const { getDimensionHighlightedBackground } = useDimensionColors();

  return (
    <Tooltip
      content={t(`digitalguides.dimensions.${dimensionsKeys[dimension!]}`)}
      className="tooltip-span"
    >
      <StyledSpan color={getDimensionHighlightedBackground(dimension)}>
        {label}
      </StyledSpan>
    </Tooltip>
  );
}
