/* eslint-disable */
import { useLayoutEffect, useState } from 'react';
import circleAnimation from '../../assets/animations/circle.json';
import tickAnimation from '../../assets/animations/tick.json';
import Star from '../star';
import soundGreenCheckmark from '../../assets/sounds/fx_feedback_greenCheckmark.mp3';
import soundActivityPulse from '../../assets/sounds/fx_feedback_activityPulse.mp3';
import soundStarReward1 from '../../assets/sounds/fx_feedback_starReward01.mp3';
import soundStarReward2 from '../../assets/sounds/fx_feedback_starReward02.mp3';
import soundStarReward3 from '../../assets/sounds/fx_feedback_starReward03.mp3';
import { LottieAnimation, Modal } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';
import { Applet } from '@innovamat/glow-api-client';
import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';

type Props = {
  backgroundImg: string;
  activities?: any[] | null; // TODO should be Applet[]
  nextActivityIndex: number;
  currentActivityIndex?: number;
  showException: boolean;
  onStartNextApplet: () => void;
};

const ProgressScreen = (props: Props) => {
  const {
    backgroundImg,
    activities,
    nextActivityIndex,
    currentActivityIndex,
    showException,
    onStartNextApplet,
  } = props;

  const [triggerTickAnimation, setTriggerTickAnimation] = useState(false);
  const [triggerStarAparition, setTriggerStarAparition] = useState(false);
  const [triggerFirstStar, setTriggerFirstStar] = useState(false);
  const [triggerSecondStar, setTriggerSecondStar] = useState(false);
  const [triggerThirdStar, setTriggerThirdStar] = useState(false);
  const [triggerCircleAnimation, setTriggerCircleAnimation] = useState(false);

  const { t } = useTranslation();

  const animationRewards = () => {
    setTimeout(() => {
      setTriggerTickAnimation(true);
      playAudio(soundGreenCheckmark);
    }, 2000);

    setTimeout(() => {
      setTriggerStarAparition(true);
    }, 2500);

    setTimeout(() => {
      setTriggerFirstStar(true);
    }, 3000);

    setTimeout(() => {
      setTriggerSecondStar(true);
    }, 4000);

    setTimeout(() => {
      setTriggerThirdStar(true);
    }, 5000);

    setTimeout(() => {
      setTriggerCircleAnimation(true);
      playAudio(soundActivityPulse);
    }, 6500);
  };

  useLayoutEffect(() => {
    animationRewards();
  }, []);

  const playAudio = (source: string) => {
    const audio = new Audio(source);
    try {
      audio?.play();
    } catch (e) {
      console.log('Error playing audio', e);
    }
  };

  const getColumns = () => {
    if (!activities) return 0;
    if (activities.length <= 6) return activities.length;
    if (activities.length <= 10) return 5;
    if (activities.length <= 12) return 6;
    return 5;
  };

  const isLastActivity = (index?: number | null) => {
    if (index === null || index === undefined) return false;
    return index === currentActivityIndex;
  };

  const startCircleAnimation = (activity: Applet) => {
    const animation =
      nextActivityIndex === activity.index && triggerCircleAnimation;
    return (
      <>
        {animation && (
          <LottieAnimation
            autoplay={triggerCircleAnimation}
            loop
            animationData={circleAnimation}
          />
        )}
      </>
    );
  };

  const renderCircle = (activity: Applet) => {
    const noCompletedActivity =
      !activity.completed && nextActivityIndex !== activity.index;
    const nextActivity =
      nextActivityIndex === activity.index && !triggerCircleAnimation;
    return (
      <>
        {(noCompletedActivity || nextActivity) && (
          <Circle id="Circle" isCompleted={false} />
        )}
      </>
    );
  };

  const startTickAnimation = (activity: Applet) => {
    if (
      activity.index === null ||
      (activity.index === undefined && activity.index !== 0)
    )
      return;
    const playAnimation =
      isLastActivity(activity.index) && triggerTickAnimation;
    const goToAnimation = !isLastActivity(activity?.index) ? 49 : undefined;
    return (
      <>
        {activity.completed && (
          <LottieAnimation
            animationData={tickAnimation}
            goTo={goToAnimation}
            autoplay={playAnimation}
            loop={false}
          />
        )}
      </>
    );
  };

  const renderStars = (activity: Applet) => {
    const stars = [1, 2, 3];

    const successPulse = (star: number) => {
      if (
        !activity.stars ||
        activity.index === null ||
        activity.index === undefined
      )
        return false;
      const activateStartPulse =
        isLastActivity(activity.index) &&
        triggerStar(star) &&
        activity?.stars >= star;
      return activateStartPulse;
    };

    const successStar = (star: number): boolean => {
      if (
        activity.index === null ||
        activity.index === undefined ||
        !activity.stars
      ) {
        return false;
      }
      const activateTriggerStar =
        isLastActivity(activity.index) && triggerStar(star);
      const activityCompleted =
        !isLastActivity(activity.index) && activity.completed;
      return (
        !!(activateTriggerStar || activityCompleted) && activity.stars >= star
      );
    };

    return (
      <>
        {stars.map((star) => (
          <Pulse success={successPulse(star)}>
            <Star
              size={'100%'}
              success={successStar(star)}
              audio={triggerAudioStar(star, isLastActivity(activity?.index))}
            />
          </Pulse>
        ))}
      </>
    );
  };

  const triggerStar = (index: number) => {
    if (index === 1) return triggerFirstStar;
    if (index === 2) return triggerSecondStar;
    return triggerThirdStar;
  };

  const triggerAudioStar = (index: number, triggerAudio: boolean) => {
    if (!triggerAudio) return undefined;
    if (index === 1) return soundStarReward1;
    if (index === 2) return soundStarReward2;
    return soundStarReward3;
  };

  return (
    <Background id="Background" img={backgroundImg}>
      {showException ? (
        <Modal
          zIndex={1002}
          isOpen={showException}
          semantic={{ type: 'warning', text: t('common.modal.important') }}
          title={t('weeklyPractice.activityErrorPopup.title')}
          onClose={() => null}
          buttons={[
            {
              children: t('weeklyPractice.activityErrorPopup.button'),
              variant: 'accent',
              onClick: onStartNextApplet,
            },
          ]}
        >
          <>{t('weeklyPractice.activityErrorPopup.body')}</>
        </Modal>
      ) : (
        <Container id="Container" columns={getColumns()}>
          {activities?.map((activity, index) => (
            <Activity key={index}>
              {startCircleAnimation(activity)}
              {startTickAnimation(activity)}
              {renderCircle(activity)}
              {activity.completed && (
                <StarsContainer
                  showStars={!isLastActivity(activity.index)}
                  triggerStarAparition={
                    isLastActivity(activity.index) && triggerStarAparition
                  }
                >
                  {renderStars(activity)}
                </StarsContainer>
              )}
            </Activity>
          ))}
        </Container>
      )}
    </Background>
  );
};

export default ProgressScreen;

const Background = styled.div<{ img: string }>`
  height: 100vh;
  padding: 10% 20%;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('${props.img}') no-repeat center center fixed`};
  background-size: cover;
`;

const Activity = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1 / 1;
  position: relative;
`;

const StarsContainer = styled.div<{
  showStars: boolean;
  triggerStarAparition: boolean;
}>`
  display: flex;
  position: absolute;
  bottom: 18%;
  gap: 8px;
  width: 60%;
  opacity: ${(props) => (props.showStars ? 1 : 0)};
  ${(props) =>
    props.triggerStarAparition &&
    css`
      opacity: 1;
    `}
  transition: opacity 1s ease-in;
  > div {
    &:nth-child(2n) {
      margin-top: 24px;
    }
  }
`;

const Container = styled.div<{ columns: number }>`
  width: 100%;
  max-width: 846px;
  min-width: 700px;
  display: grid;
  grid-template-columns: ${(props) => `repeat(${props.columns}, 1fr)`};
`;

const Circle = styled.div<{
  isCompleted: boolean;
}>`
  align-items: center;
  border: none;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  max-width: 40px;
  max-height: 40px;
  width: 100%;
  height: 100%;

  svg {
    path {
      fill: ${(props) => props.theme.colors.brand.dark['02']};
      stroke: ${(props) => props.theme.colors.brand.dark['02']};
    }
  }

  background: ${(props) =>
    props.isCompleted ? 'white' : 'rgba(255, 255, 255, 0.5)'};
`;

const pulse = keyframes`
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(1.8) rotate(180deg);
  }
  100% {
    transform: scale(1) rotate(360deg);
  }
`;

const Pulse = styled.div<{ success: boolean }>`
  ${(props) =>
    props.success &&
    css`
      animation: ${pulse} 1s linear;
    `}
`;
