import * as React from 'react';
import type { SVGProps } from 'react';
const SvgContentBase = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g fill="#757575" clipPath="url(#content_base_svg__a)">
      <path d="M19.259 1.514a2.14 2.14 0 0 0-1.715-.498c-1.961.3-3.83 1.036-5.468 2.157a1.45 1.45 0 0 0-.647 1.204v13.909a.714.714 0 0 0 1.122.595 11.85 11.85 0 0 1 5.572-2.207A2.13 2.13 0 0 0 20 14.541V3.143a2.14 2.14 0 0 0-.741-1.629M8.571 4.378a1.45 1.45 0 0 0-.65-1.204 13.2 13.2 0 0 0-5.47-2.157A2.143 2.143 0 0 0 0 3.144v11.404a2.127 2.127 0 0 0 1.874 2.127 11.85 11.85 0 0 1 5.572 2.208.714.714 0 0 0 1.125-.596z" />
    </g>
    <defs>
      <clipPath id="content_base_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgContentBase;
