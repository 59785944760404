import { IconType } from '@innovamat/glimmer-icons';
import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';

import { Tooltip } from '../Tooltip';
import { SegmentedItems } from './segmented-items';

type SegmentedButtonsProps = {
  options: Array<{
    icons: IconType[];
    onClick: () => void;
    tooltipText: string;
    disabled: boolean;
    selected: boolean;
  }>;
};

const SegmentedButtonsContainer = styled.div`
  user-select: none;
  border-radius: 4px;
  padding: 2px;
  background-color: ${({ theme }) =>
    theme.tokens.color.specific.reports.tables['background-highlight'].value};
  width: 180px;
  height: 40px;
`;

const ActiveBackground = styled.div<{
  width: number;
  left: number;
  isActive: boolean;
  disabled: boolean;
}>`
  position: absolute;
  height: 36px;
  width: ${({ width }) => width}px;
  left: ${({ left }) => left}px;
  border-radius: 4px;
  outline: ${({ theme, disabled }) =>
    disabled
      ? 'none'
      : `1px solid ${theme.tokens.color.alias.cm.border['border-subtle'].value}`};
  outline-offset: -1px;
  transition: ${({ isActive }) =>
    isActive ? 'all cubic-bezier(0.5, 0, 0, 1.25) 0.3s' : 'none'};
  background-color: ${({ theme, disabled }) =>
    disabled
      ? `${theme.tokens.color.alias.cm.bg['bg-disabled'].value}`
      : `${theme.tokens.color.global.white.value}`};
  z-index: 2;
`;

export function SegmentedButtons({
  options,
}: SegmentedButtonsProps): JSX.Element {
  const initialActiveOption = options.findIndex((option) => option.selected);
  const [activeOption, setActiveOption] = useState(
    initialActiveOption >= 0 ? initialActiveOption : 0
  );

  const [backgroundSize, setBackgroundSize] = useState({ width: 0, left: 0 });
  const optionRefs = useRef<(HTMLButtonElement | null)[]>([]);

  const handleOptionClick = (optionNumber: number): void => {
    const option = options[optionNumber];
    if (activeOption === optionNumber) return;

    setActiveOption(optionNumber);
    option.onClick();
  };

  useEffect(() => {
    setActiveOption(initialActiveOption);
  }, [initialActiveOption]);

  useEffect(() => {
    const activeOptionRef = optionRefs.current[activeOption];
    if (activeOptionRef) {
      setBackgroundSize({
        width: activeOptionRef.clientWidth,
        left: activeOptionRef.offsetLeft,
      });
    }
  }, [activeOption]);

  return (
    <SegmentedButtonsContainer>
      <ActiveBackground
        width={backgroundSize.width}
        left={backgroundSize.left}
        isActive={!!activeOption}
        disabled={options[activeOption]?.disabled}
      />
      {options.map((option, index) => {
        const { icons, tooltipText, disabled, selected } = option;
        const position =
          index === 0
            ? 'left'
            : index === options.length - 1
            ? 'right'
            : 'middle';
        return (
          <Tooltip
            content={tooltipText}
            popperOptions={{ strategy: 'fixed' }}
            key={index}
          >
            <SegmentedItems
              innerRef={(el) => (optionRefs.current[index] = el)}
              onClick={() => handleOptionClick(index)}
              icons={icons}
              state={
                disabled
                  ? 'disabled'
                  : index === activeOption || selected
                  ? 'selected'
                  : undefined
              }
              position={position}
            />
          </Tooltip>
        );
      })}
    </SegmentedButtonsContainer>
  );
}
