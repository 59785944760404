import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSendEmail = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      clipPath="url(#send-email_svg__a)"
    >
      <path d="M9.374 14.377a5 5 0 1 0 10 0 5 5 0 0 0-10 0M11.874 14.377h5M16.874 14.377 15 16.25M16.874 14.377 15 12.5" />
      <path d="M6.874 13.127h-5a1.25 1.25 0 0 1-1.25-1.25v-10a1.25 1.25 0 0 1 1.25-1.25h15a1.25 1.25 0 0 1 1.25 1.25V7.5" />
      <path d="m17.84 1.084-6.786 5.221a2.756 2.756 0 0 1-3.36 0L.906 1.084" />
    </g>
    <defs>
      <clipPath id="send-email_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSendEmail;
