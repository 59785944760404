
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as InnovamatLogoIconComponent } from "./innovamat-logo-icon.svg";

export const InnovamatLogoIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <InnovamatLogoIconComponent {...props} />;
};
