
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as ClassIconComponent } from "./class-icon.svg";

export const ClassIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <ClassIconComponent {...props} />;
};
