import { breakpointsRem } from './breakpoints';

export const grid = {
  container: {
    xs: 'full',
    sm: 'full',
    md: 'full',
    lg: 'full',
    xl: 120,
  },
  padding: {
    xs: 1,
    sm: 1,
    md: 1,
    lg: 1,
    xl: 1,
  },
  columns: {
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 12,
  },
  gutter: {
    xs: 1,
    sm: 1,
    md: 1,
    lg: 1,
    xl: 1,
  },
  breakpoints: breakpointsRem,
  colors: {
    blue: '#0470F5',
  },
};
