export const replaceOldDimension = (text: string | undefined) => {
  if (!text) return '';

  const regex = new RegExp(/\{\{(.*?)\}\}/, 'gm');

  let content = text;

  const expressions = content.match(regex) ?? [];

  if (!expressions) return content;

  const dimensionIds: Record<string, [string, string]> = {};

  expressions.forEach((expression, index) => {
    const dimensionArr = expression.split('|');

    const dimensionNumber = dimensionArr[dimensionArr.length - 1].replace(
      '}}',
      ''
    );

    dimensionArr.pop();
    const value = dimensionArr.join('').replace('{{', '');

    const newId = `old-moment-${dimensionNumber}-${index}`;
    dimensionIds[newId] = [value, dimensionNumber];

    content = content.replace(
      expression,
      `<dimensioncomponent dimensionid="${dimensionNumber}">${value}</dimensioncomponent>`
    );
  });

  return content;
};
