import { gql } from 'graphql-request';

const GET_BANNERS_DOCUMENT = gql`
  query CM20Banner($where: CM20BannerWhereInput, $locale: String!) {
    CM20Banners(where: $where, locale: $locale) {
      items {
        _id
        colour
        cta_link
        cta_text
        cta
        text
        title
        cta_external
        can_be_closed
        demo_users
        inline_topic_id
        only_for_assessment
        only_for_intervention
        icon {
          _id
          name
          description
          width
          height
          url
        }
        visible_at
        importance
        roles {
          ... on ClassroomManagerRoles {
            roles
          }
        }
      }
    }
  }
`;

export { GET_BANNERS_DOCUMENT };
