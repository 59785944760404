export enum Dimensions {
  PROBLEM = 1,
  REASONING = 2,
  CONNECTIONS = 3,
  COMMUNICATION = 4,
}

export type Dimension =
  | undefined
  | Dimensions.COMMUNICATION
  | Dimensions.CONNECTIONS
  | Dimensions.PROBLEM
  | Dimensions.REASONING;
