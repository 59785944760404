import styled from '@emotion/styled';

import { getIcon } from '../utils/mapIcons';
import { theme } from '@innovamat/glimmer-components';
import { IconType } from '../types/icons';

type Props = {
  children: React.ReactNode;
  color?: string | undefined;
  icon: IconType;
  hideIcon?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const Wrapper = styled.div<{ color: string }>`
  display: flex;
  svg path {
    stroke: ${({ color }) => color};
  }
  > div {
    border-left-color: ${({ color }) => color};
  }

  overflow-x: auto;
  overflow-y: hidden;
`;

const Content = styled.div`
  border-left: 1px solid;
  margin-left: 16px;
  padding-left: 16px;
  flex: 1;

  @media print {
    margin-left: 8px;
    padding-left: 8px;
  }
`;

const IconWrapper = styled.div`
  @media print {
    transform: scale(0.83);
  }
`;

export default function BlockWithIcon({
  children,
  color = theme.colors.dark['02'],
  icon,
  hideIcon = false,
  ...rest
}: Props) {
  const Icon = getIcon(icon);

  return (
    <Wrapper color={color} {...rest}>
      {!hideIcon && (
        <IconWrapper>
          <Icon style={{ marginTop: 8 }} />
        </IconWrapper>
      )}
      <Content>{children}</Content>
    </Wrapper>
  );
}
