import React from 'react';

import { Icons } from '../..';

import Avatar from '../Avatar';

import styles from './AvatarWithInfo.module.scss';

type Props = {
  badge?: keyof typeof Icons;
  border?: 'Info';
  className?: string;
  image?: string;
  onClick?: () => void;
  shadow?: boolean;
  size?: 'l' | 'm' | 's';
  subtitle?: string | null | undefined;
  title: string | null | undefined;
};

const sizes = {
  l: 56,
  m: 40,
  s: 24,
};

function AvatarWithInfo({
  badge,
  border,
  className,
  image,
  onClick,
  title,
  shadow = false,
  size = 'm',
  subtitle,
}: Props) {
  const getContent = () => {
    switch (size) {
      case 'm':
        return (
          <>
            <div className="h5">{title}</div>
            {subtitle && <div className={styles.courseM}>{subtitle}</div>}
          </>
        );
      case 'l':
        return (
          <>
            <div className="h4">{title}</div>
            {subtitle && <div className={styles.courseL}>{subtitle}</div>}
          </>
        );
      default:
        return (
          <>
            <div className="h6">{title}</div>
            {subtitle && <div className={styles.courseS}>{subtitle}</div>}
          </>
        );
    }
  };

  return (
    <div
      className={`${styles.container} ${className}`}
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex={0}
    >
      <Avatar
        alt={`avatar-${title}`}
        badge={badge}
        border={border}
        img={image}
        name={title || ''}
        shadow={shadow}
        size={sizes[size]}
      />
      <div className={styles.infoContainer}>{getContent()}</div>
    </div>
  );
}

export default AvatarWithInfo;
