import styled from '@emotion/styled';
import {
  authAxiosInstance,
  useAppletInfo,
  useEnvVariables,
  useUser,
} from '@innovamat/ga-features';
import { Stage } from '@innovamat/radiance-utils';
import { AppletResource, UnityWebGL } from '@innovamat/resource-viewer';
import { schoolsWithFirewall } from '@innovamat/unity-toolkit-errors';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

const Wrapper = styled.div`
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
`;

export function AppletSelector(): JSX.Element {
  const { user } = useUser();
  // const { classroomId, courseId } = useParams<{
  //   classroomId: string;
  //   courseId: string;
  // }>();

  // const { data: classroomQuery } = useClassroomQuery(
  //   {
  //     id: classroomId!,
  //     orgId: user?.organizationId!,
  //   },
  //   {
  //     enabled: Boolean(classroomId && user?.organizationId),
  //   }
  // );
  // const { getCourseById } = useCourses({
  //   regionCode: user?.region!,
  //   organizationId: user?.organizationId!,
  // });

  // const getCourseOrder = (): number | undefined => {
  //   if (classroomId) {
  //     return classroomQuery?.classroom?.courseOrder as number;
  //   }
  //   return courseId ? (getCourseById(courseId)?.order as number) : undefined;
  // };

  // const courseOrder = getCourseOrder();
  const appletInfo = useAppletInfo();
  const { STANDALONE_BUILD_DOMAIN, APOLLO_SERVER } = useEnvVariables();
  // Overwrite stage to primary / Bugfix GAM-5334
  appletInfo.educationalLevel = Stage.PRIMARIA;

  const BUILD_NOT_FOUND = 'BUILD_NOT_FOUND';

  const getPath = async (schoolId: string): Promise<string> => {
    if (!user) return '';

    const domain = schoolsWithFirewall.includes(schoolId || '')
      ? 'https://d1ecifsh34r90d.cloudfront.net'
      : STANDALONE_BUILD_DOMAIN;

    const buildsUrl = `${domain}/WebApp/BuildRoutes/routes.json`;

    const response = await fetch(buildsUrl);
    const builds = await response.json();
    const routedBuildPath = builds['ActivityManager_GA'];

    if (!routedBuildPath) {
      return `${STANDALONE_BUILD_DOMAIN}/${BUILD_NOT_FOUND}`;
    }

    return `${STANDALONE_BUILD_DOMAIN}/WebApp${routedBuildPath}`;
  };

  const schoolId = user?.organizationId || '';
  const regionParameters = appletInfo.regionParameters;

  const isAllDataAvailable = Boolean(
    user?.id &&
      // courseOrder &&
      regionParameters?.currency &&
      appletInfo.solverUrl &&
      appletInfo.platformUrl
  );

  const [appletData, setAppletData] = useState<any>(undefined);
  const [appletSelectorState, setAppletSelectorState] = useState<{}>();

  const { data: appletSelectorPath } = useQuery({
    queryKey: ['applet-selector-path'],
    queryFn: () => getPath(schoolId),
    enabled: Boolean(user?.id && schoolId && regionParameters?.currency),
  });

  const handleCloseApplet = (): void => {
    setAppletData(undefined);
  };

  const handleEventData = (eventType: string, args: any): void => {
    if (eventType === 'send_data') {
      setAppletData(args);
      setAppletSelectorState(args.PreviousAppletsListStateData);
    }
  };

  if (!isAllDataAvailable || !appletSelectorPath) return <></>;

  if (appletData) {
    return (
      <Wrapper>
        <AppletResource
          sceneName={appletData.sceneName}
          packOrVariation={appletData.packOrVariation}
          language={user?.locale || ''}
          onAppletIsCompleted={handleCloseApplet}
          onCloseApp={handleCloseApplet}
          appletInfo={appletInfo}
          setEventData={handleEventData}
          apolloServer={APOLLO_SERVER}
          axiosInstance={authAxiosInstance}
          school={schoolId}
          uid={user?.id || ''}
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <UnityWebGL
        buildPath={appletSelectorPath}
        setEventData={handleEventData}
        axiosInstance={authAxiosInstance}
        apolloServer={APOLLO_SERVER}
        unityInputs={{
          userId: user?.id,
          regionParameters: regionParameters,
          sceneName: 'ActivityManager_GA',
          Tokens: {
            AccessToken: appletInfo.accessToken,
            RefreshToken: appletInfo.refreshToken,
            IdToken: appletInfo.idToken,
            ClientID: appletInfo.clientID,
          },
          PlatformUrl: appletInfo.platformUrl,
          SolverUrl: appletInfo.solverUrl,
          // Course: classroomQuery?.classroom?.courseOrder,
          Course: undefined,
          PreviousAppletsListStateData: appletSelectorState,
        }}
        language={user?.locale || ''}
        unityStandaloneBuildDomain={STANDALONE_BUILD_DOMAIN}
      />
    </Wrapper>
  );
}
