
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as ExpandAllIconComponent } from "./expand-all-icon.svg";

export const ExpandAllIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <ExpandAllIconComponent {...props} />;
};
