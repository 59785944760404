
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as ZoomOutIconComponent } from "./zoom-out-icon.svg";

export const ZoomOutIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <ZoomOutIconComponent {...props} />;
};
