import styled from '@emotion/styled';
import { EmptyClassroom } from '@innovamat/glimmer-assets';
import { Button, Typography } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';
import { useGoogleImport } from '../../../../google-classroom';
import { useOrganization } from '../../../hooks/use-organization';
import { useFlag } from '@innovamat/flags';

const Container = styled.div`
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;

  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;
`;

const Description = styled(Typography.Body1)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

type EmptyStateProps = {
  isClassroomImportedFromGoogle: boolean;
  isDisabled?: boolean;
  handleAddStudent: () => void;
};

export function ClassroomStudentsEmptyState({
  isClassroomImportedFromGoogle,
  isDisabled = false,
  handleAddStudent,
}: EmptyStateProps): JSX.Element {
  const { t } = useTranslation();
  const { onOpenSyncroModal } = useGoogleImport();
  const { organization } = useOrganization();
  const { value: showGoogleClassroomImport } = useFlag(
    'showgoogleclassroomimport',
    false,
    { identifier: '', custom: { schoolId: organization?.id || '' } }
  );

  return (
    <Container>
      <EmptyClassroom />
      <div>
        <Typography.H4>{t('students.tableEmptyState.title')}</Typography.H4>
        <Description>{t('students.tableEmptyState.description')}</Description>
      </div>
      {isClassroomImportedFromGoogle && !showGoogleClassroomImport ? (
        <></>
      ) : isClassroomImportedFromGoogle && showGoogleClassroomImport ? (
        <Button
          disabled={isDisabled}
          variant="secondary"
          leftIcon="GoogleClassroomIcon"
          onClick={onOpenSyncroModal}
          keepIconColor
        >
          {t('common.syncGoogleClassroom')}
        </Button>
      ) : (
        <Button
          rightIcon="AddUpIcon"
          onClick={handleAddStudent}
          disabled={isDisabled}
        >
          {t('students.button.addStudent')}
        </Button>
      )}
    </Container>
  );
}
