import styled from '@emotion/styled';

import { ActionType } from './MaterialCard.types';

import Card from '../Card';

const CardWrapper = styled('div')`
  height: 221px;
  position: relative;
`;

const CardBody = styled('div')`
  height: 100%;
  width: 100%;
  position: relative;
  padding: 4px;
`;

const CardContent = styled(Card)`
  position: absolute;
`;

const Controls = styled(Card)`
  z-index: -1;
  bottom: 0;
`;

const Image = styled('img')`
  width: 100%;
  height: 117px;
  object-fit: cover;
  object-position: top;
  border-radius: 4px;
`;

const Title = styled('h6')`
  padding: 0 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const InfoContainer = styled('div')`
  display: flex;
  padding: 0 4px;
`;

const ColumnLeft = styled('div')`
  display: flex;
  flex-direction: column;
  width: 92px;
`;

const ColumnRight = styled('div')`
  flex: 1rem;
`;

const Description = styled('div')`
  padding-right: 2px;
  margin: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.dark['03']};
  font-family: ${({ theme }) => theme.typography.regular};
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
`;

const Name = styled(Description)`
  padding-top: 3px;
`;

const Language = styled(Description)`
  padding-top: 2px;
`;

const Units = styled(Description)`
  font-family: ${({ theme }) => theme.typography.regular};
  font-size: 10px;
  font-style: normal;
  line-height: 8px;
  text-align: center;
  padding-right: 0;
`;

const Counter = styled('h5')<{ variant: ActionType | '' }>`
  padding-top: 5px;
  text-align: center;
  color: ${({ variant, theme }) => {
    if (variant === 'increment') {
      return theme.colors.info;
    }

    if (variant === 'decrement') {
      return theme.colors.warning;
    }

    return theme.colors.black;
  }};
`;

export {
  CardBody,
  CardContent,
  CardWrapper,
  ColumnLeft,
  ColumnRight,
  Controls,
  Counter,
  Description,
  Image,
  InfoContainer,
  Language,
  Name,
  Title,
  Units,
};
