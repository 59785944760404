import { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import styled from '@emotion/styled';

import { Button } from '@innovamat/glimmer-components';
import { useCheckStudentQuery } from '@innovamat/glow-api-client';

import { StudentValidation } from '../types';

type Props = {
  sisId: string;
  email: string | null;
  setStudents: Dispatch<SetStateAction<(StudentValidation | null)[]>>;
};

const Wrapper = styled.div`
  height: 4rem;
  align-items: center;
  display: flex;
`;

function RevalidateButton({ sisId, setStudents, email }: Props): JSX.Element {
  const { t } = useTranslation();

  const { data, isSuccess, refetch, isLoading } = useCheckStudentQuery(
    { sisId, email },
    { enabled: false }
  );

  const handleRevalidate = (): void => {
    refetch();
  };

  useEffect(() => {
    if (isSuccess && !isLoading && data.checkStudent === null) {
      setStudents((prev) => {
        const newStudents = [...prev];
        const studentIndex = prev.findIndex(
          (student) => student?.sisId === sisId
        );

        if (studentIndex !== -1) {
          newStudents[studentIndex] = null;
        }

        return newStudents;
      });
    }

    if (isSuccess && data) {
      setStudents((prev) => {
        const newStudents = [...prev];
        const studentIndex = prev.findIndex(
          (student) => student?.sisId === sisId
        );

        if (studentIndex !== -1 && Array.isArray(data?.checkStudent)) {
          const studentData = prev[studentIndex]!;
          newStudents.splice(studentIndex, 1);

          if (data.checkStudent.length > 0) {
            const newEntries = data?.checkStudent?.map((validation) => {
              if (!validation) {
                newStudents[studentIndex] = null;
                return null;
              }

              return {
                email: studentData.email,
                errorType: validation?.type,
                rowNumber: studentData.rowNumber,
                studentName: studentData.studentName,
                sisId: sisId,
                overwrites: {
                  id: validation?.data.id,
                  classroom: validation?.data.classroom,
                  courseOrder: validation?.data.course_order,
                  firstName: validation?.data.first_name,
                  lastName: validation?.data.last_name,
                  sisId: validation?.data.sis_id,
                  email: validation?.data.email,
                },
              } as StudentValidation;
            });

            newStudents.splice(studentIndex, 0, ...newEntries);
          }
        }

        return newStudents;
      });
    }
  }, [isSuccess, data, setStudents, sisId]);

  return (
    <Wrapper>
      <Button size="S" onClick={handleRevalidate} loading={isLoading}>
        {t('students.import.retry')}
      </Button>
    </Wrapper>
  );
}

export { RevalidateButton };
