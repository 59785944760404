import * as React from 'react';
import type { SVGProps } from 'react';
const SvgTetris = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M.625 8.75a.625.625 0 0 1 .625-.625h2.5a.625.625 0 0 1 .625.625V15a.625.625 0 0 0 .625.625h2.5a.625.625 0 0 1 .625.625v3.125H1.25a.625.625 0 0 1-.625-.625ZM18.75 4.375a.625.625 0 0 1 .625.625v6.25a.625.625 0 0 1-.625.625h-2.5a.625.625 0 0 1-.625-.625v-2.5A.625.625 0 0 0 15 8.125h-2.5a.625.625 0 0 1-.625-.625V1.25A.625.625 0 0 1 12.5.625H15a.625.625 0 0 1 .625.625v2.5a.625.625 0 0 0 .625.625Z"
      />
      <path
        strokeWidth={1.249995}
        d="M11.25 11.875a.625.625 0 0 1 .625.625V15a.625.625 0 0 0 .625.625H15a.625.625 0 0 1 .625.625v2.5a.625.625 0 0 1-.625.625H8.125V16.25a.625.625 0 0 0-.625-.625H5A.625.625 0 0 1 4.375 15v-3.125Z"
      />
    </g>
  </svg>
);
export default SvgTetris;
