import React from 'react';
import cx from 'classnames';
import Badge from '../Badge';
import styles from './Icon.module.scss';
import * as Icons from '../SvgIcons';

type IconProps = {
  icon: keyof typeof Icons;
  size?: 'sm' | 'md';
  showBadge?: boolean;
  containerClassName?: string;
} & React.SVGProps<SVGSVGElement>;

function Icon({
  icon,
  className,
  size = 'md',
  showBadge = false,
  containerClassName,
  ...rest
}: IconProps) {
  const SelectedIcon = Icons[icon];

  const iconContainerClass = cx(
    {
      [containerClassName as string]: !!containerClassName,
    },
    styles.iconContainer
  );

  const iconSize = size === 'md' ? 20 : 16;

  return (
    <div
      className={iconContainerClass}
      style={{ width: iconSize, height: iconSize }}
    >
      <SelectedIcon
        className={`${className ?? ''} icon`.trim()}
        viewBox="0 0 20 20"
        {...rest}
      />
      {showBadge && <Badge />}
    </div>
  );
}

export default Icon;
