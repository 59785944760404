import styled from '@emotion/styled';
import { Container, Row, Col, Hidden } from '@innovamat/emotion-grid';

export const GridContainer = (props: any) => {
  return <GridContainerStyled {...props} />;
};

const GridContainerStyled = styled(Container)`
  @media (max-width: ${(props) => props.theme.breakpoints.xl}) {
    max-width: none !important;
  }
`;

const GridColStyled = styled(Col)`
  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    padding-right: 16px !important;
    padding-left: 16px !important;
  }
`;

export const GridCol = (props: any) => <GridColStyled {...props} />;
export const GridRow = (props: any) => <Row {...props} />;
export const GridHidden = (props: any) => <Hidden {...props} />;
