import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSamMath = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      clipPath="url(#sam_math_svg__a)"
    >
      <path d="M10 19.376c-.422-1.39-3.265-3.014-8.202-3.239a1.25 1.25 0 0 1-1.173-1.255V3.756a1.24 1.24 0 0 1 .692-1.12M10 19.376c.422-1.39 3.265-3.014 8.203-3.239a1.25 1.25 0 0 0 1.172-1.255V3.756a1.24 1.24 0 0 0-.692-1.12M10 11.876v7.5M14.375 1.25V5M12.5 3.126h3.75M6.25 6.25h4.375M8.438 3.75a.31.31 0 0 1 .312.313M8.125 4.063a.31.31 0 0 1 .313-.312M8.438 4.376a.31.31 0 0 1-.313-.313M8.75 4.063a.31.31 0 0 1-.312.313M8.438 8.126a.313.313 0 0 1 .312.312M8.125 8.438a.31.31 0 0 1 .313-.312M8.438 8.75a.31.31 0 0 1-.313-.312M8.75 8.438a.31.31 0 0 1-.312.313M3.75 2.5l.625.626L5 .626h3.75" />
    </g>
    <defs>
      <clipPath id="sam_math_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSamMath;
