import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCrics = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M4.375 13.333c-2.07 0-3.75-1.054-3.75-3.125 0-2.5 2.5-4.375 5-4.375h5v7.5ZM10.625 5.833S11.875 2.708 15 2.708c2.5 0 3.125 1.25 3.125 1.25"
      />
      <path
        strokeWidth={1.249995}
        d="M10.625 13.333h5c3.75 0 3.75-4.375 3.75-4.375s-3.257.986-4.375-1.25a6.12 6.12 0 0 1-.625-4.375l.202-.605M1.875 17.083a3.75 3.75 0 0 1 3.75-3.75M6.875 17.083a3.75 3.75 0 0 1 3.75-3.75M11.875 13.333a3.75 3.75 0 0 1 3.75 3.75"
      />
      <path
        strokeWidth={1.249995}
        d="M13.438 10.208a.313.313 0 0 1 .312.313M13.125 10.52a.313.313 0 0 1 .313-.312M13.438 10.833a.313.313 0 0 1-.313-.312"
      />
      <path strokeWidth={1.249995} d="M13.75 10.52a.313.313 0 0 1-.312.313" />
    </g>
  </svg>
);
export default SvgCrics;
