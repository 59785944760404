import styled from '@emotion/styled';

import Icon from '../Icon';

const Wrapper = styled('button')<{ isExpanded: boolean }>`
  border: none;
  background: none;
  padding: 0.6rem;
  outline: none;
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
  &:active {
    outline: none;
  }

  transform: ${({ isExpanded }) =>
    isExpanded ? 'rotate(0deg)' : 'rotate(180deg)'};
  transition: transform 0.2s ease-in-out;
`;

const ArrowIcon = styled(Icon)`
  path {
    fill: ${({ theme }) => theme.colors.semantic['01']};
  }
`;

export { ArrowIcon, Wrapper };
