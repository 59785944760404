import { useEffect, useState } from 'react';
import Star from '../../star';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

type Props = {
  numStars: number;
  totalCount: number;
  startCount: boolean;
  handleCountFinished: () => void;
  starSize: number;
};

const StarsCounter = ({
  numStars,
  totalCount,
  startCount,
  handleCountFinished,
  starSize,
}: Props) => {
  const [count, setCount] = useState(0);
  const [triggerAnimation, setTriggerAnimation] = useState(false);

  const updateCount = () => setCount(count + 1);

  useEffect(() => {
    if (startCount) {
      if (count < totalCount) {
        const interval = setInterval(updateCount, 200);
        return () => {
          clearInterval(interval);
        };
      }
      if (count === totalCount) {
        handleCountFinished();
      }
    }
    return () => undefined;
  }, [startCount, count, totalCount]);

  useEffect(() => {
    if (startCount) {
      setTriggerAnimation(true);
    }
  }, [startCount]);

  return (
    <StarsContainer
      triggerAnimation={triggerAnimation}
      key={`counter-${numStars}`}
    >
      <Star size={starSize} success={numStars > 0} />
      <Star size={starSize} success={numStars >= 2} />
      <Star size={starSize} success={numStars === 3} />
      <Count>{`× ${count}`}</Count>
    </StarsContainer>
  );
};

export default StarsCounter;

const StarsContainer = styled.div<{ triggerAnimation: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  opacity: 0;
  transition: opacity 0.2s ease-in;
  ${(props) =>
    props.triggerAnimation &&
    css`
      opacity: 1;
    `};
`;

const Count = styled.div`
  margin-left: 8px;
  font-family: ${(props) => props.theme.fonts.robotoMedium};
  font-size: 24px;
  line-height: 28px;
  @media (max-width: ${(props) => props.theme.breakpoints.xl}) {
    font-size: 18px;
    line-height: 22px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: 14px;
    line-height: 16px;
  }
`;
