
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as OpenAppletsIconComponent } from "./open-applets-icon.svg";

export const OpenAppletsIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <OpenAppletsIconComponent {...props} />;
};
