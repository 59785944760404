import styled from '@emotion/styled';
import { Badge } from '../Badge';

type Props = {
  standard: string;
};

const StyledBadge = styled(Badge)`
  padding: 5px 8px;
  height: fit-content;
  align-self: center;
  background-color: ${({ theme }) => theme.colors.dark['02']};
  color: white;
`;

export default function MomentsBlockStandard({ standard }: Props) {
  return (
    <StyledBadge>
      <span>{standard}</span>
    </StyledBadge>
  );
}
