import styled from '@emotion/styled';

import { Icons } from '@innovamat/innova-components';
import BlockContent from '../BlockContent';
import BlockWithIcon from '../BlockWithIcon';
import { Tips, TypeItem } from '../../templates/EarlyYear/types/challenges';
import { PrintableBody2 } from '../../../printable-styles/printable-typography';

type Props = {
  tips: Tips;
};

const TipTypes = {
  Management: '1',
  Learning: '2',
};

const List = styled(PrintableBody2)``;

export default function ChallengesBlockTips({ tips }: Props) {
  if (!tips) return null;

  const getIcon = (item: TypeItem): keyof typeof Icons => {
    return item.items.type.body === TipTypes.Management ? 'Touch' : 'Clip';
  };

  return (
    <>
      {tips.items.map((item, index) => (
        <BlockWithIcon icon={getIcon(item)} key={index}>
          <BlockContent>
            <List>
              <div key={item.id}>{item.items.tip.body}</div>
            </List>
          </BlockContent>
        </BlockWithIcon>
      ))}
    </>
  );
}
