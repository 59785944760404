import styled from '@emotion/styled';
import { IconBase } from '../IconBase';
import { IconType } from '@innovamat/glimmer-icons';
import { ThemeType, useGlimmerTheme } from '../../theme';

const getColor = (
  color: ColorIconOptions,
  theme: ThemeType,
  isDisabled?: boolean
): {
  iconColor: string;
  backgroundColor: string;
} => {
  if (isDisabled) {
    return {
      backgroundColor: theme.tokens.color.global.neutral.neutral100.value,
      iconColor: theme.tokens.color.alias.cm.icon['icon-disabled'].value,
    };
  }

  const baseToken = theme.tokens.color.global;
  switch (color) {
    case 'blue':
      return {
        iconColor: baseToken.blue.blue700.value,
        backgroundColor: baseToken.blue.blue100.value,
      };
    case 'teal':
      return {
        iconColor: baseToken.teal.teal700.value,
        backgroundColor: baseToken.teal.teal100.value,
      };
    case 'orange':
      return {
        iconColor: baseToken.orange.orange700.value,
        backgroundColor: baseToken.orange.orange100.value,
      };
    case 'pink':
      return {
        iconColor: baseToken.pink.pink700.value,
        backgroundColor: baseToken.pink.pink100.value,
      };
    case 'purple':
      return {
        iconColor: baseToken.purple.purple700.value,
        backgroundColor: baseToken.purple.purple100.value,
      };

    default:
      return {
        iconColor: baseToken.blue.blue700.value,
        backgroundColor: baseToken.blue.blue100.value,
      };
  }
};

const StyledIcon = styled(IconBase)<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export type ColorIconOptions = 'blue' | 'teal' | 'orange' | 'pink' | 'purple';

type Props = {
  icon: IconType;
  color: ColorIconOptions;
  isDisabled?: boolean;
};

export function ColorIcon({ icon, color, isDisabled }: Props): JSX.Element {
  const theme = useGlimmerTheme();
  const { iconColor, backgroundColor } = getColor(color, theme, isDisabled);
  return (
    <StyledIcon
      icon={icon}
      iconColor={iconColor}
      backgroundColor={backgroundColor}
      isHoverEnabled={false}
    />
  );
}
