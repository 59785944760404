
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as SoundOnIconComponent } from "./sound-on-icon.svg";

export const SoundOnIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <SoundOnIconComponent {...props} />;
};
