export enum actions {
  SET_LOADING = 'SET_LOADING',
  REMOVE_LOADING = 'REMOVE_LOADING',
}

export enum keys {
  LOGIN = 'login',
  CONTENTS = 'contents',
  SETTINGS = 'settings',
  RECOVERY = 'recovery',
  REGISTER = 'register',
  LINK = 'link',
  ASSESSOR = 'assessor',
  USER_INVITATION = 'user_invitation',
  ORGANIZATION = 'organization',
  WEEKLY_PRACTICE = 'weekly_practice',
  TRAINING_ZONE = 'training_zone',
  SET_ACCOUNT_DATA = 'SET_ACCOUNT_DATA',
  SKIP_ACTIVITY = 'SKIP_ACTIVITY'
}

export const actionSetLoading = (key: string): any => ({
  type: actions.SET_LOADING,
  key,
});

export const actionRemoveLoading = (key: string): any => ({
  type: actions.REMOVE_LOADING,
  key,
});
