import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPaginateFilter4 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path strokeWidth={1.249995} d="M3.125.622h16.25v16.25H3.125Z" />
      <path
        strokeWidth={1.249995}
        d="M16.875 19.372h-15a1.25 1.25 0 0 1-1.25-1.25v-15M13.125 11.872v-1.25M13.125 10.622V5.634a6.88 6.88 0 0 0-3.734 4.988z"
      />
    </g>
  </svg>
);
export default SvgPaginateFilter4;
