import {
  ResourceViewer,
  Session,
  Action,
  PlayerState,
} from '@innovamat/resource-viewer';
import { useCallback } from 'react';
import { useSmartGuides } from '../../context/SmartGuidesProvider';

import styled from '@emotion/styled';

import { useWebviewBridge } from '@innovamat/webview-bridge';

const Wrapper = styled.div<{ isStudent: boolean }>`
  width: 100%;
  height: ${({ isStudent }) => (isStudent ? '80vh' : '400px')};
  border-radius: 4px;
  border: 1px solid
    ${({ theme }) => theme.tokens.color.global.neutral.neutral200.value};
`;

export function PlayerBlock() {
  const { playerInfo, translations } = useSmartGuides();

  const {
    isStudent,
    resource,
    appletInfo,
    setEventData,
    onResourceViewerEvents,
    apolloServer,
    preventArrowKeysEvents,
    school,
    uid,
    axiosInstance,
  } = playerInfo!;

  const session = resource as Session;

  const handleEventsPlayer = useCallback(
    (prevState: PlayerState, state: PlayerState, action: Action) => {
      onResourceViewerEvents?.(prevState, state, action);
    },
    [onResourceViewerEvents]
  );

  const { isInWebview } = useWebviewBridge();

  if (!session.resources.length) return null;

  return (
    <Wrapper isStudent={isStudent}>
      <ResourceViewer
        resource={resource}
        translations={translations}
        onDispatchEvent={handleEventsPlayer}
        appletInfo={appletInfo}
        preventArrowKeysEvents={preventArrowKeysEvents}
        apolloServer={apolloServer!}
        axiosInstance={axiosInstance}
        setEventData={setEventData}
        school={school}
        uid={uid}
        videoOptions={{
          transparent: !isInWebview,
          playsinline: isInWebview ? 1 : 0,
        }}
        autoplay={false}
      />
    </Wrapper>
  );
}
