import styled from '@emotion/styled';

import { Icon, useGlimmerTheme } from '@innovamat/glimmer-components';
import { PrepElement } from '../types/prepr';
type Props = {
  disclaimer: PrepElement;
};

const Info = styled.span`
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  margin-top: -16px;
  color: ${({ theme }) => theme.colors.dark['01']};
  margin-bottom: 8px;
  span {
    margin-left: 8px;
  }

  @media print {
    margin-top: -8px;
  }
`;

export default function DisclaimerBlock({ disclaimer }: Props) {
  const theme = useGlimmerTheme();
  if (!disclaimer) return null;

  return (
    <Info>
      <Icon
        icon="InfoIcon"
        size="S"
        bgColor={theme.tokens.color.alias.cm.icon['icon-info'].value}
      />
      <span>{disclaimer.body}</span>
    </Info>
  );
}
