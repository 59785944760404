import * as React from 'react';
import type { SVGProps } from 'react';
const SvgArrowDownSm = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <g clipPath="url(#arrow_down_sm_svg__a)">
      <path
        fill="#333"
        d="M8 11.75a1.15 1.15 0 0 1-.864-.39L2.23 5.784a.923.923 0 0 1 1.387-1.22l4.289 4.877a.125.125 0 0 0 .188 0l4.29-4.877a.923.923 0 1 1 1.386 1.22l-4.904 5.574A1.16 1.16 0 0 1 8 11.75"
      />
    </g>
    <defs>
      <clipPath id="arrow_down_sm_svg__a">
        <path fill="#fff" d="M2 2h12v12H2z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgArrowDownSm;
