import { IconBase } from '@innovamat/glimmer-components';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { IconType } from '@innovamat/glimmer-icons';
import { PartType } from '../../types/SmartGuide';
import { usePartColors } from '../../hooks/usePartColors';
import { Stage } from '../../types/Stage';

const StyledIconPart = styled(IconBase)<{
  backgroundColor?: string;
}>`
  pointer-events: none;

  @media print {
    align-self: flex-start;
  }

  ${({ backgroundColor, theme }) =>
    backgroundColor &&
    css`
      background-color: ${backgroundColor ||
      theme.tokens.color.alias.cm.surface['surface-secondary'].value};
    `}
`;

type Props = {
  part: PartType;
  icon?: IconType;
  stage: Stage;
};

const getIcon = (part: PartType, stage: Stage): IconType => {
  const Icons = {
    1: {
      0: 'InfoIcon',
      1: 'Part1EarlyYearsIcon',
      2: 'Part2EarlyYearsIcon',
      3: 'Part3EarlyYearsIcon',
      4: 'MediaIcon',
    },
    2: {
      0: 'InfoIcon',
      1: 'Part1GuidesIcon',
      2: 'Part2GuidesIcon',
      3: 'Part3GuidesIcon',
      4: 'MediaIcon',
    },
    3: {
      0: 'InfoIcon',
      1: 'Part1GuidesIcon',
      2: 'Part2GuidesIcon',
      3: 'Part3GuidesIcon',
      4: 'MediaIcon',
    },
  };

  return Icons[stage][part] as IconType;
};

export function IconPart({ icon, part, stage }: Props) {
  const { getBackground, getElement } = usePartColors();

  return (
    <StyledIconPart
      icon={icon || getIcon(part, stage)}
      iconColor={
        getIcon(part, stage) === 'InfoIcon'
          ? getBackground(part)
          : getElement(part)
      }
      backgroundColor={getBackground(part)}
      bgColor={
        getIcon(part, stage) === 'InfoIcon' ? getElement(part) : undefined
      }
    />
  );
}
