import * as React from 'react';
import type { SVGProps } from 'react';
const SvgContentWrite = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
    <path
      d="M13.68 23.25H1.82a1.07 1.07 0 0 1-1.07-1.08V1.83A1.07 1.07 0 0 1 1.82.75h15.1A1.08 1.08 0 0 1 18 1.83v5.38M7.2 6.38h6.46"
      style={{
        fill: 'none',
        stroke: '#000',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeWidth: '1.5px',
      }}
      transform="scale(.83333)"
    />
    <path
      d="M3.8 6a.38.38 0 1 1 0 .75.38.38 0 0 1 0-.75M3.8 9.86a.38.38 0 1 1 0 .75.38.38 0 0 1 0-.75M15.93 11.35a12 12 0 0 0-1.69-.9 2.09 2.09 0 0 0-2.13.17l-1.85 1.32a2.6 2.6 0 0 0-1.06 1.75l-.28 2M23.25 22.5l-5.36-8.56"
      style={{
        fill: 'none',
        stroke: '#000',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeWidth: '1.5px',
      }}
      transform="scale(.83333)"
    />
    <path
      d="m13.71 16.5 5.57-3.41a1.62 1.62 0 0 0-1.69-2.76L7.5 16.52h0l-1.91 3.06 3.59-.31h0L11.25 18"
      style={{
        fill: 'none',
        stroke: '#000',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeWidth: '1.5px',
      }}
      transform="scale(.83333)"
    />
    <path
      d="M16.5 16.5h-3.75a1.5 1.5 0 0 0-1.5 1.5h0a1.5 1.5 0 0 0 1.5 1.5h2.74l1.76 3.75"
      style={{
        fill: 'none',
        stroke: '#000',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeWidth: '1.5px',
      }}
      transform="scale(.83333)"
    />
  </svg>
);
export default SvgContentWrite;
