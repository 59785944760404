import { useContentLists } from '@innovamat/glow-api-client';
import { MarkMessionMethodParams } from '../../../session-done';
import { ContentListType, ContentResource } from '../../types';
import { ContentList, ContentListSkeleton } from '../../components';
import styled from '@emotion/styled';

const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

type IRelatedResourcesListProps = {
  linkedListsIds: string[];
  classroomId: string;
  academicYearId: string;
  onResourceClick: (resource: ContentResource, list: ContentListType) => void;
  onMarkSession?: (params: MarkMessionMethodParams) => void;
  onToggleSolutions?: (params: MarkMessionMethodParams) => void;
  isStudentView?: boolean;
};

export function LinkedListsPage({
  linkedListsIds,
  onResourceClick,
  onMarkSession,
  onToggleSolutions,
  classroomId,
  academicYearId,
  isStudentView,
}: IRelatedResourcesListProps) {
  const { data, isLoading } = useContentLists({
    academicYearId,
    classroomId: classroomId!,
    contentListsIds: linkedListsIds,
    isStudentView: Boolean(isStudentView),
  });

  const contentLists = data;

  if (isLoading) {
    return (
      <SkeletonContainer>
        <ContentListSkeleton />
        <ContentListSkeleton />
      </SkeletonContainer>
    );
  }

  return (
    <Container>
      {contentLists?.map((list, index) => {
        return (
          <ContentList
            key={index}
            list={list}
            onResourceClick={(resource) => onResourceClick(resource, list)}
            onMarkSession={onMarkSession}
            onToggleSolutions={onToggleSolutions}
          />
        );
      })}
    </Container>
  );
}
