import styled from '@emotion/styled';
import { css } from '@emotion/react';

const OverlayClass = styled.div<{
  isOneModalOpenAlready: boolean;
  opacity?: number;
}>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  ${({ isOneModalOpenAlready, opacity }) =>
    !isOneModalOpenAlready &&
    css`
      background-color: rgba(0, 0, 0, ${opacity});
    `}
`;

const ModalWrapper = styled.div<{
  size?: string;
  backgroundColor?: string;
  padding?: string;
}>`
  position: fixed;
  justify-content: space-between;
  flex-direction: column;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.colors.white};
  z-index: 1001;
  box-shadow: ${({ backgroundColor }) =>
    !backgroundColor ? '0 8px 16px rgba(0, 0, 0, 0.24)' : 'none'};
  border-radius: 8px;
  padding: ${({ padding }) => padding || '32px 80px 60px'};
  gap: 20px;
  height: auto;
  ${({ size }) => {
    if (size === 'small') {
      return css`
        min-width: 514px;
        min-height: 256px;
      `;
    }
    return css`
      min-width: 660px;
      max-width: 90vw;
    `;
  }}
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 100%;
  }
`;

const CloseButton = styled.div<{ backgroundColor?: string }>`
  cursor: pointer;
  position: absolute;
  right: 24px;
  top: 24px;
  z-index: 1002;
`;

const ContentWrapper = styled.div`
  flex: 1;
  gap: 8px;
  display: flex;
  flex-direction: column;
  z-index: 1001;
`;

const Title = styled.div<{ backgroundColor?: string }>`
  margin-bottom: 8px;
  z-index: 1001;
  color: ${({ backgroundColor }) => (backgroundColor ? 'white' : 'none')}; ;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  z-index: 1001;
`;

export {
  Footer,
  Title,
  ContentWrapper,
  ModalWrapper,
  OverlayClass,
  CloseButton,
};
