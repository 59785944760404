/* eslint-disable no-case-declarations */
import { authAxiosInstance } from '@innovamat/ga-features';
import { storage } from '@innovamat/radiance-utils';
import { manageUnityError } from '@innovamat/unity-toolkit-errors';
import { AnyAction, Dispatch } from 'redux';
import authActions from '../../store/auth/actions';
import { store } from '../../store/store';
import {
  actionActivityIsCompleted,
  actionSetBridgeIsReady,
  actionSetPreviousScene,
  actionSetUnityData,
  actionSkipActivity,
  actionUpdateUnityData,
} from '../../store/unityData/unityData.actions';
import { setEventData } from '../../utils/setEventData';

let metaDataInfo = { isCompleted: false };

export function manageError(errorMessage: string, metaData: any = null): void {
  if (
    typeof errorMessage === 'string' &&
    (errorMessage.includes('Exception') || errorMessage.includes('exception'))
  ) {
    const {
      uData: { sceneName, packOrVariation },
      auth: {
        user: { school, uid },
      },
    } = store.getState();

    manageUnityError({
      setEventData,
      axiosInstance: authAxiosInstance,
      apolloServer: window.__GA_CONFIG__.apolloServer!,
      version: metaData?.version,
      origin: 'webapp',
      error: {
        packOrVariation,
        sceneName,
        stage: 'PRIMARIA',
        errorMessage,
        metaDataInfo,
        school,
        uid,
        metaData,
      },
    });
  }
}

export function manageErrorStopper(errorMessage: string): void {
  const {
    uData: { sceneName, packOrVariation, Account, Students },
    auth: {
      user: { school, uid },
    },
  } = store.getState();

  const isConnectionError = !Account?.id && !Students;

  if (!isConnectionError) {
    manageUnityError({
      setEventData,
      axiosInstance: authAxiosInstance,
      apolloServer: window.__GA_CONFIG__.apolloServer!,
      origin: 'webapp',
      error: {
        packOrVariation,
        sceneName,
        stage: 'PRIMARIA',
        errorMessage,
        errorType: 'Error-stopper',
        metaDataInfo,
        school,
        uid,
      },
    });
  }
}

function legacyReducer(eventData: any, dispatch: Dispatch<AnyAction>): void {
  if (eventData === 'BridgeIsReady') dispatch(actionSetBridgeIsReady(true));

  if (eventData === 'ActivityIsCompleted') {
    metaDataInfo = { isCompleted: true };
    dispatch(actionActivityIsCompleted());
  }

  manageError(eventData);
}

function lastestReducer(
  eventData: any,
  dispatch: Dispatch<AnyAction>,
  metaData: any = null
): void {
  const {
    auth: { authValues, user },
    uData,
  } = store.getState();
  switch (eventData.eventId) {
    case 'BridgeIsReady':
    case 'SendReadyToBrowser':
      dispatch(actionSetBridgeIsReady(true));
      break;
    case 'SendData':
      dispatch(actionSetUnityData(eventData.arguments));
      break;
    case 'GetBiome':
    case 'UpdateCity':
      dispatch(actionUpdateUnityData({ biomeId: eventData.arguments }));
      break;
    case 'ActivityExceptionCatch':
      const { sessionEntity, sessionType, uuid } = uData;
      if (sessionEntity?.activities) {
        dispatch(
          actionSkipActivity(
            uuid,
            sessionType,
            sessionEntity?.activities[0].codename
          )
        );
      }
      dispatch(actionActivityIsCompleted());
      break;
    case 'UpdateTokens':
      const { AccessToken, RefreshToken, IdToken } = eventData.arguments;
      const tokenData = {
        token_type: authValues.token_type,
        expires_in: authValues.expires_in,
        id_token: IdToken,
        access_token: AccessToken,
        refresh_token: RefreshToken,
      };
      storage.tokenInfo.set(tokenData);
      dispatch(authActions.refreshToken(tokenData));
      break;
    case 'SendLoadBuild':
      dispatch(
        actionSetUnityData({ sceneName: eventData.arguments.sceneName })
      );
      break;
    case 'SendCompleteActivityEvent':
      dispatch(actionActivityIsCompleted(eventData.arguments));
      break;
    case 'SendCloseAppToBrowser':
      dispatch(actionSetPreviousScene());
      break;
    case 'Error':
      manageError(eventData.arguments[0], metaData);
      break;
    case 'Error-stopper':
      manageErrorStopper(eventData.arguments);
      dispatch(
        actionUpdateUnityData({
          sceneName: 'ErrorScene_Innovamat',
          SceneName: 'ErrorScene_Innovamat',
        })
      );
      break;
    default:
      break;
  }
}

export default function unityEventsHandler(
  dispatch: Dispatch<AnyAction>
): (eventMessage: MessageEvent, metaData?: any) => void {
  return (eventMessage: MessageEvent, metaData: any = null) => {
    const { data } = eventMessage;

    const isLegacyMessage = data.eventId === null || data.eventId === undefined;

    if (!isLegacyMessage) lastestReducer(data, dispatch, metaData);
    else if (isLegacyMessage) {
      if (!(data.source !== undefined)) {
        legacyReducer(data, dispatch);
      }
    }
  };
}
