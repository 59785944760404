import * as React from 'react';
import type { SVGProps } from 'react';
const SvgOutdoorsLandscape = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M6.25 3.14 4.945 1.181a1.25 1.25 0 0 0-2.158.134L.625 5.639h10L8.901 2.191a1.25 1.25 0 0 0-2-.325l-.917.877M16.875 2.5l-2.5 6.875h5zM16.875 9.375v2.5M3.125 10l-2.5 6.875h5zM3.125 16.875v2.5M16.25 16.417a4 4 0 0 1 1.058 1.713M12.5 14.873q.3.073.624.127a8 8 0 0 1 1.256.313M8.917 12.223A4.8 4.8 0 0 0 10 13.639M8.125 8.125a14 14 0 0 0 .072 1.458"
      />
    </g>
  </svg>
);
export default SvgOutdoorsLandscape;
