import { SVGProps } from 'react';

export const EusFlag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 12"
    fill="none"
    {...props}
  >
    <g clip-path="url(#clip0_2124_1807)">
      <path d="M0 0.375V11.625H18V0.375H0Z" fill="#CC1F00" />
      <path
        d="M0 0.375L18 11.625M18 0.375L0 11.625"
        stroke="#51A329"
        stroke-width="1.548"
      />
      <path d="M9 0.375V11.625V0.375ZM0 6H18H0Z" fill="black" />
      <path d="M9 0.375V11.625M0 6H18" stroke="white" stroke-width="1.548" />
      <path
        d="M8.22766 0.425537H9.77344"
        stroke="#949494"
        stroke-width="0.1125"
      />
      <path
        d="M0.0532837 5.22656L0.0532836 6.77234"
        stroke="#949494"
        stroke-width="0.1125"
      />
      <path
        d="M17.9434 5.22656L17.9434 6.77234"
        stroke="#949494"
        stroke-width="0.1125"
      />
      <path
        d="M8.22711 11.5679H9.77289"
        stroke="#949494"
        stroke-width="0.1125"
      />
    </g>
    <defs>
      <clipPath id="clip0_2124_1807">
        <rect y="0.375" width="18" height="11.25" rx="1.125" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
