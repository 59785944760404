import styled from '@emotion/styled';

const Container = styled('section')`
  grid-template-columns: repeat(4, 1fr);
  display: grid;
  grid-gap: 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

type MaterialsListBodyProps = {
  children: React.ReactNode | React.ReactNode[];
};

function MaterialsListBody({ children }: MaterialsListBodyProps): JSX.Element {
  return <Container>{children}</Container>;
}

export { MaterialsListBody };
