import * as React from 'react';
import type { SVGProps } from 'react';
const SvgVideoDuration = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      clipPath="url(#video_duration_svg__a)"
    >
      <path d="M14.375 19.375a5 5 0 1 0 0-10 5 5 0 0 0 0 10" />
      <path d="M16.585 14.375h-2.21v-2.21M6.875 16.875h-5a1.25 1.25 0 0 1-1.25-1.25V1.875a1.25 1.25 0 0 1 1.25-1.25h8.858c.33 0 .649.132.883.366l2.393 2.393c.234.235.366.552.366.884v2.607" />
      <path d="M5.2 10.565a.57.57 0 0 1-.825-.51v-3.86a.57.57 0 0 1 .826-.51l3.859 1.93a.57.57 0 0 1 0 1.02z" />
    </g>
    <defs>
      <clipPath id="video_duration_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgVideoDuration;
