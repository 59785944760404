import { css } from '@emotion/react';

import { typography } from './typography';
import { colors } from './colors';

export const GlobalStyles = css`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  input,
  textarea,
  button,
  select {
    outline: none;
  }

  body {
    color: ${colors.black};
    font-family: ${typography.regular};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  button {
    color: ${colors.black};
    font-family: ${typography.regular};
  }

  input,
  textarea {
    line-height: ${typography.lineHeight.medium};
  }

  a {
    text-decoration: none;
    color: ${colors.brand.dark['02']};
  }

  h1 {
    font-size: ${typography.typeScale.xlarge};
    font-weight: ${typography.weight.regular};
    line-height: ${typography.lineHeight.xlarge};
  }

  h2 {
    font-size: ${typography.typeScale.large};
    font-weight: ${typography.weight.regular};
    line-height: ${typography.lineHeight.large};
  }

  h3 {
    font-size: ${typography.typeScale.caption};
    font-weight: ${typography.weight.regular};
    line-height: ${typography.lineHeight.caption};
  }

  h4,
  .h4 {
    font-size: ${typography.typeScale.medium};
    font-weight: ${typography.weight.regular};
    line-height: ${typography.lineHeight.medium};
  }

  h5,
  .h5 {
    font-family: ${typography.medium};
    font-size: ${typography.typeScale.normal};
    font-weight: ${typography.weight.medium};
    line-height: ${typography.lineHeight.normal};
  }

  h6,
  .h6 {
    font-family: ${typography.medium};
    font-size: ${typography.typeScale.small};
    font-weight: ${typography.weight.medium};
    line-height: ${typography.lineHeight.small};
  }

  .h7 {
    font-family: ${typography.medium};
    font-size: ${typography.typeScale.xsmall};
    font-weight: ${typography.weight.medium};
    line-height: ${typography.lineHeight.xsmall};
  }

  .h8 {
    font-family: ${typography.medium};
    font-size: ${typography.typeScale.micro};
    font-weight: ${typography.weight.medium};
    line-height: ${typography.lineHeight.micro};
  }

  label {
    font-family: ${typography.medium};
    font-size: ${typography.typeScale.small};
    line-height: ${typography.lineHeight.small};
  }

  .full-width {
    width: 100%;
  }

  .ripple {
    position: relative;
    overflow: hidden;

    &::after {
      display: none;
      content: '';
      position: absolute;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.2);
      width: 100px;
      height: 100px;
      margin-top: -50px;
      margin-left: -50px;
      top: 50%;
      left: 50%;
      animation: ripple 1.5s;
      opacity: 0;
    }

    &:focus:not(:active)::after {
      display: block;
    }
  }

  .no-radius {
    border-radius: 0 !important;
  }

  @keyframes ripple {
    from {
      opacity: 1;
      transform: scale(0);
    }

    to {
      opacity: 0;
      transform: scale(10);
    }
  }
`;
