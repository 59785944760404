import * as React from 'react';
import type { SVGProps } from 'react';
const SvgRetract = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M2 13.333h4.667V18M18 13.333h-4.667V18M2 6.667h4.667V2M18 6.667h-4.667V2"
    />
  </svg>
);
export default SvgRetract;
