import { SVGProps } from 'react';

export const StudentsIcon_Legacy = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg height={20} width={20} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        fill="none"
        stroke="#757575"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      >
        <path
          d="M6.77 3.438a2.813 2.813 0 105.626 0 2.813 2.813 0 10-5.625 0zM12.96 8.75a5.023 5.023 0 00-6.754 0"
          strokeWidth={1.249995}
        />
        <path
          d="M12.396 3.53a5.627 5.627 0 01-1.563.22 5.603 5.603 0 01-3.806-1.484M12.604 13.438a2.813 2.813 0 105.625 0 2.813 2.813 0 10-5.625 0zM19.375 19.375a5.022 5.022 0 00-7.917 0M18.23 13.53a5.633 5.633 0 01-5.37-1.264M1.77 13.438a2.813 2.813 0 105.626 0 2.813 2.813 0 10-5.625 0zM8.54 19.375a5.022 5.022 0 00-7.916 0M7.396 13.53a5.633 5.633 0 01-5.37-1.264"
          strokeWidth={1.249995}
        />
      </g>
    </svg>
  );
};
