
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as EmptyIconComponent } from "./empty-icon.svg";

export const EmptyIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <EmptyIconComponent {...props} />;
};
