import { roles, Roles } from '@innovamat/radiance-utils';

export function checkAllowedRoles(userRoles: Roles[]): boolean {
  return (
    roles.hasParentRole(userRoles) ||
    roles.hasTeacherRole(userRoles) ||
    roles.hasStudentRole(userRoles) ||
    roles.hasStudentJuniorRole(userRoles)
  );
}
