import styled from '@emotion/styled';
import { Icon } from '../Icon';
import { Typography } from '../Typography';
import { ThemeType } from '../../theme';
import { ColorIcon, ColorIconOptions } from '../color-icon';
import { IconType } from '@innovamat/glimmer-icons';
import { css } from '@emotion/react';
import { StateLayer } from '../../utils/common.styled';

const getBorderColor = (theme: ThemeType): string => {
  return theme.tokens.color.alias.cm.border['border-subtle'].value;
};

const Container = styled.div<{ isDisabled: boolean }>`
  position: relative;
  padding: 16px;
  display: flex;
  gap: 16px;
  border-radius: 8px;
  gap: 16px;
  min-width: 100%;

  outline: 1px solid ${({ theme }) => getBorderColor(theme)};
  outline-offset: -1px;

  &:hover {
    outline: 2px solid ${({ theme }) => getBorderColor(theme)};
    outline-offset: -2px;
  }

  align-items: center;
  cursor: pointer;

  :active {
    outline: none;
    .tool-card-state-layer {
      outline: 1px solid
        ${({ theme }) =>
          theme.tokens.color.alias.cm.border['border-subtle'].value};
      border-radius: 8px;
      outline-offset: -1px;
      background-color: ${({ theme }) =>
        theme.tokens.color.specific['state-layer']['state-press-darker'].value};
    }
  }

  ${({ isDisabled, theme }) =>
    isDisabled &&
    css`
      outline: 1px solid ${theme.tokens.color.global.neutral.neutral100.value};
      outline-offset: -1px;
      pointer-events: none;
      svg,
      path {
        fill: ${theme.tokens.color.alias.cm.icon['icon-disabled'].value};
      }
    `};
`;

const Title = styled(Typography.Subtitle2)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Description = styled(Typography.Body3)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const TextContainer = styled.div<{ isDisabled: boolean; rightIcon?: IconType }>`
  display: flex;
  flex-direction: column;
  width: ${({ rightIcon }) =>
    rightIcon ? 'calc(100% - 90px)' : 'calc(100% - 56px)'};
  ${({ theme, isDisabled }) =>
    isDisabled
      ? css`
          * {
            color: ${theme.tokens.color.alias.cm.text['text-disabled'].value};
          }
        `
      : ''};
`;

const IconContainer = styled.div`
  margin-left: auto;
`;

export type ToolCardItem = {
  color: ColorIconOptions;
  dataTestId?: string;
  description: string;
  icon: IconType;
  inlineManualId?: string;
  isDisabled?: boolean;
  rightIcon?: IconType;
  title: string;
  onClick?: () => void;
};

export function ToolCard({
  title,
  description,
  icon,
  color,
  isDisabled,
  rightIcon,
  onClick,
  dataTestId,
  inlineManualId,
}: ToolCardItem): JSX.Element {
  return (
    <Container
      isDisabled={Boolean(isDisabled)}
      onClick={onClick}
      data-testid={dataTestId}
      data-inlinemanual={inlineManualId}
    >
      <ColorIcon icon={icon} color={color} isDisabled={isDisabled} />
      <TextContainer isDisabled={!!isDisabled} rightIcon={rightIcon}>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </TextContainer>
      {rightIcon && (
        <IconContainer>
          <Icon icon={rightIcon} size="S" />
        </IconContainer>
      )}
      <StateLayer className="tool-card-state-layer" />
    </Container>
  );
}
