import styled from '@emotion/styled';
import { mapAskAndObserve } from '../../templates/EarlyYear/EarlyYear.mappers';
import BlockWithIcon from '../BlockWithIcon';
import { IconRecord } from '../../types/icons';
import { InnerHtml, getTypography } from '@innovamat/glimmer-components';
import { AskAndObserve } from '../../templates';
import { useDigitalGuides } from '../../context';

type Props = {
  data: AskAndObserve;
  contentIcons: IconRecord;
  isPrintable?: boolean;
};

const Container = styled.div<{ isPrintable?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ isPrintable }) => (isPrintable ? '12px' : '24px')};
  width: 99%;
  min-width: 0;

  table {
    margin: 8px 0;
    width: 100%;
    border-spacing: 4px;
    min-width: ${({ isPrintable }) => (isPrintable ? '0' : '550px')};
    overflow: ${({ isPrintable }) => (isPrintable ? 'hidden' : 'auto')};
    color: ${({ theme }) => theme.tokens.color.alias.cm.text.text.value};

    @media print {
      border-spacing: 2px;
      margin: 2px 0;
      min-width: none;
    }
  }

  @media print {
    gap: 12px;
    border-spacing: 2px;
    margin: 2px 0;
  }

  .mediaContainer {
    margin: 0;
  }
`;

const Td = styled.td<{ isPrintable?: boolean }>`
  padding: ${({ isPrintable }) => (isPrintable ? '4px' : '8px')};
  border-radius: 4px;
  background-color: transparent;
  vertical-align: top;
  ${({ theme }) => getTypography(theme, 'Body 2')}

  @media print {
    padding: 4px;
    ${({ theme }) => getTypography(theme, 'Body 3')}
  }

  &.bold {
    ${({ theme }) => getTypography(theme, 'Subtitle 2')}

    @media print {
      ${({ theme }) => getTypography(theme, 'Body 3')}
    }
  }

  ul {
    margin-left: 12px;
  }

  li {
    margin-bottom: 8px;
  }

  .tooltip-span {
    display: inline;
    width: fit-content;
  }
`;

const Th = styled.th<{ isPrintable?: boolean }>`
  padding: ${({ isPrintable }) => (isPrintable ? '8px 4px' : '16px 8px')};
  border-radius: 4px;
  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.surface['surface-secondary'].value};
  ${({ theme, isPrintable }) =>
    getTypography(theme, isPrintable ? 'Subtitle 2' : 'Subtitle 1')};

  @media print {
    font-size: 12px;
    line-height: 14px;
  }
`;

const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.border['border-subtle'].value};
  margin: -4px -4px 0 4px;
  width: 100%;
`;

export default function ChallengesBlockAskAndObserve({
  data,
  isPrintable,
}: Props) {
  const { user, t } = useDigitalGuides();
  const tables = mapAskAndObserve(data, user?.region!, isPrintable);

  if (!data) return null;

  return (
    <BlockWithIcon icon="Solutions">
      <Container isPrintable={isPrintable}>
        {tables.map((table, tableIndex) => (
          <>
            <table key={table.title}>
              <tbody>
                {table.rows.map((row, index) => (
                  <tr key={index}>
                    {row.map((cell, index) => {
                      const CellComponent = cell.isHeader ? Th : Td;
                      return (
                        <CellComponent
                          isPrintable={isPrintable}
                          key={index}
                          colSpan={cell.colSpan}
                          width={`${table.columnPercentage[index]}%`}
                          className={cell.className}
                        >
                          {typeof cell.text === 'string' ? (
                            <InnerHtml text={t(cell.text)} />
                          ) : (
                            cell.text
                          )}
                        </CellComponent>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
            {isPrintable && tableIndex + 1 < tables.length && <Divider />}
          </>
        ))}
      </Container>
    </BlockWithIcon>
  );
}
