import styled from '@emotion/styled';
import { Button, Popover, Typography } from '@innovamat/glimmer-components';
import {
  Roles,
  getMainRole,
  hasAdvisorRole,
  hasDistrictAdminRole,
  hasOwnerRole,
} from '@innovamat/radiance-utils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../../../../user-management';

const CustomButton = styled(Button)`
  padding-right: 8px;
  padding-left: 8px;
`;

type Props = {
  onUpdateRole: (roles: Roles[]) => void;
  teacherRoles: Roles[] | null;
  isDisabled: boolean;
};

type RoleOption = {
  value: string;
  name: string;
};

const rolesMatrix = {
  [Roles.OWNER]: [Roles.OWNER, Roles.ADMIN, Roles.TEACHER],
  [Roles.ADMIN]: [Roles.ADMIN, Roles.TEACHER],
  [Roles.TEACHER]: [Roles.TEACHER],
};

function SchoolTeachersTableRolesCell({
  onUpdateRole,
  teacherRoles,
  isDisabled,
}: Props): JSX.Element {
  const { user } = useUser();
  const { t } = useTranslation();

  const handleSelectRole = (role: Roles): void => {
    onUpdateRole(rolesMatrix[role as keyof typeof rolesMatrix]);
  };

  const rolesTranslationsKeys = useMemo(() => {
    return {
      [Roles.OWNER]: t('roles.owner'),
      [Roles.ADMIN]: t('roles.admin'),
      [Roles.TEACHER]: t('roles.teacher'),
    };
  }, [t]);

  const isAdvisorOrDistrictAdmin =
    hasAdvisorRole(user?.roles) || hasDistrictAdminRole(user?.roles);

  const rolesOptions: RoleOption[] = useMemo(() => {
    const roles: RoleOption[] = [
      {
        value: Roles.ADMIN,
        name: rolesTranslationsKeys[Roles.ADMIN],
      },
      {
        value: Roles.TEACHER,
        name: rolesTranslationsKeys[Roles.TEACHER],
      },
    ];

    if (isAdvisorOrDistrictAdmin) {
      roles.push({
        value: Roles.OWNER,
        name: rolesTranslationsKeys[Roles.OWNER],
      });
    }

    return roles;
  }, [isAdvisorOrDistrictAdmin, rolesTranslationsKeys]);

  const mainRole = getMainRole(teacherRoles);

  const valueLabel = rolesOptions.find(
    (option) => option.value === mainRole
  )?.name;

  const isSelectDisabled = hasOwnerRole(teacherRoles);

  if (teacherRoles === null) {
    return (
      <Button
        size={'M'}
        variant={'accent'}
        rightIcon="ExpandMoreIcon"
        disabled
        oneLine
      >
        <Typography.Body2>{t('roles.teacher')}</Typography.Body2>
      </Button>
    );
  }

  if (mainRole && (isDisabled || hasOwnerRole(teacherRoles))) {
    return (
      <Button size={'M'} variant={'tertiary'} disabled oneLine>
        <Typography.Body2>
          {
            rolesTranslationsKeys[
              mainRole as keyof typeof rolesTranslationsKeys
            ]
          }
        </Typography.Body2>
      </Button>
    );
  }

  return (
    <>
      <Popover
        id={'school-teachers-table-roles-cell'}
        trigger={
          <CustomButton
            size={'M'}
            variant={'tertiary'}
            rightIcon="ExpandMoreIcon"
            oneLine
            disabled={isSelectDisabled}
          >
            <Typography.Body2>{valueLabel}</Typography.Body2>
          </CustomButton>
        }
      >
        {rolesOptions.map((option) => (
          <Popover.Item
            key={option.value}
            state={option.value === mainRole ? 'selected' : 'active'}
            onSelectItem={() => handleSelectRole(option.value as Roles)}
          >
            {option.name}
          </Popover.Item>
        ))}
      </Popover>
    </>
  );
}

export { SchoolTeachersTableRolesCell };
