import styled from '@emotion/styled';

const Container = styled('section')<{ isDisabled?: boolean }>`
  padding-bottom: 2rem;
  ${({ isDisabled }) =>
    isDisabled &&
    `
    pointer-events: none;
    opacity: 0.5;
    filter: grayscale(1);
  `}
`;

type MaterialsListProps = {
  children: React.ReactNode | React.ReactNode[];
  isDisabled?: boolean;
};

const MaterialsList = ({ children, isDisabled }: MaterialsListProps) => {
  return <Container isDisabled={isDisabled}>{children}</Container>;
};

export { MaterialsList };
