import * as React from 'react';
import type { SVGProps } from 'react';
const SvgArrowLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g clipPath="url(#arrow_left_svg__a)">
      <path
        fill="#333"
        d="M5.606 9.983a1.44 1.44 0 0 1 .487-1.081l6.97-6.132a1.154 1.154 0 0 1 1.525 1.733L8.493 9.865a.156.156 0 0 0 0 .235l6.095 5.362a1.153 1.153 0 1 1-1.525 1.733l-6.967-6.13a1.45 1.45 0 0 1-.49-1.082"
      />
    </g>
    <defs>
      <clipPath id="arrow_left_svg__a">
        <path fill="#fff" d="M17.5 2.5v15h-15v-15z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgArrowLeft;
