import { GoogleLogin, PromptMomentNotification } from '@react-oauth/google';
import { useAuthState } from '../hooks/useAuthState';

export function AutoLoginGoogleButton() {
  const {
    onGoogleAutologinSuccess,
    onGoogleAutologinFailure,
    onLogin,
    autologin,
  } = useAuthState();

  const handleNotLogged = (prompt: PromptMomentNotification) => {
    if (prompt.getNotDisplayedReason() === 'opt_out_or_no_session') {
      onLogin({ type: 'google' });
    }
  };

  if (!autologin?.google) return null;

  return (
    <div style={{ display: 'none' }}>
      <GoogleLogin
        auto_select
        onSuccess={onGoogleAutologinSuccess}
        onError={onGoogleAutologinFailure}
        useOneTap
        promptMomentNotification={handleNotLogged}
      />
    </div>
  );
}
