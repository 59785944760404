import styled from '@emotion/styled';
import { useSmartGuides } from '../../context/SmartGuidesProvider';
import { GuideType } from '../../types/SmartGuide';
import { DimensionTags } from '../DimensionTags';
import { Materials } from '../Materials';
import { PreprInnerHtml } from '../PreprInnerHtml';
import { SimpleBlock } from '../SimpleBlock';
import { AssessableSummary } from '../assessables';
import { NewStandardContent } from '../new-standard-content';

const Section = styled.section`
  display: flex;
  gap: 48px;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    gap: 24px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column;

    > div {
      width: 100%;
    }
  }
`;

const SummaryTagsContainer = styled.div`
  display: flex;
  gap: 48px;
  min-width: 50%;
  flex: 1;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    gap: 24px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    flex-direction: column;
    width: 55%;

    > div {
      width: 100%;
    }
  }
`;

const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 60%;
`;

const TagsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 40%;
`;

const MaterialsContainer = styled.div`
  width: 420px;
  min-width: 260px;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    width: auto;
    max-width: 420px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    max-width: 100%;
  }
`;

export function SummarySection() {
  const { t, smartGuide, region, session } = useSmartGuides();

  const summary = smartGuide.summary;

  const isFourInOne =
    'guideType' in smartGuide && smartGuide.guideType === GuideType.FourInOne;

  if (isFourInOne) return null;

  return (
    <Section>
      <SummaryTagsContainer>
        <SummaryContainer>
          {summary.inThisTaller.text && (
            <SimpleBlock id="summary" title={t(summary.inThisTaller.titleKey)}>
              <PreprInnerHtml text={summary.inThisTaller.text} />
            </SimpleBlock>
          )}
          {summary.withIntentionOf.text && (
            <SimpleBlock title={t(summary.withIntentionOf.titleKey)}>
              <PreprInnerHtml text={summary.withIntentionOf.text} />
            </SimpleBlock>
          )}
          {summary.vocabularyKey.text && (
            <SimpleBlock title={t(summary.vocabularyKey.titleKey)}>
              <PreprInnerHtml text={summary.vocabularyKey.text} />
            </SimpleBlock>
          )}
        </SummaryContainer>

        <TagsContainer>
          {summary.assessables.length > 0 && <AssessableSummary />}

          <NewStandardContent summary={summary} />

          <DimensionTags tags={summary.dimensionTags} />
        </TagsContainer>
      </SummaryTagsContainer>

      <MaterialsContainer>
        <Materials {...summary.materials} />
      </MaterialsContainer>
    </Section>
  );
}
