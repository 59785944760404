
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as Part2EarlyYearsIconComponent } from "./part2-early-years-icon.svg";

export const Part2EarlyYearsIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <Part2EarlyYearsIconComponent {...props} />;
};
