import styled from '@emotion/styled';
import {
  Button,
  ButtonProps,
  Modal,
  Rating,
  Typography,
} from '@innovamat/glimmer-components';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';
import {
  AfterReviewContent,
  BeforeReviewContent,
  Content,
  Footer,
  Optional,
  QuestionTextWrapper,
  QuestionWrapper,
  TextArea,
} from './session-done-modal.styles';

type UnlockModalAProps = {
  t: TFunction<'translation', undefined, 'translation'>;
  isOpen: boolean;
  onClose: () => void;
  handleSendReview: () => void;
  applets: ReactNode;
  handleRating: (value: number | null) => void;
  ratingValue: number | null;
  handleReview: (value: string) => void;
  handleErrorReview: (value: string) => void;
  hasAlreadyValue: boolean;
  isOnlyRating: boolean;
  isSendReviewPending: boolean;
  handleResetRating: () => void;
  review: string;
  errorReview: string;
};

const ReviewWrapper = styled.div<{ isReviewed: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  margin-bottom: ${({ isReviewed }) => (isReviewed ? '24px' : 0)};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    align-items: center;
  }
`;

const RatingWrapper = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column;
    align-items: center;
  }
`;

const ReportError = styled(Typography.Link2)`
  cursor: pointer;
  align-self: flex-end;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    align-self: center;
  }
`;

export function UnlockModalB({
  t,
  isOpen,
  onClose,
  handleSendReview,
  applets,
  handleRating,
  ratingValue,
  handleReview,
  handleErrorReview,
  isSendReviewPending,
  handleResetRating,
  review,
  errorReview,
}: UnlockModalAProps): JSX.Element {
  const beforeRef = useRef<HTMLDivElement>(null);
  const afterRef = useRef<HTMLDivElement>(null);
  const [afterHeight, setAfterHeight] = useState<number | undefined>();
  const [beforeHeight, setBeforeHeight] = useState<number | undefined>();

  const [showErrorInput, setShowErrorInput] = useState(false);

  const getShowAppletsZone = () => {
    if (showErrorInput) return false;
    return true;
  };

  const showAppletsContent = getShowAppletsZone();

  useEffect(() => {
    if (!isOpen) return setShowErrorInput(false);

    if (beforeRef.current) {
      setBeforeHeight(beforeRef.current.scrollHeight);
    }

    if (afterRef.current) {
      setAfterHeight(afterRef.current.scrollHeight);
    }
  }, [isOpen, showErrorInput]);

  const handleGoBack = () => {
    setShowErrorInput(false);
    handleResetRating();
  };

  const buttons: ButtonProps[] = useMemo(() => {
    const footerButtons: ButtonProps[] = [
      {
        children: t('session.sesion-review.modal.send'),
        onClick: handleSendReview,
        variant: 'accent',
        disabled: ratingValue === null,
        loading: isSendReviewPending,
      },
    ];
    if (showErrorInput) {
      footerButtons.push({
        children: t('back.button'),
        onClick: handleGoBack,
        variant: 'tertiary',
        disabled: false,
        loading: false,
      });
    }
    return footerButtons;
  }, [
    handleGoBack,
    handleSendReview,
    isSendReviewPending,
    ratingValue,
    showErrorInput,
    t,
  ]);

  return (
    <Modal
      closeOnEsc
      isOpen={isOpen}
      modalWidth="768px"
      onClose={onClose}
      overlayTestId="blockLayoutModal"
      title={t('session.unlockModal.unlockContent.title')}
      closeButton="inner"
      customFooter={
        <Footer>
          {showErrorInput && (
            <Button
              onClick={handleGoBack}
              variant={'tertiary'}
              disabled={false}
              loading={false}
            >
              {t('back.button')}
            </Button>
          )}
          <Button
            onClick={handleSendReview}
            variant={'accent'}
            disabled={ratingValue === null}
            loading={isSendReviewPending}
          >
            {t('session.sesion-review.modal.send')}
          </Button>
        </Footer>
      }
    >
      <Content>
        <BeforeReviewContent
          ref={beforeRef}
          isVisible={showAppletsContent}
          height={beforeHeight!}
        >
          {applets}
        </BeforeReviewContent>

        <ReviewWrapper isReviewed={showErrorInput}>
          <Typography.Subtitle1>
            {t('session.sesion-review.modal.subtitle')}
          </Typography.Subtitle1>
          <RatingWrapper>
            <Rating
              onChange={(_, value) => handleRating(value)}
              value={ratingValue}
            />
            <TextArea
              placeholder={t('session.sesion-review.modal.message-placeholder')}
              rows={1}
              value={review}
              onChange={(e) => handleReview(e.target.value)}
            ></TextArea>
          </RatingWrapper>
          {!showErrorInput && (
            <ReportError onClick={() => setShowErrorInput(true)}>
              {t('session.sesion-review.modal.caseb.error-button')}
            </ReportError>
          )}
        </ReviewWrapper>
        <AfterReviewContent
          ref={afterRef}
          isVisible={showErrorInput}
          height={afterHeight!}
        >
          <QuestionWrapper>
            <QuestionTextWrapper>
              <Typography.Subtitle1>
                <Trans
                  i18nKey="session.sesion-review.modal.error-title"
                  components={{
                    small: <Optional />,
                  }}
                />
              </Typography.Subtitle1>
              <Optional>
                {t('session.sesion-review.modal.error-description')}
              </Optional>
            </QuestionTextWrapper>
            <TextArea
              placeholder={t('session.sesion-review.modal.message-placeholder')}
              rows={3}
              value={errorReview}
              onChange={(e) => handleErrorReview(e.target.value)}
            ></TextArea>
          </QuestionWrapper>
        </AfterReviewContent>
      </Content>
    </Modal>
  );
}
