import React from 'react';
import SelectOption from '../SelectOption';
import { components, OptionProps, ControlProps } from 'react-select';
import Icon from '../Icon';

import styles from './IconSelector.module.scss';
import selectIconsValues from '../../utils/selectIcons';
import { Option } from '../SelectOption/SelectOption';

const iconOptions = selectIconsValues.map(({ key, value }) => ({
  value: value.toString(),
  label: key,
}));

type Props = {
  label?: string;
  value?: string;
  className?: string;
  placeholder?: string;
  noBgColor?: boolean;
  onChange?: (option: Option) => void;
};

const Control = ({ children, ...props }: ControlProps<any, false>) => {
  const value = props.getValue()[0];
  return (
    <components.Control {...props}>
      {value && (
        <Icon size="sm" icon={value.label} containerClassName={styles.icon} />
      )}
      {children}
    </components.Control>
  );
};

function IconSelector({
  value,
  label,
  className,
  placeholder,
  noBgColor,
  onChange,
}: Props) {
  const Option = (props: OptionProps<any, any>) => {
    return (
      <components.Option {...props} className={styles.option}>
        <Icon size="sm" icon={props.children as keyof typeof Icon} />
        <span>{props.children}</span>
      </components.Option>
    );
  };

  return (
    <SelectOption
      placeholder={placeholder}
      noBgColor={noBgColor}
      selectorClassName={className}
      components={{ Option, Control }}
      value={value}
      label={label}
      options={iconOptions}
      onChange={onChange}
    />
  );
}
export default IconSelector;
