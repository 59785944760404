import {
  Typography,
  Tooltip,
  useCheckOverflow,
} from '@innovamat/glimmer-components';
import { useCallback, useState } from 'react';
import styled from '@emotion/styled';

type ClassroomElementProps = {
  text: string;
  rowIsNull: boolean;
  classroomName: string;
  courseOrder: number;
};

const Text = styled(Typography.Body2)<{ isNull: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: default;
  color: ${({ theme, isNull }) =>
    isNull
      ? theme.tokens.color.specific.reports.semantic['no-data'].value
      : theme.tokens.color.alias.cm.text.text.value};
`;

const StyledTooltip = styled(Tooltip)`
  display: block !important;
`;

const TextWrapper = styled.p`
  max-width: 134px;
`;

const ClassroomElement = ({
  text,
  rowIsNull,
  classroomName,
  courseOrder,
}: ClassroomElementProps): JSX.Element => {
  const [textRef, setTextRef] = useState<HTMLParagraphElement | null>(null);

  const onSetTextRef = useCallback((ref: HTMLParagraphElement | null) => {
    setTextRef(ref);
  }, []);

  const { hasOverflow } = useCheckOverflow({
    text: textRef?.children[0],
    container: textRef,
    dependencies: [onSetTextRef, textRef],
  });

  return (
    <StyledTooltip
      content={hasOverflow ? text : undefined}
      popperOptions={{ strategy: 'fixed' }}
    >
      <TextWrapper ref={onSetTextRef}>
        <Text
          isNull={rowIsNull}
          data-testid={`${courseOrder}_${classroomName}_CLASSROOMNAME`}
        >
          {text}
        </Text>
      </TextWrapper>
    </StyledTooltip>
  );
};

export default ClassroomElement;
