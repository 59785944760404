import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import styled from '@emotion/styled';
import { AvatarCard, Dropdown, Input } from '@innovamat/glimmer-components';

import type { Organization } from '@innovamat/glow-api-client';
import { useOrganizationsQuery } from '@innovamat/glow-api-client';

const DropdownContent = styled(Dropdown.Content)`
  min-width: 100% !important;
  width: 100% !important;

  > div {
    max-height: 300px;
    overflow-y: auto;
  }
`;

const SearchedOrganizationOption = ({
  organization,
}: {
  organization: Organization;
}): JSX.Element => {
  return (
    <AvatarCard key={organization?.id}>
      <AvatarCard.Text subtitle={organization?.location || undefined}>
        {organization?.name}
      </AvatarCard.Text>
    </AvatarCard>
  );
};

type Props = {
  onSelect: (organization: Organization) => void;
  defaultValue?: string;
};

const SearchOrganizationInput = ({
  onSelect,
  defaultValue,
}: Props): JSX.Element => {
  const [searchedOrganization, setSearchedOrganization] = useState(
    defaultValue ?? ''
  );
  const { t } = useTranslation();

  const { data: organizationsQueryData } = useOrganizationsQuery({
    name: searchedOrganization,
  });

  const organizations =
    (organizationsQueryData?.organizations as Organization[]) ||
    ([] as Organization[]);

  const handleSelect = (organization: Organization): void => {
    onSelect(organization);
    setSearchedOrganization(organization?.name ?? '');
  };

  return (
    <Dropdown closeOnSelectItem>
      <Dropdown.Toggle>
        <Input
          labelText={t('organizations.searchBox.label')}
          leftIcon="SearchIcon"
          rightIcon={searchedOrganization ? 'RemoveIcon' : undefined}
          onIconClick={() => setSearchedOrganization('')}
          onChange={(e) => setSearchedOrganization(e.target.value)}
          placeholder={t('organizations.searchBox.placeHolder')}
          type="search"
          value={searchedOrganization}
        />
      </Dropdown.Toggle>
      <DropdownContent>
        <div>
          {organizations.length === 0 && (
            <Dropdown.Item state="disabled">
              {t('organizations.searchBox.noResults')}
            </Dropdown.Item>
          )}
          {organizations.map((organization) => (
            <Dropdown.Item
              state="active"
              onSelectItem={() => handleSelect(organization)}
            >
              <SearchedOrganizationOption organization={organization} />
            </Dropdown.Item>
          ))}
        </div>
      </DropdownContent>
    </Dropdown>
  );
};

export { SearchOrganizationInput };
