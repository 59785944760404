import styled from '@emotion/styled';

import { getAcceptLanguage } from '@innovamat/localization';
import { useUpdateEffect } from '@innovamat/hooks';
import useResourceEvents from '../../hooks/useResourceEvents';
import PdfViewer from '../../resources/PdfViewer';
import usePlayerState from '../../providers/PlayerProviders/usePlayerState';
import useSwipe from '../../hooks/useSwipe';
import { getResource } from '../../state/selectors';
import VideoPlayer from '../../resources/VideoPlayer';

import { AppletResource } from '../../resources/AppletResource';

import GeogebraViewer from '../../resources/GeogebraViewer';
import GeniallyViewer from '../../resources/GeniallyViewer';
import ResourceNotification from '../ResourceNotification';
import useVideoContext from '../../providers/VideoProviders/useVideoContext';
import { ManipulativeZone } from '../../resources/ManipulativeZone';
import { WebAppletWrapper } from '../../resources/WebAppletWrapper';

const ResourceWrapper = styled.div<{ isInFullscreen: boolean }>`
  z-index: 0;
  background-color: ${(props) =>
    !props.isInFullscreen && props.theme.colors.dark['05']};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export default function ResourceContent() {
  const {
    currentResource,
    resource,
    currentStep,
    translations,
    fullscreen,
    fullscreenIOS,
    appletInfo,
    setEventData,
    axiosInstance,
    apolloServer,
    videoOptions,
    school,
    uid,
    autoplay,
  } = usePlayerState();

  const { progressList } = useVideoContext();

  const isInFullscreen = !!fullscreen?.active || fullscreenIOS;

  const { handleAppletIsCompleted } = useResourceEvents();
  const subResource = getResource(resource, currentResource);
  const handlers = useSwipe();

  const autoplaySub =
    resource && 'resources' in resource
      ? autoplay || subResource !== resource.resources[0]
      : autoplay;

  useUpdateEffect(() => {
    // TODO: THIS IS RERENDERING THE VIDEO PROVIDER.
    // handleResetVideoPlayer?.();
  }, [currentResource]);

  const thumbnail = (resource as any)?.thumbnail;

  const displayResource = () => {
    if (!subResource) return null;

    if (subResource.isBloqued) {
      return (
        <ResourceNotification
          text={translations['session.bloquedResource.message']}
          dataTestId="warningResourceNotEnabled"
        />
      );
    }

    if ('isManipulative' in subResource) {
      return (
        <ManipulativeZone
          fullscreen={fullscreen}
          key={subResource.id}
          language={subResource.language}
          thumbnail={thumbnail}
          title={subResource.title}
          setEventData={setEventData}
          apolloServer={apolloServer}
          axiosInstance={axiosInstance}
          appletInfo={appletInfo!}
          school={school}
          uid={uid}
        />
      );
    }

    if ('settings' in subResource) {
      return (
        <GeogebraViewer
          isInFullScreen={isInFullscreen}
          key={subResource.file}
          script={subResource.script!}
          file={subResource.file}
          settings={subResource.settings}
          translations={translations}
        />
      );
    }

    if ('pages' in subResource) {
      if (subResource.pages[currentStep].isBloqued) {
        return (
          <ResourceNotification
            text={translations['session.bloquedResource.message']}
            dataTestId="warningResourceNotEnabled"
          />
        );
      }
      return (
        <PdfViewer
          path={subResource.path}
          page={currentStep + 1}
          numPages={subResource.pages.length}
        />
      );
    }

    if ('src' in subResource) {
      return <GeniallyViewer src={subResource.src} />;
    }

    if ('url' in subResource) {
      return (
        <VideoPlayer
          videoId={subResource.id}
          key={subResource.url}
          fullscreen={fullscreen}
          url={subResource.url}
          defaultProgress={
            progressList[subResource.url] || subResource.progress
          }
          videoOptions={videoOptions}
          autoplay={autoplaySub}
          dataTestId="videoPlayerWrapper"
        />
      );
    }

    if ('sceneName' in subResource) {
      if (subResource.status === 'BUGS') {
        return (
          <ResourceNotification
            text={translations['session.buggedApplet.message']}
          />
        );
      }
      // CHECK BY TAG to know if is webcontent or not.
      if (subResource.tags.includes('web_content')) {
        const activityParameters = {
          type: 'web',
          layout: 'web',
          minDuration: 600,
          maxDuration: 1800,
          minStatements: 12,
          id: `${subResource.sceneName!}_${subResource.packOrVariation}`, //TODO: replace for subResource.uuid when possible
        };
        const { regionParameters, webContentsUrl, region, solverUrl } = appletInfo!;
        const language = region
          ? getAcceptLanguage(region, subResource.language!)
          : subResource.language;

        return (
          <WebAppletWrapper
            key={activityParameters.id}
            userParameters={{
              language,
              school,
              uid,
            }}
            regionalParameters={regionParameters}
            activityParameters={activityParameters!}
            onCloseApplet={() => {}}
            onAppletIsCompleted={handleAppletIsCompleted}
            webContentsUrl={webContentsUrl!}
            solverUrl={solverUrl!}
            setEventData={setEventData}
          />
        );
      }
      return (
        <AppletResource
          sceneName={subResource.sceneName!}
          packOrVariation={subResource.packOrVariation!}
          language={subResource.language!}
          onAppletIsCompleted={handleAppletIsCompleted}
          fullscreen={fullscreen}
          appletInfo={appletInfo!}
          thumbnail={thumbnail}
          title={subResource.title}
          setEventData={setEventData}
          apolloServer={apolloServer}
          axiosInstance={axiosInstance}
          school={school}
          uid={uid}
        />
      );
    }
    return null;
  };

  return (
    <ResourceWrapper
      {...handlers}
      isInFullscreen={fullscreen.active || fullscreenIOS}
    >
      {displayResource()}
    </ResourceWrapper>
  );
}
