import styled from '@emotion/styled';

import { Card, ChallengeCheckbox } from '@innovamat/innova-components';

const Wrapper = styled('div')`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Image = styled('img')`
  width: 100%;
  border-radius: 8px;
  aspect-ratio: 16/9;
  object-fit: cover;
`;

const CardWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 0px;
  margin: 0px 24px;
  min-height: 96px;
  width: calc(100% - 48px);
  margin-top: -10%;
  margin-bottom: 4px;

  @media (max-width: 1300px) {
    width: calc(100% - 32px);
    padding: 8px 0px;
    margin: 0px 16px;
    margin-top: -10%;
    margin-bottom: 4px;
  }
`;

const MottoWrapper = styled('div')`
  font-family: ${({ theme }) => theme.typography.medium};
  font-size: ${({ theme }) => theme.sizes.small};
  font-weight: ${({ theme }) => theme.typography.weight.medium};
  line-height: ${({ theme }) => theme.typography.lineHeight.small};
  color: ${({ theme }) => theme.colors.dark['01']};
  text-align: center;
  margin: auto 24px;
  height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: ${(props: any) => props.theme.breakpoints.lg}) {
    margin: auto 12px;
    font-size: ${({ theme }) => theme.sizes.xsmall};
    line-height: ${({ theme }) => theme.typography.lineHeight.xsmall};
  }
  span {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

const HeadingCard = styled('div')`
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark['05']};
  display: flex;
  width: 87%;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  margin-bottom: 16px;
  position: relative;
  @media (max-width: ${(props: any) => props.theme.breakpoints.lg}) {
    padding-bottom: 8px;
    margin-bottom: 8px;
  }
`;

const ChallengeCheckboxStyles = styled(ChallengeCheckbox)`
  position: absolute;
  right: 0;
  z-index: 3;
`;

const ConfettiWrapper = styled.div`
  position: absolute;
  bottom: -64px;
  right: -88px;
  z-index: 4;
`;

export {
  CardWrapper,
  MottoWrapper,
  HeadingCard,
  ChallengeCheckboxStyles,
  Image,
  Wrapper,
  ConfettiWrapper,
};
