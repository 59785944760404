import { Icons, theme } from '@innovamat/innova-components';
import { Group } from '../types/group';
import { ResourceType } from '../types/resourceType';
import { SessionResource } from '../types/session';

export const getColorByGroup = (type: Group) => {
  switch (type) {
    case Group.escalfem:
      return {
        dark: theme.colors.purple['01'],
        medium: theme.colors.purple['02'],
        light: theme.colors.purple['03'],
      };
    case Group.activitat:
      return {
        dark: theme.colors.orange['01'],
        medium: theme.colors.orange['02'],
        light: theme.colors.orange['03'],
      };
    default:
      return {
        dark: theme.colors.blue['01'],
        medium: theme.colors.blue['02'],
        light: theme.colors.blue['03'],
      };
  }
};

export const getPlayerIcon = (
  resource: SessionResource
): keyof typeof Icons => {
  switch (resource.type) {
    case ResourceType.vimeo:
      return 'CameraVideo';
    case ResourceType.manipulativeenvironment:
      return 'Manipulative';
    case ResourceType.applet:
      return 'VideoGameController';
    case ResourceType.genially:
    case ResourceType.pdf:
      return 'CheckList';
    default:
      return 'MonitorTouch';
  }
};

export function isTouchDevice() {
  return (
    !!(
      typeof window !== 'undefined' &&
      ('ontouchstart' in window ||
        ((window as any).DocumentTouch &&
          typeof document !== 'undefined' &&
          document instanceof (window as any).DocumentTouch))
    ) ||
    !!(
      typeof navigator !== 'undefined' &&
      (navigator.maxTouchPoints || (navigator as any).msMaxTouchPoints)
    )
  );
}
