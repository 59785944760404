import { useGlimmerTheme } from '@innovamat/glimmer-components';
import { PrintableBody1 } from '../../printable-styles/printable-typography';
import { PrepElement } from '../types/prepr';
import Block from './Block';
import { InnerHtmlParser } from './InnerHtmlParser';
import styled from '@emotion/styled';

type Props = {
  text: PrepElement;
  title?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const StyledPrintableBody1 = styled(PrintableBody1)`
  @media print {
    color: ${({ theme }) =>
      theme.tokens.color.alias.cm.text['text-subtle'].value};
  }
`;

export default function SimpleBlock({ title, text, ...rest }: Props) {
  const theme = useGlimmerTheme();
  if (!text) return null;

  return (
    <Block title={title} {...rest}>
      <StyledPrintableBody1
        color={theme.tokens.color.alias.cm.text['text-subtle'].value}
        id={`${rest.id}-content`}
      >
        <InnerHtmlParser text={text} />
      </StyledPrintableBody1>
    </Block>
  );
}
