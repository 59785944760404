import { createRoot } from 'react-dom/client';

import Latex from 'react-latex-next';
import { decodeStringWithAscii } from './decodeString';
import 'katex/dist/katex.min.css';

const INITIAL_MACROS = { '\\f': '#1f(#2)' };

export const replaceLatex = (text: string | undefined, id: string): string => {
  if (!text) return '';

  const regex = new RegExp(/##(.*?)##/, 'gm');
  let content = text;
  const expressions = content.match(regex);

  if (!expressions) return content;

  const latexIds: Record<string, string> = {};
  for (let index = 0; index < expressions.length; index++) {
    const expression = expressions[index];

    const cleanedExpression = expression.split('##').join('');

    if (!cleanedExpression) return '';

    const latexId = `latex-${id}-${index}`;
    latexIds[latexId] = cleanedExpression;

    const span = `<span id="${latexId}"></span>`;
    content = content.replace(expression, span);
  }

  requestAnimationFrame(() => {
    Object.entries(latexIds).forEach(([id, value]) => {
      const latexElement = document.getElementById(id);
      if (latexElement) {
        const isFormatted = latexElement?.getAttribute('done');
        if (!isFormatted) {
          latexElement.setAttribute('done', 'true');

          const wrapper = createRoot(latexElement);

          wrapper.render(
            <Latex macros={INITIAL_MACROS}>
              {decodeStringWithAscii(value)}
            </Latex>
          );
        }
      }
    });
  });

  return content;
};
