import IcomoonReact from 'icomoon-react';
import iconSet from '../../assets/icons/selection.json';

const defaultProps = {
  size: '100%',
  className: '',
};

type IconProps = {
  color?: string;
  size?: number | string;
  icon: string;
  className?: string;
};

function Icon(props: IconProps): JSX.Element {
  const { color, size, icon, className } = props;

  return (
    <IcomoonReact
      className={className}
      iconSet={iconSet}
      color={color}
      size={size}
      icon={icon}
    />
  );
}

Icon.defaultProps = defaultProps;

export default Icon;
