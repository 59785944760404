import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSchoolTeacher = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M1.875 3.125a2.5 2.5 0 1 0 5 0 2.5 2.5 0 1 0-5 0M4.375 6.875v5M6.25 19.375l.625-6.25h1.25v-2.5a3.75 3.75 0 0 0-7.5 0v2.5h1.25l.625 6.25ZM10.625 13.75h7.5a1.25 1.25 0 0 0 1.25-1.25V1.875a1.25 1.25 0 0 0-1.25-1.25h-8.75"
      />
    </g>
  </svg>
);
export default SvgSchoolTeacher;
