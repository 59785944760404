import {
  Option,
  ResourceViewer,
  Session as SessionType,
  SingleGeniallyViewer,
  SinglePdfViewer,
} from '@innovamat/resource-viewer';
import { useEffect, useState } from 'react';

import { useFullScreenHandle } from 'react-full-screen';

import styled from '@emotion/styled';
import { useEventLogging } from '@innovamat/event-logging';
import { isEmpty } from '@innovamat/radiance-utils';
import { ScrollPosition } from '@innovamat/smart-guides';
import { LOAD_ACTIVITY, useWebviewBridge } from '@innovamat/webview-bridge';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-domv6';
import { authAxiosInstance } from '../../../../utils';
import { useEnvVariables } from '../../../env-variables-manager';
import { useUser } from '../../../user-management';
import { AssessableResources, RelatedResourcesList } from '../../components';
import { Layout } from '../../components/resource-layout/layout';
import {
  useAppletInfo,
  useConditionalPdfIFrameReloadTimeout,
  usePlatformUrl,
  useRegionParameters,
} from '../../hooks';
import { useHorizontalMenuTabs } from '../../providers';
import { onResourceViewerEvents } from '../../utils/resource-viewer-events';
import './_react-pdf.scss';
import {
  AssessableResourcesWrapper,
  GuideWrapper,
  PlayerSectionWrapper,
  RelatedItemsWrapper,
  SessionContentWrapper,
} from './session-resource.styled';

type Props = {
  session: SessionType;
  onDownloadOption: (option?: Option | undefined) => void;
  translations: {};
  setScrollPosition: (value: ScrollPosition) => void;
  onGoBack: () => void;
};
const AssessableContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 64px;
  margin-top: 40px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    max-width: calc(100vw - 48px);

    > div {
      max-width: inherit;
    }
  }
`;

export function SessionResource({
  session,
  onDownloadOption,
  translations,
  setScrollPosition,
  onGoBack,
}: Props) {
  const { APOLLO_SERVER } = useEnvVariables();
  const handleFS = useFullScreenHandle();
  const { user, isRole, isStudentViewActive } = useUser();
  const { classroomId } = useParams();
  const organization = user?.organization;
  const userId = user?.id;
  const { i18n } = useTranslation();
  const {
    sectionActive,
    isSectionActive,
    changeSectionActive,
    hasLinkedResources,
    hasAssessableResources,
  } = useHorizontalMenuTabs();
  const showBanner = false;
  const appletInfo = useAppletInfo();
  const { setEventData } = useEventLogging();

  const [isPlayerLoaded, setIsPlayerLoaded] = useState(false);
  const [fromStudentView, setFromStudentView] = useState(false);

  useEffect(() => {
    isStudentViewActive ? setFromStudentView(true) : setFromStudentView(false);
  }, [isStudentViewActive]);

  useEffect(() => {
    const url = new URL(window.location.href);
    const listType = url.searchParams.get('listType');
    if (fromStudentView) {
      changeSectionActive('Session');
    } else if (listType && listType === 'assessable') {
      changeSectionActive('Assessment');
    } else {
      changeSectionActive(
        isEmpty(session.guide) || isRole.Student ? 'Session' : 'Guide'
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromStudentView]);

  useEffect(() => {
    if (sectionActive === 'Session') {
      setIsPlayerLoaded(true);
    } else {
      setIsPlayerLoaded(false);
    }
  }, [sectionActive]);

  const isDisplayingGenially =
    isSectionActive('Guide') && session.guide?.type === 'genially';

  const { isInWebview, sendEventToWebview } = useWebviewBridge();
  const { regionParameters } = useRegionParameters();
  const { platformUrl } = usePlatformUrl();

  const handleOpenApplet = (
    packOrVariation: string | number,
    sceneName: string
  ) => {
    isInWebview &&
      regionParameters &&
      sendEventToWebview(LOAD_ACTIVITY, {
        sceneName: sceneName,
        packOrVariation: packOrVariation,
        regionParameters,
        platformApiUrl: platformUrl,
        accessToken: appletInfo?.accessToken,
        refreshToken: appletInfo?.refreshToken,
        idToken: appletInfo?.idToken,
        language: user?.locale!,
        isSecondary: true,
      });
  };

  const retryPdfReload = useConditionalPdfIFrameReloadTimeout();

  useEffect(() => {
    if (isInWebview) {
      retryPdfReload();
    }
  }, [retryPdfReload, isInWebview]);

  return (
    <Layout
      title={session.title}
      description={session.description}
      id={session.id}
      printables={session.printables}
      handleDownloadOption={onDownloadOption}
      setScrollPosition={setScrollPosition}
      isSessionLayout={true}
      onFullScreenButtonClick={
        isDisplayingGenially ? () => handleFS.enter() : undefined
      }
      onGoBack={onGoBack}
    >
      <SessionContentWrapper>
        {isSectionActive('Guide') && (
          <GuideWrapper>
            {session.guide?.type === 'pdf' ? (
              <>
                {isInWebview ? (
                  <div style={{ position: 'relative' }}>
                    <div
                      style={{
                        position: 'absolute',
                        top: '12px',
                        right: '12px',
                        width: '40px',
                        height: '40px',
                        backgroundColor: '#D1D1D1',
                      }}
                    />
                    <iframe
                      title="pdf"
                      frameBorder="0"
                      scrolling="yes"
                      height="100%"
                      width="100%"
                      style={{ height: '70vh' }}
                      id="pdfIframe"
                      src={`https://docs.google.com/viewer?url=${encodeURIComponent(
                        session.guide.path
                      )}&hl=${
                        i18n.language
                      }&pid=explorer&efh=false&a=v&chrome=false&embedded=true`}
                    />
                  </div>
                ) : (
                  <SinglePdfViewer
                    path={session.guide.path}
                    translations={translations}
                    isInWebview={isInWebview}
                  />
                )}
              </>
            ) : (
              <SingleGeniallyViewer
                fullScreen={handleFS}
                src={session.guide?.path!}
                isGuide={showBanner}
              />
            )}
          </GuideWrapper>
        )}
        {(isSectionActive('Session') || isPlayerLoaded) && (
          <PlayerSectionWrapper>
            <ResourceViewer
              resource={session}
              translations={translations}
              onDispatchEvent={(prevState, state, action) =>
                onResourceViewerEvents({
                  prevState,
                  state,
                  action,
                  setEventData,
                })
              }
              apolloServer={APOLLO_SERVER}
              appletInfo={{
                ...appletInfo,
                onlyShowPlayButton: isInWebview,
                handlePlayButton: handleOpenApplet,
              }}
              axiosInstance={authAxiosInstance}
              setEventData={setEventData}
              videoOptions={{
                transparent: !isInWebview,
                playsinline: isInWebview ? 1 : 0,
              }}
              school={organization?.id!}
              uid={userId!}
            />
          </PlayerSectionWrapper>
        )}
        {isSectionActive('Assessment') && hasAssessableResources && (
          <AssessableResourcesWrapper>
            <AssessableContainer id="assessment-tab">
              <AssessableResources
                assessableLists={session.assessableLists || []}
                sessionName={session.title}
                sessionId={session.id}
                classroomId={classroomId || ''}
                setEventData={setEventData}
                academicYearId={organization?.academicYearId || ''}
                isStudent={isRole.Student}
                language={session.language}
              />
            </AssessableContainer>
          </AssessableResourcesWrapper>
        )}
        {isSectionActive('Related') && hasLinkedResources && (
          <RelatedItemsWrapper>
            <RelatedResourcesList
              linkedLists={session.relatedLists || session.linkedLists}
              sessionName={session.title}
              sessionId={session.id}
            />
          </RelatedItemsWrapper>
        )}
      </SessionContentWrapper>
    </Layout>
  );
}
