import React from 'react';
import cx from 'classnames';
import SubLabel from '../SubLabel/SubLabel';
import styles from './Input.module.scss';
import Icon from '../Icon';
import * as Icons from '../SvgIcons';
import Tooltip from '../Tooltip';
import styled from '@emotion/styled';

const ErroWrapper = styled.div`
  display: flex;
  gap: 4px;
`;

type Error = {
  message: string | undefined;
};

type Props = {
  label?: string;
  register?: any;
  required?: boolean;
  error?: Error;
  description?: string;
  icon?: keyof typeof Icons | undefined;
  iconPosition?: 'left' | 'right';
  iconContainerClassName?: string;
  iconClickable?: boolean;
  iconTooltip?: string;
  iconAction?: () => void;
  optionalText?: string;
  errorWithIcon?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

const Input = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    register,
    required,
    label,
    name,
    error,
    description,
    icon,
    iconPosition = 'right',
    iconContainerClassName,
    iconClickable,
    iconTooltip,
    iconAction,
    optionalText,
    errorWithIcon = false,
    ...rest
  } = props;

  const containerClass = cx(styles.inputContainer, {
    [styles.hasError]: error?.message,
  });

  const iconContainerClass = cx(
    {
      [iconContainerClassName as string]: !!iconContainerClassName,
      [styles[iconPosition]]: !!icon,
      [styles.withLabel]: !!label,
      [styles.withTooltip]: !!iconTooltip,
    },
    styles.iconContainer
  );

  const inputClass = cx(styles.input, {
    [styles[`icon${iconPosition}`]]: !!icon,
  });

  const iconClass = cx({
    [styles.clickable]: iconClickable,
  });

  return (
    <div className={containerClass}>
      {error?.message ? (
        <ErroWrapper>
          {errorWithIcon && <Icon icon="Error" size="sm" />}
          <SubLabel>{error.message}</SubLabel>
        </ErroWrapper>
      ) : (
        description && <SubLabel>{description}</SubLabel>
      )}

      <input className={inputClass} ref={ref} {...rest} />

      {label && (
        <div>
          <label className={styles.label} htmlFor={name}>
            {label}
          </label>
          {!required && (
            <SubLabel className={styles.optionalText}>{optionalText}</SubLabel>
          )}
        </div>
      )}
      {icon &&
        (iconTooltip ? (
          <Tooltip content={iconTooltip}>
            <div className={iconContainerClass}>
              <Icon
                icon={icon}
                className={iconClass}
                onClick={() => iconAction?.()}
              />
            </div>
          </Tooltip>
        ) : (
          <div className={iconContainerClass}>
            <Icon
              icon={icon}
              className={iconClass}
              onClick={() => iconAction?.()}
            />
          </div>
        ))}
    </div>
  );
});

export default Input;
