import styled from '@emotion/styled';
import { Icon } from '../Icon';
import { MouseEvent, useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';

import { useIsExpandable } from './useIsExpandable';
import { useEventListener } from '@innovamat/hooks';

enum AccordionSizes {
  S = 16,
  M = 24,
}

const AccordionWrapper = styled.div<{
  size: AccordionSize;
  isExpanded: boolean;
  isInteractive: boolean;
  isExpandable: boolean;
}>`
  overflow: hidden;
  pointer-events: ${({ isInteractive, isExpandable }) =>
    isInteractive && isExpandable ? 'auto' : 'none'};
  cursor: ${({ isInteractive, isExpandable }) =>
    isInteractive && isExpandable ? 'pointer' : 'default'};

  background: ${({ theme }) =>
    theme.tokens.color.alias.cm.surface['surface-primary'].value};
  outline: solid 1px
    ${({ theme }) => theme.tokens.color.alias.cm.border['border-subtle'].value};
  outline-offset: -1px;
  padding: ${({ size }) =>
    size === 'M'
      ? `${AccordionSizes[size]}px`
      : `12px ${AccordionSizes[size]}px`};
  border-radius: 4px;
  position: relative;

  @media print {
    padding: 8px;
  }

  :hover {
    box-shadow: 0px 2px 4px 0px #0000003d;
    transition: box-shadow 0.2s ease-in-out;
  }
`;

const AccordionDetails = styled.div<{
  isExpanded: boolean;
  size: AccordionSize;
  contentHeight: number;
  hasPreview: boolean;
  minPreviewHeight: number;
  hasAccordionsInside: boolean;
}>`
  cursor: default;
  max-height: 0px;
  opacity: 0;
  box-sizing: border-box;
  transition: all 0.15s ease;
  overflow: hidden;

  .mediaContainer {
    margin: 16px 0;

    @media print {
      margin: 8px 0;
    }
  }

  > div:first-of-type {
    padding-top: ${({ size }) => (size === 'M' ? '32px' : '8px')};
    padding-right: 8px;

    @media print {
      padding-top: 4px;
      padding-right: 2px;
    }
  }

  ${({ hasPreview, isExpanded, minPreviewHeight }) =>
    hasPreview &&
    !isExpanded &&
    css`
      max-height: ${minPreviewHeight}px;
      opacity: 1;

      > div {
        div,
        p,
        span {
          visibility: visible;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        span[done] {
          overflow: visible;

          span {
            overflow: visible;
          }
        }

        img,
        ul,
        table {
          visibility: hidden;
        }
      }
    `}

  ${({ hasPreview }) =>
    hasPreview &&
    css`
      .mediaContainer {
        margin: 24px 0;

        @media print {
          margin: 12px 0;
        }
      }
    `}

  ${({ isExpanded, hasAccordionsInside, contentHeight }) =>
    isExpanded &&
    css`
      opacity: 1;
      max-height: ${hasAccordionsInside ? 'fit-content' : `${contentHeight}px`};

      @media print {
        max-height: fit-content;
      }
    `}
`;

const AccordionSummary = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const IconContainer = styled.div<{ isExpanded: boolean }>`
  transform: ${({ isExpanded }) =>
    isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'};
  transition: transform 0.2s ease-in-out;
  margin-left: 24px;
`;

type AccordionSize = keyof typeof AccordionSizes;

export type AccordionProps = {
  size?: AccordionSize;
  isExpanded?: boolean;
  onExpand?: (value: boolean) => void;
  summary: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  hasPreview?: boolean;
  isInteractive?: boolean;
};

export function Accordion({
  size = 'M',
  isExpanded,
  onExpand,
  summary,
  children,
  className,
  hasPreview = false,
  isInteractive = true,
}: AccordionProps): JSX.Element {
  const contentRef = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(!!isExpanded);
  const [contentHeight, setContentHeight] = useState(0);
  const [previewHeight, setPreviewHeight] = useState(0);
  const [showEllipsis, setShowEllipsis] = useState(false);
  const isExpandable = useIsExpandable(
    hasPreview,
    expanded,
    contentRef,
    previewHeight
  );

  const hasAccordionsInside = !!contentRef.current?.querySelector(
    "[data-testid='accordion']"
  );

  const handleHeight = (): void => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.offsetHeight + 8);

      if (hasPreview) {
        const lineHeight = Number(
          window
            .getComputedStyle(contentRef.current.children[0], null)
            .getPropertyValue('line-height')
            .replace('px', '')
        );

        setPreviewHeight(lineHeight + 8);

        const firstElement =
          contentRef.current.children[0].children[0].children[0];

        const ellipsableElements = ['UL', 'OL', 'TABLE', 'IMG'];
        const firstElementIsEllipsable = ellipsableElements.includes(
          firstElement?.tagName
        );

        setShowEllipsis(hasPreview && !expanded && firstElementIsEllipsable);
      }
    }
  };

  useEffect(() => {
    handleHeight();
  }, [expanded, hasPreview]);

  useEventListener('resize', handleHeight);

  const handleOnClick = (e: MouseEvent): void => {
    if (window.getSelection()!.toString()) return;

    e.stopPropagation();
    const prevValue = !expanded;
    setExpanded(prevValue);
    onExpand?.(prevValue);
  };

  if (isExpanded !== undefined && isExpanded !== expanded) {
    setExpanded(!!isExpanded);
  }

  return (
    <AccordionWrapper
      size={size}
      isExpanded={expanded}
      className={className}
      isInteractive={isInteractive}
      isExpandable={isExpandable}
      data-testid="accordion"
      aria-expanded={expanded}
      onClick={(e) => handleOnClick(e)}
    >
      <AccordionSummary>
        {summary}
        {isExpandable && isInteractive && (
          <IconContainer isExpanded={expanded}>
            <Icon icon="ExpandMoreIcon" size={size} />
          </IconContainer>
        )}
      </AccordionSummary>
      <AccordionDetails
        size={size}
        minPreviewHeight={previewHeight}
        hasPreview={hasPreview}
        isExpanded={expanded}
        contentHeight={contentHeight}
        hasAccordionsInside={hasAccordionsInside}
      >
        {showEllipsis && <div>...</div>}
        <div ref={contentRef}>{children}</div>
      </AccordionDetails>
    </AccordionWrapper>
  );
}
