/* eslint-disable react-hooks/rules-of-hooks */
import styled from '@emotion/styled';
import {
  Avatar,
  Button,
  Icon,
  IconButton,
  Input,
  Popover,
  Skeleton,
  ToggleButton,
  Tooltip,
  Typography,
  useGlimmerTheme,
} from '@innovamat/glimmer-components';
import { dates, Stage } from '@innovamat/radiance-utils';
import { TFunction } from 'i18next';
import { HTMLInputTypeAttribute } from 'react';
import { StudentStatusCell } from '../../../components/common-table-components/student-status-cell'; // TODO: glimmerize this

type Translate = TFunction<'translation', undefined, 'translation'>;
type Option = { value: string | number; label: string };

type Parent = {
  id: string;
  userInfo: {
    email: string;
    lastLogin: string;
    firstName: string;
    lastName: string;
    token: string;
  };
};

const TEMPLATE_ID = 'template';

const columnWidth = {
  15: '15%',
  20: '20%',
  25: '25%',
  30: '30%',
  33: '33%',
  40: '40%',
  50: '50%',
};

const DropDownComponent = ({
  label,
  options,
  handleInputChange,
  isDisabled,
  inputKey,
  studentId,
}: {
  label: string;
  options: Option[];
  handleInputChange: (key: string, value: any, selectedId?: string) => void;
  studentId: string;
  isDisabled?: boolean;
  inputKey: string;
}): JSX.Element => {
  return (
    <Popover
      style={{ overflow: 'hidden' }}
      trigger={
        <Button
          size={'M'}
          variant={'tertiary'}
          rightIcon="ExpandMoreIcon"
          disabled={isDisabled}
          oneLine
          fill
          className="table-button"
        >
          {label}
        </Button>
      }
    >
      {options.map((option) => (
        <Popover.Item
          key={option.value}
          onSelectItem={() =>
            handleInputChange(inputKey, option.value, studentId)
          }
          state={label === option.label ? 'selected' : 'active'}
        >
          {option.label}
        </Popover.Item>
      ))}
    </Popover>
  );
};

type ButtonInputComponentProps = {
  type?: HTMLInputTypeAttribute;
  studentId: string;
  inputKey: string;
  value: string | number;
  handleSelect: (studentId: string, inputKey: string) => void;
  handleInputChange: (key: string, value: any, selectedId?: string) => void;
  handleBlurInput: () => void;
  isSelected: boolean;
  isError?: boolean;
  isFakeFocus?: boolean;
  isInvitation?: boolean;
  isClassroomImportedFromGoogle?: boolean;
};

const ButtonInputComponent = ({
  type,
  studentId,
  inputKey,
  value,
  handleSelect,
  handleInputChange,
  handleBlurInput,
  isSelected,
  isError,
  isFakeFocus,
  isInvitation,
  isClassroomImportedFromGoogle,
}: ButtonInputComponentProps): JSX.Element => {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const INPUT_MAX_LENGTH = 36;
    const PIN_CODE_MAX_LENGTH = 9;

    if (inputKey === 'pinCode' && e.target.value.length > PIN_CODE_MAX_LENGTH)
      return;

    if (e.target.value.length > INPUT_MAX_LENGTH) return;

    handleInputChange(inputKey, e.target.value);
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (inputKey !== 'pinCode') return;
    if (!/^[0-9]$/.test(e.key) && e.key !== 'Backspace') {
      e.preventDefault(); // Previene la entrada de caracteres no numéricos
    }
  };

  if (isClassroomImportedFromGoogle || isInvitation) {
    return <>{isInvitation ? <SubtleText>{'-'}</SubtleText> : value}</>;
  }

  return isSelected ? (
    <StyledInput
      type={type}
      value={value}
      onChange={handleOnChange}
      onKeyDown={handleOnKeyDown}
      onBlur={handleBlurInput}
      status={isError ? 'error' : undefined}
      className="table-input"
      autoFocus
    />
  ) : (
    <Button
      variant="tertiary"
      onClick={() => handleSelect(studentId, inputKey)}
      fill
      className={`table-button ${isFakeFocus ? 'fake-focus' : ''}`}
    >
      {value}
    </Button>
  );
};

const renderAvatar = ({
  avatar,
  id,
  firstName,
  handleOpenAvatarEditor,
}: {
  avatar?: string;
  id?: string;
  firstName?: string;
  handleOpenAvatarEditor: (id: string, img: string | null | undefined) => void;
}): JSX.Element | null => {
  const isTemplateStudent = id === TEMPLATE_ID;
  if (isTemplateStudent) return null;

  if (avatar) {
    return (
      <Avatar
        img={avatar}
        styles={{ margin: '0 auto' }}
        alt={`student-${firstName}`}
        onClick={() => handleOpenAvatarEditor(id!, avatar)}
      />
    );
  } else {
    return (
      <IconButton
        icon="AddPictureIcon"
        size="L"
        onClick={() => handleOpenAvatarEditor(id!, avatar)}
      />
    );
  }
};

const renderStudentName = ({
  id,
  firstName,
  lastName,
  alias,
  isInvitation,
  t,
  isHeader,
  selectedStudentId,
  selectedInputKey,
  isAnonymized,
  handleSelect,
  handleInputChange,
  handleBlurInput,
  isClassroomImportedFromGoogle,
}: {
  id?: string;
  firstName?: string;
  lastName?: string;
  alias?: string;
  isInvitation?: boolean;
  isHeader?: boolean;
  selectedStudentId: string;
  selectedInputKey: string;
  isClassroomImportedFromGoogle?: boolean;
  isAnonymized?: boolean;
  t: Translate;
  handleSelect: (studentId: string, inputKey: string) => void;
  handleInputChange: (key: string, value: any, selectedId?: string) => void;
  handleBlurInput: () => void;
}): JSX.Element => {
  const isNameSelected =
    selectedStudentId === id && selectedInputKey === 'firstName';
  const isLastNameSelected =
    selectedStudentId === id && selectedInputKey === 'lastName';
  const isAliasSelected =
    selectedStudentId === id && selectedInputKey === 'alias';

  const isTemplateStudent = id === TEMPLATE_ID;

  return (
    <FlexContainer>
      <StyledSpan width={isAnonymized ? columnWidth[33] : columnWidth[50]}>
        {isHeader ? (
          t('reports.name.title.column')
        ) : (
          <ButtonInputComponent
            handleInputChange={handleInputChange}
            inputKey="firstName"
            value={firstName || ''}
            studentId={id!}
            handleSelect={handleSelect}
            handleBlurInput={handleBlurInput}
            isSelected={isNameSelected}
            isError={firstName === ''}
            isInvitation={isInvitation}
            isClassroomImportedFromGoogle={isClassroomImportedFromGoogle}
          />
        )}
      </StyledSpan>
      <StyledSpan width={isAnonymized ? columnWidth[33] : columnWidth[50]}>
        {isHeader ? (
          t('reports.surname.title.column')
        ) : (
          <ButtonInputComponent
            handleInputChange={handleInputChange}
            inputKey="lastName"
            value={lastName || ''}
            studentId={id!}
            handleSelect={handleSelect}
            handleBlurInput={handleBlurInput}
            isSelected={isLastNameSelected}
            isError={lastName === '' || !lastName}
            isFakeFocus={isTemplateStudent}
            isInvitation={isInvitation}
            isClassroomImportedFromGoogle={isClassroomImportedFromGoogle}
          />
        )}
      </StyledSpan>

      {isAnonymized && (
        <StyledSpan width={columnWidth[33]}>
          {isHeader ? (
            t('reports.alias.title.column', 'Alias')
          ) : (
            <ButtonInputComponent
              handleInputChange={handleInputChange}
              inputKey="alias"
              value={alias?.toUpperCase() || ''}
              studentId={id!}
              handleSelect={handleSelect}
              handleBlurInput={handleBlurInput}
              isSelected={isAliasSelected}
              isInvitation={isInvitation}
              isClassroomImportedFromGoogle={isClassroomImportedFromGoogle}
            />
          )}
        </StyledSpan>
      )}
    </FlexContainer>
  );
};

const renderStudentNameSkeleton = (isAnonymized?: boolean): JSX.Element => {
  return (
    <FlexContainer>
      <StyledSpan width={isAnonymized ? columnWidth[33] : columnWidth[50]}>
        <Skeleton width={'100%'} height={'24px'} radius={'4px'} />
      </StyledSpan>
      <StyledSpan width={isAnonymized ? columnWidth[33] : columnWidth[50]}>
        <Skeleton width={'100%'} height={'24px'} radius={'4px'} />
      </StyledSpan>
      {isAnonymized && (
        <StyledSpan width={columnWidth[33]}>
          <Skeleton width={'100%'} height={'24px'} radius={'4px'} />
        </StyledSpan>
      )}
    </FlexContainer>
  );
};

const TooltipIcon = ({ t }: { t: Translate }): JSX.Element => {
  const theme = useGlimmerTheme();
  return (
    <Tooltip content={t('students.table.familyEmails.tooltip')}>
      <Icon
        size="M"
        icon="WarningIcon"
        iconColor={theme.tokens.color.alias.cm.icon['icon-inverted'].value}
        bgColor={theme.tokens.color.alias.cm.icon['icon-warning'].value}
      />
    </Tooltip>
  );
};

const renderHomeAccessSublabel = ({
  t,
  classroomHasDigitalPracticeAccess,
  handleOpenDigitalPracticeAccessModal,
}: {
  t: Translate;
  classroomHasDigitalPracticeAccess: boolean;
  handleOpenDigitalPracticeAccessModal: () => void;
}): JSX.Element => {
  return (
    <FlexContainer>
      <SublabelStyledSpan width={columnWidth[30]}>
        <DigitalPracticeSublabel>
          <IconButton
            icon="SettingsIcon"
            onClick={handleOpenDigitalPracticeAccessModal}
            size="S"
          />
          <span>{t('students.table.labels.digitalPracticeAccess')}</span>
        </DigitalPracticeSublabel>
      </SublabelStyledSpan>
      <SublabelStyledSpan
        width={columnWidth[30]}
        notVisible={!classroomHasDigitalPracticeAccess}
      >
        {t('students.table.labels.weeklyTime')}
      </SublabelStyledSpan>
      <SublabelStyledSpan
        width={columnWidth[40]}
        notVisible={!classroomHasDigitalPracticeAccess}
      >
        {t('students.table.labels.familyEmails')}
      </SublabelStyledSpan>
    </FlexContainer>
  );
};

const getParentEmails = (parents: Parent[] = [], t: Translate) => {
  if (parents?.length === 0 || !parents) {
    return t('students.table.addEmails');
  } else if (parents?.length === 1) {
    return parents?.map((parent) => parent.userInfo.email);
  } else {
    return `${parents?.length} ${t('students.table.emails')}`;
  }
};

const renderHomeAccess = ({
  t,
  id,
  homeAccess,
  weeklyTimeLimit,
  trainingAccess,
  trainingTimeLimit,
  parents,
  individualAccess,
  handleInputChange,
  isInvitation,
  weeklyTimeLimitOptions,
  trainingTimeLimitOptions,
  classroomHasDigitalPracticeAccess,
  classroomHasHomeAccess,
  handleToggleDigitalPracticeAccess,
  handleInviteParents,
}: {
  t: Translate;
  id?: string;
  homeAccess?: boolean;
  weeklyTimeLimit?: number;
  trainingAccess?: boolean;
  trainingTimeLimit?: number;
  parents?: Parent[];
  individualAccess?: boolean;
  handleInputChange: (key: string, value: any, selectedId?: string) => void;
  isInvitation?: boolean;
  weeklyTimeLimitOptions: Option[];
  trainingTimeLimitOptions: Option[];
  classroomHasDigitalPracticeAccess: boolean;
  classroomHasHomeAccess: boolean;
  handleToggleDigitalPracticeAccess: (value: boolean, id: string) => void;
  handleInviteParents: (id: string) => void;
}): JSX.Element => {
  const weeklyTimeLimitLabel = weeklyTimeLimitOptions?.find(
    (option) => option.value === weeklyTimeLimit
  )?.label;

  const trainingTimeLimitLabel = trainingTimeLimitOptions?.find(
    (option) => option.value === trainingTimeLimit
  )?.label;

  const label = classroomHasHomeAccess
    ? weeklyTimeLimitLabel
    : trainingTimeLimitLabel;

  const toggleIsActive = classroomHasDigitalPracticeAccess
    ? classroomHasHomeAccess
      ? homeAccess
      : trainingAccess
    : false;

  const isTemplateStudent = id === TEMPLATE_ID;

  return (
    <FlexContainer>
      <StyledSpan width={columnWidth[30]} notVisible={isTemplateStudent}>
        <ToggleButton
          style={{ alignSelf: 'center' }}
          isActive={toggleIsActive}
          onToggle={(value) => handleToggleDigitalPracticeAccess(value, id!)}
        />
      </StyledSpan>
      <StyledSpan
        width={columnWidth[30]}
        notVisible={!classroomHasDigitalPracticeAccess || isTemplateStudent}
      >
        <DropDownComponent
          isDisabled={!homeAccess && !trainingAccess}
          label={label || ''}
          options={
            classroomHasHomeAccess
              ? weeklyTimeLimitOptions
              : trainingTimeLimitOptions
          }
          handleInputChange={handleInputChange}
          studentId={id!}
          inputKey={
            classroomHasHomeAccess ? 'weeklyTimeLimit' : 'trainingTimeLimit'
          }
        />
      </StyledSpan>
      <StyledSpan
        width={columnWidth[40]}
        notVisible={!classroomHasDigitalPracticeAccess || isTemplateStudent}
      >
        {
          <>
            <Button
              className="table-button"
              disabled={isInvitation || (!homeAccess && !trainingAccess)}
              size={'M'}
              variant={'tertiary'}
              oneLine
              fill={parents?.length === 1}
              onClick={() => handleInviteParents(id!)}
              rightIcon={parents?.length === 0 ? 'AddUpIcon' : undefined}
            >
              {getParentEmails(parents, t)}
            </Button>
            {parents?.length === 0 &&
              (homeAccess || trainingAccess) &&
              !individualAccess && <TooltipIcon t={t} />}
          </>
        }
      </StyledSpan>
    </FlexContainer>
  );
};

const renderHomeAccessSkeleton = (
  classroomHasDigitalPracticeAccess: boolean
): JSX.Element => {
  return (
    <FlexContainer>
      <StyledSpan width={columnWidth[30]}>
        <Skeleton width={'100%'} height={'24px'} radius={'4px'} />
      </StyledSpan>
      {classroomHasDigitalPracticeAccess && (
        <>
          <StyledSpan width={columnWidth[30]}>
            <Skeleton width={'100%'} height={'24px'} radius={'4px'} />
          </StyledSpan>
          <StyledSpan width={columnWidth[40]}>
            <Skeleton width={'100%'} height={'24px'} radius={'4px'} />
          </StyledSpan>
        </>
      )}
    </FlexContainer>
  );
};

const renderDigitalPracticeSubLabel = ({
  t,
  stage,
}: {
  t: Translate;
  stage: string | null;
}): JSX.Element => {
  if (stage === Stage.INFANTIL)
    return (
      <FlexContainer>
        <SublabelStyledSpan width={columnWidth[20]}>
          {t('students.table.labels.educationalPlan')}
        </SublabelStyledSpan>
        <SublabelStyledSpan width={columnWidth[20]}>
          {t('students.table.labels.language')}
        </SublabelStyledSpan>
        <SublabelStyledSpan width={columnWidth[20]}>
          {t('students.table.labels.homeAccess')}
        </SublabelStyledSpan>
        <SublabelStyledSpan width={columnWidth[20]}>
          {t('students.table.labels.weeklyTimeLimit')}
        </SublabelStyledSpan>
        <SublabelStyledSpan width={columnWidth[20]}>
          {t('students.table.labels.familyEmails')}
        </SublabelStyledSpan>
      </FlexContainer>
    );
  else if (stage === Stage.PRIMARIA)
    return (
      <FlexContainer>
        <SublabelStyledSpan width={columnWidth[20]}>
          {t('students.table.labels.educationalPlan')}
        </SublabelStyledSpan>
        <SublabelStyledSpan width={columnWidth[20]}>
          {t('students.table.labels.language')}
        </SublabelStyledSpan>
        <SublabelStyledSpan width={columnWidth[20]}>
          {t('students.table.labels.access-pin')}
        </SublabelStyledSpan>
        <SublabelStyledSpan width={columnWidth[40]}>
          {t('students.table.labels.studentEmail')}
        </SublabelStyledSpan>
      </FlexContainer>
    );
  else if (stage === Stage.SECUNDARIA)
    return (
      <FlexContainer>
        <SublabelStyledSpan width={columnWidth[40]}>
          {t('Settings.Email')}
        </SublabelStyledSpan>
        <SublabelStyledSpan width={columnWidth[15]}>
          {t('students.table.labels.educationalPlan')}
        </SublabelStyledSpan>
        <SublabelStyledSpan width={columnWidth[15]}>
          {t('students.table.labels.language')}
        </SublabelStyledSpan>
        <SublabelStyledSpan width={columnWidth[20]}>
          {t('common.status')}
        </SublabelStyledSpan>
      </FlexContainer>
    );
  return <></>;
};

const renderPrimaryDigitalPractice = ({
  t,
  pinCode,
  language,
  email,
  educationalPlanId,
  id,
  isInvitation,
  educationOptions,
  handleInputChange,
  availableLanguages,
  individualAccess,
  handleSelect,
  selectedStudentId,
  selectedInputKey,
  handleBlurInput,
  handleToggleIndividualAccess,
  isClassroomImportedFromGoogle,
}: {
  t: Translate;
  pinCode?: number;
  language?: string;
  email?: string;
  educationalPlanId?: string;
  isInvitation?: boolean;
  individualAccess?: boolean;
  id?: string;
  educationOptions: Option[];
  handleInputChange: (key: string, value: any, selectedId?: string) => void;
  availableLanguages: Option[];
  selectedStudentId: string;
  selectedInputKey: string;
  handleSelect: (studentId: string, inputKey: string) => void;
  handleBlurInput: () => void;
  handleToggleIndividualAccess: (value: boolean, id: string) => void;
  stage: string | null;
  isClassroomImportedFromGoogle: boolean;
}): JSX.Element => {
  const educationalPlan = getEducationPlan(educationalPlanId, educationOptions);
  const educationalPlanLabel = educationOptions.find(
    (option) => option.value === educationalPlan
  )?.label;

  const languageLabel = availableLanguages.find(
    (option) => option.value === language
  )?.label;

  const isTemplateStudent = id === TEMPLATE_ID;

  return (
    <FlexContainer>
      <StyledSpan width={columnWidth[20]} notVisible={isTemplateStudent}>
        <DropDownComponent
          isDisabled={isInvitation}
          label={educationalPlanLabel || ''}
          options={educationOptions}
          handleInputChange={handleInputChange}
          studentId={id!}
          inputKey="educationalPlanId"
        />
      </StyledSpan>
      <StyledSpan width={columnWidth[20]} notVisible={isTemplateStudent}>
        <DropDownComponent
          isDisabled={isInvitation}
          label={languageLabel || ''}
          options={availableLanguages}
          handleInputChange={handleInputChange}
          studentId={id!}
          inputKey="language"
        />
      </StyledSpan>
      <StyledSpan width={columnWidth[20]} notVisible={isTemplateStudent}>
        <ButtonInputComponent
          type="number"
          handleInputChange={handleInputChange}
          inputKey="pinCode"
          value={pinCode || ''}
          studentId={id!}
          handleSelect={handleSelect}
          handleBlurInput={handleBlurInput}
          isSelected={
            selectedStudentId === id && selectedInputKey === 'pinCode'
          }
        />
      </StyledSpan>
      <StyledSpan width={columnWidth[40]} notVisible={isTemplateStudent}>
        <ToggleButton
          disabled={isClassroomImportedFromGoogle}
          isActive={individualAccess}
          onToggle={(value) => handleToggleIndividualAccess(value, id!)}
        />
        {individualAccess ? (
          <StyledTooltip content={email}>
            <EmailField>{email}</EmailField>
          </StyledTooltip>
        ) : (
          ''
        )}
      </StyledSpan>
    </FlexContainer>
  );
};

const renderEyDigitalPractice = ({
  t,
  language,
  parents,
  weeklyTimeLimit,
  educationalPlanId,
  id,
  isInvitation,
  educationOptions,
  handleInputChange,
  availableLanguages,
  homeAccess,
  handleToggleHomeAccess,
  handleInviteParents,
  weeklyTimeLimitOptions,
}: {
  t: Translate;
  language?: string;
  email?: string;
  parents?: Parent[];
  weeklyTimeLimit?: number;
  educationalPlanId?: string;
  isInvitation?: boolean;
  homeAccess?: boolean;
  id?: string;
  educationOptions: Option[];
  handleInputChange: (key: string, value: any, selectedId?: string) => void;
  availableLanguages: Option[];
  selectedStudentId: string;
  selectedInputKey: string;
  handleSelect: (studentId: string, inputKey: string) => void;
  handleBlurInput: () => void;
  handleToggleHomeAccess: (value: boolean, id: string) => void;
  handleInviteParents: (studentId: string) => void;
  weeklyTimeLimitOptions: Option[];
}): JSX.Element => {
  const educationalPlan = getEducationPlan(educationalPlanId, educationOptions);
  const educationalPlanLabel = educationOptions.find(
    (option) => option.value === educationalPlan
  )?.label;

  const languageLabel = availableLanguages.find(
    (option) => option.value === language
  )?.label;

  const weeklyTimeLimitLabel = weeklyTimeLimitOptions?.find(
    (option) => option.value === weeklyTimeLimit
  )?.label;

  const isTemplateStudent = id === TEMPLATE_ID;

  return (
    <FlexContainer>
      <StyledSpan width={columnWidth[20]} notVisible={isTemplateStudent}>
        <DropDownComponent
          isDisabled={isInvitation}
          label={educationalPlanLabel || ''}
          options={educationOptions}
          handleInputChange={handleInputChange}
          studentId={id!}
          inputKey="educationalPlanId"
        />
      </StyledSpan>
      <StyledSpan width={columnWidth[20]} notVisible={isTemplateStudent}>
        <DropDownComponent
          isDisabled={isInvitation}
          label={languageLabel || ''}
          options={availableLanguages}
          handleInputChange={handleInputChange}
          studentId={id!}
          inputKey="language"
        />
      </StyledSpan>

      <StyledSpan width={columnWidth[20]} notVisible={isTemplateStudent}>
        <ToggleButton
          isActive={homeAccess}
          onToggle={(value) => handleToggleHomeAccess(value, id!)}
        />
      </StyledSpan>

      <StyledSpan width={columnWidth[20]} notVisible={isTemplateStudent}>
        <DropDownComponent
          isDisabled={!homeAccess}
          label={weeklyTimeLimitLabel || ''}
          options={weeklyTimeLimitOptions}
          handleInputChange={handleInputChange}
          studentId={id!}
          inputKey="weeklyTimeLimit"
        />
      </StyledSpan>

      <StyledSpan width={columnWidth[20]} notVisible={isTemplateStudent}>
        <Button
          className="table-button"
          disabled={!homeAccess}
          size={'M'}
          variant={'tertiary'}
          oneLine
          fill={parents?.length === 1}
          onClick={() => handleInviteParents(id!)}
          rightIcon={
            parents?.length === 0 || !parents ? 'AddUpIcon' : undefined
          }
        >
          {getParentEmails(parents, t)}
        </Button>
      </StyledSpan>
    </FlexContainer>
  );
};

const renderSecondaryDigitalPractice = ({
  t,
  language,
  email,
  educationalPlanId,
  lastLogin,
  id,
  isInvitation,
  educationOptions,
  handleInputChange,
  availableLanguages,
}: {
  t: Translate;
  language?: string;
  email?: string;
  parents?: Parent[];
  weeklyTimeLimit?: number;
  educationalPlanId?: string;
  isInvitation?: boolean;
  lastLogin?: string;
  id?: string;
  educationOptions: Option[];
  handleInputChange: (key: string, value: any, selectedId?: string) => void;
  availableLanguages: Option[];
  selectedStudentId: string;
  selectedInputKey: string;
}): JSX.Element => {
  const educationalPlan = getEducationPlan(educationalPlanId, educationOptions);
  const educationalPlanLabel = educationOptions.find(
    (option) => option.value === educationalPlan
  )?.label;

  const languageLabel = availableLanguages.find(
    (option) => option.value === language
  )?.label;

  return (
    <FlexContainer>
      <StyledSpan width={columnWidth[40]}>
        <EmailField onClick={() => navigator.clipboard.writeText(email!)}>
          {isInvitation ? <SubtleText>{email}</SubtleText> : email}
        </EmailField>
      </StyledSpan>
      <StyledSpan width={columnWidth[15]} notVisible={isInvitation}>
        <DropDownComponent
          isDisabled={isInvitation}
          label={educationalPlanLabel || ''}
          options={educationOptions}
          handleInputChange={handleInputChange}
          studentId={id!}
          inputKey="educationalPlanId"
        />
      </StyledSpan>
      <StyledSpan width={columnWidth[15]} notVisible={isInvitation}>
        <DropDownComponent
          isDisabled={isInvitation}
          label={languageLabel || ''}
          options={availableLanguages}
          handleInputChange={handleInputChange}
          studentId={id!}
          inputKey="language"
        />
      </StyledSpan>
      <StyledSpan width={columnWidth[20]}>
        <StudentStatusCell isInvitation={isInvitation} lastLogin={lastLogin} />
      </StyledSpan>
    </FlexContainer>
  );
};

const renderEyDigitalPracticeSkeleton = (): JSX.Element => {
  return (
    <FlexContainer>
      <StyledSpan width={columnWidth[20]}>
        <Skeleton width={'100%'} height={'24px'} radius={'4px'} />
      </StyledSpan>
      <StyledSpan width={columnWidth[20]}>
        <Skeleton width={'100%'} height={'24px'} radius={'4px'} />
      </StyledSpan>
      <StyledSpan width={columnWidth[20]}>
        <Skeleton width={'100%'} height={'24px'} radius={'4px'} />
      </StyledSpan>
      <StyledSpan width={columnWidth[20]}>
        <Skeleton width={'100%'} height={'24px'} radius={'4px'} />
      </StyledSpan>
      <StyledSpan width={columnWidth[20]}>
        <Skeleton width={'100%'} height={'24px'} radius={'4px'} />
      </StyledSpan>
    </FlexContainer>
  );
};

const renderPrimaryDigitalPracticeSkeleton = (): JSX.Element => {
  return (
    <FlexContainer>
      <StyledSpan width={columnWidth[20]}>
        <Skeleton width={'100%'} height={'24px'} radius={'4px'} />
      </StyledSpan>
      <StyledSpan width={columnWidth[20]}>
        <Skeleton width={'100%'} height={'24px'} radius={'4px'} />
      </StyledSpan>
      <StyledSpan width={columnWidth[20]}>
        <Skeleton width={'100%'} height={'24px'} radius={'4px'} />
      </StyledSpan>
      <StyledSpan width={columnWidth[40]}>
        <Skeleton width={'100%'} height={'24px'} radius={'4px'} />
      </StyledSpan>
    </FlexContainer>
  );
};

const renderSecondaryDigitalPracticeSkeleton = (): JSX.Element => {
  return (
    <FlexContainer>
      <StyledSpan width={columnWidth[40]}>
        <Skeleton width={'100%'} height={'24px'} radius={'4px'} />
      </StyledSpan>
      <StyledSpan width={columnWidth[15]}>
        <Skeleton width={'100%'} height={'24px'} radius={'4px'} />
      </StyledSpan>
      <StyledSpan width={columnWidth[15]}>
        <Skeleton width={'100%'} height={'24px'} radius={'4px'} />
      </StyledSpan>
      <StyledSpan width={columnWidth[20]}>
        <Skeleton width={'100%'} height={'24px'} radius={'4px'} />
      </StyledSpan>
    </FlexContainer>
  );
};

const getEducationPlan = (
  educationalPlanId: string | null | undefined,
  educationOptions: Option[]
) => {
  if (educationalPlanId !== null) return educationalPlanId;
  if (educationOptions?.length > 0)
    return educationOptions[educationOptions?.length - 1].value;
  return undefined;
};

const renderInterventionSubLabel = ({ t }: { t: Translate }): JSX.Element => {
  return (
    <FlexContainer>
      <SublabelStyledSpan width={columnWidth[25]}>
        {t('students.table.label.toggleRti')}
      </SublabelStyledSpan>
      <SublabelStyledSpan width={columnWidth[30]}>
        {t('students.table.labels.rtiStartDate')}
      </SublabelStyledSpan>
      <SublabelStyledSpan width={columnWidth[30]}>
        {t('students.table.labels.rtiEndDate')}
      </SublabelStyledSpan>
    </FlexContainer>
  );
};

const renderIntervention = ({
  t,
  handleToggleRti,
  id,
  rti,
}: {
  t: Translate;
  handleToggleRti: (studentId: string, value: boolean) => void;
  id?: string;
  rti?: {
    enabled: boolean;
    startDate: string;
    endDate: string;
  };
}): JSX.Element => {
  return (
    <FlexContainer>
      <StyledSpan width={columnWidth[25]}>
        <ToggleButton
          isActive={rti?.enabled}
          onToggle={(value) => handleToggleRti(id!, value)}
        />
      </StyledSpan>
      <StyledSpan width={columnWidth[30]}>
        {dates.getFormattedDate(rti?.startDate) || '-'}
      </StyledSpan>
      <StyledSpan width={columnWidth[30]}>
        {dates.getFormattedDate(rti?.endDate) || '-'}
      </StyledSpan>
    </FlexContainer>
  );
};

export {
  renderAvatar,
  renderDigitalPracticeSubLabel,
  renderEyDigitalPractice,
  renderEyDigitalPracticeSkeleton,
  renderHomeAccess,
  renderHomeAccessSkeleton,
  renderHomeAccessSublabel,
  renderIntervention,
  renderInterventionSubLabel,
  renderPrimaryDigitalPractice,
  renderPrimaryDigitalPracticeSkeleton,
  renderSecondaryDigitalPractice,
  renderSecondaryDigitalPracticeSkeleton,
  renderStudentName,
  renderStudentNameSkeleton,
};

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  pointer-events: all !important;
  width: 100%;
`;

const StyledSpan = styled.span<{
  width: number | string;
  notVisible?: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-right: 8px;
  width: ${({ width }) => (typeof width === 'number' ? `${width}px` : width)};
  max-width: ${({ width }) =>
    typeof width === 'number' ? `${width}px` : width};
  min-width: ${({ width }) =>
    typeof width === 'number' ? `${width}px` : width};
  visibility: ${({ notVisible }) => (notVisible ? 'hidden' : '')};
`;

const SublabelStyledSpan = styled(StyledSpan)`
  align-items: flex-start;
`;

const StyledInput = styled(Input)`
  width: 100%;

  /* Para WebKit (Chrome, Safari, Edge) */
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Para Firefox */
  &::-moz-inner-spin-button,
  &::-moz-outer-spin-button {
    -moz-appearance: none;
    margin: 0;
  }
`;

const EmailField = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledTooltip = styled(Tooltip)`
  min-width: 0;
`;

const SubtleText = styled(Typography.Body2)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

const DigitalPracticeSublabel = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 4px;

  span {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    hyphens: auto;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
`;
