
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as ClassMaterialIconComponent } from "./class-material-icon.svg";

export const ClassMaterialIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <ClassMaterialIconComponent {...props} />;
};
