
/**
  * Do not change! File was auto generate.
  *
  */
import { ReactElement, SVGProps } from 'react';
import { ReactComponent as LogoutIconComponent } from "./logout-icon.svg";

export const LogoutIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return <LogoutIconComponent {...props} />;
};
