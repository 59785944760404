import { theme } from '@innovamat/innova-components';
import { MomentTypes } from '../types/moments';

export const getDimensionsColor = (type: string | undefined) => {
  switch (type) {
    case '1':
      return {
        dark: theme.colors.green.dark['01'],
        light: theme.colors.green['05'],
        medium: theme.colors.green['04'],
      };
    case '2':
      return {
        dark: theme.colors.navy.dark['01'],
        light: theme.colors.navy['05'],
        medium: theme.colors.navy['04'],
      };
    case '3':
      return {
        dark: theme.colors.orange.dark['01'],
        light: theme.colors.orange['05'],
        medium: theme.colors.orange['04'],
      };
    case '4':
      return {
        dark: theme.colors.purple.dark['01'],
        light: theme.colors.purple['05'],
        medium: theme.colors.purple['04'],
      };
    default:
      return undefined;
  }
};

export const getMomentColor = (momentType: string) => {
  switch (momentType) {
    case MomentTypes.Takeoff:
      return {
        dark: theme.colors.purple.dark['01'],
        light: theme.colors.purple['05'],
        normal: theme.colors.purple['01'],
      };
    case MomentTypes.Flight:
      return {
        dark: theme.colors.yellow.dark['01'],
        light: theme.colors.yellow['05'],
        normal: theme.colors.yellow['01'],
      };
    case MomentTypes.Landing:
      return {
        dark: theme.colors.blue.dark['01'],
        light: theme.colors.blue['05'],
        normal: theme.colors.blue['01'],
      };
    case MomentTypes.Video:
      return {
        dark: theme.colors.brand.dark['01'],
        light: theme.colors.brand['04'],
        normal: theme.colors.brand['02'],
      };
    default:
      return {
        dark: theme.colors.dark['01'],
        light: theme.colors.dark['06'],
        normal: theme.colors.dark['02'],
        tableBg: theme.colors.dark['05'],
      };
  }
};
