import styled from '@emotion/styled';
import {
  Grounder,
  InnerHtml,
  Skeleton,
  Typography,
} from '@innovamat/glimmer-components';
import { usePage } from '@innovamat/glow-api-client';
import { MarkMessionMethodParams } from '../../../session-done';
import {
  ContentList,
  ContentListSkeleton,
} from '../../components/content-list';
import { SkeletonContainer } from '../../components/content-list/content-list.styled';
import { ContentListType, ContentResource, Page } from '../../types/page';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

type ContentListsPageProps = {
  pageId?: string;
  banners?: JSX.Element;
  title?: string;
  onResourceClick: (
    resource: ContentResource,
    list: ContentListType,
    page: Page
  ) => void;
  onShowMoreClick?: (newPageId: string, listId: string) => void;
  imgOptimizationUrl?: string;
  onError: () => void;
  setEventData: (
    eventType: string,
    eventProperties?: any,
    addUserData?: boolean
  ) => void;
  academicYearId: string;
  classroomId?: string;
  onMarkSession?: (params: MarkMessionMethodParams) => void;
  onToggleSolutions?: (params: MarkMessionMethodParams) => void;
  isStudentView?: boolean;
  yearUpdateAnnouncementPanel?: JSX.Element;
  leadAnnouncementPanel?: JSX.Element;
};

function ContentListPageSkeleton(): JSX.Element {
  return (
    <SkeletonContainer>
      <Skeleton height="32" width="140" />
      <ContentListSkeleton />
      <ContentListSkeleton />
      <ContentListSkeleton />
    </SkeletonContainer>
  );
}

export function ContentPage({
  pageId,
  banners,
  title,
  onResourceClick,
  onShowMoreClick,
  imgOptimizationUrl,
  onError,
  academicYearId,
  classroomId,
  onMarkSession,
  onToggleSolutions,
  isStudentView,
  yearUpdateAnnouncementPanel,
  leadAnnouncementPanel,
}: ContentListsPageProps): JSX.Element {
  const hasPageId = !!pageId;

  const { data, isLoading, isError } = usePage({
    pageId: pageId!,
    academicYearId: academicYearId!,
    classroomId: classroomId!,
    isStudentView: Boolean(isStudentView),
  });

  const page = data as unknown as Page;

  if (isLoading || !hasPageId || !page) {
    return <ContentListPageSkeleton />;
  }

  if (isError || !page) {
    onError();
  }

  return (
    <>
      <Container>
        {leadAnnouncementPanel}
        <Typography.H3 data-cy="contentTitle">
          <InnerHtml text={title || page.name} />
        </Typography.H3>
        {banners && banners}
        {yearUpdateAnnouncementPanel}

        {page?.content_lists?.map((list, index) => {
          return (
            <ContentList
              key={index}
              list={list}
              onResourceClick={(resource) =>
                onResourceClick(resource, list, page)
              }
              onShowMoreClick={
                list.page_id && onShowMoreClick
                  ? () => onShowMoreClick(list.page_id, list.id)
                  : undefined
              }
              imgOptimizationUrl={imgOptimizationUrl}
              onMarkSession={onMarkSession}
              onToggleSolutions={onToggleSolutions}
            />
          );
        })}
      </Container>
      <Grounder />
    </>
  );
}
