import styled from '@emotion/styled';
import { Rating as MUIRating, createTheme } from '@mui/material';
import { ThemeProvider as MaterialProvider } from '@mui/material/styles';
import { ReactElement, SyntheticEvent } from 'react';

export type RatingProps = {
  value: number | null;
  onChange: (
    event: SyntheticEvent<Element, Event>,
    value: number | null
  ) => void;
};

const StarIcon = (): ReactElement => (
  <svg height="30" viewBox="0 0 24 24" width="30">
    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
  </svg>
);

const Wrapper = styled.div`
  .MuiRating-iconEmpty {
    fill: ${({ theme }) =>
      theme.tokens.color.alias.cm.icon['icon-disabled'].value};
  }

  .MuiRating-iconFilled {
    fill: ${({ theme }) =>
      theme.tokens.color.alias.cm.bg['bg-accent-inverted'].value};
  }
`;

export function Rating({ value, onChange }: RatingProps): JSX.Element {
  const materialTheme = createTheme({});

  return (
    <MaterialProvider theme={materialTheme}>
      <Wrapper>
        <MUIRating
          size="large"
          value={value}
          onChange={onChange}
          icon={<StarIcon />}
          emptyIcon={<StarIcon />}
        />
      </Wrapper>
    </MaterialProvider>
  );
}
