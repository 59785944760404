import Accordion from '../Accordion';
import { Moment, Moments, MomentTypes } from '../../types/moments';
import MomentBlockTitle from './MomentsBlock.Title';
import { AccordionItem, AccordionWrapper } from '../Accordion/Accordion.styles';
import { ScrollPosition, Tag } from '../../types/prepr';
import { ImageModal } from '../ImagesModal/ImagesModal';
import { Icon, Icons } from '@innovamat/innova-components';
import MomentBlockDuration from './MomentBlock/MomentBlock.Duration';
import MomentsBlockStandard from './MomentsBlock.Standard';
import styled from '@emotion/styled';
import { onClickBox } from '../../utils/openBoxes';
import MomentsBlockItems from './MomentsBlock.Items';
import { MaterialList } from '../../types/materials';
import { useDigitalGuides } from '../../context';

const StandardWrapper = styled.div`
  display: flex;
  gap: 4px;
`;

type Props = {
  moments: Moments;
  getIsOpen: (key: number) => boolean;
  scrollPosition: ScrollPosition;
  onScrollMoment: (index: number | undefined) => void;
  onSelectMoment: (moment: Moment, index: number) => void;
  onClickImage: (image: ImageModal | null) => void;
  momentIcons: Record<string, keyof typeof Icons>;
  customMomentTitles?: Record<string, string>;
  materialList?: MaterialList;
  momentSummaryKey: string;
};

export default function MomentsBlock({
  moments,
  getIsOpen,
  scrollPosition,
  onScrollMoment,
  onSelectMoment,
  onClickImage,
  momentIcons,
  customMomentTitles,
  materialList,
  momentSummaryKey,
}: Props) {
  const { t } = useDigitalGuides();

  const handleOnClick = async (index: number, moment: Moment) => {
    onClickBox(index, moment, getIsOpen, onSelectMoment, onScrollMoment);
  };

  const getIcon = (momentType: string) => {
    return <Icon icon={momentIcons[momentType]} />;
  };

  const getHeading = (
    serieName: string | undefined,
    index: number,
    momentType: string
  ) => {
    if (serieName) return serieName;
    if (customMomentTitles) return t(customMomentTitles[momentType]);

    return t('digitalguides.se.moment.title', {
      num: index,
    });
  };

  const renderAction = (
    standards: Tag[] | undefined,
    duration: string | undefined
  ) => {
    if (duration) {
      return <MomentBlockDuration duration={duration} />;
    }

    if (standards) {
      return (
        <StandardWrapper>
          {standards?.map((standard) => (
            <MomentsBlockStandard standard={standard.body} />
          ))}
        </StandardWrapper>
      );
    }

    return undefined;
  };

  return (
    <AccordionWrapper>
      <Accordion
        scrollPosition={scrollPosition}
        getIsOpen={getIsOpen}
        itemsLength={moments.items.length}
        onScroll={onScrollMoment}
        component={AccordionItem}
        renderChildren={(index) => {
          const moment = moments.items[index];
          const hasSomVideoChapter = moments.items.some(
            (item) => item.video_chapter_content
          )
            ? 0
            : 1;

          const videoChapter = moment.video_chapter_content;
          const items = videoChapter?.items[0].items;
          const momentType = moment.moment?.body || MomentTypes.Video;

          const heading = getHeading(
            items?.seriename?.body,
            index + hasSomVideoChapter,
            momentType
          );

          const duration = items?.chapter_duration?.body;
          const standards = moment?.standard?.items;

          const action = renderAction(standards, duration);

          return (
            <>
              <MomentBlockTitle
                icon={getIcon(momentType)}
                getIsOpen={getIsOpen}
                id={moment.id}
                onClick={() => handleOnClick(index, moment)}
                title={moment.title.body}
                heading={heading || ''}
                index={index}
                action={action}
                momentType={moment.moment?.body || MomentTypes.Video}
                keyMoment={!!moment.key_moment?.value}
              />
              <MomentsBlockItems
                moment={moment}
                isOpen={getIsOpen(index)}
                onClickImage={onClickImage}
                materialList={materialList}
                momentSummaryKey={momentSummaryKey}
              />
            </>
          );
        }}
      />
    </AccordionWrapper>
  );
}
