import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';

import type { ClassroomQuery } from '@innovamat/glow-api-client';
import { useMarkClassroomAsCheckedMutation } from '@innovamat/glow-api-client';

import { Button, Checkbox } from '@innovamat/glimmer-components';

import styled from '@emotion/styled';

const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.surface['surface-secondary'].value};
  border-top: 1px solid
    ${({ theme }) => theme.tokens.color.alias.cm.border['border-subtle'].value};
  bottom: 0;
  display: flex;
  height: 72px;
  justify-content: space-between;
  left: 0;
  padding: 0 32px 0 32px;
  position: fixed;
  width: 100%;
  z-index: 30;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding-left: 304px;
  }

  input {
    margin-right: 24px;
  }
`;

const CheckWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

function ClassroomCheckConfirmation({
  classroom,
}: {
  classroom: ClassroomQuery['classroom'] | undefined;
}): JSX.Element {
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const { mutate, isPending: isClassroomBeingMarkedAsChecked } =
    useMarkClassroomAsCheckedMutation();

  const handleClassCheckedConfirm = (): void => {
    mutate(
      { classroomId: classroom?.id || '' },
      {
        onSuccess: () => {
          queryClient.refetchQueries({ queryKey: ['Classroom'] });
        },
      }
    );
  };

  return (
    <Container>
      <CheckWrapper>
        <Checkbox
          checked={isCheckboxChecked}
          onCheck={() => setIsCheckboxChecked((prev) => !prev)}
        />
        {t('students.class.checkLabel')}
      </CheckWrapper>
      <div>
        <Button
          disabled={!isCheckboxChecked}
          loading={isClassroomBeingMarkedAsChecked}
          onClick={handleClassCheckedConfirm}
        >
          {t('students.class.checked')}
        </Button>
      </div>
    </Container>
  );
}

export { ClassroomCheckConfirmation };
