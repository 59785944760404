import styled from '@emotion/styled';

import { Accordion } from '../Accordion';
import { getTypography, useGlimmerTheme } from '@innovamat/glimmer-components';
import { css } from '@emotion/react';
import { useDimensionColors } from '../../hooks/useDimensionColors';
import { PreprInnerHtml } from '../PreprInnerHtml';
import { Media } from '../Media';
import { WhatToObserveItem } from '../../types/SmartGuide';
import { useSmartGuides } from '../../context/SmartGuidesProvider';
import { PrintableBody2 } from '../../printable-styles/printable-typography';
import { Stage } from '../../types/Stage';

type Props = {
  whatToObserve: WhatToObserveItem[];
  isPrintable?: boolean;
};

type StyledProps = {
  color: string;
  subtitle: string;
};

const Container = styled(PrintableBody2)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 2px;

  @media print {
    gap: 2px;
  }
`;

const StyledWhatToObserve = styled(PreprInnerHtml)<StyledProps>`
  color: ${({ theme }) => theme.tokens.color.alias.cm.text.text.value};

  ${({ theme, color, subtitle }) =>
    css`
      ::before {
        content: '${subtitle}: ';
        color: ${color};
        ${getTypography(theme, 'Subtitle 2')}

        @media print {
          font-size: 12px;
          line-height: 14px;
        }
      }
    `}

  > p {
    display: inline;

    @media print {
      ${({ theme }) => getTypography(theme, 'Body 3')}
      color: #000000;
      line-height: 14px;
      display: inline;
    }
  }
`;

export function WhatToObserve({
  whatToObserve,
  isPrintable,
}: Props): JSX.Element {
  const { getDimensionElement } = useDimensionColors();
  const theme = useGlimmerTheme();
  const { t, stage } = useSmartGuides();

  return (
    <Accordion
      summary={
        stage === Stage.Secondary
          ? t('digitalguides.observe.se')
          : t('digitalguides.observe')
      }
      isPrintable={isPrintable}
    >
      <Container>
        {whatToObserve.map((item, index) => {
          return (
            item.content && (
              <div key={index}>
                <StyledWhatToObserve
                  text={item.content}
                  subtitle={t(item.text)}
                  color={
                    item.dimension
                      ? getDimensionElement(item.dimension)
                      : theme.tokens.color.alias.cm.text.text.value
                  }
                />
                {item.media && <Media media={item.media} />}
              </div>
            )
          );
        })}
      </Container>
    </Accordion>
  );
}
