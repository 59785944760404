import { fork } from 'redux-saga/effects';
import unitySagas from '../unityData/unityData.saga';
import authSagas from '../auth/sagas';

function* rootSaga(): Generator {
  yield fork(authSagas);
  yield fork(unitySagas);
}

export default rootSaga;
