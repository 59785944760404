import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBubbleInfo = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M10.625.625a8.738 8.738 0 0 0-7.43 13.354l-2.57 5.396 5.395-2.57A8.75 8.75 0 1 0 10.625.625"
      />
      <path
        strokeWidth={1.249995}
        d="M12.5 13.125h-.625a1.25 1.25 0 0 1-1.25-1.25V8.75A.625.625 0 0 0 10 8.125h-.625M10.625 5.313A.313.313 0 0 0 10.313 5"
      />
      <path strokeWidth={1.249995} d="M10.313 5a.313.313 0 1 0 .312.313" />
    </g>
  </svg>
);
export default SvgBubbleInfo;
