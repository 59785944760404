import styled from '@emotion/styled';

import { ButtonCircleProps } from './ButtonCircle.types';

const ButtonWrapper = styled('button')<ButtonCircleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  height: 20px;
  width: 20px;
  border-radius: 50px;
  border: none;
  box-shadow: ${({ theme }) => theme.elevations['01']};
  cursor: pointer;
  outline: none;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.brand.dark['02']};
  &:hover {
    background-color: ${({ theme }) => theme.colors.brand.dark['01']};
  }
  &:disabled {
    cursor: auto;
    background-color: ${({ theme }) => theme.colors.dark['03']};
  }
  svg {
    width: 50%;
    height: 50%;
    position: relative;
  }

  svg,
  path,
  rect {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

export { ButtonWrapper };
